import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Typography,

  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {LightMode,DarkMode} from "./assets"
import Radio from '@material-ui/core/Radio';
import SettingsController from "./SettingsController";
import CssBaseline from '@material-ui/core/CssBaseline';




// Customizable Area End

export default class Settings extends SettingsController {
  // constructor(props: Props) {
  //   super(props);
  //   // Customizable Area Start
  //   // Customizable Area End
  // }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {darkMode} = this.state;
    const theme = createTheme({
      palette: {
        type: darkMode ? "dark" : "light",
        background: {
          default: darkMode ? "#000" : "#E5E4E1", // Your custom default background color for dark mode
        },
      },
    });
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
     <CssBaseline/>

    <SettingStyleWrapper>
    <Box className="settings-wrapper">
      <Typography className="textTheme">Select The Theme</Typography>
      <Box className="skeleton-wrpper">
       <Box className="skeleton-wrpper-with-radio">
            <Box className="skeleton-card-wrapper">
                  <Box className="sk-image-wrapper">
                        <img src={LightMode} alt="" />
                  </Box>
                  <Box  className="sk-lines-wrapper">
                  
                
                            <Box className="firstStrip"></Box>
                            <Box className="twoStripWrapper">
                              <Box></Box>
                              <Box></Box>
                            </Box>
                            

                            <Box style={{marginTop:"20px"}} className="firstStrip"></Box>
                            <Box className="twoStripWrapper">
                              <Box></Box>
                              <Box></Box>
                            </Box>
                      
                  </Box>
              </Box>
            <Box>
           <Box className="textRadioWrapper">
                <Radio
                    // value="b"
                    // name="radio-button-demo"
                    // inputProps={{ 'aria-label': 'B' }}
                    checked={!darkMode}
                    onChange={()=>this.handleThemeChange('light')}
                    color="default"
                    value={!darkMode}

                  />
              <Typography className="modeText">Light Mode</Typography>
           </Box>
 
            </Box>
       </Box>

         <Box className="skeleton-wrpper-with-radio">
            <Box className="skeleton-card-wrapper2">
                <Box className="sk-image-wrapper2">
                      <img src={DarkMode} alt="" />
                </Box>
                <Box className="sk-lines-wrapper">
                <Box className="firstStrip2"></Box>
                            <Box className="twoStripWrapper2">
                              <Box></Box>
                              <Box></Box>
                            </Box>
                            

                            <Box style={{marginTop:"20px"}} className="firstStrip2"></Box>
                            <Box className="twoStripWrapper2">
                              <Box></Box>
                              <Box></Box>
                            </Box>
                </Box>
            </Box>
            <Box className="textRadioWrapper">
            <Radio
                        checked={darkMode}
                        onChange={()=>this.handleThemeChange('dark')}
                        color="default"
                        value={darkMode}
  
                // value="b"
                // name="radio-button-demo"
                // inputProps={{ 'aria-label': 'B' }}
/>
          <Typography className="modeText">Dark Mode</Typography>
       
            </Box>    
         </Box>
      </Box>

      <Box className="resetPasswordWrapper">
          <Typography className="resetPasswordText">Reset Password</Typography>
           <Box onClick={this.handleResetNavigation} className="resetPasswordBtn">
               <Typography  className="resetPasswordTextAndIcon">Reset Password</Typography> < NavigateNextIcon style={{color:"#000"}}/>
           </Box>
      </Box>

    </Box>

  </SettingStyleWrapper>
  
    </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SettingStyleWrapper  = styled(Box)({
  '& .settings-wrapper':{
    padding: "30px",
  },
  '& .textTheme':{
    // color:"#FFF",
    fontFamily:'Outfit',
    fontSize:'22px',
    fontWeight:400,
    lineHeight:'normal'
  },
  '& .skeleton-wrpper':{
    marginTop:'10px',
    display:"flex",
    gap:"25px"
  },
  '& .skeleton-wrpper-with-radio':{
    maxWidth:"437px",
    width:"100%"
  },
  '& .skeleton-card-wrapper':{
    display:"flex",
    gap:"10px",
    background:"#E5E4E1",
    maxWidth:"437px",
    height:'165px',
    width:"100%",
    padding:"8px",
    borderRadius:'8px'
  },

  '& .skeleton-card-wrapper2':{
    display:"flex",
    gap:"10px",
    background:"#2B2B2B",
    maxWidth:"437px",
    height:'165px',
    width:"100%",
    padding:"8px",
    borderRadius:'8px'
  },
  
  
  '& .sk-image-wrapper':{
      display:"flex",
      background:'#17171733',
      padding:"6px",
      borderRadius:"4px",
      justifyContent:"center",
      alignItems:"center",
      maxWidth:'149px',
      width:'100%',
      height:'149px',
  },
  '& .sk-image-wrapper2':{
    display:"flex",
    background:'#F3F3F333',
    padding:"6px",
    borderRadius:"4px",
    justifyContent:"center",
    alignItems:"center",
    maxWidth:'149px',
    width:'100%',
    height:'149px',
},
'& .sk-lines-wrapper':{
    width:"100%"
},
'& .firstStrip':{
    background:"#171717",
    borderRadius:"4px",
    maxWidth: "256px",
    width:"100%",
    height: "10px",
},
'& .firstStrip2':{
  background:"#F3F3F3",
  borderRadius:"4px",
  maxWidth: "256px",
  width:"100%",
  height: "10px",
},
'& .twoStripWrapper': {
  display: "flex",
  gap:"12px",
  marginTop:"15px",
  // Other styles for .twoStripWrapper

  '& > :first-child': {
    maxWidth: "100px",
    width:"100%",
    height: "10px",
    borderRadius: "4px",
    background: "var(--text-secondary, rgba(23, 23, 23, 0.80))"
  },

  '& > :nth-child(2)': {
    maxWidth: "80px",
    width:"100%",
    height: "10px",
    borderRadius: "4px",
    background: "var(--text-secondary, rgba(23, 23, 23, 0.65))"
  },
},
'& .twoStripWrapper2': {
  display: "flex",
  gap:"12px",
  marginTop:"15px",
  // Other styles for .twoStripWrapper

  '& > :first-child': {
    maxWidth: "100px",
    width:"100%",
    height: "10px",
    borderRadius: "4px",
    background: "var(--text-secondary, rgba(243, 243, 243, 0.80))",
  },

  '& > :nth-child(2)': {
    maxWidth: "80px",
    width:"100%",
    height: "10px",
    borderRadius: "4px",
    background: "var(--text-secondary, rgba(243, 243, 243, 0.65))",
  },
},
'& .modeText':{
  // color:'#FFF',
  fontFamily: 'Outfit',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0em',
  textAlign: 'left',
},
'& .textRadioWrapper':{
  display:"flex",
  alignItems:"center",
  marginTop:"10px"
},
'& .resetPasswordWrapper':{
    marginTop:"20px",

},
'& .resetPasswordBtn':{
  gap: '8px',
  width: '100%',
  height: '44px',
  display: 'inline-flex',
  padding: '10px 15px',
  maxWidth: '180px',
  background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)',
  alignItems: 'center',
  borderRadius: '8px',
  marginBottom: '12px',
  cursor:'pointer'
},
'& .resetPasswordText':{
  marginBottom:"12px",
  // color: 'var(--text-primary, #F3F3F3)',
  fontFamily: 'Outfit',
  fontSize: '22px',
  fontStyle: 'normal',
  lineHeight: 'normal',
},
'& .resetPasswordTextAndIcon':{
  color: 'var(--button-text, #171717)',
  fontFamily: 'Outfit',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '24px',
},
'@media screen and (max-width: 687px)': {
  '& .skeleton-card-wrapper,.skeleton-card-wrapper2': {
    maxWidth:"278px",
    height:'136px',
    width:"100%",
  },
  '& .sk-image-wrapper, .sk-image-wrapper2':{
    maxWidth:'120px',
    width:'100%',
    height:'120px',
},

},

})
// export default withRouter(Settings);
// Customizable Area End
