import { Box, Typography, styled } from "@material-ui/core";
import React from 'react';

interface Props {
  status: string
}

const CustomStatus = ({status}: Props) => {
  switch (status) {
    case 'draft':
      return <CustomStatusStyle>
        <Box className="draftBox">
          <Typography className="draftText" noWrap>Draft</Typography>
        </Box>
      </CustomStatusStyle>
    case 'pending_hard_copy_handover':
      return <CustomStatusStyle>
        <Box className="pending_hardcopy_handoverWrap">
          <Typography className="pending_hardcopy_handoverTextWrap" noWrap>Pending Hard copy Handover</Typography>
        </Box>
      </CustomStatusStyle>
    case 'pending_submission':
      return <CustomStatusStyle>
        <Box className="pending_submissionWrap">
          <Typography className="pending_submissionTextWrap" noWrap>Pending submission</Typography>
        </Box>
      </CustomStatusStyle>
    case 'pending_approval':
      return <CustomStatusStyle>
        <Box className="pending_approvalWrap">
          <Typography className="pending_approvalTextWrap" noWrap>Pending Approval</Typography>
        </Box>
      </CustomStatusStyle>
    case 'pending_hard_copy_submission':
      return <CustomStatusStyle>
        <Box className="pending_hard_copy_submissionWrap">
          <Typography className="pending_hard_copy_submissionTextWrap" noWrap>Pending Hard copy submission</Typography>
        </Box>
      </CustomStatusStyle>
    case 'pending_additional_docs':
      return <CustomStatusStyle>
        <Box className="pending_additional_docsWrap">
          <Typography className="pending_additional_docsTextWrap" noWrap>Pending additional docs (B/G)</Typography>
        </Box>
      </CustomStatusStyle>
          case 'completed':
      return <CustomStatusStyle>
        <Box className="completedWrap">
          <Typography className="completedTextWrap" noWrap>Complete</Typography>
        </Box>
      </CustomStatusStyle>
    default:
      return <></>
  }
}


const CustomStatusStyle =  styled(Box)(({ theme }) => ({
  '& .draftBox':{border:`1px solid ${theme.typography.h1}`, paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2, width: 'fit-content', borderRadius: 40, },
  '& .draftText': {color: theme.typography.h1, fontFamily:'Outfit', fontWeight:400, fontSize:12,},
  '& .pending_hardcopy_handoverWrap':{backgroundColor:'#D1FAE5', paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2, width: 'fit-content', borderRadius: 40},
  '& .pending_submissionWrap':{backgroundColor:'#FEE2E2', paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2, width: 'fit-content', borderRadius: 40},
  '& .pending_approvalWrap':{backgroundColor:'#DC2626', paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2, width: 'fit-content', borderRadius: 40},
  '& .pending_hard_copy_submissionWrap':{backgroundColor:'#D97706', paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2, width: 'fit-content', borderRadius: 40},
  '& .pending_additional_docsWrap':{backgroundColor:'#FEF3C7', paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2, width: 'fit-content', borderRadius: 40},
  '& .completedWrap':{backgroundColor:'#059669', paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2, width: 'fit-content', borderRadius: 40},

  '& .pending_hardcopy_handoverTextWrap':{color:'#059669', fontFamily:'Outfit', fontWeight:400, fontSize:12,
  '@media (max-width: 600px)': {
    fontSize: 10,
  },
  },
  '& .pending_submissionTextWrap':{color:'#DC2626', fontFamily:'Outfit', fontWeight:400, fontSize:12},
  '& .pending_approvalTextWrap':{color:'#FEE2E2', fontFamily:'Outfit', fontWeight:400, fontSize:12},
  '& .pending_hard_copy_submissionTextWrap':{color:'#FEF3C7', fontFamily:'Outfit', fontWeight:400, fontSize:12},
  '& .pending_additional_docsTextWrap':{color:'#D97706', fontFamily:'Outfit', fontWeight:400, fontSize:12},
  '& .completedTextWrap':{color:'#D1FAE5', fontFamily:'Outfit', fontWeight:400, fontSize:12},


}))
  
export default CustomStatus;