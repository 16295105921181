import React, {  } from "react";

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    BlobProvider,
    Image,
    Font,
    PDFViewer
} from "@react-pdf/renderer";
import moment from "moment";


type Props = {
    isSave: boolean;
    setPdf: any;
    data: any;
};


Font.register({
    family: 'RegularMer',
    src: require('./fonts/MerriweatherSans-Regular.ttf'),
    fontWeight: 'normal',
});
Font.register({
    family: 'BoldMer',
    src: require('./fonts/MerriweatherSans-Bold.ttf'),
    fontWeight: 'bold',
});
Font.register({
    family: 'BoldItaMer',
    src: require('./fonts/MerriweatherSans-BoldItalic.ttf'),
});

Font.register({
    family: 'ItaMer',
    src: require('./fonts/MerriweatherSans-Italic.ttf'),
});


Font.register({
    family: 'RegularHel',
    src: require('./fonts/helvetica_regular.ttf'),
    fontWeight: 'normal',
});
Font.register({
    family: 'BoldHel',
    src: require('./fonts/helvetica_bold.ttf'),
    fontWeight: 'bold',
});



const styles = StyleSheet.create({
    textMer: {
        fontFamily: 'RegularMer',
        fontSize: 7,
    },
    textMerIta: {
        fontFamily: 'ItaMer',
        fontSize: 6,
    },
    textMerIta8: {
        fontFamily: 'ItaMer',
        fontSize: 8,
    },
    textMer5: {
        fontFamily: 'RegularMer',
        fontSize: 5,
    },
    textMer6: {
        fontFamily: 'RegularMer',
        fontSize: 6,
    },
    text: {
        fontFamily: 'Regular',
        fontSize: 6,
    },
    textMer8Normal: {
        fontFamily: 'RegularMer',
        fontSize: 8,
    },
    textMer9: {
        fontFamily: 'RegularMer',
        fontSize: 9,
    },
    textMerBold: {
        fontFamily: 'BoldMer',
        fontSize: 11,
    },
    textMerBold8: {
        fontFamily: 'BoldMer',
        fontSize: 8,
    },
    textMerBold6: {
        fontFamily: 'BoldMer',
        fontSize: 6,
    },
    textMerBoldIta: {
        fontFamily: 'BoldItaMer',
        fontSize: 11,
    },
    textHel8: {
        fontFamily: 'RegularHel',
        fontSize: 8,
    },
    textHel9:{
        fontFamily: 'RegularHel',
        fontSize: 9,
    },
    textHel11: {
        fontFamily: 'RegularHel',
        fontSize: 11,
    },
    textHel6: {
        fontFamily: 'RegularHel',
        fontSize: 6,
    },
    textHel7: {
        fontFamily: 'RegularHel',
        fontSize: 7,
    },
    textHelBold: {
        fontFamily: 'BoldHel',
        fontSize: 10,
    },
    textHelBold6: {
        fontFamily: 'BoldHel',
        fontSize: 6,

    },
    textHelBold8: {
        fontFamily: 'BoldHel',
        fontSize: 8,
    },

});


const pStyles = StyleSheet.create({

    page: {
        backgroundColor: "#ffffff",
        padding: '12.17px 13.55px 0px  13.55px',
        letterSpacing: 0
    },

    page2: {
        backgroundColor: "#ffffff",
        padding: '22px 16.13px 0px 20.87px',
        letterSpacing: 0
    },

    page3: {
        backgroundColor: "#ffffff",
        padding: '24px 36px 0px 36px',
        letterSpacing: 0
    },
});

const Doc1 = ({data, dob, dobGuarantor}: any) => (<>
    <Document>
        
                <Page size="A4" style={pStyles.page}>
            <View>
                <View style={{ flexDirection: "row",marginLeft:"auto", justifyContent: "space-between",width:"90%" }}>
                    <View>
                        <Text style={styles.textHel8}>Hong Leong Finance Limited
                        </Text>
                        <Text style={styles.textHel8}>
                            Head Office: 61 Raffles Quay #01-05 Hong Leong Building Singapore 048581</Text>
                    </View>
                    <View  >
                        <Text style={{ ...styles.textHel11, textAlign: "right" }}>Application Form For Hire Purchase</Text>
                    </View>
                </View>
                
                <View style={{ display: 'flex',flexDirection:"column", alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    <View>
                        <Image src={require("../src/hongleon.png")} style={{ width: 154.18, height: 53.86 }} />

                    </View>

                    <Text style={styles.textHel9}>
                        f you wish ot have a free credit report, you may obtain ti within 30 calendar days from the date of approval or rejection of this application vai hte credit bureau website at www.creditbureau.com.sg. Alternatively, you may bring the approval or rejection letter and your NRIC to the credit bureau's registered office to obtain a free credit report at Credit Bureau (Singapore) Pte Ltd 2 Shenton Way #20-02 SGX Centre 1 Singapore 068804 Tel: (65) 65656363.
                    </Text>


                </View>


                <View style={{ marginTop: 10, }}>
                    <View style={{flexDirection:"row",gap:"12px"}}>
                    <View style={{flex:1,marginRight:"5px",border:"0.5px solid #000" }}>
                    <View style={{ padding: '2.74px 6.79px', height:20,flexDirection:"row",justifyContent:"center", alignItems:"center", backgroundColor: '#000' }}>
                        <Text style={{ ...styles.textHelBold6, textAlign: "center", color: "#fff" }}>MY PERSONAL INFORMATION (MAIN APPLICANT)</Text>
                        </View>
                      
                            <Text style={{ ...styles.textHel6, padding: "0px 4px ",  }}>NAME OF APPLICANT (underline surname)   </Text>
                            <Text style={{ ...styles.textHel6, padding: "0px 4px ",  }}> </Text>
                            <View>
                                <Text style={{ ...styles.textHel6, padding: "4px ", height: 25 }}>D( r / Mr / Mdm / Msr / Ms)*_______________________________</Text>
                                <Text style={{ ...styles.textHel6, position:'absolute', left:80 }}>{data.fullName}</Text>
                            </View>
                           

                            <View style={{ borderTop: '0.5px solid #000' }}>
                                <Text style={{ ...styles.textHel6, padding: "4px ", }}> NRIC / PAS SPORT / OC CERT NO  {data.NRIC}</Text>
                            </View>
                       
                            <View style={{ flexDirection: "row", padding: "2px", justifyContent: "space-between", borderTop: "0.5px solid #000", height: 30 }}>
                                <View style={{width:'50%'}}>
                                    <Text style={{ flex: 1, ...styles.textHel6 }}> DATE FO BIRTH (DD/MM/YY)</Text>
                                    <Text style={{ flex: 1, ...styles.textHel6 }}>{dob}</Text>
                                </View>
                                
                                <Text style={{ flex: 1,width:'50%', ...styles.textHel6 }}>RACE</Text>
                            </View>
                            <View style={{ flexDirection: "row", padding: "2px", justifyContent: "space-between", borderTop: "0.5px solid #000", height: 30 }}>
                                <View style={{ flex: 1, ...styles.textHel6 }}><Text style={{  ...styles.textHel6 }}> NATIONALITY</Text>
                                <View style={{flexDirection:"row",justifyContent:"space-around"}}>
                                <Text style={{ ...styles.textHel6 }}><Text style={styles.text}>☐</Text>  SINGAPOREAN</Text>
                                    <Text style={{ ...styles.textHel6 }}><Text style={styles.text}>☐</Text>  PR</Text>
                                    <Text style={{ ...styles.textHel6 }}><Text style={styles.text}>☐</Text>  FOREIGNER</Text>
                                </View>
                                 
                                   
                                </View>
                                
                                
                               
                                <View style={{ flex: 1, ...styles.textHel6 }}>
                                    <Text style={{  ...styles.textHel6 }}> MARITAL STATUS</Text>
                                    <View style={{flexDirection:"row",justifyContent:"space-around"}}>
                                        <Text style={{ ...styles.textHel6 }}><Text style={styles.text}>☐</Text>  SINGLE</Text>
                                        <Text style={{ ...styles.textHel6 }}><Text style={styles.text}>☐</Text>  MARRIED</Text>
                                        <Text style={{ ...styles.textHel6 }}><Text style={styles.text}>☐</Text>  OTHERS</Text>
                                    </View>
                                </View>
                            </View>
                            <Text style={{ ...styles.textHel6, padding: "2px ", height: 20 ,borderTop:"0.5px solid #000"}}>NAME OF SPOUSE (according to identity card)</Text>
                       
                            <View style={{flexDirection: "column", padding: "2px",  borderTop: "0.5px solid #000" }}>
                                <Text style={{...styles.textHel6 }}>HOME ADDRESS</Text>                                
                                <Text style={{...styles.textHel6 }}>{data.address}</Text>
                                <Text style={{...styles.textHel6 }}> </Text>
                            </View>
                            <View style={{ flexDirection: "row", padding: "2px", justifyContent: "space-between", borderTop: "0.5px solid #000", height: 40 }}>
                                <Text style={{ flex: 1, ...styles.textHel6 }}>MAILING ADDRES (if different from above)</Text>
                               
                            </View>
                            <View style={{ flexDirection: "row", padding: "2px", justifyContent: "space-between", borderTop: "0.5px solid #000", height: 20 }}>
                                <View style={{ flex: 1, ...styles.textHel6 , flexDirection:'row' }}><Text style={{  ...styles.textHel6 ,marginRight:12}}> RESIDENCE</Text>
                                <View style={{flexDirection:"row",justifyContent:"space-between"}}>
                                                  
                                <Text style={{ ...styles.textHel6 ,marginRight:5}}><Text style={styles.text}>☐</Text>  OWN HOUSE </Text>
                                    <Text style={{ ...styles.textHel6,marginRight:5 }}><Text style={styles.text}>☐</Text>  RENTED</Text>
                                    <Text style={{ ...styles.textHel6 ,marginRight:5}}><Text style={styles.text}>☐</Text>  OTHERS</Text>
                                </View>
                                 
                                   
                                </View>
                                
                                
                                
                            </View>
                            <View style={{ flexDirection: "row", padding: "4px 2px", justifyContent: "space-between", borderTop: "0.5px solid #000", height: 40 }}>
                    
                                <View style={{ width:200, ...styles.textHel6 ,flexDirection:"column",justifyContent:"space-between",}}>
                                    <Text style={{ ...styles.textHel6 ,height:15,}}>  HOME:                    __________________________</Text>
                                    
                                    <Text style={{ ...styles.textHel6,height:15 }}>  OFFICE:                  __________________________</Text>
                                    
                                </View>
                                
                              

                                <View style={{ width:130, ...styles.textHel6 ,flexDirection:"column",justifyContent:"space-between", }}>
                                <Text style={{ ...styles.textHel6,height:15 }}>MOBILE:       _________________</Text>
                                <Text style={{ ...styles.textHel6, position: 'absolute', left:40 }}>{data.contactNumber}</Text>
                                <Text style={{ ...styles.textHel6 ,height:15}}>PAGER:        _________________</Text>
                                </View>

                               
                            </View>
                            <View style={{ padding:' 2px',height:20}}>
                            <Text style={{ ...styles.textHel6 }}>EMAIL ADDRESS:______________________________________________________________</Text>
                            <Text style={{ ...styles.textHel6, position: 'absolute', left:70 }}>{data.emailID}</Text>
                            </View>
                    </View>
                    <View style={{flex:1,border:"0.5px solid #000" }}>
                    <View style={{ padding: '2.74px 6.79px', height:20,flexDirection:"row",justifyContent:"center", alignItems:"center", backgroundColor: '#000' }}>
                        <Text style={{ ...styles.textHelBold6, textAlign: "center", color: "#fff" }}>GUARANTOR INFORMATION</Text>
                        </View>
                      
                            <Text style={{ ...styles.textHel6, padding: "0px 4px ",}}>NAME OF APPLICANT (underline surname)  </Text>
                            <Text style={{ ...styles.textHel6, padding: "0px 4px ",}}> </Text>
                            <View>
                                <Text style={{ ...styles.textHel6, padding: "4px ", height: 25 }}>D( r / Mr / Mdm / Msr / Ms)*_______________________________</Text>
                                <Text style={{ ...styles.textHel6, position:'absolute', left:80 }}>{data.guarantor[0] && data.guarantor[0].fullNameGuarantor}</Text>
                            </View>

                            <View style={{ borderTop: '0.5px solid #000' }}>
                                <Text style={{ ...styles.textHel6, padding: "4px ", }}> NRIC / PAS SPORT / OC CERT NO {data.guarantor[0] && data.guarantor[0].NRICGuarantor}</Text>
                            </View>
                       
                            <View style={{ flexDirection: "row", padding: "2px", justifyContent: "space-between", borderTop: "0.5px solid #000", height: 30 }}>
                                <View style={{width:'50%',}}>
                                    <Text style={{ flex: 1, ...styles.textHel6 }}> DATE FO BIRTH (DD/MM/YY)</Text>
                                    <Text style={{ flex: 1, ...styles.textHel6 }}>{dobGuarantor}</Text>
                                </View>
                                
                                <Text style={{ flex: 1,width:'50%', ...styles.textHel6 }}>RACE</Text>
                            </View>
                            <View style={{ flexDirection: "row", padding: "2px", justifyContent: "space-between", borderTop: "0.5px solid #000", height: 30 }}>
                                <View style={{ flex: 1, ...styles.textHel6 }}>
                                    <Text style={{  ...styles.textHel6 }}> NATIONALITY</Text>
                                    <View style={{flexDirection:"row",justifyContent:"space-around"}}>
                                        <Text style={{ ...styles.textHel6 }}><Text style={styles.text}>☐</Text>  SINGAPOREAN</Text>
                                        <Text style={{ ...styles.textHel6 }}><Text style={styles.text}>☐</Text>  PR</Text>
                                        <Text style={{ ...styles.textHel6 }}><Text style={styles.text}>☐</Text>  FOREIGNER</Text>
                                    </View>
                                 
                                   
                                </View>
                                
                                
                               
                                <View style={{ flex: 1, ...styles.textHel6 }}>
                                    <Text style={{  ...styles.textHel6 }}> MARITAL STATUS</Text>
                                    <View style={{flexDirection:"row",justifyContent:"space-around"}}>
                                        <Text style={{ ...styles.textHel6 }}><Text style={styles.text}>☐</Text>  SINGLE</Text>
                                        <Text style={{ ...styles.textHel6 }}><Text style={styles.text}>☐</Text>  MARRIED</Text>
                                        <Text style={{ ...styles.textHel6 }}><Text style={styles.text}>☐</Text>  OTHERS</Text>
                                    </View>
                                </View>
                            </View>
                            <Text style={{ ...styles.textHel6, padding: "2px ", height: 20 ,borderTop:"0.5px solid #000"}}>NAME OF SPOUSE (according to identity card)</Text>
                       
                            <View style={{ flexDirection: "column", padding: "2px", justifyContent: "space-between", borderTop: "0.5px solid #000", }}>
                                <Text style={{ ...styles.textHel6 }}>HOME ADDRESS</Text>
                                <Text style={{ ...styles.textHel6 }}>{data.guarantor[0] ? data.guarantor[0].addressGuarantor : ' '}</Text>
                                <Text style={{ ...styles.textHel6 }}> </Text>
                               
                            </View>
                            <View style={{ flexDirection: "row", padding: "2px", justifyContent: "space-between", borderTop: "0.5px solid #000", height: 40 }}>
                                <Text style={{ flex: 1, ...styles.textHel6 }}>MAILING ADDRES (if different from above)</Text>
                               
                            </View>
                            <View style={{ flexDirection: "row", padding: "2px", justifyContent: "space-between", borderTop: "0.5px solid #000", height: 20 }}>
                                <View style={{ flex: 1, ...styles.textHel6 , flexDirection:'row' }}><Text style={{  ...styles.textHel6 ,marginRight:12}}> RESIDENCE</Text>
                                <View style={{flexDirection:"row",justifyContent:"space-between"}}>
                                                  
                                <Text style={{ ...styles.textHel6 ,marginRight:5}}><Text style={styles.text}>☐</Text>  OWN HOUSE </Text>
                                    <Text style={{ ...styles.textHel6,marginRight:5 }}><Text style={styles.text}>☐</Text>  RENTED</Text>
                                    <Text style={{ ...styles.textHel6 ,marginRight:5}}><Text style={styles.text}>☐</Text>  OTHERS</Text>
                                </View>
                                 
                                   
                                </View>
                                
                                
                                
                            </View>
                            <View style={{ flexDirection: "row", padding: "4px 2px", justifyContent: "space-between", borderTop: "0.5px solid #000", height: 40 }}>
                    
                                <View style={{ width:200, ...styles.textHel6 ,flexDirection:"column",justifyContent:"space-between",}}>
                                    <Text style={{ ...styles.textHel6 ,height:15,}}>  HOME:                    __________________________</Text>
                                    
                                    <Text style={{ ...styles.textHel6,height:15 }}>  OFFICE:                  __________________________</Text>
                                    
                                </View>
                                
                              

                                <View style={{ width:130, ...styles.textHel6 ,flexDirection:"column",justifyContent:"space-between", }}>
                                <Text style={{ ...styles.textHel6,height:15 }}>MOBILE:       _________________</Text>
                                <Text style={{ ...styles.textHel6, position: 'absolute', left:40 }}>{data.guarantor[0] && data.guarantor[0].contactNumberGuarantor}</Text>
                                <Text style={{ ...styles.textHel6 ,height:15}}>PAGER:        _________________</Text>
                                </View>

                               
                            </View>
                            <View style={{ padding:' 2px',height:20}}>
                            <Text style={{ ...styles.textHel6 }}>EMAIL ADDRESS:______________________________________________________________</Text>
                            <Text style={{ ...styles.textHel6, position: 'absolute', left:70 }}>{data.guarantor[0] && data.guarantor[0].emailIDGuarantor}</Text>
                            </View>
                    </View>
                    </View>
                    <View style={{ padding: '2.74px 6.79px', height:20,flexDirection:"row",justifyContent:"center", alignItems:"center", backgroundColor: '#000' }}>
                        <Text style={{ ...styles.textHelBold6, textAlign: "center", color: "#fff" }}>INCOME DECLARATION</Text>
                    </View>

                    <View style={{ flexDirection:"row", border:"0.5px solid #000"}}>
                        <View style={{flex:1,marginRight:5,flexDirection:"column"}}>
                            <View style={{ flexDirection: "row",height:30 ,borderRight:"0.5px solid #000", borderBottom:"0.5px solid #000"}}>
                                <View style={{ flex: 1,borderRight:"0.5px solid #000" }}>
                                    <Text style={styles.textHel8}>CURRENT EMPLOYER</Text>
                                    <Text style={styles.textHel8}>{data.employer}</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel8}>SELF EMPLOTYED</Text>
                                </View>
                            </View>
                     
                            <View style={{ flexDirection: "row",height:45 ,borderRight:"0.5px solid #000", borderBottom:"0.5px solid #000"}}>
                                <View style={{ flex: 1, }}>
                                    <Text style={styles.textHel8}>COMPANY ADDRESS</Text>
                                    <Text style={styles.textHel8}>{data.employmentAddress}</Text>
                                </View>
                                
                            </View>
                            <View style={{ flexDirection: "row",height:30 ,borderRight:"0.5px solid #000", borderBottom:"0.5px solid #000"}}>
                            <View style={{ flex: 1,borderRight:"0.5px solid #000" }}>
                                    <Text style={styles.textHel8}>DESIGNATION</Text>
                                    <Text style={styles.textHel8}>{data.jobTitle}</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel8}>SINCE WHEN/HOW LONG</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row",height:30 ,borderRight:"0.5px solid #000", borderBottom:"0.5px solid #000"}}>
                            <View style={{ flex: 1,borderRight:"0.5px solid #000" }}>
                                    <Text style={styles.textHel8}>NETT MONTHLY SALARY</Text>
                                    <Text style={styles.textHel8}>S${data.grossMonthlySalary}</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel8}>OTHER MONTHLY SALARY</Text>
                                    <Text style={styles.textHel8}>S$</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row",height:30 ,borderRight:"0.5px solid #000"}}>
                            <View style={{ flex: 1,borderRight:"0.5px solid #000" }}>
                                    <Text style={styles.textHel8}>NAME OF PREVIOUS EMPLOYER</Text>
                                    <Text style={styles.textHel8}>S$</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel8}>SINCE WHEN/HOW LONG</Text>
                                    <Text style={styles.textHel8}>MONTHLY PAYMENT</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{flex:1 ,flexDirection:"column", borderLeft:"0.5px solid #000"}}>
                            <View style={{ flexDirection: "row",height:30 , borderBottom:"0.5px solid #000"}}>
                                <View style={{ flex: 1,borderRight:"0.5px solid #000" }}>
                                    <Text style={styles.textHel8}>CURRENT EMPLOYER</Text>
                                    <Text style={styles.textHel8}>{data.guarantor[0] && data.guarantor[0].employerGuarantor}</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel8}>SELF EMPLOTYED</Text>
                                </View>
                            </View>
                     
                            <View style={{ flexDirection: "row",height:45 , borderBottom:"0.5px solid #000"}}>
                                <View style={{ flex: 1, }}>
                                    <Text style={styles.textHel8}>COMPANY ADDRESS</Text>
                                    <Text style={styles.textHel8}>{data.guarantor[0] && data.guarantor[0].employmentAddressGuarantor}</Text>
                                </View>
                                
                            </View>
                            <View style={{ flexDirection: "row",height:30 , borderBottom:"0.5px solid #000"}}>
                            <View style={{ flex: 1,borderRight:"0.5px solid #000" }}>
                                    <Text style={styles.textHel8}>DESIGNATION</Text>
                                    <Text style={styles.textHel8}>{data.guarantor[0] && data.guarantor[0].jobTitleGuarantor}</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel8}>SINCE WHEN/HOW LONG</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row",height:30 , borderBottom:"0.5px solid #000"}}>
                            <View style={{ flex: 1,borderRight:"0.5px solid #000" }}>
                                    <Text style={styles.textHel8}>NETT MONTHLY SALARY</Text>
                                    <Text style={styles.textHel8}>S${data.guarantor[0] && data.guarantor[0].grossMonthlySalaryGuarantor}</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel8}>OTHER MONTHLY SALARY</Text>
                                    <Text style={styles.textHel8}>S$</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row",height:30 }}>
                            <View style={{ flex: 1,borderRight:"0.5px solid #000" }}>
                                    <Text style={styles.textHel8}>NAME OF PREVIOUS EMPLOYER</Text>
                                    <Text style={styles.textHel8}>S$</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel8}>SINCE WHEN/HOW LONG</Text>
                                    <Text style={styles.textHel8}>MONTHLY PAYMENT</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                     
                   
                    
                    <View style={{ padding: '2.74px 6.79px', height:20,flexDirection:"row",justifyContent:"center", alignItems:"center", backgroundColor: '#000' }}>
                        <Text style={{ ...styles.textHelBold6, textAlign: "center", color: "#fff" }}>FINANCIAL COMMENTS</Text>
                    </View>
               
                    <View style={{ flexDirection:"row"}}>
                     <View style={{flex:1,marginRight:5,flexDirection:"column"}}>
                            <View style={{ flexDirection: "row",height:30 ,border:"0.5px solid #000"}}>
                                <View style={{ flex: 1,  }}>
                                    <Text style={styles.textHel7}>FINANCIAL INSTITUTION / TYPE OF LOAN</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel7}>MONTHLY REPAYMENT</Text>
                                </View>
                            </View>
                     
                            <View style={{ flexDirection: "row",height:30 ,border:"0.5px solid #000"}}>
                                <View style={{ flex: 1,  }}>
                                    <Text style={styles.textHel7}>1.</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel7}>$</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row",height:30 ,border:"0.5px solid #000"}}>
                                <View style={{ flex: 1,  }}>
                                    <Text style={styles.textHel7}>2.</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel7}>$</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row",height:30 ,border:"0.5px solid #000"}}>
                                <View style={{ flex: 1,  }}>
                                    <Text style={styles.textHel7}>3.</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel7}>$</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row",height:30 ,border:"0.5px solid #000"}}>
                                <View style={{ flex: 1,  }}>
                                    <Text style={styles.textHel7}> </Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel7}> </Text>
                                </View>
                            </View>
                         
                       
                     </View>
                     <View style={{flex:1,flexDirection:"column"}}>
                            <View style={{ flexDirection: "row",height:30 ,border:"0.5px solid #000"}}>
                                <View style={{ flex: 1,  }}>
                                    <Text style={styles.textHel7}>FINANCIAL INSTITUTION / TYPE OF LOAN</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel7}>MONTHLY REPAYMENT</Text>
                                </View>
                            </View>
                     
                            <View style={{ flexDirection: "row",height:30 ,border:"0.5px solid #000"}}>
                                <View style={{ flex: 1,  }}>
                                    <Text style={styles.textHel7}>1.</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel7}>$</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row",height:30 ,border:"0.5px solid #000"}}>
                                <View style={{ flex: 1,  }}>
                                    <Text style={styles.textHel7}>2.</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel7}>$</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row",height:30 ,border:"0.5px solid #000"}}>
                                <View style={{ flex: 1,  }}>
                                    <Text style={styles.textHel7}>3.</Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel7}>$</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row",height:30 ,border:"0.5px solid #000"}}>
                                <View style={{ flex: 1,  }}>
                                    <Text style={styles.textHel7}> </Text>
                                </View>
                                <View style={{ width:120, }}>
                                    <Text style={styles.textHel7}> </Text>
                                </View>
                            </View>
                         
                       
                     </View>
                    </View>
                    
                   

                    
                   
                    

                </View>
            </View>
             

        </Page>
        <Page size="A4" style={pStyles.page2}>
            <View style={{border:'0.5px solid #000'}}>
                <View style={{backgroundColor:'#000', justifyContent:'center', alignItems:'center', paddingTop:4.5, paddingBottom:4.5}}>
                    <Text style={{...styles.textMerBold6, color:'#fff'}}>ARTICULARS OF GOOD</Text>
                </View>
                <View >
                    <View style={{flexDirection:'row'}}>
                        <View style={{width:'10%',padding:'10.18px 7.91px 11.89px 3.65px'}}>
                            <Text style={styles.textMer6}>GOODs</Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'50%', borderRight:'0.5px solid #000',padding:'10.18px 7.91px 11.89px 3.65px'}}>
                            <Text style={styles.textMer6}><Text style={styles.text}>☐</Text>  NEW</Text>
                            <View>
                            <Text style={styles.textMer6}><Text style={styles.text}>☐</Text>   USED</Text>
                            <Text style={{...styles.textMer6, position: 'absolute'}}>x</Text>
                            </View>
                            <Text style={styles.textMer6}><Text style={styles.text}>☐</Text>   AUTO</Text>
                            <Text style={styles.textMer6}><Text style={styles.text}>☐</Text>   MANUAL</Text>
                            <Text style={styles.textMer6}><Text style={styles.text}>☐</Text>   SALOON</Text>
                            <Text style={styles.textMer6}><Text style={styles.text}>☐</Text>   COMMERCIAL</Text>
                        </View>
                        <View  style={{width:'15%', borderRight:'0.5px solid #000', padding:'10.18px 7.91px 11.89px 3.65px'}}>
                            <Text style={styles.textMer6}>CASH PRICE</Text>
                        </View>
                        <View  style={{padding:'10.18px 7.91px 11.89px 3.65px',borderBottom:'0.5px solid #000',width:'25%'}}>
                            <Text style={styles.textMer6}>$</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',}}>
                        <View style={{width:'10%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>DESCRIPTIONS OF GOODS</Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'50%', borderBottom:'0.5px solid #000', borderRight:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            
                        </View>
                        <View  style={{width:'15%', borderRight:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>LESS: Deposit (                    %)</Text>
                        </View>
                        <View  style={{padding:'10.18px 7.91px 3.99px 3.65px',borderBottom:'0.5px solid #000', width:'25%'}}>
                            <Text style={styles.textMer6}>$</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row', height:27}}>
                        <View style={{width:'10%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>MAKE / MODEL</Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'50%',borderBottom:'0.5px solid #000', borderRight:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>{data.vehicleMake}</Text>
                        </View>
                        <View  style={{width:'15%', borderRight:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>AMOUNT OF FINANCE</Text>
                        </View>
                        <View  style={{padding:'10.18px 7.91px 3.99px 3.65px',borderBottom:'0.5px solid #000', width:'25%'}}>
                            <Text style={styles.textMer6}>$</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row', height:27}}>
                        <View style={{width:'10%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}></Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'50%', borderBottom:'0.5px solid #000',borderRight:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                        <Text style={styles.textMer6}>{data.vehicleModel}</Text>
                        </View>
                        <View  style={{width:'15%', borderRight:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>TERM CHARGES</Text>
                        </View>
                        <View  style={{padding:'10.18px 7.91px 3.99px 3.65px',borderBottom:'0.5px solid #000', width:'25%'}}>
                            <Text style={styles.textMer6}>$</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',}}>
                        <View style={{width:'10%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>REGISTRATION NO.</Text>
                        </View>

                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'23%', borderBottom:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                             <Text style={styles.textMer6}>{data.vehicleNumber}</Text>
                        </View>
                        <View style={{width:'7%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>EX-REGN NO.</Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'20%', borderBottom:'0.5px solid #000',borderRight:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            
                        </View>
                        <View  style={{width:'15%', borderRight:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>BALANCE PAYABLE</Text>
                        </View>
                        <View  style={{padding:'10.18px 7.91px 3.99px 3.65px',borderBottom:'0.5px solid #000', width:'25%'}}>
                            <Text style={styles.textMer6}>$</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',}}>
                        <View style={{width:'10%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>ORIGINAL REGN DATE</Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'23%', borderBottom:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>{moment(data.firstRegistrationDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>
                        </View>
                        <View style={{width:'7%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}></Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'20%', borderRight:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            
                        </View>
                        <View  style={{width:'15%', borderRight:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>HIRE PURCHASE PRICE</Text>
                        </View>
                        <View  style={{padding:'10.18px 7.91px 3.99px 3.65px',borderBottom:'0.5px solid #000', width:'25%'}}>
                            <Text style={styles.textMer6}>$</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',}}>
                        <View style={{width:'10%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>CHASSIS NO.</Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'23%', borderBottom:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>{data.chassisNumber}</Text>
                        </View>
                        <View style={{width:'7%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>ENGINE NO.</Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'20%', borderRight:'0.5px solid #000',borderBottom:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>{data.engineNumber}</Text>
                        </View>
                        <View  style={{width:'15%', borderRight:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}></Text>
                        </View>
                        <View  style={{padding:'10.18px 7.91px 3.99px 3.65px',borderBottom:'0.5px solid #000', width:'25%'}}>
                            <Text style={styles.textMer6}></Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',}}>
                        <View style={{width:'10%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>MFG YEAR</Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'23%', borderBottom:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                        <Text style={styles.textMer6}>{data.vehicleManufactured}</Text>
                        </View>
                        <View style={{width:'7%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>ENGINE CAP.</Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'20%', borderRight:'0.5px solid #000',borderBottom:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            
                        </View>
                        <View  style={{width:'15%', borderRight:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>Repayment Period</Text>
                        </View>
                        <View  style={{padding:'10.18px 7.91px 3.99px 3.65px',borderBottom:'0.5px solid #000', width:'25%', alignItems:'flex-end'}}>
                            <Text style={styles.textMer6}>{data.tenure} months</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',}}>
                        <View style={{width:'10%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>PARF ELIGIBILITY</Text>
                        </View>
                        <View style={{flexDirection:'row', width:'23%', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}><Text style={styles.text}>☐</Text>  Yes</Text>
                            <Text style={{...styles.textMer6, marginLeft:'20px'}}><Text style={styles.text}>☐</Text>  No</Text>
                        </View>
                        <View style={{width:'7%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>OMV</Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'20%', borderRight:'0.5px solid #000',borderBottom:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            
                        </View>
                        <View  style={{width:'15%', borderRight:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>Interest Rate</Text>
                        </View>
                        <View  style={{padding:'10.18px 7.91px 3.99px 3.65px',borderBottom:'0.5px solid #000', width:'25%', alignItems:'flex-end'}}>
                            <Text style={styles.textMer6}>% p.a.</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',}}>
                        <View style={{width:'10%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>COE:</Text>
                        </View>
                        <View style={{flexDirection:'row', width:'23%', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            
                        </View>
                        <View style={{width:'7%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>S$</Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'20%', borderRight:'0.5px solid #000',borderBottom:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            
                        </View>
                        <View  style={{width:'15%', borderRight:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>Monthly instalments</Text>
                        </View>
                        <View  style={{padding:'10.18px 7.91px 3.99px 3.65px',borderBottom:'0.5px solid #000', width:'25%', }}>
                            <Text style={styles.textMer6}>$.</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',}}>
                        <View style={{width:'10%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}></Text>
                        </View>
                        <View style={{flexDirection:'row', width:'23%', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            
                        </View>
                        <View style={{width:'7%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}></Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'20%', borderRight:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            
                        </View>
                        <View  style={{width:'15%', borderRight:'0.5px solid #000',borderBottom:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>Final Instalment</Text>
                        </View>
                        <View  style={{padding:'10.18px 7.91px 3.99px 3.65px',borderBottom:'0.5px solid #000', width:'25%', }}>
                            <Text style={styles.textMer6}>$.</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',}}>
                        <View style={{width:'10%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>INSURANCE CO</Text>
                        </View>
                        <View style={{flexDirection:'row', width:'23%',borderBottom:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            
                        </View>
                        <View style={{width:'7%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}></Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'20%', borderRight:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            
                        </View>
                        <View  style={{width:'15%', borderRight:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>Goods were inspected by:</Text>
                        </View>
                        <View  style={{padding:'10.18px 7.91px 3.99px 3.65px',width:'25%', }}>
                            <Text style={styles.textMer6}>Please sign/ Stamp here</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',}}>
                        <View style={{width:'10%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>COVER NOTE NO.</Text>
                        </View>
                        <View style={{flexDirection:'row', width:'23%',borderBottom:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            
                        </View>
                        <View style={{width:'7%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}></Text>
                        </View>
                        <View style={{flexDirection:'row', justifyContent:'space-between', width:'20%', borderRight:'0.5px solid #000',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            
                        </View>
                        <View  style={{width:'15%', borderRight:'0.5px solid #000',borderBottom:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}></Text>
                        </View>
                        <View  style={{padding:'10.18px 7.91px 3.99px 3.65px',borderBottom:'0.5px solid #000', width:'25%', }}>
                            <Text style={styles.textMer6}></Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row', }}>
                        <View style={{width:'60%',borderRight:'0.5px solid #000'}}>
                            <View style={{flexDirection:'row',}}>
                                <View style={{width:'16.5%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                                    <Text style={styles.textMer6}>SUM INSURED</Text>
                                </View>
                                <View style={{flexDirection:'row', width:'39%',borderBottom:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px', alignItems:'center'}}>
                                    <Text style={styles.textMer6}>S$</Text>
                                    <Text style={{...styles.textMer6, marginLeft:'40px'}}>COM/TPFT</Text>
                                </View>
                                <View style={{width:'7%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                                    <Text style={styles.textMer6}></Text>
                                </View>
                                <View style={{flexDirection:'row', justifyContent:'space-between', width:'20%',padding:'10.18px 7.91px 3.99px 3.65px'}}>

                                </View>
                            </View>
                            <View style={{flexDirection:'row',}}>
                                <View style={{width:'16%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                                    <Text style={styles.textMer6}>EXPIRY DATE</Text>
                                </View>
                                <View style={{flexDirection:'row', width:'39%',borderBottom:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px'}}>

                                </View>
                                <View style={{width:'7%',padding:'10.18px 7.91px 3.99px 3.65px'}}>
                                    <Text style={styles.textMer6}></Text>
                                </View>
                                <View style={{flexDirection:'row', justifyContent:'space-between', width:'20%', padding:'10.18px 7.91px 3.99px 3.65px'}}>

                                </View>
                            </View>
                            <View style={{height:5}}></View>
                        </View>
                        <View  style={{width:'15%', borderRight:'0.5px solid #000',borderBottom:'0.5px solid #000', padding:'10.18px 7.91px 3.99px 3.65px'}}>
                            <Text style={styles.textMer6}>Location of Goods (Address where goods are to be located)</Text>
                        </View>
                        <View  style={{padding:'10.18px 7.91px 3.99px 3.65px',borderBottom:'0.5px solid #000', width:'25%', }}>
                            <Text style={styles.textMer6}></Text>
                        </View>
                    </View>
                    
                </View>
                <View style={{backgroundColor:'#000', justifyContent:'center', alignItems:'center', paddingTop:4.5, paddingBottom:4.5}}>
                    <Text style={{...styles.textMerBold6, color:'#fff'}}>DECLARATION</Text>
                </View>
                <View style={{padding:'14px 2px 0px 2px', borderBottom:'0.5px solid #000'}}>
                    <Text style={styles.textMer6}>I/We hereby request you to purchase from _____________________________ and to let on hire to me/us upon hte terms and conditions of your standard hire-purchase agreement</Text>
                    <Text style={styles.textMer6}>the goods more particularly described above. I/We hereby declare that (1) the aforesaid particulars and answers given by me/us ot be true and that they shal be the basis on which the said</Text>
                    <Text style={styles.textMer6}>agreement shall be made (2) I/We have not been a bankrupt nor assigned my/our estate for the benefit of creditors (3) I/We wil repay the finance ni accordance with the terms and conditions</Text>
                    <Text style={styles.textMer6}>of the agreement fi my/our application is approved (4) the aforesaid information is submitted ot induce you to extend finance to me/us ni consideration of you agreeing to consider my/our</Text>
                    <Text style={styles.textMer6}>application for a hire purchase loan. I/We agree to pay the commitment fee of S$50 (Dollars fifty only) I/We further agree that the commitment fee is not refundable fi the hire purchase loan is</Text>
                    <Text style={styles.textMer6}>aborted for any reason whatever provided that fi the hire purchase loan si proceeded with the commitment fee shal be treated as part-payment of the 1st instalment of the hire purchase loan.</Text>
                    <Text style={styles.textMer6}> </Text>
                    <Text style={styles.textMer6}>I/We further agree that the above term charges are subject ot change at your discretion fi after 4 (four) weeks from the date of application, the Hire Purchase si not proceeded with</Text>
                    <Text style={styles.textMer6}> </Text>
                    <Text style={styles.textMer6}>If the goods  in the hire purchase is a motor vehicle, 1/we represent and warrant to you that the vehicle will only be:</Text>
                    <Text style={styles.textMer6}> 1.      used for lawful purpose;                                                  2.      driven by persons who are licensed to drive the class of vehicle; and                               3.driven by me or my/our employees.</Text>
                    <Text style={styles.textMer6}> </Text>
                    <Text style={styles.textMer6}>I authorise you ot disclose, at your sole discretion, ot any credit bureau of which you are a member or subscriber or ot any of their members or subscribers or agents, any information relating to</Text>
                    <Text style={styles.textMer6}>this application and loan account that i may have with you, For assessing any credit worthiness, you may at any time and on any number of times, obtain any information relating to me from </Text>
                    <Text style={styles.textMer6}>any credit bureau and i agree to them disclosing such information to you.</Text>
                </View>
                <View style={{borderBottom:'0.5px solid #000', flexDirection:'row'}}>
                    <View style={{width:'50%', padding:'2px 1px'}}>
                        <Text style={styles.textMer6}>Main Applicant <Text style={styles.textMerIta}>(Registered Owner)</Text></Text>
                        <Text style={{...styles.textMer6, marginTop:50}}>Signature</Text>
                        <Text style={styles.textMer6}>(Name/Date)</Text>
                    </View>
                    <View style={{width:'50%', padding:'2px 1px'}}>
                        <Text style={styles.textMer6}>Guarantor <Text style={styles.textMerIta}>(if applicable)</Text></Text>
                        <Text style={{...styles.textMer6, marginTop:50}}>Signature</Text>
                        <Text style={styles.textMer6}>(Name/Date)</Text>
                    </View>
                </View>
                <View style={{borderBottom:'0.5px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'2px 1px'}}>
                        <Text style={styles.textMer6}>REMARKS F(OR OF FCI E USE)</Text>
                        <Text style={{...styles.textMer6, marginTop:50}}>RECOMMENDED / DATE</Text>
                    </View>
                    <View style={{width:'100%', padding:'2px 1px'}}>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={{...styles.textMer6, marginTop:50}}>CONCURRED BY</Text>
                    </View>
                    <View style={{width:'100%', padding:'2px 1px'}}>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={{...styles.textMer6, marginTop:50}}>APPROVED BY</Text>
                    </View>
                </View>
                <View style={{borderBottom:'0.5px solid #000', flexDirection:'row', padding:'2px 1px'}}>
                    <Text style={styles.textMer6}>For Corporate Applicant, complete hte following nad indicate Name and NRIC/Passport No. of Authorised Signatory in the Signature box.</Text>
                </View>
                <View style={{borderBottom:'0.5px solid #000', flexDirection:'row'}}>
                    <View style={{width:'35%', padding:'2px 1px', borderRight:'0.5px solid #000'}}>
                        <Text style={styles.textMer6}>Name &NRIC</Text>
                    </View>
                    <View style={{width:'20%', padding:'2px 1px', borderRight:'0.5px solid #000'}}>
                        <Text style={styles.textMer6}>Designation</Text>
                    </View>
                    <View style={{width:'45%', padding:'2px 1px'}}>
                        <Text style={styles.textMer6}>Residential Address</Text>
                    </View>
                </View>
                <View style={{borderBottom:'0.5px solid #000', flexDirection:'row'}}>
                    <View style={{width:'35%', padding:'2px 1px', borderRight:'0.5px solid #000'}}>
                        <Text style={styles.textMer6}>1)</Text>
                    </View>
                    <View style={{width:'20%', padding:'2px 1px', borderRight:'0.5px solid #000'}}>
                        <Text style={styles.textMer6}> </Text>
                    </View>
                    <View style={{width:'45%', padding:'2px 1px'}}>
                        <Text style={styles.textMer6}> </Text>
                    </View>
                </View>
                <View style={{borderBottom:'0.5px solid #000', flexDirection:'row'}}>
                    <View style={{width:'35%', padding:'2px 1px', borderRight:'0.5px solid #000'}}>
                        <Text style={styles.textMer6}>2)</Text>
                    </View>
                    <View style={{width:'20%', padding:'2px 1px', borderRight:'0.5px solid #000'}}>
                        <Text style={styles.textMer6}> </Text>
                    </View>
                    <View style={{width:'45%', padding:'2px 1px'}}>
                        <Text style={styles.textMer6}> </Text>
                    </View>
                </View>
                <View style={{borderBottom:'0.5px solid #000', flexDirection:'row'}}>
                    <View style={{width:'35%', padding:'2px 1px', borderRight:'0.5px solid #000'}}>
                        <Text style={styles.textMer6}>3)</Text>
                    </View>
                    <View style={{width:'20%', padding:'2px 1px', borderRight:'0.5px solid #000'}}>
                        <Text style={styles.textMer6}> </Text>
                    </View>
                    <View style={{width:'45%', padding:'2px 1px'}}>
                        <Text style={styles.textMer6}> </Text>
                    </View>
                </View>
                <View style={{borderBottom:'0.5px solid #000', flexDirection:'row'}}>
                    <View style={{width:'35%', padding:'2px 1px', borderRight:'0.5px solid #000'}}>
                        <Text style={styles.textMer6}>4)</Text>
                    </View>
                    <View style={{width:'20%', padding:'2px 1px', borderRight:'0.5px solid #000'}}>
                        <Text style={styles.textMer6}> </Text>
                    </View>
                    <View style={{width:'45%', padding:'2px 1px'}}>
                        <Text style={styles.textMer6}> </Text>
                    </View>
                </View>
                <View style={{flexDirection:'row'}}>
                    <View style={{width:'35%', padding:'2px 1px', borderRight:'0.5px solid #000'}}>
                        <Text style={styles.textMer6}>5)</Text>
                    </View>
                    <View style={{width:'20%', padding:'2px 1px', borderRight:'0.5px solid #000'}}>
                        <Text style={styles.textMer6}> </Text>
                    </View>
                    <View style={{width:'45%', padding:'2px 1px'}}>
                        <Text style={styles.textMer6}> </Text>
                    </View>
                </View>
            </View>
        </Page>
        <Page size="A4" style={pStyles.page3}>
            <Text style={{...styles.textMerBold8, marginLeft:15}}>Important  -  please read Hng Leong Finance’s  From & Notice relating to the PDPA before signing</Text>
            <View style={{border:'0.5px solid #000'}}>
                <View style={{borderBottom:'0.5px solid #000', flexDirection:'row'}}>
                    <View style={{padding:'3px 6px', borderRight:'0.5px solid #000', width:'50%'}}>
                        <Text style={styles.textMerBold6}>For Hirer (Individual)</Text>
                    </View>
                    <View style={{padding:'3px 6px', width:'50%'}}>
                        <Text style={styles.textMerBold6}>For Guarantor to Hirer (Individual)</Text>
                    </View>
                </View>
                <View style={{borderBottom:'0.5px solid #000', flexDirection:'row'}}>
                    <View style={{padding:'5.83px 15px 10px 10px', borderRight:'0.5px solid #000', width:'50%'}}>
                        <Text style={styles.textMerBold8}>Part I- PDPA Declaration and Marketing Consent</Text>
                        <Text style={styles.textMer8Normal}>I agree that the Company may collect, use and disclose my</Text>
                        <Text style={styles.textMer8Normal}>personal data in the manner described above and warrant</Text>
                        <Text style={styles.textMer8Normal}>and confirm that any personal data I nave or in the tuture</Text>
                        <Text style={styles.textMer8Normal}>supply or provide to the Company may also be collected,</Text>
                        <Text style={styles.textMer8Normal}>used and disclosed in the manner described above.</Text>
                        <Text style={styles.textMer8Normal}>Ialso requestthat the Company market to me products</Text>
                        <Text style={styles.textMer8Normal}>and services of the Company, especially promotions on:</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>(Tick the below relevant options, or leave them blank if you</Text>
                        <Text style={styles.textMer8Normal}>do not want to receive such marketing information)</Text>
                        <Text style={styles.textMer8Normal}>• Loans Products                    [            ]</Text>
                        <Text style={styles.textMer8Normal}>• Deposits Products            [            ]</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>and at my telepnone numbers wnich the Company nas</Text>
                        <Text style={styles.textMer8Normal}>or collects from time to time, through the following means:</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>(Tick the below relevant options, or leave them blank if you</Text>
                        <Text style={styles.textMer8Normal}>do not want to receive such marketing information through</Text>
                        <Text style={styles.textMer8Normal}>your telephone numbers - the Company may still send such</Text>
                        <Text style={styles.textMer8Normal}>marketing information to you by post)</Text>
                        <Text style={styles.textMer8Normal}>• Text messages !          [          ]</Text>
                        <Text style={styles.textMer8Normal}>• Voice calls                        [          ]</Text>
                        <Text style={styles.textMer8Normal}>• Fax messages               [          ]</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMerBold8}>Part II - Tax Declaration</Text>
                        <Text style={styles.textMer8Normal}>declare and confirm that to the best of my knowledge,</Text>
                        <Text style={styles.textMer8Normal}>have not committed or been convicted of tax evasion crimes</Text>
                        <Text style={styles.textMer8Normal}>under Singapore tax laws or any similar serious tax crimes</Text>
                        <Text style={styles.textMer8Normal}>under any foreign law.</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMerBold8}>Part III- Hire Purchase Declaration</Text>
                        <Text style={styles.textMer8Normal}>Hire Purchase of</Text>
                        <Text style={styles.textMer8Normal}>I declare that Idid not obtain any credit facility for the hiring</Text>
                        <Text style={styles.textMer8Normal}>of the above motor vehicle.</Text>
                        <Text style={styles.textMerIta8}>If the above declaration is incorrect, please delete the</Text>
                        <Text style={styles.textMerIta8}>declaration and state the amount of the credit facility below:</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>...................................................................................................</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>Name:........................................................................................</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>NRIC/Passport No:.....................................................................</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>Signature:..................................................................................</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>Date:..........................................................................................</Text>
                    </View>
                    <View style={{padding:'3px 6px', width:'50%'}}>
                    <Text style={styles.textMerBold8}>Part I- PDPA Declaration and Marketing Consent</Text>
                        <Text style={styles.textMer8Normal}>I agree that the Company may collect, use and disclose my</Text>
                        <Text style={styles.textMer8Normal}>personal data in the manner described above and warrant</Text>
                        <Text style={styles.textMer8Normal}>and confirm that any personal data I nave or in the tuture</Text>
                        <Text style={styles.textMer8Normal}>supply or provide to the Company may also be collected,</Text>
                        <Text style={styles.textMer8Normal}>used and disclosed in the manner described above.</Text>
                        <Text style={styles.textMer8Normal}>Ialso requestthat the Company market to me products</Text>
                        <Text style={styles.textMer8Normal}>and services of the Company, especially promotions on:</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>(Tick the below relevant options, or leave them blank if you</Text>
                        <Text style={styles.textMer8Normal}>do not want to receive such marketing information)</Text>
                        <Text style={styles.textMer8Normal}>• Loans Products                    [            ]</Text>
                        <Text style={styles.textMer8Normal}>• Deposits Products            [            ]</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>and at my telepnone numbers wnich the Company nas</Text>
                        <Text style={styles.textMer8Normal}>or collects from time to time, through the following means:</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>(Tick the below relevant options, or leave them blank if you</Text>
                        <Text style={styles.textMer8Normal}>do not want to receive such marketing information through</Text>
                        <Text style={styles.textMer8Normal}>your telephone numbers - the Company may still send such</Text>
                        <Text style={styles.textMer8Normal}>marketing information to you by post)</Text>
                        <Text style={styles.textMer8Normal}>• Text messages !          [          ]</Text>
                        <Text style={styles.textMer8Normal}>• Voice calls                        [          ]</Text>
                        <Text style={styles.textMer8Normal}>• Fax messages               [          ]</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMerBold8}>Part II - Tax Declaration</Text>
                        <Text style={styles.textMer8Normal}>declare and confirm that to the best of my knowledge,</Text>
                        <Text style={styles.textMer8Normal}>have not committed or been convicted of tax evasion crimes</Text>
                        <Text style={styles.textMer8Normal}>under Singapore tax laws or any similar serious tax crimes</Text>
                        <Text style={styles.textMer8Normal}>under any foreign law.</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>Name:........................................................................................</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>NRIC/Passport No:.....................................................................</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>Signature:..................................................................................</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>Date:..........................................................................................</Text>
                    </View>
                </View>
                
            </View>
            <Text style={{...styles.textMerBold8, marginLeft:15, marginTop:1.2}}>For completion where the vehicle will be registered in the name of a business or company</Text>
            <View style={{border:'0.5px solid #000'}}>    
                <View style={{borderBottom:'0.5px solid #000', flexDirection:'row'}}>
                    <View style={{padding:'3px 6px', borderRight:'0.5px solid #000', width:'50%'}}>
                        <Text style={styles.textMerBold6}>For Hirer (Corporate)</Text>
                    </View>
                    <View style={{padding:'3px 6px', width:'50%'}}>
                        <Text style={styles.textMerBold6}>For Guarantor to Hirer (Corporate)</Text>
                    </View>
                </View>
                <View style={{borderBottom:'0.5px solid #000', flexDirection:'row'}}>
                    <View style={{padding:'5.83px 15px 10px 10px', borderRight:'0.5px solid #000', width:'50%'}}>
                        <Text style={styles.textMerBold8}>Part I- Tax Declaration Tax Declaration</Text>
                        <Text style={styles.textMer8Normal}>I declare and confirm on behalf of............................................</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>.................................................................................................</Text>
                        <Text style={styles.textMer8Normal}>(“ the hirer Company have not committed or been convicted</Text>
                        <Text style={styles.textMer8Normal}>of tax evasion crimes under Singapore Tax laws or any similar</Text>
                        <Text style={styles.textMer8Normal}>serious tax crimes under any foreign law.</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMerBold8}>Part II - Hire Purchase Declaration</Text>
                        <Text style={styles.textMer8Normal}>NRIC/Passport No:</Text>
                        <Text style={styles.textMer8Normal}>Hire Purchase of</Text>
                        <Text style={styles.textMer8Normal}>I declare and confirm on behalf of the Hirer Company that</Text>
                        <Text style={styles.textMer8Normal}>Signature:</Text>
                        <Text style={styles.textMer8Normal}>the Hirer Company did not obtain any credit facility for the</Text>
                        <Text style={styles.textMer8Normal}>hiring of the above motor vehicle.</Text>
                        <Text style={styles.textMer8Normal}>Date:..</Text>
                        <Text style={styles.textMer8Normal}>If the above declaration is incorrect, please delete the</Text>
                        <Text style={styles.textMer8Normal}>declaration and state the amount of the credit facility below:</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>................................................................................................</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>Name:........................................................................................</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>NRIC/Passport No:.....................................................................</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>Signature:..................................................................................</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>Date:..........................................................................................</Text>
                    </View>
                    <View style={{padding:'3px 6px', width:'50%'}}>
                        <Text style={styles.textMerBold8}>Tax Declaration</Text>
                        <Text style={styles.textMer8Normal}>I declare and confirm that to teh best if my knowledge,</Text>
                        <Text style={styles.textMer8Normal}>I have not committed or been convicted of tax evasion</Text>
                        <Text style={styles.textMer8Normal}>crimes under Singapore or been convicted to tax evasion</Text>
                        <Text style={styles.textMer8Normal}>tax crimes under Singapore tax laws or any similar serious</Text>
                        <Text style={styles.textMer8Normal}>tax crimes under any foreign law.</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>Name:........................................................................................</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>NRIC/Passport No:.....................................................................</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>Signature:..................................................................................</Text>
                        <Text style={styles.textMer6}> </Text>
                        <Text style={styles.textMer8Normal}>Date:..........................................................................................</Text>
                    </View>
                </View>
            </View>
        </Page>
    </Document>
</>)


const HongLeon = React.memo(({ isSave, setPdf, data }:Props) => {

    return (
        <BlobProvider document={isSave? <Doc1 data={data} dob={moment(data.dob,'YYYY-MM-DD').format('DD/MM/YYYY')} 
        dobGuarantor={moment(data.guarantor[0]?.dobGuarantor,'YYYY-MM-DD').format('DD/MM/YYYY')}/>:<></>} >
            {({blob})=>{
                    let file;
                    file = blob && new File([blob as Blob], 'hongleong.pdf');
                    data && setPdf(file)

                return <></>;
            }}

        </BlobProvider>
    )
  });

export default HongLeon;