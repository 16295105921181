import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
// Customizable Area Ends

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openModal:boolean;
  isGroup: boolean;
  message: string;
  selectedImage: File | null;
  rowIndividual: {customerName: string,customerId: number, dob: string, phoneNumber: string, email: string, interestedService: string}[];
  pageCustomerBroadcast: number;
  listCheckBroadcast: boolean[];
  currentPageCustomerBroadcast: number;
  isErrorSelectBroadcast: boolean;
  isErrorInputBroadcast: boolean;
  errorImageString: string;
  isSelectAllBroadcast: boolean;
  selectedCustomerBroadcast: any[];

  listGroupBroadcast: any;
  selectedGroup: any;

  valueSearchCusNameCRMBroadcast: string;
  valueSearchPhoneCRMBroadcast: string;
  valueSearchEmailCRMBroadcast: string;
  valueSearchServiceCRMBroadcast: string;

  valueSearchCRMBroadcast: string;
  searchAttributeCRMBroadcast: string;
  listSearchCustomerNameCRMBroadcast: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NewBroadcastController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCRMListBroadcastId: any;
  getGroupListBroadcastId: any;
  searchCrmBroadcastId: any;
  createBroadcastId: any;
  searchCustomerNameCRMBroadcastId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      openModal: false,
      message: '',
      isGroup: false,
      selectedImage: null,
      rowIndividual: [],
      pageCustomerBroadcast: 0,
      listCheckBroadcast: [false, false, false, false, false, false, false, false, false, false, ],
      currentPageCustomerBroadcast: 1,
      isErrorSelectBroadcast: false,
      isErrorInputBroadcast: false,
      isSelectAllBroadcast: false,
      selectedCustomerBroadcast: [],
      errorImageString:'',
      listGroupBroadcast: [],
      selectedGroup: null,
      valueSearchCusNameCRMBroadcast: '',
      valueSearchPhoneCRMBroadcast: '',
      valueSearchEmailCRMBroadcast: '',
      valueSearchServiceCRMBroadcast: '',
    
      valueSearchCRMBroadcast: '',
      searchAttributeCRMBroadcast: '',
      listSearchCustomerNameCRMBroadcast: []
      // Customizable Area End,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    
    switch (apiRequestCallId) {
      case this.getCRMListBroadcastId:
        this.getCRMListBroadcastReceiver(responseJson);
        break;
      case this.getGroupListBroadcastId:
        this.getGroupListBroadcastReceiver(responseJson);
        break;
      case this.searchCrmBroadcastId:
        this.getCRMListBroadcastReceiver(responseJson);
        break;
      case this.createBroadcastId:
        this.createBroadcastReceiver(responseJson);
        break;
      case this.searchCustomerNameCRMBroadcastId:
        this.searchCustomerNameCRMBroadcastReceiver(responseJson);
        break;
      default:
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getCRMListBroadcast(1);
    this.getGroupListBroadcast();
  }

  handleSwitchTab = (tab: number) => {
    this.setState({isGroup: tab !== 1})
  }

  handleImageChange = (event: any) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
        const selectedFile = selectedFiles[0];
        const validImageTypes = ['image/jpeg', 'image/png'];

        if (validImageTypes.includes(selectedFile.type)) {
            this.setState({ selectedImage: selectedFile });
        }
    }
};

  handleRemoveImage = ()=>{
    this.setState({selectedImage:null})
  }

  handleChangePageCustomerBroadcast=(_event: any, value:any) =>{
    if(this.state.valueSearchCRMBroadcast.length > 0){
      this.searchCRMBroadcast(value);
    } else {
      this.getCRMListBroadcast(value);
    }
    this.setState({currentPageCustomerBroadcast: value});
  }

  getCRMListBroadcast = async (page: number) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessageBroadcast = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageBroadcast.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageBroadcast.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.crmEndPoint}?page=${page}&per_page=10`
    );
    requestMessageBroadcast.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getCRMListBroadcastId = requestMessageBroadcast.messageId;
    runEngine.sendMessage(requestMessageBroadcast.id, requestMessageBroadcast);
  }

  getCRMListBroadcastReceiver = (responseJson: any) => {
    const dataNew = responseJson.data.data.map((customer:any) => ({
      customerId:customer.id,
      customerName: customer.attributes.name,
      dob: customer.attributes.date_of_birth,
      phoneNumber: customer.attributes.contact_number.toString(),
      email: customer.attributes.email,
      interestedService: customer.attributes.services.map((string: string) => string.charAt(0).toUpperCase() + string.slice(1)).join(', ')
    }));
    this.setState({rowIndividual: dataNew, pageCustomerBroadcast: Math.ceil(responseJson.pagination.total_count/10)});
    if((responseJson.pagination.current_page-1)*10 + responseJson.pagination.count > this.state.listCheckBroadcast.length){
      dataNew.map(()=> this.setState({listCheckBroadcast: [...this.state.listCheckBroadcast, false]}))
    }
  }

  shouldComponentUpdate(_nextProps: Readonly<Props>, nextState: Readonly<S>, _nextContext: any): boolean {
    let temp = [false, false, false, false, false, false, false, false, false, false, ];
    if(nextState.rowIndividual !== this.state.rowIndividual){
      
      let selectedCustomerBroadcast = [...this.state.selectedCustomerBroadcast];
      for(const a of selectedCustomerBroadcast){
        if(nextState.rowIndividual.map(e=>e.customerId).includes(a)){          
          const index = nextState.rowIndividual.map(e=>e.customerId).indexOf(a);
          temp.splice(index, 1, true);
        }
      } 
      const isSelectAllBroadcast = this.containsAllValues(selectedCustomerBroadcast,nextState.rowIndividual.map(e=>e.customerId))
      this.setState({listCheckBroadcast: temp, isSelectAllBroadcast});
    }
    if(nextState.selectedCustomerBroadcast !== this.state.selectedCustomerBroadcast){
      for(const item of nextState.selectedCustomerBroadcast){
        if(nextState.rowIndividual.map(e=>e.customerId).includes(item)){
          const index = this.state.rowIndividual.map(e=>e.customerId).indexOf(item);
          temp.splice(index,1,true)
        }
      }
      this.setState({listCheckBroadcast: temp});
    }
    if(nextState.valueSearchCRMBroadcast !== this.state.valueSearchCRMBroadcast){
      if(nextState.valueSearchCRMBroadcast.length > 0 ){
        this.searchCRMBroadcast(1)
      } else{
        this.getCRMListBroadcast(1);
        this.setState({currentPageCustomerBroadcast:1})
      }
    }
    return true;
  }

  handleSearchCustomerNameCRMBroadcast = async(e: any)=>{
    const value= e.target.value;
    if(e.target.value !== undefined && e.target.value !== ''){
      this.setState({valueSearchCusNameCRMBroadcast:value})
      const tokenValue = await getStorageData("authToken")
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": tokenValue
      };
      const requestMessageBroadcast = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessageBroadcast.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageBroadcast.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.crmEndPoint}/search?attribute=name&query=${value}&page=1&per_page=10`
      );
      requestMessageBroadcast.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      this.searchCustomerNameCRMBroadcastId = requestMessageBroadcast.messageId;
      runEngine.sendMessage(requestMessageBroadcast.id, requestMessageBroadcast);
    }
    if(e.target.value === ''){
      this.getCRMListBroadcast(1)
      this.setState({searchAttributeCRMBroadcast:'', valueSearchCRMBroadcast:'', valueSearchCusNameCRMBroadcast:''})
    }
  }

  searchCustomerNameCRMBroadcastReceiver = (responseJson: any)=>{
    this.setState({listSearchCustomerNameCRMBroadcast: responseJson?.data?.data.map((e:any)=>e?.attributes)})
  }


  searchCRMBroadcast = async (page:number) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessageBroadcast = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageBroadcast.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageBroadcast.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.crmEndPoint}/search?attribute=${this.state.searchAttributeCRMBroadcast}&query=${this.state.valueSearchCRMBroadcast}&page=${page}&per_page=10`
    );
    requestMessageBroadcast.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.searchCrmBroadcastId = requestMessageBroadcast.messageId;
    runEngine.sendMessage(requestMessageBroadcast.id, requestMessageBroadcast);
  }

  containsAllValues=(arr1: any, arr2: any) =>{
    // Convert arr1 to a Set for faster membership checking
    let set1 = new Set(arr1);
    
    // Check if all elements in arr2 are present in set1
    return arr2.every((item: any) => set1.has(item));
}

  handleSelectItemIndividual = (data: any, index: number)=>{
    let listCheckBroadcast = [...this.state.listCheckBroadcast];
    let selectedCustomerBroadcast = [...this.state.selectedCustomerBroadcast];
    if(!selectedCustomerBroadcast.includes(data.customerId)){
      selectedCustomerBroadcast.push(data.customerId);
      listCheckBroadcast.splice(index, 1, true);
    } else {
      selectedCustomerBroadcast.splice(selectedCustomerBroadcast.indexOf(data.customerId), 1)
      listCheckBroadcast.splice(index, 1, false);
    }
    const isSelectAllBroadcast = this.containsAllValues(selectedCustomerBroadcast,this.state.rowIndividual.map(e=>e.customerId))
    this.setState({selectedCustomerBroadcast, listCheckBroadcast, isSelectAllBroadcast})
  }

  handleCheckAllItemIndividual = (e: any) =>{
    let selectedCustomerBroadcast = [...this.state.selectedCustomerBroadcast];
    if(!e.target.checked){
      for(const item of this.state.rowIndividual){
        if(selectedCustomerBroadcast.includes(item.customerId)){
          selectedCustomerBroadcast = selectedCustomerBroadcast.filter(e => e !== item.customerId);
        }
      }
    } else {
      for(const item1 of this.state.rowIndividual){
        if(!selectedCustomerBroadcast.includes(item1.customerId)){
          selectedCustomerBroadcast.push(item1.customerId);
        }
      }
    }
    this.setState({isSelectAllBroadcast:e.target.checked, selectedCustomerBroadcast})
  }

  getGroupListBroadcast = async () => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessageBroadcast = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageBroadcast.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageBroadcast.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customerGroupEndPoint}?page=1&per_page=10000000000`
    );
    requestMessageBroadcast.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getGroupListBroadcastId = requestMessageBroadcast.messageId;
    runEngine.sendMessage(requestMessageBroadcast.id, requestMessageBroadcast);
  }

  handleChooseGroup =(item:any)=>{
    this.setState({selectedGroup:item})
  }

  getGroupListBroadcastReceiver = (responseJson: any) => {
    this.setState({listGroupBroadcast:responseJson.data})
  }

  onValueChange = (value: string) => {
    this.setState({ ...this.state, message: value });
  };

  onChangeSearchCRMBroadcast =(e:any, attribute: string)=>{
    this.setState({
      valueSearchCRMBroadcast:e.target.value,
      searchAttributeCRMBroadcast: attribute,
      currentPageCustomerBroadcast:1,
    })
    switch (attribute) {
      case 'name':
        this.setState({
          valueSearchCusNameCRMBroadcast: e.target.value, 
          valueSearchEmailCRMBroadcast:'',
          valueSearchPhoneCRMBroadcast: '',
          valueSearchServiceCRMBroadcast:''
        })
        break;
      case 'contact_number':
        this.setState({
          valueSearchCusNameCRMBroadcast: '',
          valueSearchEmailCRMBroadcast: '', 
          valueSearchPhoneCRMBroadcast:e.target.value, 
          valueSearchServiceCRMBroadcast:''
        })
        break;
      case 'email':
        this.setState({
          valueSearchCusNameCRMBroadcast: '',
          valueSearchEmailCRMBroadcast: e.target.value, 
          valueSearchPhoneCRMBroadcast:'',
          valueSearchServiceCRMBroadcast:''
        })
        break;
      case 'services':
        this.setState({
          valueSearchCusNameCRMBroadcast: '',
          valueSearchEmailCRMBroadcast: '', 
          valueSearchPhoneCRMBroadcast:'',
          valueSearchServiceCRMBroadcast: e.target.value
        })
    }
  }

  handleSubmitBroadcast= ()=>{
   this.setState({
    isErrorInputBroadcast: this.state.message === '',
    isErrorSelectBroadcast:!(this.state.selectedCustomerBroadcast.length !== 0 || this.state.selectedGroup !== null),
  })
  if(this.state.selectedImage === null){
    this.setState({errorImageString:'Please Select an Image'})
  }
    if(this.state.message !== '' && (this.state.selectedCustomerBroadcast.length !==0||this.state.selectedGroup !== null) && this.state.selectedImage !== null){
      if(this.state.selectedImage.size/1024/1024 > 5) {
        this.setState({errorImageString:'Please Select an Image below than 5MB'})
      } else {
        this.createBroadcast(this.state.isGroup);
      }
    }
  }

  createBroadcast = async(isGroup: boolean) =>{
    const tokenValue = await getStorageData("authToken")
      const headers = {
        token: tokenValue,
      };
    let formdataBroadcastCG = new FormData();
    formdataBroadcastCG.append('message',this.state.message);
    if(this.state.selectedImage) {
      formdataBroadcastCG.append('image', this.state.selectedImage);
    }
    if(isGroup){
      formdataBroadcastCG.append('customer_group_id', this.state.selectedGroup.id)
    } else {
      for (const id of this.state.selectedCustomerBroadcast) {
        formdataBroadcastCG.append("customer_ids[]", id.toString());
      }
    }
    const requestMessageBroadcast = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageBroadcast.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessageBroadcast.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.broadcastEndPoint
    );
    requestMessageBroadcast.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdataBroadcastCG
    );
    requestMessageBroadcast.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.createBroadcastId = requestMessageBroadcast.messageId;
    runEngine.sendMessage(requestMessageBroadcast.id, requestMessageBroadcast);
  }

  createBroadcastReceiver =(responseJson:any)=>{
    if(responseJson.data.id !== null){
      this.props.navigation.goBack();
    }
  }

  handleCancelBroadcast = () => {
    this.props.navigation.goBack();
  }
  // Customizable Area End
}