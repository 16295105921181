import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
import moment from "moment";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  width: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  activeTab:"team"|"invidual";
  activeSalesTrend:string;
  graphData:any[];
  startDate: Date | null;
  graphApiData:any,
  endDate: Date | null;
  isCalendarOpen:boolean; 
  accountingPayrollData:{
    team: {
      Profit: number,
      Sales: number,
      No_of_vehicles_added: number,
      Pending_cases: number
    },
    invidual: {
      Profit: number,
      Sales: number,
      No_of_vehicles_added: number,
      Pending_cases: number
    }
  },
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getAccountingPayrollId:any;
  getAccountingPayrollFilterDashboardId:any;
  getGraphDataId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: true,
      activeTab: "invidual",
      activeSalesTrend:'weekly',
      graphData :[["Month","Sales"],["Jan",7411],["Feb",4439],["Mar",8186],["Apr",4303],["May",2246],["Jun",9004],["Jul",7078],["Aug",7269],["Sep",2081],["Oct",5929],["Nov",35],["Dec",9796]],
      startDate: null,
      endDate: null,
      isCalendarOpen:false,
      accountingPayrollData:{
        team: {
          Profit: 0,
          Sales: 0,
          No_of_vehicles_added: 0,
          Pending_cases: 0
        },
        invidual: {
          Profit: 0,
          Sales: 0,
          No_of_vehicles_added: 0,
          Pending_cases: 0
        }
      },
     
      graphApiData:{},
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getAccountingPayroll();
    this.getGraphData()
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
     
      switch (webApiRequestCallId) {
        case this.getAccountingPayrollId:
          
          this.getAccountingPayrollReceiver(webResponseJson);

         
          break;
          case this.getGraphDataId:
            this.getGraphDataReciever(webResponseJson);
            break;
            case this.getAccountingPayrollFilterDashboardId:
              this.getAccountinPayrollFilterReciever(webResponseJson);
              break;
            
        default:
          break;
      }
      runEngine.debugLog("Message Recived", message);

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            dashboardData: webResponseJson.data.attributes.sub_attributres,
            totalCandidates: webResponseJson.data.attributes.total_candidates,
            type: webResponseJson.data.type,
            errorMsg: "",
            loading: false
          });
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start


  shouldComponentUpdate(_nextProps: Readonly<Props>, _nextState: Readonly<S>, _nextContext: any): boolean {
    if(this.state.isCalendarOpen){
      this.setState({isCalendarOpen:false});
    }
    return true;
  }
  
  getAccountingPayrollReceiver = (responseJson: any) => {
    const data = {...responseJson};

     
     this.setState({accountingPayrollData : data,loading:false})
   }

   getGraphDataReciever = (responseJson: any) => { 
    this.setState({graphApiData: responseJson,loading:false})
  }

 
  getAccountingPayroll = async () => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
     
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountingAndPayroll}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    this.getAccountingPayrollId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

 

  getGraphData = async () => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.graphDataEndpoint}?sales_trends=${this.state.activeSalesTrend}`
    );
    //
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    this.getGraphDataId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleTabClick = (tab:any) => {
    this.setState({ activeTab: tab });
  };
  getAccountingPayrollDateSelectDashboard = async () => {
    this.setState({loading:true})
    const tokenValue = await getStorageData("authToken")
    const header = {
      "token": tokenValue,
      "Content-Type": configJSON.validationApiContentType
       };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountingAndPayroll}?start_date=${moment(this.state.startDate).format("YYYY-MM-DD")}&end_date=${moment(this.state.endDate).format("YYYY-MM-DD")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
    this.getAccountingPayrollFilterDashboardId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
   handleSalesTrendsTabClick = (tab:string) => {
    this.setState({ activeSalesTrend: tab });
    this.getGraphData()
  };

  handleDateChange = (date: any,e:any) => {
    
     this.setState({
      startDate:date[0], 
      endDate:date[1] ,
      isCalendarOpen: false,
     })

    this.getAccountingPayrollDateSelectDashboard()
  };
  toggleCalendar = () => {
    this.setState((prevState) => ({
      isCalendarOpen: !prevState.isCalendarOpen, // Toggle calendar visibility
    }));
  
  };

  getAccountinPayrollFilterReciever = (responseJson: any) => { 
    const data = {...responseJson};
    this.setState({accountingPayrollData : data,loading:false})
  
  }

  handleReset1 = ()=>{
    this.setState({isCalendarOpen:false})
  }
  // Customizable Area End
}
