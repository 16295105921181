import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
    emailError :any;
    isResetPasswordSucees:boolean;
    isForgotPasswordSucess:boolean;
    email:string;
    openModalRequestForgotPassword:boolean;
    openModalResetSucess:boolean;
    showPassword: boolean;
    showConfirmPassword: boolean;
    showOldPassword:boolean;
    token : any;
    oldPassword:string;
    newPassword: string;
    confirmNewPassword: string;
    error:{upper:boolean,number:boolean,minimum:boolean,special:boolean};
    errorValue:string;
    formIsDirty: boolean;
    isInvalidOld: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  putResetPasswordApiId: string = ''
  postForgotPasswordApiId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
        emailError:"",
        isForgotPasswordSucess:false,
        isResetPasswordSucees:false,
        email:'',
        openModalRequestForgotPassword:false,
        openModalResetSucess:false,
        showOldPassword:false,
        showPassword: false,
        showConfirmPassword:false,
        token : '',
        oldPassword:'',
        newPassword:'',
        confirmNewPassword: '',
        error:{upper:false,number:false,minimum:false,special:false},
        errorValue:'',
        formIsDirty: false,
        isInvalidOld: false,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  // web events

  // Customizable Area Start
  handleOpen = () => {
    this.setState({ openModalRequestForgotPassword: true });
  };

  handleClose = () => {
    this.setState({ openModalRequestForgotPassword: false });
  };

  handleOpenForgotSucess=()=>{
    this.setState({isForgotPasswordSucess:true})
  }
  handleCloseForgotSucess=()=>{
    this.setState({isForgotPasswordSucess:false})
  }
  handleOpenResetSucess=()=>{
    this.setState({isResetPasswordSucees:true})
  }
  handleCloseResetSucess=()=>{
    this.setState({isResetPasswordSucees:false})
  }

  ////
  
  handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }
    handleShowConfirm = () => {
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword })
    }
    handleOldPassword = () => {
        this.setState({ showOldPassword: !this.state.showOldPassword })
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
         if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.putResetPasswordApiId &&
            this.putResetPasswordApiId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if(responseJson.error === 'Invalid old password or new password mismatch'){
              this.setState({isInvalidOld:true})
            }
             if(responseJson?.data?.attributes){
                this.handleOpenResetSucess();
             }
      
        }
         if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postForgotPasswordApiId &&
            this.postForgotPasswordApiId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson?.errors){
              this.setState({emailError: responseJson.errors[0].otp})
            }

            if (responseJson?.data) {   
                this.handleClose()
                setTimeout(()=>{
                      this.handleOpenForgotSucess()
                },1000)

            } 
          
        }
        // Customizable Area End
    }


  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value,
      error:{
        upper: /^(?=.*[a-z])(?=.*[A-Z])/.test(value) ? true : false,
        number: /^(?=.*\d)/.test(value) ? true : false,
        minimum:/^.{8,}$/.test(value) ? true : false,
        special:/^(?=.*[!@#\$%\^&\*])/.test(value) ? true : false,
      },
      errorValue:value});
  };
  onValueChangeEmail = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value});
  };

checkSubmit = () => {
  this.setState({...this.state, formIsDirty: true})
}

resetPasswordApi = () => {
    const header = {
        "Content-Type": "application/json",
        "token": localStorage.getItem('authToken')
    };
    let body =  {
            old_password :this.state.oldPassword,
            new_password : this.state.newPassword,
            confirm_new_password: this.state.confirmNewPassword
    }
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.resetPasswordApiEndpoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
    );
    this.putResetPasswordApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
}
forgotPasswordApi = () => {
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    const header = {
        "Content-Type": "application/json",
    };
    let formDataCampaign =  {
        "data": {
            "type": "email_account",
            "attributes":{
                "email": this.state.email
            }
        }
    }
    this.postForgotPasswordApiId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.forgotPasswordAPiEndPoint
      );
  
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(formDataCampaign)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
}
  // Customizable Area End
}
