import React from "react";
// Customizable Area Start
import clsx from 'clsx';
import { Box, Grid, Input, InputLabel,TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withWidth, MenuItem, Select } from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";
 
// Customizable Area End

import LoanCalculatorController, { Props, loanTerm } from "./LoanCalculatorController.web";


export default class LoanCalculator extends LoanCalculatorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    const {width} = this.props;
    return (
        // Customizable Area Start
        // Required for all blocks
      <>
        <LoanCalculatorStyle>
          <Grid container className="containerBox">
            <Grid item xs={12} md={12} className="tabContainer">
              <Box className="tab">
                <Box data-test-id='overviewBtn' className="tabTextWrap" onClick={this.handleNavigateOverview}>
                  <Typography className="tabText">Overview</Typography>
                </Box>
                <Box className="gap"/>
                <Box className="activeTab">
                  <Typography className="activeText">Financial</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid item className="tabContainer">
                <Box className="tabWrap">
                  <Box data-test-id={'normalLoan'} onClick={() => this.handleLoanType(0)} className="tabLabelWrap">
                    <Typography className={clsx({'tabLabelActive':this.state.loanType === 0, 'tabLabelInactive':this.state.loanType !== 0})}>Normal Loan</Typography>
                    {this.state.loanType === 0 && <Box className='underline'/>}
                  </Box>
                  <Box data-test-id={'overLoan'} onClick={() => this.handleLoanType(1)} className="tabLabelWrap">
                    <Typography className={clsx({'tabLabelActive':this.state.loanType === 1, 'tabLabelInactive':this.state.loanType !== 1})}>Over Loan</Typography>
                    {this.state.loanType === 1 && <Box className='underline'/>}
                  </Box>
                  <Box data-test-id={'fullLoan'} onClick={() => this.handleLoanType(2)} className="tabLabelWrap">
                    <Typography className={clsx({'tabLabelActive':this.state.loanType === 2, 'tabLabelInactive':this.state.loanType !== 2})}>Full Loan</Typography>
                    {this.state.loanType === 2 && <Box className='underline'/>}
                  </Box>
                </Box>
              </Grid>          
              <Grid item container className="padding20">
                <Grid item md={12} xs={12} className="marginB20">
                  <InputLabel htmlFor="carName" className="inputLabel">
                    Car Price
                  </InputLabel>
                  <Input
                    data-test-id='carPriceId'
                    className="inputField"
                    placeholder="Place holder"
                    disableUnderline
                    value={this.state.carPrice}
                    onChange={(event) => {
                        this.onValueChange("carPrice", event.target.value);                        
                    }}
                    fullWidth
                    onKeyDown={(events) => this.handleNumericInputLoan(events, { allowDecimal: true, maxDecimalPlace: 2, maxValue : 9999999999.99 })}
                  />
                </Grid>
                {this.state.loanType === 0 && <Grid item md={12} xs={12} className="marginB20">
                  <InputLabel className="inputLabel">
                    Downpayment
                  </InputLabel>
                  <Input
                    data-test-id='downpaymentId'
                    className="inputField"
                    placeholder="Place holder"
                    disableUnderline
                    value={this.state.downpayment}
                      onChange={(event) => {
                        const regex = /^[0-9\b]+$/;
                        if (event.target.value === "" || regex.test(event.target.value)) {
                          this.onValueChange("downpayment", event.target.value);                        
                        }                        
                      }}
                    fullWidth
                  />
                  <Box className="downpaymentBtnWrap">
                    <Box data-test-id='downpaymentBtn30' onClick={() => this.handleBtnDownpayment(30)} className="downpaymentBtn">
                      <Typography className="downpaymentBtnText">30%</Typography>
                    </Box>
                    <Box data-test-id='downpaymentBtn40' onClick={() => this.handleBtnDownpayment(40)} className="downpaymentBtn">
                      <Typography className="downpaymentBtnText">40%</Typography>
                    </Box>
                    <Box data-test-id='downpaymentBtn50' onClick={() => this.handleBtnDownpayment(50)} className="downpaymentBtn">
                      <Typography className="downpaymentBtnText">50%</Typography>
                    </Box>
                  </Box>
                </Grid>}
                {this.state.loanType === 1 && <Grid item md={12} xs={12} className="marginB20">
                  <InputLabel className="inputLabel">
                    Over loan Amount
                  </InputLabel>
                  <Input
                    data-test-id='overLoanId'
                    className="inputField"
                    placeholder="Place holder"
                    disableUnderline
                    value={this.state.overLoanAmount}
                      onChange={(event) => {
                        const regex = /^[0-9\b]+$/;
                        if (event.target.value === "" || regex.test(event.target.value)) {
                          this.onValueChange("overLoanAmount", event.target.value);                      
                        }                          
                      }}
                    fullWidth
                  />
                </Grid>}
                <Grid item md={12} xs={12} className="marginB20">
                  <InputLabel className="inputLabel">
                    Interest Rate %
                  </InputLabel>
                  <Input
                    data-test-id='interestRateId'
                    className="inputField"
                    placeholder="Place holder"
                    disableUnderline
                    value={this.state.interestRate}
                      onChange={(event) => {
                        const regex = /^[0-9.\b]+$/;
                        if (event.target.value === "" || regex.test(event.target.value)) {
                          this.onValueChange("interestRate", event.target.value);                  
                        }                          
                      }}
                    fullWidth
                  />
                  <Box className="downpaymentBtnWrap">
                    <Box data-test-id='interestRateBtn1' onClick={() => this.handleInterestRate('2.68')} className="downpaymentBtn">
                      <Typography className="downpaymentBtnText">2.68%</Typography>
                    </Box>
                    <Box data-test-id='interestRateBtn2' onClick={() => this.handleInterestRate('3.08')} className="downpaymentBtn">
                      <Typography className="downpaymentBtnText">3.08%</Typography>
                    </Box>
                    <Box data-test-id='interestRateBtn3' onClick={() => this.handleInterestRate('3.48')} className="downpaymentBtn">
                      <Typography className="downpaymentBtnText">3.48%</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12} xs={12} className="marginB20">
                  <InputLabel className="inputLabel">
                    Loan Term
                  </InputLabel>
                      <Select
                        data-test-id='loanTermSelectionId'
                        className="inputField"
                        placeholder="Place holder"
                        disableUnderline
                        value={this.state.loanTermOpt}
                        onChange={this.onSelectLoanTerm}
                        MenuProps={{
                          style: {
                            marginTop: '20px',
                            marginLeft: '-10px',
                          },
                          PaperProps: {
                            style: {
                              backgroundColor: '#3F3E3A',
                              borderRadius: '8px',
                              padding: '8px',
                              fontFamily: 'Outfit'
                            }
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                        fullWidth
                    >
                      {loanTerm.map((item)=>(
                        <MenuItem key={item.value} value={item.value} style={{color:'#F3f3f3', fontFamily:'Outfit', fontSize:16}}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                </Grid>
                <Grid item md={12} xs={12} className="marginB20">
                {this.state.isShowOtherLoanTerm &&  <>
                  <InputLabel className="inputLabel">
                    Other Loan Term
                  </InputLabel>      
                    <TextField
                      data-test-id='otherLoanTermId'
                      className="inputField"
                      placeholder="Months"
                      InputProps={{disableUnderline: true, }}
                      value={this.state.loanTerm}
                      onChange={(event) => {
                        const regex = /^[0-9\b]+$/;
                        if (event.target.value === "" || regex.test(event.target.value)) {
                          this.onValueChange("loanTerm", event.target.value);                  
                        }                             
                      }}
                      onBlur={(event)=>{
                        if(Number(event.target.value)>60){
                          this.onValueChange("loanTerm", '60');
                        }
                        if(Number(event.target.value)<12){
                          this.onValueChange("loanTerm", '12');
                        }
                      }}
                      fullWidth
                    />
                    </>}
                </Grid>                
              </Grid>                  
            </Grid>
            <Grid container item xs={12} md={6} className="padding20">
              <Grid item xs={12} md={12} className="wrapQR">
                <Typography className="textQR">Scan to pay your deposit</Typography>
                <Grid item xs={12} style={{objectFit:"cover"}}>
                <img src={this.state.qrcode} className="qrcode"/>
                </Grid>
              </Grid>
              <Grid item container xs={12} md={12} className="wrapInfo">
                <Grid container item xs={12} md={12}>
                  <Grid item xs={12} md={6} className="GroupLoan">
                    <Typography className="textLabel">Total paid amount</Typography>
                    <Typography className="textDetail">${Number(this.state.totalPaidAmount).toLocaleString()}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className="GroupLoan">
                    <Typography className="textLabel">Interest amount</Typography>
                    <Typography className="textDetail">${Number(this.state.interestAmount).toLocaleString()}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} className="GroupLoanLine"/>
                <Grid item container xs={12} md={12}>
                  <Grid item xs={12} md={6} className="GroupLoan">
                    <Typography className="textLabel">Loan amount</Typography>
                    <Typography className="textDetail">${Number(this.state.loanAmount).toLocaleString()}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className="GroupLoan">
                    <Typography className="textLabel">monthly instalment</Typography>
                    <Typography className="textDetail">${Number(this.state.monthInstalment).toLocaleString()} /month</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} className="wrapButtonSection">
                  <Typography className="textBtnSection">COE Left : 61 months | ARF : $19,654 | COE : $28,199</Typography>
                  <Box className="btnWrap">
                    
                    <Typography data-test-id='apply' onClick={this.handelRedirect} className="btnTextApply">Apply loan</Typography>
                   
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            

            
            <Grid item xs={12} className="padding20">
              <TableContainer className={clsx({'width300': width === 'xs', 'width100': width !=='xs' })}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell width={'40%'}></StyledTableCell>
                      <StyledTableCell align="center">Year</StyledTableCell>
                      <StyledTableCell align='center'>Loan redemption</StyledTableCell>
                      <StyledTableCell width={'40%'}></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.rows.map((row)=>
                      <TableRow>
                        <StyledTableCell width={'40%'} align="center"></StyledTableCell>
                        <StyledTableCell align="center">{row.year}</StyledTableCell>
                        <StyledTableCell align="center">${row.loanRedemption}</StyledTableCell>
                        <StyledTableCell width={'40%'} align="center"></StyledTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item md={12} xs={12} className="padding20">
              <TableContainer className={clsx({'width300': width === 'xs', 'width100': width !=='xs' })}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center"></StyledTableCell>
                      <StyledTableCell align='center' width={"12%"}>Financial services</StyledTableCell>
                      <StyledTableCell align='center' width={"12%"}>DBS</StyledTableCell>
                      <StyledTableCell align="center" width={"12%"}>Maybank</StyledTableCell>
                      <StyledTableCell align="center" width={"12%"}>OCBC</StyledTableCell>
                      <StyledTableCell align="center" width={"12%"}>UOB</StyledTableCell>
                      <StyledTableCell align="center" width={"12%"}>Hong Leong finance</StyledTableCell>
                      <StyledTableCell align="center" width={"12%"}>Tokyo Century Leasing</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.bankTable.map((row)=>
                      <TableRow>
                        <StyledTableCellHeader align="center">{row.col1}</StyledTableCellHeader>
                        <StyledTableCell align="center">{row.financial_services}</StyledTableCell>
                        <StyledTableCell align="center">{row.dbs}</StyledTableCell>
                        <StyledTableCell align="center">{row.may_bank}</StyledTableCell>
                        <StyledTableCell align="center">{row.ocbc}</StyledTableCell>
                        <StyledTableCell align="center">{row.uob}</StyledTableCell>
                        <StyledTableCell align="center">{row.hong_leong_finance}</StyledTableCell>
                        <StyledTableCell align="center">{row.tokyo_century_leasing}</StyledTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              </Grid>
          </Grid>
        </LoanCalculatorStyle>
      </>
        // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledTableCell = withStyles((theme)=>({
  head: {
    backgroundColor: (theme as any).typography.surface2, 
    color: (theme as any).typography.h1, 
    fontSize: '14px',
    fontFamily: 'Outfit', 
    fontWeight: 700,
    verticalAlign:'center',
    border: 'none',
  },
  body: {
    backgroundColor: (theme as any).typography.body2, 
    color: (theme as any).typography.h1, 
    fontSize: '14px',
    fontFamily: 'Outfit', 
    fontWeight: 400,
    border: 'none'
  }
}))(TableCell)

const StyledTableCellHeader = withStyles((theme)=>({
  body: {
    backgroundColor: (theme as any).typography.surface2, 
    color: (theme as any).typography.h1, 
    fontSize: '14px',
    fontFamily: 'Outfit', 
    fontWeight: 700,
    border: 'none'
  }
}))(TableCell)

const LoanCalculatorStyle  = styled(Box)(({ theme }) => ({
  "& .MuiInputBase-input":{
    fontFamily: "Outfit",
    fontSize: 16,
    fontWeight: 400,
    color: (theme as any).typography.h1, 
  },
  '& .containerBox': {
    padding: "20px",
  },
  '& .gap': {
    width:"24px"
  },
  '& .qrcode': {
    width:300,
    height:300,
    resizeMode:'cover',
    '@media (max-width: 600px)': {
      width:150,
    height:150,
    resizeMode:'cover',
    },
  },
  '& .tab': {
    display: "flex",
    marginTop: "24px",
    borderRadius: "8px",
    padding: "10px",
    border: `1px ${(theme as any).typography.border} solid`,
    backgroundColor: (theme as any).typography.body2, 
    width: "fit-content"
  },
  '& .tabTextWrap': {
    cursor: 'pointer',
    padding: "8px",
    borderRadius: "4px",
  },
  '& .width300':{
    width: '300px',
    borderRadius: '8px'
  },
  '& .width100':{
width: '100%',
    borderRadius: '8px'
  },
  '& .padding20':{
    padding:'20px'
  },
  '& .marginB20':{
    marginBottom:'20px'
  },
  '& .activeTab': {
    padding: "8px",
    borderRadius: "4px",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))"
  },
  '& .activeText': {
    color: "#171717",
    fontFamily: "Outfit",
    fontSize:"18px",
    fontWeight: 700,
    '@media (max-width: 600px)': {
      fontSize: 10,
    },
  },
  '& .tabText': {
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize:"18px",
    fontWeight: 700,
    '@media (max-width: 600px)': {
      fontSize: 10,
    },
  },
  "& .inputLabel": {
    marginBottom: "5px",
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .inputField": {
    display: "flex",
    height: "56px",
    padding: "10px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: "1px solid  #CBD5E1",
    background: (theme as any).typography.body2, 
    color: "white",
  },
  "& .downpaymentBtnWrap":{
    flexDirection:'row', 
    marginTop:'16px'
  },
  "& .downpaymentBtn":{
    cursor: 'pointer',
    paddingLeft: '16px',
    paddingRight: '16px', 
    paddingTop: '10px', 
    paddingBottom: '10px', 
    background: (theme as any).typography.surface2, 
    borderRadius: '8px', 
    justifyContent: 'center', 
    alignItems: 'center', 
    display: 'inline-flex', 
    marginRight:'16px'},
  "& .downpaymentBtnText":{
    color: '#CEAE71', 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700'
  },
  "& .wrapQR":{
    alignItems:'center', 
    display:'flex', 
    justifyContent:'center', 
    flexDirection:'column', 
    marginBottom:'32px',
  },
  "& .textQR":{
    color: (theme as any).typography.h1, 
    fontSize: '20px', 
    fontFamily: 'Outfit', 
    fontWeight: '400', 
    marginBottom:'32px'
  },
   "& .wrapInfo":{
    padding: '16px', 
    background: (theme as any).typography.body2,  
    borderRadius: '8px', 
  },
  "& .textLabel":{
    color: (theme as any).typography.h1,  
    fontSize: '18px', 
    fontFamily: 'Outfit', 
    fontWeight: '400'
  }
  ,
  "& .textDetail":{
    color: (theme as any).typography.h1, 
    fontSize: 18, 
    fontFamily: 'Outfit', 
    fontWeight: '700'
  },
  "& .GroupLoan":{
    display:'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  "& .GroupLoanLine":{
    height: 0, 
    border: '1px rgba(145, 101, 56, 0.40) solid', 
    marginTop:'16px', 
    marginBottom:'16px'
  },
  "& .tabLabelActive":{
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent", 
    fontSize: 18, 
    fontFamily: 'Outfit', 
    fontWeight: '700',
    '@media (max-width: 600px)': {
      fontSize: 10,
    },
  },
  "& .tabLabelWrap":{
    cursor: 'pointer',
    marginRight:'32px',
    borderRadius: '4px', 
    justifyContent: 'center',
    alignItems: 'center', 
    display: 'flex',
    flexDirection:'column'
  },
  "& .underline":{
    height:2,
    backgroundColor:'#F7DD91', 
    width:'100%'
  },
  "& .tabLabelInactive":{
    color: (theme as any).typography.h1, 
    fontSize: '18px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
    '@media (max-width: 600px)': {
      fontSize: 10,
    },},
  "& .tabWrap":{
    padding: '10px', 
    borderRadius: '8px', 
    justifyContent: 'flex-start', 
    alignItems: 'center', 
    display: 'inline-flex'
  },
  "& .tabContainer":{
    padding:'20px',
    width:'fit-content',
    
  },
  "& .fitContent":{
    width:'fit-content'
  },
  "& .wrapButtonSection":{
    display:'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',
  },
  "& .textBtnSection":{
    color: 'rgba(243, 243, 243, 0.65)', 
    fontSize: "14px", 
    fontFamily: 'Outfit', 
    fontWeight: '400', 
    marginBottom:'16px', 
    marginTop:'16px'
  },
  "& .btnWrap":{
    cursor: 'pointer',
    paddingLeft: "16px", 
    paddingRight: "16px", 
    paddingTop: "10px", 
    paddingBottom: "10px", 
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    alignItems: 'center',
  },
  "& .btnTextApply":{
    color: '#171717', 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '700'
  },
  "& .linkText":{
    textDecoration:"none"
  }
}));
export {LoanCalculator}

withWidth()(LoanCalculator);
// Customizable Area End