import React, { Component } from 'react';
import clsx from 'clsx';
import {
  withStyles,
  createStyles,
  Theme,
  WithStyles,
} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Avatar, Box, withWidth } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { withRouter ,RouteComponentProps} from 'react-router-dom';
import { getStorageData } from '../../framework/src/Utilities';

const drawerWidth = 275;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      "& .PersistentDrawerLeft-drawerOpen-10": {
        width: '275px',
        height:"100%",
        border:0
      },
      "& .PersistentDrawerLeft-drawerOpen-9": {
        width: '275px',
        border:0
      },
      "& .PersistentDrawerLeft-appBarShift-6": {
        width: "calc(100% - 275px)",
        boxShadow: "none",
        border:0
      },
      "& .PersistentDrawerLeft-appBar-5": {
        boxShadow: "none",
      },
      "& .MuiToolbar-gutters": {
        paddingLeft: "28px",
      },
      "& .PersistentDrawerLeft-drawerClose-11": {
        width: "100px",
        height:"100%"
      },
      "& .MuiSvgIcon-root": {
        color: 'white',
      },
      "& .MuiTypography-body1":{
        fontFamily: "Outfit",
      },
      "& .drawerBtn ": {
        color: (theme as any).typography.h2,
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        padding: "8px var(--border-radius, 8px)",
        gap: "10px",
        // paddingLeft: 8,
        alignSelf: "stretch",
      },
      "& .activetText":{
        color: 'black',
      },
      "& .activetBtn": {
        padding: "8px var(--border-radius, 8px)",
        gap: "10px",
        alignSelf: "stretch",
        borderRadius: "8px",
        background:
          "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))"

      },
      "& .avatar":{
        background:"#edd28e",
      },
      "& .list": {
        padding: "20px",
      },
      "& .boxLogout": {
        display: "flex",
        flexDirection: "column",
        alignItems: 'flex-start',
        marginLeft: '25px',
      },
      "& .btnText": {
        color: (theme as any).typography.h2,
        fontFamily: "Outfit",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: '45px',
        cursor: "pointer",
      },
      "& .profileText": {
        color: (theme as any).typography.h2,

      },
      "& .profilaBox": {
        marginLeft: '15px',
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        alignItems: "center",
        fontFamily: "Outfit",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        marginBottom: "30px",
      },
      "& .profilaClose": {
        display: "flex",
        justifyContent: "center",
        marginBottom: "40px",
      },
      "& .mainBox": {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      },
      "& .drawerBox": {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginBottum: "40px",
      },
      "& .divider": {
        background: "#916538",
      }
    },
    boxLogo: {
      display: "flex",
      justifyContent: "center",
    },
    logo: {
      width: "100px",
      height: "65px",
    },
    logoSmall: {
      marginTop: '20px',
      width: "55px",
      height: "55px",
    },
    notifications: {
      height: "24px",
      width: "24px",
      marginRight: "40px",
      color: `${(theme as any).typography.h1} !important`,
    },
    appBar: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      background: (theme as any).typography.body2,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      height: '64px',
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    header:{
      fontFamily:'Outfit',
      color:(theme as any).typography.h1,
      fontWeight:400,
      fontSize:24,
      '@media (max-width: 600px)': {
        fontSize: 16,
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      background: (theme as any).typography.body2,
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      border:0
    },
    drawerClose: {
      background: (theme as any).typography.body2,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      border:0,
      width: theme.spacing(10) + 3,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      background: "#151515",
      height: "100vh",
      color: 'white',
      flexGrow: 1,
      // padding: theme.spacing(3),
    },
    icon:{
      color:`${(theme as any).typography.h1} !important`
    }
  });


export interface PersistentDrawerLeftProps extends WithStyles<typeof styles>, RouteComponentProps<any>{navigation: any, id: string, width: any, handleOpenNotification: any}
export class PersistentDrawerLeft extends Component<
  PersistentDrawerLeftProps,
  { open: boolean; selectedItem: string | null;email:string,fullName:string, anchor: any, headerText: string }
> {
  constructor(props: PersistentDrawerLeftProps) {
    super(props);
    this.state = {
      open: false,
      selectedItem: null,
      headerText: '',
      email:"",
      fullName:"",
      anchor: null,
    };
  }

  async componentDidMount() {
    this.checkHeader()
    let email = await getStorageData("email")
    this.setState({email:email})
    let fullName = await getStorageData("fullName")
    this.setState({fullName:fullName})
  }

  componentDidUpdate(prevProps: any, _prevState: any, snapshot?:any): void {
    // Check if the pathname has changed
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.checkHeader()
    }
  }

  checkHeader = async() =>{
    const pathSegments = window.location.pathname.split('/');
      switch (pathSegments[1]) {
        case 'LandingPage':
          this.setState({...this.state, headerText:"DASHBOARD", selectedItem:'Dashboard'})
          break;
        case 'Catalogue':
          this.setState({...this.state, headerText:"CATALOGUE", selectedItem:'Catalogue'})
          break;
        case 'AddCatalougue':
          this.setState({...this.state, headerText:"ADD CATALOGUE", selectedItem:'Catalogue'})
          break;
        case 'EditCatalougue':
          this.setState({...this.state, headerText:"EDIT CATALOGUE", selectedItem:'Catalogue'})
          break;
        case 'CatalogueDetail':
          this.setState({...this.state, headerText:"CAR INFORMATION", selectedItem:'Catalogue'})
          break;
        case 'Calculator':
          this.setState({...this.state, headerText:"CALCULATOR",selectedItem:'Catalogue'})
          break;
        case 'CRM':
          this.setState({...this.state, headerText:"CRM",selectedItem:'CRM'})
          break;
        case 'NewCustomer':
          this.setState({...this.state, headerText:"NEW CUSTOMER", selectedItem:'CRM'})
          break;
        case 'EditCustomer':
          this.setState({...this.state, headerText:"EDIT CUSTOMER", selectedItem:'CRM'})
          break;
        case 'CustomerGroup':
          this.setState({...this.state, headerText:"CRM", selectedItem:'CRM'})
          break;
        case 'NewCustomerGroup':
          this.setState({...this.state, headerText:"NEW GROUP", selectedItem:'CRM'})
          break;

          case 'Settings':
          this.setState({...this.state, headerText:"SETTINGS", selectedItem:''})
          break;
          case 'resetPasswordthroughsetting':
            this.setState({...this.state, headerText:"RESET PASSWORD"})
            break;
        case 'EditCustomerGroup':
          this.setState({...this.state, headerText:"EDIT GROUP", selectedItem:'CRM'})
          break;
        case 'Broadcast':
          this.setState({...this.state, headerText:"BROADCAST", selectedItem:'CRM'})
          break;
        case 'NewBroadcast':
          this.setState({...this.state, headerText:"NEW BROADCAST", selectedItem:'CRM'})
          break;
        case 'SalesAndContract': 
          this.setState({...this.state, headerText:"SALES AND CONTRACT", selectedItem: 'Sales and Contract'})
          break;
          case 'AccountingAndPayroll': 
          this.setState({...this.state, headerText:"ACCOUNTING AND PAYROLL", selectedItem: 'Accounting and Payroll'})
          break;
          case 'Marketing': 
          this.setState({...this.state, headerText:"MARKETING", selectedItem: 'Marketing'})
          break;
          case 'CreateNewPost': 
          this.setState({...this.state, headerText:"NEW SOCIAL MEDIA POST", selectedItem: 'Marketing'})
          break;
         
          case 'MarketingDetails': 
          this.setState({...this.state, headerText:"NEW SOCIAL MEDIA POST", selectedItem: 'Marketing'})
          break;
          
        case 'NewSalesAndContract':
          this.setState({...this.state, headerText:"NEW DEAL", selectedItem: 'Sales and Contract'})
          break;
        case 'EditSalesAndContract':
          this.setState({...this.state, headerText:"EDIT DEAL", selectedItem: 'Sales and Contract'})
          break;
        case 'GuarantorDetails':
          this.setState({...this.state, headerText:"GUARANTOR DETAILS", selectedItem: 'Sales and Contract'})
          break;
        case 'DealDetail':
          const userType = await getStorageData("user_type");
          if(userType==="sales_manager" ){
            this.setState({...this.state, headerText:"DEAL TO BE APPROVED", selectedItem: 'Sales and Contract'})
          } else {
            this.setState({...this.state, headerText:"NEW DEAL", selectedItem: 'Sales and Contract'})
          }
          break;
        case 'EditGuarantor':
          this.setState({...this.state, headerText:"GUARANTOR DETAILS", selectedItem: 'Sales and Contract'})
          break;
        case 'History':
          this.setState({...this.state, headerText:"IMPORT HISTORY", selectedItem: 'Catalogue'})
          break;
        default:
          this.setState({...this.state, headerText:"DASHBOARD", selectedItem: 'Dashboard'})
          break;
      }
  }

  shouldComponentUpdate(nextProps: any, _nextState:any, _nextContext: any): boolean {
    if(nextProps.width !== this.props.width){
      this.handleDrawerClose();
    }
    return true;
  }

  handelLogout = () => {
    localStorage.removeItem('authToken');
    this.props.history.push('/')
    this.props.history.go(0)
  };

  handleDrawerOpen = (width: string) => {
    if(width==='xs'){
      this.setState({anchor:true})
    } else {
    this.setState({ open: true });}
  };

  handleDrawerClose = () => {
    this.setState({ open: false, anchor:null });
  };

  handleListItemClick = (text: string) => {    
    this.setState({...this.state, selectedItem: text});
    switch (text) {
      case 'Dashboard':
        this.props.history.push('/LandingPage')
        break; 
      case 'CRM':
        this.props.history.push('/CRM')
        break;
      case 'Catalogue':
        this.props.history.push('/Catalogue')
        break;
      case 'Sales and Contract':
        this.props.history.push('/SalesAndContract')
        break;

        case 'Accounting and Payroll':
          this.props.history.push('/AccountingAndPayroll')
          break;

          case 'Marketing':
            this.props.history.push('/Marketing')
            break;
        
    }
  }

  
  render() {
    const { classes, theme, width } = this.props as any;
    const { open, selectedItem, headerText, anchor } = this.state;

    const firstChar = this.state.email.charAt(0).toUpperCase();
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          elevation={0}
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={()=>this.handleDrawerOpen(width)}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon className={classes.icon}/>
            </IconButton>
            <Typography variant="h6" noWrap className={classes.header}>
              {headerText}
            </Typography>
          </Toolbar>
          <Box onClick={()=>this.props.handleOpenNotification()}>
            <NotificationsNoneIcon              
              className={classes.notifications}
            />
          </Box>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'rtl' ? <CloseIcon className={classes.icon}/> : <CloseIcon className={classes.icon}/>}
            </IconButton>
          </div>
          {open ? <div className={classes.boxLogo}>
            <img
              className={classes.logo}
              src={require("./logo.png")}
            />
          </div> :
            <div className={classes.boxLogo}>
              <img
                className={classes.logoSmall}
                src={require("./smallLogo.png")}
              />
            </div>
          }
          <Box className='drawerBox'>
            <List className='list'>
              {open && ['Dashboard', 'Accounting and Payroll', 'CRM', 'Sales and Contract', 'Catalogue', 'Marketing',].map((text, index) => (
                <ListItem
                  button
                  key={text}
                  className={clsx('drawerBtn', { 'activetBtn':selectedItem === text })}
                  onClick={() => this.handleListItemClick(text)}>
                  <ListItemText className={clsx('drawerBtn', { 'activetText':selectedItem === text })} primary={text} />
                </ListItem>
              ))}
            </List>
            {open ? <div className='bottumBox'>
              <Divider className='divider' variant="middle" />
              <div className='mainBox'>
                <Box className='boxLogout'>
                  <Typography className='btnText' onClick={()=>this.props.history.push('/Settings')}>Settings</Typography>
                  <Typography className='btnText' onClick={this.handelLogout}>Logout</Typography>
                </Box>
                <Box className='profilaBox'>
                  <div>
                    <Avatar className='avatar'>{firstChar}</Avatar>
                  </div>
                  <div style={{ overflow: "hidden" }}>
                    <Typography className='profileText'>{this.state.fullName}</Typography>
                    <Typography className='profileText'>{this.state.email}</Typography>
                  </div>
                  <Box>
                    <img style={{color:"white"}}
                      src={require("./arrowUp.png")}
                    />
                  </Box>
                </Box>
              </div>
            </div> :
              <div className='bottumBox'>
                <Box className='profilaClose'>
                  <div>
                    <Avatar className='avatar'>{firstChar}</Avatar>
                  </div>
                </Box>
              </div>
            }
          </Box>
        </Drawer>
        <Drawer 
          anchor={'left'} 
          open={anchor}
          className={clsx(classes.root)}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: anchor,
              [classes.drawerClose]: !anchor,
            }),
          }}
        >
          <div className={classes.toolbar}>
              <IconButton onClick={this.handleDrawerClose}>
                {theme.direction === 'rtl' ? <CloseIcon className={classes.icon}/> : <CloseIcon className={classes.icon}/>}
              </IconButton>
          </div>
          { <div className={classes.boxLogo}>
            <img
              className={classes.logo}
              src={require("./logo.png")}
            />
          </div> 
          }
          <Box className='drawerBox'>
              <List className='list'>
                { ['Dashboard', 'Accounting and Payroll', 'CRM', 'Sales and Contract', 'Catalogue', 'Marketing'].map((text, index) => (
                  <ListItem
                    button
                    key={text}
                    className={clsx('drawerBtn', { 'activetBtn': this.state.selectedItem === text })}
                    onClick={() => this.handleListItemClick(text)}>
                    <ListItemText className={clsx('drawerBtn', { 'activetText': this.state.selectedItem === text })} primary={text} />
                  </ListItem>
                ))}
              </List>
              <div className='bottumBox'>
                <Divider className='divider' variant="middle" />
                <div className='mainBox'>
                  <Box className='boxLogout'>
                    <Typography className='btnText' onClick={()=>this.props.history.push('/Settings')}>Settings</Typography>
                    <Typography className='btnText' onClick={this.handelLogout}>Logout</Typography>
                  </Box>
                  <Box className='profilaBox'>
                    <div>
                      <Avatar className='avatar'>{firstChar}</Avatar>
                    </div>
                    <div style={{ overflow: "hidden" }}>
                      <Typography className='profileText'>{this.state.fullName}</Typography>
                      <Typography className='profileText'>{this.state.email}</Typography>
                    </div>
                    <Box>
                      <img style={{color:"white"}}
                        src={require("./arrowUp.png")}
                      />
                    </Box>
                  </Box>
                </div>
              </div>
          </Box> 
        </Drawer>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(withWidth()(PersistentDrawerLeft)));
