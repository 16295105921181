import { Box, Input, InputAdornment, MenuItem, Select, Typography, makeStyles, styled } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import React , {useState} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import CustomStatus from "./CustomStatus";
import { boolean } from "yup";

interface Props {
  value: string,
  placeholder: string,
  onChange?: (e: any)=>void,
  isStatus?: boolean,
  isDealValue?: boolean,
  isService?: boolean,
  testId?: string,
  onSelect?: (e: any) => void,
  isSearch?: boolean;
  isCustomerName?:boolean;
  listSearchCustomerName?: any;
  
  isVehicleName?: boolean;
  isDealId?: boolean;
  isVehicleNumber?: boolean;
  isClientName?: boolean;
  listSearchVehicleName?: any;
  listSearchDealId?: any;
  listSearchVehicleNumber?: any;
  listSearchClientName?: any;
  closeCalendar?: any;
}

const CustomSelectSearch = ({closeCalendar, value, placeholder, onChange,onSelect,isSearch,isCustomerName,listSearchCustomerName,isVehicleName,isDealId,isVehicleNumber, isClientName, listSearchClientName, listSearchDealId, listSearchVehicleNumber, listSearchVehicleName, isStatus, isDealValue, isService, testId}: Props) => {
  const classes = useStyles();
  const [visibleSearchValue, setVisibleSearchValue] = useState(false);
  return <CustomSelectStyle>
    <Select 
      data-testid={testId}
      className="inputField"
      fullWidth
      displayEmpty
      type="date"
      disableUnderline
      value={value}
      onMouseDown={()=>
        closeCalendar && closeCalendar()
      }
      IconComponent={()=><ExpandMoreIcon style={{position: 'absolute', right:0}}/>}
      MenuProps={{
        style: {
          marginTop: '10px',
          marginLeft: '-10px',
        },
        PaperProps:{ className : classes.paper  },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
      renderValue={
        () => renderValue(value, placeholder)
      }
      ><SelectStyle>
        {(isSearch|| isCustomerName|| isVehicleName || isDealId || isVehicleNumber || isClientName) &&
        <Input 
        data-testid={`inputId${testId}`}
        inputRef={input => input && input.focus()}
        disableUnderline
        className="inputField"  
        onChange={(e)=>{
          setVisibleSearchValue(e.target.value !== '')
          onChange && onChange(e);
        }}    
        defaultValue={value} 
        value={value} 
        endAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>}/>}

        {isVehicleName && <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, paddingTop:20}}>
            {visibleSearchValue && listSearchVehicleName.map((e:any)=><Box data-testid="isVehicleName" style={{cursor:'pointer'}}  onClick={()=>onSelect && onSelect({target:{value:e.vahicle_name}})}>
              <Typography className="text1">{e.vahicle_name}<Typography className="text3">ID : {e.id}</Typography></Typography>
            </Box>)}
          </Box> }

          {isCustomerName && <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, paddingTop:20}}>
            {visibleSearchValue && listSearchCustomerName && listSearchCustomerName.map((e:any)=><Box data-testid="isCustomerName" style={{cursor:'pointer'}}  onClick={()=>onSelect && onSelect({target:{value:e.name}})}>
              <Typography className="text1">{e.name}</Typography>
            </Box>)}
          </Box> }

          {isDealId && <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, paddingTop:20}}>
            {visibleSearchValue && listSearchDealId.map((e:any)=><Box data-testid="isDealId" style={{cursor:'pointer'}}  onClick={()=>onSelect && onSelect({target:{value:e}})}>
              <Typography className="text1">{e}</Typography>
            </Box>)}
          </Box> }

          {isVehicleNumber && <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, paddingTop:20}}>
            {visibleSearchValue && listSearchVehicleNumber.map((e:any)=><Box data-testid="isVehicleNumber" style={{cursor:'pointer'}}  onClick={()=>onSelect && onSelect({target:{value:e}})}>
              <Typography className="text1">{e}</Typography>
            </Box>)}
          </Box> }

          {isClientName && <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, paddingTop:20}}>
            {visibleSearchValue && listSearchClientName.map((e:any)=><Box data-testid="isClientName" style={{cursor:'pointer'}}  onClick={()=>onSelect && onSelect({target:{value:e}})}>
              <Typography className="text1">{e}</Typography>
            </Box>)}
          </Box> }
        
        {isStatus && 
        <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, alignItems:'center'}}>
            <Box data-testid="isStatus" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:''}})}>
              <Typography className="text">Any</Typography>
            </Box>
            <Box data-testid="isStatus1" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:'draft'}})}>
              <CustomStatus status="draft"/>
            </Box>
            <Box data-testid="isStatus2" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:'pending_submission'}})}>
              <CustomStatus status="pending_submission"/>
            </Box>
            <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:'pending_approval'}})}>
              <CustomStatus status="pending_approval"/>
            </Box>
            <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:'pending_additional_docs'}})}>
              <CustomStatus status="pending_additional_docs"/>
            </Box>
            <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange && onChange({target:{value:'pending_hard_copy_submission'}})}>
              <CustomStatus status="pending_hard_copy_submission"/>
            </Box>
            <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:'completed'}})}>
              <CustomStatus status="completed"/>
            </Box>
              <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:'pending_hard_copy_handover'}})}>
            <CustomStatus status="pending_hard_copy_handover"/>
            </Box>
          </Box>      
        }

        {isDealValue && 
        <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20}}>
            <Box data-testid="isDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:''}})}>
              <Typography className="text">Any</Typography>
            </Box>
            <Box data-testid="isDealValue1" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:'0-10000'}})}>
              <Typography className="text">0-10000</Typography>
            </Box>
            <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:'10001-20000'}})}>
            <Typography className="text">10001-20000</Typography>
            </Box>
            <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:'20001-30000'}})}>
            <Typography className="text">20001-30000</Typography>
            </Box>
            <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange && onChange({target:{value:'30001-40000'}})}>
            <Typography className="text">30001-40000</Typography>
            </Box>
            <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:'40001-50000'}})}>
            <Typography className="text">40001-50000</Typography>
            </Box>
            <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:'50001-60000'}})}>
              <Typography className="text">50001-60000</Typography>
            </Box>
          </Box>      
        } 

        {isService && 
          <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20}}>
            <Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:''}})}>
              <Typography className="text">Any</Typography>
            </Box>
            <Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:'buying'}})}>
              <Typography className="text">Buying</Typography>
            </Box>
            <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:'selling'}})}>
            <Typography className="text">Selling</Typography>
            </Box>
            <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:'insurance'}})}>
            <Typography className="text">Insurance</Typography>
            </Box>
          </Box>      
        } </SelectStyle>
        
          
    </Select>
  </CustomSelectStyle>
}

const renderValue = (selected: any, message:any) => {
  if (selected !== "") {
    return <Typography className="searchText">{selected}</Typography>;
  }

  return <Typography className="searchText">{message}</Typography>;
};

const SelectStyle = styled(Box)(({ theme }) => ({
  "& .text": {fontFamily:'Outfit', fontSize:'12px', color:theme.typography.h1, fontWeight:400},
  "& .text1": {fontFamily:'Outfit', fontSize:'16px', color:theme.typography.h1, fontWeight:400, display:'flex', flexDirection:'row', gap: 10},
  "& .text2": {fontFamily:'Outfit', fontSize:'16px', color:'#f3f3f365', fontWeight:400},
  "& .inputField": {
    display: "flex",
    height: "32px",
    width:"150px",
    padding: "10px 8px 10px 8px",
    justifyContent: "flex-end",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: (theme as any).typography.body2,
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
  },
}))

const useStyles = makeStyles(theme=>({
  paper: {
    backgroundColor:(theme as any).typography.surface2,
        borderRadius:'8px',
        padding: '8px'
  }, 
  select: {
    '& .textSelect': {
      color: '#F3F3F3', 
      fontSize: '14px', 
      fontFamily: 'Outfit', 
      fontWeight: '400',
    },
    
    '& .wrapText': {
      cursor: 'pointer',
      padding: '8px'
    },
  },
}));

const CustomSelectStyle = styled(Box)(({ theme }) => ({
  
  "& .MuiSelect-select": {
    position:'absolute',
    left: 10,
    width:90
  },
  '& .inputFieldServices': {
    marginTop: '4px',
    borderRadius: 8,
    border: `1px solid ${(theme as any).typography.border}`,
    background: (theme as any).typography.body2,
    padding: '10px 8px',
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    height:"32px",
  },
  "& .searchText": {
    color: (theme as any).typography.h1,
    fontSize: 14,
    fontFamily: 'Outfit', 
    fontWeight: '400', 
  },
  '& .iconX': {
    cursor: 'pointer',
    color: (theme as any).typography.h1,
  },

  '& .wrapStatus': {
    
  },
  '& .selectedService': {
    marginTop:'16px',
    display: 'flex',
    flexDirection:'column'
  },
  "& .inputField": {
    display: "flex",
    height: "32px",
    width:"150px",
    padding: "10px 8px 10px 8px",
    justifyContent: "flex-end",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: (theme as any).typography.body2,
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .dropdownText": {
    color: (theme as any).typography.h1,
    fontSize: 12,
    fontFamily: 'Outfit', 
    fontWeight: '400', 
  },
}))
export default CustomSelectSearch;