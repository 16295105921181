import React, { useEffect, useRef, useState, useCallback } from "react";
import { Message } from "framework/src/Message";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";

import GuarantorDetailsView from "./GuarantorDetailsView.web";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { runEngine } from "framework/src/RunEngine";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface ViewProps {
  testID: string;
  // Customizable Area Start
  width: string;
  
  fullName:string,
  dob:string,
  NRIC: string,
  address: string,
  emailID: string,
  contactNumber: string,
  employer: string,
  jobTitle: string,
  employmentAddress: string,
  grossMonthlySalary: string,

  bank:string,
  tenure: string,
  checkSubmit: ()=>void;
  handleSave:(e:any)=>void;
  formIsDirty: boolean;
  openModalRetrieving: boolean,
  handleCancel: ()=>void;
  listSearchGuarantor: any;
  handleSearchGuarantor: (e:any)=>void;
  handleUploadCPF:(e:any)=>void;
  handleUploadNOA:(e:any)=>void;
  handleUploadSupporting:(e:any)=>void;
  CPFfile: any;
  NOAfile: any;
  supportFile: any;
  CPFfileEdit: any;
  NOAfileEdit: any;
  supportFileEdit: any;
  guarantorDetails: any;
  handleDeleteCPFFileEdit: any;
  handleDeleteNOAFileEdit: any;
  handleDeleteSupportingFileEdit: any;
  dataPDF: any;
  isSaveUOB: boolean;
  isSaveMoneyMax: boolean;
  isSaveDBS: boolean;
  isSaveMaybank: boolean;
  isSaveTembusa: boolean;
  isSaveTokyo: boolean;
  isSaveVM: boolean;
  isSaveHongLeong: boolean;
  handleSavePdfDBS: (file: any) => void;
  handleSavePdfUOB: (file: any) => void;
  handleSavePdfMoneymax: (file: any) => void;
  handleSavePdfMaybank: (file: any) => void;
  handleSavePdfTembusa: (file: any) => void;
  handleSavePdfTokyo: (file: any) => void;
  handleSavePdfVM: (file: any) => void;
  handleSavePdfHongleon: (file: any) => void;
  isLoading: boolean;
  handleMoment:any;
  handleDeleteCPF: () => void;
  handleDeleteNOA: () => void;
  handleDeleteSupporting: () => void;
  selectedGuarantorIndex: any;
  dealDetail: any;
  // Customizable Area End
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: string;
  // Customizable Area End
}

const subscribedMessages = [
  // Customizable Area Start
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
  // Customizable Area End
];

const GuarantorDetails: React.FC<Props> = (props) => {
  // Customizable Area Start
  const searchGuarantorId = useRef("");
  const getDealDetailId = useRef("");
  const updateSalesAndContractId = useRef("");
  const deleteCPFAttachmentId = useRef("");
  const deleteNOAAttachmentId = useRef("");
  const deleteSupportAttachmentId = useRef("");
  const savePDFId = useRef("");
  
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [openModalRetrieving,setOpenModalRetrieving] = useState(false);
  const [guarantorDetails, setGuarantorDetails] = useState();
  const [fullName, setFullName] = useState('');
  const [NRIC, setNRIC] = useState('');
  const [address, setAddress] = useState('');
  const [emailID, setEmailID] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [employer, setEmployer] = useState('');
  const [dob, setDob] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [employmentAddress, setEmploymentAddress] = useState('');
  const [grossMonthlySalary, setGrossMonthlySalary] = useState('');
  const [bank, setBank] = useState('');
  const [tenure, setTenure] = useState('');   
  const [listSearchGuarantor, setListSearchGuarantor] = useState([]); 
  const [CPFfile, setCPFfile] = useState<any>();
  const [NOAfile, setNOAfile] = useState<any>();
  const [supportFile, setSupportFile] = useState<any>(null);
  const [CPFfileEdit, setCPFfileEdit] = useState<any>(null);
  const [NOAfileEdit, setNOAfileEdit] = useState<any>(null);
  const [supportFileEdit, setSupportFileEdit] = useState<any>();
  const [isCPFfileEditDelete, setCPFfileEditDelete] = useState(false);
  const [isNOAfileEditDelete, setNOAfileEditDelete] = useState(false);
  const [isSupportFileEditDelete, setSupportFileEditDelete] = useState(false);
  const [data, setData] = useState<any>(null);
  const [dataPDF, setDataPDF] = useState<any>(null);
  const [isSave, setIsSave] = useState(false);
  const [isGenerate, setIsGenerate] = useState(true);
  const [isSaveUOB, setIsSaveUOB] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSaveMoneyMax, setIsSaveMoneyMax] = useState(false)
  const [isSaveDBS, setIsSaveDBS] = useState(false)
  const [isSaveMaybank, setIsSaveMaybank] = useState(false)
  const [isSaveTembusa, setIsSaveTembusa] = useState(false)
  const [isSaveTokyo, setIsSaveTokyo] = useState(false)
  const [isSaveVM, setIsSaveVM] = useState(false)
  const [isSaveHongLeong, setIsSaveHongLeong] = useState(false)
  const [PdfFileUOB, setPdfFileUOB] = useState<any>(null);
  const [PdfFileMoneyMax, setPdfFileMoneyMax] = useState<any>(null);
  const [PdfFileDBS, setPdfFileDBS] = useState<any>(null);
  const [PdfFileMaybank, setPdfFileMaybank] = useState<any>(null);
  const [PdfFileTembusa, setPdfFileTembusa] = useState<any>(null);
  const [PdfFileTokyo, setPdfFileTokyo] = useState<any>(null);
  const [PdfFileVM, setPdfFileVM] = useState<any>(null);
  const [PdfFileHongleong, setPdfFileHongleong] = useState<any>(null);
  const [dealDetail, setDealDetail] = useState<any>();
  const [selectedGuarantorIndex, setSelectedGuarantorIndex] = useState<any>();
  // Customizable Area End

  useEffect(() => {
    setReceiveCallback(receive);

    subscribedMessages.forEach((message) => subscribe(message));

    // Customizable Area Start
    getDealDetail(props.navigation.getParam('dealId'))
    // Customizable Area End

    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, []);

  const receive = (from: string, message: Message) => {
    // Customizable Area Start
    debugLog("API Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
        extractNetworkResponse(message);
      if (responseJson) {
        switch (apiRequestCallId) {
          case searchGuarantorId.current:
            searchGuarantorReceiver(responseJson)
            break;
          case getDealDetailId.current:
            getDealDetailReceiver(responseJson)
            break;
          case updateSalesAndContractId.current:
            updateSalesAndContractReceiver(responseJson)
            break;
          case deleteCPFAttachmentId.current:
            deleteCPFAttachmentReceiver();
            break;
          case deleteNOAAttachmentId.current:
            deleteNOAAttachmentReceiver();
            break;
          case deleteSupportAttachmentId.current:
            deleteSupportAttachmentReceiver();
            break;
          case savePDFId.current:
            props.navigation.navigate("DealDetail", {dealId: props.navigation.getParam('dealId')})
          default:
            break;
        }
      }

      // if (responseJson?.errors) {
      //   alert(JSON.stringify(responseJson.errors));
      // }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  const {
    sendMessage,
    sendBlockMessage,
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    debugLog,
    unsubscribeFromMessage,
  } = useRunEngine();

  const { extractNetworkResponse, hideKeyboard, isPlatformWeb } =
    useBlockHelpers();

  const getDealDetail = async (dealId: any) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };
    sendNetworkRequest(
      getDealDetailId, 
      configJSON.listOfOrdersMethod,
      `${configJSON.getOrderByIdEndPoint}/${dealId}`, 
      header )
  }

  const getDealDetailReceiver = (responseJson: any)=>{
    const data = responseJson.data.attributes.guarantor_details.data.find((e: { id: any; })=>e.id===props.navigation.getParam("guarantorId"))
    setGuarantorDetails(data);
    setSelectedGuarantorIndex(responseJson.data.attributes.guarantor_details.data.findIndex((e: { id: any; })=>e.id===props.navigation.getParam("guarantorId")))
    setDealDetail(responseJson.data);
    setCPFfileEdit(data.attributes.cpf_contribution_for_last_six_month);
    setNOAfileEdit(data.attributes.noa_for_last_two_year);
    setSupportFileEdit(data.attributes.supporting_docs);
  }

  const checkSubmit = ()=>{
    setFormIsDirty(true);
  }

  const handleSearchGuarantor = async (args: any) => {
    if(args.target.value !== undefined && args.target.value !== ""){
      const tokenValue = await getStorageData("authToken")
      const header = {
        "Content-Type": configJSON.apiContentType,
        "token": tokenValue
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getCustomerEndPoint}/search?attribute=name&query=${args.target.value}&page=1&per_page=10`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.listOfOrdersMethod
      );
      searchGuarantorId.current = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }else {
      setListSearchGuarantor([])
    }
  }

  const searchGuarantorReceiver = (responseJson: any)=>{
    setListSearchGuarantor(responseJson?.data?.data ?? [])
  }  

  const handleUploadCPF = (event:any)=>{
    const input = event.target;
    const files = input.files;
    setCPFfile(files[0])
  }
  const handleUploadNOA = (event:any)=>{
    const input = event.target;
    const files = input.files;
    setNOAfile(files[0])
  }
  const handleUploadSupporting = (event:any)=>{
    const input = event.target;
    const files = input.files;
    setSupportFile(files[0])
  }

  const handleSave = async (args: any)=>{
    setData(args)
    isCPFfileEditDelete && deleteCPF('cpf_contribution_for_last_six_month')
    isNOAfileEditDelete && deleteNOA('noa_for_last_two_year')
    isSupportFileEditDelete && deleteSupport('supporting_docs')
    if(!isCPFfileEditDelete && !isNOAfileEditDelete && !isSupportFileEditDelete){
      setIsSave(true)
    }
  }
  
  useEffect(()=>{
    if(data !== null && isSave){
      save(data)
    }
  }, [isSave, data])

  const save = async (args: any)=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue,
    };
    let formDataGuarantor = new FormData();
    
    formDataGuarantor.append(`job_title`, args.jobTitle);
    formDataGuarantor.append(`full_name`, args.fullName);
    formDataGuarantor.append(`email`, args.emailID);
    formDataGuarantor.append(`date_of_birth`, args.dob);
    formDataGuarantor.append(`employement_address`, args.employmentAddress);
    formDataGuarantor.append(`nric_number`, args.NRIC);
    formDataGuarantor.append(`contact_number`, args.contactNumber);
    formDataGuarantor.append(`employer`, args.employer);
    formDataGuarantor.append(`gross_monthly_salary`, args.grossMonthlySalary);
    formDataGuarantor.append(`address`, args.address);
    CPFfile && formDataGuarantor.append(`cpf_contribution_for_last_six_month`, CPFfile);
    NOAfile && formDataGuarantor.append(`noa_for_last_two_year`, NOAfile);
    supportFile && formDataGuarantor.append(`supporting_docs`, supportFile);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    updateSalesAndContractId.current = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateGuarantor}?guarantor_id=${props.navigation.getParam('guarantorId')}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cencelOrderMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataGuarantor,
    );
    sendMessage(requestMessage.id, requestMessage);
  }

  const deleteCPF =async(data: string)=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };

    sendNetworkRequest(
      deleteCPFAttachmentId, 
      configJSON.deleteOrderMethod,
      `${configJSON.deleteGuarantorAttachment}?attachment_name=${data}&guarantor_id=${props.navigation.getParam('guarantorId')}`,
      header )
  }

  const deleteCPFAttachmentReceiver = ()=>{
    setCPFfileEditDelete(false);
    if(!isNOAfileEditDelete && !isSupportFileEditDelete){
      setIsSave(true)
    }
  }

  const deleteNOA=async(data: string)=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };
    sendNetworkRequest(
      deleteNOAAttachmentId, 
      configJSON.deleteOrderMethod,
      `${configJSON.deleteGuarantorAttachment}?attachment_name=${data}&guarantor_id=${props.navigation.getParam('guarantorId')}`,
      header )
  }

  const deleteNOAAttachmentReceiver = ()=>{
    setNOAfileEditDelete(false);
    if(!isNOAfileEditDelete && !isSupportFileEditDelete){
      setIsSave(true)
    }
  }
  
  const handleMoment = (args:any, args2: string,)=>{
    return moment(args, args2).format('YYYY-MM-DD')
  }

  const deleteSupport=async(data: string)=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };
    sendNetworkRequest(
      deleteSupportAttachmentId, 
      configJSON.deleteOrderMethod,
      `${configJSON.deleteGuarantorAttachment}?attachment_name=${data}&guarantor_id=${props.navigation.getParam('guarantorId')}`,
      header )
  }

  const deleteSupportAttachmentReceiver = ()=>{
    setSupportFileEditDelete(false);
    if(!isCPFfileEditDelete && !isNOAfileEditDelete){
      setIsSave(true)
    }
  }

  const updateSalesAndContractReceiver = (responseJson: any) => {
    setIsLoading(true);
    let dealDetail = responseJson.data
    setDealDetail(dealDetail);
    
    let tempDataGuarantor :any ={};
    tempDataGuarantor.vehicleName = dealDetail.attributes.vahicle_name;
    tempDataGuarantor.vehiclePrice= dealDetail.attributes.vehicle_price;
    tempDataGuarantor.vehicleId=dealDetail.attributes.vehicle_id;
    tempDataGuarantor.vehicleNumber=dealDetail.attributes.vehicle_number;
    tempDataGuarantor.vehicleManufactured= dealDetail.attributes.vehicle_manufactured_year;
    tempDataGuarantor.vehicleMake= dealDetail.attributes.vehicle_make;
    tempDataGuarantor.vehicleModel= dealDetail.attributes.vehicle_model;
    tempDataGuarantor.engineNumber= dealDetail.attributes.engine_number;
    tempDataGuarantor.chassisNumber= dealDetail.attributes.chassis_number;
    tempDataGuarantor.firstRegistrationDate = moment(dealDetail.attributes.first_registration_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    tempDataGuarantor.fullName= dealDetail.attributes.customer_detail.full_name;
    tempDataGuarantor.dob= dealDetail.attributes.customer_detail.date_of_birth;
    tempDataGuarantor.NRIC= dealDetail.attributes.customer_detail.nric_number;
    tempDataGuarantor.address= dealDetail.attributes.customer_detail.address;
    tempDataGuarantor.emailID= dealDetail.attributes.customer_detail.email;
    tempDataGuarantor.contactNumber= dealDetail.attributes.customer_detail.contact_number;
    tempDataGuarantor.employer= dealDetail.attributes.customer_detail.employer;
    tempDataGuarantor.jobTitle= dealDetail.attributes.customer_detail.job_title;
    tempDataGuarantor.employmentAddress= dealDetail.attributes.customer_detail.employement_address;
    tempDataGuarantor.grossMonthlySalary= dealDetail.attributes.customer_detail.gross_monthly_salary;
    for(let i = 0; i< dealDetail.attributes.bank_attachments.length; i++){
      switch (dealDetail.attributes.bank_attachments[i].name) {
        case "UOB":
          setIsSaveUOB(true)
          break;
        case "Moneymax":
          setIsSaveMoneyMax(true)
          break;
        case "Maybank":
          setIsSaveMaybank(true)
          break;
        case "DBS bank Limited":
          setIsSaveDBS(true)
          break;
        case "Tembusu Financial Services":
          setIsSaveTembusa(true)
          break;
        case "Tokyo Century":
          setIsSaveTokyo(true)
          break;
        case "VM":
          setIsSaveVM(true)
          break;
        case "Hong Leong Finance":
          setIsSaveHongLeong(true)
          break;
        default:
          break;
      }
    }
    switch (dealDetail.attributes.financial_details[0].loan_tennure) {
      case '12 months':
        tempDataGuarantor.tenure = 12;
        break;
      case '24 months':
        tempDataGuarantor.tenure = 24;
        break;
      case '36 months':
        tempDataGuarantor.tenure = 36;
        break;
      case '48 months':
        tempDataGuarantor.tenure = 48;
        break;
      case '60 months':
        tempDataGuarantor.tenure = 60;
        break;
      default:
        break;
    }
    let list = [];
    for(let i=0; i < dealDetail?.attributes.guarantor_details.data.length; i++){
      let guarantor = dealDetail?.attributes.guarantor_details.data[i].attributes;
      let temp : any = {};
      temp.NRICGuarantor = guarantor.nric_number;
      temp.addressGuarantor = guarantor.address;
      temp.contactNumberGuarantor = guarantor.contact_number;
      temp.dobGuarantor = guarantor.date_of_birth;
      temp.emailIDGuarantor = guarantor.email;
      temp.employerGuarantor = guarantor.employer;
      temp.employmentAddressGuarantor = guarantor.employement_address;
      temp.fullNameGuarantor = guarantor.full_name;
      temp.grossMonthlySalaryGuarantor = guarantor.gross_monthly_salary
      temp.jobTitleGuarantor = guarantor.job_title;
      list.push(temp);
    }
    tempDataGuarantor.guarantor = list;
    
    setDataPDF(tempDataGuarantor);
  }

  useEffect(()=>{
    let length =  [PdfFileUOB ,PdfFileMoneyMax, PdfFileDBS, PdfFileHongleong, PdfFileMaybank, PdfFileTembusa, PdfFileVM, PdfFileTokyo].filter(args=>args!== null).length;
    if(length !==0 && length === dealDetail?.attributes.bank_attachments.length && isGenerate){
      setIsGenerate(false);
      updateRegenerateDocument()
    }
  }, [PdfFileUOB ,PdfFileMoneyMax, PdfFileDBS, PdfFileHongleong, PdfFileMaybank, PdfFileTembusa, PdfFileVM, PdfFileTokyo])

  const updateRegenerateDocument= async()=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue,
    };
    let formDataGuarantor = new FormData();
    formDataGuarantor.append('financial_details_attributes[0][loan_tennure]',dealDetail?.attributes.financial_details[0].loan_tennure)
    isSaveUOB && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][0][image]`, PdfFileUOB);
    isSaveUOB && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][0][name]`, "UOB");
    isSaveMoneyMax && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][1][image]`, PdfFileMoneyMax);
    isSaveMoneyMax && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][1][name]`, "Moneymax");
    isSaveTokyo && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][2][image]`, PdfFileTokyo);
    isSaveTokyo && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][2][name]`, "Tokyo Century");
    isSaveDBS && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][3][image]`, PdfFileDBS);
    isSaveDBS && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][3][name]`, "DBS bank Limited");
    isSaveHongLeong && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][4][image]`, PdfFileHongleong);
    isSaveHongLeong && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][4][name]`, "Hong Leong Finance");
    isSaveTembusa && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][5][image]`, PdfFileTembusa);
    isSaveTembusa && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][5][name]`, "Tembusu Financial Services");
    isSaveMaybank && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][6][image]`, PdfFileMaybank);
    isSaveMaybank && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][6][name]`, "Maybank");
    isSaveVM && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][7][image]`, PdfFileVM);
    isSaveVM && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][7][name]`, "VM");
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    savePDFId.current = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listOfOrdersEndPoints}/${props.navigation.getParam('dealId')}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cencelOrderMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataGuarantor,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const handleCancel = () =>{
    props.navigation.goBack()
  }

  const handleDeleteCPFFileEdit =()=>{
    setCPFfileEditDelete(true)
    setCPFfileEdit(null);
  }
  const handleDeleteNOAFileEdit =()=>{
    setNOAfileEditDelete(true)
    setNOAfileEdit(null)
  }
  const handleDeleteSupportingFileEdit =()=>{
    setSupportFileEditDelete(true)
    setSupportFileEdit(null);
  }
  
  const savePdfUOB = (file: any) => {
    setPdfFileUOB(file)
  }
  const savePdfMaybank = (file: any) => {
    setPdfFileMaybank(file)
  }
  const savePdfTembusa = (file: any) => {
    setPdfFileTembusa(file)
  }
  const savePdfTokyo = (file: any) => {
    setPdfFileTokyo(file)
  }
  const savePdfVM = (file: any) => {
    setPdfFileVM(file)
  }
  const savePdfMoneyMax = (file: any) => {
    setPdfFileMoneyMax(file)
  }
  const savePdfDBS = (file: any) => {
    setPdfFileDBS(file)
  }
  const savePdfHongleon = (file: any) => {
     setPdfFileHongleong(file)
  }


  const handleSavePdfUOB = useCallback(savePdfUOB, []);
  const handleSavePdfMoneymax = useCallback(savePdfMoneyMax, []);
  const handleSavePdfDBS = useCallback(savePdfDBS, []);
  const handleSavePdfMaybank = useCallback(savePdfMaybank, []);
  const handleSavePdfTembusa = useCallback(savePdfTembusa, []);
  const handleSavePdfTokyo = useCallback(savePdfTokyo, []);
  const handleSavePdfVM = useCallback(savePdfVM, []);
  const handleSavePdfHongleon = useCallback(savePdfHongleon, []);

  const handleDeleteCPF = () => {
    setCPFfile(undefined)
  }
  const handleDeleteNOA = () => {
    setNOAfile(undefined)
  }
  const handleDeleteSupporting = () => {
    setSupportFile(undefined)
  }
  // Customizable Area End

  const viewProps: ViewProps = {
    testID: "GuarantorDetails",
    // Customizable Area Start
    width: props.width,
    
    fullName:fullName,
    dob:dob,
    NRIC: NRIC,
    address: address,
    emailID: emailID,
    contactNumber: contactNumber,
    employer: employer,
    jobTitle: jobTitle,
    employmentAddress: employmentAddress,
    grossMonthlySalary: grossMonthlySalary,

    bank:bank,
    tenure: tenure,
    formIsDirty,
    checkSubmit,
    handleSave,
    openModalRetrieving,
    handleCancel,
    listSearchGuarantor,
    handleSearchGuarantor,
    handleUploadCPF,
    handleUploadNOA,
    handleUploadSupporting,
    CPFfile,
    NOAfile,
    supportFile,
    CPFfileEdit,
    NOAfileEdit,
    supportFileEdit,
    guarantorDetails,
    handleDeleteCPFFileEdit,
    handleDeleteNOAFileEdit,
    handleDeleteSupportingFileEdit,
    dataPDF,
    isSaveUOB,
    isSaveMoneyMax,
    isSaveDBS,
    isSaveMaybank,
    isSaveTembusa,
    isSaveTokyo,
    isSaveVM,
    isSaveHongLeong,
    handleSavePdfDBS,
    handleSavePdfUOB,
    handleSavePdfMoneymax,
    handleSavePdfMaybank,
    handleSavePdfTembusa,
    handleSavePdfTokyo,
    handleSavePdfVM,
    handleSavePdfHongleon,
    isLoading,
    handleMoment,
    handleDeleteCPF,
    handleDeleteNOA,
    handleDeleteSupporting,
    selectedGuarantorIndex,
    dealDetail,
    // Customizable Area End
  };

  return <GuarantorDetailsView {...viewProps} />;
};

export default GuarantorDetails;
