export const UploadImg = require("../assets/uploder.png");
export const Facebook = require("../assets/facebook.png");
export const Instagram = require("../assets/instagram.png");
export const Tiktok = require("../assets/tiktok.png");
export const Logo = require("../assets/logo.png");
export const CarImage = require("../assets/carImage.png");
export const Edit = require("../assets/edit.png");
export const Dummy = require("../assets/dummy.png");
export const Close = require("../../../components/src/close.png");
export const QRCode = require("../assets/qrcode.png");
export const FileIcon = require("../assets/file.png");
export const DeleteIcon = require("../assets/delete.png");
