Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.httpPutMethod =  "PUT";
exports.profileResetPasswordAPiEndPoint = "account_block/reset_password";
exports.landingPageEndPoint = "bx_block_dashboard/landing_pages"; 
exports.accountingAndPayroll ='bx_block_dashboard/candidates'
exports.btnExampleTitle = "CLICK ME";
exports.getSocialMediaEndpointDashboard = "bx_block_cfintegrationwithfacebook2/social_medias";
exports.exampleAPiMethodDashboard = "DELETE";
exports.getSocialMediaEndpoint = "bx_block_cfintegrationwithfacebook2/social_medias";

// Customizable Area End