import { Box, Typography, styled } from "@material-ui/core";
import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

interface Props {
  status: string
}

const CustomStatusDealDetail = ({status}: Props) => {
  switch (status) {
    case 'draft':
      return <CustomStatusStyle>
        <Box className="draftBox">
          <Typography className="draftText" noWrap>Draft</Typography>
          <KeyboardArrowDownIcon/>
        </Box>
      </CustomStatusStyle>
    case 'pending_hard_copy_handover':
      return <CustomStatusStyle>
        <Box className="pending_hardcopy_handoverWrap">
          <Typography className="pending_hardcopy_handoverTextWrap" noWrap>Pending Hard copy Handover</Typography>
          <KeyboardArrowDownIcon/>
        </Box>
      </CustomStatusStyle>
    case 'pending_submission':
      return <CustomStatusStyle>
        <Box className="pending_submissionWrap">
          <Typography className="pending_submissionTextWrap" noWrap>Pending submission</Typography>
          <KeyboardArrowDownIcon/>
        </Box>
      </CustomStatusStyle>
    case 'pending_approval':
      return <CustomStatusStyle>
        <Box className="pending_approvalWrap">
          <Typography className="pending_approvalTextWrap" noWrap>Pending Approval</Typography>
          <KeyboardArrowDownIcon/>
        </Box>
      </CustomStatusStyle>
    case 'pending_hard_copy_submission':
      return <CustomStatusStyle>
        <Box className="pending_hard_copy_submissionWrap">
          <Typography className="pending_hard_copy_submissionTextWrap" noWrap>Pending Hard copy submission</Typography>
          <KeyboardArrowDownIcon/>
        </Box>
      </CustomStatusStyle>
    case 'pending_additional_docs':
      return <CustomStatusStyle>
        <Box className="pending_additional_docsWrap">
          <Typography className="pending_additional_docsTextWrap" noWrap>Pending additional docs (B/G)</Typography>
          <KeyboardArrowDownIcon/>
        </Box>
      </CustomStatusStyle>
          case 'completed':
      return <CustomStatusStyle>
        <Box className="completedWrap">
          <Typography className="completedTextWrap" noWrap>Complete</Typography>
          <KeyboardArrowDownIcon/>
        </Box>
      </CustomStatusStyle>
    default:
      return <></>
  }
}


const CustomStatusStyle = styled(Box)({
  '& .draftBox':{cursor:'pointer',border:'1px solid #f3f3f3', paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2, width: 'fit-content', borderRadius: 40, alignItems:'center', display:'flex',flexDirection:'row', justifyContent:'space-between'},
  '& .draftText': {color:'#F3F3F3', fontFamily:'Outfit', fontWeight:400, fontSize:12,},
  '& .pending_hardcopy_handoverWrap':{cursor:'pointer',backgroundColor:'#D1FAE5', paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2, width: 'fit-content', borderRadius: 40,alignItems:'center', display:'flex', flexDirection:'row', justifyContent:'space-between',
  '@media (max-width: 600px)': {
    paddingLeft:4, paddingRight:4, paddingTop:1, paddingBottom:1,
  },
  },
  '& .pending_submissionWrap':{ '@media (max-width: 600px)': {
    paddingLeft:4, paddingRight:4, paddingTop:1, paddingBottom:1,
  },cursor:'pointer',backgroundColor:'#FEE2E2', paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2, width: 'fit-content', borderRadius: 40, alignItems:'center', display:'flex',flexDirection:'row', justifyContent:'space-between'},
  '& .pending_approvalWrap':{ '@media (max-width: 600px)': {
    paddingLeft:4, paddingRight:4, paddingTop:1, paddingBottom:1,
  },cursor:'pointer',backgroundColor:'#DC2626', paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2, width: 'fit-content', borderRadius: 40,alignItems:'center',  display:'flex',flexDirection:'row', justifyContent:'space-between'},
  '& .pending_hard_copy_submissionWrap':{ '@media (max-width: 600px)': {
    paddingLeft:4, paddingRight:4, paddingTop:1, paddingBottom:1,
  },cursor:'pointer',backgroundColor:'#D97706', paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2, width: 'fit-content', borderRadius: 40,alignItems:'center',  display:'flex',flexDirection:'row', justifyContent:'space-between'},
  '& .pending_additional_docsWrap':{ '@media (max-width: 600px)': {
    paddingLeft:4, paddingRight:4, paddingTop:1, paddingBottom:1,
  },cursor:'pointer',backgroundColor:'#FEF3C7', paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2, width: 'fit-content', borderRadius: 40, alignItems:'center', display:'flex',flexDirection:'row', justifyContent:'space-between'},
  '& .completedWrap':{ '@media (max-width: 600px)': {
    paddingLeft:4, paddingRight:4, paddingTop:1, paddingBottom:1,
  },cursor:'pointer',backgroundColor:'#059669', paddingLeft:8, paddingRight:8, paddingTop:2, paddingBottom:2, width: 'fit-content', borderRadius: 40, alignItems:'center', display:'flex',flexDirection:'row', justifyContent:'space-between'},

  '& .pending_hardcopy_handoverTextWrap':{color:'#059669', fontFamily:'Outfit', fontWeight:400, fontSize:12,
    '@media (max-width: 600px)': {
      fontSize: 8,
    },
  },
  '& .pending_submissionTextWrap':{'@media (max-width: 600px)': {
    fontSize: 8,
  },color:'#DC2626', fontFamily:'Outfit', fontWeight:400, fontSize:12},
  '& .pending_approvalTextWrap':{'@media (max-width: 600px)': {
    fontSize: 8,
  },color:'#FEE2E2', fontFamily:'Outfit', fontWeight:400, fontSize:12},
  '& .pending_hard_copy_submissionTextWrap':{'@media (max-width: 600px)': {
    fontSize: 8,
  },color:'#FEF3C7', fontFamily:'Outfit', fontWeight:400, fontSize:12},
  '& .pending_additional_docsTextWrap':{'@media (max-width: 600px)': {
    fontSize: 8,
  },color:'#D97706', fontFamily:'Outfit', fontWeight:400, fontSize:12},
  '& .completedTextWrap':{'@media (max-width: 600px)': {
    fontSize: 8,
  },color:'#D1FAE5', fontFamily:'Outfit', fontWeight:400, fontSize:12},

})
export default CustomStatusDealDetail;