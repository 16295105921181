import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { Message } from "framework/src/Message";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";

import NewSalesAndContractView from "./NewSalesAndContractView.web";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { runEngine } from "framework/src/RunEngine";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface ViewProps {
  testID: string;
  // Customizable Area Start
  width: string;
  listBank : any;
  vehicleName: string,
  vehiclePrice: string,
  vehicleId: string,
  vehicleNumber: string,
  vehicleManufactured: string,
  vehicleMake: string,
  vehicleModel: string,
  engineNumber: string,
  chassisNumber: string,
  firstRegistrationDate: string,

  fullName: string,
  dob: string,
  NRIC: string,
  address: string,
  emailID: string,
  contactNumber: string,
  employer: string,
  jobTitle: string,
  employmentAddress: string,
  grossMonthlySalary: string,

  bank: string,
  tenure: string,
  listSelectedBank: any,
  checkSubmit: () => void,
  handleSubmit: (args: any) => void,
  formIsDirty: boolean;
  openModalGuarantor: boolean,
  handleModalGuarantor: () => void,
  handleNavigateGuarantorDetail: () => void,
  handleSearchVehicle: (args: any) => void,
  listSearchVehicle: any;
  listSearchCustomer: any;
  handleSearchCustomer: (args: any) => void,
  CPFfile: any;
  NOAfile: any;
  supportFile: any;
  handleUploadCPF: (args: any) => void;
  handleUploadNOA: (args: any) => void;
  handleUploadSupporting: (args: any) => void;
  handleDeleteCPF: () => void;
  handleDeleteNOA: () => void;
  handleDeleteSupporting: () => void;

  checkSubmitGuarantor: () => void;
  handleSaveGuarantor: (args: any) => void;
  formIsDirtyGuarantor: boolean;
  openModalRetrieving: boolean,
  handleCancelGuarantor: () => void;
  listSearchGuarantor: any;
  handleSearchGuarantor: (args: any) => void;
  handleUploadCPFGuarantor: (args: any) => void;
  handleUploadNOAGuarantor: (args: any) => void;
  handleUploadSupportingGuarantor: (args: any) => void;
  handleDeleteCPFGuarantor: (args: any) => void;
  handleDeleteNOAGuarantor: (args: any) => void;
  handleDeleteSupportingGuarantor: (args: any) => void;
  CPFfileGuarantor: any;
  NOAfileGuarantor: any;
  supportFileGuarantor: any;
  isAddGuarantor: boolean;
  listAddedGuarantor: any;
  editGuarantor: (args: number) => void;
  deleteGuarantor: (args: number) => void;
  isEditGuarantor: boolean;

  setVehicleName: Dispatch<SetStateAction<string>>;
  setVehiclePrice: Dispatch<SetStateAction<string>>;
  setVehicleId: Dispatch<SetStateAction<string>>;
  setVehicleNumber: Dispatch<SetStateAction<string>>;
  setVehicleManufactured: Dispatch<SetStateAction<string>>;
  setVehicleMake: Dispatch<SetStateAction<string>>;
  setVehicleModel: Dispatch<SetStateAction<string>>;
  setEngineNumber: Dispatch<SetStateAction<string>>;
  setChassisNumber: Dispatch<SetStateAction<string>>;
  setFirstRegistrationDate: Dispatch<SetStateAction<string>>;
  setFullName: Dispatch<SetStateAction<string>>;
  setNRIC: Dispatch<SetStateAction<string>>;
  setAddress: Dispatch<SetStateAction<string>>;
  setEmailID: Dispatch<SetStateAction<string>>;
  setContactNumber: Dispatch<SetStateAction<string>>;
  setEmployer: Dispatch<SetStateAction<string>>;
  setDob: Dispatch<SetStateAction<string>>;
  setJobTitle: Dispatch<SetStateAction<string>>;
  setEmploymentAddress: Dispatch<SetStateAction<string>>;
  setGrossMonthlySalary: Dispatch<SetStateAction<string>>;
  setListSelectedBank: Dispatch<SetStateAction<any>>;
  setTenure: Dispatch<SetStateAction<string>>;
  selectedGuarantorIndex: any;
  handleCancel: () => void;
  data: any;
  isSaveUOB: boolean;
  isSaveMoneyMax: boolean;
  isSaveDBS: boolean;
  isSaveMaybank: boolean;
  isSaveTembusa: boolean;
  isSaveTokyo: boolean;
  isSaveVM: boolean;
  isSaveHongLeong: boolean;
  handleSavePdfDBS: (file: any) => void;
  handleSavePdfUOB: (file: any) => void;
  handleSavePdfMoneymax: (file: any) => void;
  handleSavePdfMaybank: (file: any) => void;
  handleSavePdfTembusa: (file: any) => void;
  handleSavePdfTokyo: (file: any) => void;
  handleSavePdfVM: (file: any) => void;
  handleSavePdfHongleon: (file: any) => void;
  handleMoment: (args: any, args2: string) => any;
  saveAsDraft: ()=>void;
  toggleCalendarFirstRegistrationDate: ()=>void;
  isOpenCalendarFirstRegistrationDate: boolean;
  toggleCalendarDOBCustomer: ()=>void;
  isOpenCalendarDOBCustomer: boolean;
  closeCalendar: ()=>void;
  toggleCalendarDOBGuarantor: ()=>void;
  isOpenCalendarDOBGuarantor: boolean;
  dealDetail: any;
  listGuarantorEditScreen: any;
  isEdit: boolean;
  deleteGuarantorEdit: ()=>void;
  CPFfileEdit: any;
  NOAfileEdit: any;
  supportFileEdit: any;
  handleDeleteCPFFileEdit: any;
  handleDeleteNOAFileEdit: any;
  handleDeleteSupportingFileEdit: any;
  isLoading: boolean;
  // Customizable Area End
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: string;
  // Customizable Area End
}

const subscribedMessages = [
  // Customizable Area Start
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
  MessageEnum.NavigationPayLoadMessage,
  // Customizable Area End
];

const NewSalesAndContract: React.FC<Props> = (props) => {
  // Customizable Area Start
  const createSalesAndContractId = useRef("");
  const searchVehicleId = useRef("");
  const vehicleDetailsID = useRef("");
  const searchCustomerId = useRef("");
  const searchGuarantorId = useRef("");
  const getListBanksId = useRef("");
  const getDealDetailId = useRef("");
  const deleteGuarantorId = useRef('');
  const editSalesAndContractId = useRef('');
  const deleteCPFAttachmentId = useRef("");
  const deleteNOAAttachmentId = useRef("");
  const deleteSupportAttachmentId = useRef("");
 const deletedGuarantorId = useRef('') ;

  const [dealId, setDealId] = useState();
  const [dealDetail, setDealDetail] = useState<any>();
  const [listBank, setListBank] = useState([]);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [formIsDirtyGuarantor, setFormIsDirtyGuarantor] = useState(false);
  const [openModalGuarantor, setOpenModalGuarantor] = useState(false);
  const [openModalRetrieving, setOpenModalRetrieving] = useState(false);
  const [vehicleName, setVehicleName] = useState('');
  const [vehiclePrice, setVehiclePrice] = useState('');
  const [vehicleId, setVehicleId] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [vehicleManufactured, setVehicleManufactured] = useState('');
  const [vehicleMake, setVehicleMake] = useState('');
  const [vehicleModel, setVehicleModel] = useState('');
  const [engineNumber, setEngineNumber] = useState('');
  const [chassisNumber, setChassisNumber] = useState('');
  const [firstRegistrationDate, setFirstRegistrationDate] = useState('');
  const [fullName, setFullName] = useState('');
  const [NRIC, setNRIC] = useState('');
  const [address, setAddress] = useState('');
  const [emailID, setEmailID] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [employer, setEmployer] = useState('');
  const [dob, setDob] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [employmentAddress, setEmploymentAddress] = useState('');
  const [grossMonthlySalary, setGrossMonthlySalary] = useState('');
  const [CPFfile, setCPFfile] = useState<any>(null);
  const [NOAfile, setNOAfile] = useState<any>(null);
  const [supportFile, setSupportFile] = useState<any>(null);
  const [bank, setBank] = useState('');
  const [tenure, setTenure] = useState('');
  const [listSelectedBank, setListSelectedBank] = useState<any>([]);
  const [listSearchCustomer, setListSearchCustomer] = useState([]);
  const [searchVehicleValue, setSearchVehicleValue] = useState('');
  const [listSearchVehicle, setListSearchVehicle] = useState([]);
  const [listSearchGuarantor, setListSearchGuarantor] = useState([]);
  const [CPFfileGuarantor, setCPFfileGuarantor] = useState();
  const [NOAfileGuarantor, setNOAfileGuarantor] = useState();
  const [supportFileGuarantor, setSupportFileGuarantor] = useState();
  const [isAddGuarantor, setIsAddGuarantor] = useState(false)
  const [isEditGuarantor, setIsEditGuarantor] = useState(false)
  const [selectedGuarantorIndex, setSelectedGuarantorIndex] = useState<any>();
  const [listAddedGuarantor, setListAddedGuarantor] = useState<any>([]);
  const [isSaveUOB, setIsSaveUOB] = useState(false)
  const [isSaveMoneyMax, setIsSaveMoneyMax] = useState(false)
  const [isSaveDBS, setIsSaveDBS] = useState(false)
  const [isSaveMaybank, setIsSaveMaybank] = useState(false)
  const [isSaveTembusa, setIsSaveTembusa] = useState(false)
  const [isSaveTokyo, setIsSaveTokyo] = useState(false)
  const [isSaveVM, setIsSaveVM] = useState(false)
  const [isSaveHongLeong, setIsSaveHongLeong] = useState(false)
  const [PdfFileUOB, setPdfFileUOB] = useState<any>(null);
  const [PdfFileMoneyMax, setPdfFileMoneyMax] = useState<any>(null);
  const [PdfFileDBS, setPdfFileDBS] = useState<any>(null);
  const [PdfFileMaybank, setPdfFileMaybank] = useState<any>(null);
  const [PdfFileTembusa, setPdfFileTembusa] = useState<any>(null);
  const [PdfFileTokyo, setPdfFileTokyo] = useState<any>(null);
  const [PdfFileVM, setPdfFileVM] = useState<any>(null);
  const [PdfFileHongleong, setPdfFileHongleong] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [isCreated, setIsCreated] = useState(false);
  const [isEditted, setIsEdited] = useState(false);
  const [isSaveAsDraft, setIsSaveAsDraft] = useState(false);
  const [isOpenCalendarFirstRegistrationDate, setIsOpenCalendarFirstRegistrationDate] = useState(false);
  const [isOpenCalendarDOBCustomer, setIsOpenCalendarDOBCustomer] = useState(false);
  const [isOpenCalendarDOBGuarantor, setIsOpenCalendarDOBGuarantor] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [listGuarantorEditScreen, setListGuarantorEditScreen] = useState();
  const [selectedGuarantorId, setSelectedGuarantorId] = useState();
  const [CPFfileEdit, setCPFfileEdit] = useState<any>(null);
  const [NOAfileEdit, setNOAfileEdit] = useState<any>(null);
  const [supportFileEdit, setSupportFileEdit] = useState<any>();
  const [isCPFfileEditDelete, setCPFfileEditDelete] = useState(false);
  const [isNOAfileEditDelete, setNOAfileEditDelete] = useState(false);
  const [isSupportFileEditDelete, setSupportFileEditDelete] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deletedGuarantorIds, setDeletedGuarantorIds] = useState<any>([]);
  // Customizable Area End

  useEffect(() => {
    setReceiveCallback(receive);

    subscribedMessages.forEach((message) => subscribe(message));

    // Customizable Area Start
    checkVehicleid();
    getListBanks();
    checkIsEdit();
    // Customizable Area End

    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
      localStorage.removeItem("vehicleLoanId")
    };
  }, []);

  const receive = (from: string, message: Message) => {
    // Customizable Area Start
    debugLog("API Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
        extractNetworkResponse(message);
      if (responseJson) {

        switch (apiRequestCallId) {
          case createSalesAndContractId.current:
            createSalesAndContractReceiver(responseJson);
            break;
          case editSalesAndContractId.current:
            editSalesAndContractReceiver(responseJson);
            break;            
          case searchVehicleId.current:
            searchVehicleReceiver(responseJson)
            break;
          case searchCustomerId.current:
            searchCustomerReceiver(responseJson)
            break;
          case searchGuarantorId.current:
            searchGuarantorReceiver(responseJson)
            break;
          case vehicleDetailsID.current:
            getCatelougDetailsReceiver(responseJson)
            break;
          case getListBanksId.current:
            getListBanksReceiver(responseJson)
            break;
          case getDealDetailId.current:
            getDealDetailReceiver(responseJson);
            break;
          case deleteGuarantorId.current:
            checkIsEdit();
            setSelectedGuarantorId(undefined)
            setOpenModalGuarantor(false)
            break;
          case deleteCPFAttachmentId.current:
            deleteCPFAttachmentReceiver();
            break;
          case deleteNOAAttachmentId.current:
            deleteNOAAttachmentReceiver();
            break;
          case deleteSupportAttachmentId.current:
            deleteSupportAttachmentReceiver();
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  const {
    sendBlockMessage,
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    debugLog,
    unsubscribeFromMessage,
  } = useRunEngine();

  const { extractNetworkResponse, hideKeyboard, isPlatformWeb } =
    useBlockHelpers();

  const checkSubmit = () => {
    setFormIsDirty(true);
  }
  const handleModalGuarantor = () => {
    setOpenModalGuarantor(!openModalGuarantor)
  }

  const handleNavigateGuarantorDetail = () => {
    setIsAddGuarantor(true)

  }
  
  const checkIsEdit = () =>{
    const pathSegments = window.location.pathname.split('/');
    if(pathSegments[1] === 'NewSalesAndContract'){
      setIsEdit(false);
    } else {
      setIsEdit(true);
      setDealId(props.navigation.getParam('dealId') ?? '');
      getDealDetail(props.navigation.getParam('dealId'))
    } 
  }

  const getDealDetail = async (dealId: any) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };
    const requestMessageSaleAndContract = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrderByIdEndPoint}/${dealId}`, 
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    getDealDetailId.current = requestMessageSaleAndContract.messageId;
    runEngine.sendMessage(requestMessageSaleAndContract.id, requestMessageSaleAndContract);
  }

  const getDealDetailReceiver = (responseJson: any)=>{
    let data = responseJson.data;
    setDealDetail(responseJson.data)
    setVehicleName(responseJson.data.attributes.vahicle_name);
    setVehiclePrice(responseJson.data.attributes.vehicle_price);
    setVehicleId(responseJson.data.attributes.vehicle_id);
    setVehicleNumber(responseJson.data.attributes.vehicle_number);
    setVehicleManufactured(responseJson.data.attributes.vehicle_manufactured_year);
    setVehicleMake(responseJson.data.attributes.vehicle_make);
    setVehicleModel(responseJson.data.attributes.vehicle_model);
    setEngineNumber(responseJson.data.attributes.engine_number);
    setChassisNumber(responseJson.data.attributes.chassis_number);
    setFirstRegistrationDate(moment(responseJson.data.attributes.first_registration_date, 'DD/MM/YYYY').format('YYYY-MM-DD'));
    setFullName(responseJson.data.attributes.customer_detail.full_name);
    setDob(responseJson.data.attributes.customer_detail.date_of_birth);
    setNRIC(responseJson.data.attributes.customer_detail.nric_number);
    setAddress(responseJson.data.attributes.customer_detail.address);
    setEmailID(responseJson.data.attributes.customer_detail.email);
    setContactNumber(responseJson.data.attributes.customer_detail.contact_number);
    setEmployer(responseJson.data.attributes.customer_detail.employer);
    setJobTitle(responseJson.data.attributes.customer_detail.job_title);
    setEmploymentAddress(responseJson.data.attributes.customer_detail.employement_address);
    setGrossMonthlySalary(responseJson.data.attributes.customer_detail.gross_monthly_salary);
    setTenure(responseJson.data.attributes.financial_details[0].loan_tennure);

    let temp
    temp = data.attributes.guarantor_details.data.map((args:any)=>{return {
      fullNameGuarantor : args.attributes.full_name,
      NRICGuarantor : args.attributes.nric_number,
      addressGuarantor : args.attributes.address,
      contactNumberGuarantor : args.attributes.contact_number,
      dobGuarantor : args.attributes.date_of_birth,
      emailIDGuarantor : args.attributes.email,
      employerGuarantor : args.attributes.employer,
      employmentAddressGuarantor : args.attributes.employement_address,
      grossMonthlySalaryGuarantor : args.attributes.gross_monthly_salary,
      jobTitleGuarantor : args.attributes.job_title,
      id: args.id,
      cpfObject: args.attributes.cpf_contribution_for_last_six_month,
      noaObject: args.attributes.noa_for_last_two_year,
      supportingObject: args.attributes.supporting_docs,
    }})
    setListAddedGuarantor(temp)

    setCPFfileEdit(data.attributes.cpf_contribution_for_last_six_month);
    setNOAfileEdit(data.attributes.noa_for_last_two_year);
    setSupportFileEdit(data.attributes.supporting_docs);
    setListSelectedBank(data.attributes.bank_attachments.map((e: any)=>{return {bank: {name: e.name, is_signed: e.is_signed, bank_id: e.bank_id}}}))
  }

  const handleSubmit = async (args: any) => {
    setIsLoading(true)
    if (isEdit){
      handleSave(args);
      
    } else {
      handleSaveCreate(args)
    }
  }

  const handleSaveEdit = async (args:any)=>{
    let data = args;
    switch (args.tenure) {
      case '12 months':
        data.tenure = 12;
        break;
      case '24 months':
        data.tenure = 24;
        break;
      case '36 months':
        data.tenure = 36;
        break;
      case '48 months':
        data.tenure = 48;
        break;
      case '60 months':
        data.tenure = 60;
        break;
      default:
        break;
    }
    let list = [...listAddedGuarantor];
    data.guarantor = list;
    for(let i = 0; i< args.listSelectedBank.length; i++){
        switch (args.listSelectedBank[i].bank.name) {
          case "UOB":
            setIsSaveUOB(true)
            break;
          case "Moneymax":
            setIsSaveMoneyMax(true)
            break;
          case "Maybank":
            setIsSaveMaybank(true)
            break;
          case "DBS bank Limited":
            setIsSaveDBS(true)
            break;
          case "Tembusu Financial Services":
            setIsSaveTembusa(true)
            break;
          case "Tokyo Century":
            setIsSaveTokyo(true)
            break;
          case "VM":
            setIsSaveVM(true)
            break;
          case "Hong Leong Finance":
            setIsSaveHongLeong(true)
            break;
          default:
            break;
        }
    }
    isCPFfileEditDelete && deleteCPF('cpf_contribution_for_last_six_month')
    isNOAfileEditDelete && deleteNOA('noa_for_last_two_year')
    isSupportFileEditDelete && deleteSupport('supporting_docs')
    if(!isCPFfileEditDelete && !isNOAfileEditDelete && !isSupportFileEditDelete){
      setIsSave(true)
    }
    setData(data);
  }

  const handleSaveCreate = async (args: any) => {
    let data = args;
    data.guarantor = listAddedGuarantor;
    switch (args.tenure) {
      case '12 months':
        data.tenure = 12;
        break;
      case '24 months':
        data.tenure = 24;
        break;
      case '36 months':
        data.tenure = 36;
        break;
      case '48 months':
        data.tenure = 48;
        break;
      case '60 months':
        data.tenure = 60;
        break;
      default:
        break;
    }
    for(let i = 0; i< args.listSelectedBank.length; i++){
      switch (args.listSelectedBank[i].bank.name) {
        case "UOB":
          setIsSaveUOB(true)
          break;
        case "Moneymax":
          setIsSaveMoneyMax(true)
          break;
        case "Maybank":
          setIsSaveMaybank(true)
          break;
        case "DBS bank Limited":
          setIsSaveDBS(true)
          break;
        case "Tembusu Financial Services":
          setIsSaveTembusa(true)
          break;
        case "Tokyo Century":
          setIsSaveTokyo(true)
          break;
        case "VM":
          setIsSaveVM(true)
          break;
        case "Hong Leong Finance":
          setIsSaveHongLeong(true)
          break;
        default:
          break;
      }
    }
    setData(data);
  }

  const savePdfUOB = (file: any) => {
    setPdfFileUOB(file)
  }
  const savePdfMaybank = (file: any) => {
    setPdfFileMaybank(file)
  }
  const savePdfTembusa = (file: any) => {
    setPdfFileTembusa(file)
  }
  const savePdfTokyo = (file: any) => {
    setPdfFileTokyo(file)
  }
  const savePdfVM = (file: any) => {
    setPdfFileVM(file)
  }
  const savePdfMoneyMax = (file: any) => {
    setPdfFileMoneyMax(file)
  }
  const savePdfDBS = (file: any) => {
    setPdfFileDBS(file)
  }
  const savePdfHongleon = (file: any) => {
     setPdfFileHongleong(file)
  }

  const handleSavePdfUOB = useCallback(savePdfUOB, []);
  const handleSavePdfMoneymax = useCallback(savePdfMoneyMax, []);
  const handleSavePdfDBS = useCallback(savePdfDBS, []);
  const handleSavePdfMaybank = useCallback(savePdfMaybank, []);
  const handleSavePdfTembusa = useCallback(savePdfTembusa, []);
  const handleSavePdfTokyo = useCallback(savePdfTokyo, []);
  const handleSavePdfVM = useCallback(savePdfVM, []);
  const handleSavePdfHongleon = useCallback(savePdfHongleon, []);

  useEffect(() => {
    let length =  [PdfFileUOB ,PdfFileMoneyMax, PdfFileDBS, PdfFileHongleong, PdfFileMaybank, PdfFileTembusa, PdfFileVM, PdfFileTokyo].filter(args=>args!== null).length;
    if (data !== null &&  length !== 0 && length === listSelectedBank.length && !isCreated && !isEdit) {
      setIsCreated(true)
      handleSubmitFinal(data);
    }
    if (data !== null  &&  length !== 0 && length === listSelectedBank.length&&  !isEditted &&  isEdit && isSave) {
      setIsEdited(true)
      handleEditFinal(data);
    }
  }, [PdfFileUOB ,PdfFileMoneyMax, PdfFileDBS, PdfFileHongleong, PdfFileMaybank, PdfFileTembusa, PdfFileVM, PdfFileTokyo, isCreated, isEdit, isEditted, isSave, data])

  const handleEditFinal =async (args:any) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue,
    };
    let formData = new FormData();
    formData.append('vehicle_id', args.vehicleId);
    formData.append('vahicle_name', args.vehicleName);
    formData.append('vehicle_price', args.vehiclePrice);
    formData.append('vehicle_number', args.vehicleNumber);
    formData.append('vehicle_make', args.vehicleMake);
    formData.append('vehicle_model', args.vehicleModel);
    formData.append('engine_number', args.engineNumber);
    formData.append('vehicle_manufactured_year', args.vehicleManufactured);

    formData.append('chassis_number', args.chassisNumber);
    formData.append('first_registration_date', args.firstRegistrationDate);
    formData.append('customer_detail_attributes[job_title]', args.jobTitle);
    formData.append('customer_detail_attributes[full_name]', args.fullName);
    formData.append('customer_detail_attributes[email]', args.emailID);
    formData.append('customer_detail_attributes[date_of_birth]', moment(args.dob).format('DD/MM/YYYY'));
    formData.append('customer_detail_attributes[employement_address]', args.employmentAddress);
    formData.append('customer_detail_attributes[nric_number]', args.NRIC);
    formData.append('customer_detail_attributes[contact_number]', args.contactNumber);
    formData.append('customer_detail_attributes[employer]', args.employer);
    formData.append('customer_detail_attributes[gross_monthly_salary]', args.grossMonthlySalary);
    formData.append('customer_detail_attributes[address]', args.address);
    for (let i = 0; i < listAddedGuarantor.length; i++) {
      listAddedGuarantor[i].id && formData.append(`guarantor_details_attributes[${i}][id]`, listAddedGuarantor[i].id);
      formData.append(`guarantor_details_attributes[${i}][job_title]`, listAddedGuarantor[i].jobTitleGuarantor);
      formData.append(`guarantor_details_attributes[${i}][full_name]`, listAddedGuarantor[i].fullNameGuarantor);
      formData.append(`guarantor_details_attributes[${i}][email]`, listAddedGuarantor[i].emailIDGuarantor);
      formData.append(`guarantor_details_attributes[${i}][date_of_birth]`, listAddedGuarantor[i].dobGuarantor);
      formData.append(`guarantor_details_attributes[${i}][employement_address]`, listAddedGuarantor[i].employmentAddressGuarantor);
      formData.append(`guarantor_details_attributes[${i}][nric_number]`, listAddedGuarantor[i].NRICGuarantor);
      formData.append(`guarantor_details_attributes[${i}][contact_number]`, listAddedGuarantor[i].contactNumberGuarantor);
      formData.append(`guarantor_details_attributes[${i}][employer]`, listAddedGuarantor[i].employerGuarantor);
      formData.append(`guarantor_details_attributes[${i}][gross_monthly_salary]`, listAddedGuarantor[i].grossMonthlySalaryGuarantor);
      formData.append(`guarantor_details_attributes[${i}][address]`, listAddedGuarantor[i].addressGuarantor);
      listAddedGuarantor[i].cpf && formData.append(`guarantor_details_attributes[${i}][cpf_contribution_for_last_six_month]`, listAddedGuarantor[i].cpf);
      listAddedGuarantor[i].noa && formData.append(`guarantor_details_attributes[${i}][noa_for_last_two_year]`, listAddedGuarantor[i].noa);
      listAddedGuarantor[i].supporting && formData.append(`guarantor_details_attributes[${i}][supporting_docs]`, listAddedGuarantor[i].supporting);
    }
    for (let i = 0; i < listSelectedBank.length; i++) {
      formData.append(`financial_details_attributes[0][bank_ids][]`, listSelectedBank[i].bank.bank_id);
    }
    formData.append('financial_details_attributes[0][loan_tennure]',tenure)
    CPFfile && formData.append('cpf_contribution_for_last_six_month', CPFfile);
    NOAfile && formData.append('noa_for_last_two_year', NOAfile);
    supportFile && formData.append('supporting_docs',supportFile);
    isSaveUOB && formData.append(`financial_details_attributes[0][bank_attachments_attributes][0][image]`, PdfFileUOB);
    isSaveUOB && formData.append(`financial_details_attributes[0][bank_attachments_attributes][0][name]`, "UOB");
    isSaveMoneyMax && formData.append(`financial_details_attributes[0][bank_attachments_attributes][1][image]`, PdfFileMoneyMax);
    isSaveMoneyMax && formData.append(`financial_details_attributes[0][bank_attachments_attributes][1][name]`, "Moneymax");
    isSaveTokyo && formData.append(`financial_details_attributes[0][bank_attachments_attributes][2][image]`, PdfFileTokyo);
    isSaveTokyo && formData.append(`financial_details_attributes[0][bank_attachments_attributes][2][name]`, "Tokyo Century");
    isSaveDBS && formData.append(`financial_details_attributes[0][bank_attachments_attributes][3][image]`, PdfFileDBS);
    isSaveDBS && formData.append(`financial_details_attributes[0][bank_attachments_attributes][3][name]`, "DBS bank Limited");
    isSaveHongLeong && formData.append(`financial_details_attributes[0][bank_attachments_attributes][4][image]`, PdfFileHongleong);
    isSaveHongLeong && formData.append(`financial_details_attributes[0][bank_attachments_attributes][4][name]`, "Hong Leong Finance");
    isSaveTembusa && formData.append(`financial_details_attributes[0][bank_attachments_attributes][5][image]`, PdfFileTembusa);
    isSaveTembusa && formData.append(`financial_details_attributes[0][bank_attachments_attributes][5][name]`, "Tembusu Financial Services");
    isSaveMaybank && formData.append(`financial_details_attributes[0][bank_attachments_attributes][6][image]`, PdfFileMaybank);
    isSaveMaybank && formData.append(`financial_details_attributes[0][bank_attachments_attributes][6][name]`, "Maybank");
    isSaveVM && formData.append(`financial_details_attributes[0][bank_attachments_attributes][7][image]`, PdfFileVM);
    isSaveVM && formData.append(`financial_details_attributes[0][bank_attachments_attributes][7][name]`, "VM");

    const requestMessageSaleAndContract = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    editSalesAndContractId.current = requestMessageSaleAndContract.messageId;
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listOfOrdersEndPoints}/${dealId}`,
    );

    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cencelOrderMethod,
    );

    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );
    runEngine.sendMessage(requestMessageSaleAndContract.id, requestMessageSaleAndContract);
  }

  const handleSubmitFinal = async (args: any) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    let formData = new FormData();
    formData.append('vehicle_id', args.vehicleId);
    formData.append('vahicle_name', args.vehicleName);
    formData.append('vehicle_price', args.vehiclePrice);
    formData.append('vehicle_number', args.vehicleNumber);
    formData.append('vehicle_make', args.vehicleMake);
    formData.append('vehicle_model', args.vehicleModel);
    formData.append('engine_number', args.engineNumber);
    formData.append('vehicle_manufactured_year', args.vehicleManufactured);

    formData.append('chassis_number', args.chassisNumber);
    formData.append('status', isSaveAsDraft ? 'draft' : 'pending_submission');
    formData.append('first_registration_date', args.firstRegistrationDate);
    formData.append('customer_detail_attributes[job_title]', args.jobTitle);
    formData.append('customer_detail_attributes[full_name]', args.fullName);
    formData.append('customer_detail_attributes[email]', args.emailID);
    formData.append('customer_detail_attributes[date_of_birth]', moment(args.dob).format('DD/MM/YYYY'));
    formData.append('customer_detail_attributes[employement_address]', args.employmentAddress);
    formData.append('customer_detail_attributes[nric_number]', args.NRIC);
    formData.append('customer_detail_attributes[contact_number]', args.contactNumber);
    formData.append('customer_detail_attributes[employer]', args.employer);
    formData.append('customer_detail_attributes[gross_monthly_salary]', args.grossMonthlySalary);
    formData.append('customer_detail_attributes[address]', args.address);
    for (let i = 0; i < listAddedGuarantor.length; i++) {
      formData.append(`guarantor_details_attributes[${i}][job_title]`, listAddedGuarantor[i].jobTitleGuarantor);
      formData.append(`guarantor_details_attributes[${i}][full_name]`, listAddedGuarantor[i].fullNameGuarantor);
      formData.append(`guarantor_details_attributes[${i}][email]`, listAddedGuarantor[i].emailIDGuarantor);
      formData.append(`guarantor_details_attributes[${i}][date_of_birth]`, listAddedGuarantor[i].dobGuarantor);
      formData.append(`guarantor_details_attributes[${i}][employement_address]`, listAddedGuarantor[i].employmentAddressGuarantor);
      formData.append(`guarantor_details_attributes[${i}][nric_number]`, listAddedGuarantor[i].NRICGuarantor);
      formData.append(`guarantor_details_attributes[${i}][contact_number]`, listAddedGuarantor[i].contactNumberGuarantor);
      formData.append(`guarantor_details_attributes[${i}][employer]`, listAddedGuarantor[i].employerGuarantor);
      formData.append(`guarantor_details_attributes[${i}][gross_monthly_salary]`, listAddedGuarantor[i].grossMonthlySalaryGuarantor);
      formData.append(`guarantor_details_attributes[${i}][address]`, listAddedGuarantor[i].addressGuarantor);
      listAddedGuarantor[i].cpf && formData.append(`guarantor_details_attributes[${i}][cpf_contribution_for_last_six_month]`, listAddedGuarantor[i].cpf);
      listAddedGuarantor[i].noa && formData.append(`guarantor_details_attributes[${i}][noa_for_last_two_year]`, listAddedGuarantor[i].noa);
      listAddedGuarantor[i].supporting && formData.append(`guarantor_details_attributes[${i}][supporting_docs]`, listAddedGuarantor[i].supporting);
    }

    for (let i = 0; i < listSelectedBank.length; i++) {
      formData.append(`financial_details_attributes[0][bank_ids][]`, listSelectedBank[i].bank.id);
    }
    formData.append('financial_details_attributes[0][loan_tennure]',tenure)
    CPFfile && formData.append('cpf_contribution_for_last_six_month', CPFfile);
    NOAfile && formData.append('noa_for_last_two_year', NOAfile);
    supportFile && formData.append('supporting_docs',supportFile);
    isSaveUOB && formData.append(`financial_details_attributes[0][bank_attachments_attributes][0][image]`, PdfFileUOB);
    isSaveUOB && formData.append(`financial_details_attributes[0][bank_attachments_attributes][0][name]`, "UOB");
    isSaveMoneyMax && formData.append(`financial_details_attributes[0][bank_attachments_attributes][1][image]`, PdfFileMoneyMax);
    isSaveMoneyMax && formData.append(`financial_details_attributes[0][bank_attachments_attributes][1][name]`, "Moneymax");
    isSaveTokyo && formData.append(`financial_details_attributes[0][bank_attachments_attributes][2][image]`, PdfFileTokyo);
    isSaveTokyo && formData.append(`financial_details_attributes[0][bank_attachments_attributes][2][name]`, "Tokyo Century");
    isSaveDBS && formData.append(`financial_details_attributes[0][bank_attachments_attributes][3][image]`, PdfFileDBS);
    isSaveDBS && formData.append(`financial_details_attributes[0][bank_attachments_attributes][3][name]`, "DBS bank Limited");
    isSaveHongLeong && formData.append(`financial_details_attributes[0][bank_attachments_attributes][4][image]`, PdfFileHongleong);
    isSaveHongLeong && formData.append(`financial_details_attributes[0][bank_attachments_attributes][4][name]`, "Hong Leong Finance");
    isSaveTembusa && formData.append(`financial_details_attributes[0][bank_attachments_attributes][5][image]`, PdfFileTembusa);
    isSaveTembusa && formData.append(`financial_details_attributes[0][bank_attachments_attributes][5][name]`, "Tembusu Financial Services");
    isSaveMaybank && formData.append(`financial_details_attributes[0][bank_attachments_attributes][6][image]`, PdfFileMaybank);
    isSaveMaybank && formData.append(`financial_details_attributes[0][bank_attachments_attributes][6][name]`, "Maybank");
    isSaveVM && formData.append(`financial_details_attributes[0][bank_attachments_attributes][7][image]`, PdfFileVM);
    isSaveVM && formData.append(`financial_details_attributes[0][bank_attachments_attributes][7][name]`, "VM");

    const requestMessageSaleAndContract = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    createSalesAndContractId.current = requestMessageSaleAndContract.messageId;
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listOfOrdersEndPoints,
    );

    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createOrderMethod,
    );

    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );
    runEngine.sendMessage(requestMessageSaleAndContract.id, requestMessageSaleAndContract);
  }
  const getCatelougDetails = async () => {
    const tokenValue = await getStorageData("authToken")
    
    const vehicle_Id = await getStorageData("vehicleLoanId")
    
      const header = {
        "Content-Type": configJSON.apiContentType,
        "token": tokenValue
      };
      const requestMessageSaleAndContract = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessageSaleAndContract.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageSaleAndContract.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getVehicleApi}/${JSON.parse(vehicle_Id)}`
      );
      requestMessageSaleAndContract.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.listOfOrdersMethod
      );
      vehicleDetailsID.current = requestMessageSaleAndContract.messageId;
      runEngine.sendMessage(requestMessageSaleAndContract.id, requestMessageSaleAndContract);
    
  
  }
  const getCatelougDetailsReceiver = (responseJson: any) => {

    let API_DATA = responseJson.catalogue.data

    setVehicleId(API_DATA.id)
    setVehicleName(API_DATA.attributes.name);
    setVehiclePrice(API_DATA.attributes.purchase_price)
    setVehicleNumber(API_DATA.attributes.vehicle_number)
    setVehicleManufactured(API_DATA.attributes.manufactured_year)
    setVehicleMake(API_DATA.attributes.make)
    setVehicleModel(API_DATA.attributes.model)
    setFirstRegistrationDate(moment(API_DATA.attributes.reg_date, 'DD/MM/YYYY').format('YYYY-MM-DD'))

  }
  const checkVehicleid = async ()=>{
    const vehicle_Id = await getStorageData("vehicleLoanId")

    if(vehicle_Id){
      getCatelougDetails()
    }
  }

  const handleMoment = (args:any, args2: string,)=>{
    return moment(args, args2).format('YYYY-MM-DD')
  }

  const getListBanks = async () => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };
    const requestMessageSaleAndContract = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getListBankApi}`,
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    getListBanksId.current = requestMessageSaleAndContract.messageId;
    runEngine.sendMessage(requestMessageSaleAndContract.id, requestMessageSaleAndContract);
  }

  const getListBanksReceiver = (responseJson: any)=>{
    setListBank(responseJson.banks);
  }

  const handleSearchVehicle = async (args: any) => {
    setSearchVehicleValue(args.target.value)
    if(args.target.value !== undefined && args.target.value !== ""){
      const tokenValue = await getStorageData("authToken")
      const header = {
        "Content-Type": configJSON.apiContentType,
        "token": tokenValue
      };
      const requestMessageSaleAndContract = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessageSaleAndContract.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header) 
      );
      requestMessageSaleAndContract.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getVehicleApi}/search?&page=1&per_page=10&query=${args.target.value}&filter_by_name=true&deal=true`
      );
      requestMessageSaleAndContract.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.listOfOrdersMethod
      );
      searchVehicleId.current = requestMessageSaleAndContract.messageId;
      runEngine.sendMessage(requestMessageSaleAndContract.id, requestMessageSaleAndContract);
    } else{
      setListSearchVehicle([])
    }
  }

  const searchVehicleReceiver = (responseJson: any) => {
    setListSearchVehicle(responseJson?.data?.data ?? [])   
  }

  const createSalesAndContractReceiver = (responseJson: any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "NewCustomer"
        );
        
        message.addData(getName(MessageEnum.NavigationPropsMessage), 'test');
        
        const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
          test: 'test',
        });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      
        sendBlockMessage(message);
    setIsLoading(false)
    setIsCreated(true)
    props.navigation.navigate('DealDetail', { dealId: responseJson.data.id })
  }
  const editSalesAndContractReceiver = (responseJson: any) => {
    setIsLoading(false)
    setIsEdited(true)
    props.navigation.navigate('DealDetail', { dealId: responseJson.data.id })
  }

  const handleSearchCustomer = async (args: any) => {
    if(args.target.value !== undefined && args.target.value !== ""){
      const tokenValue = await getStorageData("authToken")
      const header = {
        "Content-Type": configJSON.apiContentType,
        "token": tokenValue
      };
      const requestMessageSaleAndContract = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessageSaleAndContract.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageSaleAndContract.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getCustomerEndPoint}/search?attribute=name&query=${args.target.value}&page=1&per_page=10`
      );
      requestMessageSaleAndContract.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.listOfOrdersMethod
      );
      searchCustomerId.current = requestMessageSaleAndContract.messageId;
      runEngine.sendMessage(requestMessageSaleAndContract.id, requestMessageSaleAndContract);
    } else {
      setListSearchCustomer([])
    }
  }

  const searchCustomerReceiver = (responseJson: any) => {
    setListSearchCustomer(responseJson?.data?.data ?? [])
  }

  const checkSubmitGuarantor = () => {
    setFormIsDirtyGuarantor(true);
  }

  const handleSearchGuarantor = async (args: any) => {
    if(args.target.value !== undefined && args.target.value !== ""){
      const tokenValue = await getStorageData("authToken")
      const header = {
        "Content-Type": configJSON.apiContentType,
        "token": tokenValue
      };
      const requestMessageSaleAndContract = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessageSaleAndContract.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageSaleAndContract.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getCustomerEndPoint}/search?attribute=name&query=${args.target.value}&page=1&per_page=10`
      );
      requestMessageSaleAndContract.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.listOfOrdersMethod
      );
      searchGuarantorId.current = requestMessageSaleAndContract.messageId;
      runEngine.sendMessage(requestMessageSaleAndContract.id, requestMessageSaleAndContract);
    } else {
      setListSearchGuarantor([])
    }
  }

  const searchGuarantorReceiver = (responseJson: any) => {
    setListSearchGuarantor(responseJson?.data?.data ?? [])
  }

  const handleUploadCPFGuarantor = (event: any) => {
    const input = event.target;
    const files = input.files;
    setCPFfileGuarantor(files[0])
  }
  const handleUploadNOAGuarantor = (event: any) => {
    const input = event.target;
    const files = input.files;
    setNOAfileGuarantor(files[0])
  }
  const handleUploadSupportingGuarantor = (event: any) => {
    const input = event.target;
    const files = input.files;
    setSupportFileGuarantor(files[0])
  }

  const handleUploadCPF = (event: any) => {
    const input = event.target;
    const files = input.files;
    setCPFfile(files[0])
  }
  const handleUploadNOA = (event: any) => {
    const input = event.target;
    const files = input.files;
    setNOAfile(files[0])
  }
  const handleUploadSupporting = (event: any) => {
    const input = event.target;
    const files = input.files;
    setSupportFile(files[0])
  }

  const handleDeleteCPF = () => {
    setCPFfile(undefined)
  }
  const handleDeleteNOA = () => {
    setNOAfile(undefined)
  }
  const handleDeleteSupporting = () => {
    setSupportFile(undefined)
  }

  const handleSaveGuarantor = (args: any) => {
    setFormIsDirtyGuarantor(false);
    if (isEditGuarantor) {
      let temp = listAddedGuarantor[selectedGuarantorIndex];
      let list = [...listAddedGuarantor];
      temp.NRICGuarantor = args.NRICGuarantor;
      temp.addressGuarantor = args.addressGuarantor;
      temp.contactNumberGuarantor = args.contactNumberGuarantor;
      temp.dobGuarantor = args.dobGuarantor;
      temp.emailIDGuarantor = args.emailIDGuarantor;
      temp.employerGuarantor = args.employerGuarantor;
      temp.employmentAddressGuarantor = args.employmentAddressGuarantor;
      temp.fullNameGuarantor = args.fullNameGuarantor;
      temp.grossMonthlySalaryGuarantor = args.grossMonthlySalaryGuarantor
      temp.jobTitleGuarantor = args.jobTitleGuarantor;
      temp.cpf = CPFfileGuarantor;
      temp.noa = NOAfileGuarantor;
      temp.supporting = supportFileGuarantor;
      list.splice(selectedGuarantorIndex, 1, temp)
      setListAddedGuarantor(list)
      setIsAddGuarantor(false);
      setIsEditGuarantor(false)
      setCPFfileGuarantor(undefined)
      setNOAfileGuarantor(undefined)
      setSupportFileGuarantor(undefined)
    } else {
      let temp = args;
      temp.cpf = CPFfileGuarantor;
      temp.noa = NOAfileGuarantor;
      temp.supporting = supportFileGuarantor;
      setListAddedGuarantor([...listAddedGuarantor, temp])

      setCPFfileGuarantor(undefined)
      setNOAfileGuarantor(undefined)
      setSupportFileGuarantor(undefined)
      setIsAddGuarantor(false);
    }
  }

  const handleCancelGuarantor = () => {
    setIsAddGuarantor(false);
    setIsEditGuarantor(false)
  }

  const handleCancel = () => {
    props.navigation.goBack();
  }

  const editGuarantor = (index: number) => {
    setSelectedGuarantorIndex(index);
    listAddedGuarantor[index].cpf !== undefined && setCPFfileGuarantor(listAddedGuarantor[index].cpf) 
    listAddedGuarantor[index].noa !== undefined && setNOAfileGuarantor(listAddedGuarantor[index].noa) 
    listAddedGuarantor[index].supporting !== undefined && setSupportFileGuarantor(listAddedGuarantor[index].supporting) 
    setIsAddGuarantor(true);
    setIsEditGuarantor(true);
  }

  const deleteGuarantor = (index: number) => {
    let temp = [...listAddedGuarantor];
    const deletedGuarantor = temp.splice(index, 1)[0];
    setDeletedGuarantorIds([...deletedGuarantorIds, deletedGuarantor.id]);
    setListAddedGuarantor(temp);
  }
  const handleDeleteGuarantor = async (guarantorId : number) => {
    const tokenValue = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue,
    };
    const requestMessageSaleAndContract = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteGuarantorDetail}?guarantor_id=${guarantorId}`
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteOrderMethod
    );
    deletedGuarantorId.current = requestMessageSaleAndContract.messageId;
    runEngine.sendMessage(requestMessageSaleAndContract.id, requestMessageSaleAndContract);
  };

  const handleSave = async (args: any) => {
    if (deletedGuarantorIds.length > 0) {
      for (const guarantorId of deletedGuarantorIds) {
        await handleDeleteGuarantor(guarantorId);
      }
    } 
    for (const data of listAddedGuarantor){
      if(data.cpfDelete){
        deleteAttachmentGuarantor('cpf_contribution_for_last_six_month', data.id)
      }
      if(data.noaDelete){
        deleteAttachmentGuarantor('noa_for_last_two_year', data.id)
      }
      if(data.supportDelete){
        deleteAttachmentGuarantor('supporting_docs', data.id)
      }
    }
    
    handleSaveEdit(args)
    setDeletedGuarantorIds([]);
  };

  const saveAsDraft = ()=>{
    setIsSaveAsDraft(true);
    checkSubmit()
  }

  const toggleCalendarFirstRegistrationDate = ()=>{
    setIsOpenCalendarFirstRegistrationDate(!isOpenCalendarFirstRegistrationDate);
    setIsOpenCalendarDOBCustomer(false);
    setIsOpenCalendarDOBGuarantor(false);
  }

  const toggleCalendarDOBCustomer = ()=>{
    setIsOpenCalendarFirstRegistrationDate(false);
    setIsOpenCalendarDOBCustomer(!isOpenCalendarDOBCustomer);
    setIsOpenCalendarDOBGuarantor(false);
  }

  const toggleCalendarDOBGuarantor = ()=>{
    setIsOpenCalendarFirstRegistrationDate(false);
    setIsOpenCalendarDOBCustomer(false);
    setIsOpenCalendarDOBGuarantor(!isOpenCalendarDOBGuarantor);
  }

  const closeCalendar = ()=>{
    setIsOpenCalendarFirstRegistrationDate(false);
    setIsOpenCalendarDOBCustomer(false);
    setIsOpenCalendarDOBGuarantor(false);
  }

  const deleteGuarantorEdit= async()=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };
    const requestMessageSaleAndContract = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteGuarantorDetail}?guarantor_id=${selectedGuarantorId}`
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteOrderMethod
    );
    deleteGuarantorId.current = requestMessageSaleAndContract.messageId;
    runEngine.sendMessage(requestMessageSaleAndContract.id, requestMessageSaleAndContract);
  };

  const deleteCPF =async(data: string)=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };
    const requestMessageSaleAndContract = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteDealAttachment}?attachment_name=${data}&deal_id=${props.navigation.getParam('dealId')}`
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteOrderMethod
    );
    deleteCPFAttachmentId.current = requestMessageSaleAndContract.messageId;
    runEngine.sendMessage(requestMessageSaleAndContract.id, requestMessageSaleAndContract);
  }

  const deleteAttachmentGuarantor =async(data: string, guarantorId: any)=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };
    const requestMessageSaleAndContract = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteGuarantorAttachment}?attachment_name=${data}&guarantor_id=${guarantorId}`,
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteOrderMethod
    );
    // deleteCPFAttachmentGuarantorId.current = requestMessageSaleAndContract.messageId;
    runEngine.sendMessage(requestMessageSaleAndContract.id, requestMessageSaleAndContract);
  }

  const deleteCPFAttachmentReceiver = ()=>{
    setCPFfileEditDelete(false);
    if(!isNOAfileEditDelete && !isSupportFileEditDelete){
      setIsSave(true)
    }
  }

  const deleteNOA=async(data: string)=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };
    const requestMessageSaleAndContract = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteDealAttachment}?attachment_name=${data}&deal_id=${props.navigation.getParam('dealId')}`
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteOrderMethod
    );
    deleteNOAAttachmentId.current = requestMessageSaleAndContract.messageId;
    runEngine.sendMessage(requestMessageSaleAndContract.id, requestMessageSaleAndContract);
  }

  const deleteNOAAttachmentReceiver = ()=>{
    setNOAfileEditDelete(false);
    if(!isCPFfileEditDelete && !isSupportFileEditDelete){
      setIsSave(true)
    }
  }

  const deleteSupport=async(data: string)=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };
    const requestMessageSaleAndContract = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteDealAttachment}?attachment_name=${data}&deal_id=${props.navigation.getParam('dealId')}`
    );
    requestMessageSaleAndContract.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteOrderMethod
    );
    deleteSupportAttachmentId.current = requestMessageSaleAndContract.messageId;
    runEngine.sendMessage(requestMessageSaleAndContract.id, requestMessageSaleAndContract);
  }

  const deleteSupportAttachmentReceiver = ()=>{
    setSupportFileEditDelete(false);
    if(!isCPFfileEditDelete && !isNOAfileEditDelete){
      setIsSave(true)
    }
  }

  const handleDeleteCPFFileEdit =()=>{
    setCPFfileEditDelete(true)
    setCPFfileEdit(null);
  }
  const handleDeleteNOAFileEdit =()=>{
    setNOAfileEditDelete(true)
    setNOAfileEdit(null)
  }
  const handleDeleteSupportingFileEdit =()=>{
    setSupportFileEditDelete(true)
    setSupportFileEdit(null);
  }

  const handleDeleteCPFGuarantor =(index: any)=>{
    let temp = [...listAddedGuarantor];
    temp[index].cpfDelete = true;
    temp[index].cpfObject = undefined;
    setListAddedGuarantor(temp)
    setCPFfileGuarantor(undefined);
  }
  const handleDeleteNOAGuarantor =(index: any)=>{
    let temp = [...listAddedGuarantor];
    temp[index].noaDelete = true;
    temp[index].noaObject = undefined;
    setListAddedGuarantor(temp)
    setNOAfileGuarantor(undefined)

  }
  const handleDeleteSupportingGuarantor =(index: any)=>{
    let temp = listAddedGuarantor;
    temp[index].supportDelete = true;
    temp[index].supportObject = undefined;
    setListAddedGuarantor(temp)
    setSupportFileGuarantor(undefined);
  }
  // Customizable Area End

  const viewProps: ViewProps = {
    testID: "NewSalesAndContractView",
    // Customizable Area Start
    width: props.width,
    listBank,
    vehicleName: vehicleName,
    vehiclePrice: vehiclePrice,
    vehicleId: vehicleId,
    vehicleNumber: vehicleNumber,
    vehicleManufactured: vehicleManufactured,
    vehicleMake: vehicleMake,
    vehicleModel: vehicleModel,
    engineNumber: engineNumber,
    chassisNumber: chassisNumber,
    firstRegistrationDate: firstRegistrationDate,

    fullName: fullName,
    dob: dob,
    NRIC: NRIC,
    address: address,
    emailID: emailID,
    contactNumber: contactNumber,
    employer: employer,
    jobTitle: jobTitle,
    employmentAddress: employmentAddress,
    grossMonthlySalary: grossMonthlySalary,
    CPFfile,
    NOAfile,
    supportFile,
    handleUploadCPF,
    handleUploadNOA,
    handleUploadSupporting,
    handleDeleteCPF,
    handleDeleteNOA,
    handleDeleteSupporting,


    bank: bank,
    tenure: tenure,
    listSelectedBank,
    formIsDirty,
    checkSubmit,
    handleSubmit,
    openModalGuarantor,
    handleModalGuarantor,
    handleNavigateGuarantorDetail,
    handleSearchVehicle,
    listSearchVehicle,
    listSearchCustomer,
    handleSearchCustomer,
    isEditGuarantor,

    checkSubmitGuarantor,
    handleSaveGuarantor,
    formIsDirtyGuarantor,
    openModalRetrieving,
    handleCancelGuarantor,
    listSearchGuarantor,
    handleSearchGuarantor,
    handleUploadCPFGuarantor,
    handleUploadNOAGuarantor,
    handleUploadSupportingGuarantor,
    handleDeleteCPFGuarantor,
    handleDeleteNOAGuarantor,
    handleDeleteSupportingGuarantor,
    CPFfileGuarantor,
    NOAfileGuarantor,
    supportFileGuarantor,
    isAddGuarantor,
    listAddedGuarantor,
    editGuarantor,
    deleteGuarantor,
    selectedGuarantorIndex,

    setVehicleName,
    setVehiclePrice,
    setVehicleId,
    setVehicleNumber,
    setVehicleManufactured,
    setVehicleMake,
    setVehicleModel,
    setEngineNumber,
    setChassisNumber,
    setFirstRegistrationDate,
    setFullName,
    setNRIC,
    setAddress,
    setEmailID,
    setContactNumber,
    setEmployer,
    setDob,
    setJobTitle,
    setEmploymentAddress,
    setGrossMonthlySalary,
    setListSelectedBank,
    setTenure,
    handleCancel,
    data,
    isSaveUOB,
    isSaveMoneyMax,
    isSaveDBS,
    isSaveMaybank,
    isSaveTembusa,
    isSaveTokyo,
    isSaveVM,
    isSaveHongLeong,
    handleSavePdfDBS,
    handleSavePdfUOB,
    handleSavePdfMoneymax,
    handleSavePdfMaybank,
    handleSavePdfTembusa,
    handleSavePdfTokyo,
    handleSavePdfVM,
    handleSavePdfHongleon,
    handleMoment,
    saveAsDraft,
    toggleCalendarFirstRegistrationDate,
    isOpenCalendarFirstRegistrationDate,
    toggleCalendarDOBCustomer,
    isOpenCalendarDOBCustomer,
    closeCalendar,
    toggleCalendarDOBGuarantor,
    isOpenCalendarDOBGuarantor,
    dealDetail,
    listGuarantorEditScreen,
    isEdit,
    deleteGuarantorEdit,
    CPFfileEdit,
    NOAfileEdit,
    supportFileEdit,
    handleDeleteCPFFileEdit,
    handleDeleteNOAFileEdit,
    handleDeleteSupportingFileEdit,
    isLoading
    // Customizable Area End
  };

  return <NewSalesAndContractView {...viewProps} />;
};

export default NewSalesAndContract;
