import React from "react";
// Customizable Area Start
import { Box, BoxProps, Grid, TextField, Typography, withWidth } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from "moment"
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Chart } from "react-google-charts";
import Calendar from 'react-calendar';

import DashboardController, {
    Props,
} from "./DashboardController.web";
import { bookImg, carBookImg, chartImg, octiconImg } from "./assets";
import clsx from 'clsx';

// Customizable Area End
// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

class AccountingAndPayroll extends DashboardController {
    constructor(props: Props) {
        super(props);

        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start


    // Customizable Area End

    render() {
        // Customizable Area Start
        const BoxView = Box as React.FC<BoxProps>

        const isDateRangeSelected = this.state.startDate && this.state.endDate;

        // Generate the toggle button text based on whether a date range is selected
        const toggleButtonText = isDateRangeSelected
          ? `${moment(this.state.startDate).format('DD MMM')} - ${moment(this.state.endDate).format('DD MMM YYYY')}`
          : 'Select Date Range';

        const { width } = this.props;
        const { accountingPayrollData, activeTab } = this.state;


        // Customizable Area End
        return (
            //Merge Engine DefaultContainer
            // Customizable Area Start

            <AccountingAndPayrollStyle>
                <Grid container className={"ANP_mainContainer"}>

                    <Grid item container md={12} xs={12}>
                            <Grid item xs={12} md>

                            
                                <BoxView className="ANP_tabBoxWrap">
                                    <Typography
                                        className={` ${this.state.activeTab === 'invidual' ? 'ANP_activeTabBtn' : 'ANP_tabBtnText'}`}
                                        data-test-id="Individual"
                                        onClick={() => this.handleTabClick('invidual')}
                                    >
                                        Individual
                                    </Typography>
                                    <Typography
                                        className={` ${this.state.activeTab === 'team' ? 'ANP_activeTabBtn' : 'ANP_tabBtnText'}`}
                                        data-test-id="Team"
                                        onClick={() => this.handleTabClick('team')}
                                    >
                                        Team
                                    </Typography>
                                </BoxView>
                            </Grid>
                            <Grid item xs={12} md className='ANP_wrapDate'>

                                <div style={{ position: 'relative' }} className="customebtn">
                                    <button className="customButton" onClick={this.toggleCalendar}>{toggleButtonText}  <ExpandMoreIcon className="expandIcon" /></button>
                                    {this.state.isCalendarOpen && ( // Render calendar only when isCalendarOpen is true
                                        <div className="rangeCalendar"  >
                                            <Calendar
                                                data-test-id="Calendar"
                                                onChange={this.handleDateChange}
                                                selectRange={true}
                                                value={[this.state.startDate, this.state.endDate]}
                                                className="customCalendar"



                                            />

                                        </div>
                                    )}
                                </div>
                            </Grid>
                    </Grid>
                    <Grid item container className="ANP_panelMainBoxWrap">
                        <Grid item xs={12} md={8} className="ANP_panelMainBox">
                            <Grid container spacing={4} data-test-id="availabilityData">
                                <Grid item xs={12} md={6}>
                                    <BoxView className="ANP_panelBox">
                                        <img src={octiconImg} />
                                        <BoxView className="ANP_panelTextBox">
                                            <Typography className="ANP_panelTitleText">Profit</Typography>
                                            <Typography className="ANP_panelSubText">${`${accountingPayrollData[activeTab]?.Profit}`}</Typography>
                                        </BoxView>
                                    </BoxView>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BoxView className="ANP_panelBox">
                                        <img src={chartImg} />
                                        <BoxView className="ANP_panelTextBox">
                                            <Typography className="ANP_panelTitleText">Sales</Typography>
                                            <Typography className="ANP_panelSubText">{accountingPayrollData[activeTab]?.Sales}</Typography>
                                        </BoxView>
                                    </BoxView>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BoxView className="ANP_panelBox">
                                        <img src={carBookImg} />
                                        <BoxView className="ANP_panelTextBox">
                                            <Typography className="ANP_panelTitleText">No of vehicles added</Typography>
                                            <Typography className="ANP_panelSubText">{accountingPayrollData[activeTab]?.No_of_vehicles_added}</Typography>
                                        </BoxView>
                                    </BoxView>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BoxView className="ANP_panelBox">
                                        <img src={bookImg} />
                                        <BoxView className="ANP_panelTextBox">
                                            <Typography className="ANP_panelTitleText">Pending cases </Typography>
                                            <Typography className="ANP_panelSubText">{accountingPayrollData[activeTab]?.Pending_cases}</Typography>
                                        </BoxView>
                                    </BoxView>
                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid item md={12} xs={12}>
                        <BoxView mt={3} mb={4} >
                            <Typography className="ANP_trendSales" variant="h6">Sales trend</Typography>
                        </BoxView>

                        <BoxView className="ANP_tabMainBoxWrap">
                            <BoxView className="ANP_tabBoxWrap">
                                <Typography
                                    className={` ${this.state.activeSalesTrend === 'weekly' ? 'ANP_activeTabBtn' : 'ANP_tabBtnText'}`}
                                    data-test-id="Weekly"
                                    onClick={() => this.handleSalesTrendsTabClick('weekly')}
                                >
                                    Weekly
                                </Typography>
                                <Typography
                                    className={` ${this.state.activeSalesTrend === 'monthly' ? 'ANP_activeTabBtn' : 'ANP_tabBtnText'}`}
                                    data-test-id="Monthly"
                                    onClick={() => this.handleSalesTrendsTabClick('monthly')}
                                >
                                    Monthly
                                </Typography>
                            </BoxView>

                        </BoxView>

                        <BoxView mt={3} mb={4} data-test-id="growth">
                            {
                                this.state.activeSalesTrend === 'monthly' ?
                                    <Typography className="ANP_dataPercent" variant="h6">{this.state?.graphApiData?.sales_growth?.toFixed(1)}%</Typography>

                                    : (<Typography className="ANP_dataPercent">{this.state.graphApiData?.percentage_growth?.toFixed(1)}%</Typography>)
                            }

                        </BoxView>


                        <BoxView   >


                            <Chart
                                chartType="AreaChart"

                                height="400px"
                                data-test-id="Chart"
                                data={this.state.graphApiData?.graphData}
                                style={{ overflow: "auto" }}
                                className="custom-chart"
                                options={{
                                    title: "",
                                    tooltip: { isHtml: true },
                                    hAxis: {
                                        title: "Month",
                                        textStyle: {
                                            fontSize: 7,
                                          },
                                    },
                                    vAxis: {

                                        minValue: 0,
                                        format: "short"
                                    },
                                    legend: { position: "none" },

                                    chartArea: {

                                        width: "90%",
                                        height: "80%"
                                    },
                                    series: {
                                        0: {
                                            color: "#CEAE71",
                                            lineWidth: 1.5,
                                            pointSize: 5,
                                            pointShape: "circle",
                                            pointVisible: true
                                        },
                                    },
                                }}
                            />
                        </BoxView>

                    </Grid>
                </Grid>
            </AccountingAndPayrollStyle>

            // Customizable Area End
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start



const AccountingAndPayrollStyle = styled(Box)(({ theme }) => ({
    '& .ANP_mainContainer': {
        backgroundColor: (theme as any).typography.body1,
        padding:30
    },
    '& .custom-chart': {
        backgroundColor: (theme as any).typography.body1,

        "& rect": {
            fill: `${(theme as any).typography.body1} !important`,
        },

        "& text": {
            fill: `${(theme as any).typography.h1} !important`,
            background: `${(theme as any).typography.body1} !important`,
            color: "#000"
        }
    },
    '& .ANP_activeTabBtn': {
        display: "flex",
        padding: "8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "4px",
        color: "#171717",
        background:
            "var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
        cursor: "pointer",
        fontFamily: "Outfit",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
    },

    '& .current-date': {
        backgroundColor: 'gray !important',
    },

    '& .customButton': {
        display: 'inline-flex',
        height: '32px',
        padding: '6px 10px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '6px',
        flexShrink: 0,
        borderRadius: '8px',
        border: `1px solid ${(theme as any).typography.border}`,
        background: (theme as any).typography.body2,
        position: 'relative',
        color: (theme as any).typography.h1,
        zIndex: 1, /* Ensure button is above the arrow */
        fontFamily:'Outfit',
        fontWeight:'700',
        fontSize:16
    },
    '& .grayDate': {
        color: 'gray',  
    },
    '& .yellowRange': {
        background: 'yellow',  
    },
    '& .customCalendar': {
        border: `1px solid ${(theme as any).typography.border}`,
        background: (theme as any).typography.body2,

        color: (theme as any).typography.h1,
        // Add any other custom styles as needed
        display: 'inline-flex',
        padding: '24px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
        borderRadius: '8px',
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)'
    },
    '& .react-calendar__month-view__weekdays__weekday': {
        color: (theme as any).typography.h1,

    },
    '& .react-calendar__tile--now': {
        background: (theme as any).typography.body1,
        opacity: 9,
    },
    '& .react-calendar__navigation': {
        display: 'flex',
        height: '44px',
        marginBottom: '1em',

        width: '100%',
        color: (theme as any).typography.h1,
    },
    '& .react-calendar, .react-calendar *, .react-calendar ::before, .react-calendar ::after': {
        boxSizing: 'border-box',
        color: (theme as any).typography.h1,
    },
    '& .react-calendar__navigation__prev2-button  ': { display: "none" },
    "& .react-calendar__navigation__next2-button": {
        display: "none"
    },
    '& .react-calendar__tile--hover': {
        background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%)) !important',
        color: "#171717 !important",
        '& abbr': {
            color: "#171717 !important"
        }
    },

    '& .react-calendar__tile': {
         
    },
    '& .ANP_catalogueMainBoxxs': {
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        background: "#2B2B2B",
        padding: "8px",
        flexDirection: 'row',
        marginBottom: '16px',
        width: '327px',
        overflowX: 'scroll',
        gap: '10px',
    },
    '& .ANP_width300': {
        marginTop: '24px',
        width: '327px',
    },
    '& .ANP_inputField': {
        display: "inline-flex",
        width: "70%",
        padding: "5px 90px",
        color: "white",
        justifyContent: "center",
        alignItems: "center",
        gap: "6px",
        borderRadius: "8px",
        border: "1px solid var(--border, rgba(255, 246, 220, 0.30))",
        background: "var(--surface, #2B2B2B)"
    },
    '& .ANP_unSuccessful': {
        color: "var(--Status-Red-600, #DC2626)",
        fontFamily: "Outfit",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "18px",
        textTransform: "uppercase",
        display: "flex",
        padding: "2px 8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "2px",
        borderRadius: "40px",
        background: "var(--Status-Red-100, #FEE2E2)",
    },
    '& .ANP_width100': {
        marginTop: '24px',
        width: '100%',
    },
    '& .ANP_panelBox': {
        display: "flex",
        padding: "24px",
        gap: "30px",
        alignItems: "center",
        flexShrink: 0,
        borderRadius: "8px",
        border: `1px solid ${(theme as any).typography.border}`,
        background: (theme as any).typography.body2,
        height:'130px' ,
    },

    '& .react-calendar__tile--active': {


        background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
        color: "#171717 !important",
        '& abbr': {
            color: "#171717 !important",
        },
        borderRadius: '0', /* Reset border radius for all tiles */
        '&:first-of-type': {
            borderTopLeftRadius: '35px',
            borderBottomLeftRadius: '35px',
        },
        '&:last-of-type': {
            borderTopRightRadius: '35px',
            borderBottomRightRadius: '35px',
        },

    },

    '& .react-calendar__navigation button:enabled:focus': {
        background: (theme as any).typography.body1,

    },

    '& .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus': {
        background: (theme as any).typography.body1,

    },
    '& .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus': {
        background: (theme as any).typography.body1,

    },
    '& .ANP_padding30xs': {
        width: 'fit-content',
        padding: "30px",

    },
    '& .ANP_makeModelText': {
        color: "var(--text-primary, #F3F3F3)",
        textAlign: "right",
        fontFamily: "Outfit",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 500,
    },
    '& .ANP_panelMainBox': {
        marginBottom: 10
    },
    '& .ANP_tabBoxWrap': {
        display: "inline-flex",
        padding: "10px",
        alignItems: "center",
        gap: "24px",
        borderRadius: "8px",
        border: `1px solid ${(theme as any).typography.border}`,
        background: (theme as any).typography.body2,
        marginBottom:'10px'
    },
    '& .ANP_viewMoreBtnBox': {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
    },
    '& .ANP_marginTop31': {
        marginTop: '31px',
    },
    '& .ANP_padding30': {
        padding: "30px",

    },
    '& .ANP_pandding': {
        display: "flex",
        padding: "2px 8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "2px",
        borderRadius: "40px",
        background: "var(--Status-Amber-100, #FEF3C7)",
        color: "var(--Status-Amber-600, #D97706)",
        fontFamily: "Outfit",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "18px",
        textTransform: "uppercase"
    },
    '& .ANP_Successful': {
        display: "flex",
        padding: "2px 8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "2px",
        borderRadius: "40px",
        background: "var(--Status-Green-100, #D1FAE5)",
        color: "var(--Status-Green-600, #059669)",
        fontFamily: "Outfit",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "18px",
        textTransform: "uppercase",
    },
    '& .ANP_tableCellBorder': {
        borderBottom: "none",
    },
    '& .ANP_catalogueSubText': {
        width: '10%',
        color: "rgba(243, 243, 243, 0.80)",
        fontFamily: "Outfit",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        minWidth: 100
    },
    '& .ANP_selectDate': {
        backgroundColor: "#2B2B2B",
        border: '1px solid #fff6dc30',
        borderRadius: 8,
        width: '187px',
        height: 'fit-content'
    },
    '& .ANP_makeModelBox': {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        width: 'fit-content',
        minWidth: 100,
        justifyContent: 'center',
        alignItems: 'center'
    },
    '& .ANP_catalogueOwnerText': {
        color: "var(--text-primary, #F3F3F3)",
        textOverflow: "ellipsis",
        fontFamily: "Outfit",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        width: '10%',
        minWidth: 100
    },
    '& .ANP_panelMainBoxWrap': {
        marginTop: '27px'
    },
    '& .ANP_wrapDate': {
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
        gap: 10
    },
    '& .ANP_panelTextBox': {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
    },
    '& .ANP_panelTitleText': {
        color: (theme as any).typography.h1,
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        overflow: "hiden"
    },

    '& .ANP_panelSubText': {
        color: (theme as any).typography.h1,
        fontFamily: "Avenir !important",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 500,
        wordBreak:'break-all'
    },
    '& .ANP_titleText': {
        color: "var(--text-primary, #F3F3F3)",
        fontFamily: "Outfit",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 500,
    },
    '& .ANP_dataPercent': {
        color: 'var(--text-primary, #F3F3F3)',
        fontFamily: 'Avenir !important',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
        backgroundClip: 'text',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
    },

    '& .ANP_catalogueMainBox': {
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        background: "#2B2B2B",
        padding: "8px",
        flexDirection: 'row',
        marginBottom: '16px',
        gap: '10px',
        overflow: 'auto'
    },

    '& .ANP_tabMainBoxWrap': {
        display: "flex",
        justifyContent: "space-between",
    },
    '& .ANP_tabBtnText': {
        cursor: "pointer",
        fontFamily: "Outfit",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        color: (theme as any).typography.h1,
    },
    '& .MuiTableCell-body': {
        color: "white",
        background: "#2B2B2B",
        textAlign: "center",
    },
    '& .MuiTypography-body1': {
        fontFamily: "Outfit",
    },
    '& .ANP_ChartBox': {
        width: "100%"
    },
    "& .rangeCalendar ": { 
        position: 'absolute', 
        top: '150%', 
        left: '-11rem',
        '@media (max-width: 600px)': {
            left: '-3.5rem',
            position: 'absolute', top: '150%',
          },
     },
    "& .ANP_trendSales": {
        color: (theme as any).typography.h1,
        fontFamily: 'Inter',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    '@media (max-width: 600px)': {

        '& .ANP_tabBoxWrap': {
            marginRight: "9px"

        },
        "& .rangeCalendar ": {
            top: "125%",
            left: "-13rem",
            position: "absolute",
            width: "300px",
        },
        // Add more styles as needed for small screens
    },
    '@media (max-width: 480px)': {

        '& .ANP_padding30xs': {
            width: 'fit-content',
            padding: "10px",

        },

        '& .customCalendar': {
            padding: 0,
            fontSize: 12,
        },

        "& .rangeCalendar ": {
            top: "125%",


            left: "-12.3rem",
            width: "270px",
            position: "absolute",
        },
        // Add more styles as needed for small screens
    },
    '& .react-calendar__tile--hasActive': {
        background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
        color: "#171717 !important",
        '& abbr': {
          color: "#171717 !important",
        },
        borderRadius: '0', /* Reset border radius for all tiles */
        '&:first-of-type': {
          borderTopLeftRadius: '35px',
          borderBottomLeftRadius: '35px',
        },
        '&:last-of-type': {
          borderTopRightRadius: '35px',
          borderBottomRightRadius: '35px',
        },
      },
    

}));
export { AccountingAndPayroll }
export default withWidth()(AccountingAndPayroll);
// Customizable Area End
