import React from "react";
// Customizable Area Start
import {
  Box,
  Input,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  CssBaseline,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import {passwordHide,passwordShow} from "./assets"
import ResetPasswordController, { Props } from "./ResetPasswordController";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Formik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/Close";
import clsx from 'clsx';
import CustomError from "../../../components/src/CustomError";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
// Customizable Area End


export default class ResetThroughSetting extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userSchemaEmail = () => {
    return Yup.object().shape({
      emailId: Yup.string().email('Entered Email ID is invalid').matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[|a-z]{2,}$/, "Entered Email ID is invalid").required("*Email ID is required!"),
    });
  };
  
  userSchema = () => {
    return Yup.object().shape({
      oldPassword: Yup.string()
      .required("Please Enter your old password"),
      newPassword: Yup.string()
        .required("Please Enter your new password")
        .min(8, "Your password must be longer than 8 characters.")
        .max(25)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])/,
          "Must Contain One Uppercase, One Lowercase"
        ) 
        .matches(
          /^(?=.*[!@#\$%\^&\*])/,
          "Must Contain One Special Case Character"
        ) 
        .matches(
          /^(?=.{6,20}$)\D*\d/,
          "Must Contain One Number"
        ),
      confirmNewPassword: Yup.string().required("Please Enter confirm password")
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    });
  };
  // Customizable Area End
  
  render() {
    return (
      // Customizable Area Start
      <>
        <CssBaseline/>
        <ResetThroughSettingStyle>
          <Modal
            className="modal"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.isForgotPasswordSucess}
            onClose={this.handleCloseForgotSucess}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.isForgotPasswordSucess}>
              <ModalStyle>
                <Box className="wrapModel">                  
                  <Box className="modalHeaderWrap">
                    <Typography className="forgotTitle" id="transition-modal-title">Forgot password</Typography>
                    <IconButton
                      aria-label="close"
                      onClick={this.handleCloseForgotSucess}
                      style={{background:"transparent"}}
                    >
                      <CloseIcon className="icon"/>       
                    </IconButton>
                  </Box>
                  <Box className="wrapModalContent">
                    <Typography className="textModel">Password reset link has been sent to your email. Use the  link to reset your password.</Typography>
                  </Box>       
                </Box>
              </ModalStyle>
            </Fade>
          </Modal>
          
          <Modal
            className="modal"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openModalRequestForgotPassword}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.openModalRequestForgotPassword}>
              <ModalStyle>
                <Box className="wrapModel">
                  <Box className="modalHeaderWrap">
                    <Typography className="forgotTitle" id="transition-modal-title">Forgot Password</Typography>
                  </Box>
                  <Box className="wrapModalContent">
                  <Formik
                    data-test-id="formik1"
                    initialValues={{
                      emailId: this.state.email
                    }}
                    validationSchema={this.userSchemaEmail}
                    onSubmit={(values) => {

                    }}
                  >
                    {({ errors, setFieldValue, handleSubmit }) => (
                      <form onSubmit={handleSubmit} noValidate autoComplete="off">
                        <Typography className="labelText">Email ID</Typography>
                        <Input
                          data-test-id='email'
                          className={clsx({'errorInput': errors.emailId, 'inputField': !errors.emailId},)}
                          placeholder="Enter Your Email ID"
                          disableUnderline
                          value={this.state.email}
                          onChange={(event) => {
                            this.onValueChangeEmail("email", event.target.value);
                            setFieldValue("emailId", event.target.value);
                            this.setState({emailError:""})
                          }}
                          fullWidth
                        />
                        <CustomError errors={errors.emailId}/>
                        <CustomError errors={this.state.emailError}/>
                        <Button className="button" type="submit" onClick={this.forgotPasswordApi}>Get Password Reset Link</Button>
                      </form>
                    )}
                  </Formik>
                  </Box>
                </Box>
              </ModalStyle>
            </Fade>
          </Modal>

          <Modal
            className="modal"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.isResetPasswordSucees}
            onClose={this.handleCloseResetSucess}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}>
          <Fade in={this.state.isResetPasswordSucees}>
            <ModalStyle>
                <Box className="wrapModel">
                  <Box className="modalHeaderWrap">
                    <Typography className="forgotTitle" id="transition-modal-title">Reset Password</Typography>
                    <IconButton
                      aria-label="close"
                      onClick={this.handleCloseResetSucess}
                      style={{background:"transparent"}}
                    >
                      <CloseIcon className="icon"/>        
                    </IconButton>
                  </Box>
                  <Box className="wrapModalContent">
                    <Typography className="textModel">Password has been successfully reset!</Typography>
                  </Box>
                </Box>
              </ModalStyle>
            </Fade>
          </Modal>
        
          <Grid container style={{padding:"30px"}}>
            <Grid item xs={12} sm={12} md={6} className="resetMainContainer">
              <Box className="resetPasswordContainer">
                <Typography className="resetPasswordText">Reset Password</Typography>
                <Formik
                  data-test-id="formik"
                  enableReinitialize
                  initialValues={{
                    oldPassword:this.state.oldPassword,
                    newPassword: this.state.newPassword,
                    confirmNewPassword: this.state.confirmNewPassword,
                  }}
                  validationSchema={this.userSchema}
                  validateOnChange={this.state.formIsDirty}
                  validateOnBlur={this.state.formIsDirty}
                  onSubmit={(values) => {
                    this.resetPasswordApi();
                  }}
                >
                  {({ errors, setFieldValue, handleSubmit }) => (
                  <form onSubmit={handleSubmit} noValidate autoComplete="off">
                    <Typography className="labelText">Enter Old Password</Typography>
                    <Input
                      className={clsx({'errorInput': errors.oldPassword ||  this.state.isInvalidOld, 'inputFieldSetting': !errors.oldPassword && !this.state.isInvalidOld},)}
                      data-test-id='oldPassword'
                      placeholder="Enter Old password"
                      disableUnderline
                      fullWidth 
                      value={this.state.oldPassword}
                      onChange={(event) => {
                        this.onValueChange("oldPassword", event.target.value);
                        setFieldValue("oldPassword", event.target.value);
                        this.setState({isInvalidOld:false})
                      }}
                      type={this.state.showOldPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          
                          <IconButton
                            data-test-id='handleClickShowPassword'
                            aria-label="toggle password visibility"
                            onClick={this.handleOldPassword}
                            edge="end"
                          >
                            {this.state.showOldPassword ? <VisibilityIcon className="iconToggle" />:<VisibilityOffIcon className="iconToggle" /> }
                         </IconButton>
                        </InputAdornment>
                      }
                      />
                      {this.state.isInvalidOld && <Typography className="errorsMsg">Invalid old password</Typography>}
                      <CustomError errors={errors.oldPassword}/>
                    <Typography className="labelText">Enter New Password</Typography>
                    <Input
                      className={clsx({'errorInput': errors.newPassword, 'inputFieldSetting': !errors.newPassword},)}
                      data-test-id="password"
                      disableUnderline
                      fullWidth 
                      placeholder="Enter New Password"
                      value={this.state.newPassword}
                      onChange={(event) => {
                       this.onValueChange("newPassword", event.target.value);
                       setFieldValue("newPassword", event.target.value);
                      }}
                      type={this.state.showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            data-test-id='handleClickShowPassword'
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                            className="iconButton"
                          >
                            {this.state.showPassword ? <VisibilityIcon className="iconToggle" />:<VisibilityOffIcon className="iconToggle" /> }
                          </IconButton>
                        </InputAdornment>
                      }/>
                      {errors.newPassword === "Please Enter your new password" && <CustomError errors={errors.newPassword}/>}
                    <Typography className="labelText">Confirm New Password</Typography>
                    <Input
                      className={clsx({'errorInput': errors.confirmNewPassword, 'inputFieldSetting': !errors.confirmNewPassword},)}
                      data-test-id='confirmPassword'
                      placeholder="Enter Confirm Password"
                      disableUnderline
                      fullWidth 
                      value={this.state.confirmNewPassword}
                      onChange={(event) => {
                       this.onValueChange("confirmNewPassword", event.target.value);
                       setFieldValue("confirmNewPassword", event.target.value);
                     }}
                      type={this.state.showConfirmPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            data-test-id='handleClickShowPassword'
                            aria-label="toggle password visibility"
                            onClick={this.handleShowConfirm}
                            edge="end"
                          >
                           {this.state.showConfirmPassword ? <VisibilityIcon className="iconToggle" />:<VisibilityOffIcon className="iconToggle" /> }
                          </IconButton>
                        </InputAdornment>
                      }
                      />
                      <CustomError errors={errors.confirmNewPassword}/>
                         <Box className="errorsMsgBox">
                      {this.state.errorValue.length > 0 && !this.state.error.minimum && <Typography className="errorsMsg">1. A minimum of 8 characters</Typography>}
                        <span></span>
                      {this.state.errorValue.length > 0 && !this.state.error.upper && <Typography className="errorsMsg">2. Include a mix of uppercase and lowercase letters.</Typography>}
                      {this.state.errorValue.length > 0 && !this.state.error.number && <Typography className="errorsMsg">3. Include at least one number.</Typography>}
                      {this.state.errorValue.length > 0 && !this.state.error.special && <Typography className="errorsMsg">4. Include at least one special character</Typography>}
                      </Box>
                       <Box className="forgotPasswordLinkWrapper">
                           <Typography onClick={this.handleOpen} className="forgotOldPasswordText">Forgot Old Password ?</Typography>
                       </Box>
                       <Button data-test-id='resetBtn' className={`resetStngBtn`} type="submit" onClick={this.checkSubmit}>Reset Password</Button>
                  </form>)}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </ResetThroughSettingStyle>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ModalStyle = styled(Box)(({ theme } ) => ({
  "& .errorsMsg":{
    color: "#F87171",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .wrapModel":{
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    backgroundColor:(theme as any).typography.body2,
    borderRadius: "16px",
    minWidth:'40%'
  },
  "& .icon":{
    color:(theme as any).typography.h1,
  },
  '& .modalHeaderWrap': {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingRight: "24px",
    paddingLeft: "24px",
    paddingTop:'24px'   
  },
  "& .forgotTitle":{
    fontFamily: 'Outfit',
    fontSize: '38px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  '& .wrapModalContent': {
    padding: '24px'
  },
  '& .errorInput': {
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: (theme as any).typography.body2, 
    color: (theme as any).typography.h1, 
    border: "1px solid #F87171",
    display: "flex",
    height: "56px",
    padding: "10px 8px",
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
    fontFamily:'Outfit',
    fontSize:16,
    fontWeight:400
  },
  '& .errorInput1': {
    display: "flex",
    height: "56px",
    padding: "10px 8px",
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
    fontFamily:'Outfit',
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: (theme as any).typography.body2, 
    color: (theme as any).typography.h1, 
    border: "1px solid #F87171",
    fontSize:16,
    fontWeight:400
  },
  "& .inputField": {   
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    display: "flex",
    height: "56px",
    padding: "10px 8px",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${(theme as any).typography.borderSetting}`,
    background: (theme as any).typography.body2, 
    color: (theme as any).typography.h1, 
    width: '100%',
    fontFamily:'Outfit',
    fontSize:16,
    fontWeight:400
  },
  "& .textModel":{
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    marginBottom: '24px'
  },
  '& .labelText':{
    color:(theme as any).typography.h1, 
    fontFamily: 'Outfit',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '22px',
    marginBottom:"5px",
   },
  "& .wrapButton":{display: 'flex', justifyContent: 'center',marginBottom:20},
  "& .button":{
    display: "flex",
    width: "100%",
    justifyContent: "center",
    textTransform: 'none',
    height: "56px",
    padding: "16px",
    marginTop : '25px',
    borderRadius: "8px",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "8px",
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
      color: "var(--text-primary, #171717)",
      fontFamily: "Outfit",
      fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 700,
  },
}));
const ResetThroughSettingStyle = styled(Box)(({ theme } ) => ({
  '& .errorInput': {
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    display: "flex",
    height: "56px",
    padding: "10px 8px",
    justifyContent: "center",
    background: (theme as any).typography.body2, 
    color: (theme as any).typography.h1, 
    border: "1px solid #F87171",
    alignItems: "center",
    width: '100%',
    fontFamily:'Outfit',
    fontSize:16,
    fontWeight:400
  },
  '& .errorInput1': {
    background: (theme as any).typography.body2, 
    color: (theme as any).typography.h1, 
    border: "1px solid #F87171",
    alignItems: "center",
    width: '100%',
    fontFamily:'Outfit',
    fontSize:16,
    fontWeight:400,
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    display: "flex",
    height: "56px",
    padding: "10px 8px",
    justifyContent: "center",
    
  },
 '& .resetMainContainer':{
  padding:'5px'
 },
 '& .resetPasswordText':{
  color: (theme as any).typography.h1, 
  fontFamily: 'Outfit',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginBottom:"15px"
 },
 '& .labelText':{
  color:(theme as any).typography.h1, 
  fontFamily: 'Outfit',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '22px',
  marginBottom:"5px",
  marginTop: '30px'
 },
 '& .inputFieldSetting':{
  color:(theme as any).typography.h1, 
  display: 'flex',
  height: '56px',
  padding: '10px 8px',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily:'Outfit;',
  gap: '8px',
  alignSelf: 'stretch',
  borderRadius: '8px',
  border: '1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)',
  background: (theme as any).typography.body2, 

  '& .iconToggle':{
    color:(theme as any).typography.h2, 
  }
 },
 '& .forgotPasswordLinkWrapper':{
    margin:"15px 0"
 },
 '& .forgotOldPasswordText':{
     cursor:"pointer",
    textAlign:"end",
    fontFamily: 'Outfit',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
 },
 '& .resetStngBtn':{
  display: "flex",
  width: "100%",
  justifyContent: "center",
  textTransform: 'none',
  height: "56px",
  padding: "16px",
  marginTop : '25px',
  borderRadius: "8px",
  alignItems: "center",
  alignSelf: "stretch",
  gap: "8px",
  background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    color: "var(--text-primary, #171717)",
    fontFamily: "Outfit",
    fontStyle: "normal",
  fontSize: "16px",
  fontWeight: 700,
 },
 "& .errorsMsg": {
  fontFamily: "Outfit",
  fontWeight: 400,
  fontSize: "12px",
  color: "var(--status-red-400, #F87171)",
  fontStyle: "normal",
},
"& .errorsMsgBox": {
  marginTop:"16px",
},
"& .error" :{
  borderColor: "#F87171",
},
"& .modal":{
  background:"green",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxWidth:"640px",
  width:"100%",
  height:"344px"
},
"& .disabledBtn":{
  opacity: 0.5,
  background: (theme as any).typography.h1, 
},
"& .paper":{

},
}))

// Customizable Area End
