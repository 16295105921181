Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.validationDeleteApiMethodType = "DELETE";
exports.validationPostApiMethodType = "POST";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfintegrationwithtiktok";
exports.labelBodyText = "cfintegrationwithtiktok Body";
exports.PutEampleMethod = "PUT";
exports.btnExampleTitle = "CLICK ME";
exports.getVehicleListEndpoint = "bx_block_catalogue/catalogues";
exports.getSocialMediaEndpoint = "bx_block_cfintegrationwithfacebook2/social_medias";
// Customizable Area End