import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Dummy, Facebook, Instagram, Tiktok, Logo } from "./assets";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
type NumericInputOptionsDetail = {
  allowDecimal?: boolean;
  maxDecimalPlaces?: number;
  maxValue?: number;
  maxLength?: number;
  isInteger?: boolean;
  exactLength?: number;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  width: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userType: string,
  vehicleId: any,
  openModal: boolean;
  attribute: any;
  listImages: string[];
  listShowedImages: string[];
  listShowedImagesSmallScreen: string[];
  selectedImage: string;
  firstImageIndex: number;
  lastImageIndex: number;
  firstImageIndexSmall: number;
  lastImageIndexSmall: number;

  initPrice: string;
  price: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getVehicleDetailId: any;
  putDetailID: any;
  patchVehiclePriceApiId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      userType:'',
      vehicleId: this.props.navigation.getParam('vehicleId'),
      openModal: false,
      attribute: null,
      listImages: [],
      listShowedImages: [],
      listShowedImagesSmallScreen: [],
      selectedImage: '',
      firstImageIndex: 0,
      lastImageIndex: 3,
      firstImageIndexSmall: 0,
      lastImageIndexSmall: 1,
      
      initPrice: '',
      price: '',
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getVehicleDetailApi();
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    if(apiRequestCallId === this.getVehicleDetailId){
      this.getVehicleDetailReceiver(responseJson);
    }
    if(apiRequestCallId === this.patchVehiclePriceApiId){
      this.patchVehiclePriceApiReceiver(responseJson);
    }

    
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  shouldComponentUpdate(_nextProps: Readonly<Props>, nextState: Readonly<S>, _nextContext: any): boolean {
    if(nextState.listImages !== this.state.listImages){
      if(nextState.listImages?.length > 4){
        this.setState({
          listShowedImages: nextState.listImages?.slice(0, 4), 
          listShowedImagesSmallScreen: nextState.listImages?.slice(0,2),
          selectedImage:nextState.listImages[0]
        });
      }
      else {
        this.setState({
          listShowedImages: nextState.listImages?.slice(0, nextState.listImages?.length + 1), 
          listShowedImagesSmallScreen: nextState.listImages?.slice(0,2),
          selectedImage:nextState.listImages !== undefined ? nextState.listImages[0] : '',
        });
      }
    }
    return true;
  }
  
  getVehicleDetailApi = async () => {
    const userType = await getStorageData("user_type");
      this.setState({ userType })
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": tokenValue
    };
    const requestMessageCatalogue = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVehicleDetailApi}/${this.state.vehicleId}`
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.getVehicleDetailId = requestMessageCatalogue.messageId;
    runEngine.sendMessage(requestMessageCatalogue.id, requestMessageCatalogue);
  }

  getVehicleDetailReceiver = (responseJson: any) => {
    this.setState({ 
      attribute: responseJson.catalogue.data.attributes, 
      listImages: responseJson.catalogue.data.attributes.images?.map((image: { url: any; })=>image.url),
      initPrice: responseJson.catalogue.data.attributes?.selling_price,
      price: responseJson.catalogue.data.attributes?.selling_price,
      vehicleId: responseJson.catalogue.data.id,
     })
    localStorage.setItem('editPrice', responseJson.catalogue.data.attributes?.selling_price);
  }

  handleModalPrice = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  handleClickImage = (index: number) =>{
    this.setState({selectedImage: this.state.listShowedImages[index]})
  }

  handleClickImageSmall = (index: number) =>{
    this.setState({selectedImage: this.state.listShowedImagesSmallScreen[index]})
  }

  handleClickLeft = ()=>{
    if(this.state.firstImageIndex > 0){
        this.setState({
          listShowedImages: this.state.listImages.slice(this.state.firstImageIndex - 1, this.state.lastImageIndex),
          firstImageIndex: this.state.firstImageIndex - 1,
          lastImageIndex: this.state.lastImageIndex - 1,
        })
    }
  }

  handleClickLeftSmall = ()=>{
    if(this.state.firstImageIndexSmall > 0){
        this.setState({
          listShowedImagesSmallScreen: this.state.listImages.slice(this.state.firstImageIndexSmall - 1, this.state.lastImageIndexSmall),
          firstImageIndexSmall: this.state.firstImageIndexSmall - 1,
          lastImageIndexSmall: this.state.lastImageIndexSmall - 1,
        }
      )
    }
  }

  handleClickRight = () => {
    if(this.state.lastImageIndex < this.state.listImages.length - 1 ){
        this.setState({
          listShowedImages: this.state.listImages.slice(this.state.firstImageIndex + 1, this.state.lastImageIndex + 2),
          firstImageIndex: this.state.firstImageIndex + 1,
          lastImageIndex: this.state.lastImageIndex + 1,
        }
      )
    }
  }

  handleClickRightSmall = () => {
    if(this.state.lastImageIndexSmall < this.state.listImages.length - 1 ){   
        this.setState({
          listShowedImagesSmallScreen: this.state.listImages.slice(this.state.firstImageIndexSmall + 1, this.state.lastImageIndexSmall + 2),
          firstImageIndexSmall: this.state.firstImageIndexSmall + 1,
          lastImageIndexSmall: this.state.lastImageIndexSmall + 1,
        }
      )
    }
  }

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  handleNumericInput = (event : any, optionsNumber : NumericInputOptionsDetail = {}) => {
    const {
      allowDecimal = false,
      maxDecimalPlaces = 0,
      maxValue = null,
      maxLength = null,
      isInteger = false,
    } = optionsNumber;
    const Allowed = ['Backspace' , 'Delete', 'Tab' , 'Enter' , 'ArrowLeft', 'ArrowRight']
    
    if(Allowed.includes(event.key)) return ;
    const target = event.target as HTMLInputElement;
    if (!/[0-9.]/.test(event.key) ||
    (allowDecimal && event.key === '.' && target.value.includes('.')) ||
    ((isInteger && event.key === '.')) || 
    ((maxLength !== null && target.value.length >= maxLength)) || 
    ((maxValue !== null && parseFloat(target.value + event.key) > maxValue) ) ||
    ((allowDecimal && target.value.includes('.') && target.value.split('.')[1].length >= maxDecimalPlaces)  && 
    (target.selectionStart && target.selectionStart > target.value.indexOf('.')))
    ) {
      event.preventDefault();
    }
  };

  handleSavePrice = async (value: any) => {    
    this.patchVehiclePriceApi(value.price);
  }

  patchVehiclePriceApi = async (value: any) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": tokenValue
    };
    const requestMessageCatalogue = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVehicleDetailApi}/${this.state.vehicleId}/update_selling_price`
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({"selling_price":value})
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePatch
    );
    this.patchVehiclePriceApiId = requestMessageCatalogue.messageId;
    runEngine.sendMessage(requestMessageCatalogue.id, requestMessageCatalogue);
  }

  patchVehiclePriceApiReceiver = (data: any)=>{
    localStorage.setItem('editPrice', data.data.attributes.selling_price);
    this.setState({initPrice: data.data.attributes.selling_price,openModal: false})
  }

  handleNavigateFinancial = () => {
    this.props.navigation.navigate('LoanCalculator',  { vehicleId: this.state.vehicleId })
  }
  // Customizable Area End
}
