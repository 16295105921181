import React, { Component } from 'react';
import clsx from 'clsx';
import {
  withStyles,
  createStyles,
  Theme,
  WithStyles,
} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Avatar, Box, Select, styled, withWidth } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withRouter ,RouteComponentProps} from 'react-router-dom';
import { getStorageData } from 'framework/src/Utilities';
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import moment from 'moment';

const drawerWidth = 300;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      "& .PersistentDrawerLeft-drawerOpen-10": {
        width: '275px',
        height:"100%",
      },
      "& .PersistentDrawerLeft-drawerOpen-9": {
        width: '275px',
      },
      "& .PersistentDrawerLeft-appBarShift-6": {
        width: "calc(100% - 275px)",
        boxShadow: "none",
      },
      "& .PersistentDrawerLeft-appBar-5": {
        boxShadow: "none",
      },
      "& .MuiToolbar-gutters": {
        paddingLeft: "28px",
      },
      "& .PersistentDrawerLeft-drawerClose-11": {
        width: "100px",
        height:"100%"
      },
      "& .MuiSvgIcon-root": {
        color: 'white',
      },
      "& .MuiTypography-body1":{
        fontFamily: "Outfit",
      },
      "& .drawerBox": {
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 16,
        
      },
      "& .notificationText": {
        fontFamily: "Outfit",
        fontWeight: 700,
        fontSize: '16px',
        color: (theme as any).typography.h1, 
        alignItems:'center',
        display: 'flex',
      },
      '& .inputFieldServices': {
        marginTop: '4px',
        borderRadius: 8,
        border: "1px solid var(--neutrals-cool-gray-200, #E2E8F0)",
        background: (theme as any).typography.body2, 
        padding: '10px 8px',
        color: (theme as any).typography.h3, 
        fontFamily: "Outfit",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        height:"32px",
        width:'100px'
      },
      "& .titleItem": {
        fontFamily: "Outfit",
        fontWeight: 400,
        fontSize: '14px',
        color: (theme as any).typography.h1, 
      },
      "& .dateItem": {
        fontFamily: "Outfit",
        fontWeight: 400,
        fontSize: '12px',
        color: (theme as any).typography.h3, 
      },
      "& .textItem": {
        fontFamily: "Outfit",
        fontWeight: 400,
        fontSize: '12px',
        color: (theme as any).typography.h2, 
      },
      "& .wrapContent": {
        overflow:'auto',
      },
      "& .wrapItemUnread": {
        cursor: 'pointer',
        backgroundColor:(theme as any).typography.surface2, 
        marginTop: 8, 
        padding: 8
      },
      "& .wrapItem": {
        cursor: 'pointer',
        backgroundColor:(theme as any).typography.body2, 
        marginTop: 8, 
        padding: 8
      },
      "& .wrapHeader": {
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-between',
      },
      "& .icon": {
        color: (theme as any).typography.h1, 
      },
      
    },
    drawerOpen: {
      background: (theme as any).typography.body2, 
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      background: (theme as any).typography.body2, 
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(10) + 3,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    icon:{
      
     color: (theme as any).typography.h1, 
    }
  });

  const CustomSelectStyle = styled(Box)(({ theme }) => ({  
    marginTop: '-8px !important',
    marginBottom: '-8px !important',
    '& .wrapText':{
      paddingTop: 12,
      paddingBottom:12,
      paddingLeft: 16,
      paddingRight: 16,

      backgroundColor:(theme as any).typography.body2, 
    },
    "& .textSelect": {
      fontFamily: "Outfit",
      fontWeight: 400,
      fontSize: '12px',
      color:(theme as any).typography.h1, 
    },

  }))

  interface S {
    // Customizable Area Start
    listNotification: any;
    listNotificationRead: any;
    listNotificationUnread: any;
    valueFilter: string,
    loading:boolean,
    currentCount:number,
    currentCountRead:number,
    currentCountUnread:number,
    isMax: boolean,
    isMaxRead: boolean,
    isMaxUnread: boolean,
    isBelow: boolean,
    isBelowRead: boolean,
    isBelowUnread: boolean,
    // Customizable Area End
  }
  
  interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
  }

interface NotificationDrawerProps extends WithStyles<typeof styles>, RouteComponentProps<any>{navigation: any, id: string, width: any, anchor: boolean, handleOpenNotification: any}

class NotificationDrawer extends BlockComponent<
NotificationDrawerProps, S, SS
> {
  getNotificationListId: any;
  getNotificationReadListId: any;
  getNotificationUnreadListId: any;
  updateNotificationId: any;
  scrollerRef :any
  constructor(props: NotificationDrawerProps) {
    super(props);
    this.scrollerRef = React.createRef();
    this.receive = this.receive.bind(this);
     this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];
    this.state = {
      listNotification: [],
      listNotificationRead: [],
      listNotificationUnread: [],
      valueFilter: 'All',
      loading:true,
      currentCount:1,
      currentCountRead:1,
      currentCountUnread:1,
      isMax: false,
      isMaxRead: false,
      isMaxUnread: false,
      isBelow: false,
      isBelowRead: false,
      isBelowUnread: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getListNotification();
    window.addEventListener('scroll', this.handleScroll, true);
  }

  handleScroll = (e: any)=>{
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if(bottom && !this.state.isMax && (this.state.valueFilter==="All" || this.state.valueFilter ==="") && this.props.anchor){
        this.setState({currentCount:this.state.currentCount + 1})
        this.getListNotification()
      }
      if(bottom && !this.state.isMaxRead && (this.state.valueFilter==="Read") && this.props.anchor){
        this.setState({currentCountRead:this.state.currentCountRead + 1})
        this.getListFilterNotificationRead('true')
      }
      if(bottom && !this.state.isMaxUnread && (this.state.valueFilter==="Unread") && this.props.anchor){
        this.setState({currentCountUnread:this.state.currentCountUnread + 1})
        this.getListFilterNotificationUnread('false')
      }
    
  }

   
  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if(getName(MessageEnum.NavigationPayLoadMessage) === message.id){
      this.getListNotification();
    }
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    
    switch (apiRequestCallId) {
      case this.getNotificationListId:
        this.getNotificationListReceiver(responseJson);
        break;
      case this.getNotificationReadListId:
        this.getNotificationReadListReceiver(responseJson);
        break;
      case this.getNotificationUnreadListId:
        this.getNotificationUnreadListReceiver(responseJson);
        break;
      case this.updateNotificationId:
        this.updateNotificationReceiver(responseJson);
        break;
      default:
        break;
    }
    // Customizable Area End
  }

  getListNotification = async () => {
    const tokenValue = await getStorageData("authToken")
    if(tokenValue !==null){
      const header = {
        "Content-Type": "application/json",
        "token": tokenValue
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_push_notifications/push_notifications?per_page=20&page=${this.state.currentCount}`
       
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );
      this.getNotificationListId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }else{
      return
    }
    
  }

  getListFilterNotificationRead = async (value: any) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": "application/json",
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_push_notifications/push_notifications?is_read=${value}&per_page=20&page=${this.state.currentCountRead}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.getNotificationReadListId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getListFilterNotificationUnread = async (value: any) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": "application/json",
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_push_notifications/push_notifications?is_read=${value}&per_page=20&page=${this.state.currentCountUnread}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.getNotificationUnreadListId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getNotificationListReceiver = (responseJson: any) => {
    if(this.state.currentCount===1){
      this.setState({listNotification: responseJson?.data?.data})
    }
    if(responseJson?.data?.data.length === 0){
      this.setState({isMax: true })
    } else if(!this.state.isBelow && this.state.currentCount !== 1) {
      this.setState({listNotification: [...this.state.listNotification,...responseJson?.data?.data ??''],loading:false})
      if(responseJson?.data?.data.length <20){
        this.setState({isBelow: true })
      }
    }
  }

  getNotificationReadListReceiver = (responseJson: any) => {
    if(this.state.currentCountRead===1){
      this.setState({listNotificationRead: responseJson?.data?.data})
    }
    if(responseJson?.data?.data.length === 0){
      this.setState({isMaxRead: true })
    } else if(!this.state.isBelowRead && this.state.currentCountRead !== 1) {
      this.setState({listNotificationRead: [...this.state.listNotificationRead,...responseJson?.data?.data ?? '' ],loading:false})
      if(responseJson?.data?.data.length <20){
        this.setState({isBelowRead: true })
      }
  }
  }

  getNotificationUnreadListReceiver = (responseJson: any) => {
    if(this.state.currentCountUnread===1){
      this.setState({listNotificationUnread: responseJson?.data?.data})
    }
    if(responseJson?.data?.data.length === 0){
      this.setState({isMaxUnread: true })
    } else if(!this.state.isBelowUnread  && this.state.currentCountUnread !== 1) {
      this.setState({listNotificationUnread: [...this.state.listNotificationUnread,...responseJson?.data?.data?? ''],loading:false})
      if(responseJson?.data?.data.length <20){
        this.setState({isBelowUnread: true })
      }
    }
  }
  

  handleFilter = (value: string)=>{
    this.setState({valueFilter: value, currentCount:1, currentCountRead:1, currentCountUnread:1})
    switch (value) {
      case 'All':    
        this.getListNotification();
        break;
      case 'Read':
        this.getListFilterNotificationRead('true')
        break;
      case 'Unread':
        this.getListFilterNotificationUnread('false')
        break;
      default:
        break;
    }
  }
  
  handleClick = async(value: any)=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": "application/json",
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_push_notifications/push_notifications/${value.id}?is_read=true`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    this.updateNotificationId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateNotificationReceiver = (responseJson: any) => {
    this.setState({valueFilter:''})
    this.getListNotification();
    switch (responseJson.data.attributes.type) {
      case 'catalogue':
        this.props.history.push(`/CatalogueDetail/${responseJson.data.attributes.catalogue_id}`)
        break;
      case 'Customer':
        this.props.history.push(`/EditCustomer/${responseJson.data.attributes.customer_id}`)
        break;
      case 'Deal':
        this.props.history.push(`/SalesAndContract`)
        break;
    
      default:
        break;
    }
    window.location.reload();
  }

  
  

  render() {
    const { classes, theme } = this.props as any;
    return (
      <div className={classes.root} onScroll={e=> console.log("scroll___ ")} >
        <Drawer 
          anchor={'right'} 
          onScroll={e=> console.log("scroll___ ")}
          open={this.props.anchor}
          ref={this.scrollerRef}
          className={clsx(classes.root)}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: this.props.anchor,
              [classes.drawerClose]: !this.props.anchor,
            }),
          }}
        >
          <div className={classes.toolbar}>
              <IconButton onClick={this.props.handleOpenNotification}>
                {theme.direction === 'rtl' ? <CloseIcon className='icon'/> : <CloseIcon className='icon'/>}
              </IconButton>
          </div>
          
          <Box className='drawerBox'>
            <Box className='headerNotification' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
              <Typography className='notificationText'>Notifications</Typography>
              <Select
                className="inputFieldServices"
                disableUnderline
                displayEmpty
                value={this.state.valueFilter}
                fullWidth
                renderValue={
                  () => <Typography className="searchText">{this.state.valueFilter ? this.state.valueFilter : 'Select'}</Typography>
                }
                MenuProps={{
                  classes: {paper: classes.select},
                  style: {
                    marginTop: '10px',
                  },
                  PaperProps: {
                    style: {
                      borderRadius:'8px',
                     
                      width:'100px',
                      height: 'fit-content',
                    }
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >             
                <CustomSelectStyle>
                  {['All','Read','Unread'].map((e)=> <Box className='wrapText' onClick={()=>this.handleFilter(e)}>
                    <Typography className='textSelect'>{e}</Typography>
                  </Box>)}
                </CustomSelectStyle>                                      
              </Select>
            </Box>
            <Box className='wrapContent'>
              {(this.state.valueFilter==='All' ||this.state.valueFilter==='') && this.state.listNotification?.map((e:any,index:number)=> {
                let title
                switch (e?.attributes.type) {
                  case 'catalogue':
                    title= 'Catalogue';
                    break;
                  case 'Customer':
                    title= 'CRM';
                    break;
                  case 'Deal':
                    title= 'Sales and Contract';
                    break;
                  default:
                    break;
                }
              return <Box key={index} className={clsx({'wrapItem': e.attributes.is_read, 'wrapItemUnread': !e.attributes.is_read})} onClick={()=> this.handleClick(e)}>
                  <Box className='wrapHeader'>
                    <Typography className='titleItem'>{title}</Typography>
                    <Typography className='dateItem'>{e.attributes.created_at}</Typography>
                  </Box>
                  <Typography className='textItem'>{e.attributes.remarks}</Typography>
                </Box>}
              )}
              {this.state.valueFilter==='Read' && this.state.listNotificationRead?.map((e:any,index:number)=> {
                let title
                switch (e?.attributes.type) {
                  case 'catalogue':
                    title= 'Catalogue';
                    break;
                  case 'Customer':
                    title= 'CRM';
                    break;
                  default:
                    break;
                }
              return <Box key={index} className={clsx({'wrapItem': e.attributes.is_read, 'wrapItemUnread': !e.attributes.is_read})} onClick={()=> this.handleClick(e)}>
                  <Box className='wrapHeader'>
                    <Typography className='titleItem'>{title}</Typography>
                    <Typography className='dateItem'>{e.attributes.created_at}</Typography>
                  </Box>
                  <Typography className='textItem'>{e.attributes.remarks}</Typography>
                </Box>}
              )}
              {this.state.valueFilter==='Unread' && this.state.listNotificationUnread?.map((e:any,index:number)=> {
                let title
                switch (e?.attributes.type) {
                  case 'catalogue':
                    title= 'Catalogue';
                    break;
                  case 'Customer':
                    title= 'CRM';
                    break;
                  default:
                    break;
                }
              return <Box key={index} className={clsx({'wrapItem': e.attributes.is_read, 'wrapItemUnread': !e.attributes.is_read})} onClick={()=> this.handleClick(e)}>
                  <Box className='wrapHeader'>
                    <Typography className='titleItem'>{title}</Typography>
                    <Typography className='dateItem'>{e.attributes.created_at}</Typography>
                  </Box>
                  <Typography className='textItem'>{e.attributes.remarks}</Typography>
                </Box>}
              )}
            </Box>

          </Box> 
        </Drawer>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(withWidth()(NotificationDrawer)));
