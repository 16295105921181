import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

export const services = ['buying', 'selling', 'insurance']
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import React from "react";

export const priceValueList = ['Below $10,000', '$10,000 to $15,000', '$15,001 to $20,000', '$20,001 to $25,000', '$25,001 to $30,000','$30,001 to $35,000', '$35,001 to $40,000', '$40,001 to $45,000', '$45,001 to $50,000', '$50,001 to $60,000', '$60,001 to $70,000', '$70,001 to $80,000', '$80,001 to $100,000', '$100,001 to $120,000', '$120,001 to $140,000', '$140,001 to $160,000', '$160,001 to $180,000', '$180,001 to $200,000', 'Above $200,000'];
export const depreciationList = ['Any', 'Below $7k /yr', 'Below $8k /yr', 'Below $9k /yr', 'Below $10k /yr', '$10k to $11k /yr', '$11k to $12k /yr', '$12k to $13k /yr', '$13k to $14k /yr', '$14k to $16k /yr', '$16k to $18k /yr', '$18k to $20k /yr', '$20k to $25k /yr', 'Above $25k /yr'];
export const regDateList = ['2024', '2023', '2022', '2021', '2020','2019', '2018', '2017', '2016', '2015', '2014', '< 1 year(s) old', '< 2 year(s) old', '< 3 year(s) old', '< 4 year(s) old', '< 5 year(s) old', '> 6 year(s) old', '> 7 year(s) old', '> 8 year(s) old', '> 9 year(s) old', '> 10 year(s) old'];
export const engineCapList = ['Below 661cc', '661cc to 1,600cc', '1,601cc to 2,000cc', '2,001cc to 3,000cc', 'Above 3,000cc']
export const milageList = ['Below 10,000 km', '10,000 - 20,000 km', '20,001 - 30,000 km', '30,001 - 40,000 km', '40,001 - 50,000 km', '50,001 - 70,000 km', '70,001 - 90,000 km', '90,001 - 110,000 km', '110,001 - 130,000 km', '130,001 - 150,000 km', '150,001 - 200,000 km', '200,001 - 300,000 km', 'Above 300,000 km'];
export const vehicleTypeList = ['Mid-Sized Sedan', 'Luxury Sedan', 'Hatchback', 'MPV', 'SUV', 'Sports Car', 'Stationwagon', 'Diesel Car', 'Hybrid Car', 'Electric Car', 'Vintage Car',  ];

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  customerId: string,
  formIsDirty: boolean,
  name: string,
  dob: string,
  contactNumber: string,
  email: string,
  datePurchase: string,
  dateRoadTaxRenewal: string,
  services: string[],
  listMake: string[],
  listModel: string[],

  selectedMake: string[],
  selectedModel: string[],
  price: any,
  engineCap : any,
  milage: any,
  vehicleType: string[],

  isShowPreference: boolean;
  isEdit: boolean;
  date_of_insurance:string;
  isCalendarOpen:boolean; 
  isRoadTaxRenewal:boolean;
  isDatePurchase:boolean;
  isDOB:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NewCustomerCRMController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMakeListId : any;
  getModelListId: any;
  addNewCustomerId: any;
  updateCustomerId: any;
  getCustomerDetailId: any;
  calendarRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.calendarRef = React.createRef();
    // Customizable Area End
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      customerId: '',
      isEdit: false,
      formIsDirty: false,
      name: '',
      dob: '',
      contactNumber: '',
      email: '',
      datePurchase: '',
      dateRoadTaxRenewal: '',
      services: [],
      isShowPreference: false,
      listMake:[],
      listModel:[],
      selectedMake:[],
      selectedModel:[],

      price: null,
      engineCap: null,
      vehicleType: [],
      milage: null,
      date_of_insurance:"",
      isCalendarOpen:false,
      isRoadTaxRenewal:false,
      isDatePurchase:false,
      isDOB:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    switch (apiRequestCallId) {
      case this.getMakeListId:
        this.getMakeListReceiver(responseJson);
        break;
      case this.getModelListId:
        this.getModelListReceiver(responseJson);
        break;
      case this.addNewCustomerId:
        this.addNewCustomerReceiver(responseJson);
        break;
      case this.updateCustomerId:
        this.updateCustomerReceiver(responseJson);
        break;
      case this.getCustomerDetailId:
        this.getCustomerDetailReceiver(responseJson);
        break;
      default:
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getMakeList();
    this.checkEdit();
  }


  handleCloseCalendar = () => {
    this.setState({
      isDatePurchase: false,
      isRoadTaxRenewal: false,
      isDOB: false,
      isCalendarOpen: false,
    })
  };

  shouldComponentUpdate(_nextProps: Readonly<Props>, nextState: Readonly<S>, _nextContext: any): boolean {
    if(nextState.services !== this.state.services){
      this.setState({isShowPreference: nextState.services.length > 0})
    }
    if(this.state.isCalendarOpen || this.state.isDatePurchase || this.state.isRoadTaxRenewal || this.state.isDOB){
      this.handleCloseCalendar()
    }
    return true;
  }
  

  checkEdit = () =>{
    const pathSegments = window.location.pathname.split('/');
    if(pathSegments[1] === 'NewCustomer'){
      this.setState({isEdit: false})
    } else {
      this.setState({isEdit: true, customerId: this.props.navigation.getParam('customerId') ?? ''})
      this.getCustomerDetail(this.props.navigation.getParam('customerId'))
    } 
  }

  handleClickDob = ()=>{
    this.setState({
      isDOB: !this.state.isDOB, isDatePurchase: false,
      isRoadTaxRenewal: false,
      isCalendarOpen: false,
    })
  }

  getCustomerDetail = async (id: any) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.crmEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getCustomerDetailId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCustomerDetailReceiver = (responseJson: any) => {    
    this.setState({
      name: responseJson.data.attributes.name,
      dob:responseJson.data.attributes.date_of_birth,
      contactNumber: responseJson.data.attributes.contact_number,
      email: responseJson.data.attributes.email,
      datePurchase: responseJson.data.attributes.date_of_purchase,
      dateRoadTaxRenewal: responseJson.data.attributes.date_of_road_tax_renawal,
      services: responseJson.data.attributes.services,
      price: responseJson.data.attributes.price,
      selectedModel: responseJson.data.attributes.model,
      selectedMake: responseJson.data.attributes.make,
      engineCap: responseJson.data.attributes.engine_cap,
      milage: responseJson.data.attributes.milage,
      vehicleType:responseJson.data.attributes.vehicle_type,
      date_of_insurance:responseJson.data.attributes.date_of_insurance
    })
    this.getModelList(responseJson.data.attributes.make.join(','))
   }

  getMakeList = async () => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.makeModelEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getMakeListId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMakeListReceiver = (responseJson: any) => {
    const makeList = responseJson.data.map((e: any)=>e.attributes.name)
    this.setState({listMake: makeList})
  }

  getModelList = async (selectedMake: string) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.makeModelEndPoint}?filter_values=${selectedMake}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getModelListId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getModelListReceiver = (responseJson: any) => {
    const modelList = responseJson.data?.map((e: any)=>e.attributes.name)
    this.setState({listModel: modelList ?? []})
  }

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  onSelectServices = (event: any) => {
    const {
      target: { value },
    } = event;
    this.setState(
      // On autofill we get a stringified value.
      {...this.state, services: typeof value === 'string' ? value.split(',') : value,}
    );
  }

  handleSelectMake = (value: string)=>{
    if(!this.state.selectedMake.includes(value)){
      let temp = [...this.state.selectedMake, value]
      if(temp.length > 0 ){
        this.getModelList(temp.join(','));
      }
      this.setState({selectedMake:temp});
    }
  }

  handleDeleteSelectedMake = (value: string) => {
    let temp = this.state.selectedMake;
    temp = temp.filter(item => item !== value);
    this.setState({selectedMake: temp}); 
    if(this.state.listMake.length > 0){
      this.getModelList(this.state.selectedMake.join(','));
    }
  }

  handleSelectModel = (value: string)=>{
    if(!this.state.selectedModel.includes(value)){
      this.setState({selectedModel:[...this.state.selectedModel,value]})
    }
  }

  handleDeleteSelectedModel = (value: string) => {
    let temp = this.state.selectedModel;
    temp = temp.filter(item => item !== value);
    this.setState({selectedModel: temp})
  }

  handleSelectPrice = (value: any)=>{
      this.setState({price:value})
  }

  handleDeleteSelectedPrice = () => {
    this.setState({price: ''})
  }

  handleSelectEngineCap = (value: string)=>{
    this.setState({engineCap:value})
  }

  handleDeleteSelectedEngineCap = () => {
    this.setState({engineCap: ''})
  }

  handleSelectMilage = (value: any)=>{
    this.setState({milage: value})
  }

  handleDeleteSelectedMilage = () => {
    this.setState({milage: ''})
  }

  handleSelectVehicleType = (value: any)=>{
    if(!this.state.vehicleType.includes(value)){
      this.setState({vehicleType:[...this.state.vehicleType,value]})
    }
  }

  handleDeleteSelectedVehicleType = (value: string) => {
    let temp = this.state.vehicleType;
    temp = temp.filter(item => item !== value);
    this.setState({vehicleType: temp})
  }

  checkSubmit = () => {
    this.setState({...this.state, formIsDirty: true})
  }

  onSubmit = (e:any) => {
    if(this.state.isEdit){
      this.updateCustomer(e);
    } else {
      this.addNewCustomer(e);
    }
  }

  addNewCustomer = async (e: any) => {
    const tokenValue = await getStorageData("authToken")
      const headers = {
        token: tokenValue,
      };
    let formdata = new FormData();
    formdata.append('name',e.name);
    formdata.append('date_of_birth', moment(e.dob).format('DD/MM/YYYY'));
    formdata.append('email', e.email);
    formdata.append('contact_number', e.contactNumber);
    formdata.append('date_of_road_tax_renawal', moment(e.dateRoadTaxRenewal).format('DD/MM/YYYY'));
    formdata.append('date_of_purchase',moment(e.datePurchase).format('DD/MM/YYYY'));
    formdata.append('date_of_insurance',moment(e.date_of_insurance).format('DD/MM/YYYY'));
   
    for (const services of this.state.services) {
      formdata.append("services[]", services);
    }
    for (const make of this.state.selectedMake) {
      formdata.append("make[]", make);
    }
    for (const model of this.state.selectedModel) {
      formdata.append("model[]", model);
    }
    this.state.price && formdata.append("price[]", this.state.price);
    this.state.engineCap && formdata.append("engine_cap[]", this.state.engineCap);
    this.state.milage && formdata.append("milage[]", this.state.milage);
    for (const vehicleType of this.state.vehicleType) {
      formdata.append("vehicle_type[]", vehicleType);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.crmEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.addNewCustomerId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addNewCustomerReceiver = (responseJson: any) => {
    if(responseJson.data.id !== null){
      this.props.navigation.goBack();
    }
  }

  updateCustomer = async (e: any) => {
    const tokenValue = await getStorageData("authToken")
      const headers = {
        token: tokenValue,
      };
    let formdataEdit = new FormData();
    formdataEdit.append('name',e.name);
    formdataEdit.append('date_of_birth', moment(e.dob).format('DD/MM/YYYY'));
    formdataEdit.append('email', e.email);
    formdataEdit.append('contact_number', e.contactNumber);
    formdataEdit.append('date_of_road_tax_renawal', moment(e.dateRoadTaxRenewal).format('DD/MM/YYYY'));
    formdataEdit.append('date_of_purchase', moment(e.datePurchase).format('DD/MM/YYYY'));
    formdataEdit.append('date_of_insurance',moment(e.date_of_insurance).format('DD/MM/YYYY'));
    for (const services of this.state.services) {
      formdataEdit.append("services[]", services);
    }
    
    if(this.state.selectedMake.length>0){
      for (const make of this.state.selectedMake) {
        formdataEdit.append("make[]", make);
      }
    }else{
        formdataEdit.append("make[]", "");
      }
    
      if(this.state.selectedModel.length>0){
        for (const model of this.state.selectedModel) {
          formdataEdit.append("model[]", model);
        }
      }else{
          formdataEdit.append("model[]", "");
        }


   
     formdataEdit.append("price[]", this.state.price);
      formdataEdit.append("engine_cap[]", this.state.engineCap);
      formdataEdit.append("milage[]", this.state.milage);
      if(this.state.vehicleType.length>0){
        for (const model of this.state.vehicleType) {
          formdataEdit.append("vehicle_type[]", model);
        }
      }else{
          formdataEdit.append("vehicle_type[]", "");
        }
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.crmEndPoint}/${this.state.customerId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdataEdit
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    this.updateCustomerId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateCustomerReceiver = (responseJson: any) => {
    if(responseJson?.data?.id !== null){
     this.props.navigation.goBack();
    }
  }

  handleCancel = () => {
    this.props.navigation.goBack();
  }

  toggleCalendar = () => {
    this.setState((prevState) => ({
      isCalendarOpen: !prevState.isCalendarOpen, 
      isDatePurchase:false,
      isDOB:false,
      isRoadTaxRenewal:false,
      
      // Toggle calendar visibility
    }))};
  // Customizable Area End
}