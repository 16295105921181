import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { getStorageData, removeStorageData} from "../../../framework/src/Utilities";
import moment from "moment";
export const configJSON = require("./config");
import {FormikProps} from "formik" ;

const pageid = "205301136008162";
const longString = "EAAnW2X7zoOwBO0hcg7I3rsdZB9xmeZC5YZAmpnyZCc4jZA7XKtuw0n84ApNVkZBpeLN30O56wsAfKZCiWYezYVK0GNJo3t09ZBtWh2G1I5ZCt3kd8MBwmvZCEpErZCtO6idF3CyIgZBenvkNZCRpz3tZBreRr9TmI7pk7QuJtLfDZA6kCZA5zBhW5A50LaYUK0EbhlfIwFkZASr0a8AUZD"
const insta_id = "17841464943600812";
type NumericInputOptions = {
  allowDecimal?: boolean;
  maxDecimalPlaces?: number;
  maxValue?: number;
  maxLength?: number;
  isInteger?: boolean;
  exactLength?: number;
};
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  handleAddCatalougue:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userType: any;
  accessoriesD:string;
  featuresD:string;
  description:string;
  showError:boolean;
  selectedImage: any;
  currentImage:null;
  openModel:boolean;
  images:string[];
  selectedImages: any,
  make:string;
  model:string;
  carName:string;
  PurchasePrice:string;
  sellingPrice:string;
  depreciation:string;
  regDate:string;
  milage:string;
  manufactured:string;
  roadTax:string;
  trasmission:string;
  degree:string;
  omv:string;
  coe:string;
  arf:string;
  engineCap:string;
  power:string;
  curbWeight:string;
  owner:string;
  vehicleType:string;
  descripArea:string;
  features:string;
  accessories:string;
  vehicleData:any;
  makeModalData:any;
  paramId:any;
  dataModal:any;
  isEdit: boolean;
  formIsDirty: boolean;
  removedImage : number[];
  vehicleNumber:string,
  government: string,
  empId:string,
  isOpenCalendar: boolean;
  isShareToFacebook: boolean;
  isShareToInstagram: boolean;
  isShareToTiktok: boolean;
  mediaIdsCatalogue: any;
  instagramIdsCatalogue: any;
  imagesURLFacebook: any;
  imagesURLInstagram: any;
  isLoading: boolean;
  isSharedToFacebook: boolean;
  isSharedToInstagram: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AddCatalougueController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postForgotPasswordApiId: string = "";
  editCataloguesApiId:string = "";
  crateCataloguesApiId:string = '';
  getVehicleEditDataId:string = '';
  getMakeModalDataId:string = '';
  getModalDataId:string='';
  getMediaIdFacebookId = '';
  createFacebookPostId = '';

  createInstagramMediaContainerId = '';
  publishInstagramMediaContainerId = '';
  createItemContainerInstagramId = '';
  createCarouselContainerInstagramId = '';
  formikMethods : FormikProps<unknown> | null  ;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.formikMethods = null;
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.state = {
      userType:'',
      makeModalData:[],
      dataModal:[],
      vehicleData:[],
      accessoriesD:'',
      featuresD:'',
      description: '',
      showError:false,
      images:[],
      selectedImages:[],
      selectedImage: null,
      openModel: false,
      currentImage:null,
      make:'',
      model:'',
      carName:'',
      PurchasePrice:'',
      sellingPrice:'',
      depreciation:'',
      regDate:'',
      milage:'',
      manufactured:'',
      roadTax:'',
      trasmission:'',
      degree:'',
      omv:'',
      coe:'',
      arf:'',
      engineCap:'',
      power:'',
      curbWeight:'',
      owner:'',
      vehicleType:'',
      descripArea:'',
      features:'',
      accessories:'',
      paramId:"null",
      isEdit: false,
      formIsDirty:false,
      removedImage: [],
      vehicleNumber:'',
      government:'',
      empId:'',
      isLoading:false,
      isOpenCalendar: false,
      isShareToFacebook: false,
      isShareToInstagram:false,
      isShareToTiktok:false,
      mediaIdsCatalogue: [],
      instagramIdsCatalogue: [],
      imagesURLFacebook: [],
      imagesURLInstagram: [],
      isSharedToFacebook: false,
      isSharedToInstagram:false,
      
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    // let id = this.props.navigation.getParam("id")
    // this.setState({paramId : id})
    // this.getVehicleEditDataApi()
    this.getMakeModalDataApi()
    this.checkEdit();
    // Customizable Area End
  }


  // Customizable Area Start
  // Customizable Area Start
  checkDataPreviousScreen = async()=>{
    const vehicleNumber = await getStorageData("vehicleNumber")
    const government = await getStorageData("government")
    const empId = await getStorageData("empId")
    if(vehicleNumber === null || government === null || empId === null){
      this.props.navigation.navigate('Catalogue')
    } else {
      this.setState({
        empId:empId, 
        government:government, 
        vehicleNumber:vehicleNumber
      })
    }
  }

  checkEdit = async() =>{
    const pathSegments = window.location.pathname.split('/');
    if(pathSegments[1] === 'AddCatalougue'){
      this.checkDataPreviousScreen();
      this.setState({isEdit: false})
    } else {
      const userType = await getStorageData("user_type");
      this.setState({isEdit: true, paramId: this.props.navigation.getParam('id') ?? '', userType })
      this.getVehicleEditDataApi();
      
    } 
  }

  handleImageChange = (event: any) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
        const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/tiff'];
        const filteredFiles = Array.from(selectedFiles).filter((file: any) =>
            validImageTypes.includes(file.type)
        );

        if (filteredFiles.length > 0) {
            const newImages = filteredFiles.map((file: any) => URL.createObjectURL(file));

            this.setState((prevState) => ({
                images: [
                    ...prevState.images,
                    ...newImages.slice(0, 8 - prevState.images.length)
                ],
                selectedImages: [
                    ...prevState.selectedImages,
                    ...filteredFiles.slice(0, 8 - prevState.selectedImages.length)
                ],
                currentImage: null,
            }));
        }
    }
};

  handleRemoveImage = (index:number, url: string) => {
    if(this.state.isEdit) {
      const id = this.state.vehicleData?.attributes.images.find((args: any)=>args.url === url).id
      this.setState({removedImage:[...this.state.removedImage, id]})
    }
    const updatedImages = [...this.state.images];
    const selectedImages = [...this.state.selectedImages];
    updatedImages.splice(index, 1);
    selectedImages.splice(index,1)
    this.setState({
      images: updatedImages,
      selectedImages: selectedImages,
    });
  };

  handleCancel = ()=>{
    this.props.navigation.goBack()
  }

  checkSubmit = () => {
    this.setState({...this.state, formIsDirty: true})
  }

  handleSuccess = (responseJson:any) =>{
    this.setState({
      imagesURLFacebook: responseJson.catalogue.data.attributes.images,
      imagesURLInstagram: responseJson.catalogue.data.attributes.images,

    }, ()=>{
      if (this.state.isShareToFacebook){
        this.getMediaFacebookId(this.state.imagesURLFacebook[0].url)
      }
      if (this.state.isShareToInstagram){
        this.handlePostInstagram();
      }
      if (!this.state.isShareToFacebook && !this.state.isShareToInstagram){
        this.setState({openModel:true, isLoading: false})
      }
    });    
  }

  


  handleSuccessedit =(responseJson:any) =>{
      this.setState({ isLoading:false ,openModel:true})
  }

  handleCloseModal = () =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "NewCustomer"
        );
        
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        
        const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
          test: 'test',
        });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      
        this.send(message);
    this.props.navigation.navigate('Catalogue')
    this.setState({openModel:false})
  }

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };
  getMakeModalDataApi = async () => {
      const tokenValue = await getStorageData("authToken")
      const header = {
        "Content-Type": configJSON.productApiContentType,
        "token": tokenValue
      };
      const requestMessageCatalogue = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessageCatalogue.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageCatalogue.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getMakeModalDataApi
      );
      requestMessageCatalogue.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeGet
      );
      this.getMakeModalDataId = requestMessageCatalogue.messageId;
      runEngine.sendMessage(requestMessageCatalogue.id, requestMessageCatalogue);
    }

    getModelDataApi = async (make: string) => {
      const tokenValue = await getStorageData("authToken")
      const header = {
        "Content-Type": configJSON.productApiContentType,
        "token": tokenValue
      };
      const requestMessageCatalogue = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessageCatalogue.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageCatalogue.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getMakeModalDataApi}?filter_values=${make}`
      );
      requestMessageCatalogue.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeGet
      );
      this.getModalDataId = requestMessageCatalogue.messageId;
      runEngine.sendMessage(requestMessageCatalogue.id, requestMessageCatalogue);
    }

    getModalDataReceiver =(responseJson:any)=>{
      if(!responseJson.error){
        this.setState({ dataModal: responseJson.data})
      }
    }
    
    getMakeModalDataReceiver =(responseJson:any)=>{
      if(!responseJson?.error){
        this.setState({ makeModalData: responseJson?.data})
      }
    }

getVehicleEditDataApi = async () => {
  let id = this.props.navigation.getParam("id")
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": tokenValue
    };
    const requestMessageCatalogue = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVehicleEditDataApi}${id}`
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.getVehicleEditDataId = requestMessageCatalogue.messageId;
    runEngine.sendMessage(requestMessageCatalogue.id, requestMessageCatalogue);
  }

  getVehicleEditDataReceiver =(responseJson:any)=>{
    this.setState({ 
      vehicleData: responseJson?.catalogue?.data,
      images: responseJson?.catalogue?.data?.attributes.images?.map((args: { url: any; })=>args.url), 
      make: responseJson?.catalogue?.data?.attributes.make,
      model: responseJson?.catalogue?.data?.attributes.model,
      manufactured: responseJson?.catalogue?.data?.attributes.manufactured_year,
    })
    this.getModelDataApi(responseJson?.catalogue?.data?.attributes.make);
  }

  onSubmit = (args: any) => {
    if(this.state.images?.length !== 0){
      this.setState({
       isLoading:true})
      if(this.state.isEdit){
        this.editCatalogueApi(args);
      } else {
        this.addCatalogueApi(args);
      }
    }
  }
  
   handleNumericInput = (evnt : React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, options : NumericInputOptions = {}) => {
    const {
      allowDecimal = false,
      maxDecimalPlaces = 0,
      maxValue = null,
      maxLength = null,
      isInteger = false,
    } = options;
    const Allowed = ['Backspace' , 'Delete', 'Tab' , 'Enter' , 'ArrowLeft', 'ArrowRight']
    
    if(Allowed.includes(evnt.key)) return ;
    const target = evnt.target as HTMLInputElement;
    if (!/[0-9.]/.test(evnt.key) ||
    (allowDecimal && evnt.key === '.' && target.value.includes('.')) ||
    ((isInteger && evnt.key === '.')) || 
    ((maxLength !== null && target.value.length >= maxLength)) || 
    ((maxValue !== null && parseFloat(target.value + evnt.key) > maxValue) ) ||
    ((allowDecimal && target.value.includes('.') && target.value.split('.')[1].length >= maxDecimalPlaces)  && 
    (target.selectionStart && target.selectionStart > target.value.indexOf('.')))
    ) {
      evnt.preventDefault();
    }
  };
  handleRegDate = (date : any) => {
    if(date && date <= new Date()) {
      this.formikMethods?.setFieldValue("regDate", date)
      this.closeCalendar()
    }
  }
  renderRegDateTypography = (regDate : string) => {
    return regDate !== "" ? moment(regDate).format('MM/DD/YYYY') : "mm/dd/yyyy";
  };
  getRegDateButtonClass = (regDate : string) => {
    return regDate === "" ? "customButtonPLACEHOLDER" : "customButton";
  }
  toggleCalendar = () => {
    this.setState((prevState: any) => ({
      isOpenCalendar: !prevState.isOpenCalendar
    }))};

  closeCalendar = ()=>{
    this.setState(() => ({
      isOpenCalendar: false
    }))};
    
  addCatalogueApi = async(args: any) => {
  const tokenValue = await getStorageData("authToken")
      const headers = {
        token: tokenValue,
      };
      let formdataAdd = new FormData();
      if (this.state.selectedImages && this.state.selectedImages.length > 0) {
        for (const image of this.state.selectedImages) {
          formdataAdd.append("images[]", image);
        }
    }
      formdataAdd.append("reg_date", moment(args.regDate).format('DD/MM/YYYY')); 
      formdataAdd.append("mileage", args.mileage);
      formdataAdd.append("road_tax", args.roadTax);
      formdataAdd.append("transmission", args.trasmission);
      formdataAdd.append("sku", args.carName);
      formdataAdd.append("description", args.descripArea ?? "");
      formdataAdd.append("manufactured_year", args.manufactured);
      formdataAdd.append("degree_value", args.degree);
      formdataAdd.append("omv", args.omv);
      formdataAdd.append("coe", args.coe);
      formdataAdd.append("arf", args.arf);
      formdataAdd.append("engine_cap", args.engineCap);
      formdataAdd.append("power", args.power);
      formdataAdd.append("curb_weight", args.curbWeight);
      formdataAdd.append("purchase_price", args.PurchasePrice);
      formdataAdd.append("selling_price", args.sellingPrice);
      formdataAdd.append("no_of_owner", args.owner);
      formdataAdd.append("features", args.features?? "");
      formdataAdd.append("vehicle_type", args.vehicleType);
      formdataAdd.append("accessories", args.accessories ?? "");
      formdataAdd.append("name", args.carName);
      formdataAdd.append("depreciation", args.depreciation);
      formdataAdd.append("model", args.model);
      formdataAdd.append("make", args.make);
      formdataAdd.append("government_id", this.state.government);
      formdataAdd.append("emp_id", this.state.empId);
      formdataAdd.append("vehicle_number", this.state.vehicleNumber);
      formdataAdd.append("purchase_price", args.PurchasePrice);
      formdataAdd.append("selling_price", args.sellingPrice);
      const requestMessageCatalogue = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessageCatalogue.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessageCatalogue.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addCatalogueApi
      );
      requestMessageCatalogue.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdataAdd
      );
      requestMessageCatalogue.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.addCataloguePost
      );
      this.crateCataloguesApiId = requestMessageCatalogue.messageId;
      runEngine.sendMessage(requestMessageCatalogue.id, requestMessageCatalogue);
  }
  editCatalogueApi = async (args: any) => {
    await this.handleDeleteImage()
    const tokenValue = await getStorageData("authToken")
    const headers = {
      token: tokenValue,
    };
    let id = this.props.navigation.getParam("id")
    let formdata = new FormData();
    if (this.state.selectedImages && this.state.selectedImages.length > 0) {
      for (const image of this.state.selectedImages) {
        formdata.append("images[]", image);
      }
    }
    formdata.append("reg_date", moment(args.regDate).format('DD/MM/YYYY')); 
      formdata.append("mileage", args.mileage);
      formdata.append("road_tax", args.roadTax);
      formdata.append("transmission", args.trasmission);
      formdata.append("sku", args.carName);
      formdata.append("description", args.descripArea);
      formdata.append("manufactured_year", args.manufactured);
      formdata.append("degree_value", args.degree);
      formdata.append("omv", args.omv);
      formdata.append("coe", args.coe);
      formdata.append("arf", args.arf);
      formdata.append("engine_cap", args.engineCap);
      formdata.append("power", args.power);
      formdata.append("curb_weight", args.curbWeight);
      formdata.append("purchase_price", args.PurchasePrice);
      formdata.append("selling_price", args.sellingPrice);
      formdata.append("no_of_owner", args.owner);
      formdata.append("vehicle_type", args.vehicleType);
      formdata.append("features", args.features);
      formdata.append("accessories", args.accessories);
      formdata.append("name", args.carName);
      formdata.append("depreciation", args.depreciation);
      formdata.append("model", args.model);
      formdata.append("make", args.make);
      formdata.append("purchase_price", args.PurchasePrice);
      formdata.append("selling_price", args.sellingPrice);
      formdata.append("government_id", "government_id");
      formdata.append("emp_id", "1234");
      formdata.append("vehicle_number", "vehicle_number");
    const requestMessageCatalogue = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editCatalogueApi}${id}`
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );
    this.editCataloguesApiId = requestMessageCatalogue.messageId;
    runEngine.sendMessage(requestMessageCatalogue.id, requestMessageCatalogue);
  }

  handleDeleteImage = async ()=>{
    for(const value of this.state.removedImage){
      const tokenValue = await getStorageData("authToken")
      const headers = {
        token: tokenValue,
      };
      const requestMessageCatalogue = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessageCatalogue.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessageCatalogue.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.editCatalogueApi}delete_image?attachment_name=images&attachment_id=${value}&catalogue_id=${this.state.paramId}`
      );
      requestMessageCatalogue.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeDelete
      );
      this.editCataloguesApiId = requestMessageCatalogue.messageId;
      runEngine.sendMessage(requestMessageCatalogue.id, requestMessageCatalogue);
    }

  }

  handleSelectFacebook = ()=>{
    this.setState({isShareToFacebook:!this.state.isShareToFacebook})
  }
  
  handleSelectInstagram = ()=>{
    this.setState({isShareToInstagram:!this.state.isShareToInstagram})
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(this.crateCataloguesApiId == apiRequestCallId && responseJson) {
      this.handleSuccess(responseJson)
    }
    else if(this.editCataloguesApiId == apiRequestCallId && responseJson) {
      this.handleSuccessedit(responseJson)
    }
    switch (apiRequestCallId) {
      case this.getVehicleEditDataId:
        this.getVehicleEditDataReceiver(responseJson);
        break;
        case this.getMakeModalDataId:
        this.getMakeModalDataReceiver(responseJson);
        break;
        case this.getModalDataId:
          this.getModalDataReceiver(responseJson);
          break;
        case this.getMediaIdFacebookId:
          responseJson && this.getMediaIdFacebookReceiver(responseJson);
          break;
        case this.createFacebookPostId:
          this.createFacebookPostReceiver();
          break;
        case this.createInstagramMediaContainerId:
          this.publishInstagramMediaContainer(responseJson.id);
          break;
        case this.publishInstagramMediaContainerId:
          this.publishInstagramMediaContainerReceiver(responseJson)
          break;
        case this.createItemContainerInstagramId: 
          this.createItemContainerInstagramReceiver(responseJson)
          break;
        case this.createCarouselContainerInstagramId:
          this.publishInstagramMediaContainer(responseJson.id);
          break;
      }
      
    // Customizable Area End
  }

  getMediaFacebookId = async (url: any) => {
    const requestMessageCatalogue = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      url: url,
      access_token: longString,
      published: false,
    };
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/${pageid}/photos`
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.getMediaIdFacebookId = requestMessageCatalogue.messageId; 
    runEngine.sendMessage(requestMessageCatalogue.id, requestMessageCatalogue) ;
  };

  getMediaIdFacebookReceiver = (response: any) => {
    let temparr =[...this.state.imagesURLFacebook];
    temparr.shift()
    this.setState(prevState => ({
      mediaIdsCatalogue: [...prevState.mediaIdsCatalogue, response.id] ,
      imagesURLFacebook: temparr
    }), () => {
      if(this.state.imagesURLFacebook.length > 0) {
        this.getMediaFacebookId(this.state.imagesURLFacebook[0].url)
      } else {
        this.postToFacebook(this.state.mediaIdsCatalogue)
      }
    });
  };

  postToFacebook = async (mediaIdsCatalogue: string[]) => {
    const requestMessageCatalogue = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      message: `New Car is Added`,
      attached_media: mediaIdsCatalogue.map(idmedia => ({ media_fbid: idmedia })),
    };
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type': 'application/json',
      })
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `https://graph.facebook.com/${pageid}/feed?access_token=${longString}`
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createFacebookPostId = requestMessageCatalogue.messageId;
    runEngine.sendMessage(requestMessageCatalogue.id, requestMessageCatalogue);
  };

  createFacebookPostReceiver = () => {
    this.setState({isSharedToFacebook: true}, ()=>{
      if (this.state.isSharedToInstagram || !this.state.isShareToInstagram) {
        this.setState({ openModel: true, isLoading: false });
      }
    })
  };

  handlePostInstagram = ()=>{
    if (this.state.imagesURLInstagram.length === 1){
        this.createSingleImagePostInstagram();
    } else {
      this.createMultipleImagePostInstagram(this.state.imagesURLInstagram[0].url)
    }
  }

  createSingleImagePostInstagram = () => {
    this.createInstagramMediaContainer(this.state.imagesURLInstagram[0].url, `New Car is Added`)
  };

  createMultipleImagePostInstagram =(url: string)=>{
    this.createItemContainerInstagram(url)
  }

  createInstagramMediaContainer = (imageUrl: string, caption: string) => {    
    const requestMessageCatalogue = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      image_url: imageUrl,
      caption: caption,
      access_token: longString,
    };
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${insta_id}/media`
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createInstagramMediaContainerId = requestMessageCatalogue.messageId; 
    runEngine.sendMessage(requestMessageCatalogue.id, requestMessageCatalogue) ;
  };

  publishInstagramMediaContainer = (creationId: string) => {
    const requestMessageCatalogue = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      creation_id : creationId,
      access_token: longString,
    };
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${insta_id}/media_publish`
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.publishInstagramMediaContainerId = requestMessageCatalogue.messageId; 
    runEngine.sendMessage(requestMessageCatalogue.id, requestMessageCatalogue) ;
  }

  publishInstagramMediaContainerReceiver = (response: any)=>{
    this.setState({isSharedToInstagram: true}, ()=>{
      if (this.state.isSharedToFacebook || !this.state.isShareToFacebook) {
        this.setState({ openModel: true, isLoading: false });
      }
    })
  }

  createItemContainerInstagram =  (url: string)=>{
    const requestMessageCatalogue = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      image_url: url,
      is_carousel_item: true,
      access_token: longString,
    };
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${insta_id}/media`
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createItemContainerInstagramId = requestMessageCatalogue.messageId; 
    runEngine.sendMessage(requestMessageCatalogue.id, requestMessageCatalogue) ;
  }

  createItemContainerInstagramReceiver = (response: any)=>{
    let temparr =[...this.state.imagesURLInstagram,]
    temparr.shift()
    this.setState(prevState => ({
      instagramIdsCatalogue: [...prevState.instagramIdsCatalogue, response.id],
      imagesURLInstagram: temparr
    }), () => {
      if(this.state.imagesURLInstagram.length > 0) {
        this.createItemContainerInstagram(this.state.imagesURLInstagram[0].url)
      } else {
          this.createCarouselContainerInstagram(this.state.instagramIdsCatalogue)
      }
    });
  }

  createCarouselContainerInstagram = (listIds: any)=>{
    const requestMessageCatalogue = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      media_type: "CAROUSEL",
      caption: `New Car is Added`,
      children:listIds && listIds.join(','),
      access_token: longString,
    };
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${insta_id}/media`
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageCatalogue.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createCarouselContainerInstagramId = requestMessageCatalogue.messageId; 
    runEngine.sendMessage(requestMessageCatalogue.id, requestMessageCatalogue) ;
  }  
  
  // Customizable Area End
}
