import React, {  } from "react";

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    BlobProvider,
    Image,
    Font} from "@react-pdf/renderer";
import moment from "moment";

const BoldFont = require("./fonts/TimesNewRomanBold.ttf");

type Props = {
    isSave: boolean;
    setPdf: any;
    data: any;
};

Font.register({
    family: 'Bold1',
    src: BoldFont,
    fontWeight: 'bold',
    format: "truetype",
});

Font.register({
    family: 'Outfit, sans-serif',
    src: require('./fonts/Outfit-Medium.ttf'),
    fontWeight: 'bold',
});

Font.register({
    family: "EB Garamond",
    src: require('./fonts/EBGaramond-Bold.ttf'),
    fontWeight: 'bold',
    
});

Font.register({
    family: 'BoldArialNarrow',
    src: require('./fonts/arialnarrow_bold.ttf'),
    fontWeight: 'bold',
    format: "truetype",
});

Font.register({
    family: 'RegularArialNarrow',
    src: require('./fonts/arialnarrow.ttf'),
    fontWeight: 'normal',
});

Font.register({
    family: "Italianno",
    src: require('./fonts/EBGaramond-Italic.ttf'),
    fontWeight: 'normal',
});

const styles = StyleSheet.create({
    text: {
        fontFamily: 'Outfit, sans-serif',
        fontSize: 6.5,
    },
    textArial: {
        fontFamily: 'RegularArialNarrow',
        fontSize: 11,
    },
    textArialBold: {
        fontFamily: 'BoldArialNarrow',
        fontSize: 10,
    },
    textArialBold11: {
        fontFamily: 'BoldArialNarrow',
        fontSize: 11,
    },
    text10: {
        fontFamily: 'Outfit, sans-serif',
        fontSize: 10,
    },
    textBold17: {
        fontFamily: 'Bold1',
        fontSize: 17,
    },
    textBold12: {
        fontFamily: 'Bold1',
        fontSize: 12,
    },
    textBold13: {
        fontFamily: 'Bold1',
        fontSize: 13,
    },
    textBold10: {
        fontFamily: 'Bold1',
        fontSize: 9,
    },
    textBold6Normal: {
        fontFamily: 'EB Garamond',
        fontSize: 6.5,
    },
    textBold6Italic: {
        fontFamily: 'Italianno',
        fontSize: 6.5,
    },
    textBold8: {
        fontFamily: 'Outfit, sans-serif',
        fontSize: 8,
        fontWeight: 700

    },
    textBold8Clear: {
        fontFamily: 'EB Garamond',
        color: "#fff",
        fontSize: 7,
    },
});





const pStyles = StyleSheet.create({

    page: {
        backgroundColor: "#ffffff",
        padding: '20px 23px',
        letterSpacing: 0
    },

    page2: {
        backgroundColor: "#ffffff",
        padding: '74px 74px',
        letterSpacing: 0
    },
});

const Doc1 = ({data, dob, dobGuarantor, dobGuarantor1}: any)=>(<>
    <Document>
        <Page size="A4" style={pStyles.page}>
            <View style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <Image src={require("../src/tambusa.jpeg")} style={{ width: 145, height: 30, border: "1px solid" }} />

                <View style={{ height: 30, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <Text style={styles.textBold8}>TEMBUSU FINANCIAL SERVICES PTE. LTD.</Text>
                    <Text style={styles.textBold8}>RC No. 201831354N GST No. 201831354N</Text>
                    <Text style={styles.textBold8}>61 Ubi Avenue 2 #06-04 Singapore 408898 | Tel: 6741 2588 | Fax: 6904 9686</Text>


                </View>

            </View>

            <View style={{ border: '1px solid #000', marginTop: 5 }}>
                <View style={{ backgroundColor: '#000', textAlign: "center", padding: '1px 2px', borderBottom: '1px solid #000' }}>
                    <Text style={styles.textBold8Clear}>APPLICATION FORN FOR MOTOR VEHICLE FINANCING</Text>
                </View>

                <View style={{ backgroundColor: '#D0D0D0', textAlign: "left", padding: '1px 2px', borderBottom: '1px solid #000' }}>
                    <Text style={styles.textBold6Normal}>PERSONAL PARTICULARS OF APPLICANT</Text>
                </View>

                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row', height: 30 }}>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}>Name of Applicant (<Text 
                        style={styles.textBold6Italic}
                        >
                            Underline surname
                            </Text> ):</Text>
                            <Text style={styles.textBold6Normal}>{data?.fullName}</Text>
                    </View>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>


                        <Text style={styles.textBold6Normal}>NRIC/Passport/Company Registration/Business</Text>
                        <Text style={styles.textBold6Normal}> Registration No. ( 
                            <Text style={styles.textBold6Italic}>
                            provide copy of NRIC: Passport ACRA search
                            </Text>
                          
                            
                            )</Text>
                            <Text style={styles.textBold6Normal}>{data?.NRIC}</Text>
                    </View>
                    <View style={{ flex: 1, padding: '1px 2px' }}>
                        <Text style={styles.textBold6Normal}> Residential / Registered Office Address</Text>
                        <Text style={styles.textBold6Normal}>{data?.address}</Text>
                    </View>

                </View>
                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row', height: 15 }}>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}>Date of Birth (
                            <Text style={styles.textBold6Italic}>
                            dd mm yyyy</Text>
                            )</Text>
                            <Text style={styles.textBold6Normal}>{dob}</Text>
                    </View>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>


                        <Text style={styles.textBold6Normal}>Telephone No.</Text>
                        <Text style={styles.textBold6Normal}>{data?.contactNumber}</Text>

                    </View>
                    <View style={{ flex: 1, padding: '1px 2px' }}>
                        <Text style={styles.textBold6Normal}> Email Address</Text>
                        <Text style={styles.textBold6Normal}>{data?.emailID}</Text>
                    </View>

                </View>
                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row' }}>
                    <View style={{ width: "33.4%", borderRight: '1px solid #000' }}>
                        <View style={{ borderBottom: "1px solid #000", height: 30, padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Occupation</Text>
                            <Text style={styles.textBold6Normal}>{data?.jobTitle}</Text>

                        </View>
                        <View style={{ padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Length of Service</Text>
                            <View style={{ flexDirection: 'row', width: "90%", justifyContent: "flex-end" }}>
                                <View style={{ flexDirection: 'row', width: "30%", justifyContent: "space-between" }}>
                                    <Text style={styles.textBold6Normal}> yrs</Text>
                                    <Text style={styles.textBold6Normal}> mths</Text>
                                </View>

                            </View>


                        </View>
                    </View>

                    <View style={{ width: "33.4%", borderRight: '1px solid #000' }}>
                        <View style={{ borderBottom: "1px solid #000", height: 30, padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Name of Employer/ Self-Employed:</Text>
                            <Text style={styles.textBold6Normal}> (
                                <Text style={styles.textBold6Italic}>provide copy of salary slip, income too and CPF statement</Text>
                                )</Text>
                                <Text style={styles.textBold6Normal}>{data?.employer}</Text>
                        </View>
                        <View style={{ padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Monthly Income
                            </Text>
                            <View style={{ height: 3 }}></View>
                            <Text style={styles.textBold6Normal}> S${data?.grossMonthlySalary}</Text>
                        </View>
                    </View>


                    <View style={{ flex: 1, padding: '1px 2px', flexDirection: 'row' }}>


                        <View style={{ flex: 1 }}>
                            <Text style={styles.textBold6Normal}>Gender </Text>
                            <Text style={styles.textBold6Normal}>☐  Male</Text>
                            <Text style={styles.textBold6Normal}>☐  Female</Text>

                        </View>
                        <View style={{ flex: 1 }}>
                            <Text style={styles.textBold6Normal}>Nationality </Text>
                            <Text style={styles.textBold6Normal}>☐  SC</Text>
                            <Text style={styles.textBold6Normal}>☐  PR</Text>
                            <Text style={styles.textBold6Normal}>☐  Others</Text>
                        </View>

                        <View style={{ flex: 1 }}>
                            <Text style={styles.textBold6Normal}>Race </Text>
                            <Text style={styles.textBold6Normal}>☐  Malay</Text>
                            <Text style={styles.textBold6Normal}>☐  Chinese</Text>
                            <Text style={styles.textBold6Normal}>☐  Indian</Text>
                        </View>

                        <View style={{ flex: 1 }}>
                            <Text style={styles.textBold6Normal}>MaritalStatus </Text>
                            <Text style={styles.textBold6Normal}>☐  Single</Text>
                            <Text style={styles.textBold6Normal}>☐  Married</Text>
                            <Text style={styles.textBold6Normal}>☐  Others</Text>
                        </View>
                    </View>
                </View>
                <View style={{ backgroundColor: '#D0D0D0', textAlign: "left", padding: '1px 2px', borderBottom: '1px solid #000' }}>
                    <Text style={styles.textBold6Normal}>OTHER BINANCIAL COMMITMENTS</Text>
                </View>
                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row', height: 20 }}>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}>Financial Institution</Text>
                    </View>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>


                        <Text style={styles.textBold6Normal}>Type of Loan</Text>


                    </View>
                    <View style={{ flex: 1, padding: '1px 2px' }}>
                        <Text style={styles.textBold6Normal}> Monthly Repayment</Text>
                    </View>

                </View>
                <View style={{ backgroundColor: '#D0D0D0', textAlign: "left", padding: '1px 2px', borderBottom: '1px solid #000' }}>
                    <Text style={styles.textBold6Normal}>DETAILS OF MOTOR VEHICLE TO BE FINANCED</Text>
                </View>
                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row', height: 20 }}>
                    <View style={{ flex: 2, padding: '1px 2px', borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}>Make & Model</Text>
                        <Text style={styles.textBold6Normal}>{data?.vehicleMake} {data?.vehicleModel}</Text>
                    </View>
                    <View style={{ width: 90, padding: '1px 2px', borderRight: '1px solid #000' }}>


                        <Text style={styles.textBold6Normal}>Year of Manufacture</Text>
                        <Text style={styles.textBold6Normal}>{data?.vehicleManufactured}</Text>

                    </View>
                    <View style={{ width: 90, padding: '1px 2px', borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}> Registration No.</Text>
                        <Text style={styles.textBold6Normal}>{data?.vehicleNumber}</Text>
                    </View>
                    <View style={{ flex: 1, padding: '1px 2px' }}>
                        <Text style={styles.textBold6Normal}> Original Registration Date (
                            
                            <Text style={styles.textBold6Italic}>dd mm yyyy</Text>
                            )</Text>
                            <Text style={styles.textBold6Normal}>{moment(data?.firstRegistrationDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>
                    </View>

                </View>
                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row', height: 20 }}>
                    <View style={{ flex: 2, padding: '1px 2px', borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}>Chassis No</Text>
                        <Text style={styles.textBold6Normal}>{data?.chassisNumber}</Text>
                    </View>
                    <View style={{ width: 90, padding: '1px 2px', borderRight: '1px solid #000' }}>


                        <Text style={styles.textBold6Normal}>Engine Capacity</Text>

                        <View style={{ flexDirection: 'row', width: "90%", justifyContent: "flex-end" }}>
                            <View style={{ flexDirection: 'row', width: "30%", justifyContent: "flex-end" }}>
                                <Text style={styles.textBold6Normal}> cc</Text>

                            </View>

                        </View>
                    </View>
                    <View style={{ width: 90, padding: '1px 2px', borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}> Engine No.</Text>
                        <Text style={styles.textBold6Normal}>{data?.engineNumber}</Text>
                    </View>
                    <View style={{ flex: 1, padding: '1px 2px' }}>
                        <Text style={styles.textBold6Normal}> No. of Transfers</Text>
                    </View>

                </View>
                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row', height: 20 }}>
                    <View style={{ flex: 2, padding: '4px 2px', flexDirection: 'row', alignItems: "flex-end", justifyContent: "space-evenly", borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}>New</Text>
                        <Text style={styles.textBold6Normal}>X Used</Text>
                        <Text style={styles.textBold6Normal}>Passenger</Text>
                        <Text style={styles.textBold6Normal}>Commercial Vehicle</Text>
                        <Text style={styles.textBold6Normal}>Normal</Text>
                        <Text style={styles.textBold6Normal}> Off-peak Car</Text>


                    </View>


                    <View style={{ width: 90, padding: '1px 2px', borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}> COE Amount S$</Text>
                    </View>
                    <View style={{ width: 123.4, padding: '1px 2px' }}>
                        <Text style={styles.textBold6Normal}> </Text>
                    </View>

                </View>

                <View style={{ backgroundColor: '#D0D0D0', textAlign: "left", padding: '1px 2px', borderBottom: '1px solid #000' }}>
                    <Text style={styles.textBold6Normal}>FINANCING DETAILS</Text>
                </View>
                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row', height: 20 }}>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}>Net Purchase Price </Text>
                        <View style={{ height: 2 }}></View>
                        <Text style={styles.textBold6Normal}> S$</Text>
                    </View>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>


                        <Text style={styles.textBold6Normal}>Deposit Paid </Text>
                        <View style={{ height: 2 }}></View>
                        <Text style={styles.textBold6Normal}> S$</Text>

                    </View>
                    <View style={{ flex: 1, padding: '1px 2px' }}>
                        <Text style={styles.textBold6Normal}>  Amount to be Finianced Interest Rate</Text>

                        <View style={{ flexDirection: 'row', width: 80, justifyContent: "space-between" }}>
                            <Text style={styles.textBold6Normal}> S$</Text>
                            <Text style={styles.textBold6Normal}> @</Text>
                        </View>

                    </View>

                </View>
                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row', height: 20 }}>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}>Monthly Instalments  </Text>
                        <View style={{ height: 2 }}></View>
                        <Text style={styles.textBold6Normal}> S$</Text>
                    </View>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>


                        <Text style={styles.textBold6Normal}>Final Instalment</Text>
                        <View style={{ height: 2 }}></View>
                        <Text style={styles.textBold6Normal}> S$</Text>

                    </View>
                    <View style={{ flex: 1, padding: '1px 2px' }}>
                        <Text style={styles.textBold6Normal}>  Financing Period</Text>

                        <View style={{ flexDirection: 'row', width: "100%", justifyContent: "flex-end" }}>

                            <Text style={styles.textBold6Normal}>{data?.tenure} mths</Text>
                        </View>

                    </View>

                </View>
                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row', height: 40 }}>
                    <View style={{ width: 100, padding: '1px 2px', }}>
                        <Text style={styles.textBold6Normal}>Application of Credit Facilities  </Text>

                    </View>
                    <View style={{ flex: 2, padding: '1px 2px', }}>


                        <Text style={styles.textBold6Normal}>I have not received any other credit facilities or loans (including but not limited ot unsecured facilities and credit cards) for the hire- purchase of the Vehicle, including for the downpayment.</Text>

                        <Text style={styles.textBold6Normal}> I have received other credit facilities or loans (including but not limited ot unsecured facilities and credit cards) for the hire-purchase of the Vehicle, including for the downpayment.</Text>
                        <Text style={styles.textBold6Normal}>Total Value: S</Text>

                    </View>


                </View>
                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row', height: 50 }}>
                    <View style={{ width: 100, padding: '1px 2px', }}>
                        <Text style={styles.textBold6Normal}>Discounts, Rebates or Benefits
                        </Text>
                        <Text style={styles.textBold6Normal}>
                            from Seller or Other Parties  </Text>

                    </View>
                    <View style={{ flex: 2, padding: '1px 2px', }}>


                        <Text style={styles.textBold6Normal}>I have not received any discounts, rebates or benefits from the vendor or another person, which has the effect of reducing the actual price of the Vehicle below the purchase price stated in the Hire Purchase Agreement.</Text>

                        <Text style={styles.textBold6Normal}> I have received the following discounts, rebates or benefits from the vendor or another person, which has the effect of reducing the actual price of the Vehicle below the purchase price stated in the Hire Purchase Agreement.</Text>
                        <View style={{ flexDirection: 'row', width: 250, height: 20, justifyContent: "space-between", alignItems: "center" }}>
                            <Text style={styles.textBold6Normal}>Benefit(s):</Text>
                            <Text style={styles.textBold6Normal}>Value: S$</Text>
                        </View>


                    </View>


                </View>
                <View style={{ backgroundColor: '#D0D0D0', textAlign: "left", padding: '1px 2px', borderBottom: '1px solid #000' }}>
                    <Text style={styles.textBold6Normal}>APPLICANT'S DECLARATION AND AUTHORISATION</Text>
                </View>
                <View style={{ flexDirection: 'row', }}>
                    <View style={{ padding: '1px 2px', }}>
                        <Text style={styles.textBold6Normal}>
                            By submitting this application for hire purchase financing, I hereby:-
                        </Text>
                        <Text style={styles.textBold6Normal}>
                            1. Represent and warrant that al information and documents provided by me are true, complete and accurate and that Ihave not withheld any material fact, information or document.
                        </Text>
                        <Text style={styles.textBold6Normal}>
                            2. Agree to provide the Company with all documents and information as the Company may require from time to time to support my application.
                        </Text>
                        <Text style={styles.textBold6Normal}>
                            3. Authorise the Company to conduct credit checks and to obtain and/or verify any information about me as the Company may deem fit.
                        </Text>

                        <Text style={styles.textBold6Normal}>
                            4.Consent to and (where relevant) procure that all relevant individuals whose personal data has been disclosed to the Company by or through me (including my authorised signatories and representatives) (collectively "Relevant Individuals") consent to, the Company, its officers, employees, agents and advisers collecting, using or disclosing such information relating to myself (and the Relevant Individuals) including details of my accounts to the following persons wherever situated (whether in Singapore or elsewhere) to the extent that the information is personal data, in connection with this application form and the purposes set out in the Company's Data Protection Policy, or as si otherwise required or permitted in accordance with applicable law-
                        </Text>

                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                            (a) any financial institution (whether acting as the Company's bank or in relation to the provision of financing in connection with the Company's products or services or otherwise);
                        </Text>
                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                            (b) the Company's head office and any of its branches, representative offices, subsidiaries, related corporations and affiliates;
                        </Text>
                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                            (c) any court, government and regulatory agency or authority such as LTA;
                        </Text>

                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                            (d) the Hire Purchase, Finance and Leasing Association of Singapore ("HPFLAS"), its successors and assigns, any body or organisation assuming the material functions of
                            HPFLAS in replacement of the same;
                        </Text>

                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >  (e)the  guarantor or any other person providing security or credit support for my obligations;</Text>
                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} > (f)any credit bureau or any other person providing security or credit support fro obligations;</Text>
                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                            (h)to the extent the  information is personl data, to the identified in the company’s data Protection Policy; and
                        </Text>

                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >(i) any other person to whom disclosure is permitted or required by law.</Text>

                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >5.  Confirm that :-</Text>
                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                            (a) there are no pending legal, arbitration, enforcements or criminal proceedings commenced against me in Singapore or any other jurisdiction;
                        </Text>
                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                            (b) there are no winding up, bankrupt, judicial management, receivership, scheme of arrangement, or equivalent proceedings against me in any jurisdiction;
                        </Text>
                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                            (c) I am not an undischarged bankrupt and there were no winding up, bankruptcy, judicial management, receivership, scheme of arrangement, or their equivalent proceedings
                            against me before in Singapore or in any jurisdiction;
                        </Text>
                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                            (d) I have never been charged or convicted of any criminal offence involving fraud or dishonesty;
                        </Text>
                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                            (e) I am not insolvent  and have not filed or passed any resolution for winding up , bankruptcy , judicial management, receivership, scheme of arrangement, or their equivalent in
                            Singapore or any other jurisdiction;
                        </Text>
                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                            (f)no sStatuary Demand under the bankruptcy Act (Cap. 20) or Companies Act (Cap. 50) has been served on me in Singapore or their equivalent in any other jurisdiction;
                        </Text>
                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                            (g) I have not stopped, suspended, threatened tos top or suspend my indebtedness, propose to make a general assignment or arrangement or composition with or for the benefit of my
                            creditors or a moratorium is agreed or declaration respect of or affected my indebtedness;
                        </Text>
                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                            (h) I am not in default of any of my loan, borrowing or financial agreements with any banks or financial institutions;
                        </Text>
                        <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                            (i) I am applying for the hire purchase financing facility for my own use and not for the benefit of any other party and will inform the company in writing immediately if this not the
                            case.
                        </Text>
                        <Text style={styles.textBold6Normal}>
                            6. Acknowledge that the Company reserves the right ot reject my application for hire purchase financing at its sole and absolute discretion without giving any reason.
                        </Text>


                    </View>



                </View>

            </View>
            <View style={{ flexDirection: 'row', height: 47 , }}>

                <View style={{ width: '60%', padding: '6px 4px 0px 4px', flexDirection: 'column' }}>
                    <Text style={styles.textBold6Normal}>Signed by the Applicant</Text>


                    <View style={{ width: '100%', height: '100%', flexDirection: 'row', alignItems: "flex-end" }}>
                        <View >
                            <Text style={styles.textBold6Normal}>___________________________</Text>
                            <Text style={styles.textBold6Normal}>Name</Text>
                        </View>
                        <View >
                            <Text style={styles.textBold6Normal}>___________________________</Text>
                            <Text style={styles.textBold6Normal}>Date</Text>
                        </View>

                    </View>
                    

                </View>
            </View>

        </Page>
        <Page size="A4" style={pStyles.page}>
            <View style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <Image src={require("../src/tambusa.jpeg")} style={{ width: 145, height: 30, border: "1px solid" }} />

                <View style={{ height: 30, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <Text style={styles.textBold8}>TEMBUSU FINANCIAL SERVICES PTE. LTD.</Text>
                    <Text style={styles.textBold8}>RC No. 201831354N GST No. 201831354N</Text>
                    <Text style={styles.textBold8}>61 Ubi Avenue 2 #06-04 Singapore 408898 | Tel: 6741 2588 | Fax: 6904 9686</Text>


                </View>

            </View>

            <View style={{ border: '1px solid #000', marginTop: 5 }}>
                <View style={{ backgroundColor: '#000', textAlign: "left", padding: '1px 2px', borderBottom: '1px solid #000' }}>
                    <Text style={styles.textBold8Clear}>PERSONAL PARTICULARS OF GUARANTOR1</Text>
                </View>



                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row' }}>
                    <View style={{ width: "33.4%", borderRight: '1px solid #000' }}>
                        <View style={{ height: 40, padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Name of Applicant (
                                <Text style={styles.textBold6Italic}>
                                Underline surname
                                </Text>
                               
                                ):</Text>
                                
                            <Text style={styles.textBold6Normal}>{data?.guarantor[0] && data?.guarantor[0].fullNameGuarantor}</Text>

                        </View>
                        {/* <View style={{ padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Length of Service</Text>
                            <View style={{ flexDirection: 'row', width: "90%", justifyContent: "flex-end" }}>
                                <View style={{ flexDirection: 'row', width: "30%", justifyContent: "space-between" }}>
                                    <Text style={styles.textBold6Normal}> yrs</Text>
                                    <Text style={styles.textBold6Normal}> mths</Text>
                                </View>

                            </View>


                        </View> */}
                    </View>

                    <View style={{ width: "33.4%", borderRight: '1px solid #000' }}>
                        <View style={{ borderBottom: "1px solid #000", height: 20, padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Relationship with Applicant</Text>


                        </View>
                        <View style={{ padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>NRIC/Passport (
                                <Text style={styles.textBold6Italic}>
                                provide copy of NRIC: Passport ACRA search
                                </Text>
                                )</Text>
                                <Text style={styles.textBold6Normal}>{data?.guarantor[0] && data?.guarantor[0].NRICGuarantor}</Text>
                        </View>
                    </View>


                    <View style={{ flex: 1, padding: '1px 2px', flexDirection: 'column' }}>


                        <Text style={styles.textBold6Normal}>Residential / Registered Office Address </Text>
                        <Text style={styles.textBold6Normal}>{data?.guarantor[0] && data?.guarantor[0].addressGuarantor}</Text>
                    </View>
                </View>
                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row', height: 25 }}>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}>Date of Birth (
                            <Text style={styles.textBold6Italic}>
                            dd mm yyyy
                                </Text>
                         
                            )</Text>
                            <Text style={styles.textBold6Normal}>{dobGuarantor}</Text>
                    </View>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>


                        <Text style={styles.textBold6Normal}>Telephone No.</Text>
                        <Text style={styles.textBold6Normal}>{data?.guarantor[0] && data?.guarantor[0].contactNumberGuarantor}</Text>

                    </View>
                    <View style={{ flex: 1, padding: '1px 2px' }}>
                        <Text style={styles.textBold6Normal}> Email Address</Text>
                        <Text style={styles.textBold6Normal}>{data?.guarantor[0] && data?.guarantor[0].emailIDGuarantor}</Text>
                    </View>

                </View>


                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row' }}>
                    <View style={{ width: "33.4%", borderRight: '1px solid #000' }}>
                        <View style={{ borderBottom: "1px solid #000", height: 30, padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Occupation</Text>
                            <Text style={styles.textBold6Normal}>{data?.guarantor[0] && data?.guarantor[0].jobTitleGuarantor}</Text>

                        </View>
                        <View style={{ padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Length of Service</Text>
                            <View style={{ flexDirection: 'row', width: "90%", justifyContent: "flex-end" }}>
                                <View style={{ flexDirection: 'row', width: "30%", justifyContent: "space-between" }}>
                                    <Text style={styles.textBold6Normal}> yrs</Text>
                                    <Text style={styles.textBold6Normal}> mths</Text>
                                </View>

                            </View>


                        </View>
                    </View>

                    <View style={{ width: "33.4%", borderRight: '1px solid #000' }}>
                        <View style={{ borderBottom: "1px solid #000", height: 30, padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Name of Employer/ Self-Employed:</Text>
                            <Text style={styles.textBold6Normal}> (
                                <Text style={styles.textBold6Italic}>
                                provide copy of salary slip, income too and CPF statement
                                </Text>
                             )</Text>
                             <Text style={styles.textBold6Normal}>{data?.guarantor[0] && data?.guarantor[0].employerGuarantor}</Text>
                        </View>
                        <View style={{ padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Monthly Income
                            </Text>
                            <View style={{ height: 3 }}></View>
                            <Text style={styles.textBold6Normal}> S${data?.guarantor[0] && data?.guarantor[0].grossMonthlySalaryGuarantor}</Text>
                        </View>
                    </View>


                    <View style={{ flex: 1, padding: '1px 2px', flexDirection: 'row' }}>


                        <View style={{ flex: 1 }}>
                            <Text style={styles.textBold6Normal}>Gender </Text>
                            <Text style={styles.textBold6Normal}>☐  Male</Text>
                            <Text style={styles.textBold6Normal}>☐  Female</Text>

                        </View>
                        <View style={{ flex: 1 }}>
                            <Text style={styles.textBold6Normal}>Nationality </Text>
                            <Text style={styles.textBold6Normal}>☐  SC</Text>
                            <Text style={styles.textBold6Normal}>☐  PR</Text>
                            <Text style={styles.textBold6Normal}>☐  Others</Text>
                        </View>

                        <View style={{ flex: 1 }}>
                            <Text style={styles.textBold6Normal}>Race </Text>
                            <Text style={styles.textBold6Normal}>☐  Malay</Text>
                            <Text style={styles.textBold6Normal}>☐  Chinese</Text>
                            <Text style={styles.textBold6Normal}>☐  Indian</Text>
                        </View>

                        <View style={{ flex: 1 }}>
                            <Text style={styles.textBold6Normal}>MaritalStatus </Text>
                            <Text style={styles.textBold6Normal}>☐  Single</Text>
                            <Text style={styles.textBold6Normal}>☐  Married</Text>
                            <Text style={styles.textBold6Normal}>☐  Others</Text>
                        </View>
                    </View>
                </View>
                <View style={{ backgroundColor: '#D0D0D0', textAlign: "left", padding: '1px 2px', borderBottom: '1px solid #000' }}>
                    <Text style={styles.textBold6Normal}>OTHER BINANCIAL COMMITMENTS</Text>
                </View>
                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row', height: 40 }}>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}>Financial Institution</Text>
                    </View>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>


                        <Text style={styles.textBold6Normal}>Type of Loan</Text>


                    </View>
                    <View style={{ flex: 1, padding: '1px 2px' }}>
                        <Text style={styles.textBold6Normal}> Monthly Repayment</Text>
                    </View>

                </View>
                <View style={{ backgroundColor: '#000', textAlign: "left", padding: '1px 2px', borderBottom: '1px solid #000' }}>
                    <Text style={styles.textBold8Clear}>PERSONAL PARTICULARS OF GUARANTOR2</Text>
                </View>



                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row' }}>
                    <View style={{ width: "33.4%", borderRight: '1px solid #000' }}>
                        <View style={{ height: 40, padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Name of Applicant (
                                <Text  style={styles.textBold6Italic}>
                                Underline surname
                                </Text>
                                 ):</Text>
                            <Text style={styles.textBold6Normal}>{data?.guarantor[1] && data?.guarantor[1].fullNameGuarantor}</Text>

                        </View>
                        {/* <View style={{ padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Length of Service</Text>
                            <View style={{ flexDirection: 'row', width: "90%", justifyContent: "flex-end" }}>
                                <View style={{ flexDirection: 'row', width: "30%", justifyContent: "space-between" }}>
                                    <Text style={styles.textBold6Normal}> yrs</Text>
                                    <Text style={styles.textBold6Normal}> mths</Text>
                                </View>

                            </View>


                        </View> */}
                    </View>

                    <View style={{ width: "33.4%", borderRight: '1px solid #000' }}>
                        <View style={{ borderBottom: "1px solid #000", height: 20, padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Relationship with Applicant</Text>


                        </View>
                        <View style={{ padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>NRIC/Passport (
                                <Text  style={styles.textBold6Italic}>
                                provide copy of NRIC: Passport ACRA search
                                </Text>
                                )</Text>
                                <Text style={styles.textBold6Normal}>{data?.guarantor[1] && data?.guarantor[1].NRICGuarantor}</Text>
                        </View>
                    </View>


                    <View style={{ flex: 1, padding: '1px 2px', flexDirection: 'row' }}>


                        <Text style={styles.textBold6Normal}>Residential / Registered Office Address </Text>
                        <Text style={styles.textBold6Normal}>{data?.guarantor[1] && data?.guarantor[1].addressGuarantor}</Text>
                    </View>
                </View>
                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row', height: 25 }}>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}>Date of Birth (
                           
                            <Text  style={styles.textBold6Italic}>
                            dd mm yyyy
                                </Text>
                            )</Text>
                            <Text style={styles.textBold6Normal}>{dobGuarantor1}</Text>
                    </View>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>


                        <Text style={styles.textBold6Normal}>Telephone No.</Text>

                        <Text style={styles.textBold6Normal}>{data?.guarantor[1] && data?.guarantor[1].contactNumberGuarantor}</Text>
                    </View>
                    <View style={{ flex: 1, padding: '1px 2px' }}>
                        <Text style={styles.textBold6Normal}> Email Address</Text>
                        <Text style={styles.textBold6Normal}>{data?.guarantor[1] && data?.guarantor[1].emailIDGuarantor}</Text>
                    </View>

                </View>


                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row' }}>
                    <View style={{ width: "33.4%", borderRight: '1px solid #000' }}>
                        <View style={{ borderBottom: "1px solid #000", height: 30, padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Occupation</Text>
                            <Text style={styles.textBold6Normal}>{data?.guarantor[1] && data?.guarantor[1].jobTitleGuarantor}</Text>

                        </View>
                        <View style={{ padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Length of Service</Text>
                            <View style={{ flexDirection: 'row', width: "90%", justifyContent: "flex-end" }}>
                                <View style={{ flexDirection: 'row', width: "30%", justifyContent: "space-between" }}>
                                    <Text style={styles.textBold6Normal}> yrs</Text>
                                    <Text style={styles.textBold6Normal}> mths</Text>
                                </View>

                            </View>


                        </View>
                    </View>

                    <View style={{ width: "33.4%", borderRight: '1px solid #000' }}>
                        <View style={{ borderBottom: "1px solid #000", height: 30, padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Name of Employer/ Self-Employed:</Text>
                            <Text style={styles.textBold6Normal}> (
                               
                                <Text  style={styles.textBold6Italic}>
                                provide copy of salary slip, income too and CPF statement
                                </Text>
                                )</Text>
                                <Text style={styles.textBold6Normal}>{data?.guarantor[1] && data?.guarantor[1].employerGuarantor}</Text>
                        </View>
                        <View style={{ padding: "1px 2px" }}>
                            <Text style={styles.textBold6Normal}>Monthly Income
                            </Text>
                            <View style={{ height: 3 }}></View>
                            <Text style={styles.textBold6Normal}> S${data?.guarantor[1] && data?.guarantor[1].grossMonthlySalaryGuarantor}</Text>
                        </View>
                    </View>


                    <View style={{ flex: 1, padding: '1px 2px', flexDirection: 'row' }}>


                        <View style={{ flex: 1 }}>
                            <Text style={styles.textBold6Normal}>Gender </Text>
                            <Text style={styles.textBold6Normal}>☐  Male</Text>
                            <Text style={styles.textBold6Normal}>☐  Female</Text>

                        </View>
                        <View style={{ flex: 1 }}>
                            <Text style={styles.textBold6Normal}>Nationality </Text>
                            <Text style={styles.textBold6Normal}>☐  SC</Text>
                            <Text style={styles.textBold6Normal}>☐  PR</Text>
                            <Text style={styles.textBold6Normal}>☐  Others</Text>
                        </View>

                        <View style={{ flex: 1 }}>
                            <Text style={styles.textBold6Normal}>Race </Text>
                            <Text style={styles.textBold6Normal}>☐  Malay</Text>
                            <Text style={styles.textBold6Normal}>☐  Chinese</Text>
                            <Text style={styles.textBold6Normal}>☐  Indian</Text>
                        </View>

                        <View style={{ flex: 1 }}>
                            <Text style={styles.textBold6Normal}>MaritalStatus </Text>
                            <Text style={styles.textBold6Normal}>☐  Single</Text>
                            <Text style={styles.textBold6Normal}>☐  Married</Text>
                            <Text style={styles.textBold6Normal}>☐  Others</Text>
                        </View>
                    </View>
                </View>
                <View style={{ backgroundColor: '#D0D0D0', textAlign: "left", padding: '1px 2px', borderBottom: '1px solid #000' }}>
                    <Text style={styles.textBold6Normal}>OTHER BINANCIAL COMMITMENTS</Text>
                </View>
                <View style={{ borderBottom: '1px solid #000', flexDirection: 'row', height: 40 }}>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}>Financial Institution</Text>
                    </View>
                    <View style={{ flex: 1, padding: '1px 2px', borderRight: '1px solid #000' }}>
                        <Text style={styles.textBold6Normal}>Type of Loan</Text>
                    </View>
                    <View style={{ flex: 1, padding: '1px 2px' }}>
                        <Text style={styles.textBold6Normal}> Monthly Repayment</Text>
                    </View>

                </View>
                <View style={{ backgroundColor: '#D0D0D0', textAlign: "left", padding: '1px 2px', borderBottom: '1px solid #000' }}>
                    <Text style={styles.textBold6Normal}>GUARANTOR'S DECLARATIONS</Text>
                </View>


                <View >
                    <Text style={styles.textBold6Normal}>I hereby:-</Text>
                      <Text style={styles.textBold6Normal}>
                        1. Represent and warrant that al information and documents provided by me are true, complete and accurate and that I have not withheld any material fact, information or document.
                    </Text>

                      <Text style={styles.textBold6Normal}>
                        2. Agree to provide the Company with all documents and information as the Company may require.
                    </Text>
                      <Text style={styles.textBold6Normal}>
                        .3 Authorise the Company to conduct credit checks and to obtain and/or verify any information about me as the Company may deem fit.
                    </Text>

                      <Text style={styles.textBold6Normal}>
                        4. Consent to and (where relevant)  procure that all relevant individuals whose personal data has bee disclosed to the  Company by or through me (including my authorised signatories
                        to myself (and the “Relevant Individuals”) consent to, the company m its officers, employees, agents and advisers collecting, using or disclosing  such information relating
                        to myself  (and the relevant Individuals) including details of my accounts to thr following persons wherever situated(whether in Singapore or elsewhere) to the extent that the
                        information si personal data, ni connection with this application form and the purposes set out ni the Company's Data Protection Policy, or as si otherwise required or permitted in accordance with applicable law-
                    </Text>
                     <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                        (a)8y mCompanishitionb eeinsalesrestateincessusttres,reincorporainsonionwhtihetCompanys'producstroservciesroohterwsie);
                    </Text>
                     <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                        (c) any court, government and regulatory agency or authority such as LTA;
                    </Text>

                     <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                        (d) the Hire Purchase, Finance and Leasing Association of Singapore ("HPFLAS"), its successors and assigns, any body or organisation assuming the material functions of HPFLAS ni replacement of the same;
                    </Text>
                     <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                        (e) any credit bureau or credit reference or evaluation agency and any member or subscriber of such credit bureau or agency, including but not limited ot the Credit Bureau
                        190heteithenoacaspersonatroeppesnihtetieamerompni DeidprotecionPortinedliability parntershpi)dnaauhtorsiedsginaotry;
                    </Text>
                     <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                        (h) any other person ot whom disclosure is permitted or required by law.
                    </Text>
                     <Text style={styles.textBold6Normal} >
                        Confirm that:-
                    </Text>
                     <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                        (a) there are no pending legal, arbitrary, enforcement of criminal proceedings, commenced against me  in Singapore or any other jurisdiction;
                    </Text>

                     <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                        (b) there are no winding up, bankruptcy, judicial management, receivership, scheme of arrangement, or their equivalent proceedings
                    </Text>

                     <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                        (c) I am not an undischarged bankrupt and these were no winding up, bankruptcy, judicial management, receivership, scheme of arrangement, or their equivalent proceedings
                        against me before in Singapore or in any jurisdiction;
                    </Text>

                     <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                        (d) I have never been charged or convicted of any criminal offence involving fraud or dishonesty;
                    </Text>

                     <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                        (e) I am not insolvent and have not filed or passed any resolution.
                    </Text>

                     <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                        (f)no Statutory Demand under the Bankruptcy Act (Cap.20) or Companies act (Cat.50) has been served on me in Singapore or their equivalent in any other jurisdiction;
                    </Text>

                     <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                        (g) I have not stopped , suspended, threatened to stop or suspend my indebtedness, propose to make a general assignment or arrangement or composition with or fofr the benefit of
                        my creditors or a moratorium is agreed or declared in respect to or affected my indebtedness;
                    </Text>

                     <Text style={{ ...styles.textBold6Normal, padding: "2px 4px" }} >
                        (h) I am not in default of and/or in breach of any of loans, borrowings or financing agreements with any banks or financinginstitutions.
                    </Text>



                </View>

            </View>
            <View style={{ flexDirection: 'row',justifyContent:"space-between", height: 110 ,  }}>

            <View style={{ flex:1,padding: '6px 4px', flexDirection: 'column' }}>
                    <Text style={styles.textBold6Normal}>Signed by the Guarantor 1</Text>


                    <View style={{ width: '100%', height: '100%', flexDirection: 'row', alignItems: "flex-end" }}>
                        <View >
                            <Text style={styles.textBold6Normal}>______________________________</Text>
                            <Text style={styles.textBold6Normal}>Name</Text>
                        </View>
                        <View >
                            <Text style={styles.textBold6Normal}>_______________________________</Text>
                            <Text style={styles.textBold6Normal}>Date</Text>
                        </View>

                    </View>
                    

                </View>
                <View style={{ flex:1,padding: '6px 4px', flexDirection: 'column' }}>
                    <Text style={styles.textBold6Normal}>Signed by the Guarantor 2</Text>


                    <View style={{ width: '100%', height: '100%', flexDirection: 'row', alignItems: "flex-end" }}>
                        <View >
                            <Text style={styles.textBold6Normal}>___________________________________</Text>
                            <Text style={styles.textBold6Normal}>Name</Text>
                        </View>
                        <View >
                            <Text style={styles.textBold6Normal}>___________________________________</Text>
                            <Text style={styles.textBold6Normal}>Date</Text>
                        </View>

                    </View>
                    

                </View>
            </View>

        </Page>
    </Document>
</>)


const TambusaPdf = React.memo(({ isSave, setPdf, data }:Props) => {

    return (
        <BlobProvider document={isSave? <Doc1 data={data} dob={moment(data?.dob,'YYYY-MM-DD').format('DD/MM/YYYY')} 
        dobGuarantor={moment(data?.guarantor[0]?.dobGuarantor,'YYYY-MM-DD').format('DD/MM/YYYY')}
        dobGuarantor1={moment(data?.guarantor[1]?.dobGuarantor,'YYYY-MM-DD').format('DD/MM/YYYY')}/>:<></>} >
            {({blob})=>{
                    let file;
                    file = blob && new File([blob as Blob], 'tembusa.pdf');
                    data && setPdf(file)

                return <></>;
            }}

        </BlobProvider>
    )
  });

export default TambusaPdf;