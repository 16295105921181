Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";
exports.httpPutMethod =  "PUT";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings";
exports.labelBodyText = "settings Body";

exports.btnExampleTitle = "CLICK ME";
exports.forgotPasswordAPiContentType = "application/json";
exports.profileForgotPasswordAPiEndPoint= "bx_block_forgot_password/otp"
exports.resetPasswordApiEndpoint = "bx_block_settings/settings/reset_old_password";
exports.forgotPasswordAPiEndPoint= "bx_block_forgot_password/otp"
// Customizable Area End