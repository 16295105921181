import React, { useEffect, useRef, useState } from "react";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";

import OrderManagementView from "./OrderManagementView.web";

// Customizable Area Start
import { runEngine } from "framework/src/RunEngine";
import { Order } from "./types";
import { getStorageData } from "../../../framework/src/Utilities";
enum dataStatus {
  pending_hard_copy_handover = 'Pending Hard copy Handover', 
  pending_submission = 'Pending submission',
  pending_approval ='Pending Approval', 
  pending_hard_copy_submission = 'Pending Hard copy submission', 
  pending_additional_docs = 'Pending additional docs (B/G)', 
  completed= 'Complete'}
// Customizable Area End

export const configJSON = require("./config");

export interface ViewProps {
  testID: string;
  // Customizable Area Start
  width: string;
  vehicleName:string;
  dealId: string;
  vehicleNumber: string;
  clientName: string;
  dealDate: string;
  dealValue: string;
  statusText: string;
  handleChangePageCustomer: any;
  handleOpenEditMenu: any;
  handleOpenEditMenu1: any;
  handleModalDelete: any;
  handleCloseEditMenu: any;
  handleCloseEditMenu1: any;
  anchorEl: any;
  anchorEl1: any;
  handleEditDeal: any;
  handleAddNewDeal: any;
  rowDeal: any;
  onChangeSearchDeal: any;

  currentPage: any;
  totalPage: any;

  listSearchVehicleName: any;
  listSearchDealId: any;
  listSearchVehicleNumber: any;
  listSearchClientName:any;
  handleSearchVehicleName: (e:any)=>void;
  handleSearchDealId: (e:any)=>void;
  handleSearchVehicleNumber: (e:any)=>void;
  handleSearchClientName: (e:any)=>void;
  handleNavigateDetail: (e:any)=>void;
  openModalDeleteDeal: boolean;
  handleDeleteDeal: ()=>void;
  toggleCalendar: ()=> void;
  closeCalendar: ()=>void;
  isOpenCalendar: boolean;
  userType: any;
  // Customizable Area End
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: string;
  // Customizable Area End
}

const subscribedMessages = [
  // Customizable Area Start
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
  // Customizable Area End
];

const OrderManagement: React.FC<Props> = (props) => {
  // Customizable Area Start
  const getSalesAndContractListId = useRef("");
    const deleteDealDetailId = useRef("");
  const getSalesAndContractListClientNameId = useRef("");
  const getSalesAndContractListDealId = useRef("");
  const getSalesAndContractListVehicleNameId = useRef("");
  const getSalesAndContractListVehicleNumberId = useRef("");

  const [vehicleName, setVehicleName] = useState('');
  const [dealId, setDealId] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [clientName, setClientName] = useState('');
  const [dealDate, setDealDate] = useState('');
  const [dealValue, setDealValue] = useState('');
  const [statusText, setStatus] = useState('');

  const [searchAttribute, setSearchAttribute] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [rowDeal, setRowDeal] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [currentAnchorEl, setCurrentAnchorEl] = useState<any>(null);

  const [listSearchVehicleName, setListSearchVehicleName] = useState([])
  const [listSearchDealId, setListSearchDealId] = useState([])
  const [listSearchVehicleNumber, setListSearchVehicleNumber] = useState([])
  const [listSearchClientName, setListSearchClientName] = useState([])
  const [openModalDeleteDeal, setOpenModalDeleteDeal] = useState(false);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [userType, setUserType] = useState();
  
  // Customizable Area End

  useEffect(() => {
    setReceiveCallback(receive);

    subscribedMessages.forEach((message) => subscribe(message));

    // Customizable Area Start
    async function getRole() {
      const userType = await getStorageData("user_type");
      setUserType(userType);
    }
    getRole()
    getSalesAndContractList(1);
    // Customizable Area End

    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, []);

  const receive = (from: string, message: Message) => {
    // Customizable Area Start
    debugLog("API Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
        extractNetworkResponse(message);
      if (responseJson) {
        switch (apiRequestCallId) {          
          case getSalesAndContractListId.current:
            setRowDeal(responseJson?.data ?? []);
            setTotalPage(Math.ceil(responseJson?.meta?.total_count/10))
            break;
          case getSalesAndContractListClientNameId.current:
            setListSearchClientName(responseJson.data.map((e:any)=>e?.attributes.customer_detail.full_name))
            break;
          case getSalesAndContractListVehicleNameId.current:
            setListSearchVehicleName(responseJson.data.map((e:any)=>e?.attributes))
            break;
          case getSalesAndContractListDealId.current:
            setListSearchDealId(responseJson.data.map((e:any)=>e?.attributes.deal_random_id))
            break;
          case getSalesAndContractListVehicleNumberId.current:
            setListSearchVehicleNumber(responseJson.data.map((e:any)=>e?.attributes.vehicle_number))
            break;
          case deleteDealDetailId.current:
            if(searchValue===''){
              getSalesAndContractList(currentPage)
            } else {
              searchSalesAndContractList(currentPage)
            }
            setOpenModalDeleteDeal(false);
            setAnchorEl(null)
            setAnchorEl1(null)
            break;
          default:
            break;
        }
      }

      if (responseJson?.errors) {
        alert(JSON.stringify(responseJson.errors));
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  useEffect(() => {
    setReceiveCallback(receive);

    subscribedMessages.forEach((message) => subscribe(message));
    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  });
  const {
    sendBlockMessage,
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    debugLog,
    unsubscribeFromMessage,
  } = useRunEngine();

  const { extractNetworkResponse, hideKeyboard, isPlatformWeb } =
    useBlockHelpers();

  const getSalesAndContractList = async(page: number)=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listOfOrdersEndPoints}?page=${page}&per_page=10`, 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    getSalesAndContractListId.current = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
 

  const handleOpenEditMenu = (event: any, data: any) =>{
    closeCalendar()
    event.stopPropagation();
    const anchorEl = event.currentTarget
    setAnchorEl(anchorEl);
    setCurrentAnchorEl(data);
  }

  const handleOpenEditMenu1 = (event: any, data: any) =>{
    closeCalendar()
    event.stopPropagation();
    const anchorEl = event.currentTarget
    setAnchorEl1(anchorEl);
    setCurrentAnchorEl(data);
  }
  const handleCloseEditMenu = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
    setCurrentAnchorEl(null);
  }
  const handleCloseEditMenu1 = (event: any) => {
    event.stopPropagation();
    setAnchorEl1(null);
    setCurrentAnchorEl(null);
  }
  const handleAddNewDeal = () =>{
    props.navigation.navigate('NewSalesAndContract');
  }

  const onChangeSearchDeal =(e:any, attribute:string)=>{
    setSearchAttribute(attribute);
    setSearchValue(e.target.value)
    setCurrentPage(1);
    switch (attribute) {
      case 'vahicle_name':
        setVehicleName(e.target.value)
        setDealId('');
        setVehicleNumber('');
        setClientName('');
        setDealDate('');
        setDealValue('');
        setStatus('');
        break;
      case 'deal_random_id':
        setVehicleName('')
        setDealId(e.target.value);
        setVehicleNumber('');
        setClientName('');
        setDealDate('');
        setDealValue('');
        setStatus('');
        break;
      case 'vehicle_number':
        setVehicleName('')
        setDealId('');
        setVehicleNumber(e.target.value);
        setClientName('');
        setDealDate('');
        setDealValue('');
        setStatus('');
        break;
      case 'client_name':
        setVehicleName('')
        setDealId('');
        setVehicleNumber('');
        setClientName(e.target.value);
        setDealDate('');
        setDealValue('');
        setStatus('');
        break;
      case 'deal_date':
        setVehicleName('')
        setDealId('');
        setVehicleNumber('');
        setClientName('');
        setDealDate(e.target.value);
        setDealValue('');
        setStatus('');
        break;
      case 'status':
        const a: keyof typeof dataStatus = e.target.value
        setVehicleName('')
        setDealId('');
        setVehicleNumber('');
        setClientName('');
        setDealDate('');
        setDealValue('');
        setStatus(dataStatus[a]);
        break;
      case 'deal_value':
        setVehicleName('')
        setDealId('');
        setVehicleNumber('');
        setClientName('');
        setDealDate('');
        setDealValue(e.target.value);
        setStatus('');
        break;
    }
  }

  const handleSearchClientName = async(e:any)=>{
    if(e.target.value === ''){
      getSalesAndContractList(1);
      setSearchAttribute('');
      setSearchValue('')
      setClientName('')
      setListSearchClientName([])
    } else {
      setClientName(e.target.value)
      const tokenValue = await getStorageData("authToken")
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": tokenValue
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header) 
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.listOfOrdersEndPoints}?filter_params=client_name&filter_value=${e.target.value}&page=1&per_page=10`, 
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.listOfOrdersMethod
      );
      getSalesAndContractListClientNameId.current = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  const handleSearchDealId = async(e:any)=>{
    if(e.target.value === ''){
      getSalesAndContractList(1);
      setSearchAttribute('');
      setSearchValue('')
      setDealId('')
      setListSearchDealId([])
    } else {
      setDealId(e.target.value)
      const tokenValue = await getStorageData("authToken")
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": tokenValue
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header) 
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.listOfOrdersEndPoints}?filter_params=deal_random_id&filter_value=${e.target.value}&page=1&per_page=10`,
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.listOfOrdersMethod
      );
      getSalesAndContractListDealId.current = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  const handleSearchVehicleName= async(e:any)=>{
    if(e.target.value === ''){
      getSalesAndContractList(1);
      setSearchAttribute('');
      setSearchValue('')
      setVehicleName('')
      setListSearchVehicleName([])
    } else {
      setVehicleName(e.target.value)
      const tokenValue = await getStorageData("authToken")
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": tokenValue
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header) 
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.listOfOrdersEndPoints}?filter_params=vahicle_name&filter_value=${e.target.value}&page=1&per_page=10`,
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.listOfOrdersMethod
      );
      getSalesAndContractListVehicleNameId.current = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  const handleSearchVehicleNumber = async(e:any)=>{
    if(e.target.value === ''){
      getSalesAndContractList(1);
      setSearchAttribute('');
      setSearchValue('')
      setVehicleNumber('')
      setListSearchVehicleNumber([])
    } else {
      setVehicleNumber(e.target.value)
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listOfOrdersEndPoints}?filter_params=vehicle_number&filter_value=${e.target.value}&page=1&per_page=10`, 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    getSalesAndContractListVehicleNumberId.current = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  useEffect(()=>{
    if(searchValue===''){
      getSalesAndContractList(1)
    } else {
      searchSalesAndContractList(1)
    }
  },[searchAttribute, searchValue])

  const searchSalesAndContractList = async(page: number)=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listOfOrdersEndPoints}?filter_params=${searchAttribute}&filter_value=${searchValue}&page=${page}&per_page=10`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    getSalesAndContractListId.current = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const handleChangePageCustomer  = (_e: any,page: number)=>{
    
    if(searchValue.length > 0){
      searchSalesAndContractList(page);
    } else {
      getSalesAndContractList(page);
    }
    setCurrentPage(page);  
  }

  const handleNavigateDetail = (row: any)=>{
    props.navigation.navigate("DealDetail", {dealId: row.id})
  }

  const handleEditDeal = (event: any, data: any)=>{
    event.stopPropagation();
    props.navigation.navigate("EditSalesAndContract", {dealId: currentAnchorEl.id})
  }
  const handleModalDelete = (event: any, data: any)=>{
    event.stopPropagation();
    setOpenModalDeleteDeal(!openModalDeleteDeal)
  }
  const handleDeleteDeal = async()=>{
        const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrderByIdEndPoint}/${currentAnchorEl?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteOrderMethod
    );
    deleteDealDetailId.current = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const toggleCalendar = ()=>{
    setIsOpenCalendar(!isOpenCalendar)
  }
  const closeCalendar = ()=>{
    setIsOpenCalendar(false)
  }
  // Customizable Area End

  const viewProps: ViewProps = {
    testID: "OrderManagementView",
    // Customizable Area Start
    width: props.width,
    vehicleName,
    vehicleNumber,
    dealId,
    dealDate,
    clientName,
    dealValue,
    statusText,    
    handleChangePageCustomer,
    handleOpenEditMenu,
    handleOpenEditMenu1,
    handleModalDelete,
    handleCloseEditMenu,
    handleCloseEditMenu1,
    anchorEl,
    anchorEl1,
    handleEditDeal,
    handleAddNewDeal,
    rowDeal : rowDeal,
    onChangeSearchDeal,
    currentPage,
    totalPage,
    listSearchClientName,
    listSearchDealId,
    listSearchVehicleName,
    listSearchVehicleNumber,
    handleSearchClientName,
    handleSearchDealId,
    handleSearchVehicleName,
    handleSearchVehicleNumber,
    handleNavigateDetail,
    openModalDeleteDeal,
    handleDeleteDeal,
    toggleCalendar,
    isOpenCalendar,
    closeCalendar,
    userType
    // Customizable Area End
  };

  return <OrderManagementView {...viewProps} />;
};

export default OrderManagement;
