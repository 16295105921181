import * as React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  CircularProgress,
  MenuItem,
  MenuProps,
  Modal,
  Typography,
  withWidth ,
  Input, InputAdornment
} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';
import SaveAlt from '@material-ui/icons/SaveAlt';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {  FileIcon } from "./assets";
import CustomStatusDealDetail from "../../../components/src/CustomStatusDealDetail";
import CustomSelectSearchField from "../../../components/src/CustomSelectSearchField";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import CloseIcon from '@material-ui/icons/Close';
import { Close } from "./assets";
import CustomStatus from "../../../components/src/CustomStatus";
import TokyoPdf from "../../../components/src/TokyoPdf";
import DBSPdf from "../../../components/src/DBSPdf";
import UOBPdf from "../../../components/src/UOBPdf";
import MoneyMaxPdf from "../../../components/src/MoneyMaxPdf";
import TambusaPdf from "../../../components/src/TambusaPdf";
import MaybankPdf from "../../../components/src/MaybankPdf";
import VmPdf from "../../../components/src/VmPdf";
import HongLeon from "../../../components/src/HongLeonPdf";
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';


// Customizable Area End

import { ViewProps } from "./DealDetail";

const DealDetailView: React.FC<ViewProps> = ({
  // Customizable Area Start
  testID,
  isExpandCustomerDetail,
  isExpandVehicleDetail,
  isExpandGuarantorDetail,
  isOpenManagerApproval,
  handleExpandCustomerDetail, 
  handleExpandVehicleDetail,
  handleExpandGuarantorDetail,
  handleOpenManagerApproval,
  handleOpenEditMenuGuarantor,
  anchorElGuarantor,
  handleCloseEditMenuGuarantor,
  dealDetail,
  handleNavigateEditGuarantor,
  handleClickGuarantor,
  openModelGuarantor,
  selectedGuarantor,
  openModelDeleteGuarantor,
  handleOpenDeleteGuarantor,
  handleDeleteGuarantor,
  handleDeleteDocument,
  handleUploadCPF,
  handleUploadNOA,
  handleUploadSupporting,
  handleCloseStatus,
  handleOpenStatus,
  anchorElStatus,
  handleUpdateStatusSaleMan,
  handleNavigateEditDeal,
  signApplication,
  signGuarantor1Application,
  signGuarantor2Application,
  handleSignDocumentRegenerate,
  data,
  isSaveUOB,
  isSaveMoneyMax,
  isSaveDBS,
  isSaveMaybank,
  isSaveTembusa,
  isSaveTokyo,
  isSaveVM,
  isSaveHongLeong,
  handleSavePdfDBS,
  handleSavePdfUOB,
  handleSavePdfMoneymax,
  handleSavePdfMaybank,
  handleSavePdfTembusa,
  handleSavePdfTokyo,
  handleSavePdfVM,
  handleSavePdfHongleon,
  handleOpenSignedDocument,
  isLoading,
  selectedBroker,
  handleManagerFormSubmit,
  visibleStatus,
  userType,
  brokerList,
  handleUpdateBroker,
  openModalSign,
  handleOpenSignModal,
  handleOpenResendEmail,
  handleResendEmail,
  openModelResendEmail,
  formIsDirty,
  checkSubmit
  // Customizable Area End
}) => {

  // Customizable Area Start
  const renderModalSign = () => {
    return <Modal open={openModalSign}>
      <ModalStyle>
        <Box className="wrapModalSign">
          <Box className="modalHeaderWrap">
            <Typography className="headerText">Car leasing Form</Typography>
            <IconButton className="iconPadding" onClick={handleOpenSignModal}>
              <CloseIcon className="icon"/>
            </IconButton>
          </Box>
          <Box className={'wrapModalContent'}>
            <Typography className="contentText">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer imperdiet elit eu ex pellentesque malesuada. Vivamus gravida sagittis tellus et sagittis. Nam vehicula aliquet lacinia. Sed ullamcorper, nulla ut accumsan efficitur, nulla turpis porttitor nibh, non elementum sapien nulla vel odio. Phasellus dictum maximus ipsum nec sollicitudin. Proin venenatis metus mi, quis scelerisque massa consectetur quis. Mauris nisi nisi, gravida ac purus in, posuere euismod ipsum. Ut magna ipsum, tincidunt vitae hendrerit et, iaculis at ex. Praesent eros justo, egestas sit amet quam at, fermentum elementum felis. Vivamus tellus tellus, aliquam quis erat ac, sodales cursus mauris. In at tincidunt magna, quis auctor dui. Etiam aliquam erat sed sagittis mollis. Donec ultricies tellus quis sapien vestibulum sagittis.
Pellentesque ac ipsum dignissim, pulvinar lacus non, vehicula ex. Ut gravida ullamcorper eros, sit amet imperdiet nisi porttitor ut. Nam ut velit erat. Aliquam faucibus massa ligula, at cursus dolor ullamcorper auctor. Vivamus lacinia sem sit amet blandit gravida. Nulla sodales eget orci a feugiat. Suspendisse egestas, libero ac ornare venenatis, metus est fringilla lectus, at fringilla odio nisl mattis odio. Nunc euismod sapien a arcu cursus laoreet. Quisque sem arcu, finibus nec velit sit amet.</Typography>

          <Box className="signContainer">
            <Typography className="contentText">Signature: </Typography>
            <Box>
              <Box className="boxSign"></Box>
              <Box className="signBorder">
                <SaveAlt/>
                <Typography className="contentText">Sign</Typography>
              </Box>
            </Box>
            
          </Box>
          
          </Box>
          <Box className="modalBottomWrap">
            <Box data-testId='btnSubmit' className="btnWrapSign" onClick={handleSignDocumentRegenerate}>
              <Typography className="btnTextSign">Start signing</Typography>
            </Box>
          </Box>
        </Box> 
      </ModalStyle>
    </Modal>
  }

  const renderModalGuarantorDetail = () => {
    return <Modal open={openModelGuarantor}>
      <ModalStyle>
        <Box className="wrapModal">
          <Box className="modalHeaderWrap">
            <Typography className="headerText">Guarantor details</Typography>
            <IconButton className="iconPadding" onClick={()=>handleClickGuarantor(null)}>
              <CloseIcon className="icon"/>
            </IconButton>
          </Box>
          <Box className={'wrapModalContentGuarantor'}>
            <Typography className="textDetail1">Name: {selectedGuarantor?.attributes.full_name}</Typography>
            <Typography className="textDetail1">DOB: {selectedGuarantor?.attributes.date_of_birth}</Typography>
            <Typography className="textDetail1">{selectedGuarantor?.attributes.address}</Typography>
            <Typography className="textDetail1">NRIC number: {selectedGuarantor?.attributes.nric_number}</Typography>
            <Typography className="textDetail1">Email ID: {selectedGuarantor?.attributes.email}</Typography>
            <Typography className="textDetail1">Contact Number: {selectedGuarantor?.attributes.contact_number}</Typography>
            <Typography className="textDetail1">Employer: {selectedGuarantor?.attributes.employer}</Typography>
            <Typography className="textDetail1">Job Title: {selectedGuarantor?.attributes.job_title}</Typography>
            <Typography className="textDetail1">Employment address: {selectedGuarantor?.attributes.employement_address}</Typography>
            <Typography className="textDetail1">Gross Monthly Salary: ${selectedGuarantor?.attributes.gross_monthly_salary}</Typography>
            {<Box className="wrapDocument">
              <Typography className="textTitle">Supporting documents</Typography>
              <Box className={'wrapDocumentList'}>
                {selectedGuarantor?.attributes.cpf_contribution_for_last_six_month && <Box className="wrapDocumentItem">
                  <Typography className="textTitleDoc">CPF contribution for last 6 months</Typography>
                  <Box className="documentUploadedGuarantor">
                    <Box className="uploadIconBoxGuarantor">
                      <img src={FileIcon} />
                    </Box>
                    <Box className="wrapTextUploadGuarantor">
                      <Typography className="textUpload1">{selectedGuarantor?.attributes.cpf_contribution_for_last_six_month.name}</Typography>
                    </Box> 
                  </Box>
                </Box>}
                {selectedGuarantor?.attributes.noa_for_last_two_year && <Box className="wrapDocumentItem">
                  <Typography className="textTitleDoc">NOA for last 2 years</Typography>
                  <Box className="documentUploadedGuarantor">
                    <Box className="uploadIconBoxGuarantor">
                      <img src={FileIcon} />
                    </Box>
                    <Box className="wrapTextUploadGuarantor">
                      <Typography className="textUpload1">{selectedGuarantor?.attributes.noa_for_last_two_year.name}</Typography>
                    </Box> 
                  </Box>
                </Box>}
                {selectedGuarantor?.attributes.supporting_docs && <Box className="wrapDocumentItem">
                  <Typography className="textTitleDoc">Upload supporting Docs</Typography>
                  <Box className="documentUploadedGuarantor">
                    <Box className="uploadIconBoxGuarantor">
                      <img src={FileIcon} />
                    </Box>
                    <Box className="wrapTextUploadGuarantor">
                      <Typography className="textUpload1">{selectedGuarantor?.attributes.supporting_docs.name}</Typography>
                    </Box> 
                  </Box>
                </Box>}
              </Box>
            </Box>}
          </Box>
          
        </Box> 
      </ModalStyle>
    </Modal>
  }

  const renderModalGuarantor = () => {
    return <Modal open={openModelDeleteGuarantor}>
      <ModalStyle>
        <Box className="wrapModalGuarantor">
          <Box className="modalHeaderWrapDelete">
            <Typography className="modalHeaderText">Delete Guarantor</Typography>
            <IconButton onClick={()=>handleOpenDeleteGuarantor(null)} className="iconPadding">
              <img src={Close}/>
            </IconButton>
          </Box>
          <Box className="line"/> 
          <Box className="wrapModalContentDelete">
            <Typography className="deleteMsg">Are you sure you want to delete this Guarantor </Typography>
          </Box>
          <Box className="line"/> 
          <Box className="modalFooterWrap">
            <Button data-testId='cancelBtn' className="cancelBtn" onClick={()=>handleOpenDeleteGuarantor(null)}>Cancel</Button>
            <Button data-testId='saveBtn' className="saveBtn" onClick={handleDeleteGuarantor}>Delete</Button>
          </Box> 
        </Box> 
      </ModalStyle>
    </Modal>
  }

  const renderModalResendEmail = () => {
    return <Modal open={openModelResendEmail}>
      <ModalStyle>
        <Box className="wrapModalGuarantor">
          <Box className="modalHeaderWrapDelete">
            <Typography className="modalHeaderText">Failed to send Email</Typography>
            <IconButton onClick={()=>handleOpenResendEmail(null)} className="iconPadding">
              <img src={Close}/>
            </IconButton>
          </Box>
          <Box className="line"/> 
          <Box className="wrapModalContentDelete">
            <Typography className="contentMsg">Failed to send the email to {selectedBroker}, please verify the email address with admin or retry. </Typography>
          </Box>
          <Box className="line"/> 
          <Box className="modalFooterWrap">
            <Button data-testId='cancelBtn' className="cancelBtn" onClick={()=>handleOpenResendEmail(null)}>Cancel</Button>
            <Button data-testId='saveBtn' className="saveBtn" onClick={handleResendEmail}>Resend Email</Button>
          </Box> 
        </Box> 
      </ModalStyle>
    </Modal>
  }

  const renderChangePrice = () => {
    return <Modal open={false}>
      <ModalStyle>
        <Box className="wrapModalGuarantor">
          <Box className="modalHeaderWrapDelete">
            <Typography className="modalHeaderText">The price of the catalogue has been changed</Typography>
            <IconButton onClick={()=>{}} className="iconPadding">
              <img src={Close}/>
            </IconButton>
          </Box>
          <Box className="line"/> 
          <Box className="wrapModalContentDelete">
            <Typography className="deleteMsg">Do you want to sync the updated price to the deal?</Typography>
          </Box>
          <Box className="line"/> 
          <Box className="modalFooterWrap">
            <Button data-testId='cancalBtn' className="cancelBtn" onClick={()=>{}}>Cancel</Button>
            <Button data-testId='saveBtn' className="saveBtn" onClick={()=>{}}>Sync</Button>
          </Box> 
        </Box> 
      </ModalStyle>
    </Modal>
  }

  const renderLoading = () => {
    return <Modal open={isLoading}>
      <ModalStyle>
        <Box className="wrapModalLoading">
          <Box className="wrapModalContentLoading">
            <CircularProgress className="iconLoading" size={20} />
            <Typography className="textLoading">Please wait while we are updating the deal</Typography>
          </Box>
         
        </Box> 
      </ModalStyle>
    </Modal>
  }
  const validationSchema = Yup.object().shape({
    Margin:Yup.number()
    .typeError('Must be a number')
    .test('is-decimal', 'Must be a number with up to two decimal places', value => 
      (value === undefined || value === null) ? true : /^\d+(\.\d{1,2})?$/.test(value)
    )
    .required('This field is required').max(9999999999.99, 'Fee cannot be more than 9999999999.99'),
    LoanComms: Yup.number()
    .typeError('Must be a number')
    .test('is-decimal', 'Must be a number with up to two decimal places', value => 
      (value === undefined || value === null) ? true : /^\d+(\.\d{1,2})?$/.test(value)
    )
    .required('This field is required').max(9999999999.99, 'This field cannot be more than 9999999999.99'),
    AdminFee: Yup.number()
    .typeError('Must be a number')
    .test('is-decimal', 'Must be a number with up to two decimal places', value => 
      (value === undefined || value === null) ? true : /^\d+(\.\d{1,2})?$/.test(value)
    )
    .required('This field is required').max(9999999999.99, 'This field cannot be more than 9999999999.99'),
    InsuranceComms: Yup.number()
    .typeError('Must be a number')
    .test('is-decimal', 'Must be a number with up to two decimal places', value => 
      (value === undefined || value === null) ? true : /^\d+(\.\d{1,2})?$/.test(value)
    )
    .required('This field is required').max(9999999999.99, 'This field cannot be more than 9999999999.99'),
    RepairFee: Yup.number()
    .typeError('Must be a number')
    .test('is-decimal', 'Must be a number with up to two decimal places', value => 
      (value === undefined || value === null) ? true : /^\d+(\.\d{1,2})?$/.test(value)
    )
    .required('This field is required').max(9999999999.99, 'This field cannot be more than 9999999999.99'),
    FinanceAdminFee: Yup.number()
    .typeError('Must be a number')
    .test('is-decimal', 'Must be a number with up to two decimal places', value => 
      (value === undefined || value === null) ? true : /^\d+(\.\d{1,2})?$/.test(value)
    )
    .required('This field is required').max(9999999999.99, 'This field cannot be more than 9999999999.99'),
    MiscellaneousFees: Yup.array().of(
      Yup.object().shape({
        fee: Yup.number()
          .typeError('This field must be a number')
          .test('is-decimal', 'This field must be a number with up to two decimal places', value => 
            (value === undefined || value === null) ? true : /^\d+(\.\d{1,2})?$/.test(value)
          ).max(9999999999.99, 'This field cannot be more than 9999999999.99')
      })
    )
  });

  const renderManagerApproval = () => {
    const initialValues : any = {
      Margin: 0,
      LoanComms:0,
      AdminFee: 0,
      InsuranceComms: 0 ,
      RepairFee: 0,
      FinanceAdminFee: 0,
      MiscellaneousFees: []
    };
    return <Modal open={isOpenManagerApproval}>
    <ModalStyle>
    <Box className="mainBox">
          <Formik
            data-test-id="formik"
            validationSchema={validationSchema}
            validateOnChange={formIsDirty}
              validateOnBlur={formIsDirty}
            initialValues={initialValues}
            onSubmit={(values) => {
              handleManagerFormSubmit(values)
            }}
          >
            {({ errors, setFieldValue, handleSubmit, values }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Box className="modalMainWrap">
                  <Typography className="modalTitleText">Please add following details</Typography>
                  <IconButton 
                  onClick={handleOpenManagerApproval}>
                    <CloseIcon className="icon" />
                  </IconButton>
                </Box>
              <Box className="lineModal" />
                <Box  className="modalContenerWrap">
                  <Box className="modalInputBox">
                    {['Margin', 'LoanComms', 'AdminFee', 'InsuranceComms', 'RepairFee', 'FinanceAdminFee'].map((field:any, index:any) => (
                      <Box key={field} marginBottom={2}>
                        <Typography className="titleText">{field.replace(/([A-Z])/g, ' $1')}</Typography>
                        <Input
                          data-test-id={`${field}ID`}
                          className="inputField"
                          placeholder={`Enter ${field}`}
                          disableUnderline
                          value={values[field]}
                          onChange={(event) => setFieldValue(field, event.target.value)}
                          fullWidth
                        />
                        
                        {errors[field] && <Typography className="errorsMsg">{errors[field]}</Typography>}
                      </Box>
                      
                    ))}

                  </Box>
                  <Box className="lineModal" />
                  <FieldArray 
                      name="MiscellaneousFees"
                      render={arrayHelpers => (
                        <Box>
                          {values.MiscellaneousFees.map((fee:any, index : any) => (
                            <Box key={index} display="flex" alignItems="center" marginTop={3} marginBottom={2}>
                              <Box flex={1} marginRight={2}>
                                <Typography className="titleText">Miscellaneous Fee {index + 1}</Typography>
                                <Input
                                  className="inputField"
                                  placeholder="Enter fee"
                                  disableUnderline
                                  value={fee.fee}
                                  onChange={(event) => setFieldValue(`MiscellaneousFees.${index}.fee`, event.target.value)}
                                  fullWidth
                                />
                                {errors.MiscellaneousFees && (errors.MiscellaneousFees as any)[index] && (errors.MiscellaneousFees as any)[index].fee && <Typography className="errorsMsg">{(errors.MiscellaneousFees as any)[index].fee}</Typography>}
                              </Box>
                              <Box flex={1} marginRight={2}>
                                <Typography className="titleText">Description</Typography>
                                <Input
                                  className="inputField"
                                  placeholder="Enter description"
                                  disableUnderline
                                  value={fee.description}
                                  onChange={(event: any) => setFieldValue(`MiscellaneousFees.${index}.description`, event.target.value)}
                                  fullWidth
                                />
                              </Box>
                              <IconButton onClick={() => arrayHelpers.remove(index)}>
                                <Box sx = {{alignItems :'center'}} className="deletebtn"> 
                                <DeleteIcon  style={{color: '#CEAE71' , marginTop : '10px'}}/>
                                </Box>
                              </IconButton>
                            </Box>
                          ))}
                          {(
                            <Button style={{marginTop: "40px"}} className = "btnAddMisc" onClick={() => arrayHelpers.push({ fee: '', description: '' })}>
                              <AddCircleOutlineIcon style={{color: '#CEAE71', marginRight: '5px'}}/>
                              <Typography className="btntextAddmisc"> Add Miscellaneous Fee</Typography>
                            </Button>
                          )}
                        </Box>
                      )}
                    />
                </Box>
                <Box className="lineModal" />
                <Box className="modalBtnWrap">
                  <Button data-testid='modalCancelBtn' onClick={handleOpenManagerApproval} className="modalCancelBtn">Cancel</Button>
                  <Button data-testid='modalAddBtn' type="submit" className="modalAddBtn" onClick={checkSubmit}>Save</Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </ModalStyle>
  </Modal>
  }
  
  // Customizable Area End
  
  return (
    
    // Customizable Area Start
    <>
      {data && <DBSPdf isSave={isSaveDBS} setPdf={handleSavePdfDBS} data={data}/>}
      {data && <UOBPdf isSave={isSaveUOB} setPdf={handleSavePdfUOB} data={data}/>}
      {data && <MoneyMaxPdf isSave={isSaveMoneyMax} setPdf={handleSavePdfMoneymax} data={data}/>}
      {data && <TokyoPdf isSave={isSaveTokyo} setPdf={handleSavePdfTokyo} data={data}/>}
      {data && <TambusaPdf isSave={isSaveTembusa} setPdf={handleSavePdfTembusa} data={data}/>}
      {data && <MaybankPdf isSave={isSaveMaybank} setPdf={handleSavePdfMaybank} data={data}/>}
      {data && <VmPdf isSave={isSaveVM} setPdf={handleSavePdfVM} data={data}/>}
      {data && <HongLeon isSave={isSaveHongLeong} setPdf={handleSavePdfHongleon} data={data}/>}
      {renderModalSign()}
      {renderModalGuarantorDetail()}
      {renderModalGuarantor()}
      {renderLoading()}
      {renderChangePrice()}
      {renderManagerApproval()}
      {renderModalResendEmail()}
      <DealDetailStyle data-testid={testID}>
        <Box className="container">
          <Grid container className="wrapContent">
            <Grid item container className="wrapHeader">
            <Grid item xs={12} md sm>
              <Box className="wrapId">
                <Typography className="textId">Deal ID: {dealDetail?.id}</Typography>
                {!(dealDetail?.attributes.status === "pending_hard_copy_handover" || dealDetail?.attributes.status === "completed") && <Box data-testId="editDeal" className="btnWrap2" onClick={handleNavigateEditDeal}>
                  <EditIcon className="iconGold"/>
                  <Typography className="btnTextEdit">Edit</Typography>
                </Box>}
              </Box>
            </Grid>
            <Grid item xs={12} sm md >
              <Box className='wrapStatus'>
                <Typography className="labelText">Status</Typography>
                <Box aria-haspopup="true" data-testId='editButton' onClick={(e)=>handleOpenStatus(e)}>
                  <CustomStatusDealDetail status={dealDetail?.attributes.status} />
                </Box>
                <StyledMenu
                  data-testId="simple-menu"
                  anchorEl={anchorElStatus}
                  keepMounted
                  open={Boolean(anchorElStatus)}
                  onClose={handleCloseStatus}
                >
                  {visibleStatus.map((e: any)=> <MenuItem onClick={()=>{handleUpdateStatusSaleMan(e)}} data-testId="EditMenuID">
                    <CustomStatus status={e}/>
                  </MenuItem>)}

                </StyledMenu>
              </Box>
            </Grid>
            </Grid>
            { (userType === "sales_manager") ? <>
            <Grid item md={12} xs={12} className="line"/>  
             <Grid item md={12} xs={12}>
              <Typography className="selectedBankLabel">Broker</Typography>
              <Box className='wrapBroker'>

                <Typography className="labelText">Select the Broker</Typography>
                <CustomSelectSearchField 
                  value={selectedBroker} 
                  listSearchCustomer={brokerList} placeholder={'Select'} 
                  isBroker
                  onSelect={handleUpdateBroker}/>
              </Box>
            </Grid>  
               </>: null 
            } 
            <Grid item md={12} xs={12} className="line"/> 
            <Grid item md={12} className="wrapSign">
              {dealDetail?.attributes.bank_attachments.map((args: any, index: number)=>  <Box className="wrapSignItem">
                <Typography className="labelText">Loan application ({args?.name})</Typography>
                
                {(args.is_signed === false || args.is_signed === null) && <Box className="wrapSignBtn">
                  <Box data-testId='signDoc' className="btnWrap" onClick={()=> signApplication(args, index)}>
                    <Typography className="btnText">Sign Application</Typography>
                  </Box>
                  <Box className="btnWrap2">
                    <SaveAlt className="iconGold" onClick={()=>window.open(args?.url)}/>
                  </Box>
                </Box>}
                {(args.is_signed_guarantor_one === false || args.is_signed_guarantor_one === null) && args.is_signed === true && dealDetail?.attributes.guarantor_details.data.length !== 0 && 
                <Box className="wrapSignBtn">
                  <Box data-testId='signDoc' className="btnWrap" onClick={()=> signGuarantor1Application(args, index)}>
                    <Typography className="btnText">Guarantor 1 Sign Application</Typography>
                  </Box>
                  <Box className="btnWrap2">
                    <SaveAlt className="iconGold" onClick={()=>handleOpenSignedDocument(args)}/>
                  </Box>
                </Box>}
                {(args.is_signed_guarantor_two === false || args.is_signed_guarantor_one === null) && args.is_signed_guarantor_one === true && dealDetail?.attributes.guarantor_details.data.length === 2 && 
                <Box className="wrapSignBtn">
                  <Box data-testId='signDoc' className="btnWrap" onClick={()=> signGuarantor2Application(args, index)}>
                    <Typography className="btnText">Guarantor 2 Sign Application</Typography>
                  </Box>
                  <Box className="btnWrap2">
                    <SaveAlt className="iconGold" onClick={()=>handleOpenSignedDocument(args)}/>
                  </Box>
                </Box>}
                {args.is_signed && dealDetail?.attributes.guarantor_details.data.length === 0 &&
                  <Box className="wrapSignBtn">
                    <Typography className="signedText">Application Signed</Typography>
                  <Box data-testId='downSign' className="btnWrap2" onClick={()=>handleOpenSignedDocument(args)}>
                    <SaveAlt className="iconGold" />
                  </Box>
                </Box>
                }
                 {args.is_signed && args.is_signed_guarantor_one  && dealDetail?.attributes.guarantor_details.data.length === 1 &&
                  <Box className="wrapSignBtn">
                    <Typography className="signedText">Application Signed</Typography>
                  <Box data-testId='downSign' className="btnWrap2" onClick={()=>handleOpenSignedDocument(args)}>
                    <SaveAlt className="iconGold" />
                  </Box>
                </Box>
                }
                 {args.is_signed && args.is_signed_guarantor_one && args.is_signed_guarantor_two &&
                  <Box className="wrapSignBtn">
                    <Typography className="signedText">Application Signed</Typography>
                  <Box data-testId='downSign' className="btnWrap2" onClick={()=>handleOpenSignedDocument(args)}>
                    <SaveAlt className="iconGold" />
                  </Box>
                </Box>
                }
              </Box>)}
                          
            </Grid>
            <Grid item md={12} xs={12} className="line"/>
            <Grid container className="wrapHeaderCustomerDetail">
              <Typography className="selectedBankLabel">Upload supporting documents</Typography>
              <Box className="wrapDocuments">
                <Box className="wrapDocument">
                  <Typography className="documentTitle">CPF contribution for last 6 months</Typography>
                  {dealDetail?.attributes.cpf_contribution_for_last_six_month === null ? <>
                            <input
                              data-testId='CPFupload'
                              id="CPFupload"
                              type={!(dealDetail?.attributes.status === "pending_hard_copy_handover" || dealDetail?.attributes.status === "completed") ?"file": undefined}
                              style={{ display: 'none' }}
                              onChange={handleUploadCPF}/>
                            <label htmlFor="CPFupload">
                              <Box className="documentUpload">
                                <AddIcon className="addIcon"/>
                                <Typography className='textUpload'>Upload</Typography>
                              </Box> 
                            </label>
                          </>  :  
                  <Box className={'documentUploaded'}>
                    <Box className="uploadIconBox">
                      <img src={FileIcon} />
                    </Box>
                    <Box className="wrapTextUpload">
                      <Typography className={'textUpload1'}>{dealDetail?.attributes.cpf_contribution_for_last_six_month.name}</Typography>
                    </Box>
                    {!(dealDetail?.attributes.status === "pending_hard_copy_handover" || dealDetail?.attributes.status === "completed") && <HighlightOffRoundedIcon data-testid="deleteCPF" className="iconBank" onClick={()=>handleDeleteDocument('cpf_contribution_for_last_six_month')}/>}
                  </Box>  }            
                </Box>
                <Box className="wrapDocument">
                  <Typography className="documentTitle">NOA for last 2 years</Typography>
                  {dealDetail?.attributes.noa_for_last_two_year === null ?  <><input
                        data-testId='NOAfile'
                        id="NOAfile"
                        type={!(dealDetail?.attributes.status === "pending_hard_copy_handover" || dealDetail?.attributes.status === "completed") ?"file": undefined}
                        style={{ display: 'none' }}
                        onChange={handleUploadNOA}/>
                      <label htmlFor="NOAfile"><Box className="documentUpload">
                            <AddIcon className="addIcon"/>
                            <Typography className='textUpload'>Upload</Typography>
                          </Box></label></>  :
                  <Box className={'documentUploaded'}>
                    <Box className="uploadIconBox">
                      <img src={FileIcon} />
                    </Box>
                    <Box className="wrapTextUpload">
                      <Typography className={'textUpload1'}>{dealDetail?.attributes.noa_for_last_two_year.name}</Typography>
                    </Box>
                    {!(dealDetail?.attributes.status === "pending_hard_copy_handover" || dealDetail?.attributes.status === "completed") && <HighlightOffRoundedIcon data-testid="deleteNOA" className="iconBank" onClick={()=>handleDeleteDocument('noa_for_last_two_year')}/>} 
                  </Box>}         
                </Box>
                <Box className="wrapDocument">
                  <Typography className="documentTitle">Upload supporting Docs</Typography>
                  {dealDetail?.attributes.supporting_docs === null ? <><input
                        data-testId='supportFile'
                        id="supportFile"
                        type={!(dealDetail?.attributes.status === "pending_hard_copy_handover" || dealDetail?.attributes.status === "completed") ?"file": undefined}
                        style={{ display: 'none' }}
                        onChange={handleUploadSupporting}/>
                      <label htmlFor="supportFile"><Box className="documentUpload">
                            <AddIcon className="addIcon"/>
                            <Typography className='textUpload'>Upload</Typography>
                          </Box></label></>  :    
                  <Box className={'documentUploaded'}>
                    <Box className="uploadIconBox">
                      <img src={FileIcon} />
                    </Box>
                    <Box className="wrapTextUpload">
                      <Typography className={'textUpload1'}>{dealDetail?.attributes.supporting_docs.name}</Typography>
                    </Box>
                    {!(dealDetail?.attributes.status === "pending_hard_copy_handover" || dealDetail?.attributes.status === "completed") && <HighlightOffRoundedIcon data-testid="deleteSupporting" className="iconBank" onClick={()=>handleDeleteDocument('supporting_docs')}/>} 
                  </Box>}         
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} xs={12} className="line"/>
            <Grid item md={12} xs={12}>
              <Typography className="selectedBankLabel">Loan applying bank</Typography>
              
                <Box className='wrapBank'>
                {dealDetail?.attributes.bank_attachments.map((args: any)=><Box className="bankItem">
                  <Typography className="textBank">{args?.name}</Typography>
                </Box>)}
              </Box>
            </Grid>
            <Grid item md={12} xs={12} className="line"/>
            <Grid item md={12} xs={12}>
              <Box className="wrapExpand" data-testId="expandCustomerDetail" onClick={handleExpandCustomerDetail}>
                <Typography className="selectedBankLabel">Customer details</Typography>
                {isExpandCustomerDetail ? <ExpandLessIcon className="icon"/>: <ExpandMoreIcon className="icon"/>}
              </Box>
              {isExpandCustomerDetail && <Box className="wrapContentExpand">
                <Typography className="textDetail1">Name: {dealDetail?.attributes.customer_detail.full_name ?? ""}</Typography>
                <Typography className="textDetail1">DOB: {dealDetail?.attributes.customer_detail.date_of_birth ?? ""}</Typography>
                <Typography className="textDetail1">Address: {dealDetail?.attributes.customer_detail.address}</Typography>
                <Typography className="textDetail1">NRIC number: {dealDetail?.attributes.customer_detail.nric_number}</Typography>
                <Typography className="textDetail1">Email ID: {dealDetail?.attributes.customer_detail.email}</Typography>
                <Typography className="textDetail1">Contact Number: {dealDetail?.attributes.customer_detail.contact_number}</Typography>
                <Typography className="textDetail1">Employer: {dealDetail?.attributes.customer_detail.employer}</Typography>
                <Typography className="textDetail1">Job Title: {dealDetail?.attributes.customer_detail.job_title}</Typography>
                <Typography className="textDetail1">Employment address: {dealDetail?.attributes.customer_detail.employement_address}</Typography>
                <Typography className="textDetail1">Gross Monthly Salary: ${dealDetail?.attributes.customer_detail.gross_monthly_salary}</Typography>
              </Box>}
            </Grid>
            <Grid item md={12} xs={12} className="line"/>
            <Grid item md={12} xs={12}>
              <Box className="wrapExpand" data-testId="expandVehicleDetail" onClick={handleExpandVehicleDetail}>
                <Typography className="selectedBankLabel">Vehicle details</Typography>
                {isExpandVehicleDetail ? <ExpandLessIcon className="icon"/>: <ExpandMoreIcon className="icon"/>}
              </Box>
              {isExpandVehicleDetail && <Box className="wrapContentExpand">
                <Typography className="selectedBankLabel"></Typography>
                <Typography className="textDetail1">Vehicle:<Typography className="textVehicle"><span>&nbsp;</span>{dealDetail?.attributes.vahicle_name}</Typography></Typography>
                <Typography className="textDetail1">Price:<Typography className="textVehicle"><span>&nbsp;</span>${dealDetail?.attributes.vehicle_price}</Typography></Typography>
                <Typography className="textDetail1">Vehicle ID:<Typography className="textVehicle"><span>&nbsp;</span>{dealDetail?.attributes.vehicle_id}</Typography></Typography>
                <Typography className="textDetail1">Vehicle number:<Typography className="textVehicle"><span>&nbsp;</span>{dealDetail?.attributes.vehicle_number}</Typography></Typography>
                <Typography className="textDetail1">Year:<Typography className="textVehicle"><span>&nbsp;</span>{dealDetail?.attributes.vehicle_manufactured_year}</Typography></Typography>
                <Typography className="textDetail1">Vehicle make:<Typography className="textVehicle"><span>&nbsp;</span>{dealDetail?.attributes.vehicle_make}</Typography></Typography>
                <Typography className="textDetail1">Vehicle model:<Typography className="textVehicle"><span>&nbsp;</span>{dealDetail?.attributes.vehicle_model}</Typography></Typography>
                <Typography className="textDetail1">Engine number:<Typography className="textVehicle"><span>&nbsp;</span>{dealDetail?.attributes.engine_number}</Typography></Typography>
                <Typography className="textDetail1">Chassis number:<Typography className="textVehicle"><span>&nbsp;</span>{dealDetail?.attributes.chassis_number}</Typography></Typography>
                <Typography className="textDetail1">Registration Date:<Typography className="textVehicle"><span>&nbsp;</span>{dealDetail?.attributes.chassis_number}</Typography></Typography>         
              </Box>}     
            </Grid>
            <Grid item md={12} xs={12} className="line"/>
            <Grid item md={12} xs={12}>
              <Box className="wrapExpand" data-testId="expandGuarantorDetail" onClick={handleExpandGuarantorDetail}>
                <Typography className="selectedBankLabel">Guarantor details</Typography>
                {isExpandGuarantorDetail ? <ExpandLessIcon className="icon"/>: <ExpandMoreIcon className="icon"/>}
              </Box>

              {isExpandGuarantorDetail && <Box className="wrapContentExpand">
                {dealDetail?.attributes.guarantor_details.data.map((item: any, index: any)=>
                <Box className="wrapItem">
                  <Box className="groupText" data-testid={`guarantorItem-${index}`} onClick={()=>handleClickGuarantor(item)}>
                    <Typography className='itemText'>Guarantor {index+1} : {item.attributes.full_name}</Typography>
                  </Box>
                  {!(dealDetail?.attributes.status === "pending_hard_copy_handover" || dealDetail?.attributes.status === "completed") &&<Box data-testId={`editIcon-${index}`} onClick={(args)=>handleOpenEditMenuGuarantor(args, item)}><MoreVertIcon className='icon' /></Box>}
                   <StyledMenuGuarantor
                    id="simple-menu"
                    anchorEl={anchorElGuarantor}
                    keepMounted
                    open={Boolean(anchorElGuarantor)}
                    onClose={handleCloseEditMenuGuarantor}
                  >
                    <MenuItem data-testid={`editBtn-${index}`} onClick={(event: any)=>handleNavigateEditGuarantor(event, item)}>
                      <ListItemIcon>
                        <EditIcon style={{color:"#F3F3F3"}}/>
                      </ListItemIcon>
                      <ListItemText primary="Edit" style={{color: '#F3F3F3', fontSize: 12, fontFamily: 'Outfit', fontWeight: 400,}}/>
                    </MenuItem>
                   <MenuItem data-testid={`deleteBtn-${index}`} onClick={()=>handleOpenDeleteGuarantor(item)}>
                      <ListItemIcon>
                        <DeleteIcon style={{color:"#F3F3F3"}}/>
                      </ListItemIcon>
                      <ListItemText primary="Delete" style={{color: '#F3F3F3', fontSize: 12, fontFamily: 'Outfit', fontWeight: 400,}}/>
                    </MenuItem>
                  </StyledMenuGuarantor>
                </Box>
                )}
              </Box>
            }
            </Grid>
          </Grid>
        </Box>
      </DealDetailStyle>
    </>
    // Customizable Area End
  );
};

// Customizable Area Start
const StyledMenu = withStyles({
  paper: {
    borderRadius: '8px',
    backgroundColor: '#3F3E3A',
    '& .MuiMenuItem-root': {
      justifyContent:'center'
    },
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const ModalStyle = styled(Box)(({ theme }) => ({
  '& .modalHeaderText': {
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize:"24px",
    fontWeight: 700,
  },
  '& .textLoading': {
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize:"16px",
    fontWeight: 700,
    marginTop:24
  },
  '& .line': {
    height:"1px", 
    backgroundColor:(theme as any).typography.line,
    marginTop:16,
    marginBottom:16,
  },
  '& .saveBtn': {
    marginLeft: "16px",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    borderRadius: "8px",
    color: "#171717",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
    height: "56px"
  },
  "& .textUpload1":{
    color: (theme as any).typography.h1, 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '400',
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis',
    width:100
  },
  '& .cancelBtn': {
    backgroundColor:(theme as any).typography.surface2, 
    borderRadius: "8px",
    color: "#CEAE71",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
    height: "56px"
  },
  '& .modalFooterWrap': {
    display: "flex",
    justifyContent: "flex-end",
    padding: "24px"   
  },
  "& .deleteMsg":{
    color: '#F87171', 
    fontSize: 16, 
    fontFamily: 'Outfit', 
    fontWeight: '400',
  },
  "& .contentMsg":{
    color: (theme as any).typography.h1, 
    fontSize: 16, 
    fontFamily: 'Outfit', 
    fontWeight: '400',
  },
  "& .documentUploadedGuarantor":{
    borderRadius: 8,
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-between',
    width:230,
    height:72,
    backgroundColor:(theme as any).typography.surface2, 
    padding:'0 8px',
    marginTop:8
  },
  "& .uploadIconBoxGuarantor":{
    display: "flex",
    padding: "var(--border-radius, 16px)",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "8px", 
    background: "#FFEFBD",
  },
  "& .wrapTextUpload":{
    width:110,
  },
  '& .textDetail1': {
    color: (theme as any).typography.h1, 
    fontSize: '16px', 
    fontFamily: 'Inter', 
    fontWeight: '400',
    display:'inline',
    flexDirection:'row',
    marginBottom:8
  },
  '& .wrapDocument': {
    paddingTop:'16px'
  },
  '& .wrapDocumentList': {
    display:'flex',
    flexDirection:'row',
    marginTop:8,
    '@media (max-width: 600px)': {
      display:'flex',
      flexDirection:'column',
      marginTop:8
    },
  },
  '& .wrapDocumentItem': {
    flexDirection:'column',
    marginRight: 40,
  },
  '& .textTitle': {
    color: (theme as any).typography.h1, 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
    display:'flex',
  },
  '& .textTitleDoc': {
    color: (theme as any).typography.h1, 
    fontSize: '14px', 
    fontFamily: 'Outfit', 
    fontWeight: '400',
    display:'flex',
  },
  '& .btnTextSign': {
    textTransform: 'none',
    color: '#171717', 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },  
  '& .modalHeaderWrap': {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
    alignItems:'center',
    padding: "24px",
    backgroundColor:(theme as any).typography.surface2,
    borderTopLeftRadius:16,
    borderTopRightRadius:16
  },
  '& .modalHeaderWrapDelete': {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
    alignItems:'center',
    padding: "24px",
    backgroundColor:(theme as any).typography.body2,
    borderTopLeftRadius:16,
    borderTopRightRadius:16
  },
  '& .modalBottomWrap': {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'flex-end',
    alignItems:'center',
    padding: "24px",
  },
  "& .btnWrapSign":{
    width: 'fit-content',
    cursor: 'pointer',
    padding: "16px", 
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',    
  },
  "& .icon":{
    color: (theme as any).typography.h1, 
  },
  "& .contentText":{
    color: '#171717',
    fontSize: 16,
    fontFamily: 'Outfit', 
    fontWeight: '400',
  },
  "& .boxSign":{
    borderBottom: `1px solid #171717`,
    width:150,
    height:20
  },
  "& .signBorder":{
    justifyContent:'center', 
    alignItems:'center', 
    display: 'flex',
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23F59E0B' stroke-width='2' stroke-dasharray='30%2c20' stroke-dashoffset='31' stroke-linecap='square'/%3e%3c/svg%3e")`,
    width:79,
    height:72,
    backgroundColor:'#FFEFBD', 
    borderRadius: 8,
    marginLeft: 30
  },
  "& .signContainer":{
    display: 'flex',
    flexDirection: 'row',
    marginTop:20,

  },
  "& .headerText":{
    color: (theme as any).typography.h1, 
    fontSize: 24,
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  '& .iconLoading': {
    color:'#CEAE71', 
    alignSelf:'center'
  },
  '& .wrapModal': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    backgroundColor:(theme as any).typography.body2,
    borderRadius: "16px",
    '@media (max-width: 1000px) and (orientation: landscape)': {
      height: 200,
      overflow:'auto',
      minWidth:'70% !important',
    },
    '@media (max-width: 600px)': {
      height:500,
      overflow:'auto',
      minWidth:'70% !important',
    },
    minWidth:'40%',
  },
  '& .wrapModalLoading': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    backgroundColor:(theme as any).typography.body2,
    borderRadius: "16px",
    '@media (max-width: 1000px) and (orientation: landscape)': {
      height: 200,
      overflow:'auto',
      minWidth:'70% !important',
    },
    '@media (max-width: 600px)': {
      overflow:'auto',
      minWidth:'70% !important',
    },
    minWidth:'40%',
  },
  '& .wrapModalSign': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    backgroundColor:(theme as any).typography.body2,
    borderRadius: "16px",
    '@media (max-width: 1050px) and (orientation: landscape)': {
      overflow:'auto',
      width:'90% !important',
    },
    '@media (max-width: 1050px)': {
      height:'50%',
      overflow:'auto',
      width:'60% !important',
    },
    '@media (max-width: 600px)': {
      height: 500,
      overflow:'auto',
      width:'90% !important',
    },
    width:'30%',
  },
  '& .wrapModalGuarantor': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    backgroundColor:(theme as any).typography.body2,
    borderRadius: "16px",
    '@media (max-width: 1000px) and (orientation: landscape)': {
      height:200,
      overflow:'auto',
      minWidth:'70% !important',
    },
    '@media (max-width: 600px)': {
      overflow:'auto',
      minWidth:'70% !important',
    },
    minWidth:'40%',
  },
  '& .wrapModalContent': {
    padding: '40px',
    maxHeight:'400px',
    overflowY: 'scroll',
    backgroundColor:'#ececec',
    '@media (max-width: 1000px) and (orientation: landscape)': {
      height: 200,
      overflow:'auto',
    },
  },
  '& .wrapModalContentLoading': {
    justifyContent: 'center',
    padding: '40px',
    alignItems:'center',
    width:'100%',
    display:'flex',
    flexDirection:'column'
  },
  '& .wrapModalContentDelete': {
    padding: '40px',
    maxHeight:'400px',
    backgroundColor: (theme as any).typography.body2,
  },
  '& .wrapModalContentGuarantor': {
    padding: '16px',
    maxHeight:'600px',
    overflowY: 'scroll',
    backgroundColor:(theme as any).typography.body2,
    borderBottomLeftRadius:16,
    borderBottomRightRadius:16,
    display:'flex',
    flexDirection:'column',
    '@media (max-width: 600px)': {
      padding: '16px',
      '@media (orientation: landscape)': {
        height: 200,
        overflow:'auto',
      },
      maxHeight:'500px',
      overflow: 'scroll',
      backgroundColor:(theme as any).typography.body2,
      borderBottomLeftRadius:16,
      borderBottomRightRadius:16
    },
    '@media (max-width: 1000px) and (orientation: landscape)': {
        height: 200,
        overflow:'auto',
    },
  },
  '& .mainBox': {
    position: 'absolute' as 'absolute',
    backgroundColor: (theme as any).typography.body2,
    borderRadius: "16px",
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    minWidth: "80%", 
    maxHeight: '80vh',
    overflowY: 'auto',
    padding: '20px', 
  },
  '& .modalMainWrap': {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 20px",
    flexDirection: "row",
  },
  '& .modalTitleText': {
    color: (theme as any).typography.h1,
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "Outfit",
    display: "flex",
    alignItems: "center",
  },
  '& .lineModal': {
    backgroundColor: "#FFF6DC30",
    height: "1px",
  },
  '& .modalContenerWrap': {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "5%",
    paddingBottom: "5%",
  },
  '& .modalInputBox': {
    display: "grid",
    gridTemplateColumns: "1fr 1fr", 
    gap: "16px", 
  },
  '& .titleText': {
    fontSize: "14px",
    color: (theme as any).typography.h1,
    fontWeight: 700,
    fontFamily: "Outfit",
  },
  '& .inputField': {
    marginTop: '4px',
    color: (theme as any).typography.h1,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    borderRadius: 8,
    border: `1px ${(theme as any).typography.border2} solid`,
    background: (theme as any).typography.body2,
    padding: '10px 8px',
    fontWeight: 400,
    width: '100% !important',
  },
  '& .errorsMsg': {
    color: "#F87171",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  '& .modalBtnWrap': {
    display: "flex",
    justifyContent: "flex-end",
    padding: "15px 24px 0px",
  },
  '& .modalCancelBtn': {
    cursor: "pointer",
    backgroundColor: (theme as any).typography.surface2,
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
    height: "56px",
    borderRadius: "8px",
    color: "#CEAE71",
    fontFamily: "Outfit",
  },
  '& .modalAddBtn': {
    marginLeft: "16px",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    borderRadius: "8px",
    color: "#171717",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
    height: "56px"
  },
  "& .btnAddMisc":{
    cursor: 'pointer',
    padding: "16px 0 16px 0", 
    background: (theme as any).typography.surface2, 
    borderRadius: '8px', 
    width: '100%',
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',    
  },
  '& .btntextAddMisc': {
    textTransform: 'none',
    color: '#CEAE71', 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  '& .deletebtn' : {
    background: (theme as any).typography.surface2, 
    borderRadius : '11px',
    minWidth: '50px',
    minHeight: '50px'
  }
}));
const StyledMenuGuarantor = withStyles({
  paper: {
    borderRadius: '8px',
    backgroundColor: '#3F3E3A'
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    {...props}
  />
));
const DealDetailStyle  = styled(Box)(({ theme }) => ({
  "& .documentUploaded":{
    borderRadius: 8,
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-between',
    width:230,
    height:72,
    backgroundColor:(theme as any).typography.surface2, 
    padding:'0 8px',
    '@media (max-width: 600px)': {
      borderRadius: 8,
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
      justifyContent:'space-between',
      width:180,
      height:72,
      backgroundColor:(theme as any).typography.surface2, 
      padding:'0 8px'
    },
  },
  "& .iconBank":{
    cursor:'pointer',
    color: (theme as any).typography.h3, 
  },
  "& .wrapTextUpload":{
    width:110,
  },
  "& .textUpload1":{
    color: (theme as any).typography.h1, 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '400',
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis',
    '@media (max-width: 600px)': {
      color: (theme as any).typography.h1, 
      fontSize: "16px", 
      fontFamily: 'Outfit', 
      fontWeight: '400',
      whiteSpace:'nowrap',
      overflow:'hidden',
      textOverflow:'ellipsis',
      width: 80
    },
  },
  
  '& .wrapItem':{
    backgroundColor: (theme as any).typography.surface2,
    display:'flex',
    flexDirection:'row', 
    justifyContent:'space-between', 
    padding:'16px', 
    marginBottom:'16px', 
    borderRadius:'8px'
  },
  '& .itemText':{
    color: (theme as any).typography.h1,
    fontSize: 20,
    fontFamily: 'Outfit', 
    fontWeight: '400'
  },
  '& .groupText':{
    cursor:'pointer',
    display:'flex',
    flexDirection:'row', 
    gap:60
  },
  '& .wrapContentExpand':{
    marginTop:16,
    display:'flex',
    flexDirection:'column'
  },
  '& .container': {
    padding: 30
  },
  '& .wrapContent':{
    backgroundColor:(theme as any).typography.body2, 
    height:'fit-content', 
    borderRadius:16, 
    padding: 16,
  },
  '& .wrapHeader':{
    gap:10
  },
  '& .wrapId':{
    display:'flex', 
    flexDirection:'row', 
    alignItems:'center'
  },
  '& .selectedBankLabel': {
    color: (theme as any).typography.h1, 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  '& .wrapStatus': {
    display:'flex', 
    flexDirection:'row', 
    alignItems:'center', 
    justifyContent:'flex-end',
    gap:16,
  },
  '& .wrapExpand': {
    display:'flex', 
    flexDirection: 'row', 
    justifyContent:'space-between'},
  '& .textId': {
    color: (theme as any).typography.h1, 
    fontSize: '22px', 
    fontFamily: 'Inter', 
    fontWeight: '500',
    marginRight: 16
  },
  '& .textDetail1': {
    color: (theme as any).typography.h1, 
    fontSize: '16px', 
    fontFamily: 'Inter', 
    fontWeight: '400',
    display:'inline',
    flexDirection:'row',
    marginBottom:8
  },
  '& .textVehicle': {
    color: (theme as any).typography.h1, 
    fontSize: '16px', 
    fontFamily: 'Inter', 
    fontWeight: '500',
    wordWrap: 'anywhere',
    display:'inline',
  },
  "& .documentTitle":{
    color: (theme as any).typography.h1, 
    fontSize: "14px", 
    fontFamily: 'Outfit', 
    fontWeight: '400',
    marginBottom:8
  },
  "& .textBank":{
    color: (theme as any).typography.h1, 
    fontSize: "20px", 
    fontFamily: 'Outfit', 
    fontWeight: '400',
  },
  "& .documentUpload":{
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23FFF6DCFF' stroke-width='2' stroke-dasharray='30%2c20' stroke-dashoffset='31' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: 8,
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    width:230,
    height:72,
    backgroundColor:(theme as any).typography.surface2, 
    '@media (max-width: 600px)': {
      width:150,
    },
  },
  "& .wrapDocuments":{
    display:'flex',
    flexWrap:'wrap',
    marginTop:16
  },
  "& .wrapBank":{
    flexDirection:'row',
     display: 'flex', 
     gap: 24,
     marginTop:16,
     flexWrap:'wrap'

  },
  "& .wrapBroker":{
    flexDirection:'column',
     display: 'flex', 
     marginTop:16
  },
  "& .bankItem":{
    padding:10, 
    borderRadius:16, 
    border:`1px solid ${(theme as any).typography.border}`, 
    height:'fit-content', 
    justifyContent:'center', 
    alignItems:'center',
    backgroundColor: (theme as any).typography.surface2, 
  },
  "& .wrapDocument":{
    marginRight: 20,
    marginBottom:10
  },
  "& .addIcon":{
    color: (theme as any).typography.h1, 
  },
  "& .textUpload":{
    color: (theme as any).typography.h1, 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '400'
  },
  '& .wrapHeaderCustomerDetail':{
    display:'flex',
    flexDirection:'column',
  },
  '& .line': {
    height:"1px", 
    backgroundColor:(theme as any).typography.line,
    marginTop:16,
    marginBottom:16,
  },
  '& .labelText': {
    color: (theme as any).typography.h1, 
    fontSize: '14px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  "& .uploadIconBox":{
    display: "flex",
    padding: "var(--border-radius, 16px)",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "8px", 
    background: "#FFEFBD",
  },
  "& .btnWrap2":{
    cursor: 'pointer',
    padding: "10px", 
    background: (theme as any).typography.surface2,
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',
    width: 'fit-content',
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  "& .btnWrap":{
    cursor: 'pointer',
    padding: "4px 10px", 
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',
    width: 'fit-content',
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    height:32
  },
  "& .btnText":{
    color: '#171717', 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '700',
    '@media (max-width: 600px)': {
      fontSize: "12px",
    },
  },
  "& .iconGold":{
    color: '#CEAE71',
  },  
  "& .icon":{
    color: (theme as any).typography.h1, 
  },
  "& .btnTextEdit":{
    color: '#CEAE71', 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '700',
    marginLeft:4
  },
  "& .wrapSignItem":{
    display:'flex', 
    flexDirection:'column',
    gap:16
  },
  "& .wrapSignBtn":{
    display:'flex', 
    flexDirection:'row', 
    alignItems:'center', 
    gap:10
  },
  "& .wrapSign": {
    display:'flex', 
    flexDirection:'row', 
    flexWrap:'wrap', 
    gap:40
  },
  '& .signedText': {
    textTransform: 'none',
    color: '#CEAE71', 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  "& .btnWrapAdd":{
    width: '100%',
    cursor: 'pointer',
    padding: "16px 0 16px 0", 
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',    
  },
  "& .btnWrapCancel":{
    cursor: 'pointer',
    padding: "16px 0 16px 0", 
    borderRadius: '8px', 
    width: '100%',
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',    
  },
  '& .btnTextCancel': {
    textTransform: 'none',
    color: '#CEAE71', 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  '& .btnTextAdd': {
    textTransform: 'none',
    color: '#171717', 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  '& .inputField':{
    width:'50% !important'
  }
}))

// Customizable Area End
export default DealDetailView;
