import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "framework/src/Utilities";

// Customizable Area Start
import moment from "moment";
const facebookPageId = "205301136008162";
const longString = "EAAnW2X7zoOwBOzmiZA6n5fJDxzIjgeeyZBAnI9a3690wPP0NycaRDXZApx5AkI9zBVQJbJ4FLIL6ZCxvIRbWKHk7TR5vfDEuMfSbczQslfH7ZBQhKXHGQF1PO8bo3TZC1iSbwgJY2pmhWcG0hNgqEhXjlBDfPxN3A65lsS2nwLLUqyk92fUDuzubj6x5T4ypPLF3Sg3ZBAZD"
const instagramPageId = "17841464943600812";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeTab: "team" | "invidual",
  openModal: boolean,
  showPassword: boolean;
  showConfirmPassword: boolean;
  token: any;
  newPassword: string;
  confirmNewPassword: string;
  error: { upper: boolean, number: boolean, minimum: boolean, special: boolean };
  errorValue: string;
  openResetPassModal: boolean;
  openSuccessfullyModal: boolean;
  PassReset: string;
  loading: boolean;
  rowCatalogue: any;
  rowCRM: any;
  accountingPayrollData: any
  isCalendarOpen: boolean;
  startDate: Date | null;
  endDate: Date | null;
  marketing: any,
  anchorEl: any,
  anchorEl1: HTMLElement | null ,
  selectedRow: any;
  formIsDirtyCreate: boolean;
  deals: any;
  
  listImagesFacebook: any,
  listImagesInstagram: any,
  listVideosFacebook: any,
  listVideosInstagram: any,
  facebookMediaIds: any,
  instagramListIds: any,
  is_posted_on_facebook: string | null,
  is_posted_on_instagram: string | null,
  modalLoadingLandingPage: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postResetPasswordApiId: string = '';
  getLandingPageDataId: any;
  getAccountingPayrollFilterId: any;
  deletSocialMediaPostDashboardId: any;

  getMediaIdFacebookRetryLandingPageId: any;
  createFacebookPostIdRetryLandingPage: any;
  createInstagramMediaContainerRetryLandingPageId: any;
  createItemContainerInstagramRetryLandingPageId: any;
  createCarouselContainerInstagramRetryLandingPageId: any;
  publishInstagramMediaContainerRetryLandingPageId: any;
  editPostLandingPageId: any;

  createVideoPostFacebookId: any;
  createInstagramMediaContainerVideoId: any;
  checkInstagramMediaContainerVideoStatusId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      activeTab: "invidual",
      openModal: true,
      marketing: {},
      showPassword: false,
      loading: true,
      showConfirmPassword: false,
      token: '',
      newPassword: '',
      confirmNewPassword: '',
      error: { upper: false, number: false, minimum: false, special: false },
      errorValue: '',
      openResetPassModal: false,
      openSuccessfullyModal: false,
      PassReset: "",
      rowCatalogue: [],
      rowCRM: [],
      deals: [],
      accountingPayrollData: {
        team: {
          Profit: 0,
          Sales: 0,
          No_of_vehicles_added: 0,
          Pending_cases: 0
        },
        invidual: {
          Profit: 0,
          Sales: 0,
          No_of_vehicles_added: 0,
          Pending_cases: 0
        }
      },
      startDate: null,
      endDate: null,
      isCalendarOpen: false,
      anchorEl: null,
      anchorEl1: null,
      selectedRow: null,
      formIsDirtyCreate: false,

      listImagesFacebook: [],
      listImagesInstagram: [],
      listVideosFacebook: [],
      listVideosInstagram: [],
      is_posted_on_facebook: null,
      is_posted_on_instagram: null,
      facebookMediaIds: [],
      instagramListIds: [],
      modalLoadingLandingPage: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    let PassReset = await getStorageData("ResetPass")
    this.setState({ PassReset: PassReset })
    const token = await getStorageData("authToken")
    this.setState({ token: token })
    this.getLandingPageData()
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    switch (apiRequestCallId) {
      case this.postResetPasswordApiId:
        this.handleResetPasswordModal(responseJson);
        break;
      case this.getLandingPageDataId:
        this.getLandingPageDataReceiver(responseJson);
        break;
      case this.getAccountingPayrollFilterId:
        this.AccountinPayrollFilterDataReceiver(responseJson);
        break;
      case this.deletSocialMediaPostDashboardId:
        this.deleteSocialMediaReceiverDshboard(responseJson);
        break;

      case this.getMediaIdFacebookRetryLandingPageId:
        this.getMediaIdFacebookRetryLandingPageReceiver(responseJson);
        break;
      case this.createFacebookPostIdRetryLandingPage:
        this.createFacebookPostIdRetryLandingPageReceiver(responseJson);
        break;
      case this.editPostLandingPageId:
        if((this.state.is_posted_on_facebook === "true" || this.state.is_posted_on_facebook === null) && (this.state.is_posted_on_instagram === "true" || this.state.is_posted_on_instagram === null)){
          this.setState({modalLoadingLandingPage: false, anchorEl:null, anchorEl1: null})
        }
        this.getLandingPageData()
        break;
      case this.createInstagramMediaContainerRetryLandingPageId:
        this.publishInstagramMediaRetryLandingPageReceiver(responseJson.id);
        break;
      case this.publishInstagramMediaContainerRetryLandingPageId:
        this.publishInstagramMediaContainerRetryLandingPageReceiver();
        break;
      case this.createItemContainerInstagramRetryLandingPageId: 
        this.createItemContainerInstagramRetryLandingPageReceiver(responseJson)
        break;
      case this.createCarouselContainerInstagramRetryLandingPageId:
        this.publishInstagramMediaRetryLandingPageReceiver(responseJson.id);
        break;
      case this.createVideoPostFacebookId:
        this.createVideoPostFacebookReceiver();
        break;
      case this.createInstagramMediaContainerVideoId:
        this.checkInstagramMediaContainerVideoStatus("IN_PROGRESS", responseJson.id)
        break;
      case this.checkInstagramMediaContainerVideoStatusId:
        setTimeout(() =>{
          this.checkInstagramMediaContainerVideoStatus(responseJson.status_code, responseJson.id)
        }, 10000);
        break;
      default:
        break;
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start

  handleDateChange = (date: any, e: any) => {
    this.setState({
      startDate: date[0],
      endDate: date[1],
      isCalendarOpen: false,
    })

    this.getAccountingPayrollDateSelect()
  };
  toggleCalendar = () => {
    this.setState((prevState) => ({
      isCalendarOpen: !prevState.isCalendarOpen, // Toggle calendar visibility
    }));
  };

  getLandingPageData = async () => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessageLandingPage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.landingPageEndPoint}`
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getLandingPageDataId = requestMessageLandingPage.messageId;
    runEngine.sendMessage(requestMessageLandingPage.id, requestMessageLandingPage);
  }

  getLandingPageDataReceiver = (responseJson: any) => {


    this.setState({
      rowCatalogue: responseJson.catalogues.data,
      rowCRM: responseJson.crm.data,
      accountingPayrollData: {
        team: responseJson.team,
        invidual: responseJson.invidual
      },
      marketing: responseJson.marketing,
      deals: responseJson.deals.data,
      loading: false,
    })
  }

  checkSubmitCreate = ()=>{
    this.setState({...this.state, formIsDirtyCreate: true})
  }


  handleViewMoreCatalogue = () => {
    this.props.navigation.navigate('Catalogue')
  }

  handleViewMoreDeal = () => {
    this.props.navigation.navigate('SalesAndContract')
  }

  handleViewMoreCRM = () => {
    this.props.navigation.navigate('CRM')
  }

  handleViewMoreAccountingAndPayroll = () => {
    this.props.navigation.navigate('AccountingAndPayroll')
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  getAccountingPayrollDateSelect = async () => {

    this.setState({ loading: true })
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessageLandingPage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountingAndPayroll}?start_date=${moment(this.state.startDate).format("YYYY-MM-DD")}&end_date=${moment(this.state.endDate).format("YYYY-MM-DD")}`
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getAccountingPayrollFilterId = requestMessageLandingPage.messageId;
    runEngine.sendMessage(requestMessageLandingPage.id, requestMessageLandingPage);
  }
  handleCreatePassword = () => {
    this.setState({ openResetPassModal: true, openModal: false });
  }
  handleClickOpen = () => {
    this.setState({ openModal: !this.state.openModal });
  };
  handleSuccessfullyOpen = () => {
    this.setState({ openSuccessfullyModal: !this.state.openSuccessfullyModal, openResetPassModal: false });
  };
  handleTabClick = (tab: any) => {
    this.setState({ activeTab: tab });
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }
  AccountinPayrollFilterDataReceiver = (responseJson: any) => {



    const data = { ...responseJson };
    this.setState({ accountingPayrollData: data, loading: false })
  }

  handleShowConfirm = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword })
  }

  shouldComponentUpdate(_nextProps: Readonly<Props>, _nextState: Readonly<S>, _nextContext: any): boolean {
    if(this.state.isCalendarOpen){
      this.setState({isCalendarOpen:false});
    }
    return true;
  }

  onValueChangelog = (name: string, value: string) => {
    this.setState({
      ...this.state, [name]: value,
      error: {
        minimum: /^.{8,}$/.test(value) ? true : false,
        upper: /^(?=.*[a-z])(?=.*[A-Z])/.test(value) ? true : false,
        special: /^(?=.*[!@#\$%\^&\*])/.test(value) ? true : false,
        number: /^(?=.*\d)/.test(value) ? true : false,
      },
      errorValue: value
    }
    );
  };
  onValueChanges = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value }
    );
  };
  handleResetPasswordModal = (responseJson: any) => {
    const resetSuccess = responseJson?.data?.attributes?.is_reset_password
    if (resetSuccess === true) {
      this.handleSuccessfullyOpen();
      setStorageData("ResetPass", "active")
    }
  }

  resetPassApi = () => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.token
    };
    let formDataCampaign = {
      "confirm_password": this.state.confirmNewPassword,
      "password": this.state.newPassword,
    }
    const requestMessageLandingPage = new Message(

      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageLandingPage.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileResetPasswordAPiEndPoint,
    );
    requestMessageLandingPage.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessageLandingPage.addData(

      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaign),
    );
    requestMessageLandingPage.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod,
    );

    this.postResetPasswordApiId = requestMessageLandingPage.messageId;

    runEngine.sendMessage(requestMessageLandingPage.id, requestMessageLandingPage);
  }

  deleteSocialMediaReceiverDshboard = (responseJson: any) => {
    this.getLandingPageData()
  }
  handleOpenClick = (event: any, row: any) => {
    this.setState({
      anchorEl: event?.currentTarget,
      selectedRow: row
    });
  };
  handleOpenClick1 = (event: React.MouseEvent<HTMLElement, MouseEvent>, row: number) => {
    this.setState({
      anchorEl1: event?.currentTarget,
      selectedRow: row
    });
  };

  deleteSocialMediaPostDashboard = async () => {
    this.setState({
      loading: true
    })
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessageLandingPage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSocialMediaEndpointDashboard}/${this.state.selectedRow.id}`
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethodDashboard
    );
    this.deletSocialMediaPostDashboardId = requestMessageLandingPage.messageId;
    runEngine.sendMessage(requestMessageLandingPage.id, requestMessageLandingPage);
  }
  handleCloseModal = (actionType: string,) => {
    this.setState({ anchorEl: null });
    if (actionType === "Delete") {
      this.deleteSocialMediaPostDashboard()
    }
  };
  handleClose1Modal = (actionType: string,) => {
    this.setState({ anchorEl1: null });
    if (actionType === "Delete") {
      this.deleteSocialMediaPostDashboard()
    }
  };

  handleRetryLandingPage = () => {
    this.setState({
      modalLoadingLandingPage: true,
      listVideosInstagram: this.state.selectedRow.attributes.videos,
      listImagesInstagram: this.state.selectedRow.attributes.images,
      
      listVideosFacebook: this.state.selectedRow.attributes.videos,
      listImagesFacebook: this.state.selectedRow.attributes.images,
    } , ()=>{
      for(const platform of this.state.selectedRow?.attributes?.platform){
        switch(platform){
          case("Facebook"):
            if(!this.state.selectedRow.attributes.is_posted_on_facebook){
              this.setState({is_posted_on_facebook:'false'})
              this.handlePostFacebookRetryLandingPage()
            }
          break;
          case("Instagram"):
            if(!this.state.selectedRow.attributes.is_posted_on_instagram){
              this.setState({is_posted_on_instagram:'false'})
              this.handlePostInstagramRetryLandingPage()
            }
          break;
        }
      }
    })    
  }

  handlePostInstagramRetryLandingPage = ()=>{
    if (((this.state.listVideosInstagram?.length ?? 0) + (this.state.listImagesInstagram?.length ?? 0)) === 1){
      if(this.state.listVideosInstagram?.length === 1) {
        this.createSingleVideoPostInstagram();
      } else {
        this.createSingleImagePostInstagramRetryLandingPage();
      }
    } else {
      this.createMultipleImagePostInstagramRetryLandingPage(this.state.listImagesInstagram[0].url)
    }
  }

  handlePostFacebookRetryLandingPage = ()=>{
      if((this.state.listImagesFacebook?.length ?? 0) > 0 ){
        this.getMediaIdRetryList(this.state.listImagesFacebook[0].url)
      } else {
        this.createVideoPostFacebook(this.state.listVideosFacebook[0].url)
      }
  }

  createSingleVideoPostInstagram = () => {
    this.createInstagramMediaContainerVideo(this.state.listVideosInstagram[0].url, `${this.state.selectedRow.attributes.post} ${this.state.selectedRow.attributes.hashtag ?? ""}`)
  };

  createVideoPostFacebook = async (url: any)=>{
    const requestMessageLandingPage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      file_url: url,
      access_token: longString,
      description:`${this.state.selectedRow.attributes.post} ${this.state.selectedRow.attributes.hashtag ?? ""}`
    };
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/${facebookPageId}/videos`
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createVideoPostFacebookId = requestMessageLandingPage.messageId; 
    runEngine.sendMessage(requestMessageLandingPage.id, requestMessageLandingPage) ;
  }

  createVideoPostFacebookReceiver = () => {
    let temp =[...this.state.listVideosFacebook];
    temp.shift()
    this.setState(_prevState => ({
      listVideosFacebook: temp
    }), () => {
      if(this.state.listVideosFacebook.length > 0) {
        this.createVideoPostFacebook(this.state.listVideosFacebook[0].url)
      } else {
        this.setState({
          is_posted_on_facebook: "true" }, ()=>{
            this.ediPostLandingPage();
          }
        )
      }
    });
  };

  checkInstagramMediaContainerVideoStatus = (status: string, containerId: string) => {    
    switch (status) {
      case "FINISHED":
        this.publishInstagramMediaRetryLandingPageReceiver(containerId)
        break;
      case "IN_PROGRESS":
        const requestMessageLandingPage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageLandingPage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
            'Content-Type' : 'application/json',
          })
        ) ;
        requestMessageLandingPage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage), 
          `https://graph.facebook.com/v20.0/${containerId}/?fields=status_code&access_token=${longString}`
        );
        requestMessageLandingPage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'GET'
        );
        this.checkInstagramMediaContainerVideoStatusId = requestMessageLandingPage.messageId; 
        runEngine.sendMessage(requestMessageLandingPage.id, requestMessageLandingPage) ;
        break;
    
      default:
        break;
    }
  };

  createInstagramMediaContainerVideo = (videoUrl: string, caption: string) => {    
    const requestMessageLandingPage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      video_url: videoUrl,
      caption: caption,
      access_token: longString,
      media_type: "REELS"
    };
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${instagramPageId}/media`
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createInstagramMediaContainerVideoId = requestMessageLandingPage.messageId; 
    runEngine.sendMessage(requestMessageLandingPage.id, requestMessageLandingPage) ;
  };

  getMediaIdRetryList = async (url:any) => {
    const formData = new FormData();
    formData.append('url', url)
    const requestMessageLandingPage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `https://graph.facebook.com/${facebookPageId}/photos?access_token=${longString}&published=false`
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
       formData
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    this.getMediaIdFacebookRetryLandingPageId = requestMessageLandingPage.messageId;
    runEngine.sendMessage(requestMessageLandingPage.id, requestMessageLandingPage);
  };

  getMediaIdFacebookRetryLandingPageReceiver = (response: any) => {
    let temparr = [...this.state.listImagesFacebook]
    temparr.shift()
    this.setState(prevState => ({
      facebookMediaIds: [...prevState.facebookMediaIds, response.id] ,
      listImagesFacebook: temparr
    }), () => {
      if(this.state.listImagesFacebook.length > 0) {
        this.getMediaIdRetryList(this.state.listImagesFacebook[0].url)
      } else {
        this.postToFacebookLandingPage(this.state.facebookMediaIds)
      }
    });
  };

  postToFacebookLandingPage = async (facebookMediaIds: string[]) => {
    const requestMessageLandingPage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    const body = {
      message: `${this.state.selectedRow.attributes.post} ${this.state.selectedRow.attributes.hashtag ?? ""}`,
      attached_media: facebookMediaIds.map(idmedia => ({ media_fbid: idmedia })),
    };
  
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type': 'application/json',
      })
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `https://graph.facebook.com/${facebookPageId}/feed?access_token=${longString}`
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
  
    this.createFacebookPostIdRetryLandingPage = requestMessageLandingPage.messageId;
    runEngine.sendMessage(requestMessageLandingPage.id, requestMessageLandingPage);
  };

  createFacebookPostIdRetryLandingPageReceiver = (response: any) => {
    if (response && response.id) {
      this.setState({is_posted_on_facebook : "true" } )
    } else {
      this.setState({is_posted_on_facebook : "false" } )
    }
    this.ediPostLandingPage();
  };

  createSingleImagePostInstagramRetryLandingPage = () => {
    this.createInstagramMediaContainerRetryLandingPage(this.state.listImagesInstagram[0].url, `${this.state.selectedRow.attributes.post} ${this.state.selectedRow.attributes.hashtag ?? ""}`)
  };

  createMultipleImagePostInstagramRetryLandingPage =(url: string)=>{
    this.createItemContainerInstagramRetryLandingPage(this.state.listImagesInstagram[0].url)
  }

  createInstagramMediaContainerRetryLandingPage = (imageUrl: string, caption: string) => {    
    const requestMessageLandingPage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    );
    const body = {
      image_url: imageUrl,
      caption: caption,
      access_token: longString,
    };
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${instagramPageId}/media`
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createInstagramMediaContainerRetryLandingPageId = requestMessageLandingPage.messageId; 
    runEngine.sendMessage(requestMessageLandingPage.id, requestMessageLandingPage) ;
  };

  createItemContainerInstagramRetryLandingPage = (url: string)=>{
    const requestMessageLandingPage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    );
    const body = {
      image_url: url,
      is_carousel_item: true,
      access_token: longString,
    };
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${instagramPageId}/media`
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createItemContainerInstagramRetryLandingPageId = requestMessageLandingPage.messageId; 
    runEngine.sendMessage(requestMessageLandingPage.id, requestMessageLandingPage) ;
  }

  createItemContainerInstagramRetryLandingPageReceiver = (response: any)=>{
    let temparr =[...this.state.listImagesInstagram]
    temparr.shift()
    this.setState(prevState => ({
      instagramListIds: [...prevState.instagramListIds, response.id],
      listImagesInstagram: temparr
    }), () => {
      if(this.state.listImagesInstagram.length > 0) {
        this.createItemContainerInstagramRetryLandingPage(this.state.listImagesInstagram[0].url)
      } else {
        this.createCarouselContainerInstagramRetryLandingPage(this.state.instagramListIds)
      }
    });
  }

  createCarouselContainerInstagramRetryLandingPage = (listIds: any)=>{
    const requestMessageLandingPage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    );
    const body = {
      media_type: "CAROUSEL",
      caption: `${this.state.selectedRow.attributes.post} ${this.state.selectedRow.attributes.hashtag ?? ""}`,
      children:listIds && listIds.join(','),
      access_token: longString,
    };
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${instagramPageId}/media`
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createCarouselContainerInstagramRetryLandingPageId = requestMessageLandingPage.messageId; 
    runEngine.sendMessage(requestMessageLandingPage.id, requestMessageLandingPage) ;
  } 

  publishInstagramMediaRetryLandingPageReceiver = (creationId: string) => {
    const requestMessageLandingPage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    );
    const body = {
      creation_id : creationId,
      access_token: longString,
    };
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${instagramPageId}/media_publish`
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.publishInstagramMediaContainerRetryLandingPageId = requestMessageLandingPage.messageId; 
    runEngine.sendMessage(requestMessageLandingPage.id, requestMessageLandingPage) ;
  }

  publishInstagramMediaContainerRetryLandingPageReceiver = ()=>{
    this.setState({is_posted_on_instagram: 'true'}, ()=>{
      this.ediPostLandingPage();
    });
  }

  ediPostLandingPage = async () => {
    const tokenValue = await getStorageData("authToken")
    const headers = {
      token: tokenValue,
    };     
    const formdata = new FormData();
    this.state.is_posted_on_facebook && formdata.append("is_posted_on_facebook", this.state.is_posted_on_facebook);
    this.state.is_posted_on_instagram && formdata.append("is_posted_on_instagram", this.state.is_posted_on_instagram);
    const requestMessageLandingPage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSocialMediaEndpoint}/${this.state.selectedRow.id}`
    );
    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessageLandingPage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    this.editPostLandingPageId = requestMessageLandingPage.messageId;
    runEngine.sendMessage(requestMessageLandingPage.id, requestMessageLandingPage);
  }
  // Customizable Area End
}
