import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { bgimage, hidePassword, logo, showPassword } from "./assets";
import { Formik } from "formik";
import Alert from "@material-ui/lab/Alert";
import * as Yup from "yup";
import clsx from 'clsx';
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start
  userSchemas = () => {
    return Yup.object().shape({
      email: Yup.string().email('Entered Email ID is invalid').matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[|a-z]{2,}$/, "Entered Email ID is invalid").required("*Email ID is required!"),
      password: Yup.string()
      .required('The password is required!'),
    });
  };
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <>
        <LoginStyle>
          <Grid container >
            <Grid item xs={12} className="mainContainer">
              <Box className="loginCard">
                <Box className="logoBox">
                  <img src={logo} className="logo" />
                </Box>
                <Box className="logintextBox">
                  <Typography className="loginText">Login</Typography>
                  <Formik
                    data-test-id="formik"
                    enableReinitialize
                    initialValues={{
                      email: this.state.email,
                      password: this.state.password,
                    }}
                    validationSchema={this.userSchemas}
                    validateOnChange={this.state.formIsDirty}
                    validateOnBlur={this.state.formIsDirty}
                    onSubmit={(values) => {
                      this.loginApi();
                    }}
                  >
                    {({ errors, setFieldValue, handleSubmit }) => (
                      <form onSubmit={handleSubmit} noValidate autoComplete="off">
                        <Typography className="labelText">Email Id</Typography>
                        <Input
                          data-test-id='email'
                          className={`inputField ${this.state.errorEmail||errors.email ? 'errorInput' : 'inputField'}`}
                          placeholder="Enter Email"
                          disableUnderline
                          value={this.state.email}
                          onChange={(event) => {
                            this.onValueChange("email", event.target.value);
                            setFieldValue("email", event.target.value);
                          }}
                          fullWidth />
                        <Typography className="errorsMsg">{this.state.errorEmail||errors.email}</Typography>
                        <Typography className="labelText">Password</Typography>
                        <Input
                          data-test-id='password'
                          className={`inputField ${this.state.errorPass || errors.password ? 'errorInput' : ''}`}
                          placeholder="Enter Password"
                          value={this.state.password}
                          onChange={(event) => {
                            this.onValueChange("password", event.target.value);
                            setFieldValue("password", event.target.value);
                          }}
                          disableUnderline
                          fullWidth
                          type={this.state.showPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickPassword}
                                edge="end"
                              >
                                {this.state.showPassword ? <img src={showPassword} /> : <img src={hidePassword} />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <Typography className="errorsMsg">{this.state.errorPass || errors.password}</Typography>

                        <Box className="checkbocMaindiv">
                          <Box className="mainCheckbox">
                            <Checkbox id="checkBox" className="checkBox" checked={this.state.rememberMe} onClick={this.handleCheckboxChange}/>
                            <Typography className="checkboxText">Remember Me</Typography>
                          </Box>
                          <Box className="ForgotPasswordBox">
                            <Typography data-test-id='ForgotPassword' className="ForgotPassword" onClick={()=>this.props.navigation.navigate('ForgotPasswordWeb')}>Forgot Password ?</Typography>
                          </Box>
                        </Box>
                        <Box className="checkboxTerm">
                          <Box className="mainCheckbox">
                            <Checkbox id="checkBox" className="checkBox" checked={this.state.agreeTerm} onClick={this.handleSelectTerm}/>
                            <Typography className="checkboxTextTerm">I hereby consent to the <Typography className="textLink" onClick={this.handleOpenPrivacy}><span>&nbsp;&nbsp;</span>Privacy Policy<span>&nbsp;&nbsp;</span></Typography> and <Typography className="textLink" onClick={this.handleOpenTerm}><span>&nbsp;&nbsp;</span>Terms of Use</Typography></Typography>
                          </Box>
                          
                        </Box>
                        <Button data-test-id='loginBtn' className={clsx({'loginBtn': this.state.agreeTerm, 'loginBtnDisable': !this.state.agreeTerm},)} 
                          disabled={!this.state.agreeTerm} type="submit" onClick={this.checkSubmit}>Login</Button>
                        {this.state.loginFail ? <Alert className="alertIcon" icon={false} severity="error">
                        {this.state.loginFail}
                        </Alert>:''}
                      </form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </LoginStyle>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const LoginStyle = styled(Box)(({ theme }) => ({
  backgroundImage: `url('${bgimage}')`,
  height: 'auto',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  "& .mainContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    padding: '20px',

  },
  "& .MuiTypography-body1":{
    fontFamily: "Outfit",
  },
  "& .MuiSvgIcon-root":{
    fill: (theme as any).typography.textSecond,
    width: "20px",
    height: "20px",
  },
  "& .loginCard": {
    width: "100%",
    maxWidth: "640px",
    height: "auto",
    padding: '40px',
    flexDirection: "column",
    backgroundColor: (theme as any).typography.body2,
    borderRadius: '16px',
    gap: "24px",
  },
  "& .logoBox": {
    display: "flex",
    justifyContent: 'center',
  },
  "& .logo": {
    width: '150px',
    height: '102px',
    marginBottom: '24px',
  },
  // "& .checkBox": {
  //   border:'1px solid #000000'
  // },
  "& .loginText": {
    fontFamily: "Outfit",
    fontSize: "38px",
    fontStyle: "normal",
    fontWeight: 700,
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  "& .form": {
    width: '100%'
  },
  "& .labelText": {
    marginTop: "24px",
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .inputField": {
    marginTop: '4px',
    borderRadius: 8,
    border: "2px solid var(--neutrals-cool-gray-200, #E2E8F0)",
    background: (theme as any).typography.body2, 
    padding: '10px 8px',
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .mainCheckbox": {
    display: 'flex',
    flexDirection: 'row',
  },
  "& .checkbocMaindiv": {
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  "& .checkboxTerm": {
    marginTop: '10px',
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  "& .checkboxText": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    alignItems:'center'
  },
  "& .checkboxTextTerm": {
    display: "inline",
    flexDirection: "row",
    justifyContent: "center",
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    alignSelf:'center',
  },
  "& .textLink": {
    cursor:'pointer',
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    display:'inline'
  },
  "& .ForgotPasswordBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  "& .ForgotPassword": {
    cursor:'pointer',
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .loginBtn": {
    display: "flex",
    width: "100%",
    textTransform: 'none',
    height: "56px",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    color: "var(--text-primary, #171717)",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .loginBtnDisable": {
    display: "flex",
    width: "100%",
    textTransform: 'none',
    height: "56px",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background:(theme as any).typography.surface2,
    color: "var(--text-primary, #171717)",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .errorsMsg": {
    color: "var(--status-red-400, #F87171)",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .errorInput": {
    border: "1px solid #F87171",
  },
  "& .alertIcon": {
    marginTop: '24px',
    color: "var(--status-red-400, #F87171)",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    backgroundColor: "rgb(83 83 83 / 41%)",
    borderRadius: "4px",
    borderLeft: "4px solid var(--status-red-400, #F87171)",
    background: "var(--surface-2, #3F3E3A)",
  }
}))
// Customizable Area End