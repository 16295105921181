import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  withWidth,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import clsx from 'clsx';
import Calendar from 'react-calendar';
import DateRangeIcon from '@material-ui/icons/DateRange';

import { styled } from "@material-ui/core/styles";

import moment from "moment";
import * as Yup from "yup";
// Customizable Area End

import NewCustomerCRMController, {
  engineCapList,
  milageList,
  priceValueList,
  Props, services, vehicleTypeList,
} from "./NewCustomerCRMController";
import { Formik } from "formik";
import CustomSelect from "../../../components/src/CustomSelect";

import CustomSelectService from "../../../components/src/CustomSelectService";

export default class NewCustomerCRM extends NewCustomerCRMController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userSchema = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return Yup.object().shape({
      name: Yup.string().required("Name of the customer is mandatory").matches(/^[A-Za-z ]+$/, 'Name is invalid'),
      dob: Yup.date().required("Date Of Birth of the customer is mandatory").max(yesterday, "Date Of Birth is invalid"),
      contactNumber: Yup.string().required("Contact number of the customer is mandatory").matches(/^[^a-zA-Z]+$/,'Contact Number is invalid'),
      email: Yup.string().required("Email ID of the customer is mandatory").matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email ID is invalid'),
      services: Yup.array().required("Interested services of the customer is mandatory"),
      datePurchase: Yup.date().max(new Date(), "Date of purchase is invalid"),
    });
  };

  renderFormField = (label: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, fieldName: any, placeholder: string | undefined, errors: any, values: any, setFieldValue: (arg0: any, arg1: string) => void) => {
    return (
      <Grid item xs={12} md={6} className="padding10">
        <Typography className="labelText">{label}</Typography>
        <TextField
          data-test-id={fieldName}
          className={clsx({ 'errorInput':errors[fieldName], 'inputField': !errors[fieldName] })}
          placeholder={placeholder}
          type={'text'}
          onChange={(event) => {
            if(fieldName === "contactNumber"){
            const regex = /^[0-9\b]+$/;
            if (event.target.value === "" || regex.test(event.target.value)) {
              setFieldValue(fieldName, event.target.value)                     
            }} else {
              setFieldValue(fieldName, event.target.value)
            }
          }}
          InputProps={{ disableUnderline: true, style: { color: "#f3f3f3" } }}
          value={values[fieldName]}
          onPointerDown={this.handleCloseCalendar}
          fullWidth
        />
        <Typography className="errorsMsg">{errors[fieldName]}</Typography>
      </Grid>
    );
  }

  renderCalendarButton = (fieldName: any, placeholder: any, isOpen: any, toggleCalendar: any, errors: any, values: any, setFieldValue: any) => {
    return (
      <>
        <button type="button"
          className={clsx({ 'customButtonError': errors[fieldName], 'customButtonPLACEHOLDER': !values[fieldName], 'customButton': values[fieldName] })}
          onClick={toggleCalendar}>
          <Typography className="date">{values[fieldName] ? moment(values[fieldName]).format('MM/DD/YYYY') : placeholder}</Typography>
          <DateRangeIcon />
        </button>
        {isOpen && <Calendar
          className="customCalendarPop"
          onChange={(event) => {
            setFieldValue(fieldName, event);
            this.setState({ isDOB: false, isDatePurchase: false, isRoadTaxRenewal: false, isCalendarOpen:false });
          }}
          value={values[fieldName]}
        />}
        <Typography className="errorsMsg">{errors[fieldName]}</Typography>
      </>
    );
  }
  // Customizable Area End 

  render() {
    return (
      // Customizable Area Start
      <NewCustomerStyle>
        <Formik
          data-test-id='formik'
          enableReinitialize={this.state.isEdit}
          initialValues={{
            name: this.state.name,
            dob: this.state.dob ? moment(this.state.dob, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
            contactNumber: this.state.contactNumber,
            email: this.state.email,
            datePurchase: this.state.datePurchase ? moment(this.state.datePurchase, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
            dateRoadTaxRenewal: this.state.dateRoadTaxRenewal ? moment(this.state.dateRoadTaxRenewal, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
            services: this.state.services,
            date_of_insurance: this.state.date_of_insurance ? moment(this.state.date_of_insurance, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
          }}
          validationSchema={this.userSchema}
          validateOnChange={this.state.formIsDirty}
          validateOnBlur={this.state.formIsDirty}
          onSubmit={(e) => {
            this.onSubmit(e);
          }}>
          {({ errors, values, setFieldValue, handleSubmit }) => {
            return (
              <form data-test-id='form' onSubmit={handleSubmit} className="formikWrap">
                <Grid container className='container'>
                  
                  {this.renderFormField("Name*", "name", "Enter name", errors, values, setFieldValue)}
                  <Grid item xs={12} md={6} className="padding10" style={{ position: "relative" }}>
                    <Typography className="labelText">Date Of Birth*</Typography>
                  {this.renderCalendarButton("dob", "mm/dd/yyyy", this.state.isDOB, () => this.setState({ isDOB: !this.state.isDOB }), errors, values, setFieldValue)}
                  </Grid>
                  {this.renderFormField("Contact number*", "contactNumber", "Enter Contact Number", errors, values, setFieldValue)}
                  
                  {this.renderFormField("Email*", "email", "Enter Email", errors, values, setFieldValue)}
                  <Grid item xs={12} md={6} className="padding10" style={{ position: "relative" }}>
                    <Typography className="labelText">Date of purchase (Optional)</Typography>
                    {this.renderCalendarButton("datePurchase", "mm/dd/yyyy", this.state.isDatePurchase, () => this.setState({ isDatePurchase: !this.state.isDatePurchase }),  errors, values, setFieldValue)}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10" style={{ position: "relative" }}>
                    <Typography className="labelText">Date of Road tax renewal (Optional)</Typography>
                    {this.renderCalendarButton("dateRoadTaxRenewal", "mm/dd/yyyy", this.state.isRoadTaxRenewal, () => this.setState({ isRoadTaxRenewal: !this.state.isRoadTaxRenewal }),  errors, values, setFieldValue)}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Services</Typography>
                    <CustomSelectService isError={errors.services} value={this.state.services} onSelectServices={this.onSelectServices} setFieldValue={setFieldValue} closeCalendar={this.handleCloseCalendar} />
                      <Typography className="errorsMsg">{errors.services}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10" style={{ position: "relative" }}>
                    <Typography className="labelText">Date of Insurance renewal (optional)</Typography>
                  {this.renderCalendarButton("date_of_insurance", "mm/dd/yyyy", this.state.isCalendarOpen,() => this.setState({ isCalendarOpen: !this.state.isCalendarOpen }),  errors, values, setFieldValue)}
                  </Grid>
                  {this.state.isShowPreference &&
                    <>
                      <Grid container className="padding10">
                        <Typography className="ServiceLabel">Select Your Car Prefrence</Typography>
                      </Grid>
                      <Grid container>
                        <Grid item md={2} className="padding10">
                          <Typography className="labelTextService">Make</Typography>
                          <CustomSelect
                            list={this.state.listMake}
                            listSelected={this.state.selectedMake}
                            handleSelect={this.handleSelectMake}
                            closeCalendar={this.handleCloseCalendar}
                            handleDeleteSelected={this.handleDeleteSelectedMake} />
                        </Grid>
                        <Grid item md={2} className="padding10">
                          <Typography className="labelTextService">Model</Typography>
                          <CustomSelect
                            list={this.state.listModel}
                            listSelected={this.state.selectedModel}
                            notModel={this.state.selectedMake.length === 0}
                            handleSelect={this.handleSelectModel}
                            closeCalendar={this.handleCloseCalendar}
                            handleDeleteSelected={this.handleDeleteSelectedModel} />
                        </Grid>
                        <Grid item md={2} className="padding10">
                          <Typography className="labelTextService">Price</Typography>
                          <CustomSelect
                            list={priceValueList}
                            selected={this.state.price}
                            handleSelect={this.handleSelectPrice}
                            closeCalendar={this.handleCloseCalendar}
                            handleDeleteSelected={this.handleDeleteSelectedPrice} />
                        </Grid>
                        <Grid item md={2} className="padding10">
                          <Typography className="labelTextService">Engine cap</Typography>
                          <CustomSelect
                            list={engineCapList}
                            selected={this.state.engineCap}
                            handleSelect={this.handleSelectEngineCap}
                            closeCalendar={this.handleCloseCalendar}
                            handleDeleteSelected={this.handleDeleteSelectedEngineCap} />
                        </Grid>
                        <Grid item md={2} className="padding10">
                          <Typography className="labelTextService">Milage</Typography>
                          <CustomSelect
                            list={milageList}
                            selected={this.state.milage}
                            handleSelect={this.handleSelectMilage}
                            closeCalendar={this.handleCloseCalendar}
                            handleDeleteSelected={this.handleDeleteSelectedMilage} />
                        </Grid>
                        <Grid item md={2} className="padding10">
                          <Typography className="labelTextService">Vehicle Type</Typography>
                          <CustomSelect
                            list={vehicleTypeList}
                            listSelected={this.state.vehicleType}
                            handleSelect={this.handleSelectVehicleType}
                            closeCalendar={this.handleCloseCalendar}
                            handleDeleteSelected={this.handleDeleteSelectedVehicleType} />
                        </Grid>
                      </Grid>
                    </>
                  }

                </Grid>

                <Grid container style={{ marginBottom: '100px' }}>
                  <Grid item md={3} />
                  <Grid item container md={6} xs={12}>
                    <Grid item md={6} xs={12} className="padding10">
                      <Button className="btnWrapCancel" data-test-id='btnCancel' onClick={this.handleCancel}>
                        <Typography className="btnTextCancel">Cancel</Typography>
                      </Button>
                    </Grid>
                    <Grid item md={6} xs={12} className="padding10">
                      {this.state.isEdit ?
                        <Button data-test-id='btnSubmit' className="btnWrapAdd" type="submit" onClick={this.checkSubmit}>
                          <Typography className="btnTextAdd">Update Customer</Typography>
                        </Button> :
                        <Button data-test-id='btnSubmit' className="btnWrapAdd" type="submit" onClick={this.checkSubmit}>
                          <Typography className="btnTextAdd">Add New Customer</Typography>
                        </Button>}

                    </Grid>
                  </Grid>
                  <Grid item md={3} />
                </Grid>
              </form>)
          }
          }
        </Formik>
      </NewCustomerStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const NewCustomerStyle = styled(Box)(({ theme }) => ({

  '& .customButton': {
    display: 'inline-flex',
    height: '3.4rem',
    width: "100%",
    marginTop: ".3rem",
    padding: '6px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '6px',
    flexShrink: 0,
    borderRadius: '8px',
    border: `1px solid ${(theme as any).typography.border}`,
    background: (theme as any).typography.body2,
    position: 'relative',
    color: (theme as any).typography.h2 ,
    zIndex: 1, /* Ensure button is above the arrow */
  },
  '& .customButtonPLACEHOLDER': {
    display: 'inline-flex',
    height: '3.4rem',
    width: "100%",
    marginTop: ".3rem",
    padding: '6px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '6px',
    flexShrink: 0,
    borderRadius: '8px',
    border: `1px solid ${(theme as any).typography.border}`,
    background: (theme as any).typography.body2,
    position: 'relative',
    color: "gray" ,
    zIndex: 1, /* Ensure button is above the arrow */
  },
  '& .customCalendarPop': {
    border: `1px solid ${(theme as any).typography.border}`,
    background: (theme as any).typography.body2,
    marginTop: '1rem',
    color: (theme as any).typography.h1,
    // Add any other custom styles as needed
    display: 'inline-flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    borderRadius: '8px',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    position: "absolute",
    left: "1rem",
    top: "-1rem",
    zIndex: 4,
  },
  '@media (max-width: 480px)': {


    '& .customCalendarPop': {
        padding: 0,
        fontSize: 12,
    },

    "& .rangeCalendar ": {
        top: "125%",


        left: "-12.3rem",
        width: "270px",
        position: "absolute",
    },
    // Add more styles as needed for small screens
},

  '& .react-calendar__tile--now': {
    background: (theme as any).typography.body1,
    opacity: 9,
  },

  '& .date': {
    fontFamily: "Outfit",
    fontSize: 16,
    color: (theme as any).typography.h2,
    fontWeight:400
  },

  '& .padding30': {
    padding: "20px",
  },

  '& .react-calendar__tile': {
    
  },
  '& .react-calendar__month-view__weekdays__weekday': {
    color: (theme as any).typography.h1,

  },
  '& .padding10': {
    padding: "10px",
  },
  '& .formikWrap': {
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    minHeight: '100vh'
  },

  '& .container': {
    flex: 1,
    alignContent: 'start',
  },
  '& .labelText': {
    color: (theme as any).typography.h1,
    fontSize: '14px',
    fontFamily: 'Outfit',
    fontWeight: '700',
  },
  '& .labelTextService': {
    color: (theme as any).typography.h2,
    fontSize: '14px',
    fontFamily: 'Outfit',
    fontWeight: '500',
  },
  '& .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus': {
    background: (theme as any).typography.body1,
    color: `${(theme as any).typography.h1} !important`,
  },
  '& .inputField': {
    marginTop: '4px',
    borderRadius: 8,
    border: `1px ${(theme as any).typography.border} solid`,
    background: (theme as any).typography.body2,
    padding: '10px 8px',
    color: (theme as any).typography.h2,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  '& .inputField ::placeholder': {
    opacity:1
  },
  "& .react-calendar__navigation__next2-button": {
    display: "none"
  },
  '& .inputFieldServices': {
    marginTop: '4px',
    borderRadius: 8,
    border: `1px ${(theme as any).typography.border} solid`,
    background: (theme as any).typography.body2,
    padding: '10px 8px',
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    height: "32px",
  },
  '& .MuiInputBase-input': {
    color: (theme as any).typography.h2,
    fontFamily: 'Outfit',
    fontWeight: 400,
    fontSize: 16,
  },
  '& .react-calendar__tile--hover': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%)) !important',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important"
    }
  },
  '& .searchText': {
    color: (theme as any).typography.h2,
    fontFamily: 'Outfit',
    fontWeight: 400,
    fontSize: 16,
  },
  '& .react-calendar__navigation__prev2-button  ': { display: "none" },
  "& .btnWrapAdd": {
    cursor: 'pointer',
    padding: "16px 0 16px 0",
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)',
    borderRadius: '8px',
    overflow: 'hidden',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
  },
  "& .btnWrapCancel": {
    cursor: 'pointer',
    padding: "16px 0 16px 0",
    background: (theme as any).typography.body2,
    borderRadius: '8px',
    overflow: 'hidden',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
  },
  '& .btnTextCancel': {
    textTransform: 'none',
    color: '#CEAE71',
    fontSize: '16px',
    fontFamily: 'Outfit',
    fontWeight: '700',
  },
  '& .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus': {
    background: (theme as any).typography.body1,

  },
  '& .btnTextAdd': {
    textTransform: 'none',
    color: '#171717',
    fontSize: '16px',
    fontFamily: 'Outfit',
    fontWeight: '700',
  },
  '& .react-calendar, .react-calendar *, .react-calendar ::before, .react-calendar ::after': {
    boxSizing: 'border-box',
    color: (theme as any).typography.h1,
  },
  '& .Chipbasic': {
    height: 26,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 6,
    background: (theme as any).typography.body2,
    borderRadius: 16,
    overflow: 'hidden',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'inline-flex',
    marginBottom: 8,
    width: 'fit-content'
  },
  '& .react-calendar__navigation': {
    display: 'flex',
    height: '44px',
    marginBottom: '1em',

    width: '100%',
    color: (theme as any).typography.h1,
  },
  '& .Textlabel': {
    textAlign: 'right',
    color: (theme as any).typography.h1,
    fontSize: 12,
    fontFamily: 'Outfit',
    fontWeight: '400',
    marginRight: 8
  },
  '& .ServiceLabel': {
    color: (theme as any).typography.h1,
    fontSize: 20,
    fontFamily: 'Outfit',
    fontWeight: '700',
  },
  '& .selectedService': {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column'
  },
  "& .errorsMsg": {
    color: "#F87171",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  '& .react-calendar__tile--active': {


    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important",
    },
    borderRadius: '0', /* Reset border radius for all tiles */
    '&:first-of-type': {
      borderTopLeftRadius: '35px',
      borderBottomLeftRadius: '35px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '35px',
      borderBottomRightRadius: '35px',
    },

  },
  '& .errorInput': {
    marginTop: '4px',
    borderRadius: 8,
    background: (theme as any).typography.body2,
    padding: '10px 8px',
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    border: "1px solid #F87171",
  },

  '& .textSelect': {
    cursor: 'pointer',
    color: (theme as any).typography.h1,
    fontSize: 12,
    fontFamily: 'Outfit',
    fontWeight: '400',
  },
  '& .react-calendar__navigation button:enabled:focus': {
    background: (theme as any).typography.body1,

  },
  '& .customButtonError': {
    display: 'inline-flex',
    height: '3.4rem',
    width: "100%",
    marginTop: ".3rem",
    padding: '6px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '6px',
    flexShrink: 0,
    borderRadius: '8px',
    border: "1px solid #F87171",
    background: (theme as any).typography.body2,
    position: 'relative',
    color: (theme as any).typography.h2 ,
    zIndex: 1, 
  },
  '& .react-calendar__tile--hasActive': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important",
    },
    borderRadius: '0', /* Reset border radius for all tiles */
    '&:last-of-type': {
      borderTopRightRadius: '35px',
      borderBottomRightRadius: '35px',
    },
    '&:first-of-type': {
      borderTopLeftRadius: '35px',
      borderBottomLeftRadius: '35px',
    },
  },
}))
export { NewCustomerCRM }

withWidth()(NewCustomerCRM);
// Customizable Area End