import React, {  } from "react";

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    BlobProvider,
    Image,
    Font
} from "@react-pdf/renderer";
import moment from "moment";

type Props = {
    isSave: boolean;
    setPdf: any;
    data: any;
};

const styles = StyleSheet.create({
    text: {
      fontFamily: 'Regular', 
      fontSize:7,
    },
    text6pxNormal: {
        fontFamily: 'Regular', 
        fontSize:6,
      },
    text8px: {
        fontFamily: 'Bold', 
        fontSize:8,
      },
    textBold: {
        fontFamily: 'Bold', 
        fontSize:7,
      },
      textBold6pxNormal: {
        fontFamily: 'Bold', 
        fontSize:6,
      },
    r78: {
      fontFamily: 'Bold',
      fontSize:14,
    },
  });
  
Font.register({
    family: 'Bold',
    src: require('./fonts/helvetica_bold.ttf'),
    fontWeight: 'bold',
});

Font.register({
    family: 'Regular',
    src: require('./fonts/helvetica_regular.ttf'),
    fontWeight: 'normal',
});



const pStyles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff",
        padding: '0px 28.5px',
        letterSpacing: 0
    },

});
 
const Doc1 = ({data, dob, dobGuarantor}: any)=>(<>
    <Document>
        <Page size="A4" style={pStyles.page}>
            <View style={{flexDirection:'row', display:'flex', alignItems:'flex-end', justifyContent:'space-between', marginTop:37.03}}>
                <Image src={require("../src/DBS.png")} style={{width:89.48, height:32}}/>
                <View style={{alignItems:'flex-end', display:'flex'}}>
                    <View style={{border:'1px solid #000', borderRadius:100, width:29.77, height:29.77, justifyContent:'center', alignItems:'center'}}>
                    <Text style={styles.r78}>R78</Text>
                </View>
                <Text style={styles.r78}>Auto Loans</Text>
            </View>
                
            </View>
            <View style={{border:'1px solid #000', padding: "1.19px 1.19px 4px 2.71px",  marginTop:12.36}}>
                <Text style={styles.text}>
                    <Text style={styles.textBold}>Access to credit report:</Text> You may obtain a free credit report within 30 calendar days from the date of approval or rejection of this application. There are two ways to obtain
a free credit report. You can go to the credit bureau website listed below or bring your approval or rejection letter and your original NRIC to the credit bureau’s registered
office. Credit Bureau (Singapore) Pte Ltd is located at 2 Shenton Way #20-02 SGX Centre 1 Singapore 068804 Tel: (65) 6565 6363 Website: www.creditbureau.com.sg
                </Text>
            </View>
            <View style={{marginTop:3,border:'1px solid #000', padding: "6.05px 4.79px", flexDirection:'row', justifyContent:'space-between'}}>
                <View style={{width:240}}>
                    <Text style={styles.textBold}>Please enclose the following documents with your application:</Text>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <Text style={styles.text}>•     </Text>
                        <Text style={styles.text}>Copy of your NRIC (front and back) or passport with validity of more than 6 months</Text>
                    </View>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <Text style={styles.text}>•     </Text>
                        <Text style={styles.text}>Proof of income (refer to the right)</Text>
                    </View>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <Text style={styles.text}>•     </Text>
                        <Text style={styles.text}>Copy of Vehicle Sales & Purchase Agreement</Text>
                    </View>
                    <Text style={styles.textBold}>Additional documents to be submitted:</Text>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <Text style={styles.text}>☐     </Text>
                        <Text style={styles.text}>For foreigners: Copy of Work Pass with validity of more than 6 months</Text>
                    </View>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <Text style={styles.text}>☐     </Text>
                        <Text style={styles.text}>For used vehicle purchase: Copy of LTA Acknowledgement</Text>
                    </View>
                    <Text style={styles.textBold}>If you have rental income:</Text>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <Text style={styles.text}>☐     </Text>
                        <Text style={styles.text}>Latest Income Tax Notice of Assessment OR</Text>
                    </View>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <Text style={styles.text}>☐     </Text>
                        <Text style={styles.text}>Copy of signed Tenancy Agreement</Text>
                    </View>
                </View>
                <View style={{width:256}}>
                    <Text style={styles.textBold}>Proof of income:</Text>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <Text style={styles.text}>•     </Text>
                        <Text style={styles.text}>For salaried employee:</Text>
                    </View>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <Text style={styles.text}>      </Text>
                        <Text style={styles.text}>☐ Latest computerised payslip OR</Text>
                    </View>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <Text style={styles.text}>      </Text>
                        <Text style={styles.text}>☐ Last 3 months’ CPF Contribution History OR</Text>
                    </View>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <Text style={styles.text}>      </Text>
                        <Text style={styles.text}>☐ Latest Income Tax Notice of Assessment</Text>
                    </View>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <Text style={styles.text}>•     </Text>
                        <Text style={styles.text}>For self-employed and commission earners</Text>
                    </View>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <Text style={styles.text}>      </Text>
                        <Text style={styles.text}>☐ Latest 2years’ Income Tax Notice of Assessment</Text>
                    </View>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <Text style={styles.text}>•     </Text>
                        <Text style={styles.text}>For Companies:</Text>
                    </View>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <Text style={styles.text}>      </Text>
                        <Text style={styles.text}>☐ Memorandum and Articles of Association and Bank Statements for the
             last 3 months (if operating account is not maintained with DBS)</Text>
                    </View>
                    <Text style={styles.text}>You can now print your Notices of Assessment at myTax Portal with your SingPass
or IRAS PIN</Text>
                </View>
                
            </View>
            <View style={{borderBottom: '1px solid black', 
                    borderRight: '1px solid black',
                    borderLeft: '1px solid black', padding: '2.39px 12.08px', flexDirection:'row'}}>
                <Text style={styles.text8px}>Please indicate:</Text>                
                <Text style={{...styles.text8px, marginLeft:100}}><Text style={styles.text}>❑</Text> OMV ≤ S$20,000, max LTV 70%</Text>
                <Text style={{...styles.text8px, marginLeft:50}}><Text style={styles.text}>❑</Text> OMV {">"} S$20,000, max LTV 60%</Text>
            </View>

            <View style={{border:'1px solid #000', marginTop:5.02}}>
                <View style={{backgroundColor:'#ff0101', width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                    <Text style={{fontFamily:'Bold', fontSize:8, color:'#fff', alignSelf:'center'}}>PERSONAL PARTICULARS</Text>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000', justifyContent:'center',alignItems:'center',width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <Text style={styles.textBold}>Main Applicant</Text>
                    </View>
                    <View style={{flexDirection:'row',width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold}><Text style={styles.text}>❑</Text> Joint Applicant</Text>
                        </View>
                        <View style={{width:'100%'}}>
                            {data.guarantor[0] && <Text style={{position:'absolute', ...styles.text}}>v</Text>}<Text style={styles.textBold}><Text style={styles.text}>❑</Text> Guarantor (please tick one)</Text>
                        </View>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000',width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Salutation</Text>
                            <Text style={styles.text6pxNormal}>☐  Dr   ☐  Mr    ☐  Mdm    ☐  Mrs    ☐  Ms</Text>
                        </View>
                        <View style={{width:'100%'}}>
                        <Text style={styles.textBold6pxNormal}>Marital Status</Text>
                            <Text style={styles.text6pxNormal}>☐  Single   ☐  Married    ☐  Others</Text>
                        </View>
                    </View>

                    <View style={{width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Salutation</Text>
                            <Text style={styles.text6pxNormal}>☐  Dr   ☐  Mr    ☐  Mdm    ☐  Mrs    ☐  Ms</Text>
                        </View>
                        <View style={{width:'100%'}}>
                        <Text style={styles.textBold6pxNormal}>Marital Status</Text>
                            <Text style={styles.text6pxNormal}>☐  Single   ☐  Married    ☐  Others</Text>
                        </View>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000',flexDirection:'column',width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Full Name as in NRIC/Passport (underline surname) / ACRA for Companies </Text>
                        </View>
                        <View style={{width:'100%'}}>
                            <Text style={styles.text}>{data.fullName}</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'column',width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Full Name as in NRIC/Passport (underline surname)</Text>
                        </View>
                        <View style={{width:'100%'}}>
                            <Text style={styles.text}>{data.guarantor[0] && data.guarantor[0].fullNameGuarantor}</Text>
                        </View>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000',width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>NRIC/Passport No. /Company Reg. No.</Text>
                            <Text style={styles.text}>{data.NRIC}</Text>
                        </View>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Date of Birth (dd/mm/yyyy)</Text>
                            <Text style={styles.text}>{dob}</Text>
                        </View>
                    </View>

                    <View style={{width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>NRIC/Passport No.</Text>
                            <Text style={styles.text}>{data.guarantor[0] && data.guarantor[0].NRICGuarantor} </Text>
                        </View>
                        <View style={{width:'100%'}}>
                        <Text style={styles.textBold6pxNormal}>Date of Birth (dd/mm/yyyy)</Text>
                            <Text style={styles.text}>{dobGuarantor}</Text>
                        </View>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000',width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Nationality</Text>
                            <Text style={styles.text6pxNormal}>☐Singaporean/Singapore PR    ☐ Malaysian</Text>
                            <Text style={styles.text6pxNormal}>☐ Other Nationality _____________________</Text>
                        </View>
                        
                    </View>
                    <View style={{width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Nationality</Text>
                            <Text style={styles.text6pxNormal}>☐Singaporean/Singapore PR    ☐ Malaysian</Text>
                            <Text style={styles.text6pxNormal}>☐ Other Nationality _____________________</Text>
                        </View>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Relationship with Main Applicant</Text>
                            <View style={{flexDirection:'row',}}>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.text6pxNormal}>☐ Spouse</Text>
                                    <Text style={styles.text6pxNormal}>☐ Sibling</Text>
                                </View>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.text6pxNormal}>☐ Parent</Text>
                                    <Text style={styles.text6pxNormal}>☐ Children</Text>
                                </View>
                            </View>
                            
                        </View>
                    </View>
                    
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000',flexDirection:'column',width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Residential Address (P.O. Box,V-Box & C/O addresses are not allowed)</Text>
                        </View>
                        <View>
                            <Text style={styles.text}>{data.address}</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'column',width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Residential Address (P.O. Box,V-Box & C/O addresses are not allowed)</Text>
                        </View>
                        <View>
                            <Text style={styles.text}>{data.guarantor[0] && data.guarantor[0].addressGuarantor} </Text>
                        </View>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000',flexDirection:'row',width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Mailing Address (if different from above)</Text>
                        </View>
                        <View>
                            <Text>  </Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Mailing Address (if different from above)</Text>
                        </View>
                        
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000',width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <View style={{flexDirection:'row',}}>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.text6pxNormal}>Mobile No.:   {data.contactNumber}</Text>
                                    <Text style={styles.text6pxNormal}></Text>
                                </View>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.text6pxNormal}>Home/Office No.</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        
                        <View style={{width:'100%'}}>
                            <View style={{flexDirection:'row',}}>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.text6pxNormal}>Mobile No.: {data.guarantor[0] && data.guarantor[0].contactNumberGuarantor} </Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                </View>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.text6pxNormal}>Home/Office No.</Text>
                                </View>
                            </View>
                            
                        </View>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000',width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <View style={{flexDirection:'row',}}>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.text6pxNormal}>Email Address:</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>{data.emailID}</Text>
                                </View>
                                
                            </View>
                        </View>
                    </View>
                    <View style={{width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        
                        <View style={{width:'100%'}}>
                            <View style={{flexDirection:'row',}}>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.text6pxNormal}>Email Address:</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>{data.guarantor[0] && data.guarantor[0].emailIDGuarantor} </Text>
                                </View>
                                
                            </View>
                            
                        </View>
                    </View>
                </View>
                <View style={{backgroundColor:'#ff0101', width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                    <Text style={{fontFamily:'Bold', fontSize:8, color:'#fff', alignSelf:'center'}}>EMPLOYMENT DETAILS</Text>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000', justifyContent:'space-between',alignItems:'center',width:'100%', padding: "1.19px 1.19px 4px 2.71px", flexDirection:'row'}}>
                        <Text style={styles.textBold6pxNormal}>Employment Status</Text>
                        <Text style={styles.text6pxNormal}>☐ Employee</Text>
                        <Text style={styles.text6pxNormal}>☐ Self-Employed</Text>
                        <Text style={styles.text6pxNormal}>☐ Others ______________</Text>
                    </View>
                    <View style={{justifyContent:'space-between',alignItems:'center',width:'100%', padding: "1.19px 1.19px 4px 2.71px", flexDirection:'row'}}>
                        <Text style={styles.textBold6pxNormal}>Employment Status</Text>
                        <Text style={styles.text6pxNormal}>☐ Employee</Text>
                        <Text style={styles.text6pxNormal}>☐ Self-Employed</Text>
                        <Text style={styles.text6pxNormal}>☐ Others ______________</Text>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000', alignItems:'flex-start',width:'100%', padding: "1.19px 1.19px 4px 2.71px", flexDirection:'column'}}>
                        <Text style={styles.textBold6pxNormal}>Company Name & Address</Text>
                        <Text style={styles.text6pxNormal}>{data.employer }</Text>
                        <Text style={styles.text6pxNormal}>{data.employmentAddress} </Text>
                    </View>
                    <View style={{ alignItems:'flex-start',width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <Text style={styles.textBold6pxNormal}>Company Name & Address</Text>
                        <Text style={styles.text6pxNormal}>{data.guarantor[0] && data.guarantor[0].employerGuarantor} </Text>
                        <Text style={styles.text6pxNormal}>{data.guarantor[0] && data.guarantor[0].employmentAddressGuarantor} </Text>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000',width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Occupation</Text>
                            <Text style={styles.text6pxNormal}>{data.jobTitle}</Text>
                        </View>
                        <View style={{width:'100%',}}>
                            <Text style={styles.textBold6pxNormal}>Length of Service</Text>
                            <Text style={{...styles.text6pxNormal, alignSelf:'flex-end'}}>yrs                  mths</Text>
                        </View>
                    </View>

                    <View style={{width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Occupation</Text>
                            <Text style={styles.text6pxNormal}>{data.guarantor[0] && data.guarantor[0].jobTitleGuarantor}  </Text>
                        </View>
                        <View style={{width:'100%',}}>
                            <Text style={styles.textBold6pxNormal}>Length of Service</Text>
                            <Text style={{...styles.text6pxNormal, alignSelf:'flex-end'}}>yrs                  mths</Text>
                        </View>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000',width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Monthly Fixed Income</Text>
                            <Text style={styles.text6pxNormal}>S$ {data.grossMonthlySalary}</Text>
                        </View>
                        <View style={{width:'100%',}}>
                            <Text style={styles.textBold6pxNormal}>Other Monthly Income & Source</Text>
                            <Text style={styles.text6pxNormal}>S$_____________/____________________</Text>
                        </View>
                    </View>

                    <View style={{width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Monthly Fixed Income</Text>
                            <Text style={styles.text6pxNormal}>S$ {data.guarantor[0] && data.guarantor[0].grossMonthlySalaryGuarantor} </Text>
                        </View>
                        <View style={{width:'100%',}}>
                            <Text style={styles.textBold6pxNormal}>Other Monthly Income & Source</Text>
                            <Text style={styles.text6pxNormal}>S$_____________/____________________</Text>
                        </View>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000',width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Salary-crediting to DBS/POSB Account</Text>
                        </View>
                        
                    </View>

                    <View style={{width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%', flexDirection:'row'}}>
                            <Text style={{...styles.textBold6pxNormal, width:'50%'}}>Salary-crediting to DBS/POSB Account</Text>
                            <Text style={{...styles.text6pxNormal, width:'50%'}}>☐ Yes              ☐ No</Text>
                        </View>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000',width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Name of Previous Employer / Business <Text style={styles.text6pxNormal}>(if current employment is less than 1 year)</Text></Text>
                            <Text style={styles.text6pxNormal}> </Text>
                        </View>
                        
                    </View>

                    <View style={{width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Name of Previous Employer / Business <Text style={styles.text6pxNormal}>(if current employment is less than 1 year)</Text></Text>
                            <Text style={styles.text6pxNormal}> </Text>
                        </View>
                        
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000',width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Previous Occupation</Text>
                            <Text style={styles.text6pxNormal}> </Text>
                        </View>
                        <View style={{width:'100%',}}>
                            <Text style={styles.textBold6pxNormal}>Length of Service</Text>
                            <Text style={styles.text6pxNormal}> </Text>
                            <Text style={{...styles.text6pxNormal, alignSelf:'flex-end'}}>yrs                  mths</Text>
                        </View>
                    </View>

                    <View style={{width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Previous Occupation</Text>
                            <Text style={styles.text6pxNormal}> </Text>
                        </View>
                        <View style={{width:'100%',}}>
                            <Text style={styles.textBold6pxNormal}>Length of Service</Text>
                            <Text style={styles.text6pxNormal}> </Text>
                            <Text style={{...styles.text6pxNormal, alignSelf:'flex-end'}}>yrs                  mths</Text>
                        </View>
                    </View>
                </View>
                <View style={{backgroundColor:'#ff0101', width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                    <Text style={{fontFamily:'Bold', fontSize:8, color:'#fff', alignSelf:'center'}}>FINANCIAL COMMITMENTS</Text>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000',width:'100%',padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <View style={{width:'100%', flexDirection:'row'}}>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.textBold6pxNormal}>Type  Facility (Housing Loan, Car Loan, etc.)</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>1.______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>2.______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>3.______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>4.______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                </View>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.textBold6pxNormal}>Financial Institution</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                </View>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.textBold6pxNormal}>Monthly Repayment</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                </View>
                            </View>
                            
                            <View style={{width:'100%', flexDirection:'row'}}>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.textBold6pxNormal}>Trading-in existing car?</Text>
                                </View>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.text6pxNormal}>☐ Yes             ☐ No</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={{width:'100%',padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <View style={{width:'100%', flexDirection:'row'}}>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.textBold6pxNormal}>Type  Facility (Housing Loan, Car Loan, etc.)</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>1.______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>2.______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>3.______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>4.______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                </View>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.textBold6pxNormal}>Financial Institution</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                </View>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.textBold6pxNormal}>Monthly Repayment</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                    <Text style={styles.text6pxNormal}>______________________</Text>
                                    <Text style={styles.text6pxNormal}> </Text>
                                </View>
                            </View>
                            
                            <View style={{width:'100%', flexDirection:'row'}}>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.textBold6pxNormal}>Trading-in existing car?</Text>
                                </View>
                                <View style={{width:'100%'}}>
                                    <Text style={styles.text6pxNormal}>☐ Yes             ☐ No</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    
                </View>
                <View style={{backgroundColor:'#ff0101', width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                    <Text style={{fontFamily:'Bold', fontSize:8, color:'#fff', alignSelf:'center'}}>VEHICLE INFORMATION</Text>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000', justifyContent:'space-between',alignItems:'center',width:'100%', padding: "1.19px 1.19px 4px 2.71px", flexDirection:'row'}}>
                        <Text style={styles.textBold6pxNormal}>Vehicle Type:</Text>
                        <Text style={styles.text6pxNormal}>☐ New Car</Text>
                        <View>
                        <Text style={styles.text6pxNormal}>☐ Used Car</Text>
                        <Text style={{...styles.text6pxNormal, position:'absolute'}}>x</Text>
                        </View>
                        
                        <Text style={styles.text6pxNormal}>☐ Light Commercial Vehicle</Text>
                    </View>
                    <View style={{justifyContent:'space-between',alignItems:'center',width:'100%', padding: "1.19px 1.19px 4px 2.71px", flexDirection:'row'}}>
                        <Text style={styles.text6pxNormal}>If you are refinancing your used vehicle, please state:</Text>
                    </View>
                </View>
                <View style={{flexDirection:'row'}}>
                    <View style={{borderRight:'1px solid #000',width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Purchase Price S$</Text>
                            <Text style={styles.text}> </Text>
                        </View>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Make/ Model</Text>
                            <Text style={styles.text6pxNormal}> </Text>
                            <Text style={styles.text6pxNormal}>{data.vehicleMake}/{data.vehicleModel}</Text>
                        </View>
                    </View>

                    <View style={{width:'100%', flexDirection:'row', padding: "1.19px 1.19px 4px 2.71px"}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Financing Institution</Text>
                            <Text style={styles.text6pxNormal}> </Text>
                        </View>
                        <View style={{width:'100%'}}>
                        <Text style={styles.textBold6pxNormal}>First Disbursement Date <Text style={styles.text6pxNormal}>(dd/mm/yyyy)</Text></Text>
                            <Text style={styles.text6pxNormal}> </Text>
                            <Text style={styles.text6pxNormal}> </Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                <Text style={styles.text6pxNormal}># Rates are subject to change from time to time</Text>
                <Text style={styles.text6pxNormal}>Jun 2016</Text>
            </View>
        </Page>
        <Page size="A4" style={pStyles.page}>
            <View style={{border:'1px solid #000', marginTop:25.13}}>
                <View style={{backgroundColor:'#ff0101', width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                    <Text style={{fontFamily:'Bold', fontSize:8, color:'#fff', alignSelf:'center'}}>LOAN SERVICING ACCOUNT</Text>
                </View>
                <View style={{padding: '12.51px 7.46px 3.49px 5.88px '}}>
                    <Text style={styles.text6pxNormal}>Please debit my DBS/POSB Account No.  _______________________________________________  for my monthly repayments and all other sums due and owing once application is approved
(Note: POSB Current Account and Joint-all Account for single application are not applicable.)</Text>
                </View>
                <View style={{backgroundColor:'#ff0101', width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                    <Text style={{fontFamily:'Bold', fontSize:8, color:'#fff', alignSelf:'center'}}>FINANCIAL REQUEST</Text>
                </View>
                <View style={{padding: '2.01px 7.46px 3.49px 5.88px'}}>
                    <View style={{flexDirection:'row'}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Amount Required S$</Text>
                        </View>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Repayment Period</Text>
                        </View>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}>Applied Interest Rate#</Text>
                        </View>
                    </View>
                    <Text style={styles.textBold6pxNormal}> </Text>
                    <View style={{flexDirection:'row'}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.textBold6pxNormal}> </Text>
                        </View>
                        <View style={{width:'100%'}}>
                            <Text style={styles.text6pxNormal}>{data.tenure} mths</Text>
                        </View>
                    </View>                    
                </View>
                <View style={{backgroundColor:'#ff0101', width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                    <Text style={{fontFamily:'Bold', fontSize:8, color:'#fff', alignSelf:'center'}}>BENEFITS & CREDIT FACILITIES RECEIVED</Text>
                </View>
                <View style={{padding: '2.01px 7.46px 3.49px 5.88px'}}>
                    <Text style={styles.text}>1.   Did you receive any discount, rebate or any other benefit from the vendor? (e.g. vehicle servicing/maintenance package, petrol/shopping vouchers,cash discount etc.).</Text>                   
                    <Text style={styles.text}>          Yes,The type of benefit(s) I/we received is/are___________________________________ and amount received/value of the item is S$ .______________________ </Text>
                    <Text style={styles.text}>          No, I/we did not receive any benefit(s).Did you obtain any other credit facility for the purchase of the motor vehicle? </Text>
                    <Text style={styles.text}>2.   Did you obtain any other credit facility for the purchase of the motor vehicle? (e.g. credit card, credit line, overdraft, personal loan etc.) </Text>
                    <Text style={styles.text}>          Yes, the type of credit facility(ies) I/we received is/are __________________________________________and amount received is S$ ._______________________</Text>
                    <Text style={styles.text}>          No, I/we did not obtain any credit facility.</Text>
                </View>
                <View style={{backgroundColor:'#ff0101', width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                    <Text style={{fontFamily:'Bold', fontSize:8, color:'#fff', alignSelf:'center'}}>DECLARATION BY HIRER(S) / GUARANTOR</Text>
                </View>
                <View style={{padding: '5.99px 10.14px 3.49px 5.88px'}}>
                    <Text style={styles.textBold}>BY HIRER(S):</Text>
                    <Text style={styles.text}>1.    I am/We are not bound or affected by any legal impediment from entering into a Hire Purchase Agreement with you or from driving any vehicle. </Text> 
                    <Text style={styles.text}>2.    I/We declare that the information provided herein is true, complete and accurate. I/We shall immediately inform you if any of the information changes. </Text> 
                    <Text style={styles.text}>3.    I/We undertake to pay any and all out-of-pocket expenses and/or charges incurred in relation to my/our loan application. </Text> 
                    <Text style={styles.text}>4.    I/We understand that you reserve the right to decline the application without giving any reason(s) whatsoever. </Text> 
                    <Text style={styles.text}>5.    I/We undertake to provide you with documents in such form satisfactory to you that you may reasonably require for the purpose of this application, including where</Text> 
                    <Text style={styles.text}>       applicable to you, documents evidencing the date of the agreement to purchase/hire the vehicle, actual Open Market Value (OMV), the original registration date and    </Text> 
                    <Text style={styles.text}>       the first registration date of the vehicle, or in the case of refinancing or subsequent hire purchase facility, documents evidencing the fi rst credit facility or hire purchase</Text>                   
                    <Text style={styles.text}>       agreement (as the case may be). </Text> 
                    <Text style={styles.text}>6.    Where I/we am/are applying for a refinancing facility or subsequent hire purchase, I/we acknowledge that the total tenor under the fi rst credit facility or hire purchase</Text> 
                    <Text style={styles.text}>       agreement (as the case may be) and the subsequent credit facility or hire purchase agreement (as the case may be) cannot exceed 7 years. </Text> 
                    <Text style={styles.text}>7.    I/We authorise you, upon approval of this application to debit the monthly instalment amounts and all monies due and payable by me/us to you from such account</Text> 
                    <Text style={styles.text}>       as may be designated by me/us or any other account(s), which I/we maintain with you singly or jointly with any other person on a joint alternate basis, without prior </Text> 
                    <Text style={styles.text}> </Text> 
                    <Text style={styles.textBold}>BY HIRER(S) AND GUARANTOR:</Text> 
                    <Text style={styles.text}>8.    I/We authorise you to obtain and verify any information about me/us from any source and I/we consent to your disclosure to any third party, my/our personal data, this</Text> 
                    <Text style={styles.text}>       application, my/our accounts, credit facilities and affairs, you reasonably consider necessary for the purpose of this application. I/we agree that this includes you</Text> 
                    <Text style={styles.text}>       conducting checks on me/us with credit bureau, financial institutions and credit/charge card issuers, government bodies and/or my/our employer(s). </Text> 
                    <Text style={styles.text}>9.     Applicable where: </Text>
                    <Text style={styles.text}>       i) Purchase Price of Vehicle less COE is S$55,000 and below </Text> 
                    <Text style={styles.text}>        I/We acknowledge and confirm that before I/we sign this application form, I/we have pursuant to the provisions of the Hire Purchase Act, Cap.125 (the “Act”) received            </Text> 
                    <Text style={styles.text}>        a written statement from the dealer/supplier, or its representatives (“Dealer”), which contains the items set out in the Second Schedule to the Act and have read and </Text> 
                    <Text style={styles.text}>        agree to be bound by the terms therein which have been explained to me/us by the Dealer. I/We further acknowledge that I/we have read and agree to be bound by  </Text>
                    <Text style={styles.text}>        the terms in this application form and in the Hire Purchase Agreement. </Text> 
                    <Text style={styles.text}>        ii)    Purchase Price of Vehicle less COE is above S$55,000</Text> 
                    <Text style={styles.text}>        I/We acknowledge that I/we have read and agree to be bound by the terms in this application form and in the Hire Purchase Agreement. </Text> 
                    <Text style={styles.text}>10. I/We authorize you (i) to disclose any information provided by me/us in relation to this application, or (ii) to provide this application form duly filled and signed by me/         </Text>
                    <Text style={styles.text}>        us, to any car dealership or such other third party that you in good faith deem it reasonable to make such disclosure for any purposes in connection with this     </Text> 
                    <Text style={styles.text}>        application and the loan, or as may be required by any applicable law, court, regulatory or legal process, without any liability or notice to me/us. </Text> 
                    <Text style={styles.text}>11. If this application and such other form or instruction (“Instruction”) is or is purported to be given or sent by me/us to you by electronic or facsimile transmission, you are  </Text> 
                    <Text style={styles.text}>        hereby authorized by me/us, but are not obliged to accept, rely upon and act in accordance with the electronic or facsimile copy of the Instruction, without waiting for </Text>
                    <Text style={styles.text}>        the original Instruction and without any liability to me/us. In consideration of you agreeing to act on my/our authorization, I/we undertake to indemnify you against all   </Text> 
                    <Text style={styles.text}>        liabilities, claims, demands, actions, proceedings, losses, damages, costs (including legal costs on a full indemnity basis), expenses and all other liabilities which you </Text> 
                    <Text style={styles.text}>        may directly or indirectly suffer. </Text> 
                    <Text style={styles.text}>12. If there is a cancellation or termination of the loan after the loan is disbursed or if the loan amount is settled in full before the end of the loan tenor, I/we acknowledge</Text>
                    <Text style={styles.text}>that the fees as stated in the Hire Purchase Agreement will be payable by me/us. </Text> 
                    <Text style={styles.text}>13. I/We consent under the Personal Data Protection Act (Cap 26 of 2012) to the collection, use and disclosure of my/our personal data by/to DBS and such other third party as DBS may reasonably consider necessary for the purpose of the application, and confirm that I/we agree to be bound by the terms of the DBS Privacy Policy, a copy of which can be found on www.dbs.com/privacy. </Text> 
                    <Text style={styles.text}>14. I/We am/are the borrower(s) of the loan and shall only use the loan for legal purposes. </Text> 
                    <Text style={styles.text}>15. I/We am/are aware that the Dealer, through whom this application was given to me/us or by whom the vehicle is to be/was supplied, is not an agent of the Bank for any purpose. The Bank shall not be liable for any acts and/or omissions made by any such person as aforesaid.</Text>
                    <Text style={styles.text}> </Text>
                    <Text style={styles.textBold}>BY GUARANTOR:</Text> 
                    <Text style={styles.text}>I agree to be the guarantor under this hire purchase facility and shall execute a guarantee and/or such other document(s) in such form acceptable to you.</Text> 
                    <Text style={styles.text}></Text> 
                </View>
                <View style={{backgroundColor:'#ff0101', width:'100%', padding: "1.19px 1.19px 4px 2.71px"}}>
                    <Text style={{fontFamily:'Bold', fontSize:8, color:'#fff', alignSelf:'center'}}>DECLARATION</Text>
                </View>
                <View style={{padding: '5.99px 10.14px 3.49px 5.88px'}}>
                    <Text style={styles.text6pxNormal}>By signing below, I/we confirm that I/we have read, understood and agree to be bound by the terms herein.</Text>
                    <View style={{width:'100%',marginTop:'56px', flexDirection:'row'}}>
                        <View style={{width:'100%'}}>
                            <Text style={styles.text}>__________________________</Text>
                            <Text style={styles.text6pxNormal}>Signature of Main Applicant & Date</Text>
                            <Text style={styles.text6pxNormal}>For Corporate application, please indicate name and NRIC/ Passport No. of authorised signatory</Text>
                        </View>
                        <View style={{width:'70%'}}>
                            <Text style={styles.text}>__________________________</Text>
                            <Text style={styles.text6pxNormal}>Signature of Joint Applicant/ Guarantor & Date</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                <Text style={styles.text6pxNormal}># Rates are subject to change from time to time</Text>
                <Text style={styles.text6pxNormal}>Jun 2016</Text>
            </View>
        </Page>
    </Document>
</>)

const DBSPdf = React.memo(({ isSave, setPdf, data }:Props) => {
    return (
        <BlobProvider document={isSave? <Doc1 data={data} dob={moment(data.dob,'YYYY-MM-DD').format('DD/MM/YYYY')} dobGuarantor={moment(data.guarantor[0]?.dobGuarantor,'YYYY-MM-DD').format('DD/MM/YYYY')}/>:<></>} >
            {({blob})=>{
                    let file;
                    file = blob && new File([blob as Blob], 'dbs.pdf');
                    data && setPdf(file)
                
                return <></>;
            }}
            
        </BlobProvider>
    )
  });

export default DBSPdf;