import { Box, Input, InputAdornment, MenuItem, Select, Typography, makeStyles, styled } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import CustomStatus from "./CustomStatus";
import clsx from 'clsx';
import { error } from "console";

interface Props {
  value: string,
  placeholder: string,
  searchable?: boolean,
  onSelect?: (e: any) => void,
  isVehicle?: boolean,
  isFullName?: boolean,
  testId?: string,
  onChange?: (e: any)=>void,
  isTenure?: boolean;
  isBank?: boolean;
  isError?: any;
  makeList?: any;
  modelList?: any;
  isModel?: boolean;
  governmentList?: any;
  name?: any;
  SearchableList?:any;
  isLoading?:boolean,
  handelclickchange?:any,
  listSearchVehicle?: any;
  listSearchCustomer?: [];
  listBank?:any;
  closeCalendar?: any;
  isBroker?: boolean;
}


const CustomSelectSearchField = React.memo(({closeCalendar,
  value,listBank,handelclickchange,
  name, placeholder,isLoading,isBroker, SearchableList, searchable,onSelect, onChange,
  listSearchVehicle, listSearchCustomer, isVehicle, isFullName, isTenure, isBank, isError, testId, makeList, modelList,governmentList, isModel}: Props) => {
  const classes = useStyles();
  const [visibleSearchValue, setVisibleSearchValue] = useState(false);

  const handelonSelect = (val:any)=>{
    let postCaption =`Model: ${val?.attributes?.model}\nPrice: ${val?.attributes.selling_price}`
     
    handelclickchange("vehicleName",`${val?.attributes?.name} `);

    setTimeout(()=>{handelclickchange("postCaption",postCaption)},500)
    
  }
return <CustomSelectStyle>
    <Select
      data-testId={testId}
      className={clsx({ 'errorInput': isError, 'inputField': !isError },)}
      fullWidth
      name={name}
      displayEmpty
      type="date"
      disableUnderline
      onMouseDown={()=>{
        closeCalendar && closeCalendar();
      }}
      value={value}
      IconComponent={() => <ExpandMoreIcon style={{ color: "#f3f3f380", position: 'absolute', right: 0 }} />}
      MenuProps={{
        style: {
          marginTop: '20px',
          marginLeft: '-10px',
        },
        PaperProps: {
          style: {
            backgroundColor: '#3F3E3A',
            borderRadius: '8px',
            padding: '8px',
            fontFamily: 'Outfit'
          }
        },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
      renderValue={
        () => renderValue(value, placeholder)
      }
      >
        {isVehicle && <Input 
          data-testid={`inputId${testId}`}
          disableUnderline
          className="inputField"  
          onChange={(e)=>{
            setVisibleSearchValue(e.target.value !== '')
            onChange && onChange(e);
          }}    
          defaultValue={value}    
          value={value}                  
          style={{
            fontWeight: 400,
            display: "flex",
            height: "32px",
            padding: "10px 8px 10px 8px",
            justifyContent: "flex-end",
            alignItems: "center",
            alignSelf: "stretch",
            borderRadius: "8px",
            border: "1px solid #CBD5E1",
            background: "#2b2b2b",
            color: "#A6B0BF",
            fontFamily: "Outfit",
            fontSize: "15px",
            fontStyle: "normal",                             
          }}
          endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>}/> }
          
      {searchable && <Input
        data-testid={`inputId${testId}`}
        disableUnderline
        className="inputField"

        onChange={(e)=>{
          setVisibleSearchValue(e.target.value !== '')
          onChange && onChange(e);
        }} 
        defaultValue={value}
        value={value}
        style={{
          fontWeight: 400,
          display: "flex",
          height: "32px",
          padding: "10px 8px 10px 8px",
          justifyContent: "flex-end",
          alignItems: "center",
          alignSelf: "stretch",
          borderRadius: "8px",
          border: "1px solid #CBD5E1",
          background: "#2b2b2b",
          color: "#A6B0BF",
          fontFamily: "Outfit",
          fontSize: "15px",
          fontStyle: "normal",
        }}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>} />}

          {
        searchable && SearchableList && <Box className="wrapStatus" style={{ display: 'flex', flexDirection: 'column', gap: 20, paddingTop: 20 }}>
          {visibleSearchValue && 
               SearchableList?.data?.data?.map((val:any,index:any) => {
                return  <Typography onClick={()=>handelonSelect(val)} style={{ fontFamily: 'Outfit', fontSize: '16px', color: '#f3f3f3', fontWeight: 400, display: 'flex', flexDirection: 'row', gap: 10 }}>{val.attributes.name} <Typography style={{ fontFamily: 'Outfit', fontSize: '16px', color: '#f3f3f365', fontWeight: 400 }}>ID : {val.attributes.random_id}</Typography></Typography>
              })  
            }
           

        </Box>
      }
      {searchable && !SearchableList && <Box className="wrapStatus" style={{ display: 'flex', flexDirection: 'column', gap: 20, paddingTop: 20 }}>
        <Box data-testid="testIdDealValue" style={{ cursor: 'pointer' }} onClick={() => onSelect && onSelect({ target: { value: '0-10000' } })}>
          <Typography style={{ fontFamily: 'Outfit', fontSize: '16px', color: '#f3f3f3', fontWeight: 400, display: 'flex', flexDirection: 'row', gap: 10 }}>BMW X5 <Typography style={{ fontFamily: 'Outfit', fontSize: '16px', color: '#f3f3f365', fontWeight: 400 }}>ID : 123456</Typography></Typography>
        </Box>
        <Box style={{ cursor: 'pointer' }} onClick={() => onChange && onChange({ target: { value: '10001-20000' } })}>
          <Typography style={{ fontFamily: 'Outfit', fontSize: '12px', color: '#f3f3f3', fontWeight: 400 }}>10001-20000</Typography>
        </Box>
        <Box style={{ cursor: 'pointer' }} onClick={() => onChange && onChange({ target: { value: '20001-30000' } })}>
          <Typography style={{ fontFamily: 'Outfit', fontSize: '12px', color: '#f3f3f3', fontWeight: 400 }}>20001-30000</Typography>
        </Box>
        <Box style={{ cursor: 'pointer' }} onClick={() => onChange && onChange && onChange({ target: { value: '30001-40000' } })}>
          <Typography style={{ fontFamily: 'Outfit', fontSize: '12px', color: '#f3f3f3', fontWeight: 400 }}>30001-40000</Typography>
        </Box>
        <Box style={{ cursor: 'pointer' }} onClick={() => onChange && onChange({ target: { value: '40001-50000' } })}>
          <Typography style={{ fontFamily: 'Outfit', fontSize: '12px', color: '#f3f3f3', fontWeight: 400 }}>40001-50000</Typography>
        </Box>
        <Box style={{ cursor: 'pointer' }} onClick={() => onChange && onChange({ target: { value: '50001-60000' } })}>
          <Typography style={{ fontFamily: 'Outfit', fontSize: '12px', color: '#f3f3f3', fontWeight: 400 }}>50001-60000</Typography>
        </Box>
      </Box>}

          {isVehicle && <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, paddingTop:20}}>
            {visibleSearchValue && listSearchVehicle?.map((e:any)=><Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onSelect && onSelect(e)}>
              <Typography style={{fontFamily:'Outfit', fontSize:'16px', color:'#f3f3f3', fontWeight:400, display:'flex', flexDirection:'row', gap: 10}}>{e.attributes.name}<Typography style={{fontFamily:'Outfit', fontSize:'16px', color:'#f3f3f365', fontWeight:400}}>ID : {e.id}</Typography></Typography>
            </Box>)}
          </Box> }

          {isFullName && <Input 
          data-testid={`inputId${testId}`}
          disableUnderline
          className="inputField"  
          onChange={(e)=>{
            setVisibleSearchValue(e.target.value !== '')
            onChange && onChange(e);
          }}   
          defaultValue={value}    
          value={value}                  
          style={{
            fontWeight: 400,
            display: "flex",
            height: "32px",
            padding: "10px 8px 10px 8px",
            justifyContent: "flex-end",
            alignItems: "center",
            alignSelf: "stretch",
            borderRadius: "8px",
            border: "1px solid #CBD5E1",
            background: "#2b2b2b",
            color: "#A6B0BF",
            fontFamily: "Outfit",
            fontSize: "15px",
            fontStyle: "normal",                             
          }}
          endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>}/> }


          {isFullName && <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, paddingTop:20}}>
            {visibleSearchValue && listSearchCustomer?.map((e:any)=><Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onSelect && onSelect(e)}>
              <Typography style={{fontFamily:'Outfit', fontSize:'16px', color:'#f3f3f3', fontWeight:400, display:'flex', flexDirection:'row', gap: 10}}>{e.attributes.name}</Typography>
            </Box>)}
          </Box> }

          {isBroker && <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20}}>
            {listSearchCustomer?.map((e:any)=><Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onSelect && onSelect(e)}>
              <Typography style={{fontFamily:'Outfit', fontSize:'16px', color:'#f3f3f3', fontWeight:400, display:'flex', flexDirection:'row', gap: 10}}>{e.attributes.name}</Typography>
            </Box>)}
          </Box> }
          

          {isTenure && <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20}}>
            <Box data-testid="testIdDealValue" style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:'12 months'}})}>
              <Typography style={{fontFamily:'Outfit', fontSize:'16px', color:'#f3f3f3', fontWeight:400}}>12 months</Typography>
            </Box>
            <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:'24 months'}})}>
              <Typography style={{fontFamily:'Outfit', fontSize:'16px', color:'#f3f3f3', fontWeight:400}}>24 months</Typography>
            </Box>
            <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:'36 months'}})}>
              <Typography style={{fontFamily:'Outfit', fontSize:'16px', color:'#f3f3f3', fontWeight:400}}>36 months</Typography>
            </Box>
            <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:'48 months'}})}>
              <Typography style={{fontFamily:'Outfit', fontSize:'16px', color:'#f3f3f3', fontWeight:400}}>48 months</Typography>
            </Box>
            <Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:'60 months'}})}>
              <Typography style={{fontFamily:'Outfit', fontSize:'16px', color:'#f3f3f3', fontWeight:400}}>60 months</Typography>
            </Box>
          </Box> }

          {isBank && <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20}}>
            {listBank?.map((e:any)=><Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:e}})}>
              <Typography style={{fontFamily:'Outfit', fontSize:'16px', color:'#f3f3f3', fontWeight:400}}>{e.name}</Typography>
            </Box>)}
          </Box> }

          {makeList && <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, maxHeight:200, overflow:'auto'}}>
            {makeList.map((e: any)=><Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:e.attributes.name}})}>
              <Typography style={{fontFamily:'Outfit', fontSize:'16px', color:'#f3f3f3', fontWeight:400}}>{e.attributes.name}</Typography>
            </Box>)}
          </Box> }

          {governmentList && <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, maxHeight:200, overflow:'auto'}}>
            {governmentList.map((e: any)=><Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:e}})}>
              <Typography style={{fontFamily:'Outfit', fontSize:'16px', color:'#f3f3f3', fontWeight:400}}>{e.name}</Typography>
            </Box>)}
          </Box> }

          {modelList?.length>0  ?
        <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, maxHeight:200, overflow:'auto'}}>
            {modelList.map((e: any)=><Box style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:e.attributes.name}})}>
              <Typography style={{fontFamily:'Outfit', fontSize:'16px', color:'#f3f3f3', fontWeight:400}}>{e.attributes.name}</Typography>
            </Box>)}
          </Box>   : isModel ? <Typography style={{fontFamily:'Outfit', fontSize:'12px', color:'#f3f3f3', fontWeight:400}}>Select a carmake first</Typography>    :null
        } 
        
          
    </Select>
  </CustomSelectStyle>
})

const renderValue = (selected: any, message: any) => {
  if (selected !== "") {
    return <Typography className="searchText">{selected}</Typography>;
  }

  return <Typography className="searchText">{message}</Typography>;
};

const useStyles = makeStyles({
  select: {
    '& .textSelect': {
      color: '#F3F3F3',
      fontSize: '14px',
      fontFamily: 'Outfit',
      fontWeight: '400',
    },

    '& .wrapText': {
      cursor: 'pointer',
      padding: '8px'
    },
  },
});

const CustomSelectStyle = styled(Box)(({ theme }) => ({
  '& .inputFieldServices': {
    marginTop: '4px',
    borderRadius: 8,
    border: `1px ${(theme as any).typography.border} solid`,
    background: (theme as any).typography.body2,
    padding: '10px 8px',
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    height: "32px",
  },
  "& .searchText": {
    color: (theme as any).typography.h1,
    fontSize: 14,
    fontFamily: 'Outfit',
    fontWeight: '400',
  },
  '& .iconX': {
    cursor: 'pointer',
    color: (theme as any).typography.h1,
  },
  '& .errorInput': {
    marginTop: '4px',
    borderRadius: 8,
    background: (theme as any).typography.body2,
    padding: '10px 8px',
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    border: "1px solid #F87171",
  },
  "& .inputField": {
    marginTop: '4px',
    borderRadius: 8,
    width: "150px",
    border: `1px ${(theme as any).typography.border} solid`,
    background: (theme as any).typography.body2,
    padding: '10px 8px',
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .dropdownText": {
    color: (theme as any).typography.h1,
    fontSize: 12,
    fontFamily: 'Outfit',
    fontWeight: '400',
  },
}))
export default CustomSelectSearchField;