import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Grid,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography,
  withWidth,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import clsx from 'clsx';
import { styled, withStyles } from "@material-ui/core/styles";
import { UploadImg } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import CustomPagination from "../../../components/src/CustomPagination";
import CustomSelectSearch from "../../../components/src/CustomSelectSearch";
import CustomSelectCustomerGroup from "../../../components/src/CustomSelectCustomerGroup";
import CustomCheckBox from "../../../components/src/CustomCheckBox.web";

// Customizable Area End

import NewBroadcastController, {
  Props,
} from "./NewBroadcastController";

export default class NewBroadcast extends NewBroadcastController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() { 
    const {width} = this.props;  

    return (
      // Customizable Area Start
        <NewBroadcastStyle>
          <Grid container className={clsx({"padding30": width!=='xs', 'padding30xs': width == 'xs'})}>
            <Grid item >
              <Box style={{width:'fit-content', marginRight:'40px', marginBottom:'34px'}}>
                <InputLabel htmlFor="image" className="inputLabel">
                  Select image to Broadcast
                </InputLabel>
                {this.state.selectedImage ? 
                  <Box>
                    <Box className="imageShow">
                      <Box className="closeBox">
                        <CloseIcon className="closeIcon"  id="closeIcon" onClick={this.handleRemoveImage}/>
                      </Box>
                      <img  
                        src={URL.createObjectURL(this.state.selectedImage)}
                        className="imageShow"
                      />
                    </Box>
                  </Box>
                   
                  : <Box className="wrapAddImage">
                    <input
                        id="imageInput"
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={this.handleImageChange}
                        data-test-id="handleImageChange"
                        multiple
                      />
                    <label htmlFor="imageInput">
                      <Box className="imageUploader">
                        <img src={UploadImg} />
                        <Typography className="uploadText">Add image</Typography>
                      </Box>
                    </label>
                  </Box>
                }
                <Typography className="errorsMsg">{this.state.errorImageString}</Typography>
                
              </Box>
            </Grid>
            
            <Grid item xs={12} md>
              <InputLabel htmlFor="message" className="inputLabel">
                Message
              </InputLabel>
              <TextareaAutosize
                data-test-id="AccessoriesId"
                minRows={9}
                maxRows={9}
                maxLength={512}
                className="textArea"
                placeholder="Enter message"
                value={this.state.message}
                onChange={(event) => {
                  this.onValueChange(event.target.value);
                }}
              />     
              {this.state.isErrorInputBroadcast && <Typography className="errorsMsg">Please Enter a message to Send the broadcast</Typography>}       
            </Grid>

            <Grid item xs={12} md={12} className="wrapTitle">
              <TableContainer className={clsx({'width300': width === 'xs', 'width100': width !=='xs', 'test': true })}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCellBroadcast align='left' colSpan={6}>
                        <Box>
                          <Typography className="titleText">Select Customers for broadcast</Typography>
                          <Box className="tabWrap">
                            <TabItemBroadcast
                              data-test-id={'individualsId'}
                              label="Individuals"
                              isActive={!this.state.isGroup}
                              onClick={()=>this.handleSwitchTab(1)}
                            />
                            <TabItemBroadcast
                              data-test-id={'customer-group'}
                              label="Customer Groups"
                              isActive={this.state.isGroup}
                              onClick={()=>this.handleSwitchTab(2)}
                            />
                          </Box>
                          {!this.state.isGroup && <Box className="wrapSelect">
                            <CustomCheckBox checked={this.state.isSelectAllBroadcast} onChange={this.handleCheckAllItemIndividual}/> 
                            <Typography className="contentText">Select all</Typography>
                          </Box>}
                        </Box>
                      </StyledTableCellBroadcast>
                    </TableRow>
                    {!this.state.isGroup && <TableRow>
                      {/* <StyledTableCellBroadcast align='left'    >
                      </StyledTableCellBroadcast> */}
                      <StyledTableCellBroadcast align='left' colSpan={2}>
                        <CustomSelectSearch value={this.state.valueSearchCusNameCRMBroadcast} isCustomerName listSearchCustomerName={this.state.listSearchCustomerNameCRMBroadcast} placeholder={"Customer Name"} onChange={(e)=>this.handleSearchCustomerNameCRMBroadcast(e)} onSelect={(e)=>this.onChangeSearchCRMBroadcast(e, 'name')}/>
                      </StyledTableCellBroadcast>
                      <StyledTableCellBroadcast align='left'>
                        <Box className="dobBox">
                          <Typography className="dobText">Date Of Birth</Typography> 
                        </Box>
                      </StyledTableCellBroadcast>
                      <StyledTableCellBroadcast align="left">
                        <CustomSelectSearch value={this.state.valueSearchPhoneCRMBroadcast} isSearch placeholder={"Phone Number"} onChange={(e)=>this.onChangeSearchCRMBroadcast(e, 'contact_number')}/>
                      </StyledTableCellBroadcast>
                      <StyledTableCellBroadcast align="left">
                        <CustomSelectSearch value={this.state.valueSearchEmailCRMBroadcast} isSearch placeholder={"E-mail ID"} onChange={(e)=>this.onChangeSearchCRMBroadcast(e, 'email')}/>
                      </StyledTableCellBroadcast>
                      <StyledTableCellBroadcast align="left">
                      <CustomSelectSearch data-test-id={'serviceId'} value={this.state.valueSearchServiceCRMBroadcast.charAt(0).toUpperCase() + this.state.valueSearchServiceCRMBroadcast.slice(1)} placeholder='Interested Service' isService onChange={(e)=>this.onChangeSearchCRMBroadcast(e, 'services')}/>
                      </StyledTableCellBroadcast>            
                    </TableRow>}
                  </TableHead>
                  {this.state.isGroup ? 
                  <Box className="wrapCustomerGroup">
                    <Typography className="inputLabel">Select customer group</Typography>
                    <CustomSelectCustomerGroup value={this.state.selectedGroup?.attributes?.group_name ?? ""} placeholder={"Select"} listItem={this.state.listGroupBroadcast} handleChooseItem={this.handleChooseGroup}/>
                  </Box>
                  : <TableBody>
                    {this.state.rowIndividual.map((row, index)=> {
                      return <TableRow>
                        <StyledTableCellBroadcast align="left"><CustomCheckBox 
                          checked={this.state.listCheckBroadcast[index]}
                          onChange={()=>this.handleSelectItemIndividual(row, index)}/>
                        </StyledTableCellBroadcast>
                        <StyledTableCellBroadcast align="left">{row.customerName}</StyledTableCellBroadcast>
                        <StyledTableCellBroadcast align="left">{row.dob}</StyledTableCellBroadcast>
                        <StyledTableCellBroadcast align="left">{row.phoneNumber}</StyledTableCellBroadcast>
                        <StyledTableCellBroadcast align="left">{row.email}</StyledTableCellBroadcast>
                        <StyledTableCellBroadcast align="left">{row.interestedService}</StyledTableCellBroadcast>
                      </TableRow>}
                    )}
                  </TableBody>}
                </Table>
              </TableContainer>
              {this.state.isErrorSelectBroadcast && <Typography className="errorsMsg">Please Select the customer to Send the broadcast</Typography>}
              {!this.state.isGroup && <CustomPagination page={this.state.currentPageCustomerBroadcast} count={this.state.pageCustomerBroadcast} onChange={this.handleChangePageCustomerBroadcast}/>}
            </Grid>
            <Grid container>
              <Grid item md={3}/>
              <Grid item container md={6} xs={12}>
                <Grid item md={6} xs={12} className="padding10">
                  <Button className="btnWrapCancel" data-test-id='btnCancel' onClick={this.handleCancelBroadcast}>
                    <Typography className="btnTextCancel">Cancel</Typography>
                  </Button>
                </Grid>
                <Grid item md={6} xs={12} className="padding10">
                  <Button data-test-id='btnSubmit' className="btnWrapAdd" type="submit" onClick={this.handleSubmitBroadcast}>
                    <Typography className="btnTextAdd">Send Broadcast</Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid item md={3}/>
            </Grid>

          </Grid>                
        </NewBroadcastStyle>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const TabItemBroadcast = ({ label, isActive, onClick }:{ label: any, isActive: any, onClick: any}) => (
  <Box
    className="tabLabelWrap"
    onClick={onClick}
  >
    <Typography
      className={clsx({
        'tabLabelActive': isActive,
        'tabLabelInactive': !isActive,
      })}
    >
      {label}
    </Typography>
    {isActive && <Box className='underline' />}
  </Box>
);
const StyledTableCellBroadcast = withStyles((theme)=>({
  head: {
    fontWeight: 700,
    verticalAlign:'center',
    border: 'none',
    borderCollapse: 'separate',
    borderSpacing: '0px 4px',
    backgroundColor: (theme as any).typography.body2,
    color: (theme as any).typography.h1, 
    fontSize: '14px',
    fontFamily: 'Outfit',
  },
  body: {
    
    fontWeight: 400,
    border: 'none',
    borderCollapse: 'separate',
    borderSpacing: '0px 4px',
    backgroundColor: (theme as any).typography.body2,
    color: (theme as any).typography.h2, 
    fontSize: '16px',
    fontFamily: 'Outfit', 
  },
}))(TableCell)

const NewBroadcastStyle  = styled(Box)(({ theme }) => ({
  "& td:not(:first-child)":{
    wordBreak:'break-word',
    width:100
  },
  '& td:first-child ': {
   width:'1% !important' // Remove bottom border from the last table row
},
  "& .underline":{
    height:2,
    backgroundColor:'#F7DD91', 
    width:'100%'
  },
  "& .wrapCustomerGroup": {
    backgroundColor:(theme as any).typography.body2,
    padding: 16,
    height:'50vh'
  },
  "& .tabLabelWrap":{
    cursor: 'pointer',
    padding: '8px', 
    borderRadius: '4px', 
    justifyContent: 'center',
    alignItems: 'center', 
    display: 'flex',
    flexDirection:'column'
  },
  "& .tabLabelInactive":{
    color: (theme as any).typography.h1,
    fontSize: '18px', 
    fontFamily: 'Outfit', 
    fontWeight: '700'
  },

  "& .tabLabelActive":{
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent", 
    fontSize: 18, 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  '& .padding30':{
    padding: "30px",
    minHeight:'100vh'
  },
  '& .padding30xs':{
    width: 'fit-content',
    padding: "30px",
  },
  "& .uploadText": {
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textAlign: "center",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .imageShow":{
    cursor: "pointer",
    borderRadius: "8px",
    width: "150px",
    height: "150px",
  },
  "& .wrapAddImage":{
    backgroundColor:(theme as any).typography.body2, 
    borderRadius: '8px', 
    width:'fit-content'
  },
  "& .inputLabel": {
    marginBottom: "5px",
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .imageUploader": {
    cursor: "pointer",
    borderRadius: "8px",
    background: (theme as any).typography.body2,
    display: "flex",
    width: "150px",
    height: "150px",
    padding: "10px var(--border-radius, 16px)",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  "& .textArea": {
    display: "flex",
    width: "100%",
    height: "150px !important",
    padding: "10px 8px",
    gap: "8px",
    borderRadius: "8px",
    border: `1px ${(theme as any).typography.border2} solid`,
    background: (theme as any).typography.body2,
    color: (theme as any).typography.h2,
    fontFamily: "Outfit",
  },
  "& .closeBox":{
    display: 'flex',
    justifyContent:'end',
  },
  "& .closeIcon":{
    color:"white",
    position: "absolute",
    marginTop: "10px",
    marginRight:'10px'
  },
  '& .wrapTitle':{
    marginTop:'16px'
  },
  '& .width300':{
    width: '327px',
    borderRadius: '16px'
  },
  '& .width100':{
    width: '100%',
    borderRadius: '16px'
  },
  '& .titleText':{
    color: (theme as any).typography.h1, 
    fontSize: 18, 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  '& .wrapSelect':{
    marginTop:'16px',
    display: 'flex',
    flexDirection:'row',
  },
  '& .contentText': {
    color: (theme as any).typography.h2, 
    fontSize: 16, 
    fontFamily: 'Outfit', 
    fontWeight: '400',
    marginLeft: 16
  },

  "& .dobText": {
    fontFamily: "Outfit",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .dobBox": {
    height: "32px",
    width:"150px",
    padding: "10px 8px 10px 8px",
    background: (theme as any).typography.body2,
    borderRadius: "8px",
    border: `1px ${(theme as any).typography.border2} solid`,
    justifyContent: 'flex-start', 
    alignItems: 'center', 
    display: 'inline-flex'
  },
  "& .errorsMsg":{
    color: "#F87171",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .btnWrapAdd":{
    width: '100%',
    cursor: 'pointer',
    padding: "16px 0 16px 0", 
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',    
  },
  "& .btnWrapCancel":{
    cursor: 'pointer',
    padding: "16px 0 16px 0", 
    background: (theme as any).typography.body2, 
    borderRadius: '8px', 
    width: '100%',
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',    
  },
  '& .padding10':{
    padding: "10px",
  },
  '& .btnTextCancel': {
    textTransform: 'none',
    color: '#CEAE71', 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  '& .btnTextAdd': {
    textTransform: 'none',
    color: '#151515', 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  }, 
  "& .tabWrap":{
    padding: '10px',
    borderRadius: '8px', 
    justifyContent: 'flex-start', 
    alignItems: 'center', 
    display: 'inline-flex'
  },
}))
export {NewBroadcast}

withWidth()(NewBroadcast);
// Customizable Area End