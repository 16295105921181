import React from "react";

import {
    // Customizable Area Start
    Button,
    Box,
    Grid,
    Typography,
    withWidth,
    CircularProgress,
    Modal,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import clsx from 'clsx';
import { styled, } from "@material-ui/core/styles";
import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
// Customizable Area End

import MarketingDetailsController, {
    Props,

} from "./MarketingDetailsController.web";


export default class MarketingDetails extends MarketingDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderLoading = () => {
        return <Modal open={this.state.modalLoading}>
          <ModalStyle>
            <Box className="wrapModalDetail">
              <Box className="wrapModalContentLoadingDetail">
                <CircularProgress className="iconLoadingDetail" size={20} />
                <Typography className="loadingTextDetail">Please wait while we are Uploading your post</Typography>
              </Box>
            </Box> 
          </ModalStyle>
        </Modal>
    }

    // Customizable Area End

    render() {
        const { width } = this.props;
        const { marketingDetails, platform } = this.state

        return (
            // Customizable Area Start
            <>
            {this.renderLoading()}
                <MarketingDetailsGroupStyle>
                    <Box data-test-id="media_details" className={clsx({ "padding30": width !== 'xs', 'padding30xs': width == 'xs' })}>
                        <Grid item>
                            <Box display="flex">
                                <Typography className="LightTypo">Vehicle :</Typography> <Box mx={1} /><Typography className="textTypo"> {marketingDetails?.attributes?.vehicle_name}</Typography>
                            </Box>
                        </Grid>
                        <Grid item >
                            <Box className="status_box" my={4}>
                                <Typography className="textTypo">Status</Typography>
                                <Box my={2} />
                                <Typography className={marketingDetails?.attributes?.status === "SUCCESSFUL" ? "successFull" : "statusFailed "}>{marketingDetails?.attributes?.status === "SUCCESSFUL" ? "Passed" : "Failed"} </Typography>
                            </Box>
                            <Box className="status_box" my={4}>
                                <Typography className="textTypo">Platform</Typography>
                                <Box my={2} />
                                {
                                    platform.map((val: any, index: number) => {
                                        if (val.isShow) {
                                            return <Box key={index} className="iconsDiv">{val?.icon} {val.type}
                                                {
                                                    val.isPostedSuccesully ? (<CheckIcon className="check" />) : (<Box className="retry">
                                                        <ClearIcon className="crossIcon" />
                                                        <Button className="failedPLaftorm" onClick={()=>this.handleRetry(val)}><ReplayIcon /> Retry</Button>
                                                    </Box>)
                                                }
                                            </Box>
                                        }
                                    })
                                }
                            </Box>
                            <Grid className="status_box" item xs={12} md={6} lg={6} >
                                <Typography className="textTypo">Description</Typography>
                                <Box my={1} />
                                <Typography className="descriptionBox">
                                    {marketingDetails?.attributes?.post ?? "NA"}
                                </Typography>
                            </Grid>

                            <Grid className="status_box" item xs={12} md={6} lg={6} >
                                <Typography className="textTypo">Media</Typography>

                                <Box my={2} className="medias">
                                    {
                                        Array.isArray(marketingDetails?.attributes?.images) && marketingDetails?.attributes?.images?.map((val: any, index: number) => {
                                            return <Box borderRadius={10} overflow="hidden" width={200} height={200}>
                                                <img src={val.url} key={index} width="100%" height="100%" />
                                            </Box>
                                        })
                                    }
                                    {
                                        Array.isArray(marketingDetails.attributes?.videos) && marketingDetails.attributes.videos.map((val: any, index: number) => {
                                            return <Box borderRadius={10} overflow="hidden" width={200} height={200}>
                                                <video src={val.url} key={index} width="100%" height="100%" />
                                            </Box>
                                        })
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </MarketingDetailsGroupStyle>
            </>
        );
        // Customizable Area End
    }
}



// Customizable Area Start
const ModalStyle = styled(Box)(({ theme }) => ({
    '& .wrapModalDetail': {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%', 
      transform: 'translate(-50%, -50%)',
      backgroundColor:(theme as any).typography.body2, 
      borderRadius: "16px",
      minWidth:'40%'
    },
    '& .iconLoadingDetail': {
      color:'#CEAE71', 
      alignSelf:'center'
    },
    '& .wrapModalContentLoadingDetail': {
      justifyContent: 'center',
      padding: '40px',
      alignItems:'center',
      width:'100%',
      display:'flex',
      flexDirection: 'column'
    },
    '& .loadingTextDetail': {
        marginTop: 24,
      color: (theme as any).typography.h1, 
      fontFamily: "Outfit",
      fontSize:"16px",
      fontWeight: 700,
    },
}));
const MarketingDetailsGroupStyle = styled(Box)(({ theme }) => ({

    "& .check": {
        color: "green",
        fontSize: 20,
        margin: "2px 1rem ",
        cursor: "pointer"
    },
    "& .crossIcon": {
        fontSize: 20,
        margin: "2px 1rem ",
        color: "red",
        cursor: "pointer"
    },
    "& .medias": {

        flexWrap:'wrap',
        display: "flex",
        gap: ".8rem",
    },
    "& .status_box": {
        margin: "2rem 0"
    },
    "& .iconsDiv": {
        //border: "1px solid",
        fontSize: 14,
        fontFamily: 'Outfit',

        fontWeight: '500',
        color: (theme as any).typography.h2,
        display: "flex",
        alignItems: "center",
        gap: "10px",
        margin: "1rem 0",
    },
    "& .descriptionBox": {
        fontSize: 14,
        fontFamily: 'Outfit',
        fontWeight: '500',
        color: (theme as any).typography.h3,
    },

    "& .retry": {
        display: "flex",
        gap: "8px",
        alignItems: "center"
    },
    "& .failedPLaftorm": {
        fontSize: 14,
        textTransform: "capitalize",
        padding: "2px .5rem",
        borderRadius: "1rem",
        display: "flex",
        gap: "4px",
        fontFamily: 'Outfit',
        background: (theme as any).typography.body2,
        fontWeight: '500',
        color: "#e6ad46",
        "& svg": {
            fontSize: 20,
            marginRight: 2,
        }
    },

    '& .padding30': {
        padding: "30px",
    },
    '& .padding30xs': {
        width: 'fit-content',
        padding: "30px",
    },
    '& .width300': {
        width: '327px',
        borderRadius: '16px'
    },
    '& .width100': {
        width: '100%',
        borderRadius: '0',

    },
    '& .LightTypo': {
        fontSize: 16,
        fontFamily: 'Outfit',
        fontWeight: '300',

        color: (theme as any).typography.h1,
    },
    '& .textTypo': {
        fontSize: 16,
        fontFamily: 'Outfit',
        fontWeight: '500',

        color: (theme as any).typography.h1,
    },
    "& .statusFailed": {
        color: "#e13838",
        background: "#f7e9db",
        width: "fit-content",
        padding: "2px 9px",
        borderRadius: "1rem",
        fontWeight: 500,
        fontFamily: "outfit",
        fontSize: "1rem",
    },
    "& .successFull": {

        background: "var(--Status-Green-100, #D1FAE5)",
        display: "flex",
        justifyContent: "center",
        width: "fit-content",
        alignItems: "center",
        gap: "2px",
        color: "var(--Status-Green-600, #059669)",
        fontStyle: 'normal',
        lineHeight: '18px', // 150%
        textTransform: 'uppercase',
        padding: "2px 9px",
        borderRadius: "1rem",
        fontWeight: 500,
        fontFamily: "outfit",
        fontSize: "1rem",
    }


}))

export { MarketingDetails }

withWidth()(MarketingDetails);
// Customizable Area End