import * as React from "react";
// Customizable Area Start
import {
  Box,
  Button,
    Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withWidth
} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CustomSelectSearch from "../../../components/src/CustomSelectSearch";
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import CustomPagination from "../../../components/src/CustomPagination";
import CustomStatus from "../../../components/src/CustomStatus";
import { Close } from "./assets";
import DateRangeIcon from '@material-ui/icons/DateRange';
// Customizable Area End

import { ViewProps } from "./OrderManagement";
import moment from "moment";
import Calendar from "react-calendar";

const OrderManagementView: React.FC<ViewProps> = ({
  // Customizable Area Start
  testID,
  width,
  vehicleName,
  vehicleNumber,
  dealId,
  dealDate,
  clientName,
  dealValue,
  statusText,
  handleChangePageCustomer,
  handleOpenEditMenu,
  handleOpenEditMenu1,
  handleModalDelete,
  handleCloseEditMenu,
  handleCloseEditMenu1,
  anchorEl,
  handleEditDeal,
  handleAddNewDeal,
  rowDeal,
  onChangeSearchDeal,
  currentPage,
  totalPage,
  listSearchClientName,
  listSearchDealId,
  listSearchVehicleName,
  listSearchVehicleNumber,
  handleSearchClientName,
  handleSearchDealId,
  handleSearchVehicleName,
  handleSearchVehicleNumber,
  handleNavigateDetail,
  openModalDeleteDeal,
  handleDeleteDeal,
  toggleCalendar,
  closeCalendar,
  isOpenCalendar,
  anchorEl1,
  userType
  // Customizable Area End
}) => {

  // Customizable Area Start
  const renderModalDeleteDeal = () => {
    return <Modal open={openModalDeleteDeal}>
      <ModalStyle>
        <Box className="wrapModal">
          <Box className="modalHeaderWrap">
            <Typography className="modalHeaderText">Delete Deal</Typography>
            <IconButton data-testId='iconClose' onClick={(event)=>handleModalDelete(event,null)} className="iconPadding">
              <img src={Close}/>
            </IconButton>
          </Box>
          <Box className="line"/> 
          <Box className="wrapModalContent">
            <Typography className="deleteMsg">Are you sure you want to delete this Deal?</Typography>
          </Box>
          <Box className="line"/> 
          <Box className="modalFooterWrap">
            <Button data-testId='cancalBtn' className="cancelBtn" onClick={(event)=>handleModalDelete(event,null)}>Cancel</Button>
            <Button data-testId='saveBtn' className="saveBtn" onClick={()=>handleDeleteDeal()}>Delete</Button>
          </Box> 
        </Box> 
      </ModalStyle>
    </Modal>
  }
  // Customizable Area End
  
  return (
    // Customizable Area Start
      <>
      {renderModalDeleteDeal()}
      <SalesAndContractStyle data-testid={testID}>
        <Grid container className={clsx({"padding30": width!=='xs', 'padding30xs': width == 'xs'})}>
          <Grid item xs={12} md={12} className="wrapBtnAdd">
            <Box data-testId={'addNewCustomer'} className="btnWrap" onClick={handleAddNewDeal}>
              <Typography className="btnTextApply">Add New Deal</Typography>
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <TableContainer className={clsx({'width300': width === 'xs', 'width100': width !=='xs' })}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align='left' width={'15%'}>
                      <CustomSelectSearch 
                        isVehicleName
                        listSearchVehicleName={listSearchVehicleName}
                        testId="vehicleNameId" 
                        value={vehicleName} 
                        placeholder='Vehicle Name' 
                        onChange={(e)=>handleSearchVehicleName(e)}
                        closeCalendar={closeCalendar}
                        onSelect={(e)=>onChangeSearchDeal(e, 'vahicle_name')}/>
                        
                    </StyledTableCell>
                    <StyledTableCell align="left" width={'15%'}>
                      <CustomSelectSearch 
                        testId="deadId" 
                        isDealId
                        listSearchDealId={listSearchDealId}
                        value={dealId} 
                        placeholder='Deal ID' 
                        closeCalendar={closeCalendar}
                        onChange={(e)=>handleSearchDealId(e)}
                        onSelect={(e)=>onChangeSearchDeal(e, 'deal_random_id')}/>
                    </StyledTableCell>
                    <StyledTableCell align="left" width={'15%'}>
                      <CustomSelectSearch 
                        testId="vehicleNumberId" 
                        isVehicleNumber
                        listSearchVehicleNumber={listSearchVehicleNumber}
                        closeCalendar={closeCalendar}
                        onChange={(e)=>handleSearchVehicleNumber(e)}
                        value={vehicleNumber} placeholder='Vehicle Number' onSelect={(e)=>onChangeSearchDeal(e, 'vehicle_number')}/>
                    </StyledTableCell>
                    <StyledTableCell align="left" width={'15%'}>
                      <CustomSelectSearch 
                        testId="clientNameId" 
                        isClientName
                        listSearchClientName={listSearchClientName}
                        onChange={(e)=>handleSearchClientName(e)}
                        closeCalendar={closeCalendar}
                        value={clientName} placeholder='Client Name'  onSelect={(e)=>onChangeSearchDeal(e, 'client_name')}/>
                    </StyledTableCell> 
                    <StyledTableCell align="left" width={'15%'}>
                      
                      <div className="customebtn">
                       <button type="button"
                      data-testid="firstRegistrationDate"
                      className={dealDate == "" ? "customButtonPLACEHOLDER":"customButton"}
                       onClick={toggleCalendar}> <Typography className="date">{dealDate != "" ? moment(dealDate).format('MM/DD/YYYY') : "Deal Date"}</Typography><DateRangeIcon />    </button>

                    {
                      isOpenCalendar && <Calendar
                        className="customCalendarPop"
                        data-testid="regDate"
                        onChange={(event: any) => {
                          onChangeSearchDeal({target: {value: moment(event).format('YYYY-MM-DD')}}, 'deal_date')
                          closeCalendar();  
                        }}
                        value={dealDate} />
                    }</div>
                    </StyledTableCell>
                    <StyledTableCell align="left" width={'15%'}>
                      <CustomSelectSearch 
                      closeCalendar={closeCalendar}
                      testId="dealValueId" value={dealValue} placeholder='Deal Value' onChange={(e)=>onChangeSearchDeal(e, 'deal_value')} isDealValue/>
                    </StyledTableCell> 
                    <StyledTableCell align="left" width={'15%'}>
                      <CustomSelectSearch 
                        closeCalendar={closeCalendar} 
                        testId="statusId" value={statusText || 'Status'} placeholder='Status' onChange={(e)=>onChangeSearchDeal(e, 'status')} isStatus/>
                    </StyledTableCell>    
                    <StyledTableCell>
                    </StyledTableCell>        
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowDeal.map((row: any)=>
                    <TableRow onClick={()=>handleNavigateDetail(row)}>
                      <StyledTableCell align="left" data-testid="vehicleId">{row.attributes.vahicle_name}</StyledTableCell>
                      <StyledTableCell align="left">{row.attributes.deal_random_id}</StyledTableCell>
                      <StyledTableCell align="left">{row.attributes.vehicle_number}</StyledTableCell>
                      <StyledTableCell align="left">{row.attributes.customer_detail.full_name}</StyledTableCell>
                      <StyledTableCell align="center">{new  Date(row.attributes.created_at).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}</StyledTableCell>
                      <StyledTableCell align="left">${row.attributes.vehicle_price}</StyledTableCell>
                      <StyledTableCell align="left">
                        <CustomStatus status={row.attributes.status}/>
                      </StyledTableCell>
                      
                      {!(row.attributes.status === "pending_hard_copy_handover" || row.attributes.status === "completed") ?
                       <StyledTableCell align="center">
                       <Box aria-haspopup="true" data-testid='editButton' onClick={(e)=>handleOpenEditMenu(e, row)}>
                         <MoreVertIcon/>
                       </Box>                          
                       <StyledMenu
                         data-testid="simple-menu"
                         anchorEl={anchorEl}
                         keepMounted
                         open={Boolean(anchorEl)}
                         onClose={handleCloseEditMenu}
                         
                       >
                         <MenuItem onClick={(event)=>handleEditDeal(event, row)} data-testid="EditMenuID">
                           <ListItemIcon>
                             <EditIcon style={{color:"#F3F3F3"}}/>
                           </ListItemIcon>
                           <ListItemText primary="Edit" style={{color: '#F3F3F3', fontSize: 12, fontFamily: 'Outfit', fontWeight: 400,}}/>
                         </MenuItem>
                        {<MenuItem onClick={(event)=>handleModalDelete(event, row)} data-testid="deleteId">
                           <ListItemIcon>
                             <DeleteIcon style={{color:"#F3F3F3"}}/>
                           </ListItemIcon>
                           <ListItemText primary="Delete" style={{color: '#F3F3F3', fontSize: 12, fontFamily: 'Outfit', fontWeight: 400,}}/>
                         </MenuItem>}
                       </StyledMenu>
                     </StyledTableCell>:                      
                      <StyledTableCell align="center">
                      {!(row.attributes.status === "pending_hard_copy_handover" && userType!=="sales_manager") &&  <Box aria-haspopup="true" data-testid='editButton1' onClick={(e)=>handleOpenEditMenu1(e, row)}>
                        <MoreVertIcon/>
                      </Box>  }                        
                      <StyledMenu
                        data-testid="simple-menu"
                        anchorEl={anchorEl1}
                        keepMounted
                        open={Boolean(anchorEl1)}
                        onClose={handleCloseEditMenu1}
                      >
                       {<MenuItem onClick={(event)=>handleModalDelete(event, row)}>
                          <ListItemIcon>
                            <DeleteIcon style={{color:"#F3F3F3"}}/>
                          </ListItemIcon>
                          <ListItemText primary="Delete" style={{color: '#F3F3F3', fontSize: 12, fontFamily: 'Outfit', fontWeight: 400,}}/>
                        </MenuItem>}
                      </StyledMenu>
                    </StyledTableCell>}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomPagination page={currentPage} count={totalPage} onChange={handleChangePageCustomer}/>
          </Grid>
        </Grid>
      </SalesAndContractStyle>
      </>
    // Customizable Area End
  );
};

// Customizable Area Start
const ModalStyle = styled(Box)(({ theme }) => ({
  
  '& .line': {
    height:"1px", 
    backgroundColor:"#FFF6DC30",
  },
  '& .wrapModalContent': {
    padding: '40px'
  },
  '& .modalHeaderWrap': {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "24px"  
  },
  
  '& .modalFooterWrap': {
    display: "flex",
    justifyContent: "flex-end",
    padding: "24px"   
  },
  "& .deleteMsg":{
    color: '#F87171', 
    fontSize: 16, 
    fontFamily: 'Outfit', 
    fontWeight: '400',
  },
  '& .modalHeaderText': {
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize:"24px",
    fontWeight: 700,
  },
  '& .wrapModal': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    backgroundColor:(theme as any).typography.body2, 
    borderRadius: "16px",
    minWidth:'40%'
  },
  '& .cancelBtn': {
    backgroundColor:(theme as any).typography.surface2, 
    borderRadius: "8px",
    color: "#CEAE71",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
    height: "56px"
  },
  
  '& .saveBtn': {
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
    height: "56px",
    marginLeft: "16px",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    borderRadius: "8px",
    color: "#171717",
    fontFamily: "Outfit",
    fontSize: "16px",
    
  },
}));
const StyledMenu = withStyles({
  paper: {
    borderRadius: '8px',
    backgroundColor: '#3F3E3A'
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledTableCell = withStyles((theme)=>({
  head: {
    backgroundColor: (theme as any).typography.body2, 
    color: (theme as any).typography.h1, 
    fontSize: '14px',
    fontFamily: 'Outfit', 
    fontWeight: 700,
    verticalAlign:'center',
    border: 'none',
    borderCollapse: 'separate',
    borderSpacing: '0px 4px',
    minWidth:30,
  },
  body: {
    backgroundColor: (theme as any).typography.body2, 
    color: (theme as any).typography.h2, 
    fontSize: '16px',
    fontFamily: 'Outfit', 
    fontWeight: 400,
    border: 'none',
    borderCollapse: 'separate',
    borderSpacing: '0px 4px'
  },
}))(TableCell)

const SalesAndContractStyle  = styled(Box)(({ theme }) => ({
  '& .MuiInputBase-input':{
    fontSize:14,
    fontFamily:'Outfit',
    fontWeight:400,
    color: (theme as any).typography.h1, 
  },
  '& .inputField': {
    display: "flex",
    height: "32px",
    width:"150px",
    padding: "10px 8px 10px 8px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: `1px ${(theme as any).typography.border2} solid`,
    background: (theme as any).typography.body2, 
    color: (theme as any).typography.h3, 
    fontFamily: "Outfit",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  '& .padding30':{
    padding: "30px",
  },
  '& .padding30xs':{
    width: 'fit-content',
    padding: "30px",
  },
  '& .width300':{
    marginTop: '32px',
    width: '327px',
    borderRadius: '16px'
  },
  "& .btnTextApply":{
    color: '#171717', 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '700'
  },
  "& .wrapBtnAdd": { 
    width:'100%', 
    height:'fit-content', 
    paddingTop: 10, 
    display:'flex',
    justifyContent:'flex-end'
  },
  "& .btnWrap":{
    cursor: 'pointer',
    paddingTop: "10px", 
    paddingBottom: "10px", 
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',
    width: '100%',
    maxWidth: '327px'
  },
  '& .width100':{
    marginTop: '32px',
    width: '100%',
    borderRadius: '16px',
    minHeight:'600px'
  },
  '& .customCalendarPop': {
    border: `1px solid ${(theme as any).typography.border}`,
    background: (theme as any).typography.body2,
    marginTop: '1rem',
    color: (theme as any).typography.h1,
    // Add any other custom styles as needed
    display: 'inline-flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    borderRadius: '8px',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    position: "absolute",
    left: "1rem",
    zIndex: 4,
  },
  '& .customebtn':{
    position:'relative',
    width:300,
    display:'flex',
    justifyContent:'center'
  },
  '& .MuiTableRow-root':{
    cursor: 'pointer'
  },
  
  '& .customButton': {
    display: 'inline-flex',
    height: '32px',
    width: "150px",
    marginTop: ".3rem",
    padding: '6px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '6px',
    flexShrink: 0,
    borderRadius: '8px',
    border: `1px solid ${(theme as any).typography.border2}`,
    background: (theme as any).typography.body2,
    position: 'relative',
    color: (theme as any).typography.h2 ,
    zIndex: 1, /* Ensure button is above the arrow */

  },
  '@media (max-width: 480px)': {


    '& .customCalendarPop': {
        padding: 0,
        fontSize: 12,
    },

    "& .rangeCalendar ": {
        top: "125%",


        left: "-12.3rem",
        width: "270px",
        position: "absolute",
    },
    // Add more styles as needed for small screens
},

  '& .react-calendar__tile--now': {
    background: (theme as any).typography.body1,
    opacity: 9,
  },

  '& .customButtonPLACEHOLDER': {
    display: 'inline-flex',
    height: '32px',
    width: "150px",
    padding: '6px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '6px',
    flexShrink: 0,
    borderRadius: '8px',
    border: `1px solid ${(theme as any).typography.border2}`,
    background: (theme as any).typography.body2,
    position: 'relative',
    color: "gray" ,
    zIndex: 1, /* Ensure button is above the arrow */
  },
  '& .date': {
    fontFamily: "Outfit",
    fontSize: 14,
    color: (theme as any).typography.h1,
    fontWeight:400
  },


  '& .react-calendar__tile': {
  },
  '& .react-calendar__month-view__weekdays__weekday': {
    color: (theme as any).typography.h1,
  },
  '& .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus': {
    background: (theme as any).typography.body1,
    color: `${(theme as any).typography.h1} !important`,
  },
  "& .react-calendar__navigation__next2-button": {
    display: "none"
  },
  '& .react-calendar__tile--hover': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%)) !important',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important"
    }
  },
  '& .react-calendar__tile react-calendar__tile--active react-calendar__year-view__months__month': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important",
    },
    borderRadius: '0', 
    '&:first-of-type': {
      borderTopLeftRadius: '35px',
      borderBottomLeftRadius: '35px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '35px',
      borderBottomRightRadius: '35px',
    },
  },
  
  '& .react-calendar__tile--active': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important",
    },
    borderRadius: '0', 
    '&:first-of-type': {
      borderTopLeftRadius: '35px',
      borderBottomLeftRadius: '35px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '35px',
      borderBottomRightRadius: '35px',
    },
  },
  '& .react-calendar__tile--hasActive': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important",
    },
    borderRadius: '0', 
    '&:first-of-type': {
      borderTopLeftRadius: '35px',
      borderBottomLeftRadius: '35px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '35px',
      borderBottomRightRadius: '35px',
    },
  },
  '& .react-calendar, .react-calendar *, .react-calendar ::before, .react-calendar ::after': {
    boxSizing: 'border-box',
    color: (theme as any).typography.h1,
  },
  '& .react-calendar__navigation__prev2-button  ': { display: "none" },
  '& .react-calendar__navigation button:enabled:focus': {
    background: (theme as any).typography.body1,
  },
  '& .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus': {
    background: (theme as any).typography.body1,
  },
  '& .react-calendar__navigation': {
    display: 'flex',
    height: '44px',
    marginBottom: '1em',
    width: '100%',
    color: (theme as any).typography.h1,
  },
}))

// Customizable Area End
export default OrderManagementView

withWidth()(OrderManagementView);
