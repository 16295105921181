import React from "react";
// Customizable Area Start
import { Box, CircularProgress, Grid, Typography, withWidth } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { FileIcon } from "./assets";
import HistoryController, { Props } from "./HistoryController";
import clsx from 'clsx';

// Customizable Area End
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiMenu: {
      list: {
        backgroundColor: "#3F3E3A !important",
        color: "#F3F3F3 !important",
        fontWeight: 400,
      },
    },
  },
});
// Customizable Area End

export default class History extends HistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {width} = this.props;  
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
        <CatalougueStyle>
          <Grid container className={clsx({"padding30": width!=='xs', 'padding30xs': width == 'xs'})}>
          <Grid item md={12} xs={12}>
          <Box className="mainBox">
            {this.state.historyDetails.map((history: any, index: number) => (
              <Box key={index} className={clsx({"catalogueMainBox": width !== 'xs', 'catalogueMainBoxxs': width == 'xs'})}>
                <Box className="uplodehistoryBox">
                  <img src={FileIcon} />
                </Box>
                {history.attributes.success_records ===
                history.attributes.total_records ? (
                  <Box key={index} className="historyFileTextBox">
                    <Typography className="historyFileText">
                      {history.attributes.file_name}
                    </Typography>
                    <Typography className="historysuccessText">
                      ({history.attributes.success_records}/
                      {history.attributes.total_records}) Catalogue from file (
                      {history.attributes.file_name}) has been successfully
                      imported.
                    </Typography>
                    <Box className="subTextWrap">
                      <Typography className="subFileText">
                        Uploaded by : {history.attributes.uploaded_by}
                      </Typography>
                      <Typography className="subFileText">
                        Uploaded at : {history.attributes.updated_at}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <>
                  <Box className="historyFileTextBox">
                    <Typography className="historyFileText">
                      {history.attributes.file_name}
                    </Typography>
                    {history.attributes.failed_records === 0 ||
                    history.attributes.failed_records === null ? (
                      <Box
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ height: "15px", width: "15px" }}
                        />
                        <Typography className="historyImportText">
                          Importing ({history.attributes.success_records}/
                          {history.attributes.total_records}) Catalogue from
                          file ({history.attributes.file_name}){" "}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                      <Box style={{display:"flex",justifyContent:"space-between",alignItems:"flex-start",width:"95%"}}>
                        <Box style={{display:"flex",flexDirection:"column"}}>
                        <Typography className="historyErrorText">
                          ({history.attributes.success_records}/
                          {history.attributes.total_records}) Catalogue has been
                          imported.
                        </Typography>
                        <Box className="subTextWrap">
                          <Typography className="subFileText">
                            Uploaded by : {history.attributes.uploaded_by}
                          </Typography>
                          <Typography className="subFileText">
                            Uploaded at : {history.attributes.updated_at}
                          </Typography>
                        </Box>
                        </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                  </>
                )}
                {history.attributes.success_records !== history.attributes.total_records && <Box className="downloadBtn" data-test-id='handleDownload' onClick={()=> this.handleDownload(index)}><Typography className="downloadBtnText">Download Error File</Typography></Box>}
              </Box>
            ))}
          </Box>
          </Grid>
          </Grid>
        </CatalougueStyle>
      </>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const CatalougueStyle = styled(Box)(({ theme }) => ({
  
  "& .catalogueMainBox":{
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    background: (theme as any).typography.body2, 
    padding:"8px",
    flexDirection:'row', 
    marginBottom:'16px', 
    gap:'10px',
    overflow: 'auto'
  },
  "& .catalogueMainBoxxs":{
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    background: (theme as any).typography.body2, 
    padding:"8px",
    flexDirection:'row', 
    marginBottom:'16px', 
    width: '327px',
    overflowX:'scroll',
    gap:'10px',
  },
  '& .padding30':{
    padding: "30px",
  },
  '& .padding30xs':{
    
    padding: "30px",
    width: 'fit-content',
  },
  "& .MuiCircularProgress-colorPrimary": {
    color: "#F7DD91",
  },
  "& .MuiTypography-body1": {
    fontFamily: "Outfit",
  },
  "& .mainBox": {
    display: "flex",
    flexDirection: "column",
    gap: "25px",

  },
  "& .historyBox": {
    display: "flex",
    width: "100%",
    minWidth: "100vh",
    alignItems: "center",
    gap: "16px",
    borderRadius: "8px",
    background: (theme as any).typography.surface2, 
    padding: "10px",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
  },
  "& .uplodehistoryBox": {
    display: "flex",
    padding: "var(--border-radius, 16px)",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "8px",
    background: "#FFEFBD",
  },
  "& .historyFileTextBox": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "2px",
    flex: "1 0 0",
  },
  "& .historysuccessText": {
    color: "#34D399",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .historyErrorText": {
    color: "#F87171",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .historyImportText": {
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .historyFileText": {
    color: (theme as any).typography.h1, 
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .subTextWrap": {
    display: "flex",
    gap: "7px",
  },
  "& .subFileText": {
    color: (theme as any).typography.h3, 
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .downloadBtn": {
    cursor:"pointer",
    display: "flex",
    padding: "6px 10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    borderRadius: "8px",
    background: (theme as any).typography.surface2,
    position:"relative",
    // top:"-7px"
  },
  "& .downloadBtnText": {
    color: "var(--Primary-Purple-500, #CEAE71)",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
  },
}));

export {History}

withWidth()(History);
// Customizable Area End
