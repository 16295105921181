import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { bgimage, hidePassword, logo, showPassword } from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
import ResetPasswordController, {Props} from "./ResetPasswordController.web";


// Customizable Area End



const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  userSchema = () => {
    return Yup.object().shape({
      newPassword: Yup.string()
        .required("Please Enter Your Password")
        .min(8, "Your password must be longer than 8 characters.")
        .max(25)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])/,
          "Must Contain One Uppercase, One Lowercase"
        ) 
        .matches(
          /^(?=.*[!@#\$%\^&\*])/,
          "Must Contain One Special Case Character"
        ) 
        .matches(
          /^(?=.{6,20}$)\D*\d/,
          "Must Contain One Number"
        ),
      confirmNewPassword: Yup.string().required("Please Enter Your New Password")
        .oneOf([Yup.ref('newPassword'), null], 'Entered passwords do not match')
    });
  };
  
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <LoginStyle>
          <Grid container >
            <Grid item xs={12} className="mainContainer">
              <Box className="loginCard">
                <Box className="logoBox">
                  <img src={logo} className="logo" />
                </Box>
                <Box className="logintextBox">
                  <Typography className="loginText">Reset Password</Typography>
                  <Formik
                    data-test-id="formik"
                    initialValues={{
                      newPassword: this.state.newPassword,
                      confirmNewPassword: this.state.confirmNewPassword,
                    }}
                    validationSchema={this.userSchema}
                    validateOnChange={this.state.formIsDirty}
                    validateOnBlur={this.state.formIsDirty}
                    onSubmit={(values) => {
                      this.resetPasswordApi()
                    }}
                  >
                    {({ errors, setFieldValue, handleSubmit }) => (
                      <form onSubmit={handleSubmit} noValidate autoComplete="off">
                        <Typography className="labelText">New password</Typography>
                        <Input
                          data-test-id='password'
                          className={errors.newPassword ? 'inputField error' : 'inputField'}
                          placeholder="Enter New password"
                          disableUnderline
                          value={this.state.newPassword}
                          onChange={(event) => {
                            this.onValueChange("newPassword", event.target.value);
                            setFieldValue("newPassword", event.target.value);
                          }}
                          fullWidth 
                          type={this.state.showPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                data-test-id='handleClickShowPassword'
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                edge="end"
                              >
                                {this.state.showPassword ? <img src={showPassword} /> : <img src={hidePassword} />}
                              </IconButton>
                            </InputAdornment>
                          }
                          />
                        {errors.newPassword === "Please Enter Your Password" && <Typography className="errorsMsg">{errors.newPassword}</Typography>}
                        <Typography className="labelText">Confirm New Password</Typography>
                        <Input
                          data-test-id='confirmPassword'
                          className={errors.confirmNewPassword ? 'inputField error' : 'inputField'}
                          placeholder="Enter Confirm New Password"
                          value={this.state.confirmNewPassword}
                          onChange={(event) => {
                            this.onValueChangecon("confirmNewPassword", event.target.value);
                            setFieldValue("confirmNewPassword", event.target.value);
                          }}
                          disableUnderline
                          fullWidth
                          type={this.state.showConfirmPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                             id="showConfirm"
                                aria-label="toggle password visibility"
                                onClick={this.handleShowConfirm}
                                edge="end"
                              >
                                {this.state.showConfirmPassword ? <img src={showPassword} /> : <img src={hidePassword} />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {(errors.confirmNewPassword === "Entered passwords do not match" || errors.confirmNewPassword === "Please Enter Your New Password")  && <Typography className="errorsMsg">{errors.confirmNewPassword}</Typography>}
                        <Box className="errorsMsgBox">
                        {this.state.errorValue.length > 0 && !this.state.error.minimum && <Typography className="errorsMsg">1. A minimum of 8 characters</Typography>}
                        {this.state.errorValue.length > 0 && !this.state.error.upper && <Typography className="errorsMsg">2. Include a mix of uppercase and lowercase letters.</Typography>}
                        {this.state.errorValue.length > 0 && !this.state.error.number && <Typography className="errorsMsg">3. Include at least one number.</Typography>}
                        {this.state.errorValue.length > 0 && !this.state.error.special && <Typography className="errorsMsg">4. Include at least one special character</Typography>}
                        </Box>
                        
                        <Button data-test-id='resetBtn' className={`resetBtn ${errors.confirmNewPassword ? 'disabledBtn' : 'resetBtn'}`} disabled={ !errors.confirmNewPassword === false } type="submit" onClick={this.checkSubmit}>Reset Password</Button>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </LoginStyle>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const LoginStyle = styled(Box)({
  backgroundImage: `url('${bgimage}')`,
  backgroundRepeat: 'no-repeat',
  height: 'auto',
  backgroundSize: 'cover',
  width: '100%',
  "& .mainContainer": {
    justifyContent: "center",
    display: "flex",
    padding: '20px',
    alignItems: "center",
    minHeight: "100vh",
  },
  "& .loginCard": {
    maxWidth: "640px",
    width: "100%",
    padding: '40px',
    flexDirection: "column",
    backgroundColor: '#2B2B2B',
    borderRadius: '16px',
    height: "auto",
    gap: "24px",
  },
  "& .logoBox": {
    justifyContent: 'center',
    display: "flex",
  },
  "& .logo": {
    marginBottom: '24px',
    width: '150px',
    height: '102px',
  },
  "& .loginText": {
    fontSize: "38px",
    fontStyle: "normal",
    fontFamily: "Outfit",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    backgroundClip: "text",
    fontWeight: 700,
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
  },
  "& .form": {
    width: '100%'
  },
  "& .labelText": {
    color: "var(--text-primary, #F3F3F3)",
    fontWeight: 700,
    fontFamily: "Outfit",
    fontSize: "14px",
    marginTop: "24px",
    fontStyle: "normal",
  },
  "& .inputField": {
    border: "2px solid var(--neutrals-cool-gray-200, #E2E8F0)",
    borderRadius: 8,
    color: "var(--text-teriary, rgba(243, 243, 243, 0.65))",
    background: "var(--surface, #2B2B2B)",
    padding: '10px 8px',
    fontFamily: "Outfit",
    fontWeight: 400,
    fontSize: "16px",
    fontStyle: "normal",
    marginTop: '4px',
  },
  "& .mainCheckbox": {
    flexDirection: 'row',
    display: 'flex',
  },
  "& .MuiSvgIcon-root": {
    width: "20px",
    height: "20px",
    fill: "white",
  },
  "& .checkbocMaindiv": {
    flexWrap: 'wrap',
    marginBottom: '24px',
    justifyContent: 'space-between',
    display: 'flex',
    marginTop: '24px',
  },
  "& .checkboxText": {
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
    fontFamily: "Outfit",
    color: "var(--text-primary, #F3F3F3)",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .ForgotPasswordBox": {
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
  },
  "& .ForgotPassword": {
    cursor:'pointer',
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    fontFamily: "Outfit",
    fontWeight: 700,
    fontSize: "16px",
    fontStyle: "normal",
  },
  "& .resetBtn": {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    textTransform: 'none',
    height: "56px",
    padding: "16px",
    marginTop : '56px',
    borderRadius: "8px",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "8px",
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
      color: "var(--text-primary, #171717)",
      fontFamily: "Outfit",
      fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 700,
  },
  "& .disabledBtn":{
    opacity: 0.5,
    background: "#FFF"
  },
  "& .errorsMsg": {
    fontFamily: "Outfit",
    fontWeight: 400,
    fontSize: "12px",
    color: "var(--status-red-400, #F87171)",
    fontStyle: "normal",
  },
  "& .errorsMsgBox": {
    marginTop:"16px",
  },
  "& .error" :{
    border: "2px solid #F87171",
    borderRadius: 8,
    color: "var(--text-teriary, rgba(243, 243, 243, 0.65))",
    background: "var(--surface, #2B2B2B)",
    padding: '10px 8px',
    fontFamily: "Outfit",
    fontWeight: 400,
    fontSize: "16px",
    fontStyle: "normal",
    marginTop: '4px',
  }
})
// Customizable Area End
