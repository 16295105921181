import React, {  } from "react";

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    BlobProvider,
    Image,
    Font} from "@react-pdf/renderer";
import moment from "moment";

const BoldFont = require("./fonts/TimesNewRomanBold.ttf");

type Props = {
    isSave: boolean;
    setPdf: any;
    data: any;
};


Font.register({
    family: 'RegularMer',
    src: require('./fonts/MerriweatherSans-Regular.ttf'),
    fontWeight: 'normal',
});
Font.register({
    family: 'BoldMer',
    src: require('./fonts/MerriweatherSans-Bold.ttf'),
    fontWeight: 'bold',
});
Font.register({
    family: 'BoldItaMer',
    src: require('./fonts/MerriweatherSans-BoldItalic.ttf'),
});

Font.register({
    family: 'ItaMer',
    src: require('./fonts/MerriweatherSans-Italic.ttf'),
});


Font.register({
    family: 'RegularHel',
    src: require('./fonts/helvetica_regular.ttf'),
    fontWeight: 'normal',
});
Font.register({
    family: 'BoldHel',
    src: require('./fonts/helvetica_bold.ttf'),
    fontWeight: 'bold',
});



const styles = StyleSheet.create({
    textMer: {
      fontFamily: 'RegularMer', 
      fontSize:7,
    },
    textMerIta: {
        fontFamily: 'ItaMer', 
        fontSize:7,
      },
    textMer5: {
      fontFamily: 'RegularMer', 
      fontSize:5,
    },
    textMer9: {
        fontFamily: 'RegularMer', 
        fontSize:9,
      },
    textMerBold: {
      fontFamily: 'BoldMer', 
      fontSize:11,
    },
    textMerBoldIta: {
        fontFamily: 'BoldItaMer', 
        fontSize:11,
      },
    textHel8Normal: {
      fontFamily: 'RegularHel', 
      fontSize:8,
    },
    textHel7: {
      fontFamily: 'RegularHel', 
      fontSize:7,
    },
    textHel6: {
        fontFamily: 'RegularHel', 
        fontSize:6,
      },
    textHelBold: {
        fontFamily: 'BoldHel', 
        fontSize:10,
      },
      textHelBold8: {
        fontFamily: 'BoldHel', 
        fontSize:8,
      },
      text: {
        fontFamily: 'Regular', 
        fontSize:9,
      },
  });
  

const pStyles = StyleSheet.create({
    
    page: {
        backgroundColor: "#ffffff",
        padding: '12.17px 13.55px 0px  13.55px',
        letterSpacing: 0
    },

    page2: {
        backgroundColor: "#ffffff",
        padding: '14px 14px',
        letterSpacing: 0
    },
});

const Doc1 = ({data, dob, dobGuarantor, dobGuarantor1}: any)=>(<>
    <Document>
        <Page size="A4" style={pStyles.page}>
            <View>
                <View style={{flexDirection:'row', display:'flex', alignItems:'flex-end', justifyContent:'space-between'}}>
                    <View>
                        <Image src={require("../src/Maybank.png")} style={{width:154.18, height:43.86}}/>
                        <Text style={{...styles.textMer, marginTop:4.7}}>Eligibility criteria and conditions apply.</Text>
                    </View>
                    <View>
                        <Text style={{...styles.textMerBold, alignSelf:'flex-end'}}><Text style={styles.textMerBoldIta}>i</Text>HP Application Form</Text>
                        <View style={{flexDirection:'row', justifyContent:'space-between', marginTop:3.87}}>
                            <Text style={styles.textMer9}><Text style={styles.text}>☐   </Text>Conventional HP</Text>
                            <Text style={styles.textMer9}><Text style={styles.text}>☐   </Text>Bill of Sale       </Text>

                            <Text style={{...styles.textHelBold, alignSelf:'flex-end'}}>A/A No.:________________________</Text>
                        </View>

                        <Text style={styles.textMer5}>If you wish to have a free credit report, you may obtain it within 30 calendar days from the date of approval or rejection of this application via the credit bureau websites</Text>
                        <Text style={styles.textMer5}>listed below. Alternatively, you may bring the approval or rejection letter and your NRIC to the following credit bureau’s registered office to obtain a free credit report. </Text>
                        <Text style={styles.textMer5}>• Credit Bureau (Singapore) Pte Ltd</Text>
                        <Text style={styles.textMer5}>   www.creditbureau.com.sg</Text>
                    </View>
                    
                    
                </View>
                <Text style={{...styles.textMerIta, marginTop:'4.02px'}}>All information requested in this form is mandatory and must be completed in full. In the event we receive incomplete or inaccurate information your application/request may beg</Text>
                <Text style={styles.textMerIta}>delayed or rejected without further notice to you</Text>
                <View style={{border:'0.5px solid #000', marginTop:2}}>
                    <View style={{padding:'2.74px 6.79px', backgroundColor:'#FFC600'}}>
                        <Text style={styles.textHelBold}>FINANCING REQUIREMENTS</Text>
                    </View>
                    <View style={{flexDirection:'row' }}>
                        <View style={{padding:'0.5px 3.42px',flexDirection:'row', justifyContent:'space-between', width:'40%' , borderRight:'0.5px solid #000'}}>
                            <Text style={styles.textHel8Normal}>Finance Amount : S$</Text>
                            <Text style={styles.textHel8Normal}>Finance Period : {data.tenure}</Text>
                        </View>
                        <View style={{flexDirection:'column', width:'60%' }}>
                            <View style={{flexDirection:'row',borderBottom:'0.5px solid #000', padding:'0.5px 4.44px' }}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>Financing Rate :</Text>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>Flat (Advance / Arrears)</Text>
                            </View>
                            <View style={{flexDirection:'row', padding:'0.5px 4.44px' }}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>Purpose of Facility :</Text>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>Monthly / Annual Rest</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{padding:'2.74px 6.79px', backgroundColor:'#FFC600'}}>
                        <Text style={styles.textHelBold}>DETAILS OF SECURITY</Text>
                    </View>
                    <View style={{flexDirection:'row', borderBottom:'0.5px solid #000' }}>
                        <View style={{padding:'0.5px 3.42px',flexDirection:'row', justifyContent:'space-between', width:'40%' , borderRight:'0.5px solid #000'}}>
                            <Text style={styles.textHel8Normal}>Description :</Text>
                        </View>
                        <View style={{flexDirection:'column', width:'60%' }}>
                            <View style={{flexDirection:'row',padding:'0.5px 4.44px' }}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>☐   New</Text>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>Year of Manufacture :  {data.vehicleManufactured}</Text>
                            </View>
                            <View style={{flexDirection:'row', padding:'0.5px 4.44px' }}>
                                <View style={{width:'100%'}}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>☐   Second-hand (pre-owned)</Text>
                                <Text style={{...styles.textHel8Normal, position:'absolute'}}>x</Text>
                                </View>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>Purchase Price:</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{flexDirection:'row', borderBottom:'0.5px solid #000' }}>
                        <View style={{padding:'0.5px 3.42px',flexDirection:'row', justifyContent:'space-between', width:'40%' , borderRight:'0.5px solid #000'}}>
                            <Text style={styles.textHel8Normal}>Vendor :</Text>
                        </View>
                        <View style={{flexDirection:'row',padding:'0.5px 4.44px', width:'60%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Vehicle Registration No. : {data.vehicleNumber}</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Chassis No. : {data.chassisNumber}</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',  }}>
                        <View style={{padding:'0.5px 3.42px',flexDirection:'row', justifyContent:'space-between', width:'40%' , borderRight:'0.5px solid #000'}}>
                            <Text style={styles.textHel8Normal}>Referred by Dealer :</Text>
                        </View>
                        <View style={{flexDirection:'row',padding:'0.5px 4.44px', width:'60%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Eqpt/Machine Serial No. :</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Engine No. : {data.engineNumber}</Text>
                        </View>
                    </View>
                    <View style={{padding:'2.74px 6.79px', backgroundColor:'#FFC600'}}>
                        <Text style={styles.textHelBold}>APPLICANT’S PARTICULARS</Text>
                    </View>
                    <View style={{flexDirection:'row', borderBottom:'0.5px solid #000'  }}>
                        <View style={{padding:'0.5px 3.42px',flexDirection:'column', justifyContent:'space-between', width:'40%' , borderRight:'0.5px solid #000'}}>
                            <View style={{flexDirection:'row',}}>
                                <Text style={{...styles.textHelBold, width:'100%'}}>☐   Individual</Text>
                                <Text style={{...styles.textHelBold, width:'100%'}}>☐   Company / Firm</Text>
                            </View>
                            <Text style={styles.textHel8Normal}>Name / Company Name :</Text>
                            <Text style={styles.textHel8Normal}>{data.fullName}</Text>
                            <Text style={styles.textHel8Normal}>Alias (if any) :</Text>
                        </View>
                        <View style={{flexDirection:'row',padding:'0.5px 4.44px', width:'60%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Correspondence Address / Business Address :</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>{data.address}</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row', borderBottom:'0.5px solid #000',  }}>
                        <View style={{flexDirection:'row',padding:'0.5px 3.7px', width:'40%', borderRight:'0.5px solid #000' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Gender :   Male /  Female</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Office No. :</Text>
                        </View>
                        <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'60%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Office Fax No. :</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Home Tel No. :</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row', borderBottom:'0.5px solid #000'  }}>
                        <View style={{flexDirection:'row',padding:'0.5px 3.7px', width:'40%', borderRight:'0.5px solid #000' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>* Email : {data.emailID}</Text>
                        </View>
                        <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'60%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>*Mobile Phone No. : {data.contactNumber}</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'column', borderBottom:'0.5px solid #000'  }}>
                        <Text style={{...styles.textHel8Normal, width:'100%'}}>* For Main-Applicant, the mobile no. and email provided above will be used for all your banking services, and the updating of all your existing records.  .       </Text>
                        <Text style={{...styles.textHel8Normal, width:'100%'}}>   Only SG registered mobile no. will receive SMS, alerts & notifications on all banking services.</Text>
                    </View>
                    <View style={{flexDirection:'row', borderBottom:'0.5px solid #000'  }}>
                        <View style={{flexDirection:'row',padding:'0.5px 3.7px', width:'50%' , borderRight:'0.5px solid #000'}}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Marital Status :</Text>
                        </View>
                        <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Country Of Birth :</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row', borderBottom:'0.5px solid #000'  }}>
                        <View style={{flexDirection:'row',padding:'0.5px 3.7px', width:'50%' , borderRight:'0.5px solid #000'}}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Nationality :</Text>
                        </View>
                        <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Place of Incorporation :</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row', borderBottom:'0.5px solid #000'  }}>
                        <View style={{flexDirection:'row',padding:'0.5px 3.7px', width:'50%' , borderRight:'0.5px solid #000'}}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>If foreigner, PR : Yes / No</Text>
                        </View>
                        <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Principal Place of Operation :</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row' }}>
                        <View style={{flexDirection:'row',padding:'0.5px 3.7px', width:'50%' , borderRight:'0.5px solid #000'}}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Passport Expiry Date :</Text>
                        </View>
                        <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'50%' }}>
                            <View style={{flexDirection:'row', width:'50%' }}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>Type of Industry :</Text>
                            </View>
                            <View style={{flexDirection:'row',width:'50%' }}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>No. of Employees :</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{padding:'2.74px 6.79px', backgroundColor:'#C4C5C7'}}>
                        <Text style={styles.textHelBold8}>EMPLOYMENT & INCOME DECLARATION (applicable for individual applicant only)</Text>
                    </View>
                    <View style={{flexDirection:'row', borderBottom:'0.5px solid #000' }}>
                        <View style={{flexDirection:'column',padding:'0.5px 3.7px', width:'50%' , borderRight:'0.5px solid #000'}}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Employer Name :</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>{data.employer}</Text>
                        </View>
                        <View style={{flexDirection:'column',padding:'0.5px 4.22px', width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Employer Address :</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>{data.employmentAddress}</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row', borderBottom:'0.5px solid #000' }}>
                        <View style={{flexDirection:'row',padding:'0.5px 3.7px', width:'50%' , borderRight:'0.5px solid #000'}}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Occupation :  {data.jobTitle}</Text>
                        </View>
                        <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Length of Service : __________years _________months</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row', borderBottom:'0.5px solid #000' }}>
                        <View style={{flexDirection:'row',padding:'0.5px 3.7px', width:'50%' , borderRight:'0.5px solid #000'}}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Self-Employed : No / Yes, no. of employees :</Text>
                        </View>
                        <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Type of Industry:</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',padding:'0.5px 3.7px', }}>
                        <Text style={{...styles.textHel8Normal, width:'25%'}}>Monthly Income : S${data.grossMonthlySalary}</Text>
                        <Text style={{...styles.textHel8Normal, width:'75%'}}>☐  Fully Salaried           ☐   Fully Commissioned           ☐   Freelance / Part-Time            ☐   Salary with Commission</Text>
                    </View>
                    <View style={{padding:'2.74px 6.79px', backgroundColor:'#C4C5C7'}}>
                        <Text style={styles.textHelBold}>OTHER FINANCIAL COMMITMENTS</Text>
                    </View>
                    <View style={{flexDirection:'row', borderBottom:'0.5px solid #000' }}>
                        <View style={{flexDirection:'column',padding:'0.5px 3.7px', width:'50%' , borderRight:'0.5px solid #000'}}>
                            <View style={{flexDirection:'row'}}>
                                <Text style={{...styles.textHel8Normal, width:'100%', textAlign:'center', textDecoration:'underline'}}>Financial Institution</Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', textAlign:'center', textDecoration:'underline'}}>Type Of Loan</Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', textAlign:'center', textDecoration:'underline'}}>Monthly Repayment</Text>
                            </View>
                            <View style={{flexDirection:'row'}}>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>S$_________________</Text>
                            </View>
                            <View style={{flexDirection:'row'}}>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}> </Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}> </Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}> </Text>
                            </View>
                            <View style={{flexDirection:'row'}}>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>S$_________________</Text>
                            </View>
                        </View>
                        <View style={{flexDirection:'column',padding:'0.5px 3.7px', width:'50%' ,}}>
                            <View style={{flexDirection:'row'}}>
                                <Text style={{...styles.textHel8Normal, width:'100%', textAlign:'center', textDecoration:'underline'}}>Financial Institution</Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', textAlign:'center', textDecoration:'underline'}}>Type Of Loan</Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', textAlign:'center', textDecoration:'underline'}}>Monthly Repayment</Text>
                            </View>
                            <View style={{flexDirection:'row'}}>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>S$_________________</Text>
                            </View>
                            <View style={{flexDirection:'row'}}>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}> </Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}> </Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}> </Text>
                            </View>
                            <View style={{flexDirection:'row'}}>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                                <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>S$_________________</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{padding:'2.74px 6.79px', backgroundColor:'#C4C5C7'}}>
                        <Text style={styles.textHelBold}>APPLICANT’S DECLARATION</Text>
                    </View>
                    <View style={{flexDirection:'column',padding:'0.5px 3.7px', }}>
                        <Text style={{...styles.textHel7, width:'100%'}}>1.    Did you receive any discount, rebate or any other benefit from the vendor of the vehicle or any other person? (e.g. car servicing/maintenance package, petrol/shopping vouchers,</Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>cash discount etc).</Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>☐   NO. ☐  YES, I/We have received the following:_________________________________________at a total value of S$________________________ </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>    NOT APPLICABLE</Text>
                        <Text style={{...styles.textHel7, width:'100%'}}> 2. Did you obtain any other credit or hire purchase facility(ies) for the purchase or hire (as the case may be) of the vehicle:</Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>☐   NO. ☐  YES, I/We have received the following:_________________________________________at a total value of S$________________________ </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>☐   NOT APPLICABLE </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>I/We warrant that the above declaration shall remain true, accurate and complete in all respects and at all times and I/We shall immediately notify you in writing, if otherwise. I/We</Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>hereby confirm that I/We have read and understood the “Applicants’ Declaration & Authorisation” and all other pertinent information printed overleaf and agreed that the terms and </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>conditions herein form part of my/our application for hire purchase financing with Maybank Singapore Limited. Further, I/We understand and agree that no partial redemption is </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>allowed upon disbursement.</Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>☐   Yes, I agree to apply for a Maybank Family & Friends Card. My Preferred Limit for the Credit Card is S$:________________________/  ☐   I have no preference for the credit limit </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>for the Credit Card applied for in this application (recommended minimum credit limit is S$2,500 and should be in multiples of S$100). I agree that the preferred credit limit is subject </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>to Bank’s approval and will be applied to all your Maybank Credit Cards, if any. In the event if there is no preferred credit limit stated or selection made, I agree that the credit limit will </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>be assigned at the Bank’s discretion. I have read and understood the “Declaration for Credit Card (ref 0816)”, and have completed the Credit Card Application Form in full, I agree </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>and consent to Maybank Singapore Limited to process my Credit Card application upon approval of my Hire Purchase application. (Applicable for individual applicant who do not hold </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>any Maybank Credit Card only)</Text>
                    </View>
                    <View style={{padding:'2.74px 6.79px', backgroundColor:'#C4C5C7'}}>
                        <Text style={styles.textHelBold}>MARKETING COMMUNICATIONS (optional and applicable to individual only)</Text>
                    </View>
                    <View style={{flexDirection:'column',padding:'0.5px 3.7px', }}>
                        <Text style={{...styles.textHel7, width:'100%'}}>I/We consent1 to Maybank contacting me/us about products and services by</Text>
                        <Text style={{...styles.textHel7, width:'100%'}}> [ ] Post [ ] Email [ ] SMS/other messaging services [ ] Phone Call </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>Notes</Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>1 I/We hereby consent to the collection, use and disclosure of my/our personal data by Maybank Singapore Limited and its related corporations (collectively, “Maybank”), its agents, </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>business partners, marketing partners and the Co-Brand Partner (as may be relevant, depending on the nature of the product which I/We have applied for), as well as authorised </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>service providers, for the purposes of contacting me/us about your and/or their products and services, benefits, promotions and rewards, via the modes selected above and using my/</Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>our contact particulars which Maybank may have in its records from time to time (including where applicable my Singapore telephone number(s). </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>I/We confirm and agree that my/our consents granted herein do not supersede or replace any other consents which may have previously provided to Maybank in respect of my/our </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>personal data, and are additional to any rights which Maybank may have at law to collect, use or disclose my/our personal data, and that leaving any of the boxes above blank will </Text>
                        <Text style={{...styles.textHel7, width:'100%'}}>NOT be treated as a withdrawal of any other consent(s) that I/we may have previously provided to Maybank.</Text>
                        <View style={{flexDirection:'row'}}>
                        <View style={{flexDirection:'column',padding:'2.7px 0px 0px 2.7px', width:'65%' ,}}>
                            <Text style={{...styles.textHel7, width:'100%'}}>Important Notes: </Text>
                            <Text style={{...styles.textHel7, width:'100%'}}>1. The information provided above is solely for the purpose of providing the consent for marketing and does not </Text>
                            <Text style={{...styles.textHel7, width:'100%'}}>serve as a notification for update of personal particulars. </Text>
                            <Text style={{...styles.textHel7, width:'100%'}}>2. I/We understand that if I/we do not wish for the Bank to collect, use or disclose my/our personal data for such</Text>
                            <Text style={{...styles.textHel7, width:'100%'}}> Promotional Purposes, I/we may withdraw my/our consent at any time via forms available through the Internet </Text>
                            <Text style={{...styles.textHel7, width:'100%'}}>Banking (IB) portal or from the Bank’s branches. I/We further understand that if I/we do not wish for third parties </Text>
                            <Text style={{...styles.textHel7, width:'100%'}}>(such as the Bank’s business partners or marketing partners) to collect, use or disclose my personal data f</Text>
                            <Text style={{...styles.textHel7, width:'100%'}}>or such Promotional Purposes,</Text>
                            <Text style={{...styles.textHel7, width:'100%'}}>I/we should contact these third parties directly with my/our request.</Text>
                        </View>
                        <View style={{ width:'35%', justifyContent:'center', height:'100%',  }}>
                            <View style={{ height:'47.75px', borderLeft:'0.5px solid #000', borderTop: '0.5px solid #000', borderBottom:'0.5px solid #000'}}>
                                
                            </View>
                            <Text style={{...styles.textHel7, alignSelf:'center'}}>Signature of APPLICANT</Text>
                        </View>
                        </View>
                    </View>
                </View>
            </View>
            <View style={{flexDirection:'row', width:'100%'}}>
                <View style={{alignSelf:'flex-start'}}>
                    <Text style={{...styles.textHel7, }}>MSL-141i-0921 (1/4) </Text>
                    <Text style={{...styles.textHel7, }}>Sep 2021</Text>
                </View>
                <Text style={{...styles.textHel7, marginLeft:'130px'}}>Maybank Singapore Limited (UEN: 201804195C)</Text>
            </View>
           
        </Page>
        <Page size="A4" style={pStyles.page2}>
            <View style={{border:'0.5px solid #000', marginTop:2}}>
                <View style={{padding:'2.74px 6.79px', backgroundColor:'#FFC600'}}>
                    <Text style={styles.textHelBold}>GUARANTOR’S PARTICULARS</Text>
                </View>
                <View style={{flexDirection:'row', borderBottom:'0.5px solid #000' }}>
                    <View style={{flexDirection:'row',padding:'0.5px 3.7px', width:'50%' , borderRight:'0.5px solid #000'}}>
                        <Text style={{...styles.textHel8Normal, width:'100%'}}>Name : {data.guarantor[0] && data.guarantor[0].fullNameGuarantor}</Text>
                    </View>
                    <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'50%' }}>
                        <View style={{flexDirection:'row', width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Gender : Male / Female</Text>
                        </View>
                        <View style={{flexDirection:'row',width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Marital Status :</Text>
                        </View>
                    </View>
                </View>
                <View style={{flexDirection:'row', borderBottom:'0.5px solid #000' }}>
                    <View style={{flexDirection:'row',padding:'0.5px 3.7px', width:'50%' , borderRight:'0.5px solid #000'}}>
                        <View style={{flexDirection:'row', width:'50%',borderRight:'0.5px solid #000' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Correspondence Address : {data.guarantor[0] && data.guarantor[0].addressGuarantor}</Text>
                        </View>
                        <View style={{flexDirection:'row',width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Date of Birth : {dobGuarantor}</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'50%' }}>
                        <View style={{flexDirection:'row', width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>If Foreigner, PR : Yes / No</Text>
                        </View>
                        <View style={{flexDirection:'row',width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Relationship to Applicant :</Text>
                        </View>
                    </View>
                </View>
                <View style={{flexDirection:'row', borderBottom:'0.5px solid #000' }}>
                    <View style={{flexDirection:'row',padding:'0.5px 3.7px', width:'50%' , borderRight:'0.5px solid #000'}}>
                        <View style={{flexDirection:'row', width:'50%',borderRight:'0.5px solid #000' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}></Text>
                        </View>
                        <View style={{flexDirection:'row',width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Nationality :</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'50%' }}>
                        <View style={{flexDirection:'row', width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Business Regn. No. :</Text>
                        </View>
                    </View>
                </View>
                <View style={{flexDirection:'row', borderBottom:'0.5px solid #000' }}>
                    <View style={{flexDirection:'row',padding:'0.5px 3.7px', width:'50%' , borderRight:'0.5px solid #000'}}>
                        <View style={{flexDirection:'row', width:'50%',borderRight:'0.5px solid #000' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}></Text>
                        </View>
                        <View style={{flexDirection:'row',width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>NRIC / Passport No. :  {data.guarantor[0] && data.guarantor[0].NRICGuarantor}</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'50%' }}>
                        <View style={{flexDirection:'row', width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>Occupation : {data.guarantor[0] && data.guarantor[0].jobTitleGuarantor}</Text>
                        </View>
                        <View style={{flexDirection:'row',width:'50%' }}>
                            <Text style={{...styles.textHel8Normal, width:'100%'}}>^ Mobile Phone No. : {data.guarantor[0] && data.guarantor[0].contactNumberGuarantor}</Text>
                        </View>
                    </View>
                </View>
                <View style={{flexDirection:'row', borderBottom:'0.5px solid #000' }}>
                    <View style={{flexDirection:'column',padding:'0.5px 3.7px', width:'50%' , borderRight:'0.5px solid #000'}}>
                        <Text style={{...styles.textHel8Normal, width:'100%'}}>Employer Name & Address :</Text>
                        <Text style={{...styles.textHel8Normal, width:'100%'}}>{data.guarantor[0] && data.guarantor[0].employerGuarantor}</Text>
                        <Text style={{...styles.textHel8Normal, width:'100%'}}>{data.guarantor[0] && data.guarantor[0].employmentAddressGuarantor}</Text>
                    </View>
                    <View>
                        <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'100%' }}>
                            <View style={{flexDirection:'row', width:'50%' }}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>Self-Employed : No / Yes</Text>
                            </View>
                            <View style={{flexDirection:'row',width:'50%' }}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>^ Email : {data.guarantor[0] && data.guarantor[0].emailIDGuarantor}</Text>
                            </View>
                        </View>
                        <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'100%' }}>
                            <View style={{flexDirection:'row', width:'50%' }}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>Length of Service : _________ years ________ months</Text>
                            </View>
                        </View>
                        <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'100%' }}>
                            <View style={{flexDirection:'row', width:'50%' }}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>Home Tel No. :</Text>
                            </View>
                            <View style={{flexDirection:'row',width:'50%' }}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>Office Tel No. :</Text>
                            </View>
                        </View>
                    </View>                    
                </View>
                <View style={{flexDirection:'row', borderBottom:'0.5px solid #000' }}>
                    <View style={{borderRight:'0.5px solid #000',width:'50%'}}>
                        <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'100%' }}>
                            <View style={{flexDirection:'row', width:'50%' }}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>Monthly Income : S${data.guarantor[0] && data.guarantor[0].grossMonthlySalaryGuarantor}</Text>
                            </View>
                        </View>
                        <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'100%' }}>
                            <View style={{flexDirection:'row', width:'50%' }}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>■ Fully Salaried</Text>
                            </View>
                            <View style={{flexDirection:'row',width:'50%' }}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>■ Fully Commissioned</Text>
                            </View>
                        </View>
                        
                        <View style={{flexDirection:'row',padding:'0.5px 4.22px', width:'100%' }}>
                            <View style={{flexDirection:'row', width:'50%' }}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>■ Salary with Commission</Text>
                            </View>
                            <View style={{flexDirection:'row',width:'50%' }}>
                                <Text style={{...styles.textHel8Normal, width:'100%'}}>■ Freelance / Part-Time</Text>
                            </View>
                        </View>
                    </View>    
                    <View style={{flexDirection:'row',padding:'0.5px 3.7px', width:'50%', justifyContent:'center', alignItems:'center' }}>
                        <Text style={{...styles.textHel8Normal, width:'100%'}}>^ For guarantor, the mobile no. and email provided will be solely used for contacting purpose pertaining to this Hire Purchase application.</Text>
                    </View>                
                </View>
                <View style={{padding:'2.74px 6.79px', backgroundColor:'#C4C5C7'}}>
                    <Text style={styles.textHelBold}>OTHER FINANCIAL COMMITMENTS (for Guarantor)</Text>
                </View>
                <View style={{flexDirection:'row', borderBottom:'0.5px solid #000' }}>
                    <View style={{flexDirection:'column',padding:'0.5px 3.7px', width:'50%' , borderRight:'0.5px solid #000'}}>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{...styles.textHel8Normal, width:'100%', textAlign:'center', textDecoration:'underline'}}>Financial Institution</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', textAlign:'center', textDecoration:'underline'}}>Type Of Loan</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', textAlign:'center', textDecoration:'underline'}}>Monthly Repayment</Text>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>S$_________________</Text>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}> </Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}> </Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}> </Text>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>S$_________________</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'column',padding:'0.5px 3.7px', width:'50%' ,}}>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{...styles.textHel8Normal, width:'100%', textAlign:'center', textDecoration:'underline'}}>Financial Institution</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', textAlign:'center', textDecoration:'underline'}}>Type Of Loan</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', textAlign:'center', textDecoration:'underline'}}>Monthly Repayment</Text>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>S$_________________</Text>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}> </Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}> </Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}> </Text>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>___________________</Text>
                            <Text style={{...styles.textHel8Normal, width:'100%', alignSelf:'center'}}>S$_________________</Text>
                        </View>
                    </View>
                </View>
                <View style={{padding:'1px 3.98px'}}>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>I hereby represent and warrant that the information given above - and in the table printed immediately below which lists my other financial commitments - is</Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>true, complete and correct and that I have not withheld any material fact, information or document. I authorise Maybank Singapore Limited ("MSL") to obtain </Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>any information or document MSL may reasonably require, in order to process this application, from any person or relevant sources. I confirm that I am not</Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>insolvent or an undischarged bankrupt. I agree to the purposes set out in MSL’s Data Protection Policy (the link which is provided below) for the collection, </Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>use and disclosure of my personal data, including authorising MSL to disclose information and/or data relating to myself and my accounts (a) to any of MSL’s</Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>holding companies, representative and branch offices and to any of MSL's related corporation or associated company, in any jurisdiction; (b) to any</Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>government or regulatory agency or authority including but not limited to the Land Transport Authority; (c) to any of MSL's potential assignee or transferee or</Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>to any person who has or may enter into contractual relations with MSL in relation to this Loan Application; (d) to any credit bureau (including,without </Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>limitation,Credit Bureau (Singapore) Pte Ltd), as well as the members of such credit bureau; (e) to any credit reference or evaluation agencies wherever </Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>situated; and (f) to any other person to whom disclosure is permitted or required by any statutory provision or law. The consent in this paragraph shall be in</Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>addition to and shall not in any way prejudiced or affected by any other agreement, expresssed or implied, between me and MSL. By providing the information</Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>in this application form (and any other information that I may provide to you from time to time in connection with MSL products and services): I agree and</Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>consent to MSL and its related corporations (collectively, “Maybank”) collecting, using, disclosing and sharing amongst themselves my personal data, and</Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>disclosing such personal data to Maybank’s authorised service providers and relevant third parties for purposes reasonably required by Maybank to process</Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>the Applicant's application(s), provide the Applicant with the products or services which the Applicant is applying for, as well as the purposes set out in MSL's</Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>Data Protection Policy, which is accessible at www.maybank2u. com.sg or which may be provided to me upon request. I confirm that I have read and </Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>understood the Data Protection Policy. If I should withdraw my consent to the processing or handing of my personal data in respect of any purpose which</Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>Maybank may reasonably consider to be essential in order for Maybank to provide the Applicant with the products and/or services applied for, Maybank shall</Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>be entitled to treat the hire purchase financing as having been terminated by the Applicant without prejudice to any rights and remedies which it may have at</Text>
                    <Text style={{...styles.textHel8Normal, marginTop:'-3px'}}>law against the Applicant and me.</Text>
                    <View style={{alignSelf:'flex-end', marginTop:'30px', justifyContent:'center', alignItems:'center'}}>
                        <Text style={styles.textHel8Normal}>_______________________________________________________</Text>
                        <Text style={styles.textHel8Normal}>Signature of GUARANTOR</Text>
                    </View>
                </View>
                <View style={{padding:'2.74px 6.79px', backgroundColor:'#FFC600'}}>
                    <Text style={styles.textHelBold}>APPLICANT’S DECLARATION & AUTHORIZATION</Text>
                </View>
                <View style={{padding:'2.74px 1px',}}>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>By submitting this application for hire purchase financing, I/we hereby:- </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>1)   Represent and warrant that all information and documents provided by me/us are true, complete and accurate and that I/we have not withheld any material fact, information or document. </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>2)   Agree to provide Maybank Singapore Limited (“MSL”) with all such information and/or documents as MSL may reasonably require from time to time relating to or in connection with my/our application. </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>3)   Authorise MSL to conduct credit checks and to obtain and/or verify information about me/us in accordance with applicable law. </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>4)   Agree to the purposes set out in MSL’s Data Protection Policy (the link which is provided below) for the collection, use and disclosure of my/our personal data, including authorising MSL to disclose any</Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>information and/or data relating to me/us and my/our account(s) with MSL :- </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      (a) to any of MSL’s holding companies, representative and branch offices and to any of MSL’s related corporation or associated company, in any jurisdiction; </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      (b) to any government or regulatory agency or           authority including but not limited to the Land Transport Authority; </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      (c) to any of MSL’s potential assignee or transferee or to any person who has or may enter into contractual relations with MSL in relation to this application; </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      (d) to any credit bureau (including, without limitation, Credit Bureau (Singapore) Pte Ltd), as well as the members of such credit bureau; </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      (e) to any credit reference or evaluation agencies wherever situated; </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      (f) the Hire Purchase, Finance and Leasing Association of Singapore (“HPFLAS”), its successors and assigns, any body or organisation assuming the material functions of HPFLAS in replacement of the same; </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      (g) where applicable, to any auditors for the time being my/our auditors, any solicitors for the time being my/our solicitors and I/we shall pay all costs, charges, fees and other out-of-pocket expenses, whether</Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>           legal or otherwise, in respect of such disclosure on an indemnity basis; and </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>       (h) to any other person to whom disclosure is permitted or required by any statutory provision or law. </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>5)   Confirm that :-</Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>       (i) there are no pending legal, arbitration, enforcement or criminal proceedings commenced against me/us; </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>       (ii) there are no winding up, bankruptcy, judicial management, receivership, scheme of arrangement, or equivalent proceedings against me/us in any jurisdiction; </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>       (iii) I am/We are not insolvent and have not filed or passed any resolution for winding up, bankruptcy, judicial management, receivership, scheme of arrangement, or their equivalent; </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>       (iv) no Statutory Demand under the Bankruptcy Act (Cap. 20) or Companies Act (Cap. 50) has been served on me/us; </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>       (v) I/We have not stopped, suspended, threatened to stop or suspend my/our indebtedness, propose to make a general assignment or arrangement or composition with or for the benefit of my/our creditors or</Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>            a moratorium is agreed or declared in respect of or affected my/our indebtedness; </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>       (vi) I am/We are not in default of and/or in breach of any of my/our loans, borrowings or financing agreements with any banks or financial institutions; </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>       (vii) none of my/our spouse(s), dependent(s) of my/our spouse(s), my/our children, spouses of my/our children, my/our parents, my/our siblings and/or spouses of my/our siblings are employees/directors of </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>              MSL or Malayan Banking Group; </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>       (viii) none of my/our guarantor(s) is an employee/director or spouse, dependent of the spouse, child, spouse of the child, parent, sibling or spouse of the sibling of the employee/director of MSL or Malayan     </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>              Banking Group; </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>       (ix) (if it is a company or firm) none of our directors, managers, agents or guarantors is an employee/director (or spouse, dependent of the spouse, child, spouse of the child, parent, sibling or spouse of the    </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>       sibling of the employee/director) of MSL or MBB Group; </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>       (X) (if it is a company) none of our substantial shareholders* is an employee/director (or spouse, dependent of the spouse, child, spouse of the child, parent, sibling or spouse of the sibling of the employee/</Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>             director) of MSL or MBB Group; and </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      (xi) I am/We are applying for this hire purchase facility for my/our own use and not for the benefit of any other party and will inform MSL in writing immediately if this is not the case. </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>6)   Declared that I/we have not received any discount, rebate or any other benefit from the vendor or any other person, which has the effect of reducing the True Purchase Price (as defined in MAS 642 dated 25 </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>       Feb 13 and as may be replaced, supplemented or amended from time to time), except as already disclosed to you above. </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>7)   I/We have not obtained any other credit facility(ies) for the purchase or hire of the vehicle, except as already disclosed to you above. </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>8)   Acknowledge that MSL reserves the right to reject my/our application for hire purchase financing at its discretion without giving any reason, and that MSL may refuse to disburse the credit or hire purchase </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      facility (as the case may be), terminate, recall, review, and/or vary such facility or any part thereof at any time and at MSL’s discretion in the event any of the above declarations proves to be untrue, inaccurate  </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      or incomplete in any respect of if I/we fail or delay to comply with any request for information or documents as you may reasonably make. </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>9)   Undertake to pay any abortive costs imposed by MSL at its discretion and to release and hold MSL harmless from and indemnify MSL for all loss, damages, costs (including legal costs on an indemnity basis),   </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      expenses and liabilities suffered or incurred by MSL arising from or in connection with any such untrue, inaccurate or incomplete declaration(s), or failure or delay in complying with MSL’s request for  </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      information or documents. </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>10) Confirm and acknowledge that, if my/our application is approved, MSL shall be entitled at any time without notice, to retain and apply any sale proceeds from the realisation of any goods under any agreement  </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      in relation to the hire purchase financing, in or towards any of my debts or liabilities, whether alone or jointly or whether such liabilities be present or future, actual or contingent, primary or collateral and several  </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      or joint. </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>11) Acknowledge that my/our email and mobile number will be updated to receive notifications on all banking services including eStatement, SMS-OTP / Authorisation Code, SMS Alert / OTP for card transactions   </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      and other SMS eServices. </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>12) (where the Applicant is an individual) agree that by providing the information in this application form (and any other information that I may provide to you from time to time in connection with MSL products and      </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      services), I agree and consent to MSL and its related corporations (collectively, “Maybank’’) collecting, using, disclosing and sharing amongst themselves my personal data, and disclosing such personal data to </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      Maybank’s authorised service providers and relevant third parties for purposes reasonably required by Maybank to process my application(s), provide me with the products or services which I am applying for, </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      as well as the purposes set out in MSL’s Data Protection Policy, which is accessible at www.maybank2u.com.sg or which may be provided to me upon request. I confirm I have read and understood the Data </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      Pretection Policy. If I should withdraw my consent to the processing or handing of my personal data in respect of any purpose which Maybank may reasonably consider to be essential in order for Maybank to </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      provide me with the products and/or services applied for, Maybank shall be entitled to treat my hire purchase financing as having been terminated by me without prejudice to any rights and remedies which it </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      may have at law against me. </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>13) (where the Applicant is not a private individual i.e. a company, partnership, limited liability partnership, sole proprietorship or otherwise) agree that we have read, fully understand and accept the terms relating </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      to the collection, use and disclosure of the relevant information (including personal data) as set out in MSL’s prevailing terms and conditions governing the hire purchase financing. We acknowledge and agree   </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      that we are responsible for ensuring that each individual whose personal data has been provided to MSL (including authorised signatories and authorised persons) pursuant to our application(s) hereto and </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      from time to time consents to the collection, use, disclosure and/ or processing of his personal data by MSL and MSL’s authorised service providers for purposes set out in policies, circulars, notices or </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      guidelines governing MSL’s collection, use and/or disclosure of personal data, including MSL’s Data Protection Policy available inter alia at www.maybank2u.com.sg, which MSL may update from time to time </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      (“Data Protection Policies”). We shall ensure that all relevant individuals are furnished with a copy of the terms that apply to the hire purchase financing provided by MSL and the aforesaid Data Protection   </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      Polices. </Text>
                    <Text style={{...styles.textHel6, marginTop:'-0.75px'}}>      * “substantial shareholder’’ means a shareholder having an interest in 5% or more shares in the company.</Text>
                </View>
            </View>
            <View style={{flexDirection:'row', width:'100%'}}>
                <View style={{alignSelf:'flex-start'}}>
                    <Text style={{...styles.textHel7, }}>MSL-141i-0921 (1/4) </Text>
                    <Text style={{...styles.textHel7, }}>Sep 2021</Text>
                </View>
                <Text style={{...styles.textHel7, marginLeft:'130px'}}>Maybank Singapore Limited (UEN: 201804195C)</Text>
            </View>
        </Page>
    </Document>
</>)


const MaybankPdf = React.memo(({ isSave, setPdf, data }:Props) => {
    
    return (
        <BlobProvider document={isSave? <Doc1 data={data} dob={moment(data.dob,'YYYY-MM-DD').format('DD/MM/YYYY')} 
        dobGuarantor={moment(data.guarantor[0]?.dobGuarantor,'YYYY-MM-DD').format('DD/MM/YYYY')}/>:<></>} >
            {({blob})=>{
                    let file;
                    file = blob && new File([blob as Blob], 'maybank.pdf');
                    data && setPdf(file)
                
                return <></>;
            }}
            
        </BlobProvider>
    )
  });

export default MaybankPdf;