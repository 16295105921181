import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isCarMatching: boolean;
  selectedCustomer: string;
  editMenu: boolean;
  anchorEl: any;
  currentAnchorEl: any;
  rows2: {customerId:string,customerName: string, dob: string, phoneNumber: string, email: string, interestedService: string}[];
  rowCarMatching: {car_name: string, catalogue: number, customers:{name: string, contact_number:number, email: string}[]}[];
  pageCustomer: number;
  currentPageCustomer: number;
  pageCar: number;
  currentPageCar: number;
  openModal: boolean;

  valueSearchCusNameCRM: string;
  valueSearchPhoneCRM: string;
  valueSearchEmailCRM: string;
  valueSearchServiceCRM: string;
  valueSearchCarNameCar: string;
  valueSearchCusNameCar: string;
  valueSearchPhoneCar: string;
  valueSearchEmailCar: string;

  searchAttributeCRM: string;
  valueSearchCRM: string;
  searchAttributeCar: string;
  valueSearchCar: string;
  listSearchCustomerName: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfcrm4Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCRMListId: any;
  getCarMatchingListId: any;
  deleteCustomerApiId: any;
  testId: any;
  searchCrmId:any;
  searchCarMatchingId: any;
  searchCustomerNameId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isCarMatching: false,
      selectedCustomer: '',
      editMenu: false,
      anchorEl:null,
      rows2: [],
      pageCustomer:0,
      pageCar:0,
      currentPageCustomer:1,
      currentPageCar:1,
      rowCarMatching: [],
      currentAnchorEl: null,
      openModal: false,
      valueSearchCusNameCRM: '',
      valueSearchPhoneCRM: '',
      valueSearchEmailCRM: '',
      valueSearchServiceCRM: '',
      valueSearchCarNameCar: '',
      valueSearchCusNameCar: '',
      valueSearchPhoneCar: '',
      valueSearchEmailCar: '',

      searchAttributeCRM:'',
      valueSearchCRM: '',
      searchAttributeCar:'',
      valueSearchCar: '',
      listSearchCustomerName: [],
      // Customizable Area End,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    switch (apiRequestCallId) {
      case this.getCRMListId:
        this.getCRMListReceiver(responseJson);
        break;
      case this.getCarMatchingListId:
        this.getCarMatchingListReceiver(responseJson);
        break;
      case this.deleteCustomerApiId:
        this.deleteCustomerReceiver(responseJson);
        break;
      case this.searchCrmId:
        this.getCRMListReceiver(responseJson);
        break;
      case this.searchCarMatchingId:
        this.searchCarMatchingReceiver(responseJson);
        break;
      case this.searchCustomerNameId:
        this.searchCustomerNameReceiver(responseJson);
        break;
      default:
        break;
    }
    // runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getCRMList(1);
    this.getCarMatchingList(1);
  }

  handleSearchCustomerName = async(e: any)=>{
    const value= e.target.value;
    if(e.target.value !== undefined && e.target.value !== ''){
      this.setState({valueSearchCusNameCRM:value})
      const tokenValue = await getStorageData("authToken")
      console.log('3332', e.target.value)
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": tokenValue
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      console.log('333', e.target.value)
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.crmEndPoint}/search?attribute=name&query=${value}&page=1&per_page=10`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      this.searchCustomerNameId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    if(e.target.value === ''){
      this.getCRMList(1)
      this.setState({searchAttributeCRM:'', valueSearchCRM:'', valueSearchCusNameCRM:''})
    }
  }

  searchCustomerNameReceiver = (responseJson: any)=>{
    this.setState({listSearchCustomerName: responseJson?.data?.data.map((e:any)=>e?.attributes)})
  }

  getCRMList = async (page: number) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.crmEndPoint}?page=${page}&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getCRMListId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCRMListReceiver = (responseJson: any) => {
   const data = responseJson.data.data.map((customer:any) => ({
      customerId:customer.id,
      customerName: customer.attributes.name,
      dob: customer.attributes.date_of_birth,
      phoneNumber: customer.attributes.contact_number.toString(),
      email: customer.attributes.email,
      interestedService: customer.attributes.services.map((string: string) => string.charAt(0).toUpperCase() + string.slice(1)).join(', ')
    }));
    this.setState({rows2: data, pageCustomer: Math.ceil(responseJson.pagination.total_count/10)})
  }

  getCarMatchingList = async (page: number) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.carMatchingEndPoint}?page=${page}&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getCarMatchingListId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCarMatchingListReceiver = (responseJson: any) => {
    this.setState({rowCarMatching: responseJson.data, pageCar: Math.ceil(responseJson.pagination?.total_count/10)})
  }

  handleOpenEditMenu = (event:any, data:any) => {
    const anchorEl = event.currentTarget
    this.setState({...this.state, anchorEl, currentAnchorEl: data })
  }
  handleCloseEditMenu = () => {
    this.setState({...this.state, anchorEl:null, currentAnchorEl: null })
  }
  handleAddNewCustomer = () => {
    this.props.navigation.navigate('NewCustomer');
  }

  handleSwitchTab = (tab: number) => {
    this.setState({isCarMatching: tab !== 1})
  }

  handleChangePageCustomer=(_event: any, value:any) =>{ 
    if(this.state.valueSearchCRM.length > 0){
      this.searchCRM(value);
    } else {
      this.getCRMList(value);
    }
    this.setState({currentPageCustomer: value});    
  }

  handleNavigateCustomerGroup = ()=>{
    this.props.navigation.navigate('CustomerGroup');
  }

  handleNavigateBroadcast = () =>{
    this.props.navigation.navigate('Broadcast');
  }
  
  handleChangePageCarMatching=(_event: any, value:any) =>{ 
    if(this.state.valueSearchCar.length > 0){
      this.searchCarMatching(value)
    } else {
      this.getCarMatchingList(value);
    }
    this.setState({currentPageCar: value});
  }

  handleDeleteCustomer = () => {
    this.deleteCustomer(this.state.currentAnchorEl.customerId);
  }

  deleteCustomer = async (id: string) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCustomerApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.crmEndPoint + "/" + `${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteCustomerReceiver = (responseJson: any) =>{
      if(this.state.valueSearchCRM.length > 0 ){
        this.searchCRM(1)
      } else{
        this.getCRMList(1);
        this.setState({currentPageCustomer:1})
      }
    this.handleCloseEditMenu();
    this.setState({openModal: false});
  }

  handleEditCustomer = ()=> {
    this.props.navigation.navigate('EditCustomer', { customerId: this.state.currentAnchorEl.customerId });
  }

  handleModalDelete = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  onChangeSearchCRM =(e:any, attribute: string)=>{
    this.setState({
      valueSearchCRM:e.target.value,
      searchAttributeCRM: attribute,
      currentPageCustomer:1,
    })
    switch (attribute) {
      case 'name':
        this.setState({
          valueSearchCusNameCRM: e.target.value, 
          valueSearchEmailCRM:'',
          valueSearchPhoneCRM: '',
          valueSearchServiceCRM:'',
        })
        break;
      case 'contact_number':
        this.setState({
          valueSearchPhoneCRM: e.target.value, 
          valueSearchCusNameCRM: '', 
          valueSearchEmailCRM:'',
          valueSearchServiceCRM:'',
        })
        break;
      case 'email':
        this.setState({
          valueSearchEmailCRM: e.target.value,
          valueSearchPhoneCRM: '', 
          valueSearchCusNameCRM:'',
          valueSearchServiceCRM:'',
        })
        break;
      case 'services':
        this.setState({
          valueSearchEmailCRM: '',
          valueSearchPhoneCRM: '', 
          valueSearchCusNameCRM:'',
          valueSearchServiceCRM: e.target.value,
        })
        break;
    }
  }

  onChangeSearchCarMatching =(e:any, attribute:string)=>{
    this.setState({
      valueSearchCar:e.target.value,
      searchAttributeCar: attribute,
      currentPageCar:1,
    })
    switch (attribute) {
      case 'car_name':
        this.setState({
          valueSearchCarNameCar: e.target.value,
          valueSearchCusNameCar: '',
          valueSearchPhoneCar: '',
          valueSearchEmailCar: '',
        })
        break;
      case 'name':
        this.setState({
          valueSearchCarNameCar: '',
          valueSearchCusNameCar: e.target.value,
          valueSearchPhoneCar: '',
          valueSearchEmailCar: '',
        })
        break;
      case 'contact_number':
        this.setState({
          valueSearchCarNameCar: '',
          valueSearchCusNameCar: '',
          valueSearchPhoneCar: e.target.value,
          valueSearchEmailCar: '',          
        })
        break;
      case 'email':
        this.setState({
          valueSearchCarNameCar: '',
          valueSearchCusNameCar: '',
          valueSearchPhoneCar: '',
          valueSearchEmailCar: e.target.value
        })
        break;
    }
  }

  shouldComponentUpdate(_nextProps: Readonly<Props>, nextState: Readonly<S>, _nextContext: any): boolean {
    if(nextState.valueSearchCRM !== this.state.valueSearchCRM){
      if(nextState.valueSearchCRM.length > 0 ){
        this.searchCRM(1)
      } else{
        this.getCRMList(1);
        this.setState({currentPageCustomer:1})
      }
    }
    if(nextState.valueSearchCar !== this.state.valueSearchCar){
      if(nextState.valueSearchCar.length > 0 ){
        this.searchCarMatching(1)
      } else{
        this.getCarMatchingList(1);
        this.setState({currentPageCar:1})
      }
    }
    return true;
  }

  searchCRM = async (page:number) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.crmEndPoint}/search?attribute=${this.state.searchAttributeCRM}&query=${this.state.valueSearchCRM}&page=${page}&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.searchCrmId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  searchCarMatching = async (page: number) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.crmEndPoint}/car_matching_search?attribute=${this.state.searchAttributeCar}&query=${this.state.valueSearchCar}&page=${page}&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.searchCarMatchingId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  searchCarMatchingReceiver = (responseJson: any) => {
    this.setState({rowCarMatching: responseJson.data, pageCar: Math.ceil(responseJson.pagination?.total_count/10)})
  }

  // Customizable Area End
}