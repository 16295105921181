import React from "react";
// Customizable Area Start
import { Box, MenuProps,Menu, MenuItem, ListItemIcon, ListItemText, BoxProps, Button, Grid, IconButton, Input, InputAdornment, Modal, Table, TableBody, TableCell, TableContainer, TableRow, Typography, withWidth ,TableHead, CircularProgress} from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";
import LandingPageController, {
  Props
} from "./LandingPageController";
import * as Yup from "yup";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom'
import { RightArrow, bookImg, carBookImg, chartImg, hidePassword, octiconImg, showPassword } from "./assets";
import clsx from 'clsx';
import { Formik } from "formik";
import Calendar from 'react-calendar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from "moment"
import DateRangeIcon from '@material-ui/icons/DateRange';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplayIcon from '@material-ui/icons/Replay';
import CustomStatus from "../../../components/src/CustomStatus";
// Customizable Area End
// Customizable Area Start
// Customizable Area End

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  addVehicleModal = () => {
    return <Modal open={this.state.openModal}>
      <ModalStyle>
        <Box className="mainModalRBox">
          <Box className="modalResetMainWrap">
            <Typography className="modalResetTitleText">Password Update</Typography>
            <IconButton onClick={this.handleClickOpen}>
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </Box>
          <Box className="lineResetModal" />
          <Box className="modalResetWrap">
            <Typography className="titleModalResetText">Your account has been successfully created, please Reset your password and continue.</Typography>
          </Box>
          <Box className="lineResetModal" />
          <Box className="modalResetBtnWrap">
            <Button className="modalResetCancelBtn" onClick={this.handleClickOpen}>Cancel</Button>
            <Button className="modalCreatePassBtn" onClick={this.handleCreatePassword}>Create password</Button>
          </Box>
        </Box>
      </ModalStyle>
    </Modal>
  }

  passSchema = () => {
    return Yup.object().shape({
      newPassword: Yup.string()
        .required("Please Enter Your Password")
        .min(8, " ")
        .max(25)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])/,
          " "
        )
        .matches(
          /^(?=.*[!@#\$%\^&\*])/,
          " "
        )
        .matches(
          /^(?=.{6,20}$)\D*\d/,
          " "
        ),
      confirmNewPassword: Yup.string().required("Please Enter Your New Password")
        .oneOf([Yup.ref('newPassword'), null], 'Entered password does not match')
    });
  };

  resetPasswoerModal = () => {
    return <Modal open={this.state.openResetPassModal}>
      <ResetModalStyle>
        <Box className="mainModalRBox">
          <Box className="logintextBox">
            <Typography
              className="resetText">
              Create Password
            </Typography>
            <Formik
              initialValues={{
                confirmNewPassword: this.state.confirmNewPassword,
                newPassword: this.state.newPassword,
              }}
              data-test-id="formik"
              validationSchema={this.passSchema}
              validateOnChange={this.state.formIsDirtyCreate}
              validateOnBlur={this.state.formIsDirtyCreate}
              onSubmit={(values) => {
                this.resetPassApi();
              }}
            >
              {({ setFieldValue, errors, handleSubmit }) => (
                <form
                  onSubmit={handleSubmit}
                  noValidate autoComplete="off"
                >
                  <Typography
                    className="labelTextR"
                  >New password
                  </Typography>
                  <Input
                    className={
                      errors.newPassword ? 'inputField error' : 'inputField'
                    }
                    placeholder="Enter New password"
                    data-test-id='password'
                    disableUnderline
                    value={this.state.newPassword}
                    fullWidth
                    onChange={(event) => {
                      this.onValueChangelog("newPassword", event.target.value);
                      setFieldValue("newPassword", event.target.value);
                    }}
                    type={this.state.showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment
                        position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          data-test-id='handleClickShowPassword'
                          onClick={this.handleClickShowPassword}
                          edge="end"
                        >
                          {this.state.showPassword
                            ? <img src={showPassword} />
                            : <img src={hidePassword} />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Typography className="errorsMsg">{errors.newPassword}</Typography>
                  <Typography
                    className="labelTextR"
                  >Confirm New password
                  </Typography>
                  <Input
                    placeholder="Enter Confirm New Password"
                    className={errors.confirmNewPassword ? 'inputField error' : 'inputField'}
                    value={this.state.confirmNewPassword}
                    data-test-id='confirmPassword'
                    onChange={(event) => {
                      this.onValueChanges("confirmNewPassword", event.target.value);
                      setFieldValue("confirmNewPassword", event.target.value);
                    }}
                    fullWidth
                    disableUnderline
                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={this.handleShowConfirm}
                          aria-label="toggle password visibility"
                          id="showConfirm"
                          edge="end"
                        >
                          {this.state.showConfirmPassword
                            ? <img src={showPassword} />
                            : <img src={hidePassword} />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Typography
                    className="errorsMsg">
                    {errors.confirmNewPassword}
                  </Typography>
                  <Box className="errorsMsgBox">
                    {this.state.errorValue.length > 0 && !this.state.error.minimum && <Typography className="errorsMsg">
                      1. A minimum of 8 characters
                    </Typography>}
                    {this.state.errorValue.length > 0 && !this.state.error.upper && <Typography className="errorsMsg">
                      2. Include a mix of uppercase and lowercase letters.
                    </Typography>}
                    {this.state.errorValue.length > 0 && !this.state.error.number && <Typography className="errorsMsg">
                      3. Include at least one number.
                    </Typography>}
                    {this.state.errorValue.length > 0 && !this.state.error.special && <Typography className="errorsMsg">
                      4. Include at least one special character
                    </Typography>}
                  </Box>
                  
                  <Button data-test-id='resetBtn'
                    className={`resetBtn ${errors.confirmNewPassword ? 'disabledBtn' : 'resetBtn'}`}
                    disabled={!errors.confirmNewPassword === false}
                    type="submit" onClick={this.checkSubmitCreate}>
                    Reset Password
                  </Button>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </ResetModalStyle>
    </Modal>
  }
  successfully = () => {
    return <Modal open={this.state.openSuccessfullyModal}>
      <SuccessfullyStyle>
        <Box className="mainModalRBox">
          <Box className="modalResetMainWrap">
            <Typography className="loginText">Create password</Typography>
            <IconButton onClick={this.handleSuccessfullyOpen}>
              <CloseIcon style={{color: "white"}}/>
            </IconButton>
          </Box>
          <Box className="lineResetModal" />
          <Box className="modalResetWrap">
            <Typography className="titleModalResetText">Password has been successfully Created !</Typography>
          </Box>
        </Box>
      </SuccessfullyStyle>
    </Modal>
  }

  renderLoading = () => {
    return <Modal open={this.state.modalLoadingLandingPage}>
      <ModalStyle>
        <Box className="wrapModal">
          <Box className="wrapModalContentLoading">
            <CircularProgress className="iconLoading" size={20} />
            <Typography className="loadingText">Please wait while we are Uploading your post</Typography>
          </Box>
        </Box> 
      </ModalStyle>
    </Modal>
}
  // Customizable Area End 

  render() {
    // Customizable Area Start
    const BoxView = Box as React.FC<BoxProps>
    const { width } = this.props;
    // 

      const isDateRangeSelected = this.state.startDate && this.state.endDate;

      // Generate the toggle button text based on whether a date range is selected
      const toggleButtonText = isDateRangeSelected
        ? `${moment(this.state.startDate).format('DD MMM')} - ${moment(this.state.endDate).format('DD MMM YYYY')}`
        : 'Select Date Range';
    const { accountingPayrollData, activeTab, loading, marketing } = this.state
    
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <LandingStyle>
        <Grid container className={clsx({ "padding30": width !== 'xs', 'padding30xs': width == 'xs' })}>
          {this.state.PassReset === "deactive" && this.addVehicleModal()}
          {this.state.openResetPassModal && this.resetPasswoerModal()}
          {this.successfully()}
          {this.renderLoading()}
          <Grid item md={12} xs={12}>
            <BoxView className="tabMainBoxWrap">
              <BoxView className="tabBoxWrap">
                <Typography
                  className={` ${this.state.activeTab === 'invidual' ? 'activeTabBtn' : 'tabBtnText'}`}
                  data-test-id="Individual"
                  onClick={() => this.handleTabClick('invidual')}
                >
                  Individual
                </Typography>
                <Typography
                  className={` ${this.state.activeTab === 'team' ? 'activeTabBtn' : 'tabBtnText'}`}
                  data-test-id="Team"
                  onClick={() => this.handleTabClick('team')}
                >
                  Team
                </Typography>
              </BoxView>
              <BoxView className="viewMoreBtnBox" onClick={() => this.handleViewMoreAccountingAndPayroll()}>
                <Typography className="viewMoreBtnText">View more</Typography>
                <img src={RightArrow} />
              </BoxView>
            </BoxView>
          </Grid>
          <Grid item container className="panelMainBoxWrap">
            <Grid item xs={12} md={8} className="panelMainBox">
              {
                loading ? "Loading" : <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <BoxView className="panelBox">
                      <img src={octiconImg} />
                      <BoxView  className="panelTextBox">
                        <Typography className="panelTitleText">{this.state.activeTab === 'invidual' ? 'Individual ' : ''}Profit</Typography>
                        <Typography className="panelSubText">${accountingPayrollData[activeTab]?.Profit}</Typography>
                      </BoxView>
                    </BoxView>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BoxView className="panelBox">
                      <img src={chartImg} />
                      <BoxView className="panelTextBox">
                        <Typography className="panelTitleText">{this.state.activeTab === 'invidual' ? 'Individual ' : ''}Sales</Typography>
                        <Typography className="panelSubText">{accountingPayrollData[activeTab]?.Sales}</Typography>
                      </BoxView>
                    </BoxView>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BoxView className="panelBox">
                      <img src={carBookImg} />
                      <BoxView className="panelTextBox">
                        <Typography className="panelTitleText">Individual</Typography>
                        <Typography className="panelSubText">{accountingPayrollData[activeTab]?.No_of_vehicles_added}</Typography>
                      </BoxView>
                    </BoxView>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BoxView className="panelBox">
                      <img src={bookImg} />
                      <BoxView className="panelTextBox">
                        <Typography className="panelTitleText">{this.state.activeTab === 'invidual' ? 'Individual ' : ''}Pending Cases</Typography>
                        <Typography className="panelSubText">{accountingPayrollData[activeTab]?.Pending_cases}</Typography>
                      </BoxView>
                    </BoxView>
                  </Grid>
                </Grid>
              }

            </Grid>
            <Grid item xs={12} md className='wrapDate'>
              <div style={{ position: 'relative' }} className="customebtn">
                <button className="customButton" onClick={this.toggleCalendar}>{width === "xs" ? <DateRangeIcon /> : toggleButtonText}  <ExpandMoreIcon className="expandIcon" /></button>
                {this.state.isCalendarOpen && ( // Render calendar only when isCalendarOpen is true
                  <div className="rangeCalendar"  >
                    <Calendar
                      data-test-id="Calendar"
                      onChange={this.handleDateChange}
                      selectRange={true}
                      value={[this.state.startDate, this.state.endDate]}
                      className="customCalendar" />
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} className='marginTop31'>
            <BoxView className="titleMainBoxWrap">
              <Typography className="titleText">CRM</Typography>
              <BoxView className="viewMoreBtnBox" onClick={() => this.handleViewMoreCRM()}>
                <Typography className="viewMoreBtnText">View more</Typography>
                <img src={RightArrow} />
              </BoxView>
            </BoxView>

            <TableContainer className={clsx({ 'width300': width === 'xs', 'width100': width !== 'xs' })}>
              <Table>
                <TableBody>
                  {this.state.rowCRM.map((item: any) =>
                    <TableRow>
                      <StyledTableCell align="left" width={'20%'}>{item.attributes.name}</StyledTableCell>
                      <StyledTableCell align="left">{item.attributes.date_of_birth}</StyledTableCell>
                      <StyledTableCell align="left">{item.attributes.contact_number}</StyledTableCell>
                      <StyledTableCell align="left">{item.attributes.email}</StyledTableCell>
                      <StyledTableCell align="left">{item.attributes.services.map((string: string) => string.charAt(0).toUpperCase() + string.slice(1)).join(', ')}</StyledTableCell>
                    </TableRow>)}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item md={12} xs={12} className='marginTop31'>
            <BoxView className="titleMainBoxWrap">
              <Typography className="titleText">Sales Overview</Typography>
              <BoxView className="viewMoreBtnBox" onClick={() => this.handleViewMoreDeal()}>
                <Typography className="viewMoreBtnText">View more</Typography>
                <img src={RightArrow} />
              </BoxView>
            </BoxView>
            <BoxView>
              <TableContainer className={clsx({ 'width300': width === 'xs', 'width100': width !== 'xs' })}>
                <Table>
                  <TableBody>
                    {this.state.deals.map((item: any, index: number) =>
                      <TableRow>
                        <StyledTableCell className="tableCellBorder" align="left">{item.attributes.vahicle_name}</StyledTableCell>
                        <StyledTableCell className="tableCellBorder" align="left">{item.id}</StyledTableCell>
                        <StyledTableCell className="tableCellBorder" align="left">{item.attributes.vehicle_number}</StyledTableCell>
                        <StyledTableCell className="tableCellBorder" align="left">{item.attributes.customer_detail.full_name}</StyledTableCell>
                        <StyledTableCell className="tableCellBorder" align="left">{new  Date(item.attributes.created_at).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}</StyledTableCell>
                        <StyledTableCell className="tableCellBorder" align="left">${item.attributes.vehicle_price}</StyledTableCell>
                        <StyledTableCell className="tableCellBorder" align="left">
                          <CustomStatus status={item.attributes.status}/>
                        </StyledTableCell>
                      </TableRow>)}
                  </TableBody>
                </Table>
              </TableContainer>
            </BoxView>
          </Grid>
          <Grid item md={12} xs={12} className='marginTop31'>
            <BoxView className="titleMainBoxWrap">
              <Typography className="titleText">Catalogue</Typography>
              <BoxView className="viewMoreBtnBox" onClick={() => this.handleViewMoreCatalogue()}>
                <Typography className="viewMoreBtnText">View more</Typography>
                <img src={RightArrow} />
              </BoxView>
            </BoxView>
            <BoxView className="catalougeBoxWrap">
              {this.state.rowCatalogue.map((item: any) =>
                <BoxView className={clsx({ "catalogueMainBox": width !== 'xs', 'catalogueMainBoxxs': width == 'xs' })}>
                  <BoxView>
                    <img src={item.attributes.images && item.attributes.images[0].url} className="catalogueImgBox" />
                  </BoxView>
                  <BoxView className="catalougeDetailBox">
                    <Typography className="catalogueOwnerText">{item.attributes.account_name}</Typography>
                    <BoxView className="makeModelBox">
                      <Typography className="makeModelText">{item.attributes.name}</Typography>
                      <Typography className="idText">ID : {item.id}</Typography>
                    </BoxView>
                    <Typography className="priceText">${item.attributes.selling_price}</Typography>
                    <Typography className="catalogueSubText">{item.attributes.depreciation}</Typography>
                    <Typography className="catalogueSubText">{item.attributes.reg_date}</Typography>
                    <Typography className="catalogueSubText">{item.attributes.power}</Typography>
                    <Typography className="catalogueSubText">{item.attributes.mileage}</Typography>
                    <Typography className="catalogueSubText">{item.attributes.vehicle_type}</Typography>
                  </BoxView>
                </BoxView>
              )}
            </BoxView>
          </Grid>
          <Grid item md={12} xs={12} className='marginTop31'>
            <BoxView className="titleMainBoxWrap">
              <Typography className="titleText">Marketing</Typography>
              <BoxView className="viewMoreBtnBox">
                <Link style={{ textDecoration: "none" }} to="/Marketing"> <Typography className="viewMoreBtnText">View more</Typography></Link>

                <img src={RightArrow} />
              </BoxView>
            </BoxView>
            <BoxView>
              <TableContainer className={clsx({ 'width300': width === 'xs', 'width100': width !== 'xs' })}>
                <Table>
                  <TableBody>
                    {marketing?.data?.map((item: any, index: number) => <TableRow>
                      <StyledTableCell className="tableCellBorder" align="left">{item.attributes.vehicle_name}</StyledTableCell>
                      <StyledTableCell className="tableCellBorder" align="left">{item.attributes.platform.map((item: string) => item.replace(/\s/g, '')).join(', ')}</StyledTableCell>
                      <StyledTableCell className="tableCellBorder" align="left">{moment(item.attributes.created_at).format("DD/MM/YYYY")}</StyledTableCell>
                      <StyledTableCell className="tableCellBorder" align="left">
                        <Typography className={item.attributes.status === "SUCCESSFUL" ? "Successful" : "unSuccessful"}>{item.attributes.status}</Typography>
                      </StyledTableCell>
                            {item.attributes.status === "UNSUCCESSFUL" ? <StyledTableCell className="tableCellBorder" align="left">

                                  <Box data-test-id="moreicon" aria-haspopup="true" onClick={(event) => this.handleOpenClick(event, item)}>
                                      <MoreVertIcon />
                                  </Box>
                                  <StyledMenu
                                      id="simple-menu"
                                      anchorEl={this.state.anchorEl}
                                      keepMounted
                                      open={Boolean(this.state.anchorEl)}
                                      onClose={this.handleCloseModal}
                                  >
                                      
                                      <MenuItem data-test-id="Retry" onClick={this.handleRetryLandingPage}>
                                          <ListItemIcon>
                                              <ReplayIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Retry" />
                                      </MenuItem>
                                      <MenuItem data-test-id="Delete" onClick={() => this.handleCloseModal("Delete")}>
                                          <ListItemIcon>
                                              <DeleteIcon />
                                          </ListItemIcon>
                                          <ListItemText primary="Delete" />
                                      </MenuItem>
                                  </StyledMenu>
                                  </StyledTableCell> : <StyledTableCell className="tableCellBorder" align="left">

                                  <Box data-test-id="moreicon1" aria-haspopup="true" onClick={(event) => this.handleOpenClick1(event, item)}>
                                  <MoreVertIcon />
                                  </Box>
                                  <StyledMenu
                                  id="simple-menu1"
                                  anchorEl={this.state.anchorEl1}
                                  keepMounted
                                  open={Boolean(this.state.anchorEl1)}
                                  onClose={this.handleClose1Modal}
                                  >

                                  <MenuItem data-test-id="Delete1" onClick={() => this.handleClose1Modal("Delete")}>
                                      <ListItemIcon>
                                          <DeleteIcon />
                                      </ListItemIcon>
                                      <ListItemText primary="Delete" />
                                  </MenuItem>
                                  </StyledMenu>
                                  </StyledTableCell>}
                    </TableRow>)}

                  </TableBody>
                </Table>
              </TableContainer>
            </BoxView>
          </Grid>
        </Grid>
      </LandingStyle>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const SuccessfullyStyle = styled(Box)(({ theme }) => ({
  "& .MuiTypography-body1": {
    fontFamily: "Outfit",
  },
  '& .mainModalRBox': {
    padding: "10px",
    position: 'absolute' as 'absolute',
    backgroundColor: (theme as any).typography.body2,
    borderRadius: "16px",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "35%",
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  '& .modalResetMainWrap': {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    flexDirection: "row",
  },
  "& .loginText": {
    fontSize: "30px",
    fontStyle: "normal",
    fontFamily: "Outfit",
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    backgroundClip: "text",
    fontWeight: 700,
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
  },
  '& .lineResetModal': {
    backgroundColor: "#FFF6DC30",
    height: "1px",
  },
  "& .modalResetWrap": {
    paddingTop: "5%",
    paddingLeft: "5%",
    paddingRight: "30%",
    paddingBottom: "5%",
  },
  '& .titleModalResetText': {
    color: (theme as any).typography.h2,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
}));
const ResetModalStyle = styled(Box)(({ theme }) => ({
  "& .MuiTypography-body1": {
    fontFamily: "Outfit",
  },
  '& .modalResetMainWrap': {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    flexDirection: "row",
  },
  '& .mainModalRBox': {
    padding: "30px",
    position: 'absolute' as 'absolute',
    backgroundColor: (theme as any).typography.body2,
    borderRadius: "16px",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "35%",
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  "& .resetText": {
    fontSize: "38px",
    fontStyle: "normal",
    fontFamily: "Outfit",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    backgroundClip: "text",
    fontWeight: 700,
  },
  "& .labelTextR": {
    color: (theme as any).typography.h1,
    fontWeight: 700,
    fontSize: "14px",
    marginTop: "24px",
    fontStyle: "normal",
    fontFamily: "Outfit",
  },
  "& .form": {
    width: '100%'
  },
  "& .inputField": {
    color: (theme as any).typography.h3,
    background: (theme as any).typography.body2,
    border: "2px solid var(--neutrals-cool-gray-200, #E2E8F0)",
    borderRadius: 8,
    fontWeight: 400,
    fontSize: "16px",
    fontStyle: "normal",
    marginTop: '4px',
    padding: '10px 8px',
    fontFamily: "Outfit",
  },
  "& .MuiSvgIcon-root": {
    height: "20px",
    fill: "white",
    width: "20px",
  },
  "& .resetBtn": {
    fontWeight: 700,
    alignSelf: "stretch",
    gap: "8px",
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    color: "var(--text-primary, #171717)",
    fontFamily: "Outfit",
    fontStyle: "normal",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    textTransform: 'none',
    height: "56px",
    padding: "16px",
    marginTop: '56px',
    borderRadius: "8px",
    alignItems: "center",
    fontSize: "16px",  
  },
  "& .errorsMsg": {
    color: "var(--status-red-400, #F87171)",
    fontStyle: "normal",
    fontFamily: "Outfit",
    fontWeight: 400,
    fontSize: "12px",
  },
  "& .disabledBtn": {
    opacity: 0.5,
    background: "#FFF"
  },
  "& .error": {
    borderColor: "#F87171",
  },
  "& .errorsMsgBox": {
    marginTop: "16px",
  },
}));
const ModalStyle = styled(Box)(({ theme }) => ({
  "& .MuiTypography-body1": {
    fontFamily: "Outfit",
  },
  '& .wrapModal': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    backgroundColor:(theme as any).typography.body2, 
    borderRadius: "16px",
    minWidth:'40%'
  },
  '& .loadingText': {
    marginTop: 24,
  color: (theme as any).typography.h1, 
  fontFamily: "Outfit",
  fontSize:"16px",
  fontWeight: 700,
},
  '& .mainModalRBox': {
    position: 'absolute' as 'absolute',
    backgroundColor: (theme as any).typography.body2,
    borderRadius: "16px",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "35%",
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  
  '& .iconLoading': {
    color:'#CEAE71', 
    alignSelf:'center'
  },
  
  
  '& .modalResetMainWrap': {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 20px",
    flexDirection: "row",
  },
  '& .modalResetTitleText': {
    color: (theme as any).typography.h1,
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "Outfit",
    display: "flex",
    alignItems: "center",
  },
  '& .wrapModalContentLoading': {
    justifyContent: 'center',
    padding: '40px',
    alignItems:'center',
    width:'100%',
    display:'flex',
    flexDirection: 'column'
  },
  '& .titleModalResetText': {
    color: (theme as any).typography.h2,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  '& .lineResetModal': {
    backgroundColor: "#FFF6DC30",
    height: "1px",
  },
  "& .modalResetWrap": {
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingBottom: "10%",
    paddingTop: "10%",
  },
  '& .modalResetBtnWrap': {
    display: "flex",
    justifyContent: "flex-end",
    padding: "15px"
  },
  '& .modalResetCancelBtn': {
    backgroundColor: "#3F3E3A",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    maxWidth: "140px",
    padding: "20px",
    height: "56px",
    borderRadius: "8px",
    color: "#CEAE71",
    fontFamily: "Outfit",
  },
  '& .modalCreatePassBtn': {
    marginLeft: "16px",
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    borderRadius: "8px",
    color: (theme as any).typography.body1,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    width: "auto",
    height: "56px"
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    verticalAlign: 'center',
    border: 'none',
    borderCollapse: 'separate',
    borderSpacing: '0px 4px',
    backgroundColor: 'red',
    color: (theme as any).typography.h1,
    fontSize: '14px',
    fontFamily: 'Outfit',
    fontWeight: 700,
  },
  body: {
    
    backgroundColor: (theme as any).typography.body2,
    fontFamily: 'Outfit',
    fontWeight: 400,
    border: 'none',
    color: (theme as any).typography.h1,
    fontSize: '16px',
    borderCollapse: 'separate',
    borderSpacing: '0px 4px'
  },
}))(TableCell)

const LandingStyle = styled(Box)(({ theme }) => ({
  '& .customCalendar': {
    border: `1px solid ${(theme as any).typography.border}`,
    background: (theme as any).typography.body2,
    color: (theme as any).typography.h1,
    display: 'inline-flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    borderRadius: '8px',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)'
  },
  '& .textCell': {
    fontSize: 16,
    fontFamily: 'Outfit',
    fontWeight: '500',
    color: (theme as any).typography.h1,
},
'& .customButton': {
  display: 'inline-flex',
  height: '32px',
  padding: '6px 10px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
  flexShrink: 0,
  borderRadius: '8px',
  border: `1px solid ${(theme as any).typography.border}`,
  background: (theme as any).typography.body2,
  position: 'relative',
  color: (theme as any).typography.h1,
  zIndex: 1, /* Ensure button is above the arrow */
  fontFamily:'Outfit',
  fontWeight:'700',
  fontSize:16
},

  
  '& .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus': {
    background: (theme as any).typography.body1,
  },
  '& .catalogueImgBox': {
    width: '75px',
    height: '75px',
    borderRadius: "4px",
    resizeMode: 'cover'
  },
  '& .marginTop31': {
    marginTop: '31px',
  },
  '& .width300': {
    marginTop: '24px',
    width: '327px',
  },
  '& .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus': {
    background: (theme as any).typography.body1,
  },
  '& .width100': {
    marginTop: '24px',
    width: '100%',
  },
  '& .padding30': {
    padding: "30px",
  },
  "& .rangeCalendar ": { position: 'absolute', top: '150%', left: '-11rem',
  '@media (max-width: 600px)': {
    left: '-3.5rem',
    position: 'absolute', top: '150%',
  },
},
  '& .padding30xs': {
    width: 'fit-content',
    padding: "30px",
  },
  '& .react-calendar__navigation button:enabled:focus': {
    background: (theme as any).typography.body1,
  },
 
  "& .mainContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "27px",
    padding: '40px',
    minHeight: "100vh",
  },
  '& .react-calendar__navigation': {
    display: 'flex',
    height: '44px',
    marginBottom: '1em',

    width: '100%',
    color: (theme as any).typography.h1,
  },
  "& .MuiTypography-body1": {
    fontFamily: "Outfit",
  },
  "& .tabMainBoxWrap": {
    display: "flex",
    justifyContent: "space-between",
  },
  '& .react-calendar__tile--active': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important",
    },
    borderRadius: '0', /* Reset border radius for all tiles */
    '&:first-of-type': {
      borderTopLeftRadius: '35px',
      borderBottomLeftRadius: '35px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '35px',
      borderBottomRightRadius: '35px',
    },
  },
  
  "& .tabBoxWrap": {
    display: "inline-flex",
    padding: "10px",
    alignItems: "center",
    gap: "24px",
    borderRadius: "8px",
    border: `1px solid ${(theme as any).typography.border}`,
    background: (theme as any).typography.body2,
    marginRight: 10
  },
  "& .activeTabBtn": {
    display: "flex",
    padding: "8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    color: "#171717",
    background:
      "var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    cursor: "pointer",
    fontFamily: "Outfit",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    '@media (max-width: 600px)': {
      fontSize: 10,
    },
  },
  '& .react-calendar__tile': {
    
  },
  "& .tabBtnText": {
    cursor: "pointer",
    fontFamily: "Outfit",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    color: (theme as any).typography.h1,
    '@media (max-width: 600px)': {
      fontSize: 10,
    },
  },
  "& .viewMoreBtnBox": {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  '& .react-calendar__tile--hover': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%)) !important',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important"
    }
  },
  "& .viewMoreBtnText": {
    cursor: "pointer",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    background:
      "var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  "& .panelMainBoxWrap": {
    marginTop: '27px'
  },
  "& .panelMainBox": {
    marginBottom: 10
  },
  '& .react-calendar, .react-calendar *, .react-calendar ::before, .react-calendar ::after': {
    boxSizing: 'border-box',
    color: (theme as any).typography.h1,
  },
  "& .panelBox": {
    display: "flex",
    padding: "24px",
    gap: "30px",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "8px",
    border: `1px solid ${(theme as any).typography.border}`,
    background: (theme as any).typography.body2,
    height:'130px' ,
  },
  '& .react-calendar__tile--now': {
    background: (theme as any).typography.body1,
    opacity: 9,
  },

  "& .panelTextBox": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  "& .panelTitleText": {
    color: theme.typography.h1,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    overflow: "hiden"
  },
  "& .selectDate": {
    backgroundColor: "#2B2B2B",
    border: '1px solid #fff6dc30',
    borderRadius: 8,
    width: '187px',
    height: 'fit-content'
  },
  '& .react-calendar__month-view__weekdays__weekday': {
    color: (theme as any).typography.h1,
  },
  
  "& .wrapDate": {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    gap: 10
  },
  '& .react-calendar__navigation__prev2-button  ': { display: "none" },
  
  "& .panelSubText": {
    color: (theme as any).typography.h1,
    fontFamily: "Avenir",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 500,
    overflow: "hidden", 
    textOverflow: "ellipsis",
    wordBreak:'break-all'
  },
  "& .inputField": {
    display: "inline-flex",
    width: "70%",
    padding: "5px 90px",
    color: (theme as any).typography.h1,
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    borderRadius: "8px",
    border: "1px solid var(--border, rgba(255, 246, 220, 0.30))",
    background: "var(--surface, #2B2B2B)"
  },
  "& .react-calendar__navigation__next2-button": {
    display: "none"
  },
  "& .titleMainBoxWrap": {
    display: "flex",
    justifyContent: "space-between"
  },
  "& .titleText": {
    color: theme.typography.h1,
    fontFamily: "Outfit",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  "& .tableCellBorder": {
    borderBottom: "none",
  },
  "& .pandding": {
    display: "flex",
    padding: "2px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "2px",
    borderRadius: "40px",
    background: "var(--Status-Amber-100, #FEF3C7)",
    color: "var(--Status-Amber-600, #D97706)",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase"
  },
  "& .catalogueMainBox": {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    background: (theme as any).typography.body2,
    padding: "8px",
    flexDirection: 'row',
    marginBottom: '16px',
    gap: '10px',
    overflow: 'auto'
  },
  "& .catalogueMainBoxxs": {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    background: (theme as any).typography.body2,
    padding: "8px",
    flexDirection: 'row',
    marginBottom: '16px',
    width: '327px',
    overflowX: 'scroll',
    gap: '10px',
  },
  "& .catalougeBoxWrap": {
    display: "flex",
    flexDirection: "column",
    marginTop: '24px',
  },
  "& .catalougeDetailBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    padding: "8px",
    width: "100%",

  },
  "& .catalogueOwnerText": {
    color: (theme as any).typography.h1,
    textOverflow: "ellipsis",
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    width: '10%',
    minWidth: 100
  },
  "& .makeModelBox": {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    width: 100,
    justifyContent: 'center',
    alignItems: 'center'
  },
  "& .makeModelText": {
    color: (theme as any).typography.h1,
    textAlign: "center",
    fontFamily: "Outfit",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  "& .idText": {
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .priceText": {
    width:'70px',
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    background:
      "var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    backgroundClip: "text",
  },
  "& .catalogueSubText": {
    width: '10%',
    color: theme.typography.h1,
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    minWidth: 100
  },
  "& .Successful": {
    display: "flex",
    padding: "2px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "2px",
    borderRadius: "40px",
    background: "var(--Status-Green-100, #D1FAE5)",
    color: "var(--Status-Green-600, #059669)",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18px",
    width:'fit-content',
    textTransform: "uppercase",
  },
  "& .unSuccessful": {
    color: "var(--Status-Red-600, #DC2626)",
    fontFamily: "Outfit",
    width:'fit-content',
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase",
    display: "flex",
    padding: "2px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "2px",
    borderRadius: "40px",
    background: "var(--Status-Red-100, #FEE2E2)",
  },
  '@media (max-width: 600px)': {

    '& .ANP_tabBoxWrap': {
        marginRight: "9px"

    },
    "& .rangeCalendar ": {
        top: "125%",
        position: "absolute",
        width: "300px",
    },
},
'& .react-calendar__tile--hasActive': {
  background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
  color: "#171717 !important",
  '& abbr': {
    color: "#171717 !important",
  },
  borderRadius: '0', 
  '&:first-of-type': {
    borderTopLeftRadius: '35px',
    borderBottomLeftRadius: '35px',
  },
  '&:last-of-type': {
    borderTopRightRadius: '35px',
    borderBottomRightRadius: '35px',
  },
},
'@media (max-width: 480px)': {

    '& .ANP_padding30xs': {
        width: 'fit-content',
        padding: "10px",

    },

    '& .customCalendar': {
        padding: 0,
        fontSize: 12,
    },

    "& .rangeCalendar ": {
        top: "125%",


        left: "-12.3rem",
        width: "270px",
        position: "absolute",
    },
    
    // Add more styles as needed for small screens
},
}));
const StyledMenu = withStyles((theme) => ({
  paper: {
    borderRadius: '8px',
    background: (theme as any).typography.body1,
    border: `1px solid ${(theme as any).typography.body2}`,
    color: (theme as any).typography.h2,
    fontSize: 12,
    fontFamily: 'Outfit',
    fontWeight: 400,
    "& svg": {
      color: (theme as any).typography.h2,
    }
  },
}))((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export { LandingPage }

withWidth()(LandingPage);
// Customizable Area End
