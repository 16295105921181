import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  darkMode:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      darkMode:true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };
  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };
  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }


  // web events
  // setInputValue = (text: string) => {
  //   this.setState({ txtInputValue: text });
  // };

  // Customizable Area Start
  async componentDidMount() {
    // Set the dark mode from localStorage if available
    const storedDarkModeString = localStorage.getItem("darkMode");
    const storedDarkMode = storedDarkModeString ? JSON.parse(storedDarkModeString) : false;
  
    // Update the state with the stored dark mode
    this.setState({ darkMode: storedDarkMode });
  
    // Save the initial theme to localStorage
    // window.localStorage.setItem("themeToggle", storedDarkMode ? "dark" : "light");
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.darkMode !== this.state.darkMode) {
      // Save the theme to localStorage
      // const theme = this.state.darkMode ? "dark" : "light";
      // window.localStorage.setItem("themeToggle", theme);
         localStorage.setItem("darkMode", JSON.stringify(this.state.darkMode));
    }
  }
handleResetNavigation = () => {
  if (this.props.navigation && this.props.navigation.navigate) {
    this.props.navigation.navigate('ResetPasswordThroughSetting'); // Adjust the route name if needed
  } else {
    console.error('Navigation object is not available.');
  }
}


  handleThemeChange=(theme: string)=>{
    this.setState((prevState)=>({
      darkMode:!prevState.darkMode
    }))
    localStorage.setItem('themeMode', theme)
    window.location.reload();
  }
  
  
  // Customizable Area End
}
