import React from "react";
// Customizable Area Start
import { Box, Button, Chip,Grid, IconButton, Input, InputAdornment, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withWidth } from "@material-ui/core";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { Field,Formik } from "formik";
import {  FileIcon } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import * as Yup from 'yup';
import Alert from "@material-ui/lab/Alert";
import CustomPagination from "../../../components/src/CustomPagination";
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';
import CustomSelectSearchCatalogue from "../../../components/src/CustomSelectSearchCatalogue";
import CustomSelectSearchField from "../../../components/src/CustomSelectSearchField";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiMenu: {
      list: {
        backgroundColor: "#3F3E3A !important",
        color: "#F3F3F3 !important",
        fontWeight: 400,
      },
    },
  },
});
// Customizable Area End

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  userSchema = () => {
    return Yup.object().shape({
      vehicleId: Yup.string().matches(/^\d{4}$/, 'Enter Last 4 characters of ID')
      .required('Enter Last 4 characters of ID'),
      vehicleSelect: Yup.object().required(),
      vehicleNumber: Yup.string().required('Vehicle Number is required')
     });
  };
//istanbul ignore next
  addVehicleModal = () => {
    return  <Modal open={this.state.openModal}>
      <ModalStyle>
        <Box className="mainBox">
          <Formik
           data-test-id="formik"
            validationSchema={this.userSchema}
            validateOnChange={this.state.formIsDirty}
              validateOnBlur={this.state.formIsDirty}
            initialValues={{
              vehicleNumber:this.state.vehicleNumber,
              vehicleSelect:this.state.vehicleSelect,
              vehicleId:this.state.vehicleId,
            }}
            onSubmit={(values) => {
              this.handleNavigateAdd()
            }}
          >
            {({ errors, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Box className="modalMainWrap">
                <Typography className="modalTitleText">Add Vehicle</Typography>
                <IconButton onClick={this.handleClickOpen}>
                  <CloseIcon className="icon"/>
                </IconButton>
              </Box>
              <Box className="lineModal" />
              <Box className="modalContenerWrap">
                <Box className="modalInputBox">
                  <Typography className="titleText">Vehicle Number</Typography>
                    <Input
                    data-test-id="vehiclenumberID"
                      className="inputField"
                      placeholder="Enter vehicle number"
                      disableUnderline
                      value={this.state.vehicleNumber}
                      onChange={(event) => {
                        this.onValueChange("vehicleNumber", event.target.value);
                        setFieldValue("vehicleNumber", event.target.value);
                      }}
                      fullWidth
                    />
                    <Box className="wrapSelect">
                      <CustomSelectSearchField value={this.state.selectedGovernment.name} placeholder={'Select'} governmentList={this.state.governmentList} onChange={(event: any) => {
                        setFieldValue("vehicleSelect", event.target.value);
                        this.onValueChange("selectedGovernment", event.target.value)}} />
                    </Box>
                    <Input
                     data-test-id="vehicleID"
                      type="text"
                      name="ID"
                      // className={`inputField ${errors.vehicleId ? 'errorInput' : ''}`}
                      className={clsx({'errorInput': errors.vehicleId, 'inputField': !errors.vehicleId},)}
                      placeholder="Enter Last 4 characters of ID"
                      disableUnderline
                      value={this.state.vehicleId}
                      onChange={(event) => {
                        this.onValueChange("vehicleId", event.target.value);
                        setFieldValue("vehicleId", event.target.value);
                      }}
                      fullWidth
                    />
                    {errors.vehicleId && 
                    <Typography className="errorsMsg">{errors.vehicleId}</Typography>}
                    {errors.vehicleId || errors.vehicleNumber || errors.vehicleSelect ? <Alert className="alertIcon" icon={false} severity="error">
                    Verification Unsuccessful, Please Check Your Vehicle Info.
                        </Alert>:''}
                </Box>
              </Box>
              <Box className="lineModal" />
              <Box className="modalBtnWrap">
                <Button data-test-id='modalCancelBtn'  onClick={this.handleClickOpen} className="modalCancelBtn" >Cancel</Button>
                <Button data-test-id='modalAddBtn' type="submit" className="modalAddBtn" onClick={this.checkSubmit}>Add</Button>
              </Box>
            </form>
            )}
          </Formik>
        </Box>
      </ModalStyle>
    </Modal>
  }
//istanbul ignore next
  importCatalogueModal = () => {
    return  <Modal open={this.state.openImportModal}>
      <ModalStyle>
        <Box className="mainBoxImport">
          <Formik
            initialValues={{
              vehicleNumber:this.state.vehicleNumber,
              vehicleSelect:this.state.vehicleSelect,
              vehicleId:this.state.vehicleId,
            }}
            onSubmit={(values) => {
            }}
          >
            {({ errors, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Box className="modalMainWrap">
                {!this.state.deleteError
                 ? <Typography className="modalTitleText">Import Catalogue</Typography> 
                 : <Typography className="modalTitleText">Delete Catalogue</Typography>
                }
                <IconButton onClick={this.handleClickOpenModal}>
                  <CloseIcon className="icon"/>
                </IconButton>
              </Box>
              <Box className="lineModal" />
              <Box className="modalImportWrap">
                  {!this.state.deleteError ?
                    <Box className="modalImportInputBox">
                      <Typography className="importTitle">Select the file to import Catalogue.</Typography>
                  
                      {this.state.uploadedFiles.length === 0 &&
                        <>
                          <input
                            data-test-id='imageUpload'
                            id="imageInput"
                            type="file"
                            // accept=".csv"
                            style={{ display: 'none' }}
                            onChange={this.handleCsvChange}
                            multiple
                          />
                          <label htmlFor="imageInput">
                            <Box className="imageInportBox">
                              <AddIcon className="icon"/>
                              <Typography className="importText">Import</Typography>
                            </Box>
                          </label>
                        </>}
                        <>
                          {this.state.uploadedFiles.map((file: any, index: number) => (
                            <Box key={index} className="uplodeFileBox">
                              <Box className="uplodeIconBox">
                                <img src={FileIcon} />
                              </Box>
                              <Box className="uplodeFileTextBox">
                                <Typography className="uplodeFileText">{file.file.name}</Typography>
                                {!this.state.errorFile
                                ? <Typography className="uplodeFileText">{file.fileSize}</Typography>
                                : <Typography className="errorFileText">File format is not supported, please upload a CSV file</Typography>
                                }
                              </Box>
                              <Box>
                                <DeleteIcon onClick={() => this.handleFileDataDelete(index)} className="icon" />
                              </Box>
                            </Box>
                          ))}
                        </>

                        <Typography className="deleteErrorText">{
                        this.state.csvFile?.errors?.[0]?.message
                        } </Typography>
                      <Box className="downloadWrap">
                        <Box className="downloadBtn" onClick={this.handleDownloadTemplate}>
                          <Typography className="downloadBtnText">Download Template</Typography>
                        </Box>
                      </Box>
                      
                    </Box>:
                    <Typography className="deleteErrorText">Are you sure you want to delete the Catalogue {this.state.deletedFileName} ?</Typography>
                  }
              </Box>
              <Box className="lineModal" />
              <Box className="modalBtnWrap">
                <Button data-test-id='modalCancelBtn'  onClick={this.handleClickOpenModal} className="modalCancelBtn" >Cancel</Button>
                
                {!this.state.deleteError
                ?<Button data-test-id='modalAddBtn' type="submit" className="modalAddBtn" onClick={this.importCatalogueApi}>Import</Button>
                :<Button data-test-id='modalAddBtn' onClick={this.handleClickOpenModal} className="modalAddBtn">Delete</Button>
                }
              </Box>
            </form>
            )}
          </Formik>
        </Box>
      </ModalStyle>
    </Modal>
  }
//istanbul ignore next
  importingModal = () => {
    return <Modal open={this.state.openImportModal}>
      <ModalStyle>
        <Box className="mainBoxImport">

          <Box className="modalMainWrap">

            <Typography className="modalTitleText">Import Catalogue</Typography>

            <IconButton onClick={this.handleClickOpenModal}>
              <CloseIcon style={{ color: "white" ,cursor:"pointer",}} />
            </IconButton>
          </Box>
          <Box className="lineModal" />
          <Box className="modalImportWrap">
            <Typography className="titleTextImporting">Importing in progress.</Typography>
          </Box>
          <Box className="lineModal" />
          <Box className="modalHistoryBtnWrap">
            <Button data-test-id='modalCancelBtn' onClick={this.handleClickOpenModal} className="modalCancelBtn" >OK</Button>
            <Button data-test-id='modalAddBtn' type="submit" className="modalhistoryBtn" onClick={this.navigetHistorypage}>View Import History</Button>
          </Box>
        </Box>
      </ModalStyle>
    </Modal>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const itemsPerPage = 10; // Adjust as needed
    const startIndex = (this.state.currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const {width} = this.props;  
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
        <CatalogueStyle>
        <Grid container className={clsx({"padding30": width!=='xs', 'padding30xs': width == 'xs'})}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} >
                <Input
                  className="searchBar"
                  placeholder="Search"
                  disableUnderline
                  fullWidth
                  data-test-id="searchId"
                  value={this.state.searchText}
                  onChange={this.handleSetSearchBar}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon style={{ color: "white" }} />
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} md={3} >
                <Box className="catalogueBtn" onClick={(args)=>this.handleClickOpenAnchor(args)}>
                  <Typography className="catalogueBtnText">Import Catalogue</Typography>
                   <ExpandMoreIcon style={{color:"#171717"}}/>
                </Box>
                <StyledMenu
                  id="simple-menu"
                  anchorEl={this.state.anchorElImport}
                  keepMounted
                  open={Boolean(this.state.anchorElImport)}
                  onClose={this.handleCloseImport}
                >
                  <MenuItem onClick={this.handleClickOpenModal}>
                    <Typography style={{color: '#F3F3F3', fontSize: 12, fontFamily: 'Outfit', fontWeight: 400,}}>Import</Typography>
                  </MenuItem>
                 <MenuItem onClick={this.handleNavigateHistory}>
                    <Typography style={{color: '#F3F3F3', fontSize: 12, fontFamily: 'Outfit', fontWeight: 400,}}>Import history</Typography>
                  </MenuItem>
                </StyledMenu>
              </Grid>
              <Grid item xs={12} md={3} >
                <Box className="catalogueBtn" onClick={this.handleClickOpen}>
                  <Typography className="catalogueBtnText">Add Catalogue</Typography>
                </Box>
              </Grid>
            </Grid>
            <TableContainer className={clsx({'width300': width === 'xs', 'width100': width !=='xs' })}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={10} className='test'>
                      <Box className="filterTextBox">
                        <Typography className="filterText">Filter</Typography>
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align='left' className="roundedLeftBottom"  width={'10%'}>
                      <Typography className='inputLabel'>Posted by</Typography>
                      <CustomSelectSearchCatalogue data-test-id='postBy' value={this.state.valueSearchPostedBy} placeholder='Select' onChange={(args)=>{this.onChangeSearchCatalogue(args,'posted_by')}} isPostedBy/>
                    </StyledTableCell>
                    <StyledTableCell align="left"  width={'10%'}>
                      <Typography className='inputLabel'>Make</Typography>
                      <CustomSelectSearchCatalogue data-test-id='make' value={this.state.valueSearchMake} placeholder='Select' onChange={(args)=>{this.onChangeSearchCatalogue(args,'make')}} makeList={this.state.listMakeCatalogue}/>
                    </StyledTableCell>
                    <StyledTableCell align="left"  width={'10%'}>
                      <Typography className='inputLabel'>Model</Typography>
                      <CustomSelectSearchCatalogue data-test-id='model' value={this.state.valueSearchModel} placeholder='Select' onChange={(args)=>{this.onChangeSearchCatalogue(args,'model')}} isModel modelList={this.state.valueSearchMake ?  this.state.listModelCatalogue: []}/>
                    </StyledTableCell>
                    <StyledTableCell align="left" className="wrapEmail"  width={'10%'}>
                      <Typography className='inputLabel'>Price</Typography>
                      <CustomSelectSearchCatalogue data-test-id='price' value={this.state.valueSearchPrice} placeholder='Select' onChange={(args)=>{this.onChangeSearchCatalogue(args,'price')}} isPrice/>
                    </StyledTableCell>  
                    <StyledTableCell align='left'  width={'10%'}>
                      <Typography className='inputLabel'>Depreciation</Typography>
                      <CustomSelectSearchCatalogue data-test-id='Depreciation' value={this.state.valueSearchDeprecation} placeholder='Select' onChange={(args)=>{this.onChangeSearchCatalogue(args,'depreciation')}} isDeprecation/>
                    </StyledTableCell>
                    <StyledTableCell align='left'  width={'10%'}>
                      <Typography className='inputLabel'>Reg Date</Typography>
                      <CustomSelectSearchCatalogue data-test-id='regDate' value={this.state.valueSearchRegDate} placeholder='Select' onChange={(args)=>{this.onChangeSearchCatalogue(args,'reg_date')}} isRegDate/>
                    </StyledTableCell>
                    <StyledTableCell align='left'  width={'10%'}>
                      <Typography className='inputLabel'>Engine Cap</Typography>
                      <CustomSelectSearchCatalogue data-test-id='engine_cap' value={this.state.valueSearchEngineCap} placeholder='Select' onChange={(args)=>{this.onChangeSearchCatalogue(args,'engine_cap')}} isEngineCap/>
                    </StyledTableCell>
                    <StyledTableCell align='left'  width={'10%'}>
                      <Typography className='inputLabel'>Milage</Typography>
                      <CustomSelectSearchCatalogue data-test-id='milage' value={this.state.valueSearchMilage} placeholder='Select'onChange={(args)=>{this.onChangeSearchCatalogue(args,'mileage')}} isMilage/>
                    </StyledTableCell>
                    <StyledTableCell align='left' colSpan={2} className="roundedRightBottom"  width={'10%'}>
                      <Typography className='inputLabel'>Vehicle type</Typography>
                      <CustomSelectSearchCatalogue data-test-id='vehicle_type' value={this.state.valueSearchVehicleType} placeholder='Select' onChange={(args)=>{this.onChangeSearchCatalogue(args,'vehicle_type')}} isVehicleType/>
                    </StyledTableCell>          
                   
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.vehicleData && this.state.vehicleData.map((item: any, index: number)=>
                  <>
                    <TableRow className='top' onClick={(event: any)=>this.handleNavigateOverview(event,item.id)}>
                      <StyledTableCell align="left">
                        <Box style={{display:'flex', justifyContent:'flex-start'}}>
                        <Typography className="textTable">{item.attributes?.account_name}</Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left" colSpan={2}>
                        <Box style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
                        <Typography className="textMakeModel">{item.attributes?.make} {item.attributes?.model}</Typography>
                        <Typography className="textId">ID: {item.id}</Typography></Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography className="subFilterPrice">${Number(item.attributes?.selling_price).toLocaleString()}</Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography className="textTable">{item.attributes?.depreciation}</Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography className="textTable">{item.attributes?.reg_date}</Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography className="textTable">{item.attributes?.engine_cap}</Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography className="textTable">{item.attributes?.mileage}</Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography className="textTable">{item.attributes?.vehicle_type}</Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center" itemType="button">                          
                          {item.attributes.is_accessible && !item.attributes.is_added_in_deal && <Box aria-haspopup="true" onClick={(args:any)=>this.handleOpenMenu(args, item.id)}>
                            <MoreVertIcon/>
                          </Box> }                         
                          <StyledMenu
                            id="simple-menu"
                            anchorEl={this.state.menuAnchorEl}
                            keepMounted
                            open={Boolean(this.state.menuAnchorEl)}
                            onClose={this.handleCloseMenu}
                            
                          >
                            <MenuItem onClick={this.handleNavigateOverviewEdit}>
                              <ListItemIcon>
                                <EditIcon style={{color:"#F3F3F3"}}/>
                              </ListItemIcon>
                              <ListItemText primary="Edit" style={{color: '#F3F3F3', fontSize: 12, fontFamily: 'Outfit', fontWeight: 400,}}/>
                            </MenuItem>
                           <MenuItem onClick={this.handleDelete}>
                              <ListItemIcon>
                                <DeleteIcon style={{color:"#F3F3F3"}}/>
                              </ListItemIcon>
                              <ListItemText primary="Delete" style={{color: '#F3F3F3', fontSize: 12, fontFamily: 'Outfit', fontWeight: 400,}}/>
                            </MenuItem>
                          </StyledMenu>

                          {item.attributes.is_accessible && item.attributes.is_added_in_deal && <Box aria-haspopup="true" onClick={(args:any)=>this.handleOpenMenuNoDelete(args, item.id)}>
                            <MoreVertIcon/>
                          </Box> }  
                          <StyledMenu
                            id="simple-menu"
                            anchorEl={this.state.menuAnchorElNoDelete}
                            keepMounted
                            open={Boolean(this.state.menuAnchorElNoDelete)}
                            onClose={this.handleCloseMenuNoDelete}
                            
                          >
                            <MenuItem onClick={this.handleNavigateOverviewEdit}>
                              <ListItemIcon>
                                <EditIcon style={{color:"#F3F3F3"}}/>
                              </ListItemIcon>
                              <ListItemText primary="Edit" style={{color: '#F3F3F3', fontSize: 12, fontFamily: 'Outfit', fontWeight: 400,}}/>
                            </MenuItem>
                          </StyledMenu>  
                        </StyledTableCell>
                    </TableRow>
                    <TableRow className='bot' onClick={(event: any)=>this.handleNavigateOverview(event, item.id)}>
                    <StyledTableCell className="imageBox">
                      {item.attributes?.images && item.attributes?.images.length > 0 && (
                        <img src={item.attributes?.images[0].url} className="subImage" alt={`Vehicle ${index + 1}`} />
                      )}
                    </StyledTableCell>                    
                      <StyledTableCell colSpan={10}>
                        <Box>
                          <Typography className='textDes'>{item.attributes?.description}</Typography>
                          <Chip label={item.attributes?.status.toUpperCase()} className="chipLabel" />
                        </Box>
                      </StyledTableCell>
                      
                    </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          <Box className="paginationContainer">
            <CustomPagination page={this.state.currentPage} count={this.state.totalPage} onChange={this.handleChangePageCatalogue}/>
          </Box>
          </Grid>
          {this.addVehicleModal()}
          {this.state.csvFile.message === "importing in progress." ? this.importingModal() : this.importCatalogueModal()}
        </CatalogueStyle>
      </>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const StyledTableCell = withStyles((theme)=>({
  head: {
    backgroundColor: (theme as any).typography.body2, 
    color: (theme as any).typography.h1, 
    fontSize: '14px',
    fontFamily: 'Outfit', 
    fontWeight: 700,
    verticalAlign:'center',
    border: 'none',
    borderCollapse: 'separate',
    borderSpacing: '0px 4px',
    
  },
  body: {
    backgroundColor: (theme as any).typography.body2, 
    color: (theme as any).typography.h2, 
    fontSize: '16px',
    fontFamily: 'Outfit', 
    fontWeight: 400,
    border: 'none',
    borderCollapse: 'separate',
    borderSpacing: '0px 4px'
  },
}))(TableCell);
const StyledMenu = withStyles({
  paper: {
    borderRadius: '8px',
    backgroundColor: '#3F3E3A',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    style={{marginTop:'10px', width:194, marginLeft:'20px'}}
    anchorOrigin={{
      
      horizontal: 'center',
      vertical: 'bottom',
    }}
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));
const ModalStyle = styled(Box)(({ theme }) => ({
  "& .icon": {
    color: (theme as any).typography.h1, 
  },
  "& .MuiTypography-body1":{
    fontFamily: "Outfit",
  },
  "& .wrapSelect":{
    marginTop:30,
    marginBottom:30,
  },
  "& .uplodeFileBox":{
    display: "flex",
    width: "100%",
    alignItems: "center",
    gap: "16px",
    borderRadius: "8px",
    background: (theme as any).typography.surface2,
    padding:"10px",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"  
  },
  "& .uplodeIconBox":{
    display: "flex",
    padding: "var(--border-radius, 16px)",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "8px", 
    background: "#FFEFBD",
  },
  "& .uplodeFileTextBox":{
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "2px",
    flex: "1 0 0",
  },
  "& .uplodeFileText":{
  color: (theme as any).typography.h1, 
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  wordBreak:'break-all'
  },
  "& .errorFileText":{
    color: "#F87171",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400
  },
  "& .deleteIcon":{
    cursor:"pointer",
  },
  "& .deleteErrorText":{
    color: "#F87171",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400
  },
  "& .downloadWrap":{
    display:"flex",
    justifyContent:"flex-end",
    width:"100%",
  },
  "& .downloadBtnText":{
    cursor:'pointer',
    color: "#CEAE71",
    fontSize: "16px",
    fontWeight: 700,
    fontStyle: "normal",
    fontFamily: "Outfit",
  },
  "& .downloadBtn": {
    display: "flex",
    padding: "6px 10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    borderRadius: "8px",
    background: (theme as any).typography.surface2, 
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
  },
  "& .modalInputBox":{
    display:"flex",
    flexDirection:"column",
  },
  "& .modalImportInputBox":{
    display:"flex",
    flexDirection:"column",
    gap:"27px",
  },
  "& .modalInputBoxInport":{
    display:"flex",
    flexDirection:"column",
    gap:"20px",
  },
  "& .mainBoxImport":{
    minWidth:"40%",
    position: 'absolute' as 'absolute',
    backgroundColor:(theme as any).typography.body2, 
    borderRadius: "16px",
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    maxHeight:"80vh",
    overflowY:"auto"
  },
  "& .importText":{
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },

  "& .imageInportBox":{
    display: "flex",
    padding:
    "var(--border-radius, 16px) 12px var(--border-radius, 16px) var(--border-radius, 16px)",
    height: "88px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    borderRadius: "8px",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23FFF6DCFF' stroke-width='2' stroke-dasharray='30%2c20' stroke-dashoffset='31' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderWidth: "4px",
    background: (theme as any).typography.surface2, 
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
  },
  '& .modalImportWrap': {
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "10%",
    paddingBottom: "10%",
  },
  "& .alertIcon": {
    marginTop: '24px',
    color: "var(--status-red-400, #F87171)",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    backgroundColor: "rgb(83 83 83 / 41%)",
    borderRadius: "4px",
    borderLeft: "4px solid var(--status-red-400, #F87171)",
    background: "var(--surface-2, #3F3E3A)",
  },
  "& .errorsMsg":{
    color: "#F87171",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  '& .mainBox': {
    position: 'absolute' as 'absolute',
    backgroundColor:(theme as any).typography.body2, 
    borderRadius: "16px",
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    minWidth: "35%",
    maxHeight: '80vh', 
    overflowY: 'auto',
  },
  '& .modalMainWrap': {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 20px",
    flexDirection: "row",
  },
  '& .modalTitleText': {
    color: (theme as any).typography.h1, 
    fontSize:"24px",
    fontWeight: 700,
    fontFamily: "Outfit",
    display:"flex",
    alignItems:"center",
  },
  "& .modalhistoryBtn":{
    cursor:"pointer",
    marginLeft: "16px",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    borderRadius: "8px",
    color: "#171717",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    width: "auto",
    height: "56px"
  },
    '& .titleText': {
      fontSize:"14px",
      color: (theme as any).typography.h1, 
      fontWeight: 700,
      fontFamily: "Outfit",
    },
   
    '& .titleTextImporting': {
      fontSize:"16px",
      color: (theme as any).typography.h1, 
      fontWeight: 400,
      fontFamily: "Outfit",
    },
    '& .importTitle': {
      fontSize:"16px",
      color: (theme as any).typography.h1, 
      fontWeight: 400,
      fontFamily: "Outfit",
    },
    '& .lineModal': {
      backgroundColor:"#FFF6DC30",
      height:"1px", 
    },
    '& .modalContenerWrap': {
      paddingLeft: "15%",
      paddingRight: "15%",
      paddingTop: "5%",
      paddingBottom: "5%",
    },
    "& .MuiSelect-icon": {
      color: (theme as any).typography.h1,
    },
    '& .inputField': {
      marginTop: '4px',
      color: (theme as any).typography.h1,
      fontFamily: "Outfit",
      fontSize: "16px",
      fontStyle: "normal",
      borderRadius: 8,
      border: `1px ${(theme as any).typography.border2} solid`,
      background: (theme as any).typography.body2, 
      padding: '10px 8px',
      fontWeight: 400,
      width:'100% !important'
    },
    '& .errorInput': {
      marginTop: '4px',
      color: (theme as any).typography.h1,
      fontFamily: "Outfit",
      fontSize: "16px",
      fontStyle: "normal",
      borderRadius: 8,
      border: "1px solid #F87171",
      background: (theme as any).typography.body2, 
      padding: '10px 8px',
      fontWeight: 400,
      width:'100% !important'
    },
    '& .selectFild': {
      marginTop: '30px',
      marginBottom:"30px",
      color: (theme as any).typography.h3, 
      fontFamily: "Outfit",
      fontSize: "16px",
      fontStyle: "normal",
      borderRadius: 8,
      border: `1px ${(theme as any).typography.border} solid`,
      background: (theme as any).typography.body2, 
      padding: '10px 8px',
      fontWeight: 400,
    },
    '& .modalBtnWrap': {
      display: "flex",
      justifyContent: "flex-end",
      padding: "15px 24px 0px"
    },
    '& .modalHistoryBtnWrap': {
      display: "flex",
      justifyContent: "flex-end",
      padding: "15px 24px 15px"
    },
    '& .modalCancelBtn': {
      cursor:"pointer",
      backgroundColor:(theme as any).typography.surface2, 
      fontSize: "16px",
      fontWeight: 700,
      textTransform: "none",
      width: "120px",
      height: "56px",
      borderRadius: "8px",
      color: "#CEAE71",
      fontFamily: "Outfit",
    },
    '& .modalAddBtn': {
      marginLeft: "16px",
      background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
      borderRadius: "8px",
      color: "#171717",
      fontFamily: "Outfit",
      fontSize: "16px",
      fontWeight: 700,
      textTransform: "none",
      width: "120px",
      height: "56px"
    },
  }));
const CatalogueStyle = styled(Box)(({ theme }) => ({
  "& .icon": {
    color: (theme as any).typography.h1, 
  },
  '& .width300':{
    width: '327px',
    marginTop:20,
  },
  '& .width100':{
    width: '100%',
    marginTop:20,
  },
  '& .textMakeModel':{
    fontFamily: "Outfit",
    fontSize: "15px",
    fontWeight: 500,
    color: (theme as any).typography.h1, 
  },
  '& .textTable':{
    fontFamily: "Outfit",
    fontSize: "14px",
    fontWeight: 400,
    color:(theme as any).typography.h1, 
  },
  '& .textDes':{
    fontFamily: "Outfit",
    fontSize: "14px",
    fontWeight: 400,
    color: (theme as any).typography.h3, 
    overflowWrap:'anywhere'
  },
  '& .textId':{
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 400,
    color: (theme as any).typography.h3, 
  },
  "& .top td": {
    borderTop:`20px solid ${(theme as any).typography.body1}`,
  },
  '& .padding30':{
    padding: "30px",
  },
  '& .padding30xs':{
    width: 'fit-content',
    padding: "30px",
  },
  '& .MuiTableCell-root':{
    padding: "8px",
  },
  "& .MuiTypography-body1":{
    fontFamily: "Outfit",
  },
  "& .paginationContainer": {
    display: "flex",
    justifyContent: "center",
    marginTop: "50px",
    marginBottom:"50px",
    width:'100%'
  },
  "& .MuiPaginationItem-ellipsis":{
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .MuiPaginationItem-icon":{
    display: "flex",
  padding: "6px",
  alignItems: "flex-start",
  gap: "10px",
  borderRadius: "8px",
  background:
    "linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%)",
  width: "20px",
  height: "20px",
  color:"rgba(23, 23, 23, 1)",
  },
  "& .MuiPaginationItem-page":{
    color: (theme as any).typography.h3, 
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    textDecorationLine: "underline",
  },
  "& .MuiPaginationItem-page.Mui-selected":{
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    background:
      "linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textDecorationLine: "none",
  },
  "& .searchBar": {
    display: "flex",
    height: "44px",
    padding: "10px 8px",
    alignItems: "center",
    gap: "8px",
    width: "auto",
    flexShrink: 0,
    borderRadius: "8px",
    border: `1px ${(theme as any).typography.border} solid`,
    background: (theme as any).typography.body1, 
    color: (theme as any).typography.h2, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .catalogueBtnText":{
    color: "#171717",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "initial",
    fontWeight: 700,
    cursor: "pointer",
  },
  // "& .wrapButton":{
  //   paddingLeft:8, 
  //   paddingRight:8,
  //   '@media (max-width: 600px)': {
  //     padding: "8px 0px !important",
  //   },
  // },
  "& .catalogueBtn":{
    display: "flex",
    height: "44px",
    width:'100%',
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
    borderRadius: "8px",
    cursor: "pointer",
    background:
      "var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
  },
  "& .filterTextBox":{
    width: "100%",
  },
  "& .filterText":{
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px"
  },
  "& .inputLabel": {
    maxWidth:'150px',
    display: "flex", 
    justifyContent: "center",
    marginBottom: "5px",
    color:(theme as any).typography.h2, 
    textAlign: "right",
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  "& .MuiSelect-icon": {
    color: (theme as any).typography.h1, 
  },
  "& .MuiMenu-paper":{
    background:"transparent",
  },
  "& .MuiMenu-list":{
    backgroundColor:"#3F3E3A",
    color:(theme as any).typography.h1, 
  },
  "& .subFilterPrice":{
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    background:
      "linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  "& .imageBox":{
    backgroundColor:(theme as any).typography.body2, 
    padding:"10px",
    height:'100%',
  },
  "& .subImage":{
    width: "75px",
    height: "75px",
    borderRadius: "4px",
    objectFit:'contain'
  },
  "& .chipLabel":{
    display: "flex",
    padding: "2px 8px",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "2px",
    borderRadius: "40px",
    background: "#059669",
    width:"fit-content",
    color: "#D1FAE5",
    textAlign: "center",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase",
    marginTop:8
  },
  "& .modalTitle":{
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
  },
}));
export {Catalogue}
const test = withWidth()(Catalogue)
export {test}

// Customizable Area End
