import * as React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  withWidth
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { Formik } from "formik";
import CustomSelectSearchField from "../../../components/src/CustomSelectSearchField";
import * as Yup from 'yup';
import { Close } from "./assets";
import {  FileIcon } from "./assets";
import TokyoPdf from "../../../components/src/TokyoPdf";
import DBSPdf from "../../../components/src/DBSPdf";
import UOBPdf from "../../../components/src/UOBPdf";
import MoneyMaxPdf from "../../../components/src/MoneyMaxPdf";
import TambusaPdf from "../../../components/src/TambusaPdf";
import MaybankPdf from "../../../components/src/MaybankPdf";
import VmPdf from "../../../components/src/VmPdf";
import HongLeon from "../../../components/src/HongLeonPdf";
// Customizable Area End

import { ViewProps } from "./GuarantorDetails";

const GuarantorDetailsView: React.FC<ViewProps> = ({
  // Customizable Area Start
  testID,

  fullName,
  dob,
  NRIC ,
  address ,
  emailID ,
  contactNumber ,
  employer ,
  jobTitle ,
  employmentAddress ,
  grossMonthlySalary ,

  bank,
  tenure ,
  checkSubmit,
  formIsDirty,
  openModalRetrieving,
  handleSave,
  handleCancel,
  listSearchGuarantor,
  handleSearchGuarantor,
  handleUploadCPF,
  handleUploadNOA,
  handleUploadSupporting,
  CPFfile,
  NOAfile,
  supportFile,
  CPFfileEdit,
  NOAfileEdit,
  supportFileEdit,
  guarantorDetails,
  handleDeleteCPFFileEdit,
  handleDeleteNOAFileEdit,
  handleDeleteSupportingFileEdit,
  dataPDF,
  isSaveUOB,
  isSaveMoneyMax,
  isSaveDBS,
  isSaveMaybank,
  isSaveTembusa,
  isSaveTokyo,
  isSaveVM,
  isSaveHongLeong,
  handleSavePdfDBS,
  handleSavePdfUOB,
  handleSavePdfMoneymax,
  handleSavePdfMaybank,
  handleSavePdfTembusa,
  handleSavePdfTokyo,
  handleSavePdfVM,
  handleSavePdfHongleon,
  isLoading,
  handleMoment,
  handleDeleteCPF,
  handleDeleteNOA,
  handleDeleteSupporting,
  selectedGuarantorIndex,
  dealDetail
  // Customizable Area End
}) => {

  // Customizable Area Start
  const renderLoading = () => {
    return <Modal open={isLoading}>
      <ModalStyle>
        <Box className="wrapModal">
          <Box className="wrapModalContentLoading">
            <CircularProgress className="iconLoading" size={20} />
          </Box>
         
        </Box> 
      </ModalStyle>
    </Modal>
  }

  const userSchemaGuarantor = () => {
    const listEmail = dealDetail.attributes.guarantor_details.data
        .map((e: any) => e.attributes.email)
        .filter((f: any) => f !== dealDetail.attributes.guarantor_details.data[selectedGuarantorIndex].attributes.email)
    return Yup.object().shape({
      NRIC: Yup.string().matches(/^[A-Za-z]\d{7}#$/, 'NRIC should start with a letter, followed by 7 digits and ending with #'),
      emailID: Yup.string().notOneOf([dealDetail.attributes.customer_detail.email, ...listEmail], 'Enter a different Email ID').required('Enter Guarantor Email').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email ID is invalid'),
     });
  };
  // Customizable Area End
  return (
    // Customizable Area Start
    <>
      {dataPDF && <DBSPdf isSave={isSaveDBS} setPdf={handleSavePdfDBS} data={dataPDF}/>}
      {dataPDF && <UOBPdf isSave={isSaveUOB} setPdf={handleSavePdfUOB} data={dataPDF}/>}
      {dataPDF && <MoneyMaxPdf isSave={isSaveMoneyMax} setPdf={handleSavePdfMoneymax} data={dataPDF}/>}
      {dataPDF && <TokyoPdf isSave={isSaveTokyo} setPdf={handleSavePdfTokyo} data={dataPDF}/>}
      {dataPDF && <TambusaPdf isSave={isSaveTembusa} setPdf={handleSavePdfTembusa} data={dataPDF}/>}
      {dataPDF && <MaybankPdf isSave={isSaveMaybank} setPdf={handleSavePdfMaybank} data={dataPDF}/>}
      {dataPDF && <VmPdf isSave={isSaveVM} setPdf={handleSavePdfVM} data={dataPDF}/>}
      {dataPDF && <HongLeon isSave={isSaveHongLeong} setPdf={handleSavePdfHongleon} data={dataPDF}/>}
      {renderLoading()}
      <GuarantorStyle data-testid={testID}>
        <Formik 
            data-testid='formik'
            enableReinitialize
            validationSchema={userSchemaGuarantor}
            initialValues={{
              fullName:guarantorDetails?.attributes.full_name ?? "",
              dob:guarantorDetails?.attributes.date_of_birth ?? "",
              NRIC: guarantorDetails?.attributes.nric_number ?? "",
              address: guarantorDetails?.attributes.address ?? "",
              emailID: guarantorDetails?.attributes.email ?? "",
              contactNumber: guarantorDetails?.attributes.contact_number ?? "",
              employer: guarantorDetails?.attributes.employer ?? "",
              jobTitle: guarantorDetails?.attributes.job_title ?? "",
              employmentAddress: guarantorDetails?.attributes.employement_address ?? "",
              grossMonthlySalary: guarantorDetails?.attributes.gross_monthly_salary ?? "",
            }} 
            validateOnChange={formIsDirty}
            validateOnBlur={formIsDirty}
            onSubmit={(e:any)=>{
              handleSave(e)
            }}>
              {({errors, setFieldValue, handleSubmit, handleChange, values})=>(
              <form data-testid='form' onSubmit={handleSubmit} className="formikWrap">
                <Grid container className='container'>
                  <Grid container className="wrapHeaderCustomerDetail">
                    <Typography className="sectionLabel">Guarantor details</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Full Name</Typography>
                    <CustomSelectSearchField 
                        testId="guarantorSearch"
                        value={values.fullName} 
                        listSearchCustomer={listSearchGuarantor} placeholder={'Select'} isFullName 
                        onChange={handleSearchGuarantor}
                        onSelect={(e)=>{
                          setFieldValue('fullName', e.attributes.name);
                          setFieldValue('dob', handleMoment(e.attributes.date_of_birth, 'DD/MM/YYYY'));
                          setFieldValue('emailID', e.attributes.email);
                          setFieldValue('contactNumber', e.attributes.contact_number);
                          }}/>
                      {errors.fullName && 
                        <Typography className="errorsMsg">{errors.fullName}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Date of birth</Typography>
                    <TextField
                      data-testid='dob'
                      className={clsx({'errorInput': errors.dob, 'inputField': !errors.dob},)}
                      placeholder="Place holder"
                      type="date"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.dob}
                      onChange={handleChange('dob')}
                      fullWidth />
                      {errors.dob && 
                        <Typography className="errorsMsg">{errors.dob}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">NRIC number</Typography>
                    <TextField
                      data-testid='NRIC'
                      className={clsx({'errorInput': errors.NRIC, 'inputField': !errors.NRIC},)}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.NRIC}
                      onChange={handleChange('NRIC')}
                      fullWidth />
                      {errors.NRIC && 
                        <Typography className="errorsMsg">{errors.NRIC}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Address</Typography>
                    <TextField
                      data-testid='address'
                      className={clsx({'errorInput': errors.address, 'inputField': !errors.address},)}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.address}
                      onChange={handleChange('address')}
                      fullWidth />
                      {errors.address && 
                        <Typography className="errorsMsg">{errors.address}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Email ID</Typography>
                    <TextField
                      data-testid='emailID'
                      className={clsx({'errorInput': errors.emailID, 'inputField': !errors.emailID},)}
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"}, }}
                      placeholder="Place holder"
                      value={values.emailID}
                      onChange={handleChange('emailID')}
                      fullWidth />
                    {errors.emailID && 
                      <Typography className="errorsMsg">{errors.emailID}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Contact number</Typography>
                    
                    <TextField
                      data-testid='contactNumber'
                      className={clsx("inputField")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.contactNumber}
                      onChange={(event) => {
                        const regex = /^[0-9\b]+$/;
                        if (event.target.value === "" || regex.test(event.target.value)) {
                            setFieldValue('contactNumber', event.target.value);
                        }
                      }}
                      fullWidth />
                    {errors.contactNumber && 
                        <Typography className="errorsMsg">{errors.contactNumber}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Employer</Typography>
                    <TextField
                      data-testid='employer'
                      className={clsx({'errorInput': errors.employer, 'inputField': !errors.employer},)}
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"}, }}
                      placeholder="Place holder"
                      value={values.employer}
                      onChange={handleChange('employer')}
                      fullWidth />
                    {errors.employer && 
                      <Typography className="errorsMsg">{errors.employer}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Job Title</Typography>
                    
                    <TextField
                      data-testid='jobTitle'
                      className={clsx("inputField")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.jobTitle}
                      onChange={handleChange('jobTitle')}
                      fullWidth />
                    {errors.jobTitle && 
                        <Typography className="errorsMsg">{errors.jobTitle}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Employment Address</Typography>
                    <TextField
                      data-testid='employmentAddress'
                      className={clsx({'errorInput': errors.employmentAddress, 'inputField': !errors.employmentAddress},)}
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"}, }}
                      placeholder="Place holder"
                      value={values.employmentAddress}
                      onChange={handleChange('employmentAddress')}
                      fullWidth />
                    {errors.employmentAddress && 
                      <Typography className="errorsMsg">{errors.employmentAddress}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Gross monthly salary</Typography>
                    
                    <TextField
                      data-testid='grossMonthlySalary'
                      className={clsx({'errorInput': errors.grossMonthlySalary, 'inputField': !errors.grossMonthlySalary},)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.grossMonthlySalary}
                      onChange={(event) => {
                        const regex = /^[0-9\b]+$/;
                        if (event.target.value === "" || regex.test(event.target.value)) {
                            setFieldValue('grossMonthlySalary', event.target.value);
                        }
                      }}
                      fullWidth />
                    {errors.grossMonthlySalary && 
                        <Typography className="errorsMsg">{errors.grossMonthlySalary}</Typography>}
                  </Grid>
                </Grid>

                <Grid container className='container'>
                  
                  <Grid container className="wrapHeaderCustomerDetail">
                    <Typography className="selectedBankLabel">Upload supporting documents</Typography>
                    <Box className="wrapDocuments">
                     
                        <Box className="wrapDocument">
                          <Typography className="documentTitle">CPF contribution for last 6 months</Typography>
                          
                          {CPFfileEdit === null || CPFfileEdit===undefined? 
                          <>{CPFfile ? <Box className="documentUploaded">
                            <Box className="uploadIconBox">
                              <img src={FileIcon} />
                            </Box>
                            <Box className="wrapTextUpload">
                              <Typography className="textUpload1">{CPFfile?.name}</Typography>
                              <Typography className="textUpload2" display="inline">{CPFfile?.size}KB</Typography>
                            </Box>
                            <HighlightOffRoundedIcon className="iconBank"onClick={()=>handleDeleteCPF()}/> 
                          </Box> : <><input
                        data-testid='CPFupload'
                        id="CPFupload"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleUploadCPF}/>
                      <label htmlFor="CPFupload">
                        <Box className="documentUpload">
                          <AddIcon className="addIcon"/>
                          <Typography className='textUpload'>Upload</Typography>
                        </Box>
                      </label></> 
                          }  </>  : <Box className="documentUploaded">
                          <Box className="uploadIconBox">
                            <img src={FileIcon} />
                          </Box>
                          <Box className="wrapTextUpload">
                            <Typography className="textUpload1">{guarantorDetails?.attributes.cpf_contribution_for_last_six_month.name}</Typography>
                          </Box>
                          <Box data-testid="deleteCPF" onClick={handleDeleteCPFFileEdit}><HighlightOffRoundedIcon  className="iconBank" /> </Box>
                          
                        </Box>}
                        </Box>
                      
                        <Box className="wrapDocument">
                          <Typography className="documentTitle">NOA for last 2 years</Typography>
                          {NOAfileEdit === null || NOAfileEdit === undefined ? 
                          <>{NOAfile ? <Box className="documentUploaded">
                            <Box className="uploadIconBox">
                              <img src={FileIcon} />
                            </Box>
                            <Box className="wrapTextUpload">
                              <Typography className="textUpload1">{NOAfile?.name}</Typography>
                              <Typography className="textUpload2" display="inline">{NOAfile?.size}KB</Typography>
                            </Box>
                            <HighlightOffRoundedIcon className="iconBank" onClick={()=>handleDeleteNOA()}/> 
                          </Box> : <><input
                        data-testid='NOAfile'
                        id="NOAfile"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleUploadNOA}/>
                      <label htmlFor="NOAfile">
                        <Box className="documentUpload">
                          <AddIcon className="addIcon"/>
                          <Typography className='textUpload'>Upload</Typography>
                        </Box> 
                      </label></>
                          }     </>: <Box className="documentUploaded">
                          <Box className="uploadIconBox">
                            <img src={FileIcon} />
                          </Box>
                          <Box className="wrapTextUpload">
                            <Typography className="textUpload1">{guarantorDetails?.attributes.noa_for_last_two_year.name}</Typography>
                          </Box>
                          <HighlightOffRoundedIcon data-testid="deleteNOA" className="iconBank" onClick={handleDeleteNOAFileEdit}/> 
                        </Box> }
                        </Box>
                      
                      
                        <Box className="wrapDocument">
                          <Typography className="documentTitle">Upload supporting Docs</Typography>
                          {supportFileEdit === null || supportFileEdit === undefined ? 
                          <>{supportFile ? <Box className="documentUploaded">
                            <Box className="uploadIconBox">
                              <img src={FileIcon} />
                            </Box>
                            <Box className="wrapTextUpload">
                              <Typography className="textUpload1">{supportFile?.name}</Typography>
                              <Typography className="textUpload2" display="inline">{supportFile?.size}KB</Typography>
                            </Box>
                            <HighlightOffRoundedIcon className="iconBank" onClick={()=>handleDeleteSupporting()}/> 
                          </Box> :  <><input
                        data-testid='supportFile'
                        id="supportFile"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleUploadSupporting}/>
                      <label htmlFor="supportFile">
                      <Box className="documentUpload">
                            <AddIcon className="addIcon"/>
                            <Typography className='textUpload'>Upload</Typography>
                          </Box> 
                      </label></>
                          }   </> :  <Box className="documentUploaded">
                          <Box className="uploadIconBox">
                            <img src={FileIcon} />
                          </Box>
                          <Box className="wrapTextUpload">
                            <Typography className="textUpload1">{guarantorDetails?.attributes.supporting_docs.name}</Typography>
                          </Box>
                          <HighlightOffRoundedIcon data-testid="deleteSupporting" className="iconBank" onClick={handleDeleteSupportingFileEdit}/> 
                        </Box> }  
                        </Box>
                    </Box>
                  </Grid>
                </Grid>
                
                <Grid container>
                  <Grid item md={4}/>
                  <Grid item container md={4} xs={12}>
                    <Grid item md={6} xs={12} className="padding10">
                      <Button className="btnWrapCancel" data-testid='btnCancel' onClick={handleCancel}>
                        <Typography className="btnTextCancel">Cancel</Typography>
                      </Button>
                    </Grid>
                    
                    <Grid item md={6} xs={12} className="padding10">
                      <Button data-testid='btnSubmit' className="btnWrapAdd" type="submit" onClick={checkSubmit}>
                        <Typography className="btnTextAdd">Save</Typography>
                      </Button>
                      
                    </Grid>
                  </Grid>
                  <Grid item md={4}/>
                </Grid>
              </form>
            )}
        </Formik>
      </GuarantorStyle>
    </>
    // Customizable Area End
  );
};

// Customizable Area Start
const ModalStyle = styled(Box)(({ theme }) => ({
  '& .iconLoading': {
    color:'#CEAE71', 
    alignSelf:'center'
  },
  '& .wrapModal': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    backgroundColor:(theme as any).typography.body2,
    borderRadius: "16px",
    '@media (max-width: 1000px) and (orientation: landscape)': {
      height: 200,
      overflow:'auto',
      minWidth:'70% !important',
    },
    '@media (max-width: 600px)': {
      overflow:'auto',
      minWidth:'70% !important',
    },
    minWidth:'40%',
  },
  '& .wrapModalContentLoading': {
    justifyContent: 'center',
    padding: '40px',
    alignItems:'center',
    width:'100%',
    display:'flex'
  },
}));
const GuarantorStyle  = styled(Box)(({ theme }) => ({
  "& .uploadIconBox":{
    display: "flex",
    padding: "var(--border-radius, 16px)",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "8px", 
    background: "#FFEFBD",
  },
  '& .btnTextAdd': {
    textTransform: 'none',
    color: '#171717', 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  }, 
  "& .btnWrapAdd":{
    width: '100%',
    cursor: 'pointer',
    padding: "16px 0 16px 0", 
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',    
  },
  '& .btnTextCancel': {
    textTransform: 'none',
    color: '#CEAE71', 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  "& .btnWrapCancel":{
    cursor: 'pointer',
    padding: "16px 0 16px 0", 
    borderRadius: '8px', 
    width: '100%',
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',    
  },
  "& .btnWrapDraft":{
    cursor: 'pointer',
    padding: "16px 0 16px 0", 
    background: (theme as any).typography.body2, 
    borderRadius: '8px', 
    width: '100%',
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',    
  },
  '& .MuiInputBase-input::placeholder': {
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    color: (theme as any).typography.h3, 
  },
  "& .errorsMsg":{
    color: "#F87171",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  '& .MuiInputBase-input': {
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    color: (theme as any).typography.h1, 
  },
  '& .padding30':{
    padding: "30px",
  },
  '& .padding30xs':{
    width: 'fit-content',
    padding: "30px",
  },
  '& .container': {
    flex:1, 
    alignContent:'start',
  },
  '& .formikWrap': {
    height:"100%", 
    display:'flex', 
    flexDirection:'column',
    padding: 20 
  },
  '& .padding10':{
    padding: "10px",
  },
  '& .wrapHeaderCustomerDetail':{
    padding: "10px",
    display:'flex',
    flexDirection:'column',
  }, 
  '& .retrieveBtn':{
    fontFamily:'Inter',
    fontWeight:700,
    fontSize:18,
    color: (theme as any).typography.h2, 
  },  
  '& .inputField': {
    marginTop: '4px',
    borderRadius: 8,
    border: `1px ${(theme as any).typography.border} solid`,
    background: (theme as any).typography.body2, 
    padding: '10px 8px',
    color: (theme as any).typography.h3, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    width:'100% !important'
  },
  '& .labelText': {
    color: (theme as any).typography.h1, 
    fontSize: '14px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  '& .selectedBankLabel': {
    color: (theme as any).typography.h1, 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
    marginBottom:16
  },
  '& .wrapSelectedBank': {
    display:'flex', 
    flexDirection:'row',
    flexWrap:'wrap'
  },
  '& .wrapSelectedBankItem': {
    display:'flex', 
    justifyContent:'center', 
    alignItems:'center', 
    padding: 10, 
    border:`1px ${(theme as any).typography.border} solid`,
    borderRadius:16, 
    marginRight:24,
    marginBottom:10,
    backgroundColor:(theme as any).typography.body2, 
  },
  '& .wrapSelectedBankText': {
    color: (theme as any).typography.h1, 
    fontSize: '20px', 
    fontFamily: 'Outfit', 
    fontWeight: '400',
    marginRight:10
  },
  '& .errorInput': {
    marginTop: '4px',
    borderRadius: 8,
    background: (theme as any).typography.body2, 
    padding: '10px 8px',
    color: (theme as any).typography.h3, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    border: "1px solid #F87171",
  },
  '& .sectionLabel': {
    color: (theme as any).typography.h1, 
    fontSize: 24, 
    fontFamily: 'Inter', 
    fontWeight: '700',
    marginBottom:'20px'
  },
  "& .btnWrap":{
    cursor: 'pointer',
    paddingTop: "10px", 
    paddingBottom: "10px", 
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',
    height: 56,
    width: 150,
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  "& .btnTextApply":{
    color: '#171717', 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '700'
  },
  "& .iconBank":{
    color: (theme as any).typography.h3, 
  },
  "& .addIcon":{
    color: (theme as any).typography.h1, 
  },
  "& .wrapTextUpload":{
    width:110,
  },
  "& .textUpload":{
    color: (theme as any).typography.h1, 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '400'
  },
  "& .textUpload1":{
    color: (theme as any).typography.h1, 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '400',
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis'
  },
  "& .textUpload2":{
    color: (theme as any).typography.h3, 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '400',
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis'
  },
  "& .wrapDocuments":{
    cursor:'pointer',
    display:'flex',
    flexWrap:'wrap',
  },
  "& .wrapDocument":{
    marginRight: 20,
    marginBottom:10
  },
  "& .documentTitle":{
    color: (theme as any).typography.h1, 
    fontSize: "14px", 
    fontFamily: 'Outfit', 
    fontWeight: '400',
    marginBottom:8
  },
  "& .documentUpload":{
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23FFF6DCFF' stroke-width='2' stroke-dasharray='30%2c20' stroke-dashoffset='31' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: 8,
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    width:230,
    height:72,
    backgroundColor:(theme as any).typography.surface2, 
  },
  "& .documentUploaded":{
    borderRadius: 8,
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-between',
    width:230,
    height:72,
    backgroundColor:(theme as any).typography.surface2, 
    padding:'0 8px'
  }
}))

// Customizable Area End
export default GuarantorDetailsView

withWidth()(GuarantorDetailsView);
