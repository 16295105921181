import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    emailId : string;
    token : any;
    otp:boolean;
    errorMsg:string;
    formIsDirty: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    postForgotPasswordApiId: string = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionResponseData),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.state = {
            emailId : '',
            token : '',
            otp:true,
            errorMsg:"",
            formIsDirty:false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.postForgotPasswordApiId && responseJson) {
                this.handleForgrtPassword(responseJson)
            }
        }
        // Customizable Area End
    }

    onValueChange = (name: string, value: string) => {
        this.setState({ ...this.state, [name]: value });
    };

    handleForgrtPassword = (responseJson: any) => {
        if(responseJson?.errors){
            this.setState({errorMsg:responseJson?.errors[0]?.otp})
        }
        if(responseJson?.meta?.token){
            this.setState({otp:false})
        }
        
    }

    checkSubmit = () => {
       this.setState({...this.state, formIsDirty: true})
    }

    forgotPasswordApi = (args: any) => {
        const header = {
            "Content-Type": "application/json",
        };
        let formDataCampaign =  {
            "data": {
                "type": "email_account",
                "attributes":{
                    "email": args.emailId
                }
            }
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.profileForgotPasswordAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(formDataCampaign)
        );
        this.postForgotPasswordApiId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    navigateToEmailAccountLoginBlock = ()=> {
        const txtdata = new Message(getName(MessageEnum.NavigationMessage));
        txtdata.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        txtdata.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(txtdata);
    }
    // Customizable Area End

}