import { Box, Input, InputAdornment, MenuItem, Select, Typography, makeStyles, styled } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import CustomStatus from "./CustomStatus";
import { makeList } from "@uiw/react-md-editor/lib/cjs/commands/list";

interface Props {
  value: string,
  placeholder: string,
  onChange?: (e: any)=>void,
  isPostedBy?: boolean,
  testId?: string,
  isPrice?: boolean,
  isDeprecation?: boolean,
  isRegDate?: boolean,
  isEngineCap?: boolean,
  isMilage?: boolean,
  isVehicleType?: boolean,
  makeList?: any;
  modelList?: any;
  isModel?: boolean;
}

export const priceValueList = ['Below $10,000', '$10,000 to $15,000', '$15,001 to $20,000', '$20,001 to $25,000', '$25,001 to $30,000','$30,001 to $35,000', '$35,001 to $40,000', '$40,001 to $45,000', '$45,001 to $50,000', '$50,001 to $60,000', '$60,001 to $70,000', '$70,001 to $80,000', '$80,001 to $100,000', '$100,001 to $120,000', '$120,001 to $140,000', '$140,001 to $160,000', '$160,001 to $180,000', '$180,001 to $200,000', 'Above $200,000'];
export const depreciationList = ['Below $7k /yr', 'Below $8k /yr', 'Below $9k /yr', 'Below $10k /yr', '$10k to $11k /yr', '$11k to $12k /yr', '$12k to $13k /yr', '$13k to $14k /yr', '$14k to $16k /yr', '$16k to $18k /yr', '$18k to $20k /yr', '$20k to $25k /yr', 'Above $25k /yr'];
export const regDateList = ['2024', '2023', '2022', '2021', '2020','2019', '2018', '2017', '2016', '2015', '2014', '< 1 year(s) old', '< 2 year(s) old', '< 3 year(s) old', '< 4 year(s) old', '< 5 year(s) old', '> 6 year(s) old', '> 7 year(s) old', '> 8 year(s) old', '> 9 year(s) old', '> 10 year(s) old'];
export const engineCapList = ['Below 661cc', '661cc to 1,600cc', '1,601cc to 2,000cc', '2,001cc to 3,000cc', 'Above 3,000cc']
export const milageList = ['Below 10,000 km', '10,000 - 20,000 km', '20,001 - 30,000 km', '30,001 - 40,000 km', '40,001 - 50,000 km', '50,001 - 70,000 km', '70,001 - 90,000 km', '90,001 - 110,000 km', '110,001 - 130,000 km', '130,001 - 150,000 km', '150,001 - 200,000 km', '200,001 - 300,000 km', 'Above 300,000 km'];
export const vehicleTypeList = ['Mid-Sized Sedan', 'Luxury Sedan', 'Hatchback', 'MPV', 'SUV', 'Sports Car', 'Stationwagon', 'Diesel Car', 'Hybrid Car', 'Electric Car', 'Vintage Car'];

const CustomSelectSearchCatalogue = ({value, placeholder, onChange,isPrice,isPostedBy,isDeprecation, isRegDate, isEngineCap, isMilage, isVehicleType, testId, makeList, modelList, isModel}: Props) => {
  const classes = useStyles();
  return <CustomSelectStyle>
    <Select 
      data-testId={testId}
      className="inputField"
      fullWidth
      displayEmpty
      type="date"
      disableUnderline
      value={value}
      IconComponent={()=><ExpandMoreIcon style={{color:"#f3f3f380", position: 'absolute', right:0}}/>}
      MenuProps={{
        style: {
          marginTop: '10px',
          marginLeft: '-10px',
        },
        PaperProps:{ className : classes.paper  },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
      renderValue={
        () => renderValue(value, placeholder)
      }
      >
        <SelectStyle>
        {isPostedBy && <Input 
        data-testid={`inputId${testId}`}
        disableUnderline
        inputRef={input => input && input.focus()}
        className="inputField"  
        onChange={onChange}    
        defaultValue={value}    
        value={value}                  
        
        endAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>}/>}
        

        {isPrice && 
        <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, height:200, overflow:'auto'}}>
            <Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:''}})}>
              <Typography className="text">Any</Typography>
            </Box>
            {priceValueList.map(e=><Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:e}})}>
              <Typography className="text">{e}</Typography>
            </Box>)}
          </Box>      
        } 

        {isDeprecation && 
        <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, height:200, overflow:'auto'}}>
            <Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:''}})}>
              <Typography className="text">Any</Typography>
            </Box>
            {depreciationList.map(e=><Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:e}})}>
              <Typography className="text">{e}</Typography>
            </Box>)}
          </Box>      
        } 

        {isRegDate && 
        <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, height:200, overflow:'auto'}}>
            <Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:''}})}>
              <Typography className="text">Any</Typography>
            </Box>
            {regDateList.map(e=><Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:e}})}>
              <Typography className="text">{e}</Typography>
            </Box>)}
          </Box>      
        } 

        {isEngineCap && 
        <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, height:200, overflow:'auto'}}>
            <Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:''}})}>
              <Typography className="text">Any</Typography>
            </Box>
            {engineCapList.map(e=><Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:e}})}>
              <Typography className="text">{e}</Typography>
            </Box>)}
          </Box>      
        } 

        {isMilage && 
        <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, height:200, overflow:'auto'}}>
            <Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:''}})}>
              <Typography className="text">Any</Typography>
            </Box>
            {milageList.map(e=><Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:e}})}>
              <Typography className="text">{e}</Typography>
            </Box>)}
          </Box>      
        } 

        {isVehicleType && 
        <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, height:200, overflow:'auto'}}>
            <Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:''}})}>
              <Typography className="text">Any</Typography>
            </Box>
            {vehicleTypeList.map(e=><Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:e}})}>
              <Typography className="text">{e}</Typography>
            </Box>)}
          </Box>      
        }  
        
        {makeList && 
        <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, maxHeight:200, overflow:'auto'}}>
          <Box data-testid="testIdDealValue" style={{cursor:'pointer'}}  onClick={()=>onChange && onChange({target:{value:''}})}>
              <Typography className="text">Any</Typography>
            </Box>
            {makeList.map((e: any)=><Box data-testid="testIdDealValue" style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:e}})}>
              <Typography className="text">{e}</Typography>
            </Box>)}
          </Box>      
        } 

        {modelList?.length>0  ?
        <Box className="wrapStatus" style={{display:'flex', flexDirection:'column', gap:20, maxHeight:200, overflow:'auto'}}>
            {modelList.map((e: any)=><Box data-testid="testIdDealValue" style={{cursor:'pointer'}} onClick={()=>onChange && onChange({target:{value:e}})}>
              <Typography className="text">{e}</Typography>
            </Box>)}
          </Box>   : isModel ? <Typography className="text">Select a carmake first</Typography>    :null
        }    </SelectStyle>     
          
    </Select>
  </CustomSelectStyle>
}

const renderValue = (selected: any, message:any) => {
  if (selected !== "") {
    return <Typography className="searchText">{selected}</Typography>;
  }

  return <Typography className="searchText">{message}</Typography>;
};

const useStyles = makeStyles(theme=>({
  paper: {
    backgroundColor:(theme as any).typography.surface2,
        borderRadius:'8px',
        padding: '8px'
  }, 
  select: {
    '& .textSelect': {
      color: '#F3F3F3', 
      fontSize: '14px', 
      fontFamily: 'Outfit', 
      fontWeight: '400',
    },
    
    '& .wrapText': {
      cursor: 'pointer',
      padding: '8px'
    },
  },
}));

const SelectStyle = styled(Box)(({ theme }) => ({
  "& .text": {fontFamily:'Outfit', fontSize:'12px', color:theme.typography.h1, fontWeight:400},
  "& .text1": {fontFamily:'Outfit', fontSize:'16px', color:theme.typography.h1, fontWeight:400, display:'flex', flexDirection:'row', gap: 10},
  "& .text2": {fontFamily:'Outfit', fontSize:'16px', color:'#f3f3f365', fontWeight:400},
  "& .inputField": {
    display: "flex",
    height: "32px",
    width:"150px",
    padding: "10px 8px 10px 8px",
    justifyContent: "flex-end",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: (theme as any).typography.body2,
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
  },
}))

const CustomSelectStyle = styled(Box)(({ theme }) => ({
  '& .inputFieldServices': {
    marginTop: '4px',
    borderRadius: 8,
    border: `1px ${(theme as any).typography.border} solid`,
    background: (theme as any).typography.body2, 
    padding: '10px 8px',
    color: (theme as any).typography.h3, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    height:"32px",
  },
  
  "& .MuiSelect-select": {
    position:'absolute',
    left: 10,
    width:90
  },
  "& .searchText": {
    color: (theme as any).typography.h1, 
    fontSize: 14,
    fontFamily: 'Outfit', 
    fontWeight: '400', 
    width:100
  },
  '& .iconX': {
    cursor: 'pointer',
    color: (theme as any).typography.h1, 
  },

  '& .wrapStatus': {
    height:200,
    overflow:'auto'
  },
  '& .selectedService': {
    marginTop:'16px',
    display: 'flex',
    flexDirection:'column'
  },
  "& .inputField": {
    display: "flex",
    height: "32px",
    width:"150px",
    padding: "10px 8px 10px 8px",
    justifyContent: "flex-end",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: (theme as any).typography.body2, 
    color: (theme as any).typography.h3, 
    fontFamily: "Outfit",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .dropdownText": {
    color:(theme as any).typography.h1, 
    fontSize: 12,
    fontFamily: 'Outfit', 
    fontWeight: '400', 
  },
}))
export default CustomSelectSearchCatalogue;