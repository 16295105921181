import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const pageid = "205301136008162";
const longString = "EAAnW2X7zoOwBO0hcg7I3rsdZB9xmeZC5YZAmpnyZCc4jZA7XKtuw0n84ApNVkZBpeLN30O56wsAfKZCiWYezYVK0GNJo3t09ZBtWh2G1I5ZCt3kd8MBwmvZCEpErZCtO6idF3CyIgZBenvkNZCRpz3tZBreRr9TmI7pk7QuJtLfDZA6kCZA5zBhW5A50LaYUK0EbhlfIwFkZASr0a8AUZD"
const insta_id = "17841464943600812" ;

export const services = ['buying', 'selling', 'insurance']
import { getStorageData } from "../../../framework/src/Utilities";
 
interface Images {
  id: string , 
  url : string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  customerId: string,
  formIsDirty: boolean,

  isEdit: boolean;
  vehicleName: string,
  platform: any[],
  postCaption: string,
  hastag: string,
  media: any[],
  vehicleList: {},
  loading: boolean,
  errorMsg: string,
  selectedImage: any;
  currentImage: null;
  openModel: boolean;
  images: any;
  selectedImages: any,
  selectedVideos: any,
  vehicleData: any;
  removedImage: any,
  modalObject: any,
  uploadLoading: boolean,
  openProgressModal: boolean,
  is_posted_on_facebook: string ,
  is_posted_on_instagram:string,
  is_posted_on_tiktok: string,
  is_process_facebook: boolean,
  is_process_instagram: boolean,
  is_process_tiktok: boolean,
  keyForLabel:string,
  openDeletModal:boolean,
  deleteId:any,
  imageurlInsta: Images[],
  imageno : number,
  mediaIds : string[] , 
  imageindex : number,
  newPostCreated: any,
  selectedImagesURLFacebook:any,
  selectedImagesURLInstagram: any,
  selectedVideosURLFacebook:any,
  selectedVideosURLInstagram: any,
  instagramListIds: any,
  videoContainerId: string,
  postToBE : boolean ,
  postResponse : any ,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NewCustomerCRMController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getVechileListId: any;
  getNewPlatform: any;
  createNewPostId: any;
  createInstagramMediaContainerId : string | null ;
  publishInstagramMediaContainerId: string | null ;
  getMediaIdFacebookId : string | null ;
  createFacebookPostId : string | null ;
  editNewPostId: string | null;
  createItemContainerInstagramId: string | null;
  createCarouselContainerInstagramId: string | null;
  createVideoPostFacebookId: string | null;
  createInstagramMediaContainerVideoId: string | null;
  checkInstagramMediaContainerVideoStatusId: string | null;
  createItemContainerInstagramVideoId: string | null;
  checkInstagramMediaContainerVideoStatusCarouselId: string | null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.createInstagramMediaContainerId = null; 
    this.publishInstagramMediaContainerId = null;
    this.getMediaIdFacebookId = null;
    this.createFacebookPostId = null;
    this.editNewPostId = null;
    this.createItemContainerInstagramId = null;
    this.createCarouselContainerInstagramId = null;
    this.createVideoPostFacebookId = null;
    this.createInstagramMediaContainerVideoId = null;
    this.createItemContainerInstagramVideoId = null;

    this.checkInstagramMediaContainerVideoStatusId= null;
    this.checkInstagramMediaContainerVideoStatusCarouselId= null;
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      customerId: '',
      deleteId:null,
      openDeletModal:false,
      isEdit: false,
      formIsDirty: false,
      modalObject: {},
      vehicleName: "",
      platform: [],
      postCaption: "",
      hastag: "",
      media: [],
      loading: false,
      vehicleList: {},
      errorMsg: "",
      images: [],
      selectedImages: [],
      selectedVideos: [],
      selectedImage: null,
      openModel: false,
      currentImage: null,
      vehicleData: [],
      removedImage: [],
      uploadLoading: false,
      openProgressModal: false,
      is_posted_on_facebook: "false",
      is_posted_on_instagram: "false",
      is_posted_on_tiktok: "false",
      is_process_facebook: false,
      is_process_instagram: false,
      is_process_tiktok: false,
      keyForLabel:"initialID", 
      imageurlInsta:[] ,
      imageno: 0,
      mediaIds: [],
      imageindex: 0 ,
      newPostCreated: {},
      selectedImagesURLFacebook:[],
      selectedImagesURLInstagram: [],
      selectedVideosURLFacebook:[],
      selectedVideosURLInstagram: [],
      instagramListIds: [],
      videoContainerId: '' , 
      postToBE : false ,
      postResponse : {},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );  
      webErrorReponse && this.handleNoInternet();
      switch (webApiRequestCallId) {
        case this.getMediaIdFacebookId:
          responseJson && this.getMediaIdFacebookReceiver(responseJson);
          break;
        case this.createFacebookPostId:
          this.createFacebookPostReceiver(responseJson);
          break;
        case this.createVideoPostFacebookId:
          this.createVideoPostFacebookReceiver();
          break;
        case this.createNewPostId:
          this.setState({postResponse : responseJson})
          this.createNewPostReceiver(responseJson);    
          break;
        case this.createInstagramMediaContainerId:
          this.publishInstagramMediaContainer(responseJson.id);
          break;
        case this.publishInstagramMediaContainerId:
          
          this.publishInstagramMediaContainerReceiver(responseJson)
          break;
        case this.createItemContainerInstagramId: 
          this.createItemContainerInstagramReceiver(responseJson.id)
          break;
        case this.createCarouselContainerInstagramId:
          this.publishInstagramMediaContainer(responseJson.id);
          break;
        case this.getVechileListId:
          this.getVehicleReceiver(responseJson);
          break;
        case this.getNewPlatform:
          this.getPlatformReceiver(responseJson);
          break;
        case this.getVechileListId:
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
          break ;
        case this.editNewPostId:
          this.setState({ openProgressModal: true });
          this.modalHandler("postSuccess");
          break;
        case this.createInstagramMediaContainerVideoId:
          this.checkInstagramMediaContainerVideoStatus("IN_PROGRESS", responseJson.id)
          break;
        case this.checkInstagramMediaContainerVideoStatusId:
          setTimeout(() =>{
            this.checkInstagramMediaContainerVideoStatus(responseJson.status_code, responseJson.id)
          }, 10000);
          break;
        case this.createItemContainerInstagramVideoId:
          this.checkInstagramMediaContainerVideoStatusCarousel("IN_PROGRESS", responseJson.id)
          break;
        case this.checkInstagramMediaContainerVideoStatusCarouselId:
          setTimeout(() =>{
            this.checkInstagramMediaContainerVideoStatusCarousel(responseJson.status_code, responseJson.id)
          }, 10000);
          break;
        default:
          break;
      }
      runEngine.debugLog("Message Recived", message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start

    // Customizable Area End
  }

  onSubmit = async () => {
    let validfile : boolean = true ;
    for (const file of this.state.selectedImages) {
      if (!this.isValidFile(file)) {
          validfile = false;
      }
    }
    if(validfile) {
      this.modalHandler("processUpload")
      this.createNewPost();
    } else {
      this.modalHandler("uploadFIleType");
    }
  }

  handleNoInternet =()=>{
    this.setState({
      is_posted_on_facebook:'false',
      is_posted_on_instagram:'false',
      is_posted_on_tiktok:'false',
      is_process_facebook:false,
      is_process_instagram:false,
      is_process_tiktok:false,
    }, ()=>{this.modalHandler("postSuccess")}) 
    return {};
  }
  handleRetry = () => {
    if(this.state.postToBE) {
      this.createNewPostReceiver(this.state.postResponse)
    }
    else {
      this.modalHandler("processUpload")
      this.createNewPost()
    }
  }

  createNewPost = async () => {
  
    const tokenValue = await getStorageData("authToken")
    const headers = {
      token: tokenValue,
    };
    let { vehicleName, platform, hastag, postCaption, is_posted_on_facebook , is_posted_on_instagram} = this.state;
    const formdata = new FormData();
    if (this.state.selectedImages && this.state.selectedImages.length > 0) {
      for (const image of this.state.selectedImages) {
        formdata.append("images[]", image);
      }
    }

    if (this.state.selectedVideos && this.state.selectedVideos.length > 0) {
      for (const video of this.state.selectedVideos) {
        formdata.append("videos[]", video);
      }
    }
    
    formdata.append("vehicle_name", vehicleName);
    for(const data of platform){
      formdata.append("platform[]", data);
    }
   
    formdata.append("post", postCaption);
    formdata.append("hastag", hastag);
    formdata.append("is_posted_on_facebook", is_posted_on_facebook);
    formdata.append("is_posted_on_instagram" , is_posted_on_instagram) ;
    
    const requestMessageMarketing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSocialMediaEndpoint
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationPostApiMethodType
    );
    this.createNewPostId = requestMessageMarketing.messageId;
    runEngine.sendMessage(requestMessageMarketing.id, requestMessageMarketing);
  }

  createNewPostReceiver = (responseJson: any)=>{
    if(responseJson && responseJson.data.id) {
      this.setState({postToBE : true })
    }
    this.setState({
      newPostCreated: responseJson.data, 
      selectedImagesURLFacebook:responseJson.data.attributes.images, 
      selectedImagesURLInstagram:responseJson.data.attributes.images,
      selectedVideosURLFacebook:responseJson.data.attributes.videos ?? [],
      selectedVideosURLInstagram:responseJson.data.attributes.videos?? []
    }, ()=>{
      for (const platform of this.state.platform) {
        switch (platform) {
          case "Instagram":
            this.handlePostInstagram(responseJson);
          break;
          case "Facebook":
            this.handlePostFacebook();            
            break;
          default:
            break;
        }
      }
    })
  }

  handlePostInstagram = (responseJson: any)=>{
    this.setState({is_process_instagram:true,
    })
    if (((this.state.selectedVideosURLInstagram?.length ?? 0) + (this.state.selectedImagesURLInstagram?.length ?? 0)) === 1){
      if(responseJson.data.attributes?.videos?.length === 1) {
        this.createSingleVideoPostInstagram();
      } else {
        this.createSingleImagePostInstagram();
      }
    } else {
      this.createMultipleImagePostInstagram(this.state.selectedImagesURLInstagram[0].url)
    }
  }

  handlePostFacebook = ()=>{
    this.setState({is_process_facebook:true})
      if((this.state.selectedImagesURLFacebook?.length ?? 0) > 0 ){
        this.getMediaFacebookId(this.state.selectedImagesURLFacebook[0].url)
      } else {
        this.createVideoPostFacebook(this.state.selectedVideosURLFacebook[0].url)
      }
  }

  createVideoPostFacebook = async (url: any)=>{
    const requestMessageMarketing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      file_url: url,
      access_token: longString,
      description:`${this.state.postCaption} ${this.state.hastag}`
    };
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/${pageid}/videos`
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createVideoPostFacebookId = requestMessageMarketing.messageId; 
    runEngine.sendMessage(requestMessageMarketing.id, requestMessageMarketing) ;
  }

  createVideoPostFacebookReceiver = () => {
    let temp =[...this.state.selectedVideosURLFacebook];
    temp.shift()
    this.setState(prevState => ({
      selectedVideosURLFacebook: temp
    }), () => {
      if(this.state.selectedVideosURLFacebook.length > 0) {
        this.createVideoPostFacebook(this.state.selectedVideosURLFacebook[0].url)
      } else {
        this.setState({
          is_process_facebook: false,
          is_posted_on_facebook: "true" }, ()=>{
            this.ediPostAfterCreate();
          }
        )
      }
    });
  };

  getMediaFacebookId = async (url: any) => {
    const requestMessageMarketing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      url: url,
      access_token: longString,
      published: false,
    };
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/${pageid}/photos`
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.getMediaIdFacebookId = requestMessageMarketing.messageId; 
    runEngine.sendMessage(requestMessageMarketing.id, requestMessageMarketing) ;
  };

  getMediaIdFacebookReceiver = (response: any) => {
    let temparr =[...this.state.selectedImagesURLFacebook];
    temparr.shift()
    this.setState(prevState => ({
      mediaIds: [...prevState.mediaIds, response.id] ,
      selectedImagesURLFacebook: temparr
    }), () => {
      if(this.state.selectedImagesURLFacebook.length > 0) {
        this.getMediaFacebookId(this.state.selectedImagesURLFacebook[0].url)
      } else {
        if(this.state.selectedVideosURLFacebook.length >0){
          this.createVideoPostFacebook(this.state.selectedVideosURLFacebook[0].url)
        }
        this.postToFacebook(this.state.mediaIds)
      }
    });
  };

  postToFacebook = async (mediaIds: string[]) => {
    const requestMessageMarketing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      message: `${this.state.postCaption} ${this.state.hastag}`,
      attached_media: mediaIds.map(idmedia => ({ media_fbid: idmedia })),
    };
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type': 'application/json',
      })
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `https://graph.facebook.com/${pageid}/feed?access_token=${longString}`
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createFacebookPostId = requestMessageMarketing.messageId;
    runEngine.sendMessage(requestMessageMarketing.id, requestMessageMarketing);
  };

  createFacebookPostReceiver = (response: any) => {
    this.setState({
      is_process_facebook: false,
      is_posted_on_facebook: "true" }, ()=>{
        this.ediPostAfterCreate();
      }
    )
  };

  ediPostAfterCreate = async () => {
    const tokenValue = await getStorageData("authToken")
    const headers = {
      token: tokenValue,
    };     
    const formdata = new FormData();
    formdata.append("is_posted_on_facebook", this.state.is_posted_on_facebook);
    formdata.append("is_posted_on_instagram", this.state.is_posted_on_instagram);
    
    const requestMessageMarketing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSocialMediaEndpoint}/${this.state.newPostCreated.id}`
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutEampleMethod
    );
    this.editNewPostId = requestMessageMarketing.messageId;
    runEngine.sendMessage(requestMessageMarketing.id, requestMessageMarketing);
  }

  modalHandler = (uploadType: string) => {
    switch (uploadType) {
      case "uploadFIleType": this.setState({
        openModel: true,
        modalObject: {
          title: "Upload Media",
          errorMSg: "unsupported file format. please upload the file in .jpeg, .png, .mp4 or .mov.",
          buttonActions: false,
        }
      })
      break;
      case "uploadFailed": this.setState({
        openModel: true,
        uploadLoading: false,
        modalObject: {
          title: "Upload Media",
          errorMSg: "An error has occuured. Please try again later.",
          buttonActions: true,
        }
      })
        break;
      case "processUpload": this.setState({
        openModel: true,
        uploadLoading: true,
        modalObject: {
          title: "",
          errorMSg: "",
          subtitle: "Please wait while we are Uploading your post",
          buttonActions: false,
        }
      })
        break;
      case "postSuccess": this.setState({
        openModel: false,
        uploadLoading: false,
        openProgressModal: true,
        modalObject: {}
      })
        break;
      default:
        break;
    }
  }

  getVehicleReceiver = (responseJson: any) => {
    const data = { ...responseJson };
    this.setState({ vehicleList: data, loading: false })
  }
  
  getPlatformReceiver = (responseJson: any) => {
    const data = { ...responseJson };
    
    this.setState({ vehicleList: data, loading: false })
  }

  isValidFile(file: any) {
    const extension = file.name.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'mp4','mov'];
    return imageExtensions.includes(extension);
  }
  
  setImageUrlInsta = async (images:Images[]) => {
    this.setState({imageurlInsta: images})
  }
  
  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
    
  };

  getVehicleList = async () => {
    this.setState({
      loading: true
    })
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessageMarketing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVehicleListEndpoint}/search?query=${this.state.vehicleName}&marketing=true`
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getVechileListId = requestMessageMarketing.messageId;
    runEngine.sendMessage(requestMessageMarketing.id, requestMessageMarketing);
  }

  onChangeSelect = (event: any,setFieldValue:any) => {
    this.setState({ platform: event.target.value as string[] } );
    setFieldValue("platform",event.target.value as string[])
  }

  onHandleDeselect = (index: number,setFieldValue:any) => {
    const selectedPlatform = [...this.state.platform];
    selectedPlatform.splice(index, 1);
    this.setState({ platform: selectedPlatform })
    setFieldValue("platform",selectedPlatform)
  }



  handleImageChange = (event: any) => {
    let selectedFiles = event.target.files;
    const validTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/webp', 'image/tiff', 'video/mp4', 'video/quicktime',];
    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/webp', 'image/tiff'];
    const validVideoTypes = ['video/mp4', 'video/quicktime'];
    const filteredFiles = Array.from(selectedFiles).filter((file: any) =>
      validTypes.includes(file.type)
    );

    const filteredImageFiles = Array.from(selectedFiles).filter((file: any) =>
      validImageTypes.includes(file.type)
    );

    const filteredVideoFiles = Array.from(selectedFiles).filter((file: any) =>
      validVideoTypes.includes(file.type)
    );
 
    const identifier = Date.now().toString();
    this.setState({keyForLabel: identifier})
    
    if (selectedFiles.length > 0) {
    
      const newImages = Array.from(filteredFiles).map((file:any) => {
        return {
          name: file.name,
          url: URL.createObjectURL(file)
        };
      });
      
      this.setState((prevState) => ({
        media: [...prevState.selectedImages, ...Array.from(selectedFiles).slice(0, 8 - prevState.selectedImages.length)],
        images: [...prevState.images, ...newImages.slice(0, 8 - prevState.images.length)],
        selectedImages: [...prevState.selectedImages, ...Array.from(filteredImageFiles).slice(0, 8 - prevState.selectedImages.length - prevState.selectedVideos.length)],
        selectedVideos: [...prevState.selectedVideos, ...Array.from(filteredVideoFiles).slice(0, 8 - prevState.selectedImages.length - prevState.selectedVideos.length)],
        currentImage: null,
      }));
    }
  };

  handleRemoveImage = (index: number,setFieldValue:any) => {
    const updatedImages = [...this.state.images];
    const selectedImages = [...this.state.selectedImages];
    updatedImages.splice(index, 1);
    selectedImages.splice(index, 1)
    this.setState({
      images: updatedImages,
      selectedImages: selectedImages,
      media:selectedImages,
      openDeletModal:false
    });
    setFieldValue("media",selectedImages)
  };

  handelOpenDeletemodal = (index:number,) =>{
    this.setState({openDeletModal:true,deleteId:index})
  }

   isImageFileName(fileName:any) {
    const imageExtensions = [
      '.jpg',
      '.jpeg',
      '.png',
      '.gif',
      '.bmp',
      '.webp',
      '.svg',
      '.tiff',
    ];
    const fileExtension = fileName?.toLowerCase().slice(fileName.lastIndexOf('.'));

    return imageExtensions.includes(fileExtension);
  }

  createSingleImagePostInstagram = () => {
    this.createInstagramMediaContainer(this.state.selectedImagesURLInstagram[0].url, `${this.state.postCaption} ${this.state.hastag}`)
  };

  createSingleVideoPostInstagram = () => {
    this.createInstagramMediaContainerVideo(this.state.selectedVideosURLInstagram[0].url, `${this.state.postCaption} ${this.state.hastag}`)
  };

  createMultipleImagePostInstagram =(url: string)=>{
    this.createItemContainerInstagram(url)
  }

  createInstagramMediaContainerVideo = (videoUrl: string, caption: string) => {    
    const requestMessageMarketing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      video_url: videoUrl,
      caption: caption,
      access_token: longString,
      media_type: "REELS"
    };
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${insta_id}/media`
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createInstagramMediaContainerVideoId = requestMessageMarketing.messageId; 
    runEngine.sendMessage(requestMessageMarketing.id, requestMessageMarketing) ;
  };

  checkInstagramMediaContainerVideoStatus = (status: string, containerId: string) => {    
    switch (status) {
      case "FINISHED":
        this.publishInstagramMediaContainer(containerId)
        break;
      case "IN_PROGRESS":
        const requestMessageMarketing = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageMarketing.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
            'Content-Type' : 'application/json',
          })
        ) ;
        requestMessageMarketing.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage), 
          `https://graph.facebook.com/v20.0/${containerId}/?fields=status_code&access_token=${longString}`
        );
        requestMessageMarketing.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'GET'
        );
        this.checkInstagramMediaContainerVideoStatusId = requestMessageMarketing.messageId; 
        runEngine.sendMessage(requestMessageMarketing.id, requestMessageMarketing) ;
        break;
    
      default:
        break;
    }
  };

  checkInstagramMediaContainerVideoStatusCarousel = (status: string, containerId: string) => {    
    switch (status) {
      case "FINISHED":
        let temparr =[...this.state.selectedVideosURLInstagram]
        temparr.shift()
        this.setState(prevState => ({
          instagramListIds: [...prevState.instagramListIds, containerId],
          selectedVideosURLInstagram: temparr
        }), () => {
          if(this.state.selectedImagesURLInstagram.length > 0) {
            this.createItemContainerInstagramVideo(this.state.selectedImagesURLInstagram[0].url)
          } else {
            this.createCarouselContainerInstagram(this.state.instagramListIds)
          }
        });
        break;
      case "IN_PROGRESS":
        const requestMessageMarketing = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageMarketing.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
            'Content-Type' : 'application/json',
          })
        ) ;
        requestMessageMarketing.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage), 
          `https://graph.facebook.com/v20.0/${containerId}/?fields=status_code&access_token=${longString}`
        );
        requestMessageMarketing.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'GET'
        );
        this.checkInstagramMediaContainerVideoStatusCarouselId = requestMessageMarketing.messageId; 
        runEngine.sendMessage(requestMessageMarketing.id, requestMessageMarketing) ;
        break;
    
      default:
        break;
    }
  };

  createInstagramMediaContainer = (imageUrl: string, caption: string) => {    
    const requestMessageMarketing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      image_url: imageUrl,
      caption: caption,
      access_token: longString,
    };
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${insta_id}/media`
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createInstagramMediaContainerId = requestMessageMarketing.messageId; 
    runEngine.sendMessage(requestMessageMarketing.id, requestMessageMarketing) ;
  };
  
  publishInstagramMediaContainer = (creationId: string) => {
    const requestMessageMarketing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      creation_id : creationId,
      access_token: longString,
    };
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${insta_id}/media_publish`
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.publishInstagramMediaContainerId = requestMessageMarketing.messageId; 
    runEngine.sendMessage(requestMessageMarketing.id, requestMessageMarketing) ;
  }

  publishInstagramMediaContainerReceiver = (response: any)=>{
    if(response.error){
      this.setState({is_posted_on_instagram: "false", is_process_instagram: false},  
      ()=>{
        this.ediPostAfterCreate();
      })
    } else {
      this.setState({is_posted_on_instagram: "true", is_process_instagram: false},  
      ()=>{
        this.ediPostAfterCreate();
      })
    }
  }

  createItemContainerInstagram =  (url: string)=>{
    const requestMessageMarketing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      image_url: url,
      is_carousel_item: true,
      access_token: longString,
    };
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${insta_id}/media`
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createItemContainerInstagramId = requestMessageMarketing.messageId; 
    runEngine.sendMessage(requestMessageMarketing.id, requestMessageMarketing) ;
  }

  createItemContainerInstagramVideo =  (url: string)=>{
    const requestMessageMarketing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      video_url: url,
      media_type: 'VIDEO',
      is_carousel_item: true,
      access_token: longString,
    };
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${insta_id}/media`
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createItemContainerInstagramVideoId = requestMessageMarketing.messageId; 
    runEngine.sendMessage(requestMessageMarketing.id, requestMessageMarketing) ;
  }

  createItemContainerInstagramReceiver = (args: any)=>{
    let temparr =[...this.state.selectedImagesURLInstagram,]
    temparr.shift()
    this.setState(prevState => ({
      instagramListIds: [...prevState.instagramListIds, args],
      selectedImagesURLInstagram: temparr
    }), () => {
      if(this.state.selectedImagesURLInstagram.length > 0) {
        this.createItemContainerInstagram(this.state.selectedImagesURLInstagram[0].url)
      } else {
        if (this.state.selectedVideosURLInstagram.length > 0){
          this.createItemContainerInstagramVideo(this.state.selectedVideosURLInstagram[0].url)
        } else {
          this.createCarouselContainerInstagram(this.state.instagramListIds)
        }
      }
    });
  }

  createCarouselContainerInstagram = (listIds: any)=>{
    const requestMessageMarketing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      media_type: "CAROUSEL",
      caption: `${this.state.postCaption} ${this.state.hastag}`,
      children:listIds && listIds.join(','),
      access_token: longString,
    };
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${insta_id}/media`
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageMarketing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createCarouselContainerInstagramId = requestMessageMarketing.messageId; 
    runEngine.sendMessage(requestMessageMarketing.id, requestMessageMarketing) ;
  }  
  // Customizable Area End
}
