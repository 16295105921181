import * as React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  CircularProgress,
  TextField,
  Typography,
  withWidth
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { Formik } from "formik";
import CustomSelectSearchField from "../../../components/src/CustomSelectSearchField";
import * as Yup from 'yup';
import { Close } from "./assets";
import {  FileIcon } from "./assets";
import TokyoPdf from "../../../components/src/TokyoPdf";
import DBSPdf from "../../../components/src/DBSPdf";
import UOBPdf from "../../../components/src/UOBPdf";
import MoneyMaxPdf from "../../../components/src/MoneyMaxPdf";
import TambusaPdf from "../../../components/src/TambusaPdf";
import MaybankPdf from "../../../components/src/MaybankPdf";
import VmPdf from "../../../components/src/VmPdf";
import HongLeon from "../../../components/src/HongLeonPdf";
import DateRangeIcon from '@material-ui/icons/DateRange';
// Customizable Area End

import { ViewProps } from "./NewSalesAndContract";
import Calendar from "react-calendar";
import moment from "moment";

const NewSalesAndContractView: React.FC<ViewProps> = ({
  // Customizable Area Start
  testID,
  width,
  vehicleName , 
  vehiclePrice ,
  vehicleId ,
  vehicleNumber ,
  vehicleManufactured ,
  vehicleMake ,
  vehicleModel ,
  engineNumber ,
  chassisNumber ,
  firstRegistrationDate ,

  fullName,
  dob,
  NRIC ,
  address ,
  emailID ,
  contactNumber ,
  employer ,
  jobTitle ,
  employmentAddress ,
  grossMonthlySalary ,

  bank,
  tenure ,
  listSelectedBank,
  checkSubmit,
  handleSubmit,
  formIsDirty,
  openModalGuarantor,
  handleModalGuarantor,
  handleNavigateGuarantorDetail,
  handleSearchVehicle,
  listSearchVehicle,
  listSearchCustomer,
  handleSearchCustomer,
  CPFfile,
  NOAfile,
  supportFile,
  handleUploadCPF,
  handleUploadNOA,
  handleUploadSupporting,
  handleDeleteCPF,
  handleDeleteNOA,
  handleDeleteSupporting,

  checkSubmitGuarantor,
  handleSaveGuarantor,
  formIsDirtyGuarantor,
  openModalRetrieving,
  handleCancelGuarantor,
  listSearchGuarantor,
  handleSearchGuarantor,
  handleUploadCPFGuarantor,
  handleUploadNOAGuarantor,
  handleUploadSupportingGuarantor,
  handleDeleteCPFGuarantor,
  handleDeleteNOAGuarantor,
  handleDeleteSupportingGuarantor,
  CPFfileGuarantor,
  NOAfileGuarantor,
  supportFileGuarantor,
  isAddGuarantor,
  editGuarantor,
  deleteGuarantor,
  selectedGuarantorIndex,
  isEditGuarantor,

  setVehicleName,
  setVehiclePrice,
  setVehicleId,
  setVehicleNumber,
  setVehicleManufactured,
  setVehicleMake,
  setVehicleModel,
  setEngineNumber,
  setChassisNumber,
  setFirstRegistrationDate,
  setFullName,
  setNRIC,
  setAddress,
  setEmailID,
  setContactNumber,
  setEmployer,
  setDob,
  setJobTitle,
  setEmploymentAddress,
  setGrossMonthlySalary,
  setListSelectedBank,
  setTenure,
  listAddedGuarantor,
  handleCancel,
  data,
  listBank,
  isSaveUOB,
  isSaveMoneyMax,
  isSaveDBS,
  isSaveMaybank,
  isSaveTembusa,
  isSaveTokyo,
  isSaveVM,
  isSaveHongLeong,
  handleSavePdfDBS,
  handleSavePdfUOB,
  handleSavePdfMoneymax,
  handleSavePdfMaybank,
  handleSavePdfTembusa,
  handleSavePdfTokyo,
  handleSavePdfVM,
  handleSavePdfHongleon,
  handleMoment,
  saveAsDraft,
  toggleCalendarFirstRegistrationDate,
  isOpenCalendarFirstRegistrationDate,
  toggleCalendarDOBCustomer,
  toggleCalendarDOBGuarantor,
  isOpenCalendarDOBCustomer,
  isOpenCalendarDOBGuarantor,
  closeCalendar,
  dealDetail,
  isEdit,
  listGuarantorEditScreen,
  deleteGuarantorEdit,
  CPFfileEdit,
  NOAfileEdit,
  supportFileEdit,
  handleDeleteCPFFileEdit,
  handleDeleteNOAFileEdit,
  handleDeleteSupportingFileEdit,
  isLoading,
  // Customizable Area End
}) => {

  // Customizable Area Start
  const userSchema = () => {
    return Yup.object().shape({
      vehicleName: Yup.string().required('Select vehicle'),
      vehicleId: Yup.string().required('Enter Vehicle ID'),
      vehicleNumber: Yup.string().required('Enter Vehicle Number'),
      vehicleManufactured: Yup.string().required('Enter Vehicle manufactured year'),
      vehicleMake: Yup.string().required('Enter Vehicle make'),
      vehicleModel: Yup.string().required('Enter Vehicle model'),
      engineNumber: Yup.string().matches(/^\w{10}$/,'Engine number should be 10 alphanumeric characters').required('Enter Engine number '),
      chassisNumber: Yup.string().matches(/^\w{17}$/,'Chassis number should be 17 alphanumeric characters').required('Enter Chassis number'),
      firstRegistrationDate: Yup.string().required('Select registration date'),
      fullName:Yup.string().required('Select Full Name'),
      dob:Yup.string().required('Select Date Of Birth'),
      NRIC: Yup.string().matches(/^[A-Za-z]\d{7}#$/, 'NRIC should start with a letter, followed by 7 digits and ending with #').required('Enter NRIC'),
      address: Yup.string().required('Enter Address'),
      emailID: Yup.string().required('Enter Email ID').notOneOf([...listAddedGuarantor.map((e: any)=>e.emailIDGuarantor)], 'Enter a different Email ID').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email ID is invalid'),
      contactNumber:Yup.string().required('Enter Contact Number'),
      employer: Yup.string().required('Enter Employer'),
      jobTitle: Yup.string().required('Enter Job Title'),
      employmentAddress: Yup.string().required('Enter Employment Address'),
      grossMonthlySalary: Yup.string().required('Enter Gross Monthly Salary'),
      // bank:Yup.string().required('Select a bank'),
      tenure: Yup.string().required('Select a loan tennure'),
      listSelectedBank: Yup.array().of(
        Yup.object().shape({
          bank: Yup.string().required('Select a bank'),
        })
      ).required()
     });
  };

  const userSchemaGuarantor = () => {
    const listEmail = isEditGuarantor 
    ? listAddedGuarantor
        .map((e: any) => e.emailIDGuarantor)
        .filter((f: any) => f !== listAddedGuarantor[selectedGuarantorIndex].emailIDGuarantor)
    : listAddedGuarantor
    .map((e: any) => e.emailIDGuarantor);
    return Yup.object().shape({
      NRICGuarantor: Yup.string().matches(/^[A-Za-z]\d{7}#$/, 'NRIC should start with a letter, followed by 7 digits and ending with #'),
      emailIDGuarantor: Yup.string().notOneOf([emailID, ...listEmail], 'Enter a different Email ID').required('Enter Guarantor Email').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email ID is invalid'),
     });
  };


  const renderModalGuarantor = () => {
    return <Modal open={openModalGuarantor}>
      <ModalStyle>
        <Box className="wrapModal">
          <Box className="modalHeaderWrap">
            <Typography className="modalHeaderText">Delete Guarantor</Typography>
            <IconButton onClick={handleModalGuarantor} className="iconPadding">
              <img src={Close}/>
            </IconButton>
          </Box>
          <Box className="line"/> 
          <Box className="wrapModalContent">
            <Typography className="deleteMsg">Are you sure you want to delete this Guarantor </Typography>
          </Box>
          <Box className="line"/> 
          <Box className="modalFooterWrap">
            <Button data-testId='cancalBtn' className="cancelBtn" onClick={handleModalGuarantor}>Cancel</Button>
            <Button data-testId='saveBtn' className="saveBtn" onClick={deleteGuarantorEdit}>Delete</Button>
          </Box> 
        </Box> 
      </ModalStyle>
    </Modal>
  }

  const renderModalConfirmEdit = () => {
    return <Modal open={false}>
      <ModalStyle>
        <Box className="wrapModal">
          <Box className="modalHeaderWrap">
            <Typography className="modalHeaderText">Confirm to Edit</Typography>
            <IconButton onClick={()=>{}} className="iconPadding">
              <img src={Close}/>
            </IconButton>
          </Box>
          <Box className="line"/> 
          <Box className="wrapModalContent">
            <Typography className="deleteMsg">Are you sure you want to edit this Deal?</Typography>
          </Box>
          <Box className="line"/> 
          <Box className="modalFooterWrap">
            <Button data-testId='cancalBtn' className="cancelBtn" onClick={()=>{}}>Cancel</Button>
            <Button data-testId='saveBtn' className="saveBtn" onClick={()=>{}}>Save</Button>
          </Box> 
        </Box> 
      </ModalStyle>
    </Modal>
  }

  const renderLoading = () => {
    return <Modal open={isLoading}>
      <ModalStyle>
        <Box className="wrapModal">
          <Box className="wrapModalContentLoading">
            <CircularProgress className="iconLoading" size={20} />
          </Box>
        </Box> 
      </ModalStyle>
    </Modal>
  }
  // Customizable Area End

  return (
    // Customizable Area Start
    <>
    
      {data && <DBSPdf isSave={isSaveDBS} setPdf={handleSavePdfDBS} data={data}/>}
      {data && <UOBPdf isSave={isSaveUOB} setPdf={handleSavePdfUOB} data={data}/>}
      {data && <MoneyMaxPdf isSave={isSaveMoneyMax} setPdf={handleSavePdfMoneymax} data={data}/>}
      {data && <TokyoPdf isSave={isSaveTokyo} setPdf={handleSavePdfTokyo} data={data}/>}
      {data && <TambusaPdf isSave={isSaveTembusa} setPdf={handleSavePdfTembusa} data={data}/>}
      {data && <MaybankPdf isSave={isSaveMaybank} setPdf={handleSavePdfMaybank} data={data}/>}
      {data && <VmPdf isSave={isSaveVM} setPdf={handleSavePdfVM} data={data}/>}
      {data && <HongLeon isSave={isSaveHongLeong} setPdf={handleSavePdfHongleon} data={data}/>}
      {renderModalGuarantor()}
      {renderLoading()}
      {renderModalConfirmEdit()}
      <NewSalesAndContractStyle data-testid={testID}>
        {!isAddGuarantor? <Formik 
            data-testId='formik1'
            enableReinitialize
            initialValues={{
              vehicleName: vehicleName, 
              vehiclePrice: vehiclePrice,
              vehicleId: vehicleId,
              vehicleNumber: vehicleNumber,
              vehicleManufactured: vehicleManufactured,
              vehicleMake: vehicleMake,
              vehicleModel: vehicleModel,
              engineNumber:engineNumber ,
              chassisNumber: chassisNumber,
              firstRegistrationDate: firstRegistrationDate,

              fullName:fullName,
              dob:dob,
              NRIC: NRIC,
              address: address,
              emailID: emailID,
              contactNumber: contactNumber,
              employer: employer,
              jobTitle: jobTitle,
              employmentAddress: employmentAddress,
              grossMonthlySalary: grossMonthlySalary,

              bank:bank,
              tenure: tenure,
              listSelectedBank : listSelectedBank
            }} 
            validationSchema={userSchema}
            validateOnChange={formIsDirty}
            validateOnBlur={formIsDirty}
            onSubmit={(e)=>{
              handleSubmit(e)
            }}>
              {({errors, setFieldValue, handleSubmit, handleChange, values})=>(
              <form data-testId='form1' onSubmit={handleSubmit} className="formikWrap">
                <Grid container className='container'>
                  <Grid container className="padding10">
                    <Typography className="sectionLabel">Vehicle Details</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Select From Catalogue</Typography> 
                      <CustomSelectSearchField                         
                        listSearchVehicle={listSearchVehicle}
                        value={values.vehicleName} placeholder={'Select'} 
                        isError={errors.vehicleName} isVehicle 
                        onChange={(e)=>handleSearchVehicle(e)}
                        testId="searchVehicle"
                        closeCalendar={closeCalendar}
                        onSelect={(e)=>{
                          setFieldValue('vehicleName', e.attributes.name);                          
                          setFieldValue('vehiclePrice', e.attributes.selling_price);
                          setFieldValue('vehicleId', e.id);
                          setFieldValue('vehicleNumber', e.attributes.vehicle_number);
                          setFieldValue('vehicleManufactured',e.attributes.manufactured_year);
                          setFieldValue('vehicleMake',e.attributes.make);
                          setFieldValue('vehicleModel',e.attributes.model);                          
                          setFieldValue('firstRegistrationDate', handleMoment(e.attributes.reg_date, 'DD/MM/YYYY'));
                          setVehicleId(e.id);
                          setVehicleName(e.attributes.name)
                          setVehiclePrice(e.attributes.selling_price)
                          setVehicleNumber(e.attributes.vehicle_number)
                          setVehicleManufactured(e.attributes.manufactured_year)
                          setVehicleMake(e.attributes.make);
                          setVehicleModel(e.attributes.model);
                          setFirstRegistrationDate(handleMoment(e.attributes.reg_date, 'DD/MM/YYYY'))
                          }}/>
                      {errors.vehicleName && 
                        <Typography className="errorsMsg">{errors.vehicleName}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Vehicle Price</Typography>
                    <TextField
                      data-testId='vehiclePrice'
                      className={clsx({'errorInput': errors.vehiclePrice, 'inputField': !errors.vehiclePrice},)}
                      placeholder="Place holder"
                      disabled
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.vehiclePrice}
                      onChange={(e)=>{
                        setFieldValue('vehiclePrice', e.target.value);
                        setVehiclePrice(e.target.value)
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                      {errors.vehiclePrice && 
                        <Typography className="errorsMsg">{errors.vehiclePrice}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Vehicle ID</Typography>
                    <TextField
                      data-testId='vehicleId'
                      className={clsx({'errorInput': errors.vehicleId, 'inputField': !errors.vehicleId},)}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.vehicleId}
                      onChange={(e)=>{
                        setFieldValue('vehicleId', e.target.value);
                        setVehicleId(e.target.value)
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                      {errors.vehicleId && 
                        <Typography className="errorsMsg">{errors.vehicleId}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Vehicle Number</Typography>
                    <TextField
                      data-testId='email'
                      className={clsx({'errorInput': errors.vehicleNumber, 'inputField': !errors.vehicleNumber},)}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.vehicleNumber}
                      onChange={(e)=>{
                        setFieldValue('vehicleNumber', e.target.value);
                        setVehicleNumber(e.target.value)
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                      {errors.vehicleNumber && 
                        <Typography className="errorsMsg">{errors.vehicleNumber}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Enter The Vehicle Manufactured Year</Typography>
                    <TextField
                      data-testId='vehicleManufactured'
                      className={clsx({'errorInput': errors.vehicleManufactured, 'inputField': !errors.vehicleManufactured},)}
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"}, }}
                      placeholder="Place holder"
                      value={values.vehicleManufactured}
                      onChange={(e)=>{
                        setFieldValue('vehicleManufactured', e.target.value);
                        setVehicleManufactured(e.target.value)
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                    {errors.vehicleManufactured && 
                      <Typography className="errorsMsg">{errors.vehicleManufactured}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Vehicle Make</Typography>
                    
                    <TextField
                      data-testId='vehicleMake'
                      className={clsx({'errorInput': errors.vehicleMake, 'inputField': !errors.vehicleMake},)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.vehicleMake}
                      onChange={(e)=>{
                        setFieldValue('vehicleMake', e.target.value);
                        setVehicleMake(e.target.value)
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                    {errors.vehicleMake && 
                        <Typography className="errorsMsg">{errors.vehicleMake}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Vehicle Model</Typography>
                    <TextField
                      data-testId='vehicleModel'
                      className={clsx({'errorInput': errors.vehicleModel, 'inputField': !errors.vehicleModel},)}
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"}, }}
                      placeholder="Place holder"
                      value={values.vehicleModel}
                      onChange={(e)=>{
                        setFieldValue('vehicleModel', e.target.value);
                        setVehicleModel(e.target.value)
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                    {errors.vehicleModel && 
                      <Typography className="errorsMsg">{errors.vehicleModel}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Engine Number</Typography>
                    
                    <TextField
                      data-testId='engineNumber'
                      className={clsx({'errorInput': errors.engineNumber, 'inputField': !errors.engineNumber},)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.engineNumber}
                      onChange={(e)=>{
                        setFieldValue('engineNumber', e.target.value);
                        setEngineNumber(e.target.value)
                      }}
                      
                      // onChange={(event) => {
                      //   const regex = /\A[\w\d]{10}\z/;
                      //   if (event.target.value === "" || regex.test(event.target.value)) {
                      //     setFieldValue('engineNumber', event.target.value);
                      //     setEngineNumber(event.target.value)                     
                      //   }
                      // }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                    {errors.engineNumber && 
                        <Typography className="errorsMsg">{errors.engineNumber}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Chassis Number</Typography>
                    <TextField
                      data-testId='chassisNumber'
                      className={clsx({'errorInput': errors.chassisNumber, 'inputField': !errors.chassisNumber},)}
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"}, }}
                      placeholder="Place holder"
                      value={values.chassisNumber}
                      onChange={(e)=>{
                        setFieldValue('chassisNumber', e.target.value);
                        setChassisNumber(e.target.value)
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                    {errors.chassisNumber && 
                      <Typography className="errorsMsg">{errors.chassisNumber}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">First Registration Date</Typography>
                    <div className="customBtn">
                      <button type="button"
                      data-test-id="firstRegistrationDate"
                      className={errors.firstRegistrationDate ? "customButtonError":"customButton"}
                       onClick={toggleCalendarFirstRegistrationDate}> <Typography className="date">{values.firstRegistrationDate != "" ? moment(values.firstRegistrationDate).format('MM/DD/YYYY') : "mm/dd/yyyy"}</Typography><DateRangeIcon />    </button>

                    {
                      isOpenCalendarFirstRegistrationDate && <Calendar
                        className="customCalendarPop"
                        data-test-id="regDate"
                        onChange={(event: any) => {
                          setFieldValue("firstRegistrationDate", event);
                          setFirstRegistrationDate(event)
                          toggleCalendarFirstRegistrationDate()
                        }}
                        value={values.firstRegistrationDate} />
                    }
                    </div>
                    {errors.firstRegistrationDate && 
                        <Typography className="errorsMsg">{errors.firstRegistrationDate}</Typography>}
                  </Grid>
                </Grid>

                <Grid container className='container'>
                  <Grid container className="wrapHeaderCustomerDetail">
                    <Typography className="sectionLabel">Customer Details</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Full Name</Typography>
                      <CustomSelectSearchField 
                        value={values.fullName} 
                        listSearchCustomer={listSearchCustomer} placeholder={'Select'} isFullName 
                        onChange={handleSearchCustomer}
                        closeCalendar={closeCalendar}
                        isError={errors.fullName}
                        onSelect={(e)=>{
                          setFieldValue('fullName', e.attributes.name);
                          setFieldValue('dob', handleMoment(e.attributes.date_of_birth, 'DD/MM/YYYY'));
                          setFieldValue('emailID', e.attributes.email);
                          setFieldValue('contactNumber', e.attributes.contact_number);
                          setFullName(e.attributes.name);
                          setDob(handleMoment(e.attributes.date_of_birth, 'DD/MM/YYYY'));
                          setEmailID(e.attributes.email);
                          setContactNumber(e.attributes.contact_number);
                          }}/>
                      {errors.fullName && 
                        <Typography className="errorsMsg">{errors.fullName}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Date Of Birth</Typography>
                    <div className="customBtn">
                      <button type="button"
                      data-test-id="firstRegistrationDate"
                      className={errors.dob ? "customButtonError":"customButton"}
                       onClick={toggleCalendarDOBCustomer}> <Typography className="date">{dob != "" ? moment(dob).format('MM/DD/YYYY') : "mm/dd/yyyy"}</Typography><DateRangeIcon />    </button>

                    {
                      isOpenCalendarDOBCustomer && <Calendar
                        className="customCalendarPop"
                        data-test-id="regDate"
                        onChange={(event: any) => {
                          setFieldValue("firstRegistrationDate", event);
                          setDob(event)
                          toggleCalendarDOBCustomer()
                        }}
                        value={dob} />
                    }</div>
                      {errors.dob && 
                        <Typography className="errorsMsg">{errors.dob}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">NRIC Number</Typography>
                    <TextField
                      data-testId='NRIC'
                      className={clsx({'errorInput': errors.NRIC, 'inputField': !errors.NRIC},)}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.NRIC}
                      onChange={(e)=>{
                        setFieldValue('NRIC', e.target.value);
                        setNRIC(e.target.value)
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                      {errors.NRIC && 
                        <Typography className="errorsMsg">{errors.NRIC}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Address</Typography>
                    <TextField
                      data-testId='address'
                      className={clsx({'errorInput': errors.address, 'inputField': !errors.address},)}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.address}
                      onChange={(e)=>{
                        setFieldValue('address', e.target.value);
                        setAddress(e.target.value)
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                      {errors.address && 
                        <Typography className="errorsMsg">{errors.address}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Email ID</Typography>
                    <TextField
                      data-testId='emailID'
                      className={clsx({'errorInput': errors.emailID, 'inputField': !errors.emailID},)}
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"}, }}
                      placeholder="Place holder"
                      value={values.emailID}
                      onChange={(e)=>{
                        setFieldValue('emailID', e.target.value);
                        setEmailID(e.target.value)
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                    {errors.emailID && 
                      <Typography className="errorsMsg">{errors.emailID}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Contact Number</Typography>
                    
                    <TextField
                      data-testId='contactNumber'
                      className={clsx({'errorInput': errors.contactNumber, 'inputField': !errors.contactNumber},)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.contactNumber}
                      onChange={(event) => {
                        const regex = /^[0-9\b]+$/;
                        if (event.target.value === "" || regex.test(event.target.value)) {
                            setFieldValue('contactNumber', event.target.value);
                            setContactNumber(event.target.value)                        
                        }
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                    {errors.contactNumber && 
                        <Typography className="errorsMsg">{errors.contactNumber}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Employer</Typography>
                    <TextField
                      data-testId='employer'
                      className={clsx({'errorInput': errors.employer, 'inputField': !errors.employer},)}
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"}, }}
                      placeholder="Place holder"
                      value={values.employer}
                      onChange={(e)=>{
                        setFieldValue('employer', e.target.value);
                        setEmployer(e.target.value)
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                    {errors.employer && 
                      <Typography className="errorsMsg">{errors.employer}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Job Title</Typography>
                    
                    <TextField
                      data-testId='jobTitle'
                      className={clsx({'errorInput': errors.jobTitle, 'inputField': !errors.jobTitle},)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.jobTitle}
                      onChange={(e)=>{
                        setFieldValue('jobTitle', e.target.value);
                        setJobTitle(e.target.value)
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                    {errors.jobTitle && 
                        <Typography className="errorsMsg">{errors.jobTitle}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Employment Address</Typography>
                    <TextField
                      data-testId='employmentAddress'
                      className={clsx({'errorInput': errors.employmentAddress, 'inputField': !errors.employmentAddress},)}
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"}, }}
                      placeholder="Place holder"
                      value={values.employmentAddress}
                      onChange={(e)=>{
                        setFieldValue('employmentAddress', e.target.value);
                        setEmploymentAddress(e.target.value)
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                    {errors.employmentAddress && 
                      <Typography className="errorsMsg">{errors.employmentAddress}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Gross Monthly Salary</Typography>
                    
                    <TextField
                      data-testId='grossMonthlySalary'
                      className={clsx({'errorInput': errors.grossMonthlySalary, 'inputField': !errors.grossMonthlySalary},)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.grossMonthlySalary}
                      onChange={(event) => {
                        const regex = /^[0-9\b]+$/;
                        if (event.target.value === "" || regex.test(event.target.value)) {
                          setFieldValue('grossMonthlySalary', event.target.value);
                          setGrossMonthlySalary(event.target.value)                       
                        }
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                    {errors.grossMonthlySalary && 
                        <Typography className="errorsMsg">{errors.grossMonthlySalary}</Typography>}
                  </Grid>
                </Grid>

                <Grid container className='container'>
                  <Grid container className="wrapHeaderCustomerDetail">
                    <Typography className="sectionLabel">Guarantor Details</Typography>
                    {listAddedGuarantor.map((e:any, index:number)=><Box className="guarantorItemWrap">
                      <Typography className="guarantorItem">{e.fullNameGuarantor}</Typography>
                      <Box className="guarantorIconGroupWrap">
                        <Box className="guarantorIconWrap" onClick={()=>editGuarantor(index)}>
                          <EditIcon className="iconGuarantor"/>
                          <Typography className="guarantorItemIcon">Edit</Typography>
                        </Box>
                        <Box className="guarantorIconWrap"  onClick={()=>deleteGuarantor(index)}>
                          <DeleteIcon className="iconGuarantor"/>
                          <Typography className="guarantorItemIcon">Delete</Typography>
                        </Box>
                      </Box>
                    </Box>)}
                    {(listAddedGuarantor.length +(listGuarantorEditScreen ? listGuarantorEditScreen.data.length: 0)) <2 && <Box data-testId={'addGuarantorBtn'} className="btnWrap" onClick={()=>handleNavigateGuarantorDetail()}>
                      <AddIcon/>
                      <Typography className="btnTextApply">Add Guarantor</Typography>
                    </Box>}
                  </Grid>
                  
                </Grid>

                <Grid container className='container'>
                  <Grid container className="wrapHeaderCustomerDetail">
                    <Typography className="sectionLabel">Financial Details</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Choose Bank To Apply Loan</Typography>
                    <CustomSelectSearchField listBank={listBank} value={values.bank} placeholder={'Select'} isBank isError={errors.listSelectedBank} 
                      closeCalendar={closeCalendar}
                      onChange={(e)=>{
                        
                        if(values.listSelectedBank.filter((data:any)=>data.bank.name === e.target.value.name).length === 0){
                          let temp = listSelectedBank;
                          setFieldValue('bank', e.target.value.name)
                          setListSelectedBank( [...temp,{'bank':e.target.value}])
                          setFieldValue('listSelectedBank', [...temp,{'bank':e.target.value}])
                        }}}/>
                      {errors.listSelectedBank && 
                        <Typography className="errorsMsg">Select a bank</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Choose Loan Tenure</Typography>
                    <CustomSelectSearchField value={values.tenure} placeholder={'Select'} isError={errors.tenure} isTenure 
                      closeCalendar={closeCalendar}
                      onChange={(e)=>{
                        setFieldValue('tenure', e.target.value)
                        setTenure(e.target.value)
                        }}/>
                      {errors.tenure && 
                        <Typography className="errorsMsg">Select A Tenure</Typography>}
                  </Grid>
                  {values.listSelectedBank?.length > 0 && <Grid container className="wrapHeaderCustomerDetail">
                    <Typography className="selectedBankLabel">Selected Banks</Typography>
                    <Box className="wrapSelectedBank">
                    {values.listSelectedBank.map((e:any,index: number)=><Box className="wrapSelectedBankItem">
                      <Typography className="wrapSelectedBankText">{e.bank.name}</Typography>
                      <HighlightOffRoundedIcon className="iconBank" onClick={()=>{
                        let temp = values.listSelectedBank;
                        temp.splice(index, 1)
                        setFieldValue('listSelectedBank', temp)
                      }}/>                      
                    </Box>)}
                    </Box>
                  </Grid>}
                  <Grid container className="wrapHeaderCustomerDetail">
                    <Typography className="selectedBankLabel">Upload Supporting Documents</Typography>
                    <Box className="wrapDocuments">
                      
                        <Box className="wrapDocument">
                          <Typography className="documentTitle">CPF Contribution For Last 6 Months</Typography>
                          {(CPFfileEdit === null || CPFfileEdit===undefined) ?
                          <>{CPFfile ? <Box className="documentUploaded">
                            <Box className="uploadIconBox">
                              <img src={FileIcon} />
                            </Box>
                            <Box className="wrapTextUpload">
                              <Typography className="textUpload1">{CPFfile?.name}</Typography>
                              <Typography className="textUpload2" display="inline">{CPFfile?.size}KB</Typography>
                            </Box>
                            <HighlightOffRoundedIcon className="iconBank" type="button" onClick={()=>handleDeleteCPF()}/> 
                          </Box> : <>
                            <input
                              data-testId='CPFupload'
                              id="CPFupload"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={handleUploadCPF}/>
                            <label htmlFor="CPFupload">
                              <Box className="documentUpload">
                                <AddIcon className="addIcon"/>
                                <Typography className='textUpload'>Upload</Typography>
                              </Box> 
                            </label>
                          </>
                          }</> :<Box className="documentUploaded">
                          <Box className="uploadIconBox">
                            <img src={FileIcon} />
                          </Box>
                          <Box className="wrapTextUpload">
                            <Typography className="textUpload1">{dealDetail?.attributes.cpf_contribution_for_last_six_month.name}</Typography>
                          </Box>
                          <HighlightOffRoundedIcon className="iconBank" onClick={handleDeleteCPFFileEdit}/> 
                        </Box> }
                        </Box>
                      
                        <Box className="wrapDocument">
                          <Typography className="documentTitle">NOA For Last 2 Years</Typography>
                          {NOAfileEdit === null || NOAfileEdit === undefined ?
                          <>{NOAfile ? <Box className="documentUploaded">
                            <Box className="uploadIconBox">
                              <img src={FileIcon} />
                            </Box>
                            <Box className="wrapTextUpload">
                              <Typography className="textUpload1">{NOAfile?.name}</Typography>
                              <Typography className="textUpload2" display="inline">{NOAfile?.size}KB</Typography>
                            </Box>
                            <HighlightOffRoundedIcon className="iconBank" type="button" onClick={()=>handleDeleteNOA()}/> 
                          </Box> :<><input
                        data-testId='NOAfile'
                        id="NOAfile"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleUploadNOA}/>
                      <label htmlFor="NOAfile"><Box className="documentUpload">
                            <AddIcon className="addIcon"/>
                            <Typography className='textUpload'>Upload</Typography>
                          </Box></label></> 
                          }</> : <Box className="documentUploaded">
                          <Box className="uploadIconBox">
                            <img src={FileIcon} />
                          </Box>
                          <Box className="wrapTextUpload">
                            <Typography className="textUpload1">{dealDetail?.attributes.noa_for_last_two_year?.name}</Typography>
                          </Box>
                          <HighlightOffRoundedIcon className="iconBank" onClick={handleDeleteNOAFileEdit}/> 
                        </Box>}
                        </Box>
                      
                      
                        <Box className="wrapDocument">
                          <Typography className="documentTitle">Upload Supporting Docs</Typography>
                          {(supportFileEdit === null || supportFileEdit === undefined) ?
                          <>{supportFile ? <Box className="documentUploaded">
                            <Box className="uploadIconBox">
                              <img src={FileIcon} />
                            </Box>
                            <Box className="wrapTextUpload">
                              <Typography className="textUpload1">{supportFile?.name}</Typography>
                              <Typography className="textUpload2" display="inline">{supportFile?.size}KB</Typography>
                            </Box>
                            <HighlightOffRoundedIcon className="iconBank" type="button" onClick={()=>handleDeleteSupporting()}/> 
                          </Box> :<><input
                        data-testId='supportFile'
                        id="supportFile"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleUploadSupporting}/>
                      <label htmlFor="supportFile"><Box className="documentUpload">
                            <AddIcon className="addIcon"/>
                            <Typography className='textUpload'>Upload</Typography>
                          </Box></label></> 
                          }</>  :<Box className="documentUploaded">
                          <Box className="uploadIconBox">
                            <img src={FileIcon} />
                          </Box>
                          <Box className="wrapTextUpload">
                            <Typography className="textUpload1">{dealDetail?.attributes.supporting_docs.name}</Typography>
                          </Box>
                          <HighlightOffRoundedIcon className="iconBank" onClick={handleDeleteSupportingFileEdit}/> 
                        </Box>}
                        </Box>
                    </Box>
                  </Grid>
                </Grid>
                
                {isEdit ? <Grid container>
                  <Grid item md={4}/>
                  <Grid item container md={4} xs={12}>
                    <Grid item md={6} xs={12} className="padding10">
                      <Button className="btnWrapCancel" data-testId='btnCancel' onClick={handleCancel}>
                        <Typography className="btnTextCancel">Cancel</Typography>
                      </Button>
                    </Grid>
                   
                    <Grid item md={6} xs={12} className="padding10">
                      <Button data-testId='btnSubmit' className="btnWrapAdd" type="submit" onClick={checkSubmit}>
                        <Typography className="btnTextAdd">Save</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item md={4}/>
                </Grid>: <Grid container>
                  <Grid item md={3}/>
                  <Grid item container md={6} xs={12}>
                    <Grid item md={4} xs={12} className="padding10">
                      <Button className="btnWrapCancel" data-testId='btnCancel' onClick={handleCancel}>
                        <Typography className="btnTextCancel">Cancel</Typography>
                      </Button>
                    </Grid>
                    <Grid item md={4} xs={12} className="padding10">
                      
                      <Button data-testId='btnSaveAsDraft' className="btnWrapDraft" type="submit" onClick={saveAsDraft}>
                        <Typography className="btnTextCancel">Save As Draft</Typography>
                      </Button> 
                    </Grid>
                    <Grid item md={4} xs={12} className="padding10">
                      <Button data-testId='btnSubmit' className="btnWrapAdd" type="submit" onClick={checkSubmit}>
                        <Typography className="btnTextAdd">Submit</Typography>
                      </Button>
                      
                    </Grid>
                  </Grid>
                  <Grid item md={3}/>
                </Grid>}
              </form>
            )}
          </Formik>
          :<Formik 
            data-testId='formik'
            enableReinitialize
            initialValues={{
              guarantorId: isEditGuarantor? listAddedGuarantor[selectedGuarantorIndex]?.id : null,
              fullNameGuarantor: isEditGuarantor? listAddedGuarantor[selectedGuarantorIndex]?.fullNameGuarantor : '',
              dobGuarantor:isEditGuarantor?listAddedGuarantor[selectedGuarantorIndex]?.dobGuarantor : '',
              NRICGuarantor: isEditGuarantor?listAddedGuarantor[selectedGuarantorIndex]?.NRICGuarantor : '',
              addressGuarantor:isEditGuarantor? listAddedGuarantor[selectedGuarantorIndex]?.addressGuarantor : '',
              emailIDGuarantor: isEditGuarantor?listAddedGuarantor[selectedGuarantorIndex]?.emailIDGuarantor : '',
              contactNumberGuarantor: isEditGuarantor?listAddedGuarantor[selectedGuarantorIndex]?.contactNumberGuarantor : '',
              employerGuarantor: isEditGuarantor?listAddedGuarantor[selectedGuarantorIndex]?.employerGuarantor : '',
              jobTitleGuarantor: isEditGuarantor?listAddedGuarantor[selectedGuarantorIndex]?.jobTitleGuarantor : '',
              employmentAddressGuarantor: isEditGuarantor?listAddedGuarantor[selectedGuarantorIndex]?.employmentAddressGuarantor : '',
              grossMonthlySalaryGuarantor: isEditGuarantor?listAddedGuarantor[selectedGuarantorIndex]?.grossMonthlySalaryGuarantor : '',
            }} 
            validationSchema={userSchemaGuarantor}
            validateOnChange={formIsDirtyGuarantor}
            validateOnBlur={formIsDirtyGuarantor}
            onSubmit={(e:any,{ resetForm})=>{
              handleSaveGuarantor(e)
              resetForm()
            }}>
              {({errors, setFieldValue, handleSubmit, handleChange, values})=>(
              <form data-testId='form' onSubmit={handleSubmit} className="formikWrap">
                                <Grid container className='container'>
                  <Grid container className="wrapHeaderCustomerDetail">
                    <Typography className="sectionLabel">Guarantor Details</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Full Name</Typography>
                    <CustomSelectSearchField 
                        value={values.fullNameGuarantor} 
                        closeCalendar={closeCalendar}
                        listSearchCustomer={listSearchGuarantor} placeholder={'Select'} isFullName 
                        onChange={handleSearchGuarantor}
                        isError={errors.fullNameGuarantor}
                        onSelect={(e)=>{
                          setFieldValue('fullNameGuarantor', e.attributes.name);
                          setFieldValue('dobGuarantor',  handleMoment(e.attributes.date_of_birth, 'DD/MM/YYYY'));
                          setFieldValue('emailIDGuarantor', e.attributes.email);
                          setFieldValue('contactNumberGuarantor', e.attributes.contact_number);
                          }}/>
                      {errors.fullNameGuarantor && 
                        <Typography className="errorsMsg">{errors.fullNameGuarantor}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Date Of Birth</Typography>
                    <div className="customBtn">
                      <button type="button"
                      data-test-id="dobGuarantor"
                      className={values.dobGuarantor == "" ? "customButtonPLACEHOLDER":"customButton"}
                       onClick={toggleCalendarDOBGuarantor}> <Typography className="date">{values.dobGuarantor != "" ? moment(values.dobGuarantor).format('MM/DD/YYYY') : "mm/dd/yyyy"}</Typography><DateRangeIcon />    </button>

                    {
                      isOpenCalendarDOBGuarantor && <Calendar
                        className="customCalendarPop"
                        data-test-id="dobGuarantor"
                        onChange={(event: any) => {
                          setFieldValue("dobGuarantor", event);
                          toggleCalendarDOBGuarantor()
                          handleChange('dobGuarantor')
                        }}
                        value={values.dobGuarantor} />
                    }</div>
                      {errors.dobGuarantor && 
                        <Typography className="errorsMsg">{errors.dobGuarantor}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">NRIC number</Typography>
                    <TextField
                      data-testId='NRICGuarantor'
                      className={clsx({'errorInput': errors.NRICGuarantor, 'inputField': !errors.NRICGuarantor},)}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.NRICGuarantor}
                      onChange={handleChange('NRICGuarantor')}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                      {errors.NRICGuarantor && 
                        <Typography className="errorsMsg">{errors.NRICGuarantor}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Address</Typography>
                    <TextField
                      data-testId='addressGuarantor'
                      className={clsx({'errorInput': errors.addressGuarantor, 'inputField': !errors.addressGuarantor},)}
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.addressGuarantor}
                      onChange={handleChange('addressGuarantor')}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      fullWidth />
                      {errors.addressGuarantor && 
                        <Typography className="errorsMsg">{errors.addressGuarantor}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Email ID</Typography>
                    <TextField
                      data-testId='emailIDGuarantor'
                      className={clsx({'errorInput': errors.emailIDGuarantor, 'inputField': !errors.emailIDGuarantor},)}
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"}, }}
                      placeholder="Place holder"
                      value={values.emailIDGuarantor}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      onChange={handleChange('emailIDGuarantor')}
                      fullWidth />
                    {errors.emailIDGuarantor && 
                      <Typography className="errorsMsg">{errors.emailIDGuarantor}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Contact Number</Typography>
                    
                    <TextField
                      data-testId='contactNumberGuarantor'
                      className={clsx("inputField")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.contactNumberGuarantor}
                      onChange={(event) => {
                        const regex = /^[0-9\b]+$/;
                        if (event.target.value === "" || regex.test(event.target.value)) {
                          setFieldValue('contactNumberGuarantor', event.target.value);
                        }
                      }}
                      fullWidth />
                    {errors.contactNumberGuarantor && 
                        <Typography className="errorsMsg">{errors.contactNumberGuarantor}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Employer</Typography>
                    <TextField
                      data-testId='employerGuarantor'
                      className={clsx({'errorInput': errors.employerGuarantor, 'inputField': !errors.employerGuarantor},)}
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"}, }}
                      placeholder="Place holder"
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      value={values.employerGuarantor}
                      onChange={handleChange('employerGuarantor')}
                      fullWidth />
                    {errors.employerGuarantor && 
                      <Typography className="errorsMsg">{errors.employerGuarantor}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Job Title</Typography>
                    
                    <TextField
                      data-testId='jobTitleGuarantor'
                      className={clsx("inputField")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.jobTitleGuarantor}
                      onChange={handleChange('jobTitleGuarantor')}
                      fullWidth />
                    {errors.jobTitleGuarantor && 
                        <Typography className="errorsMsg">{errors.jobTitleGuarantor}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Employment Address</Typography>
                    <TextField
                      data-testId='employmentAddressGuarantor'
                      className={clsx({'errorInput': errors.employmentAddressGuarantor, 'inputField': !errors.employmentAddressGuarantor},)}
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"}, }}
                      placeholder="Place holder"
                      value={values.employmentAddressGuarantor}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      onChange={handleChange('employmentAddressGuarantor')}
                      fullWidth />
                    {errors.employmentAddressGuarantor && 
                      <Typography className="errorsMsg">{errors.employmentAddressGuarantor}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={6} className="padding10">
                    <Typography className="labelText">Gross Monthly Salary</Typography>
                    
                    <TextField
                      data-testId='grossMonthlySalaryGuarantor'
                      className={clsx({'errorInput': errors.grossMonthlySalaryGuarantor, 'inputField': !errors.grossMonthlySalaryGuarantor},)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onMouseDown={()=>
                        closeCalendar()
                      }
                      placeholder="Place holder"
                      InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                      value={values.grossMonthlySalaryGuarantor}
                      onChange={(event) => {
                        const regex = /^[0-9\b]+$/;
                        if (event.target.value === "" || regex.test(event.target.value)) {
                          setFieldValue('grossMonthlySalaryGuarantor', event.target.value);
                        }
                      }}
                      fullWidth />
                    {errors.grossMonthlySalaryGuarantor && 
                        <Typography className="errorsMsg">{errors.grossMonthlySalaryGuarantor}</Typography>}
                  </Grid>
                </Grid>

                <Grid container className='container'>
                  
                  <Grid container className="wrapHeaderCustomerDetail">
                    <Typography className="selectedBankLabel">Upload Supporting Documents</Typography>
                    <Box className="wrapDocuments">
                      
                        <Box className="wrapDocument">
                          <Typography className="documentTitle">CPF Contribution For Last 6 Months</Typography>
                          {listAddedGuarantor[selectedGuarantorIndex]?.cpfObject ?  <Box className="documentUploaded">
                          <Box className="uploadIconBox">
                            <img src={FileIcon} />
                          </Box>
                          <Box className="wrapTextUpload">
                            <Typography className="textUpload1">{listAddedGuarantor[selectedGuarantorIndex].cpfObject.name}</Typography>
                          </Box>
                          <HighlightOffRoundedIcon className="iconBank" onClick={()=>handleDeleteCPFGuarantor(selectedGuarantorIndex)}/> 
                        </Box>:<>
                          {CPFfileGuarantor ? <Box className="documentUploaded">
                            <Box className="uploadIconBox">
                              <img src={FileIcon} />
                            </Box>
                            <Box className="wrapTextUpload">
                              <Typography className="textUpload1">{CPFfileGuarantor?.name}</Typography>
                              <Typography className="textUpload2" display="inline">{CPFfileGuarantor?.size}KB</Typography>
                            </Box>
                            <HighlightOffRoundedIcon className="iconBank" onClick={()=>handleDeleteCPFGuarantor(selectedGuarantorIndex)}/> 
                          </Box> :<><input
                        data-testId='CPFupload'
                        id="CPFupload"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleUploadCPFGuarantor}/>
                      <label htmlFor="CPFupload"><Box className="documentUpload">
                            <AddIcon className="addIcon"/>
                            <Typography className='textUpload'>Upload</Typography>
                          </Box></label></> 
                          }   </>}
                        </Box>
                      
                        <Box className="wrapDocument">
                          <Typography className="documentTitle">NOA For Last 2 Years</Typography>
                          {listAddedGuarantor[selectedGuarantorIndex]?.noaObject ?  <Box className="documentUploaded">
                          <Box className="uploadIconBox">
                            <img src={FileIcon} />
                          </Box>
                          <Box className="wrapTextUpload">
                            <Typography className="textUpload1">{listAddedGuarantor[selectedGuarantorIndex].noaObject.name}</Typography>
                          </Box>
                          <HighlightOffRoundedIcon className="iconBank" onClick={()=>{handleDeleteNOAGuarantor(selectedGuarantorIndex)}}/> 
                        </Box> : 
                        <>
                          {NOAfileGuarantor ? <Box className="documentUploaded">
                            <Box className="uploadIconBox">
                              <img src={FileIcon} />
                            </Box>
                            <Box className="wrapTextUpload">
                              <Typography className="textUpload1">{NOAfileGuarantor?.name}</Typography>
                              <Typography className="textUpload2" display="inline">{NOAfileGuarantor?.size}KB</Typography>
                            </Box>
                            <HighlightOffRoundedIcon className="iconBank" onClick={()=>{handleDeleteNOAGuarantor(selectedGuarantorIndex)}}/> 
                          </Box> :<><input
                        data-testId='NOAfile'
                        id="NOAfile"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleUploadNOAGuarantor}/>
                      <label htmlFor="NOAfile"><Box className="documentUpload">
                            <AddIcon className="addIcon"/>
                            <Typography className='textUpload'>Upload</Typography>
                          </Box></label></> 
                          }</>}     
                        </Box>
                        <Box className="wrapDocument">
                          <Typography className="documentTitle">Upload Supporting Docs</Typography>
                          {listAddedGuarantor[selectedGuarantorIndex]?.supportObject ?  <Box className="documentUploaded">
                          <Box className="uploadIconBox">
                            <img src={FileIcon} />
                          </Box>
                          <Box className="wrapTextUpload">
                            <Typography className="textUpload1">{listAddedGuarantor[selectedGuarantorIndex].supportObject.name}</Typography>
                          </Box>
                          <HighlightOffRoundedIcon className="iconBank" onClick={()=>{handleDeleteSupportingGuarantor(selectedGuarantorIndex)}}/> 
                        </Box>:<>
                          {supportFileGuarantor ? <Box className="documentUploaded">
                            <Box className="uploadIconBox">
                              <img src={FileIcon} />
                            </Box>
                            <Box className="wrapTextUpload">
                              <Typography className="textUpload1">{supportFileGuarantor?.name}</Typography>
                              <Typography className="textUpload2" display="inline">{supportFileGuarantor?.size}KB</Typography>
                            </Box>
                            <HighlightOffRoundedIcon className="iconBank" onClick={()=>{handleDeleteSupportingGuarantor(selectedGuarantorIndex)}}/> 
                          </Box> : 
                          <>
                            <input
                              data-testId='supportFile'
                              id="supportFile"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={handleUploadSupportingGuarantor}/>
                            <label htmlFor="supportFile">
                              <Box className="documentUpload">
                              <AddIcon className="addIcon"/>
                              <Typography className='textUpload'>Upload</Typography>
                              </Box> 
                            </label>
                          </>
                          }</>}
                        </Box>
                      
                    </Box>
                  </Grid>
                </Grid>
                
                <Grid container>
                  <Grid item md={4}/>
                  <Grid item container md={4} xs={12}>
                    <Grid item md={6} xs={12} className="padding10">
                      <Button className="btnWrapCancel" data-testId='btnCancel' onClick={handleCancelGuarantor}>
                        <Typography className="btnTextCancel">Cancel</Typography>
                      </Button>
                    </Grid>
                    
                    <Grid item md={6} xs={12} className="padding10">
                      <Button data-testId='btnSave' data-test-id='btnSave' className="btnWrapAdd" type="submit" onClick={checkSubmitGuarantor}>
                        <Typography className="btnTextAdd">Save</Typography>
                      </Button>
                      
                    </Grid>
                  </Grid>
                  <Grid item md={4}/>
                </Grid>
              </form>
            )}
        </Formik>}
      </NewSalesAndContractStyle>
    </>
    // Customizable Area End
  );
};

// Customizable Area Start
const ModalStyle = styled(Box)(({ theme }) => ({
  '& .wrapModal': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    backgroundColor:(theme as any).typography.body2, 
    borderRadius: "16px",
    minWidth:'40%'
  },
  '& .iconLoading': {
    color:'#CEAE71', 
    alignSelf:'center'
  },
  '& .line': {
    height:"1px", 
    backgroundColor:"#FFF6DC30",
  },
  '& .wrapModalContent': {
    padding: '40px'
  },
  '& .wrapModalContentLoading': {
    justifyContent: 'center',
    padding: '40px',
    alignItems:'center',
    width:'100%',
    display:'flex'
  },
  '& .modalHeaderWrap': {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "24px"  
  },
  
  '& .modalFooterWrap': {
    display: "flex",
    justifyContent: "flex-end",
    padding: "24px"   
  },
  '& .modalHeaderText': {
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize:"24px",
    fontWeight: 700,
  },
  '& .modalContentWrap': {
    paddingTop: "40px",
    paddingBottom: "40px",
    paddingLeft: "100px",
    paddingRight: "100px",
  },
  
  '& .cancelBtn': {
    backgroundColor:(theme as any).typography.surface2, 
    borderRadius: "8px",
    color: "#CEAE71",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
    height: "56px"
  },
  "& .deleteMsg":{
    color: '#F87171', 
    fontSize: 16, 
    fontFamily: 'Outfit', 
    fontWeight: '400',
  },
  '& .saveBtn': {
    marginLeft: "16px",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    borderRadius: "8px",
    color: "#171717",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
    height: "56px"
  },
}));
const NewSalesAndContractStyle  = styled(Box)(({ theme }) => ({
  "& .uploadIconBox":{
    display: "flex",
    padding: "var(--border-radius, 16px)",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "8px", 
    background: "#FFEFBD",
  },
  "& .documentUploaded":{
    borderRadius: 8,
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-between',
    width:230,
    height:72,
    backgroundColor:(theme as any).typography.surface2, 
    padding:'0 8px'
  },
  "& .wrapTextUpload":{
    width:110,
  },
  "& .textUpload":{
    color: (theme as any).typography.h1, 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '400'
  },
  "& .textUpload1":{
    color: (theme as any).typography.h1, 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '400',
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis'
  },
  "& .textUpload2":{
    color: (theme as any).typography.h3, 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '400',
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis'
  },
  '& .btnTextAdd': {
    textTransform: 'none',
    color: '#171717', 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  }, 
  "& .btnWrapAdd":{
    width: '100%',
    cursor: 'pointer',
    padding: "16px 0 16px 0", 
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',    
  },
  '& .btnTextCancel': {
    textTransform: 'none',
    color: '#CEAE71', 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  "& .btnWrapCancel":{
    cursor: 'pointer',
    padding: "16px 0 16px 0", 
    borderRadius: '8px', 
    width: '100%',
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',    
  },
  "& .btnWrapDraft":{
    cursor: 'pointer',
    padding: "16px 0 16px 0", 
    background: (theme as any).typography.surface2, 
    borderRadius: '8px', 
    width: '100%',
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',    
  },
  '& .MuiInputBase-input::placeholder': {
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    color: (theme as any).typography.h3, 
  },
  "& .errorsMsg":{
    color: "#F87171",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  '& .MuiInputBase-input': {
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    color: (theme as any).typography.h1, 
  },
  '& .padding30':{
    padding: "30px",
  },
  '& .padding30xs':{
    width: 'fit-content',
    padding: "30px",
  },
  '& .container': {
    flex:1, 
    alignContent:'start',
  },
  '& .formikWrap': {
    height:"100%", 
    display:'flex', 
    flexDirection:'column',
    padding: 20 
  },
  '& .padding10':{
    padding: "10px",
  },
  '& .wrapHeaderCustomerDetail':{
    padding: "10px",
    display:'flex',
    flexDirection:'column',
  }, 
  '& .retrieveBtn':{
    fontFamily:'Inter',
    fontWeight:700,
    fontSize:18,
    color: (theme as any).typography.h2, 
  },  
  '& .inputField': {
    marginTop: '4px',
    borderRadius: 8,
    border: `1px ${(theme as any).typography.border} solid`,
    background: (theme as any).typography.body2, 
    padding: '10px 8px',
    color: (theme as any).typography.h3, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    width: '100% !important'
  },
  '& .labelText': {
    color: (theme as any).typography.h1, 
    fontSize: '14px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  '& .selectedBankLabel': {
    color: (theme as any).typography.h1, 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
    marginBottom:16
  },
  '& .wrapSelectedBank': {
    display:'flex', 
    flexDirection:'row',
    flexWrap:'wrap'
  },
  '& .wrapSelectedBankItem': {
    display:'flex', 
    justifyContent:'center', 
    alignItems:'center', 
    padding: 10, 
    border:`1px ${(theme as any).typography.border} solid`,
    borderRadius:16, 
    marginRight:24,
    marginBottom:10,
    backgroundColor:(theme as any).typography.body2, 
  },
  '& .wrapSelectedBankText': {
    color: (theme as any).typography.h1, 
    fontSize: '20px', 
    fontFamily: 'Outfit', 
    fontWeight: '400',
    marginRight:10
  },
  '& .errorInput': {
    marginTop: '4px',
    borderRadius: 8,
    background: (theme as any).typography.body2, 
    padding: '10px 8px',
    color: (theme as any).typography.h3, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    border: "1px solid #F87171",
  },
  '& .sectionLabel': {
    color: (theme as any).typography.h1, 
    fontSize: 24, 
    fontFamily: 'Inter', 
    fontWeight: '700',
    marginBottom:'20px'
  },
  '& .guarantorItem': {
    color: (theme as any).typography.h1, 
    fontSize: 20, 
    fontFamily: 'Outfit', 
    fontWeight: '400',
    width:100,
    wordBreak:'break-all'
  },
  '& .guarantorItemIcon': {
    color: '#B08955', 
    fontSize: 16, 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  '& .guarantorItemWrap': {
    backgroundColor: (theme as any).typography.body2, 
    padding:16,
    borderRadius: 8,
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    marginBottom:10
  },
  '& .guarantorIconWrap': {
    cursor:'pointer',
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    gap:6
  },
  '& .guarantorIconGroupWrap': {
    display:'flex',
    flexDirection:'row',
    flexWrap:'wrap',
    justifyContent:'flex-end',
    alignItems:'center',
    gap:15
  },
  "& .btnWrap":{
    cursor: 'pointer',
    paddingTop: "10px", 
    paddingBottom: "10px", 
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',
    height: 56,
    width: 150,
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  "& .btnTextApply":{
    color: '#171717', 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '700'
  },
  "& .iconBank":{
    cursor:'pointer',
    color: (theme as any).typography.h3, 
  },
  "& .addIcon":{
    color: (theme as any).typography.h1, 
  },
  "& .iconGuarantor":{
    color: '#B08955', 
  },
  "& .wrapDocuments":{
    display:'flex',
    flexWrap:'wrap',
  },
  "& .wrapDocument":{
    marginRight: 20,
    marginBottom:10
  },
  "& .documentTitle":{
    color: (theme as any).typography.h1, 
    fontSize: "14px", 
    fontFamily: 'Outfit', 
    fontWeight: '400',
    marginBottom:8
  },
  "& .documentUpload":{
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23FFF6DCFF' stroke-width='2' stroke-dasharray='30%2c20' stroke-dashoffset='31' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: 8,
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    width:230,
    height:72,
    backgroundColor:(theme as any).typography.surface2, 
  },
  '& .customCalendarPop': {
    border: `1px solid ${(theme as any).typography.border}`,
    background: (theme as any).typography.body2,
    marginTop: '1rem',
    color: (theme as any).typography.h1,
    // Add any other custom styles as needed
    display: 'inline-flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    borderRadius: '8px',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    position: "absolute",
    right: "1rem",
    zIndex: 4,
  },

  '& .customBtn':{
    display:'relative'
  },
  '& .customButton': {
    display: 'inline-flex',
    height: '3.4rem',
    width: "100%",
    marginTop: ".3rem",
    padding: '6px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '6px',
    flexShrink: 0,
    borderRadius: '8px',
    border: `1px solid ${(theme as any).typography.border}`,
    background: (theme as any).typography.body2,
    position: 'relative',
    color: (theme as any).typography.h2 ,
    zIndex: 1, 
  },
  '& .customButtonError': {
    display: 'inline-flex',
    height: '3.4rem',
    width: "100%",
    marginTop: ".3rem",
    padding: '6px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '6px',
    flexShrink: 0,
    borderRadius: '8px',
    border: "1px solid #F87171",
    background: (theme as any).typography.body2,
    position: 'relative',
    color: (theme as any).typography.h2 ,
    zIndex: 1, 
  },
  '@media (max-width: 480px)': {


    '& .customCalendarPop': {
        padding: 0,
        fontSize: 12,
    },

    "& .rangeCalendar ": {
        top: "125%",


        left: "-12.3rem",
        width: "270px",
        position: "absolute",
    },
    // Add more styles as needed for small screens
},

  '& .react-calendar__tile--now': {
    background: (theme as any).typography.body1,
    opacity: 9,
  },

  '& .customButtonPLACEHOLDER': {
    display: 'inline-flex',
    height: '3.4rem',
    width: "100%",
    marginTop: ".3rem",
    padding: '6px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '6px',
    flexShrink: 0,
    borderRadius: '8px',
    border: `1px solid ${(theme as any).typography.border}`,
    background: (theme as any).typography.body2,
    position: 'relative',
    color: "gray" ,
    zIndex: 1, 
  },
  '& .date': {
    fontFamily: "Outfit",
    fontSize: 16,
    color: (theme as any).typography.h2,
    fontWeight:400
  },


  '& .react-calendar__tile': {
  },
  '& .react-calendar__month-view__weekdays__weekday': {
    color: (theme as any).typography.h1,
  },
  '& .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus': {
    background: (theme as any).typography.body1,
    color: `${(theme as any).typography.h1} !important`,
  },
  "& .react-calendar__navigation__next2-button": {
    display: "none"
  },
  '& .react-calendar__tile--hover': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%)) !important',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important"
    }
  },
  '& .react-calendar__tile react-calendar__tile--active react-calendar__year-view__months__month': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important",
    },
    borderRadius: '0', 
    '&:first-of-type': {
      borderTopLeftRadius: '35px',
      borderBottomLeftRadius: '35px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '35px',
      borderBottomRightRadius: '35px',
    },
  },
  
  '& .react-calendar__tile--active': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important",
    },
    borderRadius: '0', 
    '&:first-of-type': {
      borderTopLeftRadius: '35px',
      borderBottomLeftRadius: '35px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '35px',
      borderBottomRightRadius: '35px',
    },
  },
  '& .react-calendar__tile--hasActive': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important",
    },
    borderRadius: '0', 
    '&:first-of-type': {
      borderTopLeftRadius: '35px',
      borderBottomLeftRadius: '35px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '35px',
      borderBottomRightRadius: '35px',
    },
  },
  '& .react-calendar, .react-calendar *, .react-calendar ::before, .react-calendar ::after': {
    boxSizing: 'border-box',
    color: (theme as any).typography.h1,
  },
  '& .react-calendar__navigation__prev2-button  ': { display: "none" },
  '& .react-calendar__navigation button:enabled:focus': {
    background: (theme as any).typography.body1,
  },
  '& .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus': {
    background: (theme as any).typography.body1,
  },
  '& .react-calendar__navigation': {
    display: 'flex',
    height: '44px',
    marginBottom: '1em',
    width: '100%',
    color: (theme as any).typography.h1,
  },
}))

// Customizable Area End
export default NewSalesAndContractView

withWidth()(NewSalesAndContractView);
