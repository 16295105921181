import { Box, ListItemIcon, ListItemText, MenuItem, Select, Typography, makeStyles, styled } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import clsx from 'clsx';
import { Check } from "@material-ui/icons";

interface Props {
  isError: any;
  value: any;
  onSelectServices: any;
  setFieldValue: any;
  closeCalendar?: any
}

export const services = ['buying', 'selling', 'insurance']

const CustomSelectService = ({isError, value, setFieldValue, onSelectServices,closeCalendar }: Props) => {
  const classes = useStyles();
  return <CustomSelectStyle>
    <Select
      data-test-id='services'
      className={clsx({'errorInput': isError, 'inputField': !isError},)}
      placeholder="Select the service you are interested in"
      disableUnderline                      
      displayEmpty
      value={value}
      onChange={(event: any)=>{
        setFieldValue("services", typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)
        onSelectServices(event);
      }}
      multiple
      fullWidth
      renderValue={
        ()=><Typography className="searchText">{value.length > 0 ? value.map((string: string) => string.charAt(0).toUpperCase() + string.slice(1)).join(', ') :'Select the service you are interested in'}</Typography>
      }
      onMouseDown={()=>{
        closeCalendar && closeCalendar();
      }}
      MenuProps={{
        style: {
          marginTop: '20px',
          marginLeft: '-10px',
        },                        
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        PaperProps: {
          style: {
            backgroundColor:'#3F3E3A',
            borderRadius:'8px',
            padding: '8px'
          }
        },
        getContentAnchorEl: null
      }}
    >
      {services.map((item)=>
      <MenuItem key={item} value={item}>
        <ListItemIcon>
          {value.indexOf(item) > -1 ? <Box style={{width:24, height:24,borderRadius: 6, backgroundColor: '#BF9B63'}}>
            <Check style={{color:"#f3f3f3"}}/>
          </Box> : <ListItemIcon>
            <Box style={{width:24, height:24,borderRadius: 6, border: '1px solid #bf9b63'} }/>
          </ListItemIcon>}                          
        </ListItemIcon>
        <ListItemText>
          <Typography style={{color: '#F3F3F3', fontSize: 16, fontFamily: 'Outfit', fontWeight: 400}}>{item.charAt(0).toUpperCase() + item.slice(1)}</Typography>
        </ListItemText> 
      </MenuItem>)}                  
    </Select>
  </CustomSelectStyle>
}

const useStyles = makeStyles({
  select: {
    '& .textSelect': {
      color: '#F3F3F3', 
      fontSize: '14px', 
      fontFamily: 'Outfit', 
      fontWeight: '400',
    },
    
    '& .wrapText': {
      cursor: 'pointer',
      padding: '8px'
    },
  },
});

const CustomSelectStyle = styled(Box)(({ theme }) => ({
  '& .inputFieldServices': {
    marginTop: '4px',
    borderRadius: 8,
    border: `1px ${(theme as any).typography.border} solid`,
    background: (theme as any).typography.body2, 
    padding: '10px 8px',
    color: (theme as any).typography.h3, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    height:"auto",
  },
  '& .searchText': {
    color: (theme as any).typography.h1, 
    fontSize: 14, 
    fontFamily: 'Outfit', 
    fontWeight: '400',
  },
  '& .iconX': {
    cursor: 'pointer',
    color: (theme as any).typography.h1, 
  },
  '& .selectedService': {
    marginTop:'16px',
    display: 'flex',
    flexDirection:'column'
  },
}))
export default CustomSelectService;