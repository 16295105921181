import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { Message } from "framework/src/Message";
import React from "react";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import MusicNoteIcon from '@material-ui/icons/MusicNote';

const pageid = "205301136008162";
const longString = "EAAnW2X7zoOwBOzmiZA6n5fJDxzIjgeeyZBAnI9a3690wPP0NycaRDXZApx5AkI9zBVQJbJ4FLIL6ZCxvIRbWKHk7TR5vfDEuMfSbczQslfH7ZBQhKXHGQF1PO8bo3TZC1iSbwgJY2pmhWcG0hNgqEhXjlBDfPxN3A65lsS2nwLLUqyk92fUDuzubj6x5T4ypPLF3Sg3ZBAZD"
const insta_id = "17841464943600812" ;

 
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  marketingDetails: any,
  platform: any,
  imagesFacebook: any,
  imagesInstagram: any,
  videosFacebook: any,
  videosInstagram: any,
  facebookMediaIds: any,
  instagramListIds: any,
  is_posted_on_facebook: string| null;
  is_posted_on_instagram: string| null;
  modalLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MarketingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  getMediaDetailId: any;
  getMediaIdFacebookRetry: any;
  createFacebookPostIdRetry: any;
  editPostId: any;
  createInstagramMediaContainerRetryId: any;
  createItemContainerInstagramRetryId: any;
  createCarouselContainerInstagramRetryId: any;
  publishInstagramMediaContainerRetryId: any;
  createInstagramMediaContainerVideoId: any;
  checkInstagramMediaContainerVideoStatusId: any;
  createVideoPostFacebookId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      marketingDetails: {},
      platform: [
        {
          type: "Instagram",
          icon: <InstagramIcon />,
          isShow: null
        },
        {
          type: "Facebook",
          icon: <FacebookIcon />,
          isShow: null
        },
        {
          type: "Tik-Tok",
          icon: <MusicNoteIcon />,
          isShow: null
        }
      ],
      facebookMediaIds: [],
      instagramListIds:[],
      imagesFacebook: [],
      imagesInstagram: [],
      videosFacebook: [],
      videosInstagram: [],
      is_posted_on_facebook: null,
      is_posted_on_instagram: null,
      modalLoading:false,
      // Customizable Area End,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (!apiRequestCallId || !responseJson) {
      return;
    }

    switch (apiRequestCallId) {
      case this.getMediaDetailId:
        this.getMediaDetailsReciever(responseJson);
        break;
      case this.getMediaIdFacebookRetry:
        this.getMediaIdFacebookRetryReceiver(responseJson);
          break;
      case this.createFacebookPostIdRetry:
        this.createFacebookPostIdRetryReceiver(responseJson);
        break;
      case this.editPostId:
        this.getMediaPostDetail(this.props.navigation.getParam('postid'))
        break;
      case this.createInstagramMediaContainerRetryId:
        this.publishInstagramMediaRetryContainer(responseJson.id);
        break;
      case this.publishInstagramMediaContainerRetryId:
        this.publishInstagramMediaContainerRetryReceiver(responseJson);
        break;
      case this.createItemContainerInstagramRetryId: 
        this.createItemContainerInstagramRetryReceiver(responseJson)
        break;
      case this.createCarouselContainerInstagramRetryId:
        this.publishInstagramMediaRetryContainer(responseJson.id);
        break;
      case this.createInstagramMediaContainerVideoId:
        this.checkInstagramMediaContainerVideoStatus("IN_PROGRESS", responseJson.id)
        break;
      case this.checkInstagramMediaContainerVideoStatusId:
        setTimeout(() =>{
          this.checkInstagramMediaContainerVideoStatus(responseJson.status_code, responseJson.id)
        }, 10000);
        break;
      case this.createVideoPostFacebookId:
        this.createVideoPostFacebookReceiver();
        break;
      default:
        break;
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getMediaPostDetail(this.props.navigation.getParam('postid'))
  }

  getMediaDetailsReciever = (responseJson: any) => {
    let typesToShow = responseJson?.data?.attributes?.platform;
 
    const object :any= {
      is_posted_on_facebook: responseJson.data.attributes.is_posted_on_facebook,
      is_posted_on_instagram: responseJson.data.attributes.is_posted_on_instagram,
      is_posted_on_tiktok: responseJson.data.attributes.is_posted_on_tictok
    };
    const updatedPlatform = this.state.platform.map((item: any) => {
      const key = `is_posted_on_${item.type.toLowerCase()}`;
      return {
        ...item,
        isShow: typesToShow.join().includes(item.type),
        isPostedSuccesully: object[key],
      }
    }
    );
    
    this.setState({
      marketingDetails: responseJson.data,
      platform: updatedPlatform,
      imagesFacebook: responseJson.data.attributes.images,
      imagesInstagram: responseJson.data.attributes.images,
      videosFacebook: responseJson.data.attributes.videos,
      videosInstagram: responseJson.data.attributes.videos,
      modalLoading:false
    })
  }

  getMediaPostDetail = async (id: any) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSocialMediaEndpoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getMediaDetailId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRetry = (data: any) => {
    this.setState({modalLoading:true})
    switch (data.type) {
      case("Facebook"):
        this.handlePostFacebook();
        break;
      case("Instagram"):
        this.handlePostInstagram();
        break;
      case("Tiktok"):
      break;
    }
  }

  handlePostInstagram = ()=>{
    if (((this.state.videosInstagram?.length ?? 0) + (this.state.imagesInstagram?.length ?? 0)) === 1){
      if(this.state.videosInstagram?.length === 1) {
        this.createSingleVideoPostInstagram();
      } else {
        this.createSingleImagePostInstagramRetry()
      }
    } else {
      this.createMultipleImagePostInstagramRetry(this.state.imagesInstagram[0].url)
    }
  }

  handlePostFacebook = ()=>{
      if((this.state.imagesFacebook?.length ?? 0) > 0 ){
        this.getMediaIdRetry(this.state.imagesFacebook[0])
      } else {
        this.createVideoPostFacebook(this.state.videosFacebook[0].url)
      }
  }

  createVideoPostFacebook = async (url: any)=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      file_url: url,
      access_token: longString,
      description:`${this.state.marketingDetails.attributes.post} ${this.state.marketingDetails.attributes.hashtag ?? ""}`
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/${pageid}/videos`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createVideoPostFacebookId = requestMessage.messageId; 
    runEngine.sendMessage(requestMessage.id, requestMessage) ;
  }

  createVideoPostFacebookReceiver = () => {
    let temp =[...this.state.videosFacebook];
    temp.shift()
    this.setState(prevState => ({
      videosFacebook: temp
    }), () => {
      if(this.state.videosFacebook.length > 0) {
        this.createVideoPostFacebook(this.state.videosFacebook[0].url)
      } else {
        this.setState({
          is_posted_on_facebook: "true" }, ()=>{
            this.ediPost();
          }
        )
      }
    });
  };

  getMediaIdRetry = async (url:any) => {
    const formData = new FormData();
    formData.append('url', url.url)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `https://graph.facebook.com/${pageid}/photos?access_token=${longString}&published=false`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
       formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    this.getMediaIdFacebookRetry = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getMediaIdFacebookRetryReceiver = (response: any) => {
    let temparr = [...this.state.imagesFacebook]
    temparr.shift()
    this.setState(prevState => ({
      facebookMediaIds: [...prevState.facebookMediaIds, response.id] ,
      imagesFacebook: temparr
    }), () => {
      if(this.state.imagesFacebook.length > 0) {
        this.getMediaIdRetry(this.state.imagesFacebook[0])
      } else {
        this.postToFacebook(this.state.facebookMediaIds)
      }
    });
  };

  postToFacebook = async (facebookMediaIds: string[]) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    const body = {
      message: `${this.state.marketingDetails.attributes.post} ${this.state.marketingDetails.attributes.hashtag ?? ""}`,
      attached_media: facebookMediaIds.map(idmedia => ({ media_fbid: idmedia })),
    };
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type': 'application/json',
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `https://graph.facebook.com/${pageid}/feed?access_token=${longString}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
  
    this.createFacebookPostIdRetry = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createFacebookPostIdRetryReceiver = (response: any) => {
    if (response && response.id) {
      this.setState({is_posted_on_facebook : "true" } )
    } else {
      this.setState({is_posted_on_facebook : "false" } )
    }
    this.ediPost();
  };

  createSingleImagePostInstagramRetry = () => {
    this.createInstagramMediaContainerRetry(this.state.imagesInstagram[0].url, `${this.state.marketingDetails.attributes.post} ${this.state.marketingDetails.attributes.hashtag ?? ""}`)
  };

  createSingleVideoPostInstagram = () => {
    this.createInstagramMediaContainerVideoRetry(this.state.videosInstagram[0].url, `${this.state.marketingDetails.attributes.post} ${this.state.marketingDetails.attributes.hashtag ?? ""}`)
  };

  createMultipleImagePostInstagramRetry =(url: string)=>{
    this.createItemContainerInstagramRetry(this.state.imagesInstagram[0].url)
  }

  createInstagramMediaContainerVideoRetry = (videoUrl: string, caption: string) => {    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      video_url: videoUrl,
      caption: caption,
      access_token: longString,
      media_type: "REELS"
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${insta_id}/media`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createInstagramMediaContainerVideoId = requestMessage.messageId; 
    runEngine.sendMessage(requestMessage.id, requestMessage) ;
  };

  checkInstagramMediaContainerVideoStatus = (status: string, containerId: string) => {    
    switch (status) {
      case "FINISHED":
        this.publishInstagramMediaRetryContainer(containerId)
        break;
      case "IN_PROGRESS":
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
            'Content-Type' : 'application/json',
          })
        ) ;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage), 
          `https://graph.facebook.com/v20.0/${containerId}/?fields=status_code&access_token=${longString}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'GET'
        );
        this.checkInstagramMediaContainerVideoStatusId = requestMessage.messageId; 
        runEngine.sendMessage(requestMessage.id, requestMessage) ;
        break;
    
      default:
        break;
    }
  };

  createInstagramMediaContainerRetry = (imageUrl: string, caption: string) => {    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    );
    const body = {
      image_url: imageUrl,
      caption: caption,
      access_token: longString,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${insta_id}/media`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createInstagramMediaContainerRetryId = requestMessage.messageId; 
    runEngine.sendMessage(requestMessage.id, requestMessage) ;
  };

  createItemContainerInstagramRetry = (url: string)=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    );
    const body = {
      image_url: url,
      is_carousel_item: true,
      access_token: longString,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${insta_id}/media`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createItemContainerInstagramRetryId = requestMessage.messageId; 
    runEngine.sendMessage(requestMessage.id, requestMessage) ;
  }

  createItemContainerInstagramRetryReceiver = (response: any)=>{
    let temparr =[...this.state.imagesInstagram]
    temparr.shift()
    this.setState(prevState => ({
      instagramListIds: [...prevState.instagramListIds, response.id],
      imagesInstagram: temparr
    }), () => {
      if(this.state.imagesInstagram.length > 0) {
        this.createItemContainerInstagramRetry(this.state.imagesInstagram[0].url)
      } else {
        this.createCarouselContainerInstagramRetry(this.state.instagramListIds)
      }
    });
  }

  createCarouselContainerInstagramRetry = (listIds: any)=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    );
    const body = {
      media_type: "CAROUSEL",
      caption: `${this.state.marketingDetails.attributes.post} ${this.state.marketingDetails.attributes.hashtag ?? ""}`,
      children:listIds && listIds.join(','),
      access_token: longString,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${insta_id}/media`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createCarouselContainerInstagramRetryId = requestMessage.messageId; 
    runEngine.sendMessage(requestMessage.id, requestMessage) ;
  } 

  publishInstagramMediaRetryContainer = (creationId: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    );
    const body = {
      creation_id : creationId,
      access_token: longString,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${insta_id}/media_publish`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.publishInstagramMediaContainerRetryId = requestMessage.messageId; 
    runEngine.sendMessage(requestMessage.id, requestMessage) ;
  }

  publishInstagramMediaContainerRetryReceiver = (response: any)=>{
    if(response.error){
      this.setState({is_posted_on_instagram: "false"},  
      ()=>{
        this.ediPost();
      })
    } else {
      this.setState({is_posted_on_instagram: "true"},  
      ()=>{
        this.ediPost();
      })
    }
  }

  ediPost = async () => {
    const tokenValue = await getStorageData("authToken")
    const headers = {
      token: tokenValue,
    };     
    const formdata = new FormData();
    this.state.is_posted_on_facebook && formdata.append("is_posted_on_facebook", this.state.is_posted_on_facebook);
    this.state.is_posted_on_instagram && formdata.append("is_posted_on_instagram", this.state.is_posted_on_instagram);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSocialMediaEndpoint}/${this.props.navigation.getParam('postid')}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutEampleMethod
    );
    this.editPostId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}