import React from "react";
// Customizable Area Start
import { Box, Button, Grid, IconButton, Modal, TextField, Typography, withWidth } from "@material-ui/core";
import { createTheme,styled } from "@material-ui/core/styles";
import clsx from 'clsx';
import * as Yup from "yup";
// Customizable Area End

import CatalogueDetailController, { Props } from "./CatalogueDetailController.web";
import { ChevronLeft, ChevronRight, Edit as EditIcon, Close } from "@material-ui/icons";
import { Formik } from "formik";

const theme = createTheme({
  palette: {
    primary: {
      main: "#171717",
      contrastText: "#fff",
    },
  },
});

class CatalogueDetail extends CatalogueDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userSchema = () => {
    return Yup.object().shape({
      price: Yup.string().required("Please enter numbers only"),
    });
  };

  renderModal = () => {
    return <Modal open={this.state.openModal}>
      <ModalStyle>
        <Box className="wrapModal">
          <Box className="modalHeaderWrap">
            <Typography className="modalHeaderText">Edit Vehicle Price</Typography>
            <IconButton onClick={this.handleModalPrice} className="iconPadding">
              <Close className="icon"/>
            </IconButton>
          </Box>
          <Box className="line"/> 
          <Formik 
            validationSchema={this.userSchema}
            initialValues={{
              price: this.state.price,
            }}
            onSubmit={(values) => {
              this.handleSavePrice(values);
            }}>
              {({errors, setFieldValue, handleSubmit }) => {
                return <form onSubmit={handleSubmit} noValidate autoComplete="off">                  
                  <Box className="modalContentWrap">
                    <Typography className="labelText">Vehicle Price</Typography>
                      <TextField
                        data-test-id='price'
                        className={clsx({'width369': this.props.width !== 'xs', 'errorInput': errors.price, 'inputField': !errors.price},)}
                        placeholder="Placeholder"
                        InputProps={{disableUnderline: true}}
                        value={this.state.price}
                        onChange={(event) => {
                          this.onValueChange("price", event.target.value);
                          setFieldValue('price', event.target.value); 
                        }}
                        onKeyDown={(event: any) => this.handleNumericInput(event, { allowDecimal: true, maxDecimalPlaces: 2, maxValue : 9999999999.99 })}
                        fullWidth />
                        {errors.price && 
                          <Typography className="errorsMsg">{errors.price}</Typography>}
                  </Box>
                  <Box className="line"/> 
                  <Box className="modalFooterWrap">
                    <Button data-test-id='cancalBtn' className="cancelBtn" onClick={this.handleModalPrice}>Cancel</Button>
                    <Button type="submit" data-test-id='saveBtn' className="saveBtn">Save</Button>
                  </Box>                
                </form>
              }}
            </Formik>
        </Box> 
      </ModalStyle>
    </Modal>
  }

  renderListImage = () => {
    return <ImageStyle>
      <Grid item container md={4}>
        {(this.props.width !== "xs" && this.props.width !== "sm") ?
          <Box className="wrapSection">
            <Box data-test-id="btnLeft" className={clsx('iconLeftWrap', {'iconPressable':this.state.firstImageIndex !== 0})} onClick={this.handleClickLeft}>
              <ChevronLeft  className="icon"/>
            </Box>

            <Box className="listImageWrap">
              {this.state.listShowedImages?.map((item, index) => <Box data-test-id="imageItem" className="imageItem" onClick={() => this.handleClickImage(index)}>
                  <img className="image" src={item}/>
                </Box>
              )}
            </Box>
            
            <Box data-test-id="btnRight" className={clsx('iconRightWrap', {'iconPressable': this.state.listImages?.length > 4 && this.state.lastImageIndex !== this.state.listImages.length - 1})} onClick={this.handleClickRight}>
              <ChevronRight className="icon"/>
            </Box>
          </Box> :
          <Box className="wrapSection">
            <Box data-test-id="btnLeft" className={clsx('iconLeftWrap', {'iconPressable':this.state.firstImageIndexSmall !== 0})} onClick={this.handleClickLeftSmall}>
              <ChevronLeft  className="icon"/>
            </Box>

            <Box className="listImageWrap">
              {this.state.listShowedImagesSmallScreen.map((item, index) => <Box data-test-id="imageItem" className="imageItem" onClick={() => this.handleClickImageSmall(index)}>
                  <img className="image" src={item}/>
                </Box>
              )}
            </Box>
          
            <Box data-test-id="btnRight" className={clsx('iconRightWrap', {'iconPressable': this.state.listImages?.length > 2 && this.state.lastImageIndexSmall !== this.state.listImages.length - 1})} onClick={this.handleClickRightSmall}>
              <ChevronRight  className="icon"/>
            </Box>
          </Box>}
      </Grid>
    </ImageStyle>
  }
  // Customizable Area End

  render() {
    const {width} = this.props;
    return (
        // Customizable Area Start
        // Required for all blocks
      <>
        {this.renderModal()}
        <CatalogueDetailStyle>
          <Box className={clsx({"containerBox": width!=='xs', 'containerBoxxs': width == 'xs'})}>
            <Grid container>
              <Grid item xs={12} md={10} className="wrapHeader">
                <Box className="wrapTitle">
                  <Box>
                    <Typography className="title">{this.state.attribute?.name}</Typography>
                  </Box>
                  <Box className="statusWrap">
                    <Typography className="statusText">{this.state.attribute?.status.toUpperCase()}</Typography>
                  </Box>
                </Box>
                <Typography className="textId">ID : {this.state.vehicleId}</Typography>
                <Box className="tab">
                  <Box className="activeTab">
                    <Typography className="activeText">Overview</Typography>
                  </Box>
                  <Box className="gap"/>
                  <Box data-test-id="financialBtn" className="tabTextWrap" onClick={this.handleNavigateFinancial}>
                    <Typography className="tabText">Financial</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs md className="priceWrap">
                <Box className="priceHeader">
                  <Typography className="priceHeaderText">Price</Typography>
                  {(this.state.attribute?.deal_status === 'pending_approval' && this.state.userType === 'sales_manager' || this.state.attribute?.is_added_in_deal === false) && (
                    <IconButton data-test-id="changePriceBtn" onClick={this.handleModalPrice} className="iconPadding">
                      <EditIcon className="icon"/>
                    </IconButton>
                  )}
                </Box>
                <Typography className="priceValue">{`$${Number(this.state.initPrice).toLocaleString()}`}</Typography>
              </Grid>
            </Grid>
            

            <Grid item container className="contentWrap">
              <Grid item container className="wrapSpec">
                <Grid item className="imageSetionWrap" xs={12} md>          
                  <img className="imageSelected" src={this.state.selectedImage}/>                  
                  {this.renderListImage()}
                </Grid>
                <Grid item container className="techSpecWrap" md>
                  <Grid xs={12} md item className="wrapCol">
                    <Grid item className="wrapSpecDetail">
                      <Typography className="specTitleText">Depreciation</Typography>
                      <Typography className="specDetailText">{this.state.attribute?.depreciation}</Typography>
                    </Grid>
                    <Grid item className="wrapSpecDetail">
                      <Typography className="specTitleText">Milage</Typography>
                      <Typography className="specDetailText">{this.state.attribute?.mileage}</Typography>
                    </Grid>
                    <Grid item className="wrapSpecDetail">
                      <Typography className="specTitleText">Road Tax</Typography>
                      <Typography className="specDetailText">{this.state.attribute?.road_tax}</Typography>
                    </Grid>
                    <Grid item className="wrapSpecDetail">
                      <Typography className="specTitleText">Degree value</Typography>
                      <Typography className="specDetailText">{this.state.attribute?.degree_value}</Typography>
                    </Grid>
                    <Grid item className="wrapSpecDetail">
                      <Typography className="specTitleText">COE</Typography>
                      <Typography className="specDetailText">{this.state.attribute?.coe}</Typography>
                    </Grid>
                    <Grid item className="wrapSpecDetail">
                      <Typography className="specTitleText">Engine cap</Typography>
                      <Typography className="specDetailText">{this.state.attribute?.engine_cap}</Typography>
                    </Grid>
                    <Grid item className="wrapSpecDetail">
                      <Typography className="specTitleText">Curb Weight</Typography>
                      <Typography className="specDetailText">{this.state.attribute?.curb_weight}</Typography>
                    </Grid>
                    <Grid item className="wrapSpecDetail">
                      <Typography className="specTitleText">Type of vehicle</Typography>
                      <Typography className="specDetailText">{this.state.attribute?.vehicle_type}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md sm={12}>
                    <Grid item className="wrapSpecDetail">
                      <Typography className="specTitleText">Reg Date</Typography>
                      <Typography className="specDetailText">{this.state.attribute?.reg_date}</Typography>
                    </Grid>
                    <Grid item className="wrapSpecDetail">
                      <Typography className="specTitleText">Manufactured</Typography>
                      <Typography className="specDetailText">{this.state.attribute?.manufactured_year}</Typography>
                    </Grid>
                    <Grid item className="wrapSpecDetail">
                      <Typography className="specTitleText">Transmission</Typography>
                      <Typography className="specDetailText">{this.state.attribute?.transmission}</Typography>
                    </Grid>
                    <Grid item className="wrapSpecDetail">
                      <Typography className="specTitleText">OMV</Typography>
                      <Typography className="specDetailText">{this.state.attribute?.omv}</Typography>
                    </Grid>
                    <Grid item className="wrapSpecDetail">
                      <Typography className="specTitleText">ARF</Typography>
                      <Typography className="specDetailText">{this.state.attribute?.arf}</Typography>
                    </Grid>
                    <Grid item className="wrapSpecDetail">
                      <Typography className="specTitleText">Power</Typography>
                      <Typography className="specDetailText">{this.state.attribute?.power}</Typography>
                    </Grid>
                    <Grid item className="wrapSpecDetail">
                      <Typography className="specTitleText">No. of Owners</Typography>
                      <Typography className="specDetailText">{this.state.attribute?.no_of_owner}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography className="descriptionTitle">Description</Typography>
                <Typography className="descriptionDetail">{this.state.attribute?.description}</Typography>
                <Box className="line"/> 
                <Typography className="descriptionTitle">Features</Typography>
                <Typography className="descriptionDetail">{this.state.attribute?.features}</Typography>
                <Box className="line"/> 
                <Typography className="descriptionTitle">Accessories</Typography>
                <Typography className="descriptionDetail">{this.state.attribute?.accessories}</Typography> 
              </Grid>
            </Grid>
          </Box>
        </CatalogueDetailStyle>
      </>
        // Customizable Area End
    );
  }
}

// Customizable Area Start
const CatalogueDetailStyle  = styled(Box)(({ theme }) => ({
  '& .icon': {
    color: (theme as any).typography.h1, 
  },
  '& .containerBox': {
    padding: "30px",
  },
  '& .containerBoxxs': {
    padding: "30px",
  },
  '& .iconPadding': {
    padding: 0
  },
  '& .wrapHeader': {
    marginBottom:"24px"
  },
  '& .gap': {
    width:"24px"
  },
  '& .headerWrap': {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  '& .imageSelected': {
    height:"100%", 
    width:"100%", 
    maxWidth:"480px", 
    aspectRatio:"480/400", 
    objectFit:"contain"
  },
  '& .wrapTitle': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  '& .title': {
    color:(theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  '& .statusWrap': {
    display: "flex",
    marginLeft: "12px",
    backgroundColor: "#059669",
    borderRadius: "40px",
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingLeft: "8px",
    paddingRight: "8px",
    justifyContent: "center",
    alignItems: "center",
    height: "fit-content"
  },  
  '& .wrapCol': {
    marginRight:10
  },
  '& .statusText': {
    color:"#D1FAE5",
    fontFamily: "Outfit",
    fontSize:"12px",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  '& .textId': {
    color:(theme as any).typography.h3, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 400,
  }, 
  '& .priceWrap': {
    backgroundColor: (theme as any).typography.body2, 
    borderRadius: "16px",
    padding: "16px",
    height: "fit-content",
    marginBottom: "24px"
  },
  '& .priceHeader': {
    display: "flex",
    flexDirection:'row',
    justifyContent: 'space-between',
    alignItems: "flex-end"
  },
  '& .priceHeaderText': {
    color:(theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize:"18px",
    fontWeight: 400,
  },
  '& .priceValue': {
    color:(theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize:"26px",
    fontWeight: 700,
    marginTop:"16px",
    wordBreak:'break-word'
  },
  '& .tab': {
    display: "flex",
    marginTop: "24px",
    borderRadius: "8px",
    padding: "10px",
    border: `1px ${(theme as any).typography.border} solid`,
    backgroundColor: (theme as any).typography.body2, 
    width: "fit-content"
  },
  '& .tabTextWrap': {
    cursor:'pointer',
    padding: "8px",
    borderRadius: "4px",
  },
  '& .activeTab': {
    padding: "8px",
    borderRadius: "4px",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))"
  },
  '& .activeText': {
    color: "#171717",
    fontFamily: "Outfit",
    fontSize:"18px",
    fontWeight: 700,
    '@media (max-width: 600px)': {
      fontSize: 10,
    },
  },
  '& .contentWrap': {
    borderRadius: "16px",
    padding: "16px",
    backgroundColor: (theme as any).typography.body2, 
  },
  '& .tabText': {
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize:"18px",
    fontWeight: 700,
    '@media (max-width: 600px)': {
      fontSize: 10,
    },
  },
  '& .wrapSpec': {
    display: "flex",
    flexDirection: "row",
  },
  '& .techSpecWrap': {
    display:"flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  '& .wrapSpecDetail': {
    display: "flex",
    flexDirection: "row",
    marginBottom: "32px"
  },
  '& .specDetailText': {
    marginLeft: "16px",
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize:"18px",
    fontWeight: 700,
    '@media (max-width: 600px)': {
      wordBreak:'break-word'
    },
    
  },
  '& .specTitleText': {
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize:"18px",
    fontWeight: 400,
  },
  '& .descriptionTitle': {
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize:"20px",
    fontWeight: 500,
  },
  '& .imageWrap': {
    width:"480px", 
    height:"400px", 
    objectFit: "contain",
    borderRadius: "8px",
    justifyContent:"center"
  },
  '& .descriptionDetail': {
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize:"16px",
    fontWeight: 400,
    marginTop: "16px",
    wordBreak: "break-word"
  },
  '& .line': {
    height:"1px", 
    backgroundColor:"#FFF6DC30",
    marginTop: "12px",
    marginBottom: "12px"
  },
  '& .imageSetionWrap': {
    display: "flex",
    flexDirection: "column",
    marginRight: "24px", 
    maxWidth: '480px'
  },
  '& .listImageWrap': {
    display: "flex", 
    flexDirection:"row"
  }
}));
const ImageStyle = styled(Box)(({ theme }) => ({
  "& .MuiInputBase-input":{
    fontFamily: "Outfit",
    fontSize: 16,
    fontWeight: 400,
    color: (theme as any).typography.h1, 
  },
 '& .wrapSection': {
    display: "flex", 
    marginTop:"16px", 
    marginBottom: "24px"
  },
 '& .iconLeftWrap': {
    display: "flex", 
    borderRadius: "4px", 
    marginRight:"8px", 
    justifyContent:"center", 
    alignItems:"center",
  },
 '& .iconRightWrap': {
    display: "flex", 
    borderRadius: "4px", 
    justifyContent:"center", 
    alignItems:"center",
  },
 '& .iconPressable': {
    backgroundColor: "#CEAE71"
  },
 '& .icon': {
    width:"20px",
    height:"20px",
    color: (theme as any).typography.h1, 
  },
 '& .image': {
    width:"100px", 
    aspectRatio:"1", 
    objectFit:"cover",
    '@media (max-width: 600px)': {
      width:"60px", 
    },
  },
  '& .imageItem': { 
    marginRight:"8px"
  }
}));
const ModalStyle = styled(Box)(({ theme }) => ({
  '& .icon': {
    color: (theme as any).typography.h1, 
  },  
  "& .MuiInputBase-input":{
    fontFamily: "Outfit",
    fontSize: 16,
    fontWeight: 400,
    color: (theme as any).typography.h1, 
  },
  '& .labelText': {
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize:"14px",
    fontWeight: 700,
  },
  '& .line': {
    height:"1px", 
    backgroundColor:'#FFF6DC30', 
  },
  '& .inputField': {
    marginTop: '4px',
    borderRadius: 8,
    border: `1px ${(theme as any).typography.border} solid`,
    background: (theme as any).typography.body2, 
    padding: '10px 8px',
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  '& .errorInput': {
    marginTop: '4px',
    borderRadius: 8,
    background:(theme as any).typography.body2, 
    padding: '10px 8px',
    color: (theme as any).typography.h3, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    border: "1px solid #F87171",
  },
  '& .wrapModal': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    backgroundColor:(theme as any).typography.body2, 
    borderRadius: "16px",
    minWidth:'40%'
  },
  '& .width369': {
    width: '100%',
    
  },
  '& .modalHeaderWrap': {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "24px"   
  },
  '& .modalHeaderText': {
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize:"24px",
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
  },
  '& .modalFooterWrap': {
    display: "flex",
    justifyContent: "flex-end",
    padding: "24px"   
  },
  '& .modalContentWrap': {
    paddingTop: "30px",
    paddingBottom: "30px",
    paddingLeft: "100px",
    paddingRight: "100px",
  },
  "& .errorsMsg":{
    color: "#F87171",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  '& .cancelBtn': {
    backgroundColor:"#3F3E3A",
    borderRadius: "8px",
    color: "#CEAE71",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
    height: "56px"
  },
  '& .saveBtn': {
    marginLeft: "16px",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    borderRadius: "8px",
    color: "#171717",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
    height: "56px"
  },
}));
export {CatalogueDetail}

export default withWidth()(CatalogueDetail);
// Customizable Area End