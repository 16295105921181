import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
 
export const loanTerm = [
  {value: '12 months', label: '12 months'},
  {value: '24 months', label: '24 months'},
  {value: '36 months', label: '36 months'},
  {value: '48 months', label: '48 months'},
  {value: '60 months', label: '60 months'},
  {value: 'Other Loan Term', label: 'Other Loan Term'},
]
type NumericInputOptionsLoan = {
  allowDecimal?: boolean;
  maxDecimalPlace?: number;
  maxValue?: number;
  maxLength?: number;
  isInteger?: boolean;
  exactLength?: number;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  width: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  vehicleId: any,
  loanType: number,
  isShowOtherLoanTerm: boolean,
  loanTermOpt: string,

  carPrice: any,
  downpayment: string,
  overLoanAmount: string,
  interestRate: string,
  loanTerm: string,
  otherLoanTerm: string,
  rows: {year:number, loanRedemption: number}[],
  bankTable: {
    col1:string, 
    financial_services:string, 
    dbs: string, 
    may_bank:string, 
    ocbc:string, 
    uob: string, 
    hong_leong_finance:string, 
    tokyo_century_leasing: string
  }[],
  qrcode: string,
  loanAmount: string,
  interestAmount: string,
  totalPaidAmount: string,
  monthInstalment: string,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LoanCalculatorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCarPriceApiId: any;
  getBankDetailApiId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      vehicleId: this.props.navigation.getParam('vehicleId'),
      loanType: 0,
      isShowOtherLoanTerm: false,
      loanTermOpt: '12 months',

      carPrice: localStorage.getItem('editPrice'),
      downpayment: '',
      overLoanAmount: '',
      interestRate: '',
      loanTerm: '12',
      otherLoanTerm: '',
      rows: [
        {year:0, loanRedemption:0},
        {year:1, loanRedemption:0},
        {year:2, loanRedemption:0},
        {year:3, loanRedemption:0},
        {year:4, loanRedemption:0}],
      bankTable: [],
      loanAmount: '',
      interestAmount: '',
      totalPaidAmount: '',
      monthInstalment: '',
      qrcode:''
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getBankDetailApi();
    const loanAmount = (Number(this.state.carPrice) - Number(this.state.downpayment) + Number(this.state.overLoanAmount));
    const interestAmount = Math.round(loanAmount * Number(this.state.interestRate)/100 * (Number(this.state.loanTerm)/12));
    const totalPaidAmount = interestAmount + loanAmount;
    const monthInstalment = (interestAmount + loanAmount)/Number(this.state.loanTerm);
    this.setState({...this.state, 
      loanAmount: loanAmount.toString(), 
      interestAmount: interestAmount.toString(),
      monthInstalment: Math.round(monthInstalment).toString(),
      totalPaidAmount: totalPaidAmount.toString()})
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    switch (apiRequestCallId) {
      case this.getCarPriceApiId:
        this.getCarPriceReceiver(responseJson);
        break;
      case this.getBankDetailApiId:
        this.getBankDetailReceiver(responseJson);
        break;
      default:
        break;
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>, _snapshot?: SS | undefined): void {
    const isInputChanged = (input: keyof S) => prevState[input] !== this.state[input];
    if (
      isInputChanged('carPrice') ||
      isInputChanged('downpayment') ||
      isInputChanged('interestRate') ||
      isInputChanged('loanTerm') ||
      isInputChanged('overLoanAmount')
    ) {
      const loanAmount = (Number(this.state.carPrice) - Number(this.state.downpayment) + Number(this.state.overLoanAmount));
      const interestAmount = Math.round(loanAmount * Number(this.state.interestRate)/100 * (Number(this.state.loanTerm)/12));
      const totalPaidAmount = interestAmount + loanAmount;
      const monthInstalment = (interestAmount + loanAmount)/Number(this.state.loanTerm);
      for (let i=0; i < 5; i++){
        let loanRedemption = Math.round(totalPaidAmount - monthInstalment*i*12 - 
         (Number(this.state.loanTerm) - i*12)*(Number(this.state.loanTerm)+1 - i*12)/(Number(this.state.loanTerm)*(Number(this.state.loanTerm) + 1))*interestAmount*0.8);
        let loanRedemtionState = this.state.rows;
        const updatedValue = loanRedemption > 0 ? loanRedemption : 0;
        const updatedYear = i === 0 ? 0 : i;

        loanRedemtionState.splice(i, 1, { year: updatedYear, loanRedemption: updatedValue });
                
        if (i === 0 && loanRedemption > 0) {
          loanRedemtionState.splice(0, 1, { year: 0, loanRedemption: loanAmount });
        }
      
        this.setState({...this.state, rows: loanRedemtionState})
      }
      this.setState({...this.state, 
        loanAmount: loanAmount.toString(), 
        interestAmount: interestAmount.toString(),
        monthInstalment: Math.round(monthInstalment).toString(),
        totalPaidAmount: totalPaidAmount.toString()})
    }
    if(prevState.loanTermOpt !== this.state.loanTermOpt){
      switch (this.state.loanTermOpt) {
        case '12 months':
          this.setState({...this.state, loanTerm:'12', isShowOtherLoanTerm: false})
          break;
        case '24 months':
          this.setState({...this.state, loanTerm:'24', isShowOtherLoanTerm: false})
          break;
        case '36 months':
          this.setState({...this.state, loanTerm:'36', isShowOtherLoanTerm: false})
          break;
        case '48 months':
          this.setState({...this.state, loanTerm:'48', isShowOtherLoanTerm: false})
          break;
        case '60 months':
          this.setState({...this.state, loanTerm:'60', isShowOtherLoanTerm: false})
          break;
        case 'Other Loan Term':
          this.setState({...this.state, loanTerm:'', isShowOtherLoanTerm: true})
          break;
      }
    }
  }

  getCarPriceApi = async () => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVehicleDetailApi}/${this.state.vehicleId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.getCarPriceApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCarPriceReceiver = (responseJson: any) => {
    this.setState({ 
      carPrice: responseJson.data.attributes?.price,
     })
  }

  getBankDetailApi = async () => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bankDetailApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.getBankDetailApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBankDetailReceiver = (responseJson: any) => {
    const bankTable = responseJson.data.data.map((bank:any) => ({
      col1: bank.attributes.car_type.replace(/_/g, ' ').replace(/\b\w/g, (c: string) => c.toUpperCase()),
      financial_services: bank.attributes.financial_services|| "N/A",
      dbs: bank.attributes.dbs || "N/A",
      may_bank: bank.attributes.may_bank || "N/A",
      ocbc: bank.attributes.ocbc || "N/A",
      uob: bank.attributes.uob || "N/A",
      hong_leong_finance: bank.attributes.hong_leong_finance || "N/A",
      tokyo_century_leasing: bank.attributes.tokyo_century_leasing || "N/A"
  }));
    this.setState({ 
      bankTable,
      qrcode:responseJson.qr_code_url
     })
  }

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  onSelectLoanTerm = (event: any) => {
    this.onValueChange("loanTermOpt", event.target.value);
  }
  
  handleBtnDownpayment = (value: number) => {
    this.setState({...this.state, downpayment: (Math.round(Number(this.state.carPrice)*((value)/100))).toString()});
  }

  handleInterestRate = (value: string) => {
    this.setState({...this.state, interestRate: value});
  }

  handleLoanType = (value: number)=>{
    this.setState({...this.state, loanType: value, overLoanAmount: '', downpayment:''})
  }

  handleNavigateOverview = () => {
    this.props.navigation.navigate('CatalogueDetail',  { vehicleId: this.state.vehicleId });
  }

  handelRedirect=()=>{
   
  localStorage.setItem("vehicleLoanId",JSON.stringify(this.state.vehicleId))
    this.props.navigation.navigate('NewSalesAndContract');
  }

  handleNumericInputLoan = (events : any, options : NumericInputOptionsLoan = {}) => {
    const {
      allowDecimal = false,
      maxDecimalPlace = 0,
      maxValue = null,
      maxLength = null,
      isInteger = false,
    } = options;
    const Allowed = ['Backspace' , 'Delete', 'Tab' , 'Enter' , 'ArrowLeft', 'ArrowRight']
    
    if(Allowed.includes(events.key)) return ;
    const target = events.target as HTMLInputElement;
    if (!/[0-9.]/.test(events.key) ||
    (allowDecimal && events.key === '.' && target.value.includes('.')) ||
    ((isInteger && events.key === '.')) || 
    ((maxLength !== null && target.value.length >= maxLength)) || 
    ((maxValue !== null && parseFloat(target.value + events.key) > maxValue) ) ||
    ((allowDecimal && target.value.includes('.') && target.value.split('.')[1].length >= maxDecimalPlace)  && 
    (target.selectionStart && target.selectionStart > target.value.indexOf('.')))
    ) {
      events.preventDefault();
    }
  };
  // Customizable Area End
}
