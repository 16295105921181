Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePut = "PUT";
exports.apiMethodTypePatch = "PATCH";
exports.apiMethodTypeDelete = "DELETE";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.addCatalogueApi="bx_block_catalogue/catalogues";
exports.editCatalogueApi="bx_block_catalogue/catalogues/";
exports.getVehicleEditDataApi="bx_block_catalogue/catalogues/";
exports.getVehicleDetailApi="bx_block_catalogue/catalogues";
exports.deleteDetailApi="bx_block_catalogue/catalogues/";
exports.getFilterApi="bx_block_catalogue/catalogues";
exports.addCataloguePost = "POST";
exports.importCatalogueEndPoint="bx_block_catalogue/catalogues/import_csv";
exports.getHistoryEndApi="bx_block_catalogue/catalogues/import_history"
exports.bankDetailApiEndPoint = "bx_block_admin/bank_details/listing_bank_details";

exports.sampleCsvFile = 
`Reg date,Mileage,Manufactured year,Name,Depreciation,Model,Make,Description,Road tax,Transmission,Degree value,Omv,Coe,Arf,Engine cap,Power,Curb weight,No of owner,Vehicle type,Features,Accessories,Government id,Emp id,Selling price,Purchase price,Vehicle number,Status,images
12/12/1990,2.4,2014,Q6,test,audi,audi,hhh,4.8,hh,5,2.5,7,2,6,yhsh,4,2,roww,milage,hhhh,1,1,1222,2222,mp0333,available,"[https://images.pexels.com/photos/170811/pexels-photo-170811.jpeg?auto=compress&cs=tinysrgb&w=600,https://images.pexels.com/photos/210019/pexels-photo-210019.jpeg?auto=compress&cs=tinysrgb&w=600,https://images.pexels.com/photos/112460/pexels-photo-112460.jpeg?auto=compress&cs=tinysrgb&w=600,https://images.pexels.com/photos/1592384/pexels-photo-1592384.jpeg?auto=compress&cs=tinysrgb&w=600,https://images.pexels.com/photos/35967/mini-cooper-auto-model-vehicle.jpg?auto=compress&cs=tinysrgb&w=600,https://images.pexels.com/photos/810357/pexels-photo-810357.jpeg?auto=compress&cs=tinysrgb&w=600,https://images.pexels.com/photos/244206/pexels-photo-244206.jpeg?auto=compress&cs=tinysrgb&w=600,https://images.pexels.com/photos/627678/pexels-photo-627678.jpeg?auto=compress&cs=tinysrgb&w=600]"
`
exports.getMakeModalDataApi = "bx_block_catalogue/drop_downs/make_model_lists";
exports.getGovernmentApi= 'bx_block_catalogue/drop_downs?drop_down_value=government_id';
// Customizable Area End