import React from "react";

import {
  // Customizable Area Start
  Box,
  Grid,
  GridList,
  IconButton,
  ImageList,
  ImageListItem,
  ListSubheader,
  Modal,
  Typography,
  withWidth,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import clsx from 'clsx';
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';

// Customizable Area End

import BroadcastController, {
  Props,
} from "./BroadcastController";
import ChevronRight from "@material-ui/icons/ChevronRight";

class Broadcast extends BroadcastController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderModal = (width: string) => {
    return <Modal open={this.state.openModal}>
      <ModalStyle>
        <Box className="wrapModal">
          <Box className="modalHeaderWrap">
            <IconButton onClick={()=>this.handleModalDetail()} className="iconPadding">
              <CloseIcon className="icon"/>
            </IconButton>
          </Box>
          <Box className="line"/> 
          <Box className={clsx({'wrapModalContentxs': width === 'xs' || width === 'sm', 'wrapModalContent': !(width === 'xs' || width === 'sm') })}>
            <Box className="image">
              <img src={this.state.selectedBroadcast &&  this.state.selectedBroadcast.attributes.image?.url} className={clsx({'imgCarxsModel': width === 'xs' || width === 'sm', 'imgCarmdModel': !(width === 'xs' || width === 'sm') })}/>
            </Box>
            <Typography className={clsx({'contentModelxs': width === 'xs' || width === 'sm', 'contentModelmd': !(width === 'xs' || width === 'sm') })}>{this.state.selectedBroadcast && this.state.selectedBroadcast.attributes.message}</Typography>
            <Typography className="bottomText">Sent to {this.state.selectedBroadcast && this.state.selectedBroadcast.attributes.send_to}</Typography>
          </Box>
        </Box> 
      </ModalStyle>
    </Modal>
  }
  // Customizable Area End

  render() { 
    const {width} = this.props; 
    return (     
      // Customizable Area Start
      <Box style={{padding:30}}>
        {this.renderModal(width)}
        <BroadcastStyle>
          <Grid container>
            <Grid container item>
              <Grid item xs={12} md={10} sm={8} className="tabContainer">
                <Box className="tab">
                  <Box data-test-id='crmBtn' className="tabTextWrap" onClick={this.handleNavigateCRM}>
                    <Typography className="tabText">CRM</Typography>
                  </Box>
                  <Box className="gap"/>
                  <Box data-test-id='overviewBtn' className="tabTextWrap" onClick={this.handleNavigateCustomerGroup}>
                    <Typography className="tabText">Customer Group</Typography>
                  </Box>
                  <Box className="gap"/>
                  <Box data-test-id='overviewBtn' className="activeTab">
                    <Typography className="activeText">Broadcast</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md sm={12} className="wrapBtnAdd">
                <Box data-test-id={'createBroadcast'} className="btnWrap" onClick={this.handleCreateNewBroadcast}>
                  <Typography className="btnTextApply">New Broadcast</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid className='wrapContent'>
              <ImageList cols={width === 'xs'? 1: width==='sm' || width ==='md' ? 2 : 3} gap={16} >
              {this.state.listBroadcast?.map((e: any)=>{
                return <ImageListItem className="itemContainer">
                  <Box className='wrapItem'>
                    <img src={e.attributes.image?.url} className={clsx({'imgCarxs': width === 'xs' || width === 'sm' || width==='md', 'imgCarmd': !(width === 'xs' || width === 'sm' || width==='md') })}/>
                    <Typography paragraph noWrap={false} className='itemContent'>{e.attributes.message}</Typography>
                    <Box className="wrapBottomItem">
                      <Typography className="bottomText">Sent to {e.attributes.send_to} Customers</Typography>
                      <Box className="wrapViewMore">
                        <Typography className="viewMoreText" onClick={()=>this.handleModalDetail(e)}>View more</Typography>
                        <ChevronRight className="icon"/>
                      </Box>
                    </Box>
                  </Box>                  
                </ImageListItem>
              })}
              </ImageList>
            </Grid>     
          </Grid>                
        </BroadcastStyle>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ModalStyle = styled(Box)(({ theme }) => ({
  "& .icon": {
    color: (theme as any).typography.h1, 
  },
  '@media (orientation: landscape)': {
  },
  "& .bottomText":{
    color: (theme as any).typography.h3,
    fontSize: 14,
    fontFamily: 'Outfit', 
    fontWeight: '400', 
    marginTop:'16px'
  },
  '& .line': {
    height:"1px", 
    backgroundColor: (theme as any).typography.border,
  },
  '& .image': {
    display:'flex',
    justifyContent:'center'
  },
  '& .wrapModal': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    backgroundColor:(theme as any).typography.body2,
    borderRadius: "16px",
    minWidth:'40%'
  },
  '& .wrapModalContent': {
    padding: '40px',
    maxHeight:'600px',
    overflowY: 'scroll'
  },
  '& .wrapModalContentxs': {
    padding: '40px',
    '@media (orientation: landscape)': {
      height: 200,
      overflow:'auto',
    },
    maxHeight:'600px',
    overflow: 'scroll',
  },
  '& .modalHeaderWrap': {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: "24px",
  },
  '& .imgCarxsModel':{
    objectFit:"cover",
    width:300,
    height:200,
    borderRadius:'4px',
  },
  '& .imgCarmdModel':{
    objectFit:"cover",
    aspectRatio:300/200,
    borderRadius:'4px',
    width:520,
  },
  '& .contentModelmd':{
    width:'520',
    color: (theme as any).typography.h2,
    fontSize: 16, 
    fontFamily: 'Outfit', 
    fontWeight: '400', 
    marginTop:'16px', 
    overflowWrap:'anywhere',
  },
  '& .contentModelxs':{
    width:'300',
    color: (theme as any).typography.h2,
    fontSize: 16, 
    fontFamily: 'Outfit', 
    fontWeight: '400', 
    marginTop:'16px', 
    overflowWrap:'anywhere',
  },
}));
const BroadcastStyle  = styled(Box)(({ theme }) => ({
  
  '& .viewMoreText':{
    fontSize: 16, 
    fontFamily: 'Outfit', 
    fontWeight: '700',
    background:
      "linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  '& .groupText':{
    display:'flex',
    flexDirection:'row', 
    gap:60
  },
  '& .imgCarxs':{
    objectFit:"cover",
    width:200,
    aspectRatio:300/200,
    alignSelf:'center',
    borderRadius:'inherit',
    marginTop:16
  },
  '& .imgCarmd':{
    objectFit:"cover",
    aspectRatio:300/200,
    alignSelf:'center',
    borderRadius:'inherit',
    marginTop:16, 
    width:400
  },
  '& .wrapViewMore':{
    cursor:'pointer',
    flexDirection:'row',
    display:'flex', 
    alignItems:'center'
  },
  '& .padding30':{
    padding: "30px",
  },
  '& .icon':{
    color: '#F7DD91'
  },
  '& .padding30xs':{
    width: 'fit-content',
    padding: "30px",
  },
  "& .tabContainer":{
    width:'fit-content', 
  },  
  '& .tab': {
    display: "flex",
    borderRadius: "8px",
    padding: "10px",
    backgroundColor: (theme as any).typography.body2,
    width: "fit-content",
    border: `1px ${(theme as any).typography.border} solid`,
  },
  '& .wrapItem':{padding: 16, 
    display:'flex', 
    flexDirection:'column', 
    backgroundColor:(theme as any).typography.body2,
    border:`1px ${(theme as any).typography.border} solid`,
    borderRadius:8,
  },
  '& .tabTextWrap': {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center',
    cursor: 'pointer',
    padding: "8px",
    borderRadius: "4px",
  },
  '& .tabText': {
    color: (theme as any).typography.h1,
    fontFamily: "Outfit",
    fontSize:"18px",
    fontWeight: 700,
    '@media (max-width: 600px)': {
      fontSize: 10,
    },
  },
  '& .gap': {
    width:"24px"
  },
  '& .itemContainer': {
    height:'fit-content !important',
    width: '100%'
  },
  '& .activeTab': {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center',
    padding: "8px",
    borderRadius: "4px",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))"
  },
  '& .wrapBottomItem': {
    display:'flex', 
    flexDirection:'row', 
    justifyContent:'space-between',
    alignItems:'baseline'
  },
  '& .activeText': {
    color: "#171717",
    fontFamily: "Outfit",
    fontSize:"18px",
    fontWeight: 700,
    '@media (max-width: 600px)': {
      fontSize: 10,
    },
  },
  "& .wrapBtnAdd": { 
    width:'100%', 
    height:'fit-content', 
    paddingTop: 10, 
  },
  "& .btnTextApply":{
    color: '#171717', 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '700'
  },
  "& .itemContent":{
    color: (theme as any).typography.h2,
    fontSize: 16, 
    fontFamily: 'Outfit', 
    fontWeight: '400', 
    marginTop:'16px', 
    overflow: "hidden", textOverflow: "ellipsis", height: 150,
    },
  "& .btnWrap":{
    cursor: 'pointer',
    paddingTop: "10px", 
    paddingBottom: "10px", 
    paddingRight:'16px',
    paddingLeft:'16px',
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',
    width: '100%',
    maxWidth: '327px'

  },
  "& .wrapContent":{
    marginTop:30,
    width:'100%'
  },
  "& .bottomText":{
    color: (theme as any).typography.h3,
    fontSize: 14,
    fontFamily: 'Outfit', 
    fontWeight: '400', 
    marginTop:'16px'
  }
}))
export {Broadcast}

export default withWidth()(Broadcast);
// Customizable Area End