import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
// Customizable Area Ends

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openModal:boolean;
  listBroadcast: any;
  selectedBroadcast: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BroadcastController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBroadcastListId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      openModal: false,
      listBroadcast: [],
      selectedBroadcast: null,
      // Customizable Area End,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    if(apiRequestCallId === this.getBroadcastListId) {
      this.getBroadcastListReceiver(responseJson);
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getBroadcastList();
  }

  getBroadcastList = async () => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.broadcastEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getBroadcastListId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBroadcastListReceiver = (responseJson: any) => {
    this.setState({listBroadcast:responseJson.data})
  }

  handleModalDetail = (data?: any) => {
    this.setState({ openModal: !this.state.openModal, selectedBroadcast: data });
  };

  handleNavigateCRM = () =>{
    this.props.navigation.navigate('CRM')
  }

  handleCreateNewBroadcast = () =>{
    this.props.navigation.navigate('NewBroadcast')
  }

  handleNavigateCustomerGroup = ()=>{
    this.props.navigation.navigate("CustomerGroup")
  }
  // Customizable Area End
}