import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
const facebookPageId = "205301136008162";
const longString = "EAAnW2X7zoOwBOzmiZA6n5fJDxzIjgeeyZBAnI9a3690wPP0NycaRDXZApx5AkI9zBVQJbJ4FLIL6ZCxvIRbWKHk7TR5vfDEuMfSbczQslfH7ZBQhKXHGQF1PO8bo3TZC1iSbwgJY2pmhWcG0hNgqEhXjlBDfPxN3A65lsS2nwLLUqyk92fUDuzubj6x5T4ypPLF3Sg3ZBAZD"
const instagramPageId = "17841464943600812";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isCarMatching: boolean;
  selectedCustomer: string;
  editMenu: boolean;

  currentAnchorEl: any;
  rows2: { customerId: string, customerName: string, dob: string, phoneNumber: string, email: string, interestedService: string }[];
  rowCarMatching: { car_name: string, catalogue: number, customers: { name: string, contact_number: number, email: string }[] }[];
  pageCustomer: number;
  currentPageCustomer: number;
  pageMarketing: number;
  currentPageMarketing: number;
  openModal: boolean;

  valueSearchCusNameCRM: string;
  valueSearchPhoneCRM: string;
  valueSearchEmailCRM: string;
  valueSearchServiceCRM: string;
  valueSearchCarNameCar: string;
  valueSearchCusNameCar: string;
  valueSearchPhoneCar: string;
  valueSearchEmailCar: string;

  searchAttributeCRM: string;
  valueSearchCRM: string;
  searchAttributeCar: string;
  valueSearchCar: string;
  socialMediaList: any;
  loading: boolean,
  errorMsg: any,
  anchorEl: HTMLElement | null,
  anchorEl1: HTMLElement | null,
  selectedRow: any,
  apiResponseMessage: any,

  listImagesFacebook: any,
  listImagesInstagram: any,
  listVideosFacebook: any,
  listVideosInstagram: any,

  facebookMediaIds: any,
  instagramListIds: any,
  is_posted_on_facebook: string | null,
  is_posted_on_instagram: string | null,
  modalLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MarketingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCRMListId: any;
  getCarMatchingListId: any;
  deleteCustomerApiId: any;
  testId: any;
  searchCrmId: any;
  getMarketingListId: any;
  getSocialMediaListId: any;
  deletSocialMediaPostId: any;

  getMediaIdFacebookRetryListScreenId: any;
  createFacebookPostIdRetryListScreen: any;
  createInstagramMediaContainerRetryListScreenId: any;
  createItemContainerInstagramRetryListScreenId: any;
  createCarouselContainerInstagramRetryListScreenId: any;
  publishInstagramMediaContainerRetryListScreenId: any;
  editPostListScreenId: any;
  createInstagramMediaContainerVideoId: any;
  createVideoPostFacebookId: any;
  checkInstagramMediaContainerVideoStatusId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isCarMatching: false,
      selectedCustomer: '',
      editMenu: false,
      anchorEl: null,
      anchorEl1: null,
      loading: false,
      rows2: [],
      pageCustomer: 0,
      pageMarketing: 0,
      errorMsg: null,
      currentPageCustomer: 1,
      currentPageMarketing: 1,
      rowCarMatching: [],
      currentAnchorEl: null,
      openModal: false,
      valueSearchCusNameCRM: '',
      valueSearchPhoneCRM: '',
      valueSearchEmailCRM: '',
      valueSearchServiceCRM: '',
      valueSearchCarNameCar: '',
      valueSearchCusNameCar: '',
      valueSearchPhoneCar: '',
      valueSearchEmailCar: '',

      searchAttributeCRM: '',
      valueSearchCRM: '',
      searchAttributeCar: '',
      valueSearchCar: '',
      socialMediaList: {},
      apiResponseMessage: "",
      selectedRow: null,
      listImagesFacebook: [],
      listImagesInstagram: [],
      listVideosFacebook: [],
      listVideosInstagram: [],
      is_posted_on_facebook: null,
      is_posted_on_instagram: null,
      facebookMediaIds: [],
      instagramListIds: [],
      modalLoading: false,
      // Customizable Area End,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }



  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
   // this.getSocialMediaPostsList();
  this.getMarketingList(1)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      switch (webApiRequestCallId) {
        case this.deletSocialMediaPostId:
          this.deleteSocialMediaReceiver(responseJson);
            break;
        case this.getMarketingListId:
          this.getMarketingListReceiver(responseJson);
          break;
        case this.getMediaIdFacebookRetryListScreenId:
          this.getMediaIdFacebookRetryListScreenReceiver(responseJson);
          break;
        case this.createFacebookPostIdRetryListScreen:
          this.createFacebookPostIdRetryListScreenReceiver(responseJson);
          break;
        case this.editPostListScreenId:
          if((this.state.is_posted_on_facebook === "true" || this.state.is_posted_on_facebook === null) && (this.state.is_posted_on_instagram === "true" || this.state.is_posted_on_instagram === null)){
            this.setState({modalLoading: false, anchorEl:null, anchorEl1: null})
          }
          this.getMarketingList(this.state.currentPageMarketing)
          break;
        case this.createInstagramMediaContainerRetryListScreenId:
          this.publishInstagramMediaRetryListScreenReceiver(responseJson.id);
          break;
        case this.publishInstagramMediaContainerRetryListScreenId:
          this.publishInstagramMediaContainerRetryListScreenReceiver();
          break;
        case this.createItemContainerInstagramRetryListScreenId: 
          this.createItemContainerInstagramRetryListScreenReceiver(responseJson)
          break;
        case this.createCarouselContainerInstagramRetryListScreenId:
          this.publishInstagramMediaRetryListScreenReceiver(responseJson.id);
          break;
        case this.createInstagramMediaContainerVideoId:
          this.checkInstagramMediaContainerVideoStatus("IN_PROGRESS", responseJson.id)
          break;
        case this.checkInstagramMediaContainerVideoStatusId:
          setTimeout(() =>{
            this.checkInstagramMediaContainerVideoStatus(responseJson.status_code, responseJson.id)
          }, 10000);
          break;
        case this.createVideoPostFacebookId:
          this.createVideoPostFacebookReceiver();
          break;
        default:
          break;
      }
      if (webApiRequestCallId === this.getSocialMediaListId) {
        this.setState({
          errorMsg: webErrorReponse,
          loading: false
        });
      }
      runEngine.debugLog("Message Recived", message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getMarketingList = async (page: number) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessageListScreen = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSocialMediaEndpoint}/?page=${page}&per_page=10`
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getMarketingListId = requestMessageListScreen.messageId;
    runEngine.sendMessage(requestMessageListScreen.id, requestMessageListScreen);
  }

  getMarketingListReceiver = (responseJson: any) => {
    this.setState({socialMediaList:  responseJson,loading:false ,pageMarketing: Math.ceil(responseJson?.meta?.pagination?.total_count/10)})
  }

  handleChangePageMarketing=(_event: any, value:any) =>{ 
    this.getMarketingList(value)
    this.setState({currentPageMarketing: value });
  }

  deleteSocialMediaPost = async () => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessageListScreen = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSocialMediaEndpoint}/${this.state.selectedRow.id}`
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationDeleteApiMethodType
    );
    this.deletSocialMediaPostId = requestMessageListScreen.messageId;
    runEngine.sendMessage(requestMessageListScreen.id, requestMessageListScreen);
  }
  
  deleteSocialMediaReceiver = (responseJson: any) => {
    this.getMarketingList(this.state.currentPageMarketing)
  }

  handleCreateNewPost = () => {
    this.props.navigation.navigate('CreateNewPost');
  }

  redirectTopage=(mediaId:any)=>{
    this.props.navigation.navigate('MarketingDetails',{postid:mediaId})
  }

  handleClick = (event: any, row: any) => {
    this.setState({
      anchorEl: event?.currentTarget,
      selectedRow: row
    });
  };

  handleClick1 = (event: React.MouseEvent<HTMLElement, MouseEvent>, row: number) => {
    this.setState({
      anchorEl1: event?.currentTarget,
      selectedRow: row
    });
  };

  handleClose = (actionType: string,) => {
    this.setState({ anchorEl: null });
    if (actionType === "Delete") {
      this.deleteSocialMediaPost()
    }
  };

  handleClose1 = (actionType: string,) => {
    this.setState({ anchorEl1: null });
    if (actionType === "Delete") {
      this.deleteSocialMediaPost()
    }
  };

  handleRetry = () => {
    this.setState({
      modalLoading: true,
      listImagesInstagram: this.state.selectedRow.attributes.images,
      listImagesFacebook: this.state.selectedRow.attributes.images,
      listVideosInstagram: this.state.selectedRow.attributes.videos,
      listVideosFacebook: this.state.selectedRow.attributes.videos,
    } , ()=>{
      for(const platform of this.state.selectedRow?.attributes?.platform){
      
        switch(platform){
          case("Instagram"):
            if(!this.state.selectedRow.attributes.is_posted_on_instagram){
              this.setState({is_posted_on_instagram:"false"})
              this.handlePostInstagramRetryListScreen()
            }
          break;
          case("Facebook"):
            if(!this.state.selectedRow.attributes.is_posted_on_facebook){
              this.setState({is_posted_on_facebook:"false"})
              this.handlePostFacebookRetryListScreen()
            }
          break;
        }
      }
    })    
  }

  handlePostInstagramRetryListScreen = ()=>{
    if (((this.state.listVideosInstagram?.length ?? 0) + (this.state.listImagesInstagram?.length ?? 0)) === 1){
      if(this.state.listVideosInstagram?.length === 1) {
        this.createSingleVideoPostInstagram();
      } else {
        this.createSingleImagePostInstagramRetryListScreen();
      }
    } else {
      this.createMultipleImagePostInstagramRetryListScreen(this.state.listImagesInstagram[0].url)
    }
  }

  handlePostFacebookRetryListScreen = ()=>{
      if((this.state.listImagesFacebook?.length ?? 0) > 0 ){
        this.getMediaIdRetryList(this.state.listImagesFacebook[0].url)
      } else {
        this.createVideoPostFacebook(this.state.listVideosFacebook[0].url)
      }
  }

  createVideoPostFacebook = async (url: any)=>{
    const requestMessageListScreen = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      file_url: url,
      access_token: longString,
      description:`${this.state.selectedRow.attributes.post} ${this.state.selectedRow.attributes.hashtag ?? ""}`
    };
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/${facebookPageId}/videos`
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createVideoPostFacebookId = requestMessageListScreen.messageId; 
    runEngine.sendMessage(requestMessageListScreen.id, requestMessageListScreen) ;
  }

  createVideoPostFacebookReceiver = () => {
    let temp =[...this.state.listVideosFacebook];
    temp.shift()
    this.setState(prevState => ({
      listVideosFacebook: temp
    }), () => {
      if(this.state.listVideosFacebook.length > 0) {
        this.createVideoPostFacebook(this.state.listVideosFacebook[0].url)
      } else {
        this.setState({
          is_posted_on_facebook: "true" }, ()=>{
            this.ediPostListScreen();
          }
        )
      }
    });
  };

  createSingleVideoPostInstagram = () => {
    this.createInstagramMediaContainerVideo(this.state.listVideosInstagram[0].url, `${this.state.selectedRow.attributes.post} ${this.state.selectedRow.attributes.hashtag ?? ""}`)
  };

  createInstagramMediaContainerVideo = (videoUrl: string, caption: string) => {    
    const requestMessageListScreen = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      video_url: videoUrl,
      caption: caption,
      access_token: longString,
      media_type: "REELS"
    };
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    ) ;
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${instagramPageId}/media`
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createInstagramMediaContainerVideoId = requestMessageListScreen.messageId; 
    runEngine.sendMessage(requestMessageListScreen.id, requestMessageListScreen) ;
  };

  checkInstagramMediaContainerVideoStatus = (status: string, containerId: string) => {    
    switch (status) {
      case "FINISHED":
        this.publishInstagramMediaRetryListScreenReceiver(containerId)
        break;
      case "IN_PROGRESS":
        const requestMessageListScreen = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageListScreen.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
            'Content-Type' : 'application/json',
          })
        ) ;
        requestMessageListScreen.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage), 
          `https://graph.facebook.com/v20.0/${containerId}/?fields=status_code&access_token=${longString}`
        );
        requestMessageListScreen.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'GET'
        );
        this.checkInstagramMediaContainerVideoStatusId = requestMessageListScreen.messageId; 
        runEngine.sendMessage(requestMessageListScreen.id, requestMessageListScreen) ;
        break;
    
      default:
        break;
    }
  };

  getMediaIdRetryList = async (url:any) => {
    const formData = new FormData();
    formData.append('url', url)
    const requestMessageListScreen = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `https://graph.facebook.com/${facebookPageId}/photos?access_token=${longString}&published=false`
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
       formData
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    this.getMediaIdFacebookRetryListScreenId = requestMessageListScreen.messageId;
    runEngine.sendMessage(requestMessageListScreen.id, requestMessageListScreen);
  };

  getMediaIdFacebookRetryListScreenReceiver = (response: any) => {
    let temparr = [...this.state.listImagesFacebook]
    temparr.shift()
    this.setState(prevState => ({
      facebookMediaIds: [...prevState.facebookMediaIds, response.id] ,
      listImagesFacebook: temparr
    }), () => {
      if(this.state.listImagesFacebook.length > 0) {
        this.getMediaIdRetryList(this.state.listImagesFacebook[0].url)
      } else {
        this.postToFacebookListScreen(this.state.facebookMediaIds)
      }
    });
  };

  postToFacebookListScreen = async (facebookMediaIds: string[]) => {
    const requestMessageListScreen = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    const body = {
      message: `${this.state.selectedRow.attributes.post} ${this.state.selectedRow.attributes.hashtag ?? ""}`,
      attached_media: facebookMediaIds.map(idmedia => ({ media_fbid: idmedia })),
    };
  
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type': 'application/json',
      })
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `https://graph.facebook.com/${facebookPageId}/feed?access_token=${longString}`
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
  
    this.createFacebookPostIdRetryListScreen = requestMessageListScreen.messageId;
    runEngine.sendMessage(requestMessageListScreen.id, requestMessageListScreen);
  };

  createFacebookPostIdRetryListScreenReceiver = (response: any) => {
    if (response && response.id) {
      this.setState({is_posted_on_facebook : "true" } )
    } else {
      this.setState({is_posted_on_facebook : "false" } )
    }
    this.ediPostListScreen();
  };

  createSingleImagePostInstagramRetryListScreen = () => {
    this.createInstagramMediaContainerRetryListScreen(this.state.listImagesInstagram[0].url, `${this.state.selectedRow.attributes.post} ${this.state.selectedRow.attributes.hashtag ?? ""}`)
  };

  createMultipleImagePostInstagramRetryListScreen =(url: string)=>{
    this.createItemContainerInstagramRetryListScreen(this.state.listImagesInstagram[0].url)
  }

  createInstagramMediaContainerRetryListScreen = (imageUrl: string, caption: string) => {    
    const requestMessageListScreen = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    );
    const body = {
      image_url: imageUrl,
      caption: caption,
      access_token: longString,
    };
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${instagramPageId}/media`
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createInstagramMediaContainerRetryListScreenId = requestMessageListScreen.messageId; 
    runEngine.sendMessage(requestMessageListScreen.id, requestMessageListScreen) ;
  };

  createItemContainerInstagramRetryListScreen = (url: string)=>{
    const requestMessageListScreen = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    );
    const body = {
      image_url: url,
      is_carousel_item: true,
      access_token: longString,
    };
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${instagramPageId}/media`
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createItemContainerInstagramRetryListScreenId = requestMessageListScreen.messageId; 
    runEngine.sendMessage(requestMessageListScreen.id, requestMessageListScreen) ;
  }

  createItemContainerInstagramRetryListScreenReceiver = (response: any)=>{
    let temparr =[...this.state.listImagesInstagram]
    temparr.shift()
    this.setState(prevState => ({
      instagramListIds: [...prevState.instagramListIds, response.id],
      listImagesInstagram: temparr
    }), () => {
      if(this.state.listImagesInstagram.length > 0) {
        this.createItemContainerInstagramRetryListScreen(this.state.listImagesInstagram[0].url)
      } else {
        this.createCarouselContainerInstagramRetryListScreen(this.state.instagramListIds)
      }
    });
  }

  createCarouselContainerInstagramRetryListScreen = (listIds: any)=>{
    const requestMessageListScreen = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    );
    const body = {
      media_type: "CAROUSEL",
      caption: `${this.state.selectedRow.attributes.post} ${this.state.selectedRow.attributes.hashtag ?? ""}`,
      children:listIds && listIds.join(','),
      access_token: longString,
    };
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${instagramPageId}/media`
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.createCarouselContainerInstagramRetryListScreenId = requestMessageListScreen.messageId; 
    runEngine.sendMessage(requestMessageListScreen.id, requestMessageListScreen) ;
  } 

  publishInstagramMediaRetryListScreenReceiver = (creationId: string) => {
    const requestMessageListScreen = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        'Content-Type' : 'application/json',
      })
    );
    const body = {
      creation_id : creationId,
      access_token: longString,
    };
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      `https://graph.facebook.com/v20.0/${instagramPageId}/media_publish`
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    this.publishInstagramMediaContainerRetryListScreenId = requestMessageListScreen.messageId; 
    runEngine.sendMessage(requestMessageListScreen.id, requestMessageListScreen) ;
  }

  publishInstagramMediaContainerRetryListScreenReceiver = ()=>{
    this.setState({is_posted_on_instagram: 'true'}, ()=>{
      this.ediPostListScreen();
    });
  }

  ediPostListScreen = async () => {
    const tokenValue = await getStorageData("authToken")
    const headers = {
      token: tokenValue,
    };     
    const formdata = new FormData();
    this.state.is_posted_on_facebook && formdata.append("is_posted_on_facebook", this.state.is_posted_on_facebook);
    this.state.is_posted_on_instagram && formdata.append("is_posted_on_instagram", this.state.is_posted_on_instagram);
    const requestMessageListScreen = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSocialMediaEndpoint}/${this.state.selectedRow.id}`
    );
    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessageListScreen.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutEampleMethod
    );
    this.editPostListScreenId = requestMessageListScreen.messageId;
    runEngine.sendMessage(requestMessageListScreen.id, requestMessageListScreen);
  }
  // Customizable Area End
}