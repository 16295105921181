import { Box, Select, Typography, makeStyles, styled } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

interface Props {
  list: string[],
  listSelected?: string[],
  selected?: string,
  handleSelect: (value: string) => void,
  handleDeleteSelected: any;
  notModel?: boolean;
  closeCalendar?: any;
}

const CustomSelect = ({list, listSelected, selected = '', handleSelect,handleDeleteSelected, notModel, closeCalendar }: Props) => {
  const classes = useStyles();
  return <CustomSelectStyle>
    <Select
      // data-test-id='Make'
      className="inputFieldServices"
      disableUnderline
      displayEmpty
      value={''}
      fullWidth
      renderValue={
        () => <Typography className="searchText">Select</Typography>
      }
      onMouseDown={()=>{
        closeCalendar&& closeCalendar();
      }}
      MenuProps={{
        classes: {paper: classes.select},
        style: {
          marginTop: '10px',
          marginLeft: '-10px',
        },
        
        PaperProps:{ className : classes.paper  },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
    >             
      {notModel ? 
        <Typography className='textSelect'>Select a carmake first</Typography> 
        : 
        <Box>
          {list.map((e)=> <Box className='wrapText' onClick={()=>handleSelect(e)}>
            <Typography className='textSelect'>{e}</Typography>
          </Box>)}
        </Box>
      }                                    
    </Select>
    <Box className="selectedService">
      {listSelected && listSelected.map((e) => <Box className="Chipbasic">
        <Typography className="Textlabel">{e}</Typography>
        <CloseIcon className="iconX" fontSize="small" onClick={()=>handleDeleteSelected(e)}/>
      </Box>)} 
      {selected &&  selected !="" && <Box className="Chipbasic">
        <Typography className="Textlabel">{selected}</Typography>
        <CloseIcon className="iconX" fontSize="small" onClick={()=>handleDeleteSelected()}/>
      </Box>} 
    </Box>
  </CustomSelectStyle>
}


const useStyles = makeStyles(theme=>({
  paper: {
    backgroundColor:(theme as any).typography.surface2,
        borderRadius:'8px',
            paddingLeft: '8px',
            paddingRight: '8px',
            height: '150px',
  }, 
  select: {
    '& .textSelect': {
      color: (theme as any).typography.h1, 
      fontSize: '14px', 
      fontFamily: 'Outfit', 
      fontWeight: '400',
    },
    
    '& .wrapText': {
      cursor: 'pointer',
      padding: '8px'
    },
  },
}));

const CustomSelectStyle = styled(Box)(({ theme }) => ({
  '& .inputFieldServices': {
    marginTop: '4px',
    borderRadius: 8,
    border: `1px ${(theme as any).typography.border} solid`,
    background: (theme as any).typography.body2, 
    padding: '10px 8px',
    color: (theme as any).typography.h3, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    height:"auto",
  },
  '& .searchText': {
    color: (theme as any).typography.h1, 
    fontSize: 14, 
    fontFamily: 'Outfit', 
    fontWeight: '400',
  },
  '& .iconX': {
    cursor: 'pointer',
    color: (theme as any).typography.h1, 
  },
  '& .selectedService': {
    marginTop:'16px',
    display: 'flex',
    flexDirection:'column'
  },
}))
export default CustomSelect;