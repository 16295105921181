// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: string;
  handleAddCatalougue: any;
  handleListItemClick: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  historyDetails: any;
  failRecords: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HistoryController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getHistoryDetailId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      historyDetails: [],
      failRecords: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getHistoryDetailApi();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    if (this.getHistoryDetailId == apiRequestCallId && responseJson) {
      this.getHistoryDetailReceiver(responseJson);
    }
    runEngine.debugLog("Message Recived", message);

    // Customizable Area End
  }

  // Customizable Area Start
  handleDownload =(index:number)=>{
    const failCsvUrl = this.state.historyDetails[index].attributes.fail_csv_url
    const fileName = this.state.historyDetails[index].attributes.file_name    
    
    fetch(failCsvUrl)
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(error => console.error('Error downloading CSV file:', error));
  }
  getHistoryDetailApi = async () => {
    const tokenValue = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: tokenValue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getHistoryEndApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.getHistoryDetailId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getHistoryDetailReceiver = (responseJson: any) => {
    this.setState({ historyDetails: responseJson.data });
  };
  // Customizable Area End
}
