import React from "react";

import {
    // Customizable Area Start
    Box,
    MenuProps,
    Grid,
    Menu,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Typography,
    withWidth,
    Modal,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import clsx from 'clsx';
import { styled, withStyles } from "@material-ui/core/styles";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from "moment";
import CustomPagination from "../../../components/src/CustomPagination";
import ReplayIcon from '@material-ui/icons/Replay';
// Customizable Area End

import MarketingController, {
    Props,

} from "./MarketingController";

export default class Marketing extends MarketingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderLoading = () => {
        return <Modal open={this.state.modalLoading}>
          <ModalStyle>
            <Box className="wrapModal">
              <Box className="wrapModalContentLoading">
                <CircularProgress className="iconLoading" size={20} />
                <Typography className="loadingText">Please wait while we are Uploading your post</Typography>
              </Box>
            </Box> 
          </ModalStyle>
        </Modal>
    }

    // Customizable Area End

    render() {
        const { width } = this.props;
        return (
            // Customizable Area Start
            <>
                {this.renderLoading()}
                <MarketingGroupStyle>
                    <Grid container className={clsx({ "padding30": width !== 'xs', 'padding30xs': width == 'xs' })}>
                        <Grid container item alignItems="center">
                            <Grid item xs={12} md={10} sm={8} className="tabContainer">
                                <Box mt={3} mb={4}>
                                    <Typography className="M_title" variant="h6">Social Media Post </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={2} sm={4} className="wrapBtnAdd">
                                <Box data-test-id='addNewPost' className="btnWrap" >
                                    <Typography className="btnTextApply" onClick={this.handleCreateNewPost}>Create New Post</Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            {
                                this.state.loading ? (<Box width="100%" height={300} display="flex" alignItems="center" justifyContent="center">
                                    <CircularProgress size={40} />
                                </Box>) : (
                                    <TableContainer className={clsx({ 'width300': width === 'xs', 'width100': width !== 'xs' })}>

                                        <Table aria-label="simple table">
                                        <StyledTableHead  >
                                    <StyledTableRow>
                                        <TableCell className='textCell' style={{width:350 }}>Car Name</TableCell>
                                        <TableCell className='textCell' style={{width:250 }} align="left">Platform </TableCell>
                                        <TableCell className='textCell' style={{width:300  }} align="left">Creation Date</TableCell>
                                        <TableCell className='textCell' colSpan={2} align="left">Status</TableCell>

                                    </StyledTableRow>
                                </StyledTableHead>


                                            <StyledTableBody className={clsx({ 'width300': width === 'xs', 'width100': width !== 'xs' })}  >

                                                {
                                                    this.state.socialMediaList?.data && this.state.socialMediaList?.data.length > 0 ? this.state.socialMediaList?.data?.map((list: any, index: number) => {
                                                        return <StyledTableRow key={index} >
                                                            <StyledTableCell 
                                                            style={{width:350 }} data-test-id={`DATACLICK_${index}`} align="left" onClick={() => this.redirectTopage(list?.id)}>

                                                                {list.attributes.vehicle_name}
                                                            </StyledTableCell>
                                                            <StyledTableCell  style={{width:250 }} align="left">{list.attributes.platform.map((item: string) => item.replace(/\s/g, '')).join(', ')}</StyledTableCell>
                                                            <StyledTableCell style={{width:300  }}align="left">{moment(list.attributes.created_at).format("DD/MM/YYYY")}</StyledTableCell>
                                                            <StyledTableCell  >
                                                                <Box className={`${list.attributes.status === 'SUCCESSFUL' ? "successFull" : "UnSuccessful"} `}>
                                                                    <Typography variant="h6">
                                                                        {list.attributes.status}
                                                                    </Typography>
                                                                </Box>

                                                            </StyledTableCell>

                                                            {list.attributes.status === "UNSUCCESSFUL" ? <StyledTableCell align="center">
                                                            <Box data-test-id="moreicon" aria-haspopup="true" onClick={(e) => this.handleClick(e, list)}>
                                                                <MoreVertIcon />
                                                            </Box>
                                                            <StyledMenu
                                                                id="simple-menu"
                                                                anchorEl={this.state.anchorEl}
                                                                keepMounted
                                                                open={Boolean(this.state.anchorEl)}
                                                                onClose={this.handleClose}
                                                            >                                                                
                                                                <MenuItem data-test-id="Retry" onClick={this.handleRetry}>
                                                                    <ListItemIcon>
                                                                        <ReplayIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary="Retry" />
                                                                </MenuItem>
                                                                <MenuItem data-test-id="Delete" onClick={() => this.handleClose("Delete")}>
                                                                    <ListItemIcon>
                                                                        <DeleteIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary="Delete" />
                                                                </MenuItem>
                                                            </StyledMenu>
                                                        </StyledTableCell> : <StyledTableCell align="center">

                                                        <Box data-test-id="moreicon1" aria-haspopup="true" onClick={(event) => this.handleClick1(event, list)}>
                                                            <MoreVertIcon />
                                                        </Box>
                                                        <StyledMenu
                                                            id="simple-menu1"
                                                            anchorEl={this.state.anchorEl1}
                                                            keepMounted
                                                            open={Boolean(this.state.anchorEl1)}
                                                            onClose={this.handleClose1}
                                                        >
                                                            <MenuItem data-test-id="Delete1" onClick={() => this.handleClose1("Delete")}>
                                                                <ListItemIcon>
                                                                    <DeleteIcon />
                                                                </ListItemIcon>
                                                                <ListItemText primary="Delete" />
                                                            </MenuItem>
                                                        </StyledMenu>
                                                        </StyledTableCell>}
                                                        </StyledTableRow>
                                                    }) : <Box p={1}><Typography variant="h6" className="textCell">There is no social media post</Typography></Box>
                                                }
                                            </StyledTableBody>
                                        </Table>
                                    </TableContainer>)
                            }

                            {
                                !this.state.loading && <CustomPagination page={this.state.currentPageMarketing} count={this.state.pageMarketing} onChange={this.handleChangePageMarketing} />
                            }


                        </Grid>

                    </Grid>
                </MarketingGroupStyle>
            </>
        );
        // Customizable Area End
    }
}



// Customizable Area Start
const ModalStyle = styled(Box)(({ theme }) => ({
    '& .wrapModal': {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%', 
      transform: 'translate(-50%, -50%)',
      backgroundColor:(theme as any).typography.body2, 
      borderRadius: "16px",
      minWidth:'40%'
    },
    '& .iconLoading': {
      color:'#CEAE71', 
      alignSelf:'center'
    },
    '& .wrapModalContentLoading': {
      justifyContent: 'center',
      padding: '40px',
      alignItems:'center',
      width:'100%',
      display:'flex',
      flexDirection: 'column'
    },
    '& .loadingText': {
        marginTop: 24,
      color: (theme as any).typography.h1, 
      fontFamily: "Outfit",
      fontSize:"16px",
      fontWeight: 700,
    },
}));

const StyledTableHead: any = withStyles(theme => ({
    root: {

        background: (theme as any).typography.body1,
       
        '& .MuiTableCell-root': {
            border: "none"
        }
    }
}))(TableHead);

const StyledMenu = withStyles((theme) => ({
    paper: {
        borderRadius: '8px',
        background: (theme as any).typography.body1,
        border: `1px solid ${(theme as any).typography.body2}`,
        color: (theme as any).typography.h2,
        fontSize: 12,
        fontFamily: 'Outfit',
        fontWeight: 400,
        "& svg": {
            color: (theme as any).typography.h2,
        }
    },
}))((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledTableBody = withStyles(theme => ({
    root: {


        // Add border-radius to the table body
        overflow: 'hidden',
        '& .MuiTableCell-root': {
            border: "none"
        }
    }
}))(TableBody);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:last-child td': {
            borderBottom: 'none', // Remove bottom border from the last table row
        },
        '& .MuiTableCell-root': {
            border: "none"
        }
    },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
    body: {
        backgroundColor: (theme as any).typography.body2,
        color: (theme as any).typography.h2,
        fontSize: '16px',
        fontFamily: 'Outfit',
        fontWeight: 400,
        borderSpacing: '0px 4px',
        border: 'none',
        borderCollapse: 'separate',

    },
    head: {
        backgroundColor: (theme as any).typography.body2,
        color: (theme as any).typography.h1,
        fontSize: '14px',
        fontFamily: 'Outfit',
        fontWeight: 700,
        verticalAlign: 'center',
        border: 'none',
        borderCollapse: 'separate',
        borderSpacing: '0px 4px'

    },
}))(TableCell)
const MarketingGroupStyle = styled(Box)(({ theme }) => ({

    "& .successFull": {
        borderRadius: "40px",
        background: "var(--Status-Green-100, #D1FAE5)",
        display: "flex",
        padding: "2px 8px",
        justifyContent: "center",
        width: "fit-content",
        alignItems: "center",
        gap: "2px",

        "& h6": {
            color: "var(--Status-Green-600, #059669)",
            fontFamily: 'Outfit',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '18px', // 150%
            textTransform: 'uppercase',
        }
    },
    "& .MuiIconButton-label": {
        color: (theme as any).typography.h1,
    },

    "& .UnSuccessful": {
        borderRadius: "40px",
        background: "var(--Status-Red-100, #FEE2E2)",
        display: "flex",
        padding: "2px 8px",
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        gap: "2px",

        "& h6": {
            color: "var(--Status-Red-600, #DC2626)",
            fontFamily: 'Outfit',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '18px', // 150%
            textTransform: 'uppercase',
        }
    },



    "& .tabContainer": {
        width: 'fit-content',
    },
    "& .btnWrap": {
        cursor: 'pointer',
        paddingTop: "10px",
        paddingBottom: "10px",
        background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)',
        borderRadius: '8px',
        overflow: 'hidden',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        maxWidth: '327px'
    },

    "& .btnTextApply": {
        color: '#171717',
        fontSize: "16px",
        fontFamily: 'Outfit',
        fontWeight: '700'
    },

    '& .padding30': {
        padding: "30px",
    },
    '& .padding30xs': {
        width: 'fit-content',
        padding: "30px",
    },
    '& .width300': {
        width: '327px',
        borderRadius: '16px'
    },
    '& .width100': {
        width: '100%',
        borderRadius: '15px',

    },
    '& .textCell': {
        fontSize: 16,
        fontFamily: 'Outfit',
        fontWeight: '500',
        color: (theme as any).typography.h1,
    },

    "& .M_title": {
        color: (theme as any).typography.h1,
        fontFamily: 'Inter',
        fontSize: '1.4rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        width: "fit-content",
    },

    "& .actions": {
        borderRadius: '8px',
        border: `1px solid ${(theme as any).typography.border}`,
        background: (theme as any).typography.body2,
        padding: "12px",
        boxShadow: "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
        display: "flex",
        flexDirection: "column",

    },
    "& .action_buttons": {
        display: "flex",
        alignItems: "center",
        margin: "5px ",
        "& h6": {
            color: (theme as any).typography.h1,
            fontSize: "12px"
        },
        "& svg": {
            color: (theme as any).typography.h1,
            fontSize: "20px"
        },
    }

}))



export { Marketing }

withWidth()(Marketing);
// Customizable Area End