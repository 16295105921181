import { Box, Input, InputAdornment, MenuItem, Select, Typography, makeStyles, styled } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

interface Props {
  value: string,
  placeholder: string,
  handleChooseItem: (data:any)=>void,
  listItem: any,
}

const CustomSelectCustomerGroup = ({value, placeholder, listItem, handleChooseItem}: Props) => {
  const classes = useStyles();
  return <CustomSelectStyle>
    <Select 
      data-test-id="selectCarName"
      className="inputField"
      fullWidth
      displayEmpty
      disableUnderline
      value={value}
      IconComponent={()=><ExpandMoreIcon style={{color:"#f3f3f380"}}/>}
      MenuProps={{
        // classes:classes.paper,
        style: {
          marginTop: '10px',
          marginLeft: '-10px',
        },
        // PaperProps:{
        //   style:  classes.paper as any,
        // },
        PaperProps:{ className : classes.paper  },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
      renderValue={
        () => renderValue(value, placeholder)
      }>
        {listItem.data.map((e:any)=><SelectStyle onClick={()=>handleChooseItem(e)}>
          <Typography className="text">{e.attributes.group_name}</Typography>
          <Typography className="text">{e.attributes.customer_ids.length} members</Typography>
        </SelectStyle>)}
        
    </Select>
  </CustomSelectStyle>
}

const renderValue = (selected: any, message:any) => {
  if (selected !== "") {
    return <Typography className="searchText">{selected}</Typography>;
  }

  return <Typography className="searchText">{message}</Typography>;
};

const SelectStyle = styled(Box)(({ theme }) => ({
  display:'flex', 
  flexDirection:'row', 
  justifyContent:'space-between',
   padding: 16,
  '& .text': {
    cursor:'pointer',
    color: (theme as any).typography.h1,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 400,
  },
}))

const useStyles = makeStyles(theme=>({
  paper: {
    backgroundColor:(theme as any).typography.surface2,
    borderRadius:'8px',
    padding: '8px',
    height:300
  }, 
  select: {
    '& .textSelect': {
      color: '#F3F3F3', 
      fontSize: '14px', 
      fontFamily: 'Outfit', 
      fontWeight: '400',
    },
    
    '& .wrapText': {
      cursor: 'pointer',
      padding: '8px'
    },
    '& .text': {
      color: "#F3F3F3",
            fontFamily: "Outfit",
            fontSize: "16px",
            fontWeight: 400,
    },
  },
}));

const CustomSelectStyle = styled(Box)(({ theme }) => ({
  '& .text': {
    color: (theme as any).typography.h1,
          fontFamily: "Outfit",
          fontSize: "16px",
          fontWeight: 400,
  },
  '& .inputFieldServices': {
    marginTop: '4px',
    borderRadius: 8,
    border: `1px ${(theme as any).typography.border} solid`,
    background: "var(--surface, #2B2B2B)",
    padding: '10px 8px',
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    height:"32px",
  },
  "& .searchText": {
    color: (theme as any).typography.h1,
    fontSize: 14,
    fontFamily: 'Outfit', 
    fontWeight: '400', 
  },
  '& .iconX': {
    cursor: 'pointer',
    color: (theme as any).typography.h1,
  },
  '& .selectedService': {
    marginTop:'16px',
    display: 'flex',
    flexDirection:'column'
  },
  "& .inputField": {
    display: "flex",
    height: "56px",
    padding: "10px 8px 10px 8px",
    justifyContent: "flex-end",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: `1px ${(theme as any).typography.border2} solid`,
    background: (theme as any).typography.body2,
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .dropdownText": {
    color: (theme as any).typography.h1,
    fontSize: 12,
    fontFamily: 'Outfit', 
    fontWeight: '400', 
  },
}))
export default CustomSelectCustomerGroup;