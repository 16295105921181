import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  Typography,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  Dialog,
  Modal,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import AddCatalougueController, { Props } from "./AddCatalougueController.web";
import { Facebook, Instagram, Logo, Tiktok, UploadImg } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import CustomSelectSearchField from "../../../components/src/CustomSelectSearchField";
import CustomError from "../../../components/src/CustomError";
import clsx from 'clsx';
import moment from "moment";
import Calendar from "react-calendar";
import DateRangeIcon from '@material-ui/icons/DateRange';
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class AddCatalougue extends AddCatalougueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  userSchema = () => { 
    return Yup.object().shape({
      make: Yup.string().required("Make is mandatory"),
      model: Yup.string().required("Model is mandatory"),
      carName: Yup.string().required("Car Name is mandatory"),
      PurchasePrice: Yup.number()
      .typeError("Purchase price must be a number")
      .required("Purchase price is mandatory"),
      sellingPrice: Yup.number()
      .typeError("Selling price must be a number")
      .required("Selling price is mandatory"),
       depreciation: Yup.number()
      .typeError("Depreciation must be a number")
      .required("Depreciation is mandatory"),
      regDate: Yup.string().required("Reg Date is mandatory"),
       mileage: Yup.number()
      .typeError("Mileage must be a number")
      .required("Mileage is mandatory"),
       manufactured: Yup.string()
      .required("Manufactured year is mandatory")
      .matches(/^\d{4}$/, "Manufactured year must be a 4-digit number")
      .test('is-valid-year', 'Manufactured year cannot be greater than the current year', value => {
        return value <= new Date().getFullYear();
      }).test('is-not-too-early', 'The manufacturing year cannot be earlier than 1900', value => {
        return value && parseInt(value) >= 1900;
      }),
      roadTax: Yup.number()
      .typeError("Road Tax must be a number")
      .required("Road Tax is mandatory"),
      trasmission: Yup.string().required("Transmission is mandatory"),
       degree: Yup.number()
      .typeError("Degree must be a number")
      .required("Degree value is mandatory"),
      omv: Yup.number()
      .typeError("OMV must be a number")
      .required("OMV is mandatory"),
       coe: Yup.number()
      .typeError("COE must be a number")
      .required("COE is mandatory"),
       arf: Yup.number()
      .typeError("ARF must be a number")
      .required("ARF is mandatory"),
       engineCap: Yup.number()
      .typeError("Engine cap must be a number")
      .required("Engine cap is mandatory"),
       power: Yup.number()
      .typeError("Power must be a number")
      .required("Power is mandatory"),
       curbWeight: Yup.number()
      .typeError("Curb weight must be a number")
      .required("Curb weight is mandatory"),
       owner: Yup.number()
      .typeError("No. of owner must be a number")
      .required("No. of owner is mandatory"),
      vehicleType: Yup.string().required("Vehicle type is mandatory"),
    });
  };
  renderModal = () => {
    return <Modal open={this.state.openModel}>
      <ModalStyle>
        <Box className="wrapModel" >
          <Box className="wrapClose" >
            <CloseIcon className="closeIcon" onClick={this.handleCloseModal}/>
          </Box>
          <Box className="wrapLogo" >
            <img src={Logo} />
          </Box>
          <Box className="wrapLogo" >
          <Typography className="textModel">
            {this.state.make} {this.state.model} {this.state.manufactured} has been successfully {this.state.isEdit ? 'edited' : 'added'} to the catalogue!
          </Typography>
          </Box>
          <Box className="wrapButton">
            <Button
             onClick={this.handleCloseModal}
             className="button"
            >
              Ok
            </Button>
          </Box>
        </Box>
      </ModalStyle>
    </Modal>
  }

  renderChangePrice = () => {
    return <Modal open={false}>
       <ModalStyle>
        <Box className="wrapModel1">
          <Box className="wrapClose">
            <IconButton onClick={()=>{}} className="iconPadding">
              <CloseIcon className="closeIcon"/>
            </IconButton>
          </Box>
          <Box className="line"/> 
          <Box className="wrapModalContentDelete">
            <Typography className="textModel1">This car is already added to a deal, do you want to proceed with the price update?</Typography>
          </Box>
          <Box className="line"/> 
          <Box className="modalFooterWrap">
            <Button data-testId='cancalBtn' className="cancelBtn" onClick={()=>{}}>Cancel</Button>
            <Button data-testId='saveBtn' className="saveBtn" onClick={()=>{}}>Continue</Button>
          </Box> 
        </Box> 
      </ModalStyle>
    </Modal>
  }

  renderLoading = () => {
    return <Modal open={this.state.isLoading}>
      <ModalStyle>
        <Box className="wrapModal">
          <Box className="wrapModalContentLoading">
            <CircularProgress className="iconLoading" size={20} />
          </Box>
        </Box> 
      </ModalStyle>
    </Modal>
  }

  renderErrorImage = ()=>{
    return this.state.images?.length === 0 && <Typography className="errormsg">Please Add At Least 1 Vehicle Picture.</Typography>
  }

  render3000CharDes = (values: any)=>{
    return values.descripArea && <Typography className="errorColor">{values.descripArea.length} / 3000 characters</Typography>
  } 
  render3000CharFeature = (values: any)=>{
    return values.features && <Typography className="errorColor">{values.features.length} / 3000 characters</Typography>
  }  
  render3000CharAcc = (values: any)=>{
    return values.accessories && <Typography className="errorColor" data-test-id="ErrorAccessoriesId">{values.accessories.length} / 3000 characters</Typography>
  }  
  // Customizable Area End
  render() {
    const condition = !(this.state.vehicleData?.attributes?.deal_status === 'pending_approval' && this.state.userType === 'sales_manager' || !this.state.isEdit  || this.state.vehicleData?.attributes?.is_added_in_deal === false);
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <AddCatalougueStyle>
          <Box className="containerBox">
            <Formik
              data-test-id="formik"
              enableReinitialize
              initialValues={{
                make: this.state.vehicleData?.attributes?.make || '',
                model: this.state.vehicleData?.attributes?.model|| '', 
                carName: this.state.vehicleData?.attributes?.name,
                PurchasePrice: this.state.vehicleData?.attributes?.purchase_price,
                sellingPrice: this.state.vehicleData?.attributes?.selling_price ,
                depreciation: this.state.vehicleData?.attributes?.depreciation ,
                regDate: this.state.vehicleData?.attributes?.reg_date ? moment(this.state.vehicleData?.attributes?.reg_date, 'DD/MM/YYYY').format('YYYY-MM-DD') : '' ,
                mileage: this.state.vehicleData?.attributes?.mileage ,
                manufactured: this.state.vehicleData?.attributes?.manufactured_year ,
                roadTax: this.state.vehicleData?.attributes?.road_tax ,
                trasmission: this.state.vehicleData?.attributes?.transmission ,
                degree: this.state.vehicleData?.attributes?.degree_value ,
                omv: this.state.vehicleData?.attributes?.omv ,
                coe: this.state.vehicleData?.attributes?.coe ,
                arf: this.state.vehicleData?.attributes?.arf ,
                engineCap: this.state.vehicleData?.attributes?.engine_cap ,
                power: this.state.vehicleData?.attributes?.power ,
                curbWeight: this.state.vehicleData?.attributes?.curb_weight ,
                owner: this.state.vehicleData?.attributes?.no_of_owner ,
                vehicleType: this.state.vehicleData?.attributes?.vehicle_type ,
                descripArea: this.state.vehicleData?.attributes?.description ,
                features: this.state.vehicleData?.attributes?.features ,
                accessories: this.state.vehicleData?.attributes?.accessories ,
              }}
              innerRef={(formikProps) =>{
                this.formikMethods = formikProps as FormikProps<unknown>
              }}
              validationSchema={this.userSchema}
              validateOnChange={this.state.formIsDirty}
              validateOnBlur={this.state.formIsDirty}
              onSubmit={(values) => {
                this.onSubmit(values);
              }}
            >
              {({ setFieldValue, handleSubmit, values, errors }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <Typography className="title">Upload vehicle images</Typography>
            <Grid container spacing={2}>
              {this.state.images?.map((image:any, index:number) => (
                <Grid item key={index}>
                  <Box className="imageShow">
                    <Box className="closeBox">
                  <CloseIcon className="closeIcon"  id="closeIcon" onClick={() => this.handleRemoveImage(index, image)}/>
                  </Box>
                  <img  
                    src={image}
                    alt={`Uploaded ${index + 1}`}
                    className="imageShow"
                  />
                  </Box>
                </Grid>
              ))}
              {this.state.images?.length < 8 && (
              <Grid item>
              <input
                  id="imageInput"
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={this.handleImageChange}
                  data-test-id="handleImageChange"
                  multiple
                />
              <label htmlFor="imageInput">
                <Box className="imageUploder">
                  <img src={UploadImg} />
                  <Typography className="uplodText">Add Image</Typography>
                  <Typography className="uplodText">({this.state.images?.length}/8)</Typography>
                </Box>
                </label>
                {this.renderErrorImage()}
              </Grid>
              )}
            </Grid>
                  <Grid container spacing={3} className="inputGrid">
                    <Grid item xs={12} md={4}>
                      <InputLabel className="inputLabel">Make</InputLabel>
                      <CustomSelectSearchField value={values.make} placeholder={'Select'} makeList={this.state.makeModalData} isError={errors.make} 
                      closeCalendar={this.closeCalendar}
                      onChange={(e: any)=>{
                        setFieldValue('make', e.target.value);
                        this.onValueChange('make', e.target.value);
                        e.target.value !== values.make && setFieldValue('model', '');
                        this.getModelDataApi(e.target.value);
                      }}/>
                      <CustomError errors={errors.make}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel className="inputLabel">Model</InputLabel>
                      <CustomSelectSearchField value={values.model} placeholder={'Select'} modelList={this.state.dataModal} isError={errors.model} isModel 
                      closeCalendar={this.closeCalendar}
                      onChange={(e)=>{
                        setFieldValue('model', e.target.value);
                        this.onValueChange('model', e.target.value);
                      }}/>
                      <CustomError errors={errors.model}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="carName" className="inputLabel">
                        Car Name
                      </InputLabel>
                      <Input
                        data-test-id='carNameId'
                        className={clsx({'errorInput': errors.carName, 'inputField': !errors.carName},)}
                        placeholder="Enter car name"
                        disableUnderline
                        value={values.carName}
                        onChange={(event) => {
                          setFieldValue("carName", event.target.value);
                        }}
                        onPointerDown={this.closeCalendar}
                        fullWidth
                      />
                      <CustomError errors={errors.carName}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="PurchasePrice" className="inputLabel">
                        Purchase Price
                      </InputLabel>
                      <Input
                        data-test-id='PurchasePriceId'
                        className={clsx({'errorInput': errors.PurchasePrice, 'inputField': !errors.PurchasePrice},)}
                        placeholder="Enter Purchase price"
                        disableUnderline
                        value={values.PurchasePrice}
                          onChange={(event) => {
                            setFieldValue("PurchasePrice", event.target.value);
                        }}
                        onPointerDown={this.closeCalendar}
                        fullWidth
                        onKeyDown={(evnt) => this.handleNumericInput(evnt, { allowDecimal: true, maxDecimalPlaces: 2, maxValue : 9999999999.99 })}
                      />
                      <CustomError errors={errors.PurchasePrice}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="sellingPrice" className="inputLabel">
                        Selling Price
                      </InputLabel>
                      <Input
                        data-test-id='sellingPriceId'
                        className={clsx({'errorInput': errors.sellingPrice, 'inputField': !errors.sellingPrice},)}
                        placeholder="Enter Selling price"
                        disableUnderline
                        disabled={condition}
                        onPointerDown={this.closeCalendar}
                        value={values.sellingPrice}
                        onChange={(event) => {
                          setFieldValue("sellingPrice", event.target.value);
                        }}
                        fullWidth
                        onKeyDown={(evnt) => this.handleNumericInput(evnt, { allowDecimal: true, maxDecimalPlaces: 2, maxValue : 9999999999.99 })}
                      />
                      <CustomError errors={errors.sellingPrice}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="depreciation" className="inputLabel">
                        Depreciation
                      </InputLabel>
                      <Input
                        data-test-id="depreciationId"
                        className={clsx({'errorInput': errors.depreciation, 'inputField': !errors.depreciation},)}
                        placeholder="$11,400 /yr"
                        onPointerDown={this.closeCalendar}
                        disableUnderline
                        value={values.depreciation}
                        onChange={(event) => {
                          setFieldValue("depreciation", event.target.value);
                        }}
                        fullWidth
                        onKeyDown={(evnt) => this.handleNumericInput(evnt, { allowDecimal: true, maxDecimalPlaces: 2, maxValue : 9999999999.99 })}
                      />
                      <CustomError errors={errors.depreciation}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="regDate" className="inputLabel">
                        Reg Date
                      </InputLabel>
                      <button type="button"
                      data-test-id="regDate"
                      className={this.getRegDateButtonClass(values.regDate)}
                       onClick={this.toggleCalendar}> <Typography className="date">{this.renderRegDateTypography(values.regDate)}</Typography><DateRangeIcon />    </button>

                    {
                      this.state.isOpenCalendar && <Calendar
                        className="customCalendarPop"
                        data-test-id="regDateCal"
                        onChange={this.handleRegDate}
                        value={values.regDate ? new Date(values.regDate) : null}
                      />
                    }
                      <CustomError errors={errors.regDate}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="milage" className="inputLabel">
                        Milage 
                      </InputLabel>
                      <Input
                        data-test-id='milageId'
                        className={clsx({'errorInput': errors.mileage, 'inputField': !errors.mileage},)}
                        placeholder="12,500 km"
                        onPointerDown={this.closeCalendar}
                        disableUnderline
                        value={values.mileage}
                        onChange={(event) => {
                          setFieldValue("mileage", event.target.value);
                        }}
                        fullWidth
                        onKeyDown={(evnt) => this.handleNumericInput(evnt, { allowDecimal: true, maxDecimalPlaces: 2, maxValue : 9999999999.99 })}
                      />
                      <CustomError errors={errors.mileage}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="manufactured" className="inputLabel">
                        Manufactured Year
                      </InputLabel>
                      <Input
                        data-test-id='manufacturedId'
                        className={clsx({'errorInput': errors.manufactured, 'inputField': !errors.manufactured},)}
                        placeholder="2012"
                        onPointerDown={this.closeCalendar}
                        disableUnderline
                        value={values.manufactured}
                        onChange={(event) => {
                          this.onValueChange('manufactured', event.target.value);
                          setFieldValue("manufactured", event.target.value);
                        }}
                        fullWidth
                        onKeyDown={(evnt) => this.handleNumericInput(evnt, { isInteger: true, maxLength : 4})}
                      />
                      <CustomError errors={errors.manufactured}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="roadTax" className="inputLabel">
                        Road Tax
                      </InputLabel>
                      <Input
                        data-test-id="roadTaxId"
                        className={clsx({'errorInput': errors.roadTax, 'inputField': !errors.roadTax},)}
                        placeholder="$890 /yr"
                        onPointerDown={this.closeCalendar}
                        disableUnderline
                        value={values.roadTax}
                        onChange={(event) => {
                          setFieldValue("roadTax", event.target.value);
                        }}
                        fullWidth
                        onKeyDown={(evnt) => this.handleNumericInput(evnt, { allowDecimal: true, maxDecimalPlaces: 2, maxValue : 9999999999.99 })}
                      />
                      <CustomError errors={errors.roadTax}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="trasmission" className="inputLabel">
                        Transmission 
                      </InputLabel>
                      <Input
                        data-test-id="trasmissionId"
                        className={clsx({'errorInput': errors.trasmission, 'inputField': !errors.trasmission},)}
                        placeholder="Auto"
                        onPointerDown={this.closeCalendar}
                        disableUnderline
                        value={values.trasmission}
                        onChange={(event) => {
                          setFieldValue("trasmission", event.target.value);
                        }}
                        fullWidth
                      />
                      <CustomError errors={errors.trasmission}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="degree" className="inputLabel">
                        Degree Value
                      </InputLabel>
                      <Input
                        data-test-id="degreeId"
                        className={clsx({'errorInput': errors.degree, 'inputField': !errors.degree},)}
                        placeholder="N.A"
                        onPointerDown={this.closeCalendar}
                        disableUnderline
                        value={values.degree}
                        onChange={(event) => {
                          setFieldValue("degree", event.target.value);
                        }}
                        fullWidth
                        onKeyDown={(evnt) => this.handleNumericInput(evnt, { allowDecimal: true, maxDecimalPlaces: 2, maxValue : 9999999999.99 })}
                      />
                      <CustomError errors={errors.degree}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="omv" className="inputLabel">
                        OMV
                      </InputLabel>
                      <Input
                        data-test-id="omvId"
                        className={clsx({'errorInput': errors.omv, 'inputField': !errors.omv},)}
                        placeholder="$31,540"
                        onPointerDown={this.closeCalendar}
                        disableUnderline
                        value={values.omv}
                        onChange={(event) => {
                          setFieldValue("omv", event.target.value);
                        }}
                        fullWidth
                        onKeyDown={(evnt) => this.handleNumericInput(evnt, { allowDecimal: true, maxDecimalPlaces: 2, maxValue : 9999999999.99 })}
                      />
                      <CustomError errors={errors.omv}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="coe" className="inputLabel">
                        COE
                      </InputLabel>
                      <Input
                        data-test-id="coeId"
                        className={clsx({'errorInput': errors.coe, 'inputField': !errors.coe},)}
                        placeholder="$30,012"
                        onPointerDown={this.closeCalendar}
                        disableUnderline
                        value={values.coe}
                        onChange={(event) => {
                          setFieldValue("coe", event.target.value);
                        }}
                        fullWidth
                        onKeyDown={(evnt) => this.handleNumericInput(evnt, { allowDecimal: true, maxDecimalPlaces: 2, maxValue : 9999999999.99 })}
                      />
                      <CustomError errors={errors.coe}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="arf" className="inputLabel">
                        ARF
                      </InputLabel>
                      <Input
                        data-test-id="arfId"
                        className={clsx({'errorInput': errors.arf, 'inputField': !errors.arf},)}
                        placeholder="$43,401"
                        onPointerDown={this.closeCalendar}
                        disableUnderline
                        value={values.arf}
                        onChange={(event) => {
                          setFieldValue("arf", event.target.value);
                        }}
                        fullWidth
                        onKeyDown={(evnt) => this.handleNumericInput(evnt, { allowDecimal: true, maxDecimalPlaces: 2, maxValue : 9999999999.99})}
                      />
                      <CustomError errors={errors.arf}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="engineCap" className="inputLabel">
                        Engine Cap
                      </InputLabel>
                      <Input
                        data-test-id="engineCapId"
                        className={clsx({'errorInput': errors.engineCap, 'inputField': !errors.engineCap},)}
                        placeholder="1997 cc"
                        onPointerDown={this.closeCalendar}
                        disableUnderline
                        value={values.engineCap}
                        onChange={(event) => {
                          setFieldValue("engineCap", event.target.value);
                        }}
                        fullWidth
                        onKeyDown={(evnt) => this.handleNumericInput(evnt, { allowDecimal: true, maxDecimalPlaces: 1, maxValue : 99999.9})}
                      />
                      <CustomError errors={errors.engineCap}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="power" className="inputLabel">
                        Power
                      </InputLabel>
                      <Input
                        data-test-id="powerId"
                        className={clsx({'errorInput': errors.power, 'inputField': !errors.power},)}
                        placeholder="160.0 kw (205bhp)"
                        onPointerDown={this.closeCalendar}
                        disableUnderline
                        value={values.power}
                        onChange={(event) => {
                          setFieldValue("power", event.target.value);
                        }}
                        fullWidth
                        onKeyDown={(evnt) => this.handleNumericInput(evnt, { allowDecimal: true, maxDecimalPlaces: 1, maxValue : 999999.9})}
                      />
                      <CustomError errors={errors.power}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="curbWeight" className="inputLabel">
                        Curb Weight
                      </InputLabel>
                      <Input
                        data-test-id="curbWeightId"
                        className={clsx({'errorInput': errors.curbWeight, 'inputField': !errors.curbWeight},)}
                        placeholder="1,500 kg"
                        onPointerDown={this.closeCalendar}
                        disableUnderline
                        value={values.curbWeight}
                        onChange={(event) => {
                          setFieldValue("curbWeight", event.target.value);
                        }}
                        fullWidth
                        onKeyDown={(evnt) => this.handleNumericInput(evnt, { allowDecimal: true, maxDecimalPlaces: 2, maxValue : 9999999999.99})}
                      />
                      <CustomError errors={errors.curbWeight}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="owner" className="inputLabel">
                        No. Of Owner
                      </InputLabel>
                      <Input
                        data-test-id="ownerId"
                        className={clsx({'errorInput': errors.owner, 'inputField': !errors.owner},)}
                        placeholder="1"
                        onPointerDown={this.closeCalendar}
                        disableUnderline
                        value={values.owner}
                        onChange={(event) => {
                          setFieldValue("owner", event.target.value);
                        }}
                        fullWidth
                        onKeyDown={(evnt) => this.handleNumericInput(evnt, { isInteger: true, maxValue : 999})}
                      />
                      <CustomError errors={errors.owner}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="vehicleType" className="inputLabel">
                        Vehicle Type
                      </InputLabel>
                      <Input
                        data-test-id="vehicleTypeId"
                        className={clsx({'errorInput': errors.vehicleType, 'inputField': !errors.vehicleType},)}
                        placeholder="Sport Sedan"
                        onPointerDown={this.closeCalendar}
                        disableUnderline
                        value={values.vehicleType}
                        onChange={(event) => {
                          setFieldValue("vehicleType", event.target.value);
                        }}
                        fullWidth
                      />
                      <CustomError errors={errors.vehicleType}/>
                    </Grid>
                    
                    <Grid item xs={12} md={12}>
                      <InputLabel htmlFor="descripArea" className="inputLabel">
                        Description
                      </InputLabel>
                      <TextareaAutosize
                        data-test-id="descripAreaId"
                        minRows={9}
                        maxRows={9}
                        className='textArea'
                        maxLength={3000}
                        value={values.descripArea}
                        onPointerDown={this.closeCalendar}
                        onChange={(event) => {
                          setFieldValue("descripArea", event.target.value);
                        }}
                      />
                      {this.render3000CharDes(values)}
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <InputLabel htmlFor="features" className="inputLabel">
                        Features
                      </InputLabel>
                      <TextareaAutosize
                        data-test-id="featuresId"
                        minRows={9}
                        maxRows={9}
                        maxLength={3000}
                        className="textArea"
                        value={values.features}
                        onPointerDown={this.closeCalendar}
                        onChange={(event) => {
                          setFieldValue("features", event.target.value);
                        }}
                      />
                      {this.render3000CharFeature(values)}
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <InputLabel htmlFor="accessories" className="inputLabel">
                        Accessories
                      </InputLabel>
                      <TextareaAutosize
                        data-test-id="AccessoriesId"
                        minRows={9}
                        maxRows={9}
                        maxLength={3000}
                        className="textArea"
                        value={values.accessories}
                        onPointerDown={this.closeCalendar}
                        onChange={(event) => {
                          setFieldValue("accessories", event.target.value);
                        }}
                      />
                      {this.render3000CharAcc(values)}
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography className="shareTitle">Share To</Typography>
                      <Box className="shareBox">
                        
                          <Box 
                            className={clsx({'shareIcon': !this.state.isShareToFacebook, 'shareIconSelected': this.state.isShareToFacebook})}
                            onClick={this.handleSelectFacebook}
                          >
                            <img src={Facebook} />
                          </Box>
                    
                          <Box 
                            className={clsx({'shareIcon': !this.state.isShareToInstagram, 'shareIconSelected': this.state.isShareToInstagram})}
                            onClick={this.handleSelectInstagram}
                          >
                            <img src={Instagram} />
                          </Box>
                        
                          <Box className={clsx({'shareIcon': !this.state.isShareToTiktok, 'shareIconSelected': this.state.isShareToTiktok})}>
                            <img src={Tiktok} />
                          </Box>
                       
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} className="submitBtn">
                      <Button
                        // data-test-id="add-btn-id"
                        type="submit"
                        variant="contained"
                        className="addBtn"
                        color="secondary"
                        onClick={this.checkSubmit}
                      >
                        {this.state.isEdit ? 'Edit' : 'Add'}
                      </Button>
                      <Button
                        variant="contained"
                        className="cancelBtn"
                        color="secondary"
                        onClick={this.handleCancel}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
          {this.renderModal()}
          {this.renderChangePrice()}
          {this.renderLoading()}
        </AddCatalougueStyle>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const ModalStyle = styled(Box)(({ theme } ) => ({
  '& .wrapModalContentLoading': {
    justifyContent: 'center',
    padding: '40px',
    alignItems:'center',
    width:'100%',
    display:'flex',
    flexDirection:'column'
  },
  '& .wrapModal': {
    borderRadius: "16px",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',    
    '@media (max-width: 1000px) and (orientation: landscape)': {
      height: 200,
      overflow:'auto',
      minWidth:'70% !important',
    },
    '@media (max-width: 600px)': {
      overflow:'auto',
      minWidth:'70% !important',
    },
    backgroundColor:(theme as any).typography.body2,
    minWidth:'40%',
  },
  '& .iconLoading': {
    color:'#CEAE71', 
    alignSelf:'center'
  },
  '& .saveBtn': {
    marginLeft: "16px",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    borderRadius: "8px",
    color: "#171717",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
    height: "56px"
  },
  '& .cancelBtn': {
    backgroundColor:(theme as any).typography.surface2, 
    borderRadius: "8px",
    color: "#CEAE71",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
    height: "56px"
  },
  '& .line': {
    height:"1px", 
    backgroundColor:(theme as any).typography.line,
    marginTop:16,
    marginBottom:16,
  },
  '& .wrapModalContentDelete': {
    padding: '40px',
    maxHeight:'400px',
    backgroundColor: (theme as any).typography.body2,
  },
  '& .modalFooterWrap': {
    display: "flex",
    justifyContent: "flex-end",
    padding: "24px"   
  },
  "& .wrapModel":{
    position: 'absolute' as 'absolute',
  top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    backgroundColor:(theme as any).typography.body2,
    borderRadius: "16px",
    minWidth:'40%',
    padding:10
  },
  "& .wrapModel1":{
    position: 'absolute' as 'absolute',
  top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    backgroundColor:(theme as any).typography.body2,
    borderRadius: "16px",
    minWidth:'40%',
  },

  "& .closeIcon":{
    color: (theme as any).typography.h1, 
  },
  "& .wrapClose":{
    cursor:'poiter',
    display: 'flex', justifyContent: 'end',
    marginTop:20,
    marginRight:20
  },
  "& .wrapLogo":{display: 'flex', justifyContent: 'center', marginBottom: '60px'},
  "& .textModel":{
    color: (theme as any).typography.h1, 
  fontFamily: "Outfit",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  marginBottom: '40px',
  textAlign:'center'
},
"& .textModel1":{
  color: (theme as any).typography.h1, 
fontFamily: "Outfit",
fontSize: "20px",
fontStyle: "normal",
fontWeight: 400,
textAlign:'center'
},
  "& .wrapButton":{display: 'flex', justifyContent: 'center',marginBottom:20},
  "& .button":{
    height: '55px',
    width: '150px',
    color: "#CEAE71",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    borderRadius: "8px",
    background: "#3F3E3A",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)"
  },


}));
const AddCatalougueStyle = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  "& .errormsg":{
  color: "var(--Status-Red-400, #F87171)",
  fontFamily: "Outfit",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  },
  "& .MuiSelect-icon": {
    color: "white",
  },
  "& .containerBox": {
    padding: "30px",
  },

  "& .title": {
    marginBottom: "20px",
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  "& .MuiInputBase-input":{
    fontFamily: "Outfit",
    fontSize: 16,
    fontWeight: 400,
    color: (theme as any).typography.h1, 
  },
  "& .imageUploder": {
    cursor: "pointer",
    borderRadius: "8px",
    background: (theme as any).typography.body2, 
    display: "flex",
    width: "150px",
    height: "150px",
    padding: "10px var(--border-radius, 16px)",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  "& .imageShow":{
    cursor: "pointer",
    borderRadius: "8px",
    width: "150px",
    height: "150px",
  },
  "& .uplodText": {
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textAlign: "center",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .uplodInput": {
    display: "none",
  },
  "& .inputGrid": {
    marginTop: "32px",
  },
  "& .inputLabel": {
    marginBottom: "5px",
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .inputField": {
    display: "flex",
    height: "56px",
    padding: "10px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: `1px ${(theme as any).typography.border} solid`,
    background: (theme as any).typography.body2, 
    color: "white",
    width:'100% !important'
  },
  '& .errorInput': {
    display: "flex",
    height: "56px",
    padding: "10px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: (theme as any).typography.body2, 
    color: "white",
    border: "1px solid #F87171",
  },
  "& .inputFieldSelect": {
    display: "flex",
    height: "56px",
    padding: "10px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    border: "1px solid  #CBD5E1",
    background: (theme as any).typography.body2, 
    color: (theme as any).typography.h3, 
  },

  "& .textArea": {
    display: "flex",
    width: "100%",
    height: "150px",
    padding: "10px 8px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid  #CBD5E1",
    background: (theme as any).typography.body2, 
    color: (theme as any).typography.h3, 
    fontFamily: "Outfit",
  },
  "& .errorColor":{
    marginTop:'10px',
    // color: "#F87171",
    color : 'white',
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .shareTitle": {
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  "& .shareBox": {
    display: "flex",
    gap: "16px",
    marginTop: "12px",
  },
  "& .shareIcon": {
    display: "flex",
    width: "56px",
    height: "56px",
    padding: "10px var(--border-radius, 16px)",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "8px",
    background: '#2b2b2b'
  },
  "& .shareIconSelected": {
    display: "flex",
    width: "56px",
    height: "56px",
    padding: "10px var(--border-radius, 16px)",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "8px",
    background: '#2b2b2b',
    border:'1px solid #CEAE71'
  },
  "& .submitBtn": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "40px",
  },
  "& .addBtn": {
    display: "flex",
    padding: "8px",
    width: "300px",
    height: "56px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flexShrink: 0,
    borderRadius: "4px",
    background:
      "linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%)",
    color: "#171717",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .cancelBtn": {
    borderRadius: "8px",
    background: "#3F3E3A",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
    display: "flex",
    width: "300px",
    height: "56px",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
    color: "#CEAE71",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .MuiDialog-paperWidthSm":{
    background:"transparent",
    borderRadius:"20px"
  },
  "& .closeIcon":{
    color:"#FCE49C",
    position: "absolute",
    marginTop: 10,
    marginRight: 10
  },
  "& .closeBox":{
    display: 'flex',
    justifyContent:'end',
  },
  '& .customCalendarPop': {
    border: `1px solid ${(theme as any).typography.border}`,
    background: (theme as any).typography.body2,
    marginTop: '1rem',
    color: (theme as any).typography.h1,
    // Add any other custom styles as needed
    display: 'inline-flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    borderRadius: '8px',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    position: "absolute",
    left: "1rem",
    zIndex: 4,
    '@media (max-width: 600px)': {
      left: "0rem",
    },
  },
  '& .customButton': {
    display: 'inline-flex',
    height: '3.4rem',
    width: "100%",
    marginTop: ".3rem",
    padding: '6px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '6px',
    flexShrink: 0,
    borderRadius: '8px',
    border: `1px solid ${(theme as any).typography.border}`,
    background: (theme as any).typography.body2,
    position: 'relative',
    color: (theme as any).typography.h2 ,
    zIndex: 1,
  },
  '@media (max-width: 480px)': {


    '& .customCalendarPop': {
        padding: 0,
        fontSize: 12,
    },

    "& .rangeCalendar ": {
        top: "125%",


        left: "-12.3rem",
        width: "270px",
        position: "absolute",
    },
    // Add more styles as needed for small screens
},

  '& .react-calendar__tile--now': {
    background: (theme as any).typography.body1,
    opacity: 9,
  },

  '& .customButtonPLACEHOLDER': {
    display: 'inline-flex',
    height: '3.4rem',
    width: "100%",
    marginTop: ".3rem",
    padding: '6px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '6px',
    flexShrink: 0,
    borderRadius: '8px',
    border: `1px solid ${(theme as any).typography.border}`,
    background: (theme as any).typography.body2,
    position: 'relative',
    color: "gray" ,
    zIndex: 1,
  },
  '& .date': {
    fontFamily: "Outfit",
    fontSize: 16,
    color: (theme as any).typography.h2,
    fontWeight:400
  },


  '& .react-calendar__tile': {
  },
  '& .react-calendar__month-view__weekdays__weekday': {
    color: (theme as any).typography.h1,
  },
  '& .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus': {
    background: (theme as any).typography.body1,
    color: `${(theme as any).typography.h1} !important`,
  },
  "& .react-calendar__navigation__next2-button": {
    display: "none"
  },
  '& .react-calendar__tile--hover': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%)) !important',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important"
    }
  },
  '& .react-calendar__tile react-calendar__tile--active react-calendar__year-view__months__month': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
    color: "#171717 !important",
    borderRadius: '0',
    '&:first-of-type': {
      borderTopLeftRadius: '35px',
      borderBottomLeftRadius: '35px',
    },
    '& abbr': {
      color: "#171717 !important",
    },
    
    '&:last-of-type': {
      borderTopRightRadius: '35px',
      borderBottomRightRadius: '35px',
    },
  },
  '& .react-calendar__tile--hasActive': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important",
    },
    borderRadius: '0',
    '&:last-of-type': {
      borderTopRightRadius: '35px',
      borderBottomRightRadius: '35px',
    },
    '&:first-of-type': {
      borderTopLeftRadius: '35px',
      borderBottomLeftRadius: '35px',
    },
  },
  '& .react-calendar, .react-calendar *, .react-calendar ::before, .react-calendar ::after': {
    boxSizing: 'border-box',
    color: (theme as any).typography.h1,
  },
  '& .react-calendar__navigation__prev2-button  ': { display: "none" },
  '& .react-calendar__navigation button:enabled:focus': {
    background: (theme as any).typography.body1,
  },
  '& .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus': {
    background: (theme as any).typography.body1,
  },
  '& .react-calendar__tile--active': {
    background: 'var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))',
    color: "#171717 !important",
    '& abbr': {
      color: "#171717 !important",
    },
    borderRadius: '0',
    '&:first-of-type': {
      borderTopLeftRadius: '35px',
      borderBottomLeftRadius: '35px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '35px',
      borderBottomRightRadius: '35px',
    },
  },
  '& .react-calendar__navigation': {
    display: 'flex',
    height: '44px',
    marginBottom: '1em',
    width: '100%',
    color: (theme as any).typography.h1,
  },
}));
// Customizable Area End
