import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Modal,
  Typography,
  withWidth,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import clsx from 'clsx';
import { styled, withStyles } from "@material-ui/core/styles";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import { Close } from "./assets";
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import CustomPagination from "../../../components/src/CustomPagination";

// Customizable Area End

import CustomerGroupController, {
  Props,
} from "./CustomerGroupController";

export default class CustomerGroup extends CustomerGroupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderModal = () => {
    return <Modal open={this.state.openModal}>
      <ModalStyleCustomerGroup>
        <Box className="wrapModal">
          <Box className="modalHeaderWrap">
            <Typography className="modalHeaderText">Delete Customer group</Typography>
            <IconButton onClick={this.handleModalDeleteCustomerGroup} className="iconPadding">
              <img src={Close}/>
            </IconButton>
          </Box>
          <Box className="line"/> 
          <Box className="wrapModalContent">
            <Typography className="deleteMsg">Are you sure you want to delete customer group.</Typography>
          </Box>
          <Box className="line"/> 
          <Box className="modalFooterWrap">
            <Button data-test-id='cancalBtn' className="cancelBtn" onClick={this.handleModalDeleteCustomerGroup}>Cancel</Button>
            <Button data-test-id='saveBtn' className="saveBtn" onClick={this.handleDeleteCustomerGroup}>Delete</Button>
          </Box> 
        </Box> 
      </ModalStyleCustomerGroup>
    </Modal>
  }
  // Customizable Area End

  render() { 
    const {width} = this.props;  
    return (
      // Customizable Area Start
      <>
        {this.renderModal()}
        <CustomerGroupsStyle>
          <Grid container className={clsx({"padding30": width!=='xs', 'padding30xs': width == 'xs'})}>
            <Grid container item>
              <Grid item xs={12} md={10} className="tabContainer">
                <Box className="tab">
                  <Box data-test-id='crmBtn' className="tabTextWrap" onClick={this.handleNavigateCRM}>
                    <Typography className="tabText">CRM</Typography>
                  </Box>
                  <Box className="gap"/>
                  <Box data-test-id='overviewBtn' className="activeTab">
                    <Typography className="activeText">Customer Group</Typography>
                  </Box>
                  <Box className="gap"/>
                  <Box data-test-id='overviewBtn' className="tabTextWrap" onClick={this.handleNavigateBroadcast}>
                    <Typography className="tabText">Broadcast</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs md className="wrapBtnAdd">
                <Box data-test-id={'createCustomerGroup'} className="btnWrap" onClick={this.handleCreateNewCustomerGroup}>
                  <Typography className="btnTextApply">Create a Customer Group</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className="wrapTitle">
              <Typography className='titleText'>Customer Groups</Typography>
            </Grid>
            <Grid item xs={12} md={12} className="wrapTitle">
              {this.state.listGroup.map((item: { attributes: any; })=>
                <Box className="wrapItem">
                  <Box className="groupText">
                    <Box className="wrapName">
                      <Typography className='itemText'>{item.attributes.group_name}</Typography>
                    </Box>
                    <Typography className='itemText'>{item.attributes.customer_ids.length} Members</Typography>
                  </Box>
                  <MoreVertIcon className='icon' onClick={(e)=>this.handleOpenEditMenuCustomerGroup(e, item)}/>
                  <StyledMenuCustomerGroup
                    id="simple-menu"
                    anchorEl={this.state.anchorElCustomerGroup}
                    keepMounted
                    open={Boolean(this.state.anchorElCustomerGroup)}
                    onClose={this.handleCloseEditMenuCustomerGroup}
                  >
                    <MenuItem onClick={this.handleEditCustomerGroup}>
                      <ListItemIcon>
                        <EditIcon style={{color:"#F3F3F3"}}/>
                      </ListItemIcon>
                      <ListItemText primary="Edit" style={{color: '#F3F3F3', fontSize: 12, fontFamily: 'Outfit', fontWeight: 400,}}/>
                    </MenuItem>
                   <MenuItem onClick={this.handleModalDeleteCustomerGroup}>
                      <ListItemIcon>
                        <DeleteIcon style={{color:"#F3F3F3"}}/>
                      </ListItemIcon>
                      <ListItemText primary="Delete" style={{color: '#F3F3F3', fontSize: 12, fontFamily: 'Outfit', fontWeight: 400,}}/>
                    </MenuItem>
                  </StyledMenuCustomerGroup>
                </Box>
              )}
              <CustomPagination page={this.state.currentPageCustomerGroup} count={this.state.pageCustomerGroup} onChange={this.handleChangePageCustomerGroup}/>
            </Grid>

            
          </Grid>
        </CustomerGroupsStyle>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ModalStyleCustomerGroup = styled(Box)(({ theme }) => ({
  '& .wrapModal': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    backgroundColor:(theme as any).typography.body2,
    borderRadius: "16px",
    minWidth:'40%'
  },
  '& .wrapModalContent': {
    padding: '40px'
  },
  '& .line': {
    height:"1px", 
    backgroundColor:"#FFF6DC30",
  },
  '& .modalHeaderText': {
    color: (theme as any).typography.h1,
    fontSize:"24px",
    fontFamily: "Outfit",
    fontWeight: 700,
  }, 
  '& .modalContentWrap': {
    paddingTop: "40px",
    paddingBottom: "40px",
    paddingLeft: "100px",
    paddingRight: "100px",
  },
  '& .modalFooterWrap': {
    display: "flex",
    justifyContent: "flex-end",
    padding: "24px"   
  },
  "& .deleteMsg":{
    color: '#F87171', 
    fontSize: 16, 
    fontFamily: 'Outfit', 
    fontWeight: '400',
  },
  '& .cancelBtn': {
    backgroundColor:"#3F3E3A",
    borderRadius: "8px",
    color: "#CEAE71",
    fontSize: "16px",
    fontFamily: "Outfit",    
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
    height: "56px"
  },
  '& .modalHeaderWrap': {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "24px"   
  },
  '& .saveBtn': {
    marginLeft: "16px",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    borderRadius: "8px",
    color: "#171717",
    fontFamily: "Outfit",
    fontSize: "16px",
    height: "56px",
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
  },
}));
const StyledMenuCustomerGroup = withStyles({
  paper: {
    borderRadius: '8px',
    backgroundColor: '#3F3E3A'
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    {...props}
  />
));

const CustomerGroupsStyle  = styled(Box)(({ theme }) => ({
  '& .icon':{
    color:(theme as any).typography.h2,
  },
  '& .wrapName':{
    width: '50px'
  },
  '& .itemText':{
    color: (theme as any).typography.h1,
    fontSize: 16,
    fontFamily: 'Outfit', 
    fontWeight: '400'
  },
  '& .groupText':{
    display:'flex',
    flexDirection:'row', 
    gap:60
  },
  '& .wrapItem':{
    backgroundColor: (theme as any).typography.body2,
    display:'flex',
    flexDirection:'row', 
    justifyContent:'space-between', 
    padding:'16px', 
    marginBottom:'16px', 
    borderRadius:'8px'
  },
  '& .titleText':{
    color: (theme as any).typography.h1,
    fontSize: 18, 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  '& .wrapTitle':{
    marginTop:'16px'
  },
  '& .padding30':{
    padding: "30px",
  },
  '& .padding30xs':{
    width: 'fit-content',
    padding: "30px",
  },
  "& .tabContainer":{
    width:'fit-content', 
  },  
  '& .tab': {
    display: "flex",
    borderRadius: "8px",
    padding: "10px",
    backgroundColor: (theme as any).typography.body2,
    width: "fit-content",
    border: '1px rgba(255, 246, 220, 0.30) solid',
  },
  '& .tabTextWrap': {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center',
    cursor: 'pointer',
    padding: "8px",
    borderRadius: "4px",
  },
  '& .tabText': {
    color: (theme as any).typography.h1,
    fontFamily: "Outfit",
    fontSize:"18px",
    fontWeight: 700,
    '@media (max-width: 600px)': {
      fontSize: 10,
    },
  },
  '& .gap': {
    width:"24px"
  },
  '& .activeTab': {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center',
    padding: "8px",
    borderRadius: "4px",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))"
  },
  '& .activeText': {
    color: "#171717",
    fontFamily: "Outfit",
    fontSize:"18px",
    fontWeight: 700,
    '@media (max-width: 600px)': {
      fontSize: 10,
    },
  },
  "& .wrapBtnAdd": { 
    width:'100%', 
    height:'fit-content', 
    paddingTop: 10
  },
  "& .btnTextApply":{
    color: '#171717', 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '700'
  },
  "& .btnWrap":{
    cursor: 'pointer',
    paddingTop: "10px", 
    paddingBottom: "10px", 
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',
    width: '100%',
    maxWidth: '327px'
  },
}))
export {CustomerGroup}
withWidth()(CustomerGroup);
// Customizable Area End