import React, {  } from "react";

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    BlobProvider,
    Image,
    Font} from "@react-pdf/renderer";
import moment from "moment";

const BoldFont = require("./fonts/TimesNewRomanBold.ttf");

type Props = {
    isSave: boolean;
    setPdf: any;
    data: any;
};

Font.register({
    family: 'Bold1',
    src: BoldFont,
    fontWeight: 'bold',
    format: "truetype",
});

Font.register({
    family: 'Regular1',
    src: require('./fonts/TimesNewRoman400.ttf'),
    fontWeight: 'normal',
});
Font.register({
    family: 'BoldArialNarrow',
    src: require('./fonts/arialnarrow_bold.ttf'),
    fontWeight: 'bold',
    format: "truetype",
});

Font.register({
    family: 'RegularArialNarrow',
    src: require('./fonts/arialnarrow.ttf'),
    fontWeight: 'normal',
});

const styles = StyleSheet.create({
    text: {
      fontFamily: 'Regular1', 
      fontSize:6.5,
    },
    textArialNormal: {
        fontFamily: 'RegularArialNarrow', 
        fontSize:11,
      },
      textArialBold: {
        fontFamily: 'BoldArialNarrow', 
        fontSize:10,
      },
      textArialBold11: {
        fontFamily: 'BoldArialNarrow', 
        fontSize:11,
      },
    text10: {
      fontFamily: 'Regular1', 
      fontSize:10,
    },
    textBold17: {
      fontFamily: 'Bold1', 
      fontSize:17,
    },
    textBold12: {
      fontFamily: 'Bold1', 
      fontSize:12,
    },
    textBold13: {
      fontFamily: 'Bold1', 
      fontSize:13,
    },
    textBold10: {
      fontFamily: 'Bold1', 
      fontSize:9,
    },
    textBold6: {
        fontFamily: 'Bold1', 
        fontSize:6.5,
      },
  });
  




const pStyles = StyleSheet.create({
    
    page: {
        backgroundColor: "#ffffff",
        padding: '20px 23px',
        letterSpacing: 0
    },

    page2: {
        backgroundColor: "#ffffff",
        padding: '74px 74px',
        letterSpacing: 0
    },
    page3: {
        backgroundColor: "#ffffff",
        padding: '49px 65.85px',
        letterSpacing: 0
    },
});
 
const Doc1 = ({data, dob, dobGuarantor}: any)=>(<>
    <Document>
        <Page size="A4" style={pStyles.page}>
            <View style={{flexDirection:'row', display:'flex', alignItems:'flex-end', justifyContent:'space-between'}}>
                <Image src={require("../src/Moneymax.png")} style={{width:155, height:39}}/>
                <Text style={styles.textBold17}>Moneymax Leasing Pte. Ltd.</Text>
            </View>
            <View style={{flexDirection:'row', display:'flex', alignItems:'flex-end', justifyContent:'space-between', marginTop:14, padding:'0px 10px'}}>
                <Text style={styles.textBold13}>VEHICLE FINANCE APPLICATION FORM</Text>
                <Text style={styles.textBold12}>☐       Consumer</Text>
                <Text style={styles.textBold12}>☐      Corporate</Text>
            </View>
            <View style={{border:'1px solid #000',  marginTop:0.31}}>
                <View style={{backgroundColor:'#FFFf00', padding:'1px 6px', borderBottom:'1px solid #000'}}>
                    <Text style={styles.textBold10}>My/Our Info</Text>
                </View>
                <View style={{borderBottom:'1px solid #000', width:'100%', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000'}}>
                        <Text style={styles.textBold10}>Hirer NRIC / UEN:  {data.NRIC}</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px',}}>
                        <Text style={styles.textBold10}>Guarantor NRIC / UEN:   {data.guarantor[0] && data.guarantor[0].NRICGuarantor}</Text>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000'}}>
                        <Text style={styles.textBold10}>Name (as in NRIC/ ACRA)</Text>
                        <Text style={styles.textBold10}> </Text>
                        <Text style={styles.textBold10}>{data.fullName}</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px'}}>
                        <Text style={styles.textBold10}>Name (as in NRIC/ ACRA)</Text>
                        <Text style={styles.textBold10}>{data.guarantor[0] && data.guarantor[0].fullNameGuarantor}</Text>
                        <Text style={styles.textBold10}>Relationship:</Text>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000'}}>
                        <Text style={styles.textBold10}>☐      Singaporean ☐  ☐      SPR:________   ☐      Others:</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px'}}>
                        <Text style={styles.textBold10}>☐      Singaporean ☐  ☐      SPR:________   ☐      Others:</Text>
                        
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000'}}>
                        <Text style={styles.textBold10}>Registered Address</Text>
                        <Text style={styles.textBold10}>{data.address}</Text>
                        <Text style={{...styles.textBold10, alignSelf:'flex-end'}}>S(                      )</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px'}}>
                        <Text style={styles.textBold10}>Registered Address</Text>
                        <Text style={styles.textBold10}>{data.guarantor[0] && data.guarantor[0].addressGuarantor}</Text>
                        <Text style={{...styles.textBold10, alignSelf:'flex-end'}}>S(                      )</Text>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000'}}>
                        <Text style={styles.textBold10}>Ownership     ☐    Owned      ☐      Rented        ☐     Others:</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px'}}>
                        <Text style={styles.textBold10}>Ownership     ☐    Owned      ☐      Rented        ☐     Others:</Text>
                        
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000', justifyContent:'space-between', flexDirection:'row'}}>
                    <View style={{width:'60%', justifyContent:'space-between', flexDirection:'row'}}>
                        <Text style={styles.textBold10}>Contact Numbers</Text>
                        <Text style={styles.textBold10}>Work</Text></View>
                        <Text style={styles.textBold10}>{data.contactNumber} Mobile</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px', flexDirection:'row', justifyContent:'space-between'}}>
                    <View style={{width:'60%', justifyContent:'space-between', flexDirection:'row'}}>
                        <Text style={styles.textBold10}>Contact Numbers</Text>
                        <Text style={styles.textBold10}>Work</Text></View>
                        <Text style={styles.textBold10}>{data.guarantor[0] && data.guarantor[0].contactNumberGuarantor} Mobile</Text>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000'}}>
                        <Text style={styles.textBold10}>Are you licensed to drive in Singapore?     ☐    Yes   ☐      No</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px'}}>
                        <Text style={styles.textBold10}>Are you licensed to drive in Singapore?     ☐    Yes   ☐      No</Text>
                        
                    </View>
                </View>
                <View style={{backgroundColor:'#FFFf00', padding:'1px 6px', borderBottom:'1px solid #000'}}>
                    <Text style={styles.textBold10}>My/Our Employment</Text>
                </View>
                <View style={{borderBottom:'1px solid #000', width:'100%', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000'}}>
                        <Text style={styles.textBold10}>Employer / Job Title</Text>
                        <Text style={styles.textBold10}> </Text>
                        <Text style={styles.textBold10}>{data.employer } / {data.jobTitle}</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px',}}>
                        <Text style={styles.textBold10}>Employer / Job Title</Text>
                        <Text style={styles.textBold10}> </Text>
                        <Text style={styles.textBold10}>{data.guarantor[0] && data.guarantor[0].employerGuarantor} / {data.guarantor[0] && data.guarantor[0].jobTitleGuarantor}</Text>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000'}}>
                        <Text style={styles.textBold10}>Address</Text>
                        <Text style={styles.textBold10}>{data.employmentAddress}</Text>
                        <Text style={{...styles.textBold10, alignSelf:'flex-end'}}>S(                      )</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px'}}>
                        <Text style={styles.textBold10}>Address</Text>
                        <Text style={styles.textBold10}>{data.guarantor[0] && data.guarantor[0].employmentAddressGuarantor}</Text>
                        <Text style={{...styles.textBold10, alignSelf:'flex-end'}}>S(                      )</Text>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000'}}>
                        <Text style={styles.textBold10}>Length of Service                            Yrs                          Mths</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px'}}>
                        <Text style={styles.textBold10}>Length of Service                            Yrs                          Mths</Text>
                        
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000', flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'100%'}}>☐  Employee</Text>
                        <View style={{width:'100%', flexDirection:'row'}}>
                            <Text style={styles.textBold10}>☐  </Text>
                            <Text style={styles.textBold10}>Self Employed /   Comm Based</Text>
                        </View>
                        <View style={{width:'100%',flexDirection:'row'}}>
                            <Text style={styles.textBold10}>☐  </Text>
                            <Text style={styles.textBold10}>Others: ______________</Text>
                        </View>
                        
                    </View>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000', flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'100%'}}>☐  Employee</Text>
                        <View style={{width:'100%', flexDirection:'row'}}>
                            <Text style={styles.textBold10}>☐  </Text>
                            <Text style={styles.textBold10}>Self Employed /   Comm Based</Text>
                        </View>
                        <View style={{width:'100%',flexDirection:'row'}}>
                            <Text style={styles.textBold10}>☐  </Text>
                            <Text style={styles.textBold10}>Others: ______________</Text>
                        </View>
                        
                    </View>
                </View>
                <View style={{backgroundColor:'#FFFf00', padding:'1px 6px', borderBottom:'1px solid #000'}}>
                    <Text style={styles.textBold10}>My/Our Commitments</Text>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000',flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'100%'}}>Gross Monthly Salary</Text>
                        <Text style={{...styles.textBold10, width:'100%'}}>S$</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px',flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'100%'}}>Gross Monthly Salary</Text>
                        <Text style={{...styles.textBold10, width:'100%'}}>S$</Text>
                        
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000',flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'100%'}}>Other Income / Source</Text>
                        <Text style={{...styles.textBold10, width:'100%'}}>S$</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px',flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'100%'}}>Other Income / Source</Text>
                        <Text style={{...styles.textBold10, width:'100%'}}>S$</Text>
                        
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000',flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'100%'}}>Mthly Financial Commitments</Text>
                        <Text style={{...styles.textBold10, width:'100%'}}>S$                                        (Total)</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px',flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'100%'}}>Mthly Financial Commitments</Text>
                        <Text style={{...styles.textBold10, width:'100%'}}>S$                                        (Total)</Text>
                         
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000',flexDirection:'column'}}>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{...styles.textBold10, width:'100%'}}>CREDIT CARDS</Text>
                            <Text style={{...styles.textBold10, width:'100%'}}>S$           __________________</Text>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{...styles.textBold10, width:'100%'}}>LOAN</Text>
                            <Text style={{...styles.textBold10, width:'100%'}}>S$           __________________</Text>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{...styles.textBold10, width:'100%'}}>OTHERS (State:___________)</Text>
                            <Text style={{...styles.textBold10, width:'100%'}}>S$           __________________</Text>
                        </View>
                        
                    </View>
                    <View style={{width:'100%', padding:'1px 6px', flexDirection:'column'}}>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{...styles.textBold10, width:'100%'}}>CREDIT CARDS</Text>
                            <Text style={{...styles.textBold10, width:'100%'}}>S$           __________________</Text>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{...styles.textBold10, width:'100%'}}>LOAN</Text>
                            <Text style={{...styles.textBold10, width:'100%'}}>S$           __________________</Text>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            <Text style={{...styles.textBold10, width:'100%'}}>OTHERS (State:___________)</Text>
                            <Text style={{...styles.textBold10, width:'100%'}}>S$           __________________</Text>
                        </View>
                        
                    </View>
                </View>
                <View style={{backgroundColor:'#FFFf00', padding:'1px 6px', borderBottom:'1px solid #000'}}>
                    <Text style={styles.textBold10}>My Vehicle</Text>
                </View>
                <View style={{padding:'1px 6px', borderBottom:'1px solid #000', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                    <Text style={styles.textBold10}>Type of Vehicle</Text>
                    <Text style={styles.textBold10}>☐       New</Text>
                    <View>
                        <Text style={{...styles.textBold10, position:'absolute', left:1}}>x</Text>
                        <Text style={styles.textBold10}>☐       Used</Text>
                    </View>
                    <Text style={styles.textBold10}>☐       COE</Text>
                    <Text style={styles.textBold10}>☐       Commercial</Text>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000'}}>
                        <Text style={styles.textBold10}>Is this vehicle used for Private Hire?        ☐      Yes  ☐      No</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px'}}>
                        <Text style={styles.textBold10}>Is this vehicle used for Private Hire?        ☐      Yes  ☐      No</Text>
                    </View>
                </View>
                <View style={{padding:'1px 6px', borderBottom:'1px solid #000'}}>
                    <Text style={styles.textBold10}>Who will be the Primary Driver of the Vehicle?          ☐     Applicant        ☐      Others:______________(Relationship)</Text>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000',flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'100%'}}>Make {data.vehicleMake}</Text>
                        <Text style={{...styles.textBold10, width:'100%'}}>Model {data.vehicleModel}</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px',flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'100%'}}>Car Reg. No. {data.vehicleNumber}</Text>
                        <Text style={{...styles.textBold10, width:'100%'}}>Ori. Reg. Date {data.firstRegistrationDate}</Text>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000'}}>
                        <Text style={styles.textBold10}>Engine No. {data.engineNumber}</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px'}}>
                        <Text style={styles.textBold10}>Chassis No. {data.chassisNumber}</Text>
                    </View>
                </View>
                <View style={{backgroundColor:'#FFFf00', padding:'1px 6px', borderBottom:'1px solid #000'}}>
                    <Text style={styles.textBold10}>My Financing</Text>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000',flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'70%'}}>Purchase Price</Text>
                        <Text style={{...styles.textBold10, width:'130%'}}>S$ {data.vehiclePrice}</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px',flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'70%'}}>Tenure  {data.tenure}</Text>
                        <Text style={{...styles.textBold10, width:'130%'}}>Months      Total HP Price          S$</Text>
                         
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000',flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'70%'}}>Financed Amount</Text>
                        <Text style={{...styles.textBold10, width:'130%'}}>S$</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px',flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'70%'}}>Mthly Instalment</Text>
                        <Text style={{...styles.textBold10, width:'130%'}}>S$</Text>
                         
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'100%', padding:'1px 6px', borderRight:'1px solid #000',flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'100%'}}>Rates (P.A)</Text>
                        <Text style={{...styles.textBold10, width:'100%'}}>%    Term Charges        S$</Text>
                    </View>
                    <View style={{width:'100%',padding:'1px 6px',flexDirection:'row'}}>
                        <Text style={{...styles.textBold10, width:'70%'}}>Final Instalment</Text>
                        <Text style={{...styles.textBold10, width:'130%'}}>S$</Text>
                         
                    </View>
                </View>
                <View style={{backgroundColor:'#001F5F', padding:'1px 6px', borderBottom:'1px solid #000'}}>
                    <Text style={{...styles.textBold10,color:'#fff'}}>Declaration</Text>
                </View>
                <View style={{padding:'1px 6px', borderBottom:'1px solid #000'}}>
                    <Text style={styles.text}>I/We confirm the above information given by me in this application is true and accurate, and I have not wilfully withheld any material fact(s). <Text style={styles.textBold6}>I/We will pay all instalments due to Moneymax Leasing Pte </Text></Text>
                    <Text style={styles.text}>Ltd by GIRO if my/our application is approved.<Text style={styles.textBold6}> I/We confirm that I/we are currently not under any legal proceedings by creditors. I/We understand that Moneymax Leasing Pte Ltd may reject my/our </Text></Text>
                    <Text style={styles.text}>application at its’ sole discretion without assigning any reasons/explanation whatsoever. I/We agree that Moneymax Leasing Pte Ltd reserves the right to ask for additional information from me/us when</Text>
                    <Text style={styles.text}>assessing my/our application and if any of the above information or supporting documents provided in connection to my application are found to be false after my/our application has been approved,</Text>
                    <Text style={styles.text}> Moneymax Leasing Pte Ltd may revoke or void the approval previously granted to me/us. </Text>
                    <Text style={styles.textBold6}>I / We hereby authorize and give consent to Moneymax Leasing Pte Ltd and its related companies to conduct credit checks on me / us as you may deem fit including but not limited to checks with</Text>
                    <Text style={styles.textBold6}>credit bureau or reference agency for the purpose of assessing my / our credit worthiness, and also to disclose to parties or persons any information whatsoever given by me / us in connection with </Text>
                    <Text style={styles.textBold6}>this application and / or in connection with all previous and current transactions between Moneymax Leasing Pte Ltd and me / us as Moneymax Leasing Pte Ltd may deem necessary without any</Text>
                    <Text style={styles.textBold6}>liability or notice to me /us.</Text>
                    <Text style={styles.textBold6}> </Text>
                    <Text style={styles.textBold6}>Personal Data Protection (Applicable to Individuals only) </Text>
                    <Text style={styles.textBold6}>I/.we consent to Moneymax Leasing Pte Ltd in enrolling me into any product survey and/or allowing me to be kept informed of promotions, new services and products offered by Moneymax Leasing </Text>
                    <Text style={styles.textBold6}>Pte Ltd from time to time via the following channels selected by me below even if my contact number(s) provided to Moneymax Leasing Pte Ltd are registered with the "DO NOT </Text>
                    <Text style={styles.textBold6}>CALL" Registry :</Text>
                    <Text style={styles.textBold6}> </Text>
                    <Text style={styles.textBold6}>☐  SMS/MMS ☐  fax ☐ Voice call ☐ Direct mail ☐ Email</Text>
                    <Text style={styles.textBold6}>I have read, understood and accepted the terms and conditions set out in the Privacy and Data Protection Policy found at www.moneymaxleasing.com and fully agree to Moneymax Leasing Pte Ltd </Text>
                    <Text style={styles.textBold6}>collecting, using, disclosing, processing and/or transferring my personal data for the purposes set out in the Privacy and Data Protection Policy and that all terms and conditions set out in Privacy </Text>
                    <Text style={styles.textBold6}>and Data Protection Policy are binding on me.</Text>
                    <Text style={styles.textBold6}> </Text>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'40%', padding:'1px 6px', borderRight:'1px solid #000',flexDirection:'row'}}>
                        <View style={{width:'100%', flexDirection:'row'}}>
                            <View style={{marginTop:50, width:'60%'}}>
                                <Text style={styles.text10}>_______________</Text>
                                <Text style={styles.text10}>Signature of Applicant</Text>
                            </View>
                            <Image src={require("../src/hand.png")} style={{width:35, height:13, marginTop:40}}/>
                        </View>
                        <View style={{width:'100%', flexDirection:'row'}}>
                            <View style={{marginTop:50, width:'60%'}}>
                                <Text style={styles.text10}>_______________</Text>
                                <Text style={styles.text10}>Signature of Guarantor</Text>
                            </View>
                            <Image src={require("../src/hand.png")} style={{width:35, height:13, marginTop:40}}/>
                        </View>
                    </View>
                    <View style={{width:'60%', padding:'1px 6px',flexDirection:'column'}}>
                        <Text style={styles.textBold10}>Dealer’s Declaration </Text>
                        <Text style={styles.text10}>I confirm that the identities of the applicant(s)/Guarantor(s) have been verified against the relevant documents</Text>
                        <View style={{flexDirection:'row'}}>
                        <View style={{width:'100%', flexDirection:'row'}}>
                            <View style={{marginTop:20,}}>
                                <Text style={styles.text10}>___________________________</Text>
                                <Text style={styles.textBold10}>Signature/Name/NRIC of Dealer’s Representative</Text>
                            </View>
                            <Image src={require("../src/hand.png")} style={{width:35, height:13, marginTop:20}}/>
                        </View>
                        <View style={{width:'100%', flexDirection:'row', marginLeft:20}}>
                            <View style={{marginTop:20, width:'60%'}}>
                                <Text style={styles.text10}>_______________</Text>
                                <Text style={styles.textBold10}>Dealer’s Company Stamp</Text>
                            </View>
                        </View>
                        </View>
                    </View>
                </View>
            </View>
        </Page>
        <Page size="A4" style={pStyles.page2}>
            <Text style={{...styles.textArialBold, textDecoration:'underline', alignSelf:'center'}}>LETTER OF DECLARATION</Text>
            <View style={{marginTop: 28.74, flexDirection:'row'}}>
                <View style={{width:'20%'}}>
                    <Text style={styles.textArialNormal}>Date</Text>
                    <Text style={styles.textArialNormal}>To</Text>
                </View>
                <View style={{width:'80%'}}>
                    <Text style={styles.textArialNormal}>_________________</Text>
                    <Text style={styles.textArialNormal}>MoneyMax Leasing Pte Ltd</Text>
                    <Text style={styles.textArialNormal}>7Changi Business Park Vista, SooKee HQ </Text>
                    <Text style={styles.textArialNormal}>MSingapore 486042</Text>
                </View>
            </View>
            <View style={{marginTop: 22.44,}}>
                <Text style={styles.textArialBold11}>[A] PURSUANT TO MAS NOTICE 642</Text>
            </View>
            <View style={{marginTop: 9.37,}}>
                <Text style={styles.textArialNormal}>\ / We, the undersigned, hereby declare as follows:</Text>
            </View>
            <View style={{marginTop: 9.37, marginLeft:17.89}}>
                <Text style={styles.textArialNormal}>1.     Whether I / We have received any discount, rebate or any other benefit from the vendor of the vehicle</Text>
                <Text style={{...styles.textArialNormal, marginLeft:20}}>or any other person </Text>
            </View>
            <View style={{marginTop: 3.97, marginLeft:41.91}}>
                <Text style={styles.textArialNormal}>NO</Text>
                <Text style={styles.textArialNormal}>YES, I / We have received the following:_____________________________ at a total value of </Text>
                <Text style={styles.textArialNormal}>S$ _________________</Text>
            </View>
            <View style={{marginTop: 9.37, marginLeft:17.89}}>
                <Text style={styles.textArialNormal}>2.     Whether I / We have obtained any other credit or hire purchase facility (ies) for the </Text>
                <Text style={{...styles.textArialNormal, marginLeft:20}}>       purchase/hire (as the case may be) of the Vehicle:</Text>
            </View>
            <View style={{marginTop: 3.97, marginLeft:41.91}}>
                <Text style={styles.textArialNormal}>NO</Text>
                <Text style={styles.textArialNormal}>YES, I / We have received the following:_____________________________ at a total value of </Text>
                <Text style={styles.textArialNormal}>S$ _________________</Text>
            </View>
            <View style={{marginTop: 9.37,}}>
                <Text style={styles.textArialNormal}>I / We warrant that the above declaration shall remain true, accurate and complete in all respects and at all </Text>
                <Text style={styles.textArialNormal}>times and I / we shall immediately notify you in writing if otherwise.</Text>
            </View>
            <View style={{marginTop: 9.37}}>
                <Text style={styles.textArialNormal}>I / We agree to provide you promptly with all such information and/or documents as you may require from </Text>
                <Text style={styles.textArialNormal}>time to time relating to or in connection with the above declaration.</Text>
            </View>
            <View style={{marginTop: 9.37}}>
                <Text style={styles.textArialNormal}>I / We agree and acknowledge that you may refuse to disburse the credit or hire purchase facility (as the case</Text>
                <Text style={styles.textArialNormal}>may be), terminate, recall, review and/or vary such facility or any part thereof at any time and at your discretion </Text>
                <Text style={styles.textArialNormal}>in the event any of the above declaration proves to be untrue, inaccurate or incomplete in any respect or if I / </Text>
                <Text style={styles.textArialNormal}>we fail or delay to comply with any request for information or documents as you may take. In this regard, I / we</Text>
                <Text style={styles.textArialNormal}>undertake to pay any abortive costs imposed by you at your discretion and to release and hold you harmless</Text>
                <Text style={styles.textArialNormal}>from and indemnify you for all loss, damages, costs (including legal costs on an indemnity basis), expenses</Text>
                <Text style={styles.textArialNormal}>and liabilities suffered or incurred by you arising from or in connection with any such untrue, inaccurate or</Text>
                <Text style={styles.textArialNormal}>incomplete declaration(s), or failure or delay in complying with your request for information or documents.</Text>
            </View>
            <View style={{marginTop: 79, flexDirection:'row'}}>
                <View style={{width:'50%'}}>
                    <Text style={styles.textArialNormal}>__________________________________</Text>
                    <Text style={styles.textArialNormal}>Applicant Name: </Text>
                    <Text style={styles.textArialNormal}>Applicant NRIC:</Text>
                </View>
                <View style={{width:'50%'}}>
                    <Text style={styles.textArialNormal}>__________________________________</Text>
                    <Text style={styles.textArialNormal}>Guarantor Name: </Text>
                    <Text style={styles.textArialNormal}>Guarantor NRIC:</Text>
                </View>
            </View>

        </Page>
        <Page size="A4" style={pStyles.page3}>
            <Text style={{...styles.textArialNormal, marginLeft:10}}>Moneymax Leasing Pte Ltd</Text>
            <View style={{border:'1px solid #000', marginTop:12.45}}>
                <View style={{padding:'3.88px 8.89px 20.12px 3.88px ',borderBottom:'1px solid #000',}}>
                    <Text style={{...styles.textArialBold, alignSelf:'center'}}>HIRE PURCHASE FINANCING CHARGES</Text>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'50%', borderRight:'1px solid #000', padding:'3.88px 8.89px',}}>
                        <Text style={styles.textArialNormal}>Early Settlement</Text>
                    </View>
                    <View style={{width:'50%', padding:'1px 19.24px'}}>
                        <Text style={styles.textArialNormal}>1.      20% of interest rebate, computed</Text>
                        <Text style={{...styles.textArialNormal, marginLeft:22}}>based on the Rule of 78. </Text>
                        <Text style={styles.textArialNormal}>2.      1.5% of loan principal; and</Text>
                        <Text style={styles.textArialNormal}>3.      Balance principal payable</Text>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'50%', borderRight:'1px solid #000', padding:'3.88px 8.89px',}}>
                        <Text style={styles.textArialNormal}>Early Settlement within 36 months</Text>
                    </View>
                    <View style={{width:'50%', padding:'1px 19.24px'}}>
                        <Text style={styles.textArialNormal}>1.      20% of interest rebate, computed</Text>
                        <Text style={{...styles.textArialNormal, marginLeft:22}}>based on the Rule of 78. </Text>
                        <Text style={styles.textArialNormal}>2.      1.5% of loan principal; and</Text>
                        <Text style={styles.textArialNormal}>3.      Balance principal payable</Text>
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'50%', borderRight:'1px solid #000', padding:'3.88px 8.89px',}}>
                        <Text style={styles.textArialNormal}>Notice period for early settlement</Text>
                    </View>
                    <View style={{width:'50%', padding:'1px 6.59px'}}>
                        <Text style={styles.textArialNormal}>1 month notice in writing* or 1 month’s </Text>
                        <Text style={{...styles.textArialNormal,}}>interest in lieu thereof </Text>
                        <Text style={styles.textArialNormal}> </Text>
                        <Text style={styles.textArialNormal}>*For all early settlement requests, please write in </Text>
                        <Text style={styles.textArialNormal}>to <Text style={{textDecoration:'underline'}}>enquiry@moneymaxleasing.com.sg</Text></Text>
                        <Text style={styles.textArialNormal}> </Text>
                    </View>
                </View>
                <View style={{padding:'3.88px 8.89px 20.12px 3.88px ',borderBottom:'1px solid #000',}}>
                    <Text style={{...styles.textArialBold, alignSelf:'center'}}>Fees on Overdue Instalment</Text>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'50%', borderRight:'1px solid #000', padding:'1px 6.78px',}}>
                        <Text style={styles.textArialNormal}>Default Interest for overdue instalment</Text>
                    </View>
                    <View style={{width:'50%', padding:'1px 5.39px'}}>
                        <Text style={styles.textArialNormal}>18% per annum</Text>                        
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'50%', borderRight:'1px solid #000', padding:'3.88px 8.89px',}}>
                        <Text style={styles.textArialNormal}>Late Charge per overdue instalment</Text>
                    </View>
                    <View style={{width:'50%', padding:'1px 5.39px'}}>
                        <Text style={styles.textArialNormal}>$80</Text>                        
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'50%', borderRight:'1px solid #000', padding:'1px 6.78px',}}>
                        <Text style={styles.textArialNormal}>Issuance of Fourth Schedule </Text>
                        <Text style={styles.textArialNormal}>(Notice to Hirer on Intention to Repossess)</Text>
                    </View>
                    <View style={{width:'50%', padding:'1px 5.39px'}}>
                        <Text style={styles.textArialNormal}>$20</Text>                        
                    </View>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'50%', borderRight:'1px solid #000', padding:'1px 6.78px',}}>
                        <Text style={styles.textArialNormal}>Issuance of Fifth Schedule </Text>
                        <Text style={styles.textArialNormal}>(Notice to Hirer on Repossessed Vehicle)</Text>
                    </View>
                    <View style={{width:'50%', padding:'1px 5.39px'}}>
                        <Text style={styles.textArialNormal}>$20</Text>                        
                    </View>
                </View>
                <View style={{padding:'3.88px 8.89px 20.12px 3.88px ',borderBottom:'1px solid #000',}}>
                    <Text style={{...styles.textArialNormal, alignSelf:'center'}}>Fees on Overdue Instalment</Text>
                </View>
                <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                    <View style={{width:'50%', borderRight:'1px solid #000', padding:'1px 6.78px',}}>
                        <Text style={styles.textArialNormal}>Repossession charges</Text>
                    </View>
                    <View style={{width:'50%', flexDirection:'column'}}>
                        <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                            <View style={{width:'50%', borderRight:'1px solid #000',padding:'1px 5.45px',}}>
                                <Text style={styles.textArialNormal}>Saloon Cars</Text>
                            </View>
                            <View style={{width:'50%', padding:'1px 5.39px'}}>
                                <Text style={styles.textArialNormal}>$500</Text>                        
                            </View>
                        </View> 
                        <View style={{borderBottom:'1px solid #000', flexDirection:'row'}}>
                            <View style={{width:'50%', borderRight:'1px solid #000', padding:'1px 5.45px',}}>
                                <Text style={styles.textArialNormal}>Goods Vehicles</Text>
                            </View>
                            <View style={{width:'50%', padding:'1px 5.39px'}}>
                                <Text style={styles.textArialNormal}>$500</Text>                        
                            </View>
                        </View> 
                        <View style={{flexDirection:'row'}}>
                            <View style={{width:'50%', borderRight:'1px solid #000', padding:'1px 5.45px',}}>
                                <Text style={styles.textArialNormal}>Heavy Vehicles</Text>
                            </View>
                            <View style={{width:'50%', padding:'1px 5.39px'}}>
                                <Text style={styles.textArialNormal}>$1000</Text>                        
                            </View>
                        </View>                    
                    </View>
                </View>
                <View style={{flexDirection:'row'}}>
                    <View style={{width:'50%', borderRight:'1px solid #000', padding:'1px 6.78px',}}>
                        <Text style={styles.textArialNormal}>Storage charges</Text>
                    </View>
                    <View style={{width:'50%', padding:'1px 5.39px'}}>
                        <Text style={styles.textArialNormal}>$50/day and/or part thereof</Text>                        
                    </View>
                </View>
            </View>
            <View style={{padding:'1px 6.78px'}}>
                <Text style={styles.textArialNormal}>*Other fees & charges may be levied to recover any cost and expenses incurred during debt recovery or for </Text>  
                <Text style={styles.textArialNormal}>repossession services provided to the hirer on a case to case basis.</Text>  
                <Text style={styles.textArialNormal}> </Text>  
                <Text style={styles.textArialNormal}>**I acknowledge and understand that MoneyMax Leasing Pte Ltd has a strictly no waiver policy on any fees in </Text>  
                <Text style={styles.textArialNormal}>relation to overdue instalments.</Text>  
                <Text style={styles.textArialNormal}></Text>  
                <Text style={styles.textArialNormal}>I hereby acknowledge and confirm that I have read and understood that I shall be liable for the fees & charges </Text>  
                <Text style={styles.textArialNormal}>listed above as they fall due.</Text>  
                <Text style={styles.textArialNormal}></Text>  
                <Text style={styles.textArialNormal}></Text> 
            </View>
            <View style={{marginTop: 79, flexDirection:'row'}}>
                <View style={{width:'50%'}}>
                    <Text style={styles.textArialNormal}>__________________________________</Text>
                    <Text style={styles.textArialNormal}>Name of Hirer/Guarantor: </Text>
                    <Text style={styles.textArialNormal}>NRIC/Passport No.</Text>
                </View>
                <View style={{width:'50%'}}>
                    <Text style={styles.textArialNormal}>__________________________________</Text>
                    <Text style={styles.textArialNormal}>Name of Hirer/Guarantor: </Text>
                    <Text style={styles.textArialNormal}>NRIC/Passport No.</Text>
                </View>
            </View>
        </Page>
    </Document>
</>)

const MoneyMaxPdf = React.memo(({ isSave, setPdf, data }:Props) => {
    return (
        <BlobProvider document={isSave? <Doc1 data={data} dob={moment(data.dob,'YYYY-MM-DD').format('DD/MM/YYYY')} dobGuarantor={moment(data.guarantor[0]?.dobGuarantor,'YYYY-MM-DD').format('DD/MM/YYYY')}/>:<></>} >
            {({blob})=>{
                    let file;
                    file = blob && new File([blob as Blob], 'moneymax.pdf');
                    data && setPdf(file)
                
                return <></>;
            }}
            
        </BlobProvider>
    )
  });

export default MoneyMaxPdf;