import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEdit: boolean;
  groupId: string;
  isSelectAll: boolean;
  selectedCustomer: number[];
  rowCustomer: {customerName: string,customerId: number, dob: string, phoneNumber: string, email: string, interestedService: string}[];
  pageCustomer: number;
  selectedCheck: boolean[];
  currentPageCustomerNew: number;
  groupName: string;
  isErrorInput : boolean;
  isErrorSelect: boolean;
  valueSearchCusNameCRMNew: string;
  valueSearchPhoneCRMNew: string;
  valueSearchEmailCRMNew: string;
  valueSearchServiceCRMNew: string;

  valueSearchCRMNew: string;
  searchAttributeCRMNew: string;
  listSearchCustomerNameCRMNew: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerGroupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCRMListNewId: any;
  createCustomerGroupId: any;
  getCustomerGroupDetailId: any;
  updateCustomerGroupDetailId: any;
  searchCrmNewId: any;
  searchCustomerNameCRMNewId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isEdit: false,
      groupId:'',
      isSelectAll: false,
      selectedCustomer: [],
      pageCustomer: 0,
      rowCustomer: [],
      selectedCheck: [false, false, false, false, false, false, false, false, false, false, ],
      currentPageCustomerNew: 1,
      isErrorInput: false,
      groupName: '',
      isErrorSelect: false,
      valueSearchCusNameCRMNew: '',
      valueSearchPhoneCRMNew: '',
      valueSearchEmailCRMNew: '',
      valueSearchCRMNew: '',
      searchAttributeCRMNew: '',
      valueSearchServiceCRMNew: '',
      listSearchCustomerNameCRMNew: [],
      // Customizable Area End,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    
    switch (apiRequestCallId) {
      case this.getCRMListNewId:
        this.getCRMListNewReceiver(responseJson);
        break;
      case this.createCustomerGroupId:
        this.createCustomerGroupReceiver(responseJson);
        break;
      case this.getCustomerGroupDetailId:
        this.getCustomerGroupDetailReceiver(responseJson);
        break;
      case this.updateCustomerGroupDetailId:
        this.updateCustomerGroupReceiver(responseJson);
        break;
      case this.searchCrmNewId:
        this.getCRMListNewReceiver(responseJson);
        break;
      case this.searchCustomerNameCRMNewId:
        this.searchCustomerNameCRMNewReceiver(responseJson);
        break;
      default:
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getCRMListNew(1);
    this.checkEdit()
  }

  checkEdit = () =>{
    const pathSegments = window.location.pathname.split('/');
    if(pathSegments[1] === 'NewCustomerGroup'){
      this.setState({isEdit: false})
    } else {
      this.setState({isEdit: true, groupId: this.props.navigation.getParam('groupId') ?? ''})
      this.getGroupDetail(this.props.navigation.getParam('groupId'))
    } 
  }

  getGroupDetail = async (id: any) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customerGroupEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getCustomerGroupDetailId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCustomerGroupDetailReceiver = (responseJson: any)=>{
    this.setState({
      groupName: responseJson.data.attributes.group_name,
      selectedCustomer: responseJson.data.attributes.customer_ids.map((e: { data: { id: any; }; })=>e.data.id)
    })
  }

  getCRMListNew = async (page: number) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessageNew = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageNew.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageNew.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.crmEndPoint}?page=${page}&per_page=10`
    );
    requestMessageNew.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getCRMListNewId = requestMessageNew.messageId;
    runEngine.sendMessage(requestMessageNew.id, requestMessageNew);
  }

  getCRMListNewReceiver = (responseJson: any) => {
    const dataNew = responseJson.data.data.map((customer:any) => ({
      customerId:customer.id,
      customerName: customer.attributes.name,
      dob: customer.attributes.date_of_birth,
      phoneNumber: customer.attributes.contact_number,
      email: customer.attributes.email,
      interestedService: customer.attributes.services?.map((string: string) => string.charAt(0).toUpperCase() + string.slice(1)).join(', ')
    }));
    this.setState({rowCustomer: dataNew, pageCustomer: Math.ceil(responseJson.pagination.total_count/10)});
    if((responseJson.pagination.current_page-1)*10 + responseJson.pagination.count > this.state.selectedCheck.length){
      dataNew.map(()=> this.setState({selectedCheck: [...this.state.selectedCheck, false]}))
    }
  }

  handleChangePageCustomer=(_event: any, value:any) =>{
    if(this.state.valueSearchCRMNew.length > 0){
      this.searchCRMNew(value);
    } else {
      this.getCRMListNew(value);
    }
    this.setState({currentPageCustomerNew: value});
  }

  handleNavigateCRM = () =>{
    this.props.navigation.navigate('CRM')
  }

  onValueChange = (value: string) => {
    this.setState({ ...this.state, groupName: value });
  };

  handleCancel = () => {
    this.props.navigation.goBack();
  }

  handleCheckAllItem = (e: any) =>{
    let selectedCustomer = [...this.state.selectedCustomer];
    if(!e.target.checked){
      for(const item of this.state.rowCustomer){
        if(selectedCustomer.includes(item.customerId)){
          selectedCustomer = selectedCustomer.filter(e => e !== item.customerId);
        }
      }
    } else {
      for(const item1 of this.state.rowCustomer){
        if(!selectedCustomer.includes(item1.customerId)){
          selectedCustomer.push(item1.customerId);
        }
      }
    }
    this.setState({isSelectAll:e.target.checked, selectedCustomer})
  }

  handleSelectItem = (data: any, index: number)=>{
    let selectedCheck = [...this.state.selectedCheck];
    let selectedCustomer = [...this.state.selectedCustomer];
    if(!selectedCustomer.includes(data.customerId)){
      selectedCustomer.push(data.customerId);
      selectedCheck.splice(index, 1, true);
    } else {
      selectedCustomer.splice(selectedCustomer.indexOf(data.customerId), 1)
      selectedCheck.splice(index, 1, false);
    }
    const isSelectAll = this.containsAllValues(selectedCustomer,this.state.rowCustomer.map(e=>e.customerId))
    this.setState({selectedCustomer, selectedCheck, isSelectAll})
  }

  shouldComponentUpdate(_nextProps: Readonly<Props>, nextState: Readonly<S>, _nextContext: any): boolean {
    let temp = [false, false, false, false, false, false, false, false, false, false, ];
    if(nextState.rowCustomer !== this.state.rowCustomer || nextState.selectedCustomer !== this.state.selectedCustomer){      
      let selectedCustomer = [...nextState.selectedCustomer];
      for(const a of selectedCustomer){
        if(nextState.rowCustomer.map(e=>e.customerId).includes(a)){          
          const index = nextState.rowCustomer.map(e=>e.customerId).indexOf(a);
          temp.splice(index, 1, true);
        }
      } 
      const isSelectAll = this.containsAllValues(selectedCustomer,nextState.rowCustomer.map(e=>e.customerId))
      this.setState({selectedCheck: temp, isSelectAll});
    }
    if(nextState.selectedCustomer !== this.state.selectedCustomer){
      for(const item of nextState.selectedCustomer){
        if(nextState.rowCustomer.map(e=>e.customerId).includes(item)){
          const index = this.state.rowCustomer.map(e=>e.customerId).indexOf(item);
          temp.splice(index,1,true)
        }
      }
      this.setState({selectedCheck: temp});
    }
    if(nextState.valueSearchCRMNew !== this.state.valueSearchCRMNew){
      if(nextState.valueSearchCRMNew.length > 0 ){
        this.searchCRMNew(1)
      } else{
        this.getCRMListNew(1);
        this.setState({currentPageCustomerNew:1})
      }
    }
    return true;
  }

  containsAllValues=(arr1: any, arr2: any) =>{
    let set1 = new Set(arr1);
    return arr2.every((item: any) => set1.has(item));
  }
  
  handleSubmit = () => {   
    this.setState({isErrorInput: this.state.groupName === '',isErrorSelect:this.state.selectedCustomer.length === 0})
    if(this.state.groupName !== '' && this.state.selectedCustomer.length !==0){
      if(this.state.isEdit){
        this.updateCustomerGroup();
      } else {
        this.createCustomerGroup();
      }
    }
  }

  createCustomerGroup = async()=>{
    const tokenValue = await getStorageData("authToken")
      const headers = {
        token: tokenValue,
      };
    let formdataCG = new FormData();
    formdataCG.append('group_name',this.state.groupName);
    formdataCG.append('group_count', '1');
    for (const id of this.state.selectedCustomer) {
      formdataCG.append("customer_ids[]", id.toString());
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customerGroupEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdataCG
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.createCustomerGroupId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateCustomerGroup = async()=>{
    const tokenValue = await getStorageData("authToken")
      const headers = {
        token: tokenValue,
      };
    let formdataC = new FormData();
    formdataC.append('group_name',this.state.groupName);
    formdataC.append('group_count', this.state.selectedCustomer.length.toString()??'0');
    for (const id of this.state.selectedCustomer) {
      formdataC.append("customer_ids[]", id.toString());
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customerGroupEndPoint + '/'+this.state.groupId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdataC
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    this.updateCustomerGroupDetailId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createCustomerGroupReceiver = (responseJson: any) => {
    if(responseJson.data.id !== null){
      this.props.navigation.goBack();
    }
  }

  updateCustomerGroupReceiver = (responseJson: any) => {
    if(responseJson.data.id !== null){
      this.props.navigation.goBack();
    }
  }

  handleNavigateBroadcast = () =>{
    this.props.navigation.navigate('Broadcast');
  }

  onChangeSearchCRMNew =(e:any, attribute: string)=>{
    this.setState({
      valueSearchCRMNew:e.target.value,
      searchAttributeCRMNew: attribute,
      currentPageCustomerNew:1,
    })
    switch (attribute) {
      case 'name':
        this.setState({
          valueSearchCusNameCRMNew: e.target.value, 
          valueSearchEmailCRMNew:'',
          valueSearchPhoneCRMNew: '',
          valueSearchServiceCRMNew:''
        })
        break;
      case 'contact_number':
        this.setState({
          valueSearchCusNameCRMNew: '',
          valueSearchEmailCRMNew: '', 
          valueSearchPhoneCRMNew:e.target.value, 
          valueSearchServiceCRMNew:''
        })
        break;
      case 'email':
        this.setState({
          valueSearchCusNameCRMNew: '',
          valueSearchEmailCRMNew: e.target.value, 
          valueSearchPhoneCRMNew:'',
          valueSearchServiceCRMNew:''
        })
        break;
      case 'services':
        this.setState({
          valueSearchCusNameCRMNew: '',
          valueSearchEmailCRMNew: '', 
          valueSearchPhoneCRMNew:'',
          valueSearchServiceCRMNew: e.target.value
        })
        break;
        
    }
  }

  handleSearchCustomerNameCRMNew = async(e: any)=>{
    const value= e.target.value;
    if(e.target.value !== undefined && e.target.value !== ''){
      this.setState({valueSearchCusNameCRMNew:value})
      const tokenValue = await getStorageData("authToken")
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": tokenValue
      };
      const requestMessageCRMNew = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessageCRMNew.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageCRMNew.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.crmEndPoint}/search?attribute=name&query=${value}&page=1&per_page=10`
      );
      requestMessageCRMNew.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      this.searchCustomerNameCRMNewId = requestMessageCRMNew.messageId;
      runEngine.sendMessage(requestMessageCRMNew.id, requestMessageCRMNew);
    }
    if(e.target.value === ''){
      this.getCRMListNew(1)
      this.setState({searchAttributeCRMNew:'', valueSearchCRMNew:'', valueSearchCusNameCRMNew:''})

    }
  }

  searchCustomerNameCRMNewReceiver = (responseJson: any)=>{
    this.setState({listSearchCustomerNameCRMNew: responseJson?.data?.data.map((e:any)=>e?.attributes)})
  }

  searchCRMNew = async (page:number) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.crmEndPoint}/search?attribute=${this.state.searchAttributeCRMNew}&query=${this.state.valueSearchCRMNew}&page=${page}&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.searchCrmNewId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}