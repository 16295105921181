Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfcrm4";
exports.labelBodyText = "cfcrm4 Body";
exports.crmEndPoint = 'account_block/customers'
exports.carMatchingEndPoint = 'account_block/customers/car_matching_list'
exports.makeModelEndPoint = 'bx_block_catalogue/drop_downs/make_model_lists'
exports.deleteApiMethodType = "DELETE";
exports.putApiMethodType = "PUT";
exports.customerGroupEndPoint ='account_block/customer_groups'
exports.broadcastEndPoint = 'account_block/broad_casts'

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End