import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  listGroup : any;
  anchorElCustomerGroup: any;
  currentAnchorElCustomerGroup: any;
  openModal: boolean;
  currentPageCustomerGroup: number;
  pageCustomerGroup:number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerGroupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupListId: any;
  deleteCustomerGroupApiId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      listGroup: [],
      anchorElCustomerGroup: null,
      currentAnchorElCustomerGroup: null,
      openModal: false,
      currentPageCustomerGroup: 1,
      pageCustomerGroup: 0,
      // Customizable Area End,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    switch (apiRequestCallId) {
      case this.getGroupListId:
        this.getGroupListReceiver(responseJson);
        break;
      case this.deleteCustomerGroupApiId:
        this.deleteCustomerReceiver(responseJson);
        break;
      default:
        break;
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getGroupList(1);
  }

  getGroupList = async (page: number) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customerGroupEndPoint}?page=${page}&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getGroupListId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getGroupListReceiver = (responseJson: any) => {
    this.setState({listGroup:responseJson.data.data, pageCustomerGroup: Math.ceil(responseJson.pagination.total_count/10)})
  }

  handleNavigateCRM = () =>{
    this.props.navigation.navigate('CRM')
  }

  handleNavigateBroadcast = () =>{
    this.props.navigation.navigate('Broadcast')
  }

  handleCreateNewCustomerGroup = () =>{
    this.props.navigation.navigate('NewCustomerGroup')
  }

  handleOpenEditMenuCustomerGroup = (event:any, data:any) => {
    const anchorElCustomerGroup = event.currentTarget
    this.setState({...this.state, anchorElCustomerGroup, currentAnchorElCustomerGroup: data })
  }
  handleCloseEditMenuCustomerGroup = () => {
    this.setState({...this.state, anchorElCustomerGroup:null, currentAnchorElCustomerGroup: null })
  }

  handleEditCustomerGroup = ()=> {
    this.props.navigation.navigate('EditCustomerGroup', { groupId: this.state.currentAnchorElCustomerGroup.id });
  }

  handleModalDeleteCustomerGroup = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  handleDeleteCustomerGroup = () => {
    this.deleteCustomerGroup(this.state.currentAnchorElCustomerGroup.id);
  }

  deleteCustomerGroup = async (id: string) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCustomerGroupApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customerGroupEndPoint}` + "/" + `${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteCustomerReceiver = (responseJson: any) =>{
    this.getGroupList(1);
    this.handleCloseEditMenuCustomerGroup();
    this.setState({openModal: false});
  }

  handleChangePageCustomerGroup=(_event: any, value:any) =>{ 
    this.setState({currentPageCustomerGroup: value});
    this.getGroupList(value);
  }
  // Customizable Area End
}