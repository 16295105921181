import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  Typography,
  Grid,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { bgimage, logo } from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Customizable Area End



const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  userSchema = () => {
    return Yup.object().shape({
      emailId: Yup.string().email('Entered Email ID is invalid').matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[|a-z]{2,}$/, "Entered Email ID is invalid").required("*Email ID is required!"),
    });
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <LoginStyle>
          <Grid container >
            <Grid item xs={12} className="mainContainer">
              <Box className="loginCard">
                <Box className="logoBox">
                  <ArrowBackIcon className="icon" onClick={this.navigateToEmailAccountLoginBlock}/>
                  <img src={logo} className="logo" />
                </Box>
                {this.state.otp ? (
                <Box className="logintextBox">
                  <Typography className="loginText">Reset Password</Typography>
                  <Formik
                    data-test-id="formik"
                    initialValues={{
                      emailId: this.state.emailId
                    }}
                    validationSchema={this.userSchema}
                    validateOnChange={this.state.formIsDirty}
                    validateOnBlur={this.state.formIsDirty}
                    onSubmit={(values) => {
                      this.forgotPasswordApi(values)
                    }}
                  >
                    {({ errors, setFieldValue, handleSubmit }) => (
                      <form onSubmit={handleSubmit} noValidate autoComplete="off">
                        <Typography className="labelText">Email ID</Typography>
                        <Input
                          data-test-id='email'
                          className={errors.emailId ? 'inputField error' : 'inputField'}
                          placeholder="Enter Your Email ID"
                          disableUnderline
                          value={this.state.emailId}
                          onChange={(event) => {
                            this.onValueChange("emailId", event.target.value);
                            setFieldValue("emailId", event.target.value);
                          }}
                          fullWidth
                        />
                        <Typography className="errorsMsg">{errors.emailId || this.state.errorMsg}</Typography>
                        <Button data-test-id='forgotpasswordBtn' className="forgotpasswordBtn" type="submit" onClick={this.checkSubmit}>Get Password Reset Link</Button>
                      </form>
                    )}
                  </Formik>
                </Box>)
                  : ( <>
                    <Typography className="popoMsgtext">
                    Password reset link has been sent to your email. Use the  link to reset your password.
                   </Typography>
                   <Box className="LoginAgainbox">
                   <Typography data-test-id='loginagaintext' className="LoginAgain" onClick={this.navigateToEmailAccountLoginBlock}>Login Again</Typography>
                   </Box>
                   </>
                  )}
              
              </Box>
            </Grid>
          </Grid>
        </LoginStyle>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const LoginStyle = styled(Box)(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url('${bgimage}')`,
  backgroundSize: 'cover',
  height: 'auto',
  width: '100%',
  "& .icon": {
    color: (theme as any).typography.h1, 
  },
  "& .mainContainer": {
    display: "flex",
    padding: '20px',
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  "& .loginCard": {
    maxWidth: "640px",
    width: "100%",
    padding: '40px',
    height: "auto",
    backgroundColor: '#2B2B2B',
    gap: "24px",
    flexDirection: "column",
    borderRadius: '16px',
  },
  "& .logoBox": {
    justifyContent: 'center',
    display: "flex",
    flexDirection:'column'
  },
  "& .logo": {
    marginBottom: '22px',
    width: '150px',
    height: '102px',
    alignSelf:'center'
  },
  "& .loginText": {
    fontSize: "38px",
    fontFamily: "Outfit",
    fontStyle: "normal",
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    fontWeight: 700,
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
  },
  "& .form": {
    width: '100%'
  },
  "& .labelText": {
    color: "var(--text-primary, #F3F3F3)",
    fontFamily: "Outfit",
    fontWeight: 700,
    fontStyle: "normal",
    marginTop: "24px",
    fontSize: "14px",
  },
  "& .inputField": {
    background: "var(--surface, #2B2B2B)",
    borderRadius: 8,
    border: "2px solid var(--neutrals-cool-gray-200, #E2E8F0)",
    marginTop: '4px',
    fontFamily: "Outfit",
    padding: '10px 8px',
    color: "var(--text-teriary, rgba(243, 243, 243, 0.65))",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
  },
  "& .mainCheckbox": {
    flexDirection: 'row',
    display: 'flex',
  },
  "& .MuiSvgIcon-root": {
    width: "20px",
    height: "20px",
    fill: "white",
  },
  "& .checkbocMaindiv": {
    marginBottom: '24px',
    justifyContent: 'space-between',
    display: 'flex',
    marginTop: '24px',
    flexWrap: 'wrap',
  },
  "& .checkboxText": {
    flexDirection: "column",
    justifyContent: "center",
    color: "var(--text-primary, #F3F3F3)",
    fontSize: "16px",
    display: "flex",
    fontWeight: 400,
    fontFamily: "Outfit",
    fontStyle: "normal",
  },
  "& .ForgotPasswordBox": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  "& .ForgotPassword": {
    cursor:'pointer',
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
      WebkitBackgroundClip: "text",
      backgroundClip: "text",
      fontFamily: "Outfit",
      WebkitTextFillColor: "transparent",
      fontStyle: "normal",
      fontSize: "16px",
    fontWeight: 700,
  },
  "& .forgotpasswordBtn": {
    display: "flex",
    width: "100%",
    marginTop: '56px',
    height: "56px",
    textTransform: 'none',
    padding: "16px",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    borderRadius: "8px",
    alignSelf: "stretch",
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    color: "var(--text-primary, #171717)",
    fontFamily: "Outfit",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
  },
  "& .errorsMsg": {
    fontFamily: "Outfit",
    color: "var(--status-red-400, #F87171)",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
  },
  "& .popoMsgtext": {
    fontFamily: "Outfit",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    color: "var(--text-primary, #F3F3F3)",
  },
  "& .error" :{
    borderColor: "#F87171",
  } , 
  "& .LoginAgain": {
    cursor:'pointer',
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .LoginAgainbox": {
    display: "flex",
    justifyContent: "end",
    alignItems: "end" ,
    flexDirection: "row",
    marginTop : "60px"
  },
}))
// Customizable Area End
