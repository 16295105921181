import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withWidth,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import clsx from 'clsx';
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CustomPagination from "../../../components/src/CustomPagination"
import CustomCheckBox from "../../../components/src/CustomCheckBox.web";

import CustomSelectSearch from "../../../components/src/CustomSelectSearch";
// Customizable Area End

import NewCustomerGroupController, {
  Props,
} from "./NewCustomerGroupController";

export default class NewCustomerGroup extends NewCustomerGroupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() { 
    const {width} = this.props;  
    return (
      // Customizable Area Start
        <NewCutomerGroupsStyle>
          <Grid container className={clsx({"padding30New": width!=='xs', 'padding30xsNew': width == 'xs'})}>
            <Grid container item>
              <Grid item xs={12} md={10} className="tabContainer">
                <Box className="tab">
                  <Box data-test-id='crmBtn' className="tabTextWrapNew" onClick={this.handleNavigateCRM}>
                    <Typography className="tabText">CRM</Typography>
                  </Box>
                  <Box className="gap"/>
                  <Box data-test-id='overviewBtn' className="activeTabNew">
                    <Typography className="activeText">Customer Group</Typography>
                  </Box>
                  <Box className="gap"/>
                  <Box data-test-id='overviewBtn' className="tabTextWrapNew" onClick={this.handleNavigateBroadcast}>
                    <Typography className="tabText">Broadcast</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className="wrapTitle">
              <Typography className='titleText'>Customer Groups</Typography>
            </Grid>
            <Grid item xs={12} md={6} className="wrapTitle">
              <Typography className="labelText">Group Name</Typography>
              <TextField
                data-test-id='name'
                className={'inputField'}
                placeholder="Enter Group name"
                InputProps={{disableUnderline: true, style:{color:"#f3f3f3"} }}
                value={this.state.groupName}
                onChange={(event) => {                          
                  this.onValueChange(event.target.value);            
                }}
                fullWidth />
                {this.state.isErrorInput && <Typography className="errorsMsg">Group name is  mandatory</Typography>}
            </Grid>
            <Grid item xs={12} md={12} className="wrapTitle">
              <TableContainer className={clsx({'width300': width === 'xs', 'width100': width !=='xs', 'test': true })}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align='left' colSpan={6}>
                        <Box>
                          <Typography className="titleText">Select Customers for the group</Typography>
                          <Box className="wrapSelect">
                            <CustomCheckBox checked={this.state.isSelectAll} onChange={this.handleCheckAllItem}/> 
                            <Typography className="contentText">Select all</Typography>
                          </Box>
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                    
                      <StyledTableCell align='left' colSpan={2}>
                        <CustomSelectSearch value={this.state.valueSearchCusNameCRMNew} isCustomerName placeholder={"Customer Name"} listSearchCustomerName={this.state.listSearchCustomerNameCRMNew} onChange={(e)=>this.handleSearchCustomerNameCRMNew(e)} onSelect={(e)=>this.onChangeSearchCRMNew(e, 'name')}/>
                      </StyledTableCell>
                      <StyledTableCell align='left'>
                        <Box className="dobBox">
                          <Typography className="dobText">Date Of Birth</Typography> 
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <CustomSelectSearch value={this.state.valueSearchPhoneCRMNew} isSearch placeholder={"Phone Number"} onChange={(e)=>this.onChangeSearchCRMNew(e, 'contact_number')}/>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <CustomSelectSearch value={this.state.valueSearchEmailCRMNew} isSearch placeholder={"E-mail ID"} onChange={(e)=>this.onChangeSearchCRMNew(e, 'email')}/>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                      <CustomSelectSearch data-test-id={'serviceId'} value={this.state.valueSearchServiceCRMNew.charAt(0).toUpperCase() + this.state.valueSearchServiceCRMNew.slice(1)} placeholder='Interested Service' isService onChange={(e)=>this.onChangeSearchCRMNew(e, 'services')}/>
                      </StyledTableCell>            
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.rowCustomer.map((row, index)=> {
                      return <TableRow>
                        <StyledTableCell align="left"><CustomCheckBox 
                          checked={this.state.selectedCheck[index]} 
                          onChange={()=>this.handleSelectItem(row, index)}/>
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.customerName}</StyledTableCell>
                        <StyledTableCell align="left">{row.dob}</StyledTableCell>
                        <StyledTableCell align="left">{row.phoneNumber}</StyledTableCell>
                        <StyledTableCell align="left">{row.email}</StyledTableCell>
                        <StyledTableCell align="left">{row.interestedService}</StyledTableCell>
                      </TableRow>}
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {this.state.isErrorSelect && <Typography className="errorsMsg">Please Select the customer</Typography>}
              <CustomPagination page={this.state.currentPageCustomerNew} count={this.state.pageCustomer} onChange={this.handleChangePageCustomer}/>
            </Grid>
            <Grid container>
              <Grid item md={3}/>
              <Grid item container md={6} xs={12}>
                <Grid item md={6} xs={12} className="padding10">
                  <Button className="btnWrapCancel" data-test-id='btnCancel' onClick={this.handleCancel}>
                    <Typography className="btnTextCancel">Cancel</Typography>
                  </Button>
                </Grid>
                <Grid item md={6} xs={12} className="padding10">
                  <Button data-test-id='btnSubmit' className="btnWrapAdd" type="submit" onClick={this.handleSubmit}>
                    <Typography className="btnTextAdd">{this.state.isEdit?'Edit a Group':'Create a New Group'}</Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid item md={3}/>
            </Grid>
          </Grid>
        </NewCutomerGroupsStyle>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledTableCell = withStyles((theme)=>({
  head: {
    backgroundColor:(theme as any).typography.body2,
    color: (theme as any).typography.h1,
    fontSize: '14px',
    fontFamily: 'Outfit', 
    fontWeight: 700,
    verticalAlign:'center',
    border: 'none',
    borderCollapse: 'separate',
    borderSpacing: '0px 4px'
    
  },
  body: {
    backgroundColor: (theme as any).typography.body2,
    color: (theme as any).typography.h2,
    fontSize: '16px',
    fontFamily: 'Outfit', 
    fontWeight: 400,
    border: 'none',
    borderCollapse: 'separate',
    borderSpacing: '0px 4px'
  },
}))(TableCell)

const NewCutomerGroupsStyle  = styled(Box)(({ theme }) => ({
  "& td:not(:first-child)":{
    wordBreak:'break-word',
    width:100
  },
  '& td:first-child ': {
   width:'1% !important' // Remove bottom border from the last table row
},
  '& .MuiInputBase-input': {
    color: (theme as any).typography.h2,
    fontFamily:'Outfit',
    fontWeight: 400,
    fontSize: 16,
  },
  "& .errorsMsg":{
    color: "#F87171",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  '& .btnTextCancel': {
    textTransform: 'none',
    color: '#CEAE71', 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  '& .padding10':{
    padding: "10px",
  },
  "& .btnWrapAdd":{
    cursor: 'pointer',
    padding: "16px 0 16px 0", 
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',
    width: '100%',
  },
  "& .btnWrapCancel":{
    cursor: 'pointer',
    padding: "16px 0 16px 0", 
    background: (theme as any).typography.body2,
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',
    width: '100%',
  },
  '& .contentText': {
    color: (theme as any).typography.h2,
    fontSize: 16, 
    fontFamily: 'Outfit', 
    fontWeight: '400',
    marginLeft: 16
  },
  '& .btnTextAdd': {
    textTransform: 'none',
    color: '#171717', 
    fontSize: '16px', 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  }, 
  '& .labelText': {
    color:(theme as any).typography.h1,
    fontWeight: 700,
    fontFamily: "Outfit",
    fontSize:"14px",
  },
  "& .dobText": {
    fontFamily: "Outfit",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .dobBox": {
    height: "32px",
    width:"150px",
    padding: "10px 8px 10px 8px",
    background: (theme as any).typography.body2, 
    borderRadius: "8px",
    border: `1px ${(theme as any).typography.border2} solid`,
    justifyContent: 'flex-start', 
    alignItems: 'center', 
    display: 'inline-flex'
  },
  '& .width300':{
    width: '327px',
    borderRadius: '16px'
  },
  '& .width100':{
    width: '100%',
    borderRadius: '16px'
  },
  '& .inputField': {
    marginTop: '4px',
    borderRadius: 8,
    border: `1px ${(theme as any).typography.border2} solid`,
    background: (theme as any).typography.body2, 
    padding: '10px 8px',
    color: (theme as any).typography.h3, 
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  '& .icon':{
    color:(theme as any).typography.h2,
  },
  '& .itemText':{
    color: (theme as any).typography.h1,
    fontSize: 16,
    fontFamily: 'Outfit', 
    fontWeight: '400'
  },
  '& .groupText':{
    display:'flex',
    flexDirection:'row', 
    gap:60
  },
  '& .wrapItem':{
    backgroundColor:(theme as any).typography.body2,
    display:'flex',
    flexDirection:'row', 
    justifyContent:'space-between', 
    padding:'16px', 
    marginBottom:'16px', 
    borderRadius:'8px'
  },
  '& .wrapSelect':{
    marginTop:'16px',
    display: 'flex',
    flexDirection:'row',
  },
  '& .titleText':{
    color: (theme as any).typography.h1,
    fontSize: 18, 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  '& .wrapTitle':{
    marginTop:'16px'
  },
  '& .padding30New':{
    padding: "30px",
  },
  "& .tabContainer":{
    width:'fit-content', 
  }, 
  '& .activeText': {
    color: "#171717",
    fontFamily: "Outfit",
    fontSize:"18px",
    fontWeight: 700,
    '@media (max-width: 600px)': {
      fontSize: 10,
    },
  },
  
  "& .btnTextApply":{
    color: '#171717', 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '700'
  }, 
  '& .padding30xsNew':{
    width: 'fit-content',
    padding: "30px",
    backgroundColor: "#151515",
  },
  '& .tab': {
    display: "flex",
    borderRadius: "8px",
    padding: "10px",
    backgroundColor: (theme as any).typography.body2, 
    width: "fit-content",
    border: `1px ${(theme as any).typography.border} solid`,
  },
  '& .tabTextWrapNew': {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center',
    cursor: 'pointer',
    padding: "8px",
    borderRadius: "4px",
  },
  '& .tabText': {
    color: (theme as any).typography.h1, 
    fontFamily: "Outfit",
    fontSize:"18px",
    fontWeight: 700,
    '@media (max-width: 600px)': {
      fontSize: 10,
    },
  },
  '& .gap': {
    width:"24px"
  },
  "& .wrapBtnAdd": { 
    width:'100%', 
    height:'fit-content', 
    paddingTop: 10
  },
  '& .activeTabNew': {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center',
    padding: "8px",
    borderRadius: "4px",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))"
  },
  "& .btnWrap":{
    cursor: 'pointer',
    paddingTop: "10px", 
    paddingBottom: "10px", 
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',
    width: '100%',
    maxWidth: '327px'
  },
  
}))
export {NewCustomerGroup}

withWidth()(NewCustomerGroup);
// Customizable Area End