import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
import {getStorageData, setStorageData} from "framework/src/Utilities"
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showPassword: boolean;
    showConfirmPassword: boolean;
    token : any;
    newPassword: string;
    confirmNewPassword: string;
    error:{upper:boolean,number:boolean,minimum:boolean,special:boolean};
    errorValue:string;
    formIsDirty: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    postResetPasswordApiId: string = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionResponseData),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.state = {
            showPassword: false,
            showConfirmPassword:false,
            token : '',
            newPassword:'',
            confirmNewPassword: '',
            error:{upper:false,number:false,minimum:false,special:false},
            errorValue:'',
            formIsDirty:false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const token = await getStorageData("authToken")
        this.setState({token : token})
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.postResetPasswordApiId && responseJson) {
                this.handleResetPassword(responseJson)
            }
        }
        // Customizable Area End
    }

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }

    handleShowConfirm = () => {
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword })
    }

    onValueChange = (name: string, value: string) => {
        this.setState({ ...this.state, [name]: value,
            error:{
            upper: /^(?=.*[a-z])(?=.*[A-Z])/.test(value) ? true : false,
            number: /^(?=.*\d)/.test(value) ? true : false,
            minimum:/^.{8,}$/.test(value) ? true : false,
            special:/^(?=.*[!@#\$%\^&\*])/.test(value) ? true : false,
        },
        errorValue:value 
    }
        );
    };
    onValueChangecon = (name: string, value: string) => {
        this.setState({ ...this.state, [name]: value, error:{
            upper: /^(?=.*[a-z])(?=.*[A-Z])/.test(value) ? true : false,
            number: /^(?=.*\d)/.test(value) ? true : false,
            minimum:/^.{8,}$/.test(value) ? true : false,
            special:/^(?=.*[!@#\$%\^&\*])/.test(value) ? true : false,
        }, errorValue:value }
        );
        
    };
    handleResetPassword = (responseJson: any) => {
        if(responseJson.data.attributes.is_reset_password === true){
        this.props.navigation.navigate("EmailAccountLoginBlock")
       }
    }

    checkSubmit = () => {
        this.setState({...this.state, formIsDirty: true})
      }

    resetPasswordApi = () => {

        // Function to extract token from URL
        
        const url = window.location.search
        const token = url.split("?token=")[1];
        this.setState({token:token})
        setStorageData('authToken', this.state.token)
        const header = {
            "Content-Type": "application/json",
            "token": token
        };
        let formDataCampaign =  {
            "password" : this.state.newPassword,
            "confirm_password": this.state.confirmNewPassword,
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.profileResetPasswordAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPutMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(formDataCampaign)
        );
        this.postResetPasswordApiId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End

}