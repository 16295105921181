import React, {  } from "react";

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    BlobProvider,
    Image,
    Font,
} from "@react-pdf/renderer";
import moment from "moment";

const BoldFont = require("./fonts/TimesNewRomanBold.ttf");

type Props = {
    isSave: boolean;
    setPdf: any;
    data: any;
};


Font.register({
    family: 'RegularMer',
    src: require('./fonts/MerriweatherSans-Regular.ttf'),
    fontWeight: 'normal',
});
Font.register({
    family: 'BoldMer',
    src: require('./fonts/MerriweatherSans-Bold.ttf'),
    fontWeight: 'bold',
});
Font.register({
    family: 'BoldItaMer',
    src: require('./fonts/MerriweatherSans-BoldItalic.ttf'),
});

Font.register({
    family: 'ItaMer',
    src: require('./fonts/MerriweatherSans-Italic.ttf'),
});


Font.register({
    family: 'RegularHel',
    src: require('./fonts/helvetica_regular.ttf'),
    fontWeight: 'normal',
});
Font.register({
    family: 'BoldHel',
    src: require('./fonts/helvetica_bold.ttf'),
    fontWeight: 'bold',
});



const styles = StyleSheet.create({
    textMer: {
        fontFamily: 'RegularMer',
        fontSize: 7,
    },
    textMerIta: {
        fontFamily: 'ItaMer',
        fontSize: 6,
    },
    textMer5: {
        fontFamily: 'RegularMer',
        fontSize: 5,
    },
    textMer9: {
        fontFamily: 'RegularMer',
        fontSize: 9,
    },
    textMerBold: {
        fontFamily: 'BoldMer',
        fontSize: 11,
    },
    textMerBoldIta: {
        fontFamily: 'BoldItaMer',
        fontSize: 11,
    },
    textHel8: {
        fontFamily: 'RegularHel',
        fontSize: 8,
    },
    textHel6: {
        fontFamily: 'RegularHel',
        fontSize: 6,
    },
    textHel7: {
        fontFamily: 'RegularHel',
        fontSize: 7,
    },
    textHelBold: {
        fontFamily: 'BoldHel',
        fontSize: 10,
    },
    textHelBold6Normal: {
        fontFamily: 'BoldHel',
        fontSize: 6,

    },
    textHelBold8: {
        fontFamily: 'BoldHel',
        fontSize: 8,
    },

});


const pStyles = StyleSheet.create({

    page: {
        backgroundColor: "#ffffff",
        padding: '12.17px 13.55px 0px  13.55px',
        letterSpacing: 0
    },

    page2: {
        backgroundColor: "#ffffff",
        padding: '74px 74px',
        letterSpacing: 0
    },
});

const Doc1 = ({data, dob, dobGuarantor}: any) => (<>
    <Document>
        <Page size="A4" style={pStyles.page}>
            <View>
                <View style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                    <View>
                        <Image src={require("../src/vmpdflogo.png")} style={{ width: 154.18, height: 43.86 }} />
                        <Text style={{ ...styles.textHelBold6Normal, marginTop: 4.7 }}>Part B: auto Financing Application Form</Text>
                    </View>



                </View>


                <View style={{ marginTop: 2, border: "1px solid #000" }}>
                    <View style={{ padding: '4px' }}>
                        <Text style={styles.textHel6}>Please enclose the following documents, where applicable, with your application</Text>

                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ flex: 1, }}>

                                <Text style={styles.textHel6}>
                                    &#8226; A Photocopy of your NRIC(front and back) or passport
                                </Text>
                                <Text style={styles.textHel6}>
                                    &#8226; Copy of vehicle Sales & Purchase agreement
                                </Text>
                                <Text style={styles.textHel6}>
                                    &#8226; For salaried employee(any of the Following):

                                </Text>
                                <Text style={{ ...styles.textHel6, marginLeft: 18 }}>Latest computerised payslip, last 12 month’s CPF Contribution History OR</Text>
                                <Text style={{ ...styles.textHel6, marginLeft: 18 }}>
                                    Latest Income Tax Notice of Assessment
                                </Text>
                            </View>
                            <View style={{ flex: 1, }}>


                                <Text style={styles.textHel6}>
                                    • For self-employed and commission earners: Last two years' NOA

                                </Text>
                                <Text style={styles.textHel6}>

                                    Additional documents ot be submitted:
                                </Text>
                                <Text style={styles.textHel6}>
                                    •For used Vehicle purpose : Copy of : LTA Acknowledgement

                                </Text>
                                <Text style={{ ...styles.textHel6, marginLeft: 18 }}> You can now print your Notices of Assessment at my Tax portal with your SingPass.</Text>
                                <Text style={{ ...styles.textHel6, marginLeft: 24 }}>
                                    The service is free. Log on to www.mytax.iras.gov.sg for more details
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ padding: '2.74px 6.79px', backgroundColor: '#000' }}>
                        <Text style={{ ...styles.textHelBold6Normal, textAlign: "center", color: "#fff" }}>PERSONAL PARTICULARS</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <View style={{ flex: 1, }}>
                            <View style={{ borderBottom: "1px solid #000", padding: "2px" ,borderRight: "1px solid #000"}}>
                                <Text style={{ ...styles.textHelBold6Normal, textAlign: "center" }}>Main Applicant</Text>
                            </View>
                            <View style={{ flexDirection: "row", borderBottom: "1px solid #000", borderRight: "1px solid #000" }}>
                                <View style={{ flex: 1, padding: "2px " }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>Salutation</Text>
                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "space-evenly" }}>
                                        <Text style={{ ...styles.textHel6 }}> Dr</Text>
                                        <Text style={{ ...styles.textHel6 }}> Mr</Text>
                                        <Text style={{ ...styles.textHel6 }}> Mdm</Text>
                                        <Text style={{ ...styles.textHel6 }}> Mrs</Text>
                                        <Text style={{ ...styles.textHel6 }}> Ms</Text>
                                    </View>
                                </View>
                                <View style={{ flex: 1, padding: "2px " }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>Marital status</Text>
                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "space-evenly" }}>

                                        <Text style={{ ...styles.textHel6 }}> Single</Text>
                                        <Text style={{ ...styles.textHel6 }}> Married</Text>
                                        <Text style={{ ...styles.textHel6 }}> Others</Text>

                                    </View>
                                </View>
                            </View>
                            <View style={{ height: 30, borderBottom: "1px solid #000", borderRight: "1px solid #000", padding: "2px" }}>
                                <Text style={{ ...styles.textHelBold6Normal }}> Full Name sa ni NRIC/ Passport / ACRA for Companies</Text>
                                <Text style={{ ...styles.textHelBold6Normal }}>{data.fullName}</Text>
                            </View>
                            <View style={{ height: 20, borderBottom: "1px solid #000", flexDirection: "row", borderRight: "1px solid #000", padding: "2px" }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> NRIC/ Company Reg No</Text>
                                    <Text style={{ ...styles.textHelBold6Normal }}>{data.NRIC}</Text>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Date of Birth (d dm/ mY/ VVY)</Text>
                                    <Text style={{ ...styles.textHelBold6Normal }}>{dob}</Text>
                                </View>

                            </View>
                            <View style={{ flexDirection: "row", borderBottom: "1px solid #000", borderRight: "1px solid #000" }}>
                                <View style={{ flex: 1, padding: "2px " }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>Nationality</Text>
                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "space-evenly" }}>
                                        <Text style={{ ...styles.textHel6 }}> Singaporean</Text>
                                        <Text style={{ ...styles.textHel6 }}>     Singapore PR</Text>
                                        <Text style={{ ...styles.textHel6 }}>  Others _______________</Text>

                                    </View>
                                </View>

                            </View>
                            <View style={{ flexDirection: "row", borderBottom: "1px solid #000", borderRight: "1px solid #000" }}>
                                <View style={{ flex: 1, padding: "2px " }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>Property Ownership</Text>
                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "space-evenly" }}>
                                        <Text style={{ ...styles.textHel6 }}> Owned</Text>
                                        <Text style={{ ...styles.textHel6 }}> Rented</Text>
                                        <Text style={{ ...styles.textHel6 }}> Mortgaged</Text>
                                        <Text style={{ ...styles.textHel6 }}> Parents</Text>
                                        <Text style={{ ...styles.textHel6 }}> Spouse</Text>
                                    </View>
                                </View>

                            </View>
                            <View style={{ height: 30, borderBottom: "1px solid #000", borderRight: "1px solid #000", padding: "2px" }}>
                                <Text style={{ ...styles.textHelBold6Normal }}> Home Address</Text>
                                <Text style={{ ...styles.textHelBold6Normal }}>{data.address}</Text>
                            </View>
                            <View style={{ height: 25, borderBottom: "1px solid #000", borderRight: "1px solid #000", padding: "2px" }}>
                                <Text style={{ ...styles.textHelBold6Normal }}> Mailing Address ( <Text style={styles.textMerIta}>if Different from above</Text>)</Text>
                            </View>
                            <View style={{ height: 20, borderBottom: "1px solid #000", flexDirection: "row", borderRight: "1px solid #000", padding: "2px" }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Home No:</Text>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Office No:</Text>
                                </View>

                            </View>
                            <View style={{ height: 20, borderBottom: "1px solid #000", flexDirection: "row", borderRight: "1px solid #000", padding: "2px" }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Mobile No:</Text>
                                    <Text style={{ ...styles.textHelBold6Normal }}>{data.contactNumber}</Text>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Email Address:</Text>
                                    <Text style={{ ...styles.textHelBold6Normal }}>{data.emailID}</Text>
                                </View>

                            </View>
                        </View>
                        <View style={{ flex: 1, }}>
                            <View style={{ borderBottom: "1px solid #000", padding: "2px" }}>
                                <Text style={{ ...styles.textHelBold6Normal, textAlign: "center" }}> Guarantor</Text>
                            </View>
                            <View style={{ flexDirection: "row", borderBottom: "1px solid #000",  }}>
                                <View style={{ flex: 1, padding: "2px " }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>Salutation</Text>
                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "space-evenly" }}>
                                        <Text style={{ ...styles.textHel6 }}> Dr</Text>
                                        <Text style={{ ...styles.textHel6 }}> Mr</Text>
                                        <Text style={{ ...styles.textHel6 }}> Mdm</Text>
                                        <Text style={{ ...styles.textHel6 }}> Mrs</Text>
                                        <Text style={{ ...styles.textHel6 }}> Ms</Text>
                                    </View>
                                </View>
                                <View style={{ flex: 1, padding: "2px " }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>Marital status</Text>
                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "space-evenly" }}>

                                        <Text style={{ ...styles.textHel6 }}> Single</Text>
                                        <Text style={{ ...styles.textHel6 }}> Married</Text>
                                        <Text style={{ ...styles.textHel6 }}> Others</Text>

                                    </View>
                                </View>
                            </View>
                            <View style={{ height: 30, borderBottom: "1px solid #000", padding: "2px" }}>
                                <Text style={{ ...styles.textHelBold6Normal }}> Full Name sa ni NRIC/ Passport / ACRA for Companies</Text>
                                <Text style={{ ...styles.textHelBold6Normal }}>{data.guarantor[0] && data.guarantor[0].fullNameGuarantor}</Text>
                            </View>
                            <View style={{ height: 20, borderBottom: "1px solid #000", flexDirection: "row",padding: "2px" }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> NRIC/ Company Reg No</Text>
                                    <Text style={{ ...styles.textHelBold6Normal }}>{data.guarantor[0] && data.guarantor[0].NRICGuarantor}</Text>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Date of Birth (d dm/ mY/ VVY)</Text>
                                    <Text style={{ ...styles.textHelBold6Normal }}>{dobGuarantor}</Text>
                                </View>

                            </View>
                            <View style={{ flexDirection: "row", borderBottom: "1px solid #000",  }}>
                                <View style={{ flex: 1, padding: "2px " }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>Nationality</Text>
                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "space-evenly" }}>
                                        <Text style={{ ...styles.textHel6 }}> Singaporean</Text>
                                        <Text style={{ ...styles.textHel6 }}>     Singapore PR</Text>
                                        <Text style={{ ...styles.textHel6 }}>  Others _______________</Text>

                                    </View>
                                </View>

                            </View>
                            <View style={{ flexDirection: "row", borderBottom: "1px solid #000",  }}>
                                <View style={{ flex: 1, padding: "2px " }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>Property Ownership</Text>
                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "space-evenly" }}>
                                        <Text style={{ ...styles.textHel6 }}> Owned</Text>
                                        <Text style={{ ...styles.textHel6 }}> Rented</Text>
                                        <Text style={{ ...styles.textHel6 }}> Mortgaged</Text>
                                        <Text style={{ ...styles.textHel6 }}> Parents</Text>
                                        <Text style={{ ...styles.textHel6 }}> Spouse</Text>
                                    </View>
                                </View>

                            </View>
                            <View style={{ height: 30, borderBottom: "1px solid #000",  padding: "2px" }}>
                                <Text style={{ ...styles.textHelBold6Normal }}> Home Address</Text>
                                <Text style={{ ...styles.textHelBold6Normal }}>{data.guarantor[0] && data.guarantor[0].addressGuarantor}</Text>
                            </View>
                            <View style={{ height: 25, borderBottom: "1px solid #000",  padding: "2px" }}>
                                <Text style={{ ...styles.textHelBold6Normal }}> Mailing Address ( <Text style={styles.textMerIta}>if Different from above</Text>)</Text>
                            </View>
                            <View style={{ height: 20, borderBottom: "1px solid #000", flexDirection: "row",  padding: "2px" }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Home No:</Text>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Office No:</Text>
                                </View>

                            </View>
                            <View style={{ height: 20, borderBottom: "1px solid #000", flexDirection: "row",  padding: "2px" }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Mobile No:</Text>
                                    <Text style={{ ...styles.textHelBold6Normal }}>{data.guarantor[0] && data.guarantor[0].contactNumberGuarantor}</Text>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Email Address:</Text>
                                    <Text style={{ ...styles.textHelBold6Normal }}>{data.guarantor[0] && data.guarantor[0].emailIDGuarantor}</Text>
                                </View>

                            </View>
                        </View>


                    </View>
                    <View style={{ padding: '2.74px 6.79px', backgroundColor: '#000' }}>
                        <Text style={{ ...styles.textHelBold6Normal, textAlign: "center", color: "#fff" }}>EMPLOYMENT DETAILS</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <View style={{ flex: 1, }}>


                            <View style={{ flexDirection: "row", borderBottom: "1px solid #000", borderRight: "1px solid #000" }}>
                                <View style={{ flex: 1, padding: "2px " }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>Employment Status </Text>

                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "space-evenly" }}>
                                        <Text style={{ ...styles.textHel6 }}> Employee</Text>
                                        <Text style={{ ...styles.textHel6 }}>     Self- Employed </Text>
                                        <Text style={{ ...styles.textHel6 }}>     Commission-base         </Text>
                                        <Text style={{ ...styles.textHel6 }}>  Others _______________</Text>

                                    </View>
                                </View>

                            </View>

                            <View style={{ height: 30, borderBottom: "1px solid #000", borderRight: "1px solid #000", padding: "2px" }}>
                                <Text style={{ ...styles.textHelBold6Normal }}> Name of employer/ Business and Office Address</Text>
                                <Text style={{ ...styles.textHelBold6Normal }}> {data.employer} / {data.employmentAddress}</Text>
                            </View>
                            <View style={{ height: 20, borderBottom: "1px solid #000", flexDirection: "row", borderRight: "1px solid #000", padding: "2px" }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Job Title</Text>
                                    <Text style={{ ...styles.textHelBold6Normal }}>{data.jobTitle}</Text>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Length of Service</Text>
                                </View>

                            </View>
                            <View style={{ height: 20, borderBottom: "1px solid #000", flexDirection: "row", borderRight: "1px solid #000", padding: "2px" }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Monthly Fixed Income </Text>
                                    <Text style={{ ...styles.textHelBold6Normal }}>S${data.grossMonthlySalary}</Text>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Other Monthly Income & Source</Text>
                                </View>

                            </View>
                            <View style={{ height: 25, borderBottom: "1px solid #000", borderRight: "1px solid #000", padding: "2px" }}>
                                <Text style={{ ...styles.textHelBold6Normal }}> Name of Previous Employer/Business ( <Text style={styles.textMerIta}>if Current employment is &#60; 1yr</Text>)</Text>

                            </View>

                            <View style={{ height: 20, borderBottom: "1px solid #000", flexDirection: "row", borderRight: "1px solid #000", padding: "2px" }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Previous job Title</Text>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Length of service </Text>
                                </View>

                            </View>
                        </View>
                        <View style={{ flex: 1, }}>

                            <View style={{ flexDirection: "row", borderBottom: "1px solid #000" }}>
                                <View style={{ flex: 1, padding: "2px " }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>Employment Status </Text>

                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "space-evenly" }}>
                                        <Text style={{ ...styles.textHel6 }}> Employee</Text>
                                        <Text style={{ ...styles.textHel6 }}>     Self- Employed </Text>
                                        <Text style={{ ...styles.textHel6 }}>     Commission-base         </Text>
                                        <Text style={{ ...styles.textHel6 }}>  Others _______________</Text>

                                    </View>
                                </View>

                            </View>

                            <View style={{ height: 30, borderBottom: "1px solid #000",  padding: "2px" }}>
                                <Text style={{ ...styles.textHelBold6Normal }}> Name of employer/ Business and Office Address</Text>
                                <Text style={{ ...styles.textHelBold6Normal }}> {data.guarantor[0] && data.guarantor[0].employerGuarantor} / {data.guarantor[0] && data.guarantor[0].employmentAddressGuarantor}</Text>
                            </View>
                            <View style={{ height: 20, borderBottom: "1px solid #000", flexDirection: "row",  padding: "2px" }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Job Title</Text>
                                    <Text style={{ ...styles.textHelBold6Normal }}>{data.guarantor[0] && data.guarantor[0].jobTitleGuarantor}</Text>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Length of Service</Text>
                                </View>

                            </View>
                            <View style={{ height: 20, borderBottom: "1px solid #000", flexDirection: "row",   padding: "2px" }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Monthly Fixed Income </Text>
                                    <Text style={{ ...styles.textHelBold6Normal }}>S${data.guarantor[0] && data.guarantor[0].grossMonthlySalaryGuarantor}</Text>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Other Monthly Income & Source</Text>
                                </View>

                            </View>
                            <View style={{ height: 25, borderBottom: "1px solid #000",   padding: "2px" }}>
                                <Text style={{ ...styles.textHelBold6Normal }}> Name of Previous Employer/Business ( <Text style={styles.textMerIta}>if Current employment is &#60; 1yr</Text>)</Text>

                            </View>

                            <View style={{ height: 20, borderBottom: "1px solid #000", flexDirection: "row", padding: "2px" }}>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Previous job Title</Text>
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Length of service </Text>
                                </View>

                            </View>
                        </View>


                    </View>
                    <View style={{ padding: '2.74px 6.79px', backgroundColor: '#000' }}>
                        <Text style={{ ...styles.textHelBold6Normal, textAlign: "center", color: "#fff" }}>FINANCIAL COMMITMENTS </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <View style={{ flex: 1, }}>
                            <View style={{ flexDirection: "row", borderBottom: "1px solid #000", borderRight: "1px solid #000" }}>
                                <View style={{ flex: 1, padding: "4px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Type of Facilities (Housing Loan, Credit Card, Car Loan, etc.) Monthiy Repayment  </Text>

                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "space-between" }}>
                                        <Text style={{ ...styles.textHel6 }}> 1)  _____________________________________________</Text>
                                        <Text style={{ ...styles.textHel6 }}>    _____________________ </Text>


                                    </View>
                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "space-between" }}>
                                        <Text style={{ ...styles.textHel6 }}> 2)  _____________________________________________</Text>
                                        <Text style={{ ...styles.textHel6 }}>    _____________________ </Text>


                                    </View>
                                </View>

                            </View>
                        </View>
                        <View style={{ flex: 1, }}>
                            <View style={{ flexDirection: "row", borderBottom: "1px solid #000" }}>
                                <View style={{ flex: 1, padding: "4px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Type of Facilities (Housing Loan, Credit Card, Car Loan, etc.) Monthiy Repayment  </Text>

                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "space-between" }}>
                                        <Text style={{ ...styles.textHel6 }}> 1)  _____________________________________________</Text>
                                        <Text style={{ ...styles.textHel6 }}>    _____________________ </Text>


                                    </View>
                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "space-between" }}>
                                        <Text style={{ ...styles.textHel6 }}> 2)  _____________________________________________</Text>
                                        <Text style={{ ...styles.textHel6 }}>    _____________________ </Text>


                                    </View>
                                </View>

                            </View>
                        </View>


                    </View>
                    
                    <View style={{ padding: '2.74px 6.79px', backgroundColor: '#000' }}>
                        <Text style={{ ...styles.textHelBold6Normal, textAlign: "center", color: "#fff" }}>VEHCILE INFORMATION </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <View style={{ flex: 1, }}>
                            <View style={{ flexDirection: "row",height:28, borderBottom: "1px solid #000", borderRight: "1px solid #000" }}>
                                <View style={{ flex: 1, padding: "4px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>  Vehicle Type   </Text>

                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "center" }}>
                                        <View style={{width:100}}>
                                        <Text style={{ ...styles.textHelBold6Normal }}> New        X       Used                     COE </Text>
                                        </View>
                                      
                                     

                                    </View>
                                   
                                </View>
                                <View style={{ flex: 1, padding: "4px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>   OMV </Text>

                                    <View style={{ flexDirection: "row", padding: "2px ", alignItems: "center", justifyContent: "flex-end" }}>
                                        <View style={{ width:120}}>
                                        <Text style={{ ...styles.textHelBold6Normal }}> &#60;$20,000             &#62;$20,000 </Text>
                                        </View>
                                       
                                     

                                    </View>
                                   
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", height:25, borderRight:"1px solid #000",borderBottom: "1px solid #000" }}>
                                <View style={{ flex: 1, padding: "4px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>For used vehicle under financing, please state financing institution:  </Text>
 
                                     
                                </View>
                                 
                            </View>
                            <View style={{ flexDirection: "row", height:20, borderRight: "1px solid #000" }}>
                                <View style={{ flex: 1, padding: "4px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Make/ Model {data.vehicleMake}/{data.vehicleModel} </Text>
 
                                     
                                </View>
                                
                            </View>
                            <View style={{ flexDirection: "row", height:20, borderRight: "1px solid #000" }}>
                                <View style={{ flex: 1, padding: "4px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Vehicle Reg No: {data.vehicleNumber} </Text>
 
                                     
                                </View>
                                <View style={{ flex: 1, padding: "4px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Year of Manufacture: {data.vehicleManufactured} </Text>
 
                                     
                                </View>
                                
                            </View>
                            <View style={{ flexDirection: "row", height:20, borderRight: "1px solid #000" , borderBottom:"1px solid #000" }}>
                                <View style={{ flex: 1, padding: "4px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>Engine No:  {data.engineNumber}  </Text>
 
                                     
                                </View>
                                <View style={{ flex: 1, padding: "4px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Chassis No: {data.chassisNumber} </Text>
 
                                     
                                </View>
                                
                            </View>
                        </View>
                        <View style={{ flex: 1, }}>
                            <View style={{ flexDirection: "row", height:28, borderBottom: "1px solid #000" }}>
                                <View style={{ flex: 1, padding: "4px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Purchase Price  {data.vehiclePrice} </Text>
 
                                     
                                </View>
                                <View style={{ flex: 1, padding: "4px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> OMV (S$)  </Text>
 
                                     
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", height:25, borderBottom: "1px solid #000" }}>
                                <View style={{ flex: 1, padding: "4px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>Name of Dealer   </Text>
 
                                     
                                </View>
                                 
                            </View>
                            <View style={{ flexDirection: "row", height:20,  }}>
                                <View style={{ flex: 1, padding: "4px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Company Reg No   </Text>
 
                                     
                                </View>
                                <View style={{ flex: 1, padding: "4px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Salesperson Name  </Text>
 
                                     
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", borderBottom: "1px solid #000"  }}>
                                <View style={{ flex: 1, padding: "5px 2px" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}>Dealer's Declaration: 
                                    <Text style={{ ...styles.textHel6 }}>
                                    We confirm that the information given ni this application are true and correct and that we have also verified
                                        the identities of the applicant against their 
                                    </Text>
                                     </Text>
 
                                     
                                </View>
                                <View style={{ flex: 1, padding: "4px 2px" , flexDirection:"column",justifyContent:"flex-end" }}>
                                    <Text style={{ ...styles.textHelBold6Normal }}> Dealer’s stamp & Authorised Signatory</Text>
 
                                     
                                </View>
                            </View>
                        </View>

                        
                    </View>
                    <View style={{ padding: '2.74px 6.79px', backgroundColor: '#000' }}>
                        <Text style={{ ...styles.textHelBold6Normal, textAlign: "center", color: "#fff" }}>DECLARATION PURSUANT TO MAS NOTICE 642 </Text>
                    </View>
                    <View style={{ padding: "2px 5px" }}>
                        <Text style={styles.textHelBold6Normal}>
                            I, the undersigned, hereby declare as follows:

                        </Text>
                        <Text style={{...styles.textHelBold6Normal,marginLeft:5}}> 1) Whether I have received nya discount, rebate ro nay other benefit or from the vendor for the Vehicle or any other person:</Text>

                        <View style={{width:"90%",padding:"4px",margin:"auto"}}>
                            <Text style={styles.textHelBold6Normal}>Yes, the type of benefits) I/we received is/are___________________ and amount received / value of the item is S$____________________
                            </Text >
                            <Text style={styles.textHelBold6Normal}> No , I/we did not receive any benefit(s) .</Text>
                        </View>

                        
                    </View>
                    <View style={{ padding: "2px 5px", margin: "10px 0px" }}>

                        <Text style={{ ...styles.textHelBold6Normal, marginLeft: 5 }}> 2) Whether I have obtained other credit ro hire purchase facility(ies) ofr the purchase or hire (as the case may be) of the Vehicle:</Text>

                        <View style={{ width: "90%", padding: "4px", margin: "auto" }}>
                            <Text style={styles.textHelBold6Normal}>  Yes, the type of credit facility(ies) I/we received is/are and amount received is $S_____________________
                            </Text >
                            <Text style={styles.textHelBold6Normal}>No , I/we did not obtain any credit facility..</Text>
                        </View>


                    </View>
                   
          
                    
               </View>
            </View>

        </Page>  
        <Page size="A4" style={pStyles.page}>
            <View>
                <View style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                    <View>
                        <Image src={require("../src/vmpdflogo.png")} style={{ width: 154.18, height: 43.86 }} />

                    </View>



                </View>


                <View style={{ marginTop: 2, border: "1px solid #000" }}>

                    <View style={{ padding: '2.74px 6.79px', backgroundColor: '#000' }}>
                        <Text style={{ ...styles.textHelBold6Normal, textAlign: "center", color: "#fff" }}>FINANCIAL REQUEST</Text>
                    </View>
                    <View style={{ flexDirection: "row",}}>
                        <View style={{ flex: 1, padding: "2px " }}>
                            <Text style={{ ...styles.textHelBold6Normal }}>Net Purchase Price</Text>
                            <Text style={{ ...styles.textHelBold6Normal }}>S$</Text>
                             
                        </View>
                        <View style={{ flex: 1, padding: "2px " }}>
                            <Text style={{ ...styles.textHelBold6Normal }}>Deposit Paid     S$
                            </Text>
                            <Text style={{ ...styles.textHelBold6Normal }}>s $</Text>

                        </View>
                        <View style={{ flex: 1, padding: "2px " }}>

                            <Text style={{ ...styles.textHelBold6Normal }}>Amount Required</Text>
                            <Text style={{ ...styles.textHelBold6Normal }}>S$</Text>
                             
                        </View>
                       
                     
                    </View>
                    <View style={{ flexDirection: "row", borderBottom: "1px solid #000",}}>
                        <View style={{ flex: 1, padding: "2px " }}>
                            <Text style={{ ...styles.textHelBold6Normal }}>Repayment Period</Text>
                            
                            <Text style={{ ...styles.textHelBold6Normal ,textAlign:"right"}}>{data.tenure}</Text>
                        </View>
                        <View style={{ flex: 1, padding: "2px ",flexDirection:"row" }}>
                            <View style={{ flex: 1,   }}>
                            <Text style={{ ...styles.textHelBold6Normal }}>Applied interest Rate
                            </Text>
                            <Text style={{ ...styles.textHelBold6Normal ,textAlign:"right"}}>%p.a.</Text>
                            </View>
                            <View style={{ flex: 1,   }}>
                            <Text style={{ ...styles.textHelBold6Normal }}>Effective interest Rate
                            </Text>
                            <Text style={{ ...styles.textHelBold6Normal ,textAlign:"right"}}>%p.a.</Text>
                            </View>

                        </View>
                        <View style={{ flex: 1, padding: "2px " }}>

                            <Text style={{ ...styles.textHelBold6Normal }}>Monthly Instalment</Text>
                            <Text style={{ ...styles.textHelBold6Normal }}>S$</Text>
                             
                        </View>
                       
                     
                    </View>
                    <View style={{ padding: '2.74px 6.79px', backgroundColor: '#000' }}>
                        <Text style={{ ...styles.textHelBold6Normal, textAlign: "center", color: "#fff" }}>DECLARATION BY APPLICANT / GUARANTOR</Text>
                    </View>
                    <View style={{ ...styles.textHelBold6Normal,padding:"5px" }}>
                        <Text style={{ ...styles.textHelBold6Normal, }}>BY APPLICANT</Text>

                        <Text style={{ ...styles.textHelBold6Normal,marginLeft:5 , marginBottom:4 }}>1) I am of full age and am/are not bound ro affected by any legal impediment from entering into a Hire Purchase Agreement with you or from driving any vehicle.</Text>

                       <Text style={{ ...styles.textHelBold6Normal,marginLeft:5 , marginBottom:4 }}>
                            2) I here by declare that no person whom I intend to authorise to drive the vehicle is bound or affected by any legal impediment from driving any vehicle.
                        </Text>

                       <Text style={{ ...styles.textHelBold6Normal,marginLeft:5 , marginBottom:4 }}>
                            3) I agree that this facility is granted by you in reliance upon my representations as declared herein and such other correspondence between us in relation thereto. fI what I have told you is
                            incomplete or inaccurate ni any way, l agree that you reserve the right ot reduce, cancel or not approve the facility
                        </Text>

                       <Text style={{ ...styles.textHelBold6Normal,marginLeft:5 , marginBottom:4 }}>
                            4) I undertake ot pay cancelation fees and any and al out-of-pocket expenses and/or charges including cancelation fees insured by you ni relation ot my olan application after my loan
                            application si approved.
                        </Text>

                       <Text style={{ ...styles.textHelBold6Normal,marginLeft:5 , marginBottom:4 }}>
                            4) I undertake ot pay cancelation fees and any and al out-of-pocket expenses and/or charges including cancelation fees insured by you ni relation ot my olan application after my loan
                            application si approved.
                        </Text>

                       <Text style={{ ...styles.textHelBold6Normal,marginLeft:5 , marginBottom:4 }}>
                            5) I understand that you reserve the right to decline the application without giving any reason(s) whatsoever
                        </Text>

                       <Text style={{ ...styles.textHelBold6Normal,marginLeft:5 , marginBottom:4 }}>
                            6) I undertake ot provide you with hte actual Open Market Value O(MV) fo hte vehicle upon registration of hte said vehicle.
                        </Text>

                       <Text style={{ ...styles.textHelBold6Normal,marginLeft:5 , marginBottom:4 }}>
                            7) Except as already disclosed ot you, Ideclare that Ihave not received any discount, rebate or any other benefit from the vendor or any other person, which has the efect of reducing the True
                            Purchase Price (as defined ni MAS 642 dated 25 Feb 13 and as may be replaced, supplemented or amended from time to time).
                        </Text>

                       <Text style={{ ...styles.textHelBold6Normal,marginLeft:5 , marginBottom:4 }}>
                            8) Except sa already disclosed ot you, I/we have not obtained any other credit facility for hte purchase or hire of the vehicle.
                        </Text>

                       <Text style={{ ...styles.textHelBold6Normal,marginLeft:5 , marginBottom:4 }}>
                            9) Iam the borrower of the loan and shall only use the loan for legal purposes.
                        </Text>


                    </View>
                    <View style={{ ...styles.textHelBold6Normal, padding: "5px" }}>
                        <Text style={{ ...styles.textHelBold6Normal, }}>BY APPLICANT AND GUARANTOR</Text>

                        <Text style={{ ...styles.textHelBold6Normal, marginLeft: 5, marginBottom: 4 }}>1) I/We hereby authorise you to enquire from my/our employer(s), particulars of my/our employment with my/our employer(s) and ot obtain and verify such further information about me/
                            us as you deem necessary, for the purposes of this application.</Text>

                        <Text style={{ ...styles.textHelBold6Normal, marginLeft: 5, marginBottom: 4 }}>
                            2) I/We authorise you to obtain and/or verify and/or disclose and/or release any information about me/us and/or any information or any particulars of my/our accounts and credit facilities
                            from/with such party ro person(s) sa you deem necessary for the purposes of this application.
                        </Text>

                        <Text style={{ ...styles.textHelBold6Normal, marginLeft: 5, marginBottom: 4 }}>
                            3) I/We hereby authorise and give you consent to conduct credit checks on me/us (including but not limited to checks with any credit bureau recognised as such by the Monetary Authority of
                            Singapore) and to obtain, verify and/or to disclose or release any information relating to me/us and/or any of my/our account(s) with you from/ with/to any other party or person(s) as
                            you deem necessary for the purposes of this application.
                        </Text>

                        <Text style={{ ...styles.textHelBold6Normal, marginLeft: 5, marginBottom: 4 }}>
                            4) I/We authorise you (i) to disclose any information provided by me/us ni relation ot this application, or (i) ot provide this application form duly filed and signed by me/us ot any car
                            dealership or such other third party that you ni good faith deem ti reasonable to make such disclosure for any purposes ni connection with this application and the loan, or as may be
                            required by any applicable law, court, regulatory or legal process, without any liability or notice to me/us
                        </Text>



                        <Text style={{ ...styles.textHelBold6Normal, marginLeft: 5, marginBottom: 4 }}>
                            5) If this application and such other form or instruction ("Instruction") si or si purported to be given or sent by me/us to you by electronic or facsimile transmission, you are
                            hereby authorised by me/us to, but are not obliged ot accept, rely upon and act ni accordance with the electronic or facsimile copy of the Instruction, without waiting for the original
                            Instruction and without owing, taking on or incurring any liability ot me/us. nI consideration of you agreeing to act on my/our authorization, /we undertake ot indemnify you against al
                            liabilities, claims, demands, actions, proceedings, losses, damages, costs (including legal costs on a full indemnity basis), expenses and al other liabilities which you may directly or
                            indirectly suffer.
                        </Text>

                        <Text style={{ ...styles.textHelBold6Normal, marginLeft: 5, marginBottom: 4 }}>
                            6) If there is a cancellation or termination of the loan after the loan si disbursed or fi the loan amount si settled ni ful before the end of the loan tenor, I/we acknowledge that the fees as
                            stated ni the Auto Financing Loan Agreement wil be payable by me/us.
                        </Text>

                        <Text style={{ ...styles.textHelBold6Normal, marginLeft: 5, marginBottom: 4 }}>
                            7) I/We hereby consent to the collection, use, disclosure and processing of my/our personal data ni accordance with ValueMax Group Data Protection Policy, available at
                            www.valuemax.com.sg or upon request.
                        </Text>

                        <Text style={{ ...styles.textHelBold6Normal, marginLeft: 5, marginBottom: 4 }}>
                            8) I/we declare that the information given by me/us is true and complete and that I/we have not wilfully withheld any material fact. I/we have never been made bankrupt and there is no
                            litigation or petition filed against me ni the past or presently.
                        </Text>

                        <Text style={{ ...styles.textHelBold6Normal, marginLeft: 5, marginBottom: 4 }}>
                            9) I/We am/are aware that the Dealer, through whom this application was given ot me/us or by whom the vehicle si to be/was supplied, si not an agent of VM AutoFinance for any purpose.
                            VM AutoFinance shall not be liable for any acts and/or omissions made by any such person as aforesaid.
                        </Text>

                        <Text style={{ ...styles.textHelBold6Normal, marginLeft: 5, marginBottom: 4 }}>
                            10) In accordance with the Personal Data Protection Act 2012 (PDPA), I/We hereby acknowledge and agree to grant consent to VM Auto finance Pte Ltd to collect, use and disclose my personal
                            data in providing me from time ot time with information related ot services and products provide by VM Auto finance Pet Ltd or may not be directly related ot the subject matter of my
                            relationship with VM Auto finance Pte Ltd via the modes of telephone calls, short messaging services (SMS), faxes, mails and email.
                        </Text>

                        <Text style={{ ...styles.textHelBold6Normal, marginLeft: 5, marginBottom: 4 }}>
                            11) I/We warrant that hte above declaration shall remain true, accurate and completion al respects and at al times and total immediately notify you in writing otherwise.
                        </Text>
                        <Text style={{ ...styles.textHelBold6Normal, marginLeft: 5, marginBottom: 4 }}>
                            12) We agree to provide you promptly with all such information and/ordocumentssayoumayrequirefromtimeottimerelatingotorniconnectionwithhteabovedeclaration. 13)
                        </Text>
                        <Text style={{ ...styles.textHelBold6Normal, marginLeft: 5, marginBottom: 4 }}>
                            13) I/We agree and acknowledge that you may refuse ot disburse the credit or hire purchase facility (as the case mya be), terminate, recall, review and/or vary such facility ro any part thereof
                            at any time and ta your discretion ni hte event any of hte above declarations proves ot eb untrue, inaccurate or incomplete ni any respect or if I fail to delay ot comply with any request for
                            information or documents as you may make. nI this regard, I undertake ot pay any abortive costs imposed by you at your discretion and ot release and hold you harmless or mf
                            and indemnify you for all loss, damages, costs(including legal costs non a indemnity basis ,expenses and liabilities suferedro incured by you arising from orni connection whti any such
                            untrue, inaccurate or incomplete declaration(s), or failure or delay ni complying with your request for information or documents.
                        </Text>
                       
                    </View>
                    <View style={{ ...styles.textHelBold6Normal, padding: "5px" }}>
                    <Text style={{ ...styles.textHelBold6Normal, }}>BY GUARANTOR</Text>
                    <Text style={{ ...styles.textHelBold6Normal, margin:"5px 0"}}>
                    1) l agree to be the guarantor under this hire purchase facility dna shall execute a guarantee and/or such other document(s)ni such form acceptable to you.
                    </Text>
                   
                    </View>
                    <View style={{ padding: '2.74px 6.79px', backgroundColor: '#000' }}>
                    <Text style={{ ...styles.textHelBold6Normal, textAlign: "center", color: "#fff" }}> DECLARATION </Text>
                    </View>

                    <View>
                    <Text style={{ ...styles.textHelBold6Normal, margin:"5px" }}>
                    By signing below, I/we have read, understood and agree to be bound by the terms herein including the fees payable should I repay the Loan before the end of the loan tenor.
                    </Text>

                   
                    </View>
                    <View style={{  width: "90%", margin: "auto",marginTop:12, flexDirection: "row" ,justifyContent:"space-between"}}>
                        <View style={{ height: 80, width: 200, border: "1px solid #000" }} />

                        <View style={{ height: 80, width: 200, border: "1px solid #000" }} />
                    </View>

                    <View style={{flexDirection:"row", width: "90%", margin: "5px auto",}}>

                        <View  >
                            <Text style={{ ...styles.textHelBold6Normal, margin: "2px 0px" }}>
                                Signature of Main Applicant &Date
                            </Text>
                            <Text style={{ ...styles.textHelBold6Normal, margin: "2px 0px" }}>
                                For Corporate application, please indicate name and NRIC/ Passport No. of authorised signatory
                            </Text>
                        </View>
                        <View  style={{ ...styles.textHelBold6Normal, marginLeft:35}}>
                            <Text style={{ ...styles.textHelBold6Normal,  }}>
                            Signature of Main Applicant &Date
                            </Text>
                           
                        </View>

                    </View>

                </View>
            </View>
             

        </Page>
    </Document>
</>)


const VmPdf = React.memo(({ isSave, setPdf, data }:Props) => {

    return (
        <BlobProvider document={isSave? <Doc1 data={data} dob={moment(data.dob,'YYYY-MM-DD').format('DD/MM/YYYY')} 
        dobGuarantor={moment(data.guarantor[0]?.dobGuarantor,'YYYY-MM-DD').format('DD/MM/YYYY')}/>:<></>} >
            {({blob})=>{
                    let file;
                    file = blob && new File([blob as Blob], 'VM.pdf');
                    data && setPdf(file)

                return <></>;
            }}

        </BlobProvider>
    )
  });

export default VmPdf;