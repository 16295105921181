import React, { useCallback, useEffect, useRef, useState } from "react";
import { Message } from "framework/src/Message";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import DealDetailView from "./DealDetailView.web";
import { runEngine } from "framework/src/RunEngine";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface ViewProps {
  testID: string;
  // Customizable Area Start
  isExpandCustomerDetail: boolean;
  isExpandVehicleDetail: boolean;
  isExpandGuarantorDetail: boolean;
  isOpenManagerApproval: boolean ;
  handleOpenManagerApproval:()=>void ;
  handleExpandCustomerDetail: ()=>void;
  handleExpandVehicleDetail: ()=>void;
  handleExpandGuarantorDetail: ()=>void; 
  handleOpenEditMenuGuarantor: any;
  anchorElGuarantor: any;
  handleCloseEditMenuGuarantor: any;
  dealDetail: any;
  handleNavigateEditGuarantor: (event: any,data: any)=>void;
  handleClickGuarantor: (data: any)=>void;
  openModelGuarantor: boolean;
  selectedGuarantor: any;
  openModelDeleteGuarantor: boolean;
  handleOpenDeleteGuarantor: (data: any)=>void;
  handleDeleteGuarantor: (data: any)=>void;
  handleDeleteDocument: (data: string)=>void;
  handleUploadCPF: (data: any)=>void;
  handleUploadNOA: (data: any)=>void;
  handleUploadSupporting: (data: any)=>void;
  handleOpenStatus: (data: any)=>void;
  handleCloseStatus: (data: any)=>void;
  anchorElStatus: any;
  handleUpdateStatusSaleMan: (status: string)=>void;
  handleNavigateEditDeal:()=>void;
  signApplication: (data: any, index: number)=>void;
  signGuarantor1Application: (data: any, index: number)=>void;
  signGuarantor2Application: (data: any, index: number)=>void;
  handleSignDocumentRegenerate: any;
  data: any;
  isSaveUOB: boolean;
  isSaveMoneyMax: boolean;
  isSaveDBS: boolean;
  isSaveMaybank: boolean;
  isSaveTembusa: boolean;
  isSaveTokyo: boolean;
  isSaveVM: boolean;
  isSaveHongLeong: boolean;
  handleSavePdfDBS: (file: any) => void;
  handleSavePdfUOB: (file: any) => void;
  handleSavePdfMoneymax: (file: any) => void;
  handleSavePdfMaybank: (file: any) => void;
  handleSavePdfTembusa: (file: any) => void;
  handleSavePdfTokyo: (file: any) => void;
  handleSavePdfVM: (file: any) => void;
  handleSavePdfHongleon: (file: any) => void;
  handleOpenSignedDocument: (args: any)=>void;
  isLoading: boolean;
  brokerList : any;
  selectedBroker: any;
  visibleStatus: any;
  userType: any;
  handleManagerFormSubmit: (values:any) => void ;
  handleUpdateBroker: any;
  openModalSign: boolean;
  handleOpenSignModal: any;
  openModelResendEmail: any;
  handleOpenResendEmail: any;
  handleResendEmail: any;
  formIsDirty:boolean;
  checkSubmit: any;
  // Customizable Area End
}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: string;
  // Customizable Area End
}

const subscribedMessages = [
  // Customizable Area Start
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
  // Customizable Area End
];

const DealDetail: React.FC<Props> = (props) => {
  // Customizable Area Start
  const getDealDetailId = useRef("");
  const deleteDealDocId = useRef("");
  const updateDocumentId = useRef("");
  const updateDealStatusId = useRef("");
  const deleteGuarantorId = useRef("");
  const signDocumentId = useRef("");
  const guarantor1SignDocumentId = useRef("");
  const guarantor2SignDocumentId = useRef("");
  const getDocusignId = useRef("");
  const getDocusignId1 = useRef("");
  const updateBrokerId = useRef("");
  const updateStatusSignId = useRef("");
  const saveProfitsManagerEndpointId = useRef("") ;
  const getBrokerListId = useRef("");
  const savePDFId = useRef("");
  const getDealDetailAfterDeleteGuarantorId = useRef("");
  const resendEmailId = useRef("");

  const [selectedBroker, setSelectedBroker] = useState();
  const [dealDetail, setDealDetail] = useState<any>();
  const [isExpandCustomerDetail, setIsExpandCustomerDetail] = useState(true);
  const [isExpandVehicleDetail, setIsExpandVehicleDetail] = useState(true);
  const [isExpandGuarantorDetail, setIsExpandGuarantorDetail] = useState(true);
  const [isOpenManagerApproval, setisOpenManagerApproval] = useState(false) ;
  const [anchorElGuarantor, setAnchorElGuarantor] = useState(null);
  const [selectedGuarantor, setSelectedGuarantor] = useState<any>(null);
  const [openModelGuarantor, setOpenModelGuarantor] = useState(false);
  const [openModalSign, setOpenModalSign] = useState(false);
  const [openModelDeleteGuarantor, setOpenModelDeleteGuarantor] = useState(false);
  const [openModelResendEmail, setOpenModelResendEmail] = useState(false);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [isSaveUOB, setIsSaveUOB] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSaveMoneyMax, setIsSaveMoneyMax] = useState(false)
  const [isSaveDBS, setIsSaveDBS] = useState(false)
  const [isSaveMaybank, setIsSaveMaybank] = useState(false)
  const [isSaveTembusa, setIsSaveTembusa] = useState(false)
  const [isSaveTokyo, setIsSaveTokyo] = useState(false)
  const [isSaveVM, setIsSaveVM] = useState(false)
  const [isSaveHongLeong, setIsSaveHongLeong] = useState(false)
  const [PdfFileUOB, setPdfFileUOB] = useState<any>(null);
  const [PdfFileMoneyMax, setPdfFileMoneyMax] = useState<any>(null);
  const [PdfFileDBS, setPdfFileDBS] = useState<any>(null);
  const [PdfFileMaybank, setPdfFileMaybank] = useState<any>(null);
  const [PdfFileTembusa, setPdfFileTembusa] = useState<any>(null);
  const [PdfFileTokyo, setPdfFileTokyo] = useState<any>(null);
  const [PdfFileVM, setPdfFileVM] = useState<any>(null);
  const [PdfFileHongleong, setPdfFileHongleong] = useState<any>(null);
  const [data, setData] = useState<any>();
  const [selectedDocument, setSelectedDocument] = useState<any>();
  const [signParam, setParamSign] = useState('');
  const [brokerList, setBrokerList] = useState([]); ;
  const [indexSelectedDoc, setIndexSelectedDoc] = useState<any>();
  const [visibleStatus, setVisibleStatus] = useState<string[]>([]);
  const [userType, setUserType] = useState();
  const [isGenerate, setIsGenerate] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  // Customizable Area End

  useEffect(() => {
    setReceiveCallback(receive);

    subscribedMessages.forEach((message) => subscribe(message));

    // Customizable Area Start
    getParameterByName('event', window.location.search) === "signing_complete" && updateStatusSign()
    getBrokerList()
    getDealDetail(props.navigation.getParam('dealId'))
    getRole()
    
    // Customizable Area End

    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, []);

  const receive = (from: string, message: Message) => {
    // Customizable Area Start
    debugLog("API Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
        extractNetworkResponse(message);
      if (responseJson) {
        switch (apiRequestCallId) {
          case getDealDetailId.current:
            setIsLoading(false)
            getDealDetailReceiver(responseJson);
            break;
          case deleteDealDocId.current:
            getDealDetail(props.navigation.getParam('dealId'))
            break;
          case updateDocumentId.current:
            getDealDetailReceiver(responseJson);
            break;
          case updateDealStatusId.current:
            getDealDetailReceiver(responseJson);
            setAnchorElStatus(null);
            break;
          case deleteGuarantorId.current:
            setOpenModelDeleteGuarantor(false);
            setSelectedGuarantor(null);
            setAnchorElGuarantor(null);
            getDealDetailAfterDeleteGuarantor(props.navigation.getParam('dealId'))
            break;
          case getDealDetailAfterDeleteGuarantorId.current:
            updateSalesAndContractReceiver(responseJson)
            break;
          case signDocumentId.current:
            signDocumentReceiver(responseJson)
            break;
          case guarantor1SignDocumentId.current:
            guarantor1SignDocumentReceiver(responseJson)
            break;
          case guarantor2SignDocumentId.current:
            guarantor2SignDocumentReceiver(responseJson)
            break;
          case getDocusignId.current:
            getDocusignReceiver(responseJson);
            break;
          case getBrokerListId.current:
            handleBrokerListResponse(responseJson);
            break;
          case saveProfitsManagerEndpointId.current:
            if(responseJson.status !== 500){
              callApiUpdateStatus('completed');
              setisOpenManagerApproval(false)
            }
            break;
          case updateBrokerId.current:            
            getDealDetailReceiver(responseJson);      
            break;
          case updateStatusSignId.current:        
            const url = new URL(window.location.href);
            url.search = '';
            window.history.replaceState({}, document.title, url as unknown as string)    
             

            handleGetDocument()
            removeStorageData("documentId")
            getDealDetail(props.navigation.getParam('dealId')) 
            break;
          case savePDFId.current:
            getDealDetail(props.navigation.getParam('dealId'))
            break;
          case resendEmailId.current:
            setOpenModelResendEmail(false);
            break;
          default:
            break;
        }
      }

      if (responseJson?.errors) {
        alert(JSON.stringify(responseJson.errors));
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start

  const handleGetDocument = async() =>{
    const documentId = await getStorageData("documentId")
    const numberGuarantor = await getStorageData("numberGuarantor")
    const signedBy = await getStorageData("signedBy")
    console.log('tube numberGuarantor', numberGuarantor)
    console.log('tube signedBy', signedBy)
    if(numberGuarantor == "0" && signedBy === "customer" || numberGuarantor == "1" && signedBy === "guarantor1" || numberGuarantor == "2" && signedBy === "guarantor2" ){
      handleOpenSignedDocument1(documentId)
    }
    removeStorageData('signedBy')
  }
  const checkSubmit = () => {
    setFormIsDirty(true)
  }

  const updateStatusSign = async()=>{
    const documentId = await getStorageData("documentId")
    const signedBy = await getStorageData("signedBy")
    const tokenValue = await getStorageData("authToken")

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue,
    };
    let formData = new FormData();
    signedBy === "customer" &&  formData.append('is_signed', "true");
    signedBy === "guarantor1" &&  formData.append('is_signed_guarantor_one', "true");
    signedBy === "guarantor2" &&  formData.append('is_signed_guarantor_two', "true");
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    updateStatusSignId.current = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.docusignCreate}/${documentId}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cencelOrderMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  function getParameterByName(name: string, url:string) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  const normalizeQueryString = (queryString: any) => {
    return queryString.replace(/\?(?=.*\?)/g, '&');
  };

  const {
    sendBlockMessage,
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    debugLog,
    unsubscribeFromMessage,
  } = useRunEngine();

  const getRole= async() =>{
    const userType = await getStorageData("user_type");
    setUserType(userType);
  }

  const getDealDetail = async (dealId: any) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrderByIdEndPoint}/${dealId}`, 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    getDealDetailId.current = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const getDealDetailAfterDeleteGuarantor = async (dealId: any) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrderByIdEndPoint}/${dealId}`, 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    getDealDetailAfterDeleteGuarantorId.current = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const getDealDetailReceiver = async(responseJson: any)=>{
    const userType = await getStorageData("user_type");
    const temp = responseJson.data
    const brokerName = responseJson.data?.attributes?.broker_name || '';
    setSelectedBroker(brokerName);
    setDealDetail(responseJson.data)
    switch (responseJson.data?.attributes.status) {
      case 'draft':
        setVisibleStatus(['pending_submission']);
        break;
      case 'pending_submission':
        let lengthSignedCustomer =  responseJson.data?.attributes.bank_attachments.filter((args:any)=>args.is_signed === true).length
        let lengthSignedGuarantor1 =  responseJson.data?.attributes.bank_attachments.filter((args:any)=>args.is_signed_guarantor_one === true).length
        let lengthSignedGuarantor2 =  responseJson.data?.attributes.bank_attachments.filter((args:any)=>args.is_signed_guarantor_two === true).length
        if(userType==="sales_manager" ){
          if(responseJson.data.attributes.guarantor_details.data.length === 0){
            if(responseJson.data?.attributes.bank_attachments.length === lengthSignedCustomer && responseJson.data.attributes.broker_id !== null){
              setVisibleStatus(['pending_approval']);
            }
          } else if(responseJson.data.attributes.guarantor_details.data.length === 1){
            if(responseJson.data?.attributes.bank_attachments.length === lengthSignedGuarantor1 && responseJson.data.attributes.broker_id !== null){
              setVisibleStatus(['pending_approval']);
            }
          } else if(responseJson.data.attributes.guarantor_details.data.length === 2){
            if(responseJson.data?.attributes.bank_attachments.length === lengthSignedGuarantor2 && responseJson.data.attributes.broker_id !== null){
              setVisibleStatus(['pending_approval']);
            }
          }
        
          else{
            setVisibleStatus(['draft']);
          }
          
        } else {
        setVisibleStatus([]);
        } 
        break;
      case 'pending_approval':
        if(responseJson.data.attributes.mail_send === false && userType==="sales_manager" && responseJson.data.attributes.broker_id !==null){
          setOpenModelResendEmail(true);
        }
        if(userType==="sales_manager"){
          setVisibleStatus(['draft','pending_submission' ,'pending_additional_docs','pending_hard_copy_submission']);
        } else {
          setVisibleStatus(['pending_additional_docs','pending_hard_copy_submission']);
        }
        break;
      case 'pending_additional_docs':
        if(responseJson.data.attributes.mail_send === false && userType==="sales_manager" && responseJson.data.attributes.broker_id !==null){
          setOpenModelResendEmail(true);
        }
        if(userType==="sales_manager"){
          setVisibleStatus(['draft', 'pending_submission' ,'pending_approval' ,'pending_hard_copy_submission']);
        } else {
        setVisibleStatus(['pending_hard_copy_submission'])
        }
        break;
      case 'pending_hard_copy_submission':
        if(responseJson.data.attributes.mail_send === false && userType==="sales_manager" && responseJson.data.attributes.broker_id !==null){
          setOpenModelResendEmail(true);
        }
        if(userType==="sales_manager"){
          setVisibleStatus(['draft', 'pending_submission' ,'pending_approval' ,'pending_additional_docs','pending_hard_copy_handover']);
        } else {
        setVisibleStatus(['pending_hard_copy_handover'])
        }
        break;
      case 'pending_hard_copy_handover':
        if(responseJson.data.attributes.mail_send === false && userType==="sales_manager" && responseJson.data.attributes.broker_id !==null){
          setOpenModelResendEmail(true);
        }
        if(userType==="sales_manager"){
          setVisibleStatus(['completed']);
        } else {
          setVisibleStatus([]);
        }
        break;
      case 'completed':        
        setVisibleStatus([]);
        break;
      default:
        setVisibleStatus([]);
        break;
    }
  }

  const { extractNetworkResponse, hideKeyboard, isPlatformWeb } =
    useBlockHelpers();

  const handleExpandCustomerDetail = () =>{
    setIsExpandCustomerDetail(!isExpandCustomerDetail);
  }

  const handleExpandVehicleDetail = () =>{
    setIsExpandVehicleDetail(!isExpandVehicleDetail);
  }

  const handleExpandGuarantorDetail = () =>{
    setIsExpandGuarantorDetail(!isExpandGuarantorDetail);
  }

  const handleOpenEditMenuGuarantor = (event:any, data:any) => {
    const anchorElGuarantor = event.currentTarget
    setAnchorElGuarantor(anchorElGuarantor)
    setSelectedGuarantor(data)
  }
  const handleCloseEditMenuGuarantor = () => {
    setAnchorElGuarantor(null)
  }

  const handleClickGuarantor = (data: any) => {    
    setSelectedGuarantor(data);
    setOpenModelGuarantor(!openModelGuarantor)
  }

  const handleOpenDeleteGuarantor = () => {    
    setOpenModelDeleteGuarantor(!openModelDeleteGuarantor)
  }

  const handleOpenResendEmail = () => {    
    setOpenModelResendEmail(!openModelResendEmail)
  }

  const handleResendEmail = async ()=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listOfOrdersEndPoints}/resend_mail?id=${dealDetail.id}`, 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    resendEmailId.current = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const handleDeleteGuarantor = async()=>{
    setIsLoading(true)
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteGuarantorDetail}?guarantor_id=${selectedGuarantor?.id}`, 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteOrderMethod
    );
    deleteGuarantorId.current = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const handleNavigateEditGuarantor = (event: any)=>{
    event.stopPropagation();
    props.navigation.navigate("EditGuarantor", {dealId: props.navigation.getParam('dealId'), guarantorId: selectedGuarantor?.id})
  }

  const handleDeleteDocument = async (file: string)=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteDealAttachment}?attachment_name=${file}&deal_id=${dealDetail?.id}`,  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteOrderMethod
    );
    deleteDealDocId.current = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const handleUploadCPF = async(event: any)=>{
    const input = event.target;
    const files = input.files;
  const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue,
    };
    let formData = new FormData();
    
    formData.append('cpf_contribution_for_last_six_month', files[0]);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    updateDocumentId.current = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listOfOrdersEndPoints}/${props.navigation.getParam('dealId')}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cencelOrderMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  const handleUploadNOA = async(event: any)=>{
    const input = event.target;
    const files = input.files;
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue,
    };
    let formData = new FormData();
    
    formData.append('noa_for_last_two_year', files[0]);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    updateDocumentId.current = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listOfOrdersEndPoints}/${props.navigation.getParam('dealId')}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cencelOrderMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  const handleUploadSupporting = async(event: any)=>{
    const input = event.target;
    const files = input.files;
  const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue,
    };
    let formData = new FormData();
    
    formData.append('supporting_docs', files[0]);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    updateDocumentId.current = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listOfOrdersEndPoints}/${props.navigation.getParam('dealId')}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cencelOrderMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    const handleOpenStatus = (event: any)=>{
      if(visibleStatus.length !== 0){
        event.stopPropagation();
        const anchorEl = event.currentTarget
        setAnchorElStatus(anchorEl);
      }
     
    }
    const handleCloseStatus = () => {
      setAnchorElStatus(null);
    }

  const handleUpdateStatusSaleMan = async(status: string)=>{
    if(status === "completed"){
      handleOpenManagerApproval()
    } else {
      callApiUpdateStatus(status)
    }
  }

  const callApiUpdateStatus = async (status: string)=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue,
    };
    let formData = new FormData();

    formData.append('status', status);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    updateDealStatusId.current = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listOfOrdersEndPoints}/${props.navigation.getParam('dealId')}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cencelOrderMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const handleNavigateEditDeal = ()=>{
    props.navigation.navigate("EditSalesAndContract", {dealId: props.navigation.getParam('dealId')})
  };

  const handleOpenSignModal = ()=>{
    setOpenModalSign(!openModalSign)
  }

  const signApplication = (args: any, index: number)=>{
    setSelectedDocument(args);
    setIndexSelectedDoc(index);
    setOpenModalSign(true);
    setParamSign('sign_by=0')
    setStorageData('signedBy', "customer")
  }

  const signGuarantor1Application = (args: any, index: number)=>{
    setSelectedDocument(args);
    setIndexSelectedDoc(index);
    setOpenModalSign(true);
    setParamSign('sign_by=1')
    setStorageData('signedBy', "guarantor1")
  }

  const signGuarantor2Application = (args: any, index: number)=>{
    setSelectedDocument(args);
    setIndexSelectedDoc(index);
    setOpenModalSign(true);
    setParamSign('sign_by=2')
    setStorageData('signedBy', "guarantor2")
  }

  const handleSignDocumentRegenerate = ()=>{
    signParam === 'sign_by=0' && signDocumentRegenerate()
    signParam === 'sign_by=1' && guarantor1SignDocument()
    signParam === 'sign_by=2' && guarantor2SignDocument()
  }

  const signDocumentRegenerate = async ()=>{
    setIsLoading(true);
    let response:Response = new Response();
    response = await fetch(selectedDocument.url, {
      method: 'GET'})
    const blob = await response.blob();
    const file = new File([blob as Blob], 'signDocument.pdf')
    signDocument(file, selectedDocument)
  }

  const signDocument= async(file: any, args: any)=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    let formData = new FormData();
    formData.append('pdf', file);
    formData.append('customer_details_id', dealDetail.attributes.customer_detail.id);
    formData.append('redirect_url', `/DealDetail/${props.navigation.getParam('dealId')}`);
    formData.append('deal_id', dealDetail.id);
    formData.append('bank_attachment_id', args.bank_attachment_id)
       
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    signDocumentId.current = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.docusignCreate,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createOrderMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const signDocumentReceiver = async (responseJson: any)=>{
    setStorageData("documentId", responseJson.data.id)

    switch (responseJson.data.docusign_urls.length) {
      case 1:
        setStorageData("numberGuarantor", "0")
        break;
      case 2:
        setStorageData("numberGuarantor", "1")
        break;
      case 3:
        setStorageData("numberGuarantor", "2")
        break;
      default:
        break;
    }

    window.open(responseJson.data.docusign_urls[0])

  }

  const guarantor1SignDocument= async()=>{
    setIsLoading(true);
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.docusignCreate}/${dealDetail.attributes.bank_attachments[indexSelectedDoc].docusign_id}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    guarantor1SignDocumentId.current = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const guarantor1SignDocumentReceiver = (responseJson: any)=>{
    setStorageData("documentId", responseJson.data.id)
    switch (responseJson.data.attributes.docusign_urls.length) {
      case 1:
        setStorageData("numberGuarantor", "0")
        break;
      case 2:
        setStorageData("numberGuarantor", "1")
        break;
      case 3:
        setStorageData("numberGuarantor", "2")
        break;
      default:
        break;
    }
    window.open(responseJson.data.attributes.docusign_urls[1])
  }

  const guarantor2SignDocument= async()=>{
    setIsLoading(true);
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.docusignCreate}/${dealDetail.attributes.bank_attachments[indexSelectedDoc].docusign_id}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    guarantor2SignDocumentId.current = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const guarantor2SignDocumentReceiver = (responseJson: any)=>{
    setStorageData("documentId", responseJson.data.id)
    switch (responseJson.data.attributes.docusign_urls.length) {
      case 1:
        setStorageData("numberGuarantor", "0")
        break;
      case 2:
        setStorageData("numberGuarantor", "1")
        break;
      case 3:
        setStorageData("numberGuarantor", "2")
        break;
      default:
        break;
    }
    window.open(responseJson.data.attributes.docusign_urls[2])
  }

  const savePdfUOB = (file: any) => {
    setPdfFileUOB(file)
  }
  const savePdfMaybank = (file: any) => {
    setPdfFileMaybank(file)
  }
  const savePdfTembusa = (file: any) => {
    setPdfFileTembusa(file)
  }
  const savePdfTokyo = (file: any) => {
    setPdfFileTokyo(file)
  }
  const savePdfVM = (file: any) => {
    setPdfFileVM(file)
  }
  const savePdfMoneyMax = (file: any) => {
    setPdfFileMoneyMax(file)
  }
  const savePdfDBS = (file: any) => {
    setPdfFileDBS(file)
  }
  const savePdfHongleon = (file: any) => {
     setPdfFileHongleong(file)
  }

  const handleSavePdfUOB = useCallback(savePdfUOB, []);
  const handleSavePdfMoneymax = useCallback(savePdfMoneyMax, []);
  const handleSavePdfDBS = useCallback(savePdfDBS, []);
  const handleSavePdfMaybank = useCallback(savePdfMaybank, []);
  const handleSavePdfTembusa = useCallback(savePdfTembusa, []);
  const handleSavePdfTokyo = useCallback(savePdfTokyo, []);
  const handleSavePdfVM = useCallback(savePdfVM, []);
  const handleSavePdfHongleon = useCallback(savePdfHongleon, []);

  const handleOpenSignedDocument =async(args: any)=>{
    setIsLoading(true);
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDocusign}?id=${args.docusign_id}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    getDocusignId.current = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const handleOpenSignedDocument1 =async(docusign_id: any)=>{
    console.log('tube', docusign_id)
    setIsLoading(true);
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDocusign}?id=${docusign_id}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    getDocusignId1.current = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const getDocusignReceiver = (responseJson: any)=>{
    setIsLoading(false)
    window.open(responseJson.url)
  }

  const handleOpenManagerApproval = () => {
    setisOpenManagerApproval(!isOpenManagerApproval)
  }
  const getBrokerList = async() =>{
    const tokenValue = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBrokerListApi,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBrokerListApiMethod
    );
    getBrokerListId.current = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  const handleBrokerListResponse = (responseJson: any) => {
    setBrokerList(responseJson.data);
  };
  
  const handleManagerFormSubmit = async(values : any) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue
    };
    let formData = new FormData();
    formData.append('admin_fee', values.AdminFee);
    formData.append('finance_admin_fee', values.FinanceAdminFee);
    formData.append('insurance_comms', values.InsuranceComms);
    formData.append('loan_comms', values.LoanComms);
    formData.append('margin', values.Margin);
    formData.append('repair_fee', values.RepairFee);    
    formData.append('deal_id', props.navigation.getParam('dealId')); 

    values.MiscellaneousFees.forEach((fee: any, index: number) => {
      formData.append(`miscellaneous_fees_attributes[${index}][amount]`, fee.fee);
      formData.append(`miscellaneous_fees_attributes[${index}][description]`, fee.description);
    });
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    saveProfitsManagerEndpointId.current = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveProfitsManagerEndpoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createOrderMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  const handleUpdateBroker =async (args:any) => {
    setSelectedBroker(args.attributes.name)
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue,
    };
    let formData = new FormData();
    formData.append('broker_id', args.id);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    updateBrokerId.current = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listOfOrdersEndPoints}/${props.navigation.getParam('dealId')}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cencelOrderMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  const updateSalesAndContractReceiver = (responseJson: any) => {
    setIsLoading(true)
    let dealDetail = responseJson.data
    setDealDetail(dealDetail);
    
    let tempDataGuarantor :any ={};
    tempDataGuarantor.vehicleName = dealDetail.attributes.vahicle_name;
    tempDataGuarantor.vehiclePrice= dealDetail.attributes.vehicle_price;
    tempDataGuarantor.vehicleId=dealDetail.attributes.vehicle_id;
    tempDataGuarantor.vehicleNumber=dealDetail.attributes.vehicle_number;
    tempDataGuarantor.vehicleManufactured= dealDetail.attributes.vehicle_manufactured_year;
    tempDataGuarantor.vehicleMake= dealDetail.attributes.vehicle_make;
    tempDataGuarantor.vehicleModel= dealDetail.attributes.vehicle_model;
    tempDataGuarantor.engineNumber= dealDetail.attributes.engine_number;
    tempDataGuarantor.chassisNumber= dealDetail.attributes.chassis_number;
    tempDataGuarantor.firstRegistrationDate = moment(dealDetail.attributes.first_registration_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    tempDataGuarantor.fullName= dealDetail.attributes.customer_detail.full_name;
    tempDataGuarantor.dob= dealDetail.attributes.customer_detail.date_of_birth;
    tempDataGuarantor.NRIC= dealDetail.attributes.customer_detail.nric_number;
    tempDataGuarantor.address= dealDetail.attributes.customer_detail.address;
    tempDataGuarantor.emailID= dealDetail.attributes.customer_detail.email;
    tempDataGuarantor.contactNumber= dealDetail.attributes.customer_detail.contact_number;
    tempDataGuarantor.employer= dealDetail.attributes.customer_detail.employer;
    tempDataGuarantor.jobTitle= dealDetail.attributes.customer_detail.job_title;
    tempDataGuarantor.employmentAddress= dealDetail.attributes.customer_detail.employement_address;
    tempDataGuarantor.grossMonthlySalary= dealDetail.attributes.customer_detail.gross_monthly_salary;
    for(let i = 0; i< dealDetail.attributes.bank_attachments.length; i++){
      switch (dealDetail.attributes.bank_attachments[i].name) {
        case "UOB":
          setIsSaveUOB(true)
          break;
        case "Moneymax":
          setIsSaveMoneyMax(true)
          break;
        case "Maybank":
          setIsSaveMaybank(true)
          break;
        case "DBS bank Limited":
          setIsSaveDBS(true)
          break;
        case "Tembusu Financial Services":
          setIsSaveTembusa(true)
          break;
        case "Tokyo Century":
          setIsSaveTokyo(true)
          break;
        case "VM":
          setIsSaveVM(true)
          break;
        case "Hong Leong Finance":
          setIsSaveHongLeong(true)
          break;
        default:
          break;
      }
    }
    switch (dealDetail.attributes.financial_details[0].loan_tennure) {
      case '12 months':
        tempDataGuarantor.tenure = 12;
        break;
      case '24 months':
        tempDataGuarantor.tenure = 24;
        break;
      case '36 months':
        tempDataGuarantor.tenure = 36;
        break;
      case '48 months':
        tempDataGuarantor.tenure = 48;
        break;
      case '60 months':
        tempDataGuarantor.tenure = 60;
        break;
      default:
        break;
    }
    let list = [];
    for(let i=0; i < dealDetail?.attributes.guarantor_details.data.length; i++){
      let guarantor = dealDetail?.attributes.guarantor_details.data[i].attributes;
      let temp : any = {};
      temp.NRICGuarantor = guarantor.nric_number;
      temp.addressGuarantor = guarantor.address;
      temp.contactNumberGuarantor = guarantor.contact_number;
      temp.dobGuarantor = guarantor.date_of_birth;
      temp.emailIDGuarantor = guarantor.email;
      temp.employerGuarantor = guarantor.employer;
      temp.employmentAddressGuarantor = guarantor.employement_address;
      temp.fullNameGuarantor = guarantor.full_name;
      temp.grossMonthlySalaryGuarantor = guarantor.gross_monthly_salary
      temp.jobTitleGuarantor = guarantor.job_title;
      list.push(temp);
    }
    tempDataGuarantor.guarantor = list;
    
    setData(tempDataGuarantor);
    setIsGenerate(true)
  }

  useEffect(()=>{
    let length =  [PdfFileUOB ,PdfFileMoneyMax, PdfFileDBS, PdfFileHongleong, PdfFileMaybank, PdfFileTembusa, PdfFileVM, PdfFileTokyo].filter(args=>args!== null).length;
    if(length !==0 && length === dealDetail?.attributes.bank_attachments.length && isGenerate){
      setIsGenerate(false);
      updateRegenerateDocument()
    }
  }, [PdfFileUOB ,PdfFileMoneyMax, PdfFileDBS, PdfFileHongleong, PdfFileMaybank, PdfFileTembusa, PdfFileVM, PdfFileTokyo])

  const updateRegenerateDocument= async()=>{
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenValue,
    };
    let formDataGuarantor = new FormData();
    formDataGuarantor.append('financial_details_attributes[0][loan_tennure]',dealDetail?.attributes.financial_details[0].loan_tennure)
    isSaveUOB && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][0][image]`, PdfFileUOB);
    isSaveUOB && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][0][name]`, "UOB");
    isSaveMoneyMax && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][1][image]`, PdfFileMoneyMax);
    isSaveMoneyMax && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][1][name]`, "Moneymax");
    isSaveTokyo && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][2][image]`, PdfFileTokyo);
    isSaveTokyo && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][2][name]`, "Tokyo Century");
    isSaveDBS && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][3][image]`, PdfFileDBS);
    isSaveDBS && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][3][name]`, "DBS bank Limited");
    isSaveHongLeong && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][4][image]`, PdfFileHongleong);
    isSaveHongLeong && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][4][name]`, "Hong Leong Finance");
    isSaveTembusa && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][5][image]`, PdfFileTembusa);
    isSaveTembusa && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][5][name]`, "Tembusu Financial Services");
    isSaveMaybank && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][6][image]`, PdfFileMaybank);
    isSaveMaybank && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][6][name]`, "Maybank");
    isSaveVM && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][7][image]`, PdfFileVM);
    isSaveVM && formDataGuarantor.append(`financial_details_attributes[0][bank_attachments_attributes][7][name]`, "VM");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    savePDFId.current = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listOfOrdersEndPoints}/${props.navigation.getParam('dealId')}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cencelOrderMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataGuarantor,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End

  const viewProps: ViewProps = {
    testID: "GuarantorDetails",
    // Customizable Area Start
    isLoading,
    isExpandCustomerDetail,
    isExpandVehicleDetail,
    isExpandGuarantorDetail,
    isOpenManagerApproval,
    handleExpandCustomerDetail,
    handleExpandVehicleDetail,
    handleExpandGuarantorDetail,
    handleOpenManagerApproval,
    handleOpenEditMenuGuarantor,
    anchorElGuarantor,
    handleCloseEditMenuGuarantor,
    dealDetail,
    handleNavigateEditGuarantor,
    handleClickGuarantor,
    openModelGuarantor,
    selectedGuarantor,
    openModelDeleteGuarantor,
    handleOpenDeleteGuarantor,
    handleDeleteGuarantor,
    handleDeleteDocument,
    handleUploadCPF,
    handleUploadNOA,
    handleUploadSupporting,
    handleCloseStatus,
    handleOpenStatus,
    anchorElStatus,
    handleUpdateStatusSaleMan,
    handleNavigateEditDeal,
    signApplication,
    signGuarantor1Application,
    signGuarantor2Application,
    handleSignDocumentRegenerate,
    data,
    isSaveUOB,
    isSaveMoneyMax,
    isSaveDBS,
    isSaveMaybank,
    isSaveTembusa,
    isSaveTokyo,
    isSaveVM,
    isSaveHongLeong,
    handleSavePdfDBS,
    handleSavePdfUOB,
    handleSavePdfMoneymax,
    handleSavePdfMaybank,
    handleSavePdfTembusa,
    handleSavePdfTokyo,
    handleSavePdfVM,
    handleSavePdfHongleon,
    handleOpenSignedDocument,
    brokerList,
    selectedBroker,
    visibleStatus,
    userType,
    handleManagerFormSubmit,
    handleUpdateBroker,
    openModalSign,
    handleOpenSignModal,
    openModelResendEmail,
    handleOpenResendEmail,
    handleResendEmail,
    formIsDirty,
    checkSubmit,
    // Customizable Area End
  };

  return <DealDetailView {...viewProps} />;
};

export default DealDetail;
