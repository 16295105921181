import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Modal,
  ListItemText,
  Grid,
  FormControl,
  MenuItem,
  TextField,
  Select,
  Typography,
  Checkbox,
  CircularProgress,
  withWidth,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import DoneIcon from '@material-ui/icons/Done';
import { styled } from "@material-ui/core/styles";

import CloseIcon from '@material-ui/icons/Close';
import { Formik } from "formik";
import * as Yup from "yup";
import { Facebook, Instagram, Tiktok, UploadImg } from "./assets";
import CustomSelectSearchField from '../../../components/src/CustomSelectSearchField'
import Divider from '@material-ui/core/Divider';
import ClearIcon from '@material-ui/icons/Clear';
// Customizable Area End

import CreateNewPostController, {

  Props,
} from "./CreateNewPostController";
export default class CreateNewPost extends CreateNewPostController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  createPostSchema = () => {
    return Yup.object().shape({
      vehicleName: Yup.string().required("Selecting Vehicle is mandatory"),
      platform: Yup.array().required("This field  is Mandatory "),
      media: Yup.array().required("Please select at least 1 image to post on social media"),
    });
  };
  
  renderModal = () => {
    return <Modal open={this.state.openModel}>
      <ModalStyle>
        <Box className="wrapModel" >
          <Box width="95%" mx="auto">
            {this.state.modalObject.title !== "" && <Box className="wrapClose" >
              <Typography className="textModel">
                {this.state.modalObject.title}

              </Typography>
              <CloseIcon data-test-id='closeModal' className="closeIcon" onClick={() => { this.setState({ openModel: false, modalObject: {} }) }} />
            </Box>}

            <Box className="wrapLogo" my={1} >
              <Typography className="errorsMsg">{this.state.modalObject?.errorMSg}</Typography>
            </Box>
            <Box className="wrapLogo" my={1} >
              {this.state.uploadLoading ? <CircularProgress className="iconLoading" size={20} /> : null}
              <Typography className="subtitle">{this.state.modalObject?.subtitle}</Typography>
            </Box>
            {
              this.state.modalObject?.buttonActions ? (<Box className="wrapButton">
                <Box className="progressWrapButton">
                  <Button
                    data-test-id='cancelModal'
                    onClick={() => this.setState({ openModel: false })}
                    className="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    data-test-id="retryButton"
                    // onClick={() => this.onSubmit()}
                    className="retrybutton"
                  >
                    Retry
                  </Button>
                </Box>
              </Box>) : null
            }
          </Box>
        </Box>
      </ModalStyle>
    </Modal>
  }

  progressRenderModal = () => {
    return <Modal open={this.state.openProgressModal}>
      <ModalStyle>
        <Box className="ProgresswrapModel" >
          <Box >
            <Box className="progresswrapClose" m={1} width="95%" mx="auto">
              <Typography className="textModel">
                Upload Media
              </Typography>
              <CloseIcon data-test-id="closeIcon1" className="closeIcon" onClick={() => { this.setState({ openProgressModal: false, modalObject: {} }) }} />
            </Box>
            <Divider style={{backgroundColor:"rgba(255, 246, 220, 0.3)"}}/>
            <Box className="mediaList" my={1}>
              {this.state.platform.includes("Instagram") && <Box display="flex" className="wrapSocial">
                <Box  className="wrapSocialIcon">
                  <img src={Instagram} />
                </Box>
                
                <Typography className="socialText">Instagram</Typography>
                {this.state.is_process_instagram ? <CircularProgress className="iconLoading" size={20} /> : 
                this.state.is_posted_on_instagram === "true" ? <DoneIcon className="checkIcon" /> : <ClearIcon className="cross" />}
              </Box>}

              {this.state.platform.includes("Facebook") && <Box display="flex" className="wrapSocial">
                <Box className="wrapSocialIcon">
                  <img src={Facebook} />
                </Box>
                <Typography className="socialText">Facebook</Typography>
                {this.state.is_process_facebook ? <CircularProgress className="iconLoading" size={20} /> : 
                this.state.is_posted_on_facebook  === "true"? <DoneIcon className="checkIcon" /> : <ClearIcon className="cross" />}
              </Box>}

              {this.state.platform.includes("Tiktok") && <Box display="flex" className="wrapSocial">
                <Box className="wrapSocialIcon">
                  <img src={Tiktok} />
                </Box>
                <Typography className="socialText">Tik tok</Typography>
                {this.state.is_posted_on_tiktok  === "true"? <DoneIcon className="checkIcon" /> : <ClearIcon className="cross" />}
              </Box>}
            </Box>
            <Divider style={{backgroundColor:"rgba(255, 246, 220, 0.3)"}}/>
            <Box className="progressWrapButton">
              <Button
                data-test-id='progressWrapButton'
                onClick={() => this.setState({ openProgressModal: false })}
                className="button"
              >
                Cancel
              </Button>
              {
                (this.state.platform.includes("Facebook") && this.state.is_posted_on_facebook  === "false" ||  this.state.platform.includes("Instagram") && this.state.is_posted_on_instagram === "false")  && (!this.state.is_process_facebook && !this.state.is_process_instagram) &&
                <Button
                  onClick = {() => this.handleRetry()}
                  className="retrybutton"
                  data-test-id="retryButton2"
                >
                  Retry
                </Button>
              }
            </Box>
          </Box>
        </Box>
      </ModalStyle>
    </Modal>
  }
  
  DeleteRenderModal = (setFieldValue:any) => {
    return <Modal open={this.state.openDeletModal}>
      <ModalStyle>
        <Box className="ProgresswrapModelDelete" >
        <Box className="childDElete">
            <Box className="progresswrapClose" m={1} width="95%" mx="auto">
              <Typography className="textModel">
                Delete Media
              </Typography>
              <CloseIcon data-test-id="closeIcon1" className="closeIcon" onClick={() => { this.setState({ openDeletModal: false, modalObject: {} }) }} />
            </Box>
            <Divider style={{backgroundColor:"rgb(179, 167, 167)"}}/>             
            <Box padding={2} my={1} >
              <Typography className="errorsMsgDelete">Are you sure you want to delete this Photo/video ?  </Typography>          
            </Box>
            <Divider style={{backgroundColor:"rgb(179, 167, 167)"}}/>
            <Box className="progressWrapButton">
              <Button
                data-test-id='progressWrapButton'
                onClick={() => this.setState({ openDeletModal: false ,deleteId:null})}
                className="button"
              >
                Cancel
              </Button>              
                  <Button
                  onClick={() => this.handleRemoveImage(this.state.deleteId,setFieldValue)}
                  className="retrybutton"
                  data-test-id="retryButton2"
                >
                  Delete
                </Button> 
            </Box>
          </Box>
        </Box>
      </ModalStyle>
    </Modal>
  }
  // Customizable Area End

  render() {

    //console.log("props", this.state)

    return (
      // Customizable Area Start
      <NewCustomerStyle>
        {this.renderModal()}
        {this.progressRenderModal()}
        <Formik
          data-test-id='formik'
          enableReinitialize
          initialValues={{
            vehicleName: this.state.vehicleName,
            platform: this.state.platform,
            postCaption: this.state.postCaption,
            hastag: this.state.hastag,
            media: this.state.media
          }}
          validationSchema={this.createPostSchema}
          validateOnChange={this.state.formIsDirty}
          validateOnBlur={this.state.formIsDirty}
          onSubmit={() => {
            this.onSubmit();
          }}>
          {({ errors, values, setFieldValue, isValid, handleChange, handleSubmit }) => {
            return <form data-test-id='form' onSubmit={handleSubmit} className="formikWrap">
              <Grid container className='container'>
                <Grid item xs={12} md={6} className="padding10">
                  <Typography className="labelText">Enter The Vehicle Name*</Typography>
                  <CustomSelectSearchField
                    name="vehicleName"
                    value={this.state.vehicleName}
                    placeholder={'Vehicle name'}
                    data-test-id='vehicleName'
                    isError={errors.vehicleName}
                    searchable
                    handelclickchange={this.onValueChange}
                    isLoading={this.state.loading}
                    SearchableList={this.state.vehicleList}
                    onChange={(evnt) => {
                      setFieldValue('vehicleName', evnt.target.value);
                      this.onValueChange('vehicleName', evnt.target.value);
                      this.getVehicleList()
                    }}
                  />
                  <Box mt={2}>
                    <Typography className="errorsMsg">{errors.vehicleName}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} className="padding10">
                </Grid>
                <Grid item xs={12} md={6} className="padding10">
                  <Grid item xs={12} md={8}>
                    <Typography className="labelText">Platform*</Typography>
                    <FormControl size="small" fullWidth>
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        displayEmpty
                        fullWidth
                        className={errors.platform ? "inputField isError" : "inputField"}
                        value={this.state.platform.length===0 ? []  : this.state.platform}
                        onChange={(evnt) => this.onChangeSelect(evnt, setFieldValue)}
                        variant="outlined"
                        renderValue={(selected) => {return <Typography>Select The Social Media Platform</Typography>}}
                        MenuProps={{
                          classes: { paper: 'menuPaper' }, PaperProps: {
                            style: {
                              backgroundColor: '#3F3E3A',
                              borderRadius: '8px',
                              padding: '8px'
                            }
                          },
                        }}>
                        <MenuItem disabled selected value="">
                          <ListItemText  style={{ color: '#F3F3F3', fontSize: 16, fontFamily: 'Outfit', fontWeight: 400 }} primary="Select The Social Media Platform" />
                        </MenuItem>
                        {['Instagram', 'Facebook', 'Tik tok'].map((name) => (
                          <MenuItem key={name} value={name} style={{ color: '#F3F3F3', fontSize: 16, fontFamily: 'Outfit', fontWeight: 400 }}>
                            <Checkbox checked={this.state.platform.indexOf(name) > -1} style={{ color: "#f3f3f3" }} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box mt={2}>
                      <Typography className="errorsMsg">{errors.platform}</Typography>
                    </Box>
                  </Grid>
                  <Box className="wrapSelectedBank" >
                    {
                      this.state.platform.map((selectedPlatform: string, index: number) => {
                        return <Box className="wrapSelectedBankItem" key={index} my={2}>
                          {selectedPlatform === "Facebook" && <Box className="wrapSocialIcon">
                            <img src={Facebook} />
                          </Box>}
                          {selectedPlatform === "Tik tok" && <Box className="wrapSocialIcon">
                            <img src={Tiktok} />
                          </Box>}
                          {selectedPlatform === "Instagram" && <Box className="wrapSocialIcon">
                            <img src={Instagram} />
                          </Box>}
                          <Typography className="wrapSelectedBankText">{selectedPlatform}</Typography>
                          <CloseIcon data-test-id="platformCLose" className="iconBank" onClick={() => this.onHandleDeselect(index, setFieldValue)} />
                        </Box>
                      })
                    }
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} className="padding10">

                </Grid>
                <Grid item xs={12} md={6} className="padding10">
                  <Typography className="labelText">Post / Caption</Typography>
                  <TextField
                    className={errors.postCaption ? "inputField isError" : "inputField"}
                    type="text"
                    multiline
                    name="postCaption"
                    data-test-id='postCaption'
                    minRows={4}
                    placeholder="Post / Caption"
                    InputProps={{ disableUnderline: true, }}
                    value={values.postCaption}
                    onChange={(event) => {
                      this.onValueChange("postCaption", event.target.value);
                      setFieldValue('postCaption', event.target.value);
                    }}
                    fullWidth />
                  <Box mt={2}>
                    <Typography className="errorsMsg">{errors.postCaption}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} className="padding10">

                </Grid>
                <Grid item xs={12} md={6} className="padding10">
                  <Typography className="labelText">Hastag</Typography>
                  <TextField
                    data-test-id='hastag'

                    name="hastag"
                    className={errors.hastag ? "inputField isError" : "inputField"}
                    type="text"
                    multiline
                    minRows={4}
                    placeholder="#examples"
                    InputProps={{ disableUnderline: true, }}
                    value={values.hastag}
                    onChange={(event) => {
                      this.onValueChange("hastag", event.target.value);
                      setFieldValue('hastag', event.target.value);
                    }}
                    fullWidth />
                  <Box mt={2}>
                    <Typography className="errorsMsg">{errors.hastag}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} className="padding10" />


                <Grid item xs={12} md={6} className="padding10">
                  <Typography variant="h6" className="wrapSelectedBankText">Upload images or videos to post in your social media platforms</Typography>
                  <Grid container spacing={1} style={{ marginTop: '10px' }}>
                    {this.state.images?.map((image: any, index: number) => (
                      <Grid item key={index}>
                        <Box className="imageShow">
                          <Box className="closeBox">
                            <CloseIcon data-test-id="closeIcon2" className="closeIcon" id="closeIcon" onClick={() => this.handelOpenDeletemodal(index)} />
                          </Box>

                          {
                            this.isImageFileName(image.name) ? (<img
                              data-test-id='uploadMedia'
                              src={image.url}
                              alt={`Uploaded ${index + 1}`}
                              className="imageShow"
                            /> ):(<video  className="imageShow" >
                            <source  
                            src={image.url}
                            type="video/mp4" />

                              
                          </video>)
                          }
                          
                          
                        </Box>
                      </Grid>
                    ))}
                  </Grid>

                  <Box className="wrapAddImage">
                    <input
                      id="imageInput"
                      type="file"
                      key={this.state.keyForLabel}
                      hidden
                      onChange={this.handleImageChange}
                      data-test-id="handleImageChange"
                      multiple
                      name="media"
                      
                    />
                    <label htmlFor="imageInput">
                      <Box className="imageUploader">
                        <img src={UploadImg} />
                        <Typography className="uploadTextIcon">Add Media</Typography>
                      </Box>
                    </label>
                  </Box>

                  <Box mt={2}>
                    <Typography className="errorsMsg">{errors.media}</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '100px' }}>
                <Box width="100%" display="flex" justifyContent='center'>
                  <Grid item md={3} xs={12} className="padding10">
                    <Button disabled={!isValid} data-test-id='btnSubmit' className="Sub_btnWrapAdd" type="submit" >
                      <Typography className="btnTextAdd">Post</Typography>
                    </Button>
                  </Grid>
                </Box>
              </Grid>
              {this.DeleteRenderModal(setFieldValue)}
            </form>
          }}
        </Formik>
      </NewCustomerStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ModalStyle = styled(Box)(({ theme }) => ({
  "& .wrapModel": {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: (theme as any).typography.body2,
    borderRadius: "16px",
    minWidth: '40%',
    padding: 10
  },
  "& .ProgresswrapModel": {
    position: 'absolute' as 'absolute',
    top: '50%',
 
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: (theme as any).typography.body2,
    borderRadius: "16px",
    minWidth: '40%',
    padding: 0
  },
  "& .ProgresswrapModelDelete":{
    position: 'absolute' as 'absolute',
    top: '50%',
    height:300,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: (theme as any).typography.body2,
    borderRadius: "16px",
    minWidth: '40%',
    padding: 0
  },
  "& .childDElete":{
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    height:"100%"
  },
  "& .subtitle": {
    color: (theme as any).typography.h1,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    marginTop:24
  },
  "& .socialText": {
    color: (theme as any).typography.h1,
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    marginLeft:8
  },
  "& .wrapSocial": {
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  "& .wrapSocialIcon":{width:24, height:24, display:'flex', alignItems:'center', justifyContent:'center'},
  "& .errorsMsg": {
    color: "#F87171",
    fontFamily: "Outfit",
    fontSize: "1.2rem",
    fontStyle: "normal",
    fontWeight: 400,
  },
  '& .iconLoading': {
    color:'#CEAE71', 
    alignSelf:'center'
  },
  "& .errorsMsgDelete": {
    color: "#F87171",
    fontFamily: "Outfit",
    textTransform:"capitalize",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .closeIcon": {
    color: (theme as any).typography.h1,
    cursor: "pointer",
  },
  "& .checkIcon": {
    color: "green",
    fontSize: 20,
    margin: "2px 1rem ",
    cursor: "pointer"
  },
  "& .cross": {
    fontSize: 20,
    margin: "2px 1rem ",
    color: "red",
    cursor: "pointer"
  },
  "& .wrapClose": {
    cursor: 'poiter',
    display: 'flex',
    marginTop: 10,
    marginRight: 0,

    justifyContent: "space-between",
    height: "2rem",
    alignItems: "center"
  },

  "& .progressWrapButton": {
    display: "flex",
    justifyContent: "flex-end",
    margin: "1rem",
    gap: "1rem",
  },

  "& .progresswrapClose": {
    cursor: 'poiter',
    display: 'flex',


    justifyContent: "space-between",
    height: "2rem",
    alignItems: "center"
  },
  "& .wrapLogo": { display: 'flex', justifyContent: 'center', marginBottom: '20px', flexDirection: "column", alignItems: "center" },
  "& .textModel": {
    color: (theme as any).typography.h1,
    fontFamily: "Outfit",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    marginBottom: 0,
    textAlign: 'center'
  },
  "& .wrapButton": { display: 'flex', justifyContent: 'center', marginBottom: 20 },
  "& .button": {
    height: '55px',
    width: '150px',
    color: "#CEAE71",
    fontFamily: "Outfit",
    textTransform:"capitalize",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    borderRadius: "8px",
    background: "#3F3E3A",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)"
  },

  "& .retrybutton": {
    background: " var(--Primary-Purple-Gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    color: "#000 !important",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    textTransform: "capitalize",
    width: "8rem",
    fontWeight: 700,
  },
  "& .mediaList": {
    display: 'flex',
    gap:24,

    flexDirection: 'column',

    padding: theme.spacing(2), // You can use theme spacing
  },


}));
const NewCustomerStyle = styled(Box)(({ theme }) => ({
  "& .iconBank":{
    color: (theme as any).typography.h3, 
  },
  "& .errorsMsg": {
    color: "#F87171",
    fontFamily: "Outfit",
    textTransform:"capitalize",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },

  "& .isError": {
    border: "1px solid rgb(248, 113, 113) !important"
  },
  "& .closeBox": {
    display: 'flex',
    justifyContent: 'end',
    position: "relative" , 
    zIndex : 10,
  },
  '& .padding30': {
    padding: "20px",
  },
  "& .imageShow": {
    cursor: "pointer",
    borderRadius: "8px",
    width: "150px",
    height: "150px",
  },
  "& .closeIcon": {
    color: "#FCE49C",
    position: "absolute",
    marginTop: 10,
    marginRight: 10,
  },
  "& .wrapLogo": { display: 'flex', justifyContent: 'center', marginBottom: '60px' },
  '& .errorInput': {
    marginTop: '4px',
    borderRadius: 8,
    background: (theme as any).typography.body2,
    padding: '10px 8px',
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    border: "1px solid #F87171",
  },
  '& .padding10': {
    padding: "10px",
  },
  '& .checkbox': {
    color: (theme as any).typography.h1,
    fontSize: '14px',
    fontFamily: 'Outfit',
    fontWeight: '700',
  },

  '& .formikWrap': {
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    padding: 20
  },
  "& .labelTextPlaceholder": {
    color: (theme as any).typography.body2,
    fontSize: '14px',
    fontFamily: 'Outfit',
    fontWeight: '500',
  },

  '& .labelTextService': {
    color: (theme as any).typography.h2,
    fontSize: '14px',
    fontFamily: 'Outfit',
    fontWeight: '500',
  },
  '& .inputField': {
    marginTop: '4px',
    borderRadius: 8,
    border: `1px ${(theme as any).typography.border} solid`,
    background: (theme as any).typography.body2,

    padding: '10px 8px',
    color: (theme as any).typography.h1,
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    width: "100%",


    "& fieldset": {
      border: "none",
      outline: "none",
    },

    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '5px',
      color: (theme as any).typography.h1,
    },

    "& .MuiInputBase-inputMultiline": {
      color: (theme as any).typography.h1,
    }
  },


  "& .wrapAddImage": {
    backgroundColor: (theme as any).typography.body2,
    borderRadius: '8px',
    width: 'fit-content',
    margin: '1rem 0',
  },
  "& .imageUploader": {
    cursor: "pointer",
    borderRadius: "8px",
    background: (theme as any).typography.body2,
    display: "flex",
    width: "150px",
    height: "150px",
    padding: "10px var(--border-radius, 16px)",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  "& .uploadTextIcon": {
    background:
      "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textAlign: "center",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  '& .wrapSelectedBank': {
    display: 'flex',
    flexDirection: 'row',
    margin: "0rem",
    flexWrap: 'wrap',

  },
  '& .wrapSelectedBankItem': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: "5px 10px",
    border: `1px ${(theme as any).typography.border} solid`,
    borderRadius: 16,
    marginRight: 24,
    marginBottom: 10,
    backgroundColor: (theme as any).typography.body2,
  },
  '& .wrapSelectedBankText': {
    color: (theme as any).typography.h1,
    fontSize: '1rem',
    fontFamily: 'Outfit',
    textTransform:"capitalize",
    fontWeight: '400',
    marginRight: 10,
    marginLeft: 10,

    '& svg': {
      color: (theme as any).typography.h1,
    }
  },


  "& .Sub_btnWrapAdd": {
    cursor: 'pointer',
    padding: "16px 0 16px 0",
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)',
    borderRadius: '8px',
    overflow: 'hidden',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
  },

  '& .container': {
    flex: 1,
    alignContent: 'start',
  },
  '& .labelText': {
    color: (theme as any).typography.h1,
    fontSize: '14px',
    fontFamily: 'Outfit',
    fontWeight: '700',
  },



  '& .btnTextAdd': {
    textTransform: 'none',
    color: '#171717',
    fontSize: '16px',
    fontFamily: 'Outfit',
    fontWeight: '700',
  },




}))
export { CreateNewPost }

withWidth()(CreateNewPost);
// Customizable Area End 