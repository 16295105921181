import { Box, Typography, styled } from "@material-ui/core";
import React from 'react';

interface Props {
  errors: any
}

const CustomError = ({errors}: Props) => {
  return <CustomSelectStyle>
    {errors && <Typography className="errorsMsg">{errors}</Typography>}
  </CustomSelectStyle>
}

const CustomSelectStyle = styled(Box)({
  "& .errorsMsg":{
    color: "#F87171",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
})
export default CustomError;