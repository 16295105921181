import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withWidth,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import clsx from 'clsx';
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import CustomSelectSearch from "../../../components/src/CustomSelectSearch";
import { Close } from "./assets";
import CustomPagination from "../../../components/src/CustomPagination";
// Customizable Area End

import Cfcrm4Controller, {
  Props,
  configJSON,
} from "./Cfcrm4Controller";

export default class Cfcrm4 extends Cfcrm4Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderModal = () => {
    return <Modal open={this.state.openModal}>
      <ModalStyle>
        <Box className="wrapModal">
          <Box className="modalHeaderWrap">
            <Typography className="modalHeaderText">Delete Customer</Typography>
            <IconButton onClick={this.handleModalDelete} className="iconPadding">
              <img src={Close}/>
            </IconButton>
          </Box>
          <Box className="line"/> 
          <Box className="wrapModalContent">
            <Typography className="deleteMsg">Are you sure you want to delete customer </Typography>
          </Box>
          <Box className="line"/> 
          <Box className="modalFooterWrap">
            <Button data-test-id='cancalBtn' className="cancelBtn" onClick={this.handleModalDelete}>Cancel</Button>
            <Button data-test-id='saveBtn' className="saveBtn" onClick={this.handleDeleteCustomer}>Delete</Button>
          </Box> 
        </Box> 
      </ModalStyle>
    </Modal>
  }
  // Customizable Area End

  render() { 
    const {width} = this.props;  
    return (
      // Customizable Area Start
      <>
        {this.renderModal()}
        <AccountGroupsStyle>
          <Grid container className={clsx({"padding30": width!=='xs', 'padding30xs': width == 'xs'})}>
            <Grid container item>
              <Grid item xs={12} md={10} sm={8} className="tabContainer">
                <Box className="tab">
                  <Box className="activeTab">
                    <Typography className="activeText">CRM</Typography>
                  </Box>
                  <Box className="gap"/>
                  <Box data-test-id='overviewBtn' className="tabTextWrap" onClick={this.handleNavigateCustomerGroup}>
                    <Typography className="tabText">Customer Group</Typography>
                  </Box>
                  <Box className="gap"/>
                  <Box data-test-id='overviewBtn' className="tabTextWrap" onClick={this.handleNavigateBroadcast}>
                    <Typography className="tabText">Broadcast</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs md={2} sm={12}  className="wrapBtnAdd">
                <Box data-test-id={'addNewCustomer'} className="btnWrap" onClick={this.handleAddNewCustomer}>
                  <Typography className="btnTextApply">Add New Customer</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className="wrapTab2">
              <Grid item className="tabContainer">
                <Box className="tabWrap">
                  <Box className="tabWrap">
                    <TabItem
                      data-test-id={'customerDetail'}
                      label="Customer Details"
                      isActive={!this.state.isCarMatching}
                      onClick={()=>this.handleSwitchTab(1)}
                    />
                    <TabItem
                      data-test-id={'carMatching'}
                      label="Car Matching"
                      isActive={this.state.isCarMatching}
                      onClick={()=>this.handleSwitchTab(2)}
                    />
                  </Box>
                </Box>                
              </Grid>  
            </Grid>
            {this.state.isCarMatching?
            <Grid item md={12} xs={12} className="carMatching">
            <TableContainer className={clsx({'width300': width === 'xs', 'width100': width !=='xs', 'tests': true })}>
              <Table style={{borderCollapse:"separate", borderSpacing:"0 11px"}}>
                <TableHead style={{borderRadius:10}}>
                  <TableRow>
                    <StyledTableCell align='left'>
                      <CustomSelectSearch isSearch value={this.state.valueSearchCarNameCar} placeholder='Car Name' onChange={(e)=>this.onChangeSearchCarMatching(e,'car_name')}/>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <CustomSelectSearch isSearch value={this.state.valueSearchCusNameCar} placeholder='Customer Name' onChange={(e)=>this.onChangeSearchCarMatching(e,'name')}/>
                    </StyledTableCell>
                    <StyledTableCell  align="left">
                      <CustomSelectSearch isSearch value={this.state.valueSearchPhoneCar} placeholder='Phone Number' onChange={(e)=>this.onChangeSearchCarMatching(e,'contact_number')}/>
                    </StyledTableCell>
                    <StyledTableCell align="left" className="wrapEmail" >
                      <CustomSelectSearch isSearch value={this.state.valueSearchEmailCar} placeholder='E-mail ID' onChange={(e)=>this.onChangeSearchCarMatching(e,'email')}/>
                    </StyledTableCell>            
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.rowCarMatching && this.state.rowCarMatching.map((row)=>
                    <TableRow style={{borderRadius:10}}>
                      <StyledTableCell align="left">
                        <Typography className="textTable">{row.car_name}</Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Box>
                          {row.customers.map((e: any) => <Typography className="textTable">{e.name}</Typography>)}
                        </Box>                        
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Box style={{display: 'flex', flexDirection:'column'}}>
                          {row.customers.map((e: any) => <Typography className="textTableNormal">{e.contact_number}</Typography>)}
                        </Box>  
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Box style={{display: 'flex', flexDirection:'column'}}>
                          {row.customers.map((e: any)=><Typography className="textTableNormal">{e.email}</Typography>)}
                        </Box>  
                      </StyledTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomPagination page={this.state.currentPageCar} count={this.state.pageCar} onChange={this.handleChangePageCarMatching}/>
          </Grid>
            : <Grid item md={12} xs={12}>
              <TableContainer className={clsx({'width300': width === 'xs', 'width100': width !=='xs', 'test':true })}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align='left'> 
                        <CustomSelectSearch value={this.state.valueSearchCusNameCRM} isCustomerName listSearchCustomerName={this.state.listSearchCustomerName} onChange={(e)=>this.handleSearchCustomerName(e)} placeholder='Customer Name' onSelect={(e)=>this.onChangeSearchCRM(e,'name')}/>
                      </StyledTableCell>
                      <StyledTableCell align='left'>
                        <Box className="dobBox">
                          <Typography className="dobText">Date Of Birth</Typography> 
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <CustomSelectSearch value={this.state.valueSearchPhoneCRM} isSearch placeholder='Phone Number' onChange={(e)=>this.onChangeSearchCRM(e, 'contact_number')}/>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <CustomSelectSearch value={this.state.valueSearchEmailCRM} isSearch placeholder='E-mail ID' onChange={(e)=>this.onChangeSearchCRM(e, 'email')}/>
                      </StyledTableCell>
                      <StyledTableCell align="left" colSpan={2}>
                        <CustomSelectSearch data-test-id={'serviceId'} value={this.state.valueSearchServiceCRM.charAt(0).toUpperCase() + this.state.valueSearchServiceCRM.slice(1)} placeholder='Interested Service' isService onChange={(e)=>this.onChangeSearchCRM(e, 'services')}/>
                      </StyledTableCell>            
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.rows2.map((row)=>
                      <TableRow>
                        <StyledTableCell align="left" className="test">{row.customerName}</StyledTableCell>
                        <StyledTableCell align="left" className="test">{row.dob}</StyledTableCell>
                        <StyledTableCell align="left" className="test">{row.phoneNumber}</StyledTableCell>
                        <StyledTableCell align="left" className="test">{row.email}</StyledTableCell>
                        <StyledTableCell align="left" className="test">{row.interestedService}</StyledTableCell>
                        <StyledTableCell align="center">
                          
                          <Box aria-haspopup="true" onClick={(e)=>this.handleOpenEditMenu(e, row)}>
                            <MoreVertIcon/>
                          </Box>                          
                          <StyledMenu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleCloseEditMenu}
                          >
                            <MenuItem onClick={this.handleEditCustomer}>
                              <ListItemIcon>
                                <EditIcon style={{color:"#F3F3F3"}}/>
                              </ListItemIcon>
                              <ListItemText primary="Edit" style={{color: '#F3F3F3', fontSize: 12, fontFamily: 'Outfit', fontWeight: 400,}}/>
                            </MenuItem>
                           <MenuItem onClick={this.handleModalDelete}>
                              <ListItemIcon>
                                <DeleteIcon style={{color:"#F3F3F3"}}/>
                              </ListItemIcon>
                              <ListItemText primary="Delete" style={{color: '#F3F3F3', fontSize: 12, fontFamily: 'Outfit', fontWeight: 400,}}/>
                            </MenuItem>
                          </StyledMenu>
                        </StyledTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <CustomPagination page={this.state.currentPageCustomer} count={this.state.pageCustomer} onChange={this.handleChangePageCustomer}/>
            </Grid>}
          </Grid>
        </AccountGroupsStyle>
      </>
    );
    // Customizable Area End
  }
}

const TabItem = ({ label, isActive, onClick }:{ label: any, isActive: any, onClick: any}) => (
  <Box
    className="tabLabelWrap"
    onClick={onClick}
  >
    <Typography
      className={clsx({
        'tabLabelActive': isActive,
        'tabLabelInactive': !isActive,
      })}
    >
      {label}
    </Typography>
    {isActive && <Box className='underline' />}
  </Box>
);

// Customizable Area Start
const StyledMenu = withStyles({
  paper: {
    borderRadius: '8px',
    backgroundColor: '#3F3E3A'
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledTableCell = withStyles((theme)=>({
  body: {
    backgroundColor: (theme as any).typography.body2,
    color: (theme as any).typography.h2,
    fontSize: '16px',
    fontFamily: 'Outfit', 
    fontWeight: 400,
    borderSpacing: '0px 4px',
    border: 'none',
    borderCollapse: 'separate',
    width:100
    
  },
  head: {
    backgroundColor: (theme as any).typography.body2,
    color: (theme as any).typography.h1,
    fontSize: '14px',
    fontFamily: 'Outfit', 
    fontWeight: 700,
    verticalAlign:'center',
    border: 'none',
    borderCollapse: 'separate',
    borderSpacing: '0px 4px'
    
  },
}))(TableCell)

const AccountGroupsStyle  = styled(Box)(({ theme }) => ({
 
  "& .test .MuiTableCell-root":{
    wordBreak:'break-word'
  },
  "& .tests .MuiTableCell-root":{
    wordBreak:'break-word'
  },
  "& .textTable":{
    color: '#BF9B63', 
    fontSize: 16, 
    fontFamily: 'Outfit', 
    fontWeight: '500', 
    textDecoration: 'underline',
    minHeight:'24px'
  },
  "& .textTableNormal":{
    fontSize: 16, 
    fontFamily: 'Outfit', 
    fontWeight: '500', 
    minHeight:'24px'
  },
  "& .wrapEmail":{
    width:'100%',
    display:'flex', 
    justifyContent:'flex-end'
  },
  "& .carMatching .MuiTableRow-root th:first-child": {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  },
  "& .carMatching .MuiTableRow-root th:first-child + th": {
    color: 'red'
  },
  "& .carMatching .MuiTableRow-root th:last-child": {
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  "& .carMatching .MuiTableRow-root td:first-child": {
    borderTopLeftRadius: "16px",
    borderBottomLeftRadius: "16px",
  },
  "& .carMatching .MuiTableRow-root td:last-child": {
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
  },
  "& .tabContainer":{
    width:'fit-content', 
  },  
  "& .wrapTab2":{
    marginTop:'16px',
    marginBottom: '16px'
  },
  '& .tabTextWrap': {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center',
    cursor: 'pointer',
    padding: "8px",
    borderRadius: "4px",
  },
  "& .wrapBtnAdd": { 
    width:'100%', 
    height:'fit-content', 
    paddingTop: 10,
  },
  '& .activeTab': {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center',
    padding: "8px",
    borderRadius: "4px",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))"
  },
  '& .activeText': {
    color: "#171717",
    fontFamily: "Outfit",
    fontSize:"18px",
    fontWeight: 700,
    '@media (max-width: 600px)': {
      fontSize: 10,
  },
  },
  '& .padding30':{
    padding: "30px",
  },
  '& .padding30xs':{
    padding: "30px",
  },
  '& .width300':{
    width: '327px',
    borderRadius: '16px'
  },
  '& .width100':{
    width: '100%',
    borderRadius: '16px'
  },
  '& .tabText': {
    color: (theme as any).typography.h1,
    fontFamily: "Outfit",
    fontSize:"18px",
    fontWeight: 700,
    '@media (max-width: 600px)': {
      fontSize: 10,
    },
  },
  '& .gap': {
    width:"24px"
  },
  "& .tabLabelWrap":{
    cursor: 'pointer',
    padding: '8px', 
    borderRadius: '4px', 
    justifyContent: 'center',
    alignItems: 'center', 
    display: 'flex',
    flexDirection:'column'
  },
  "& .tabLabelActive":{
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent", 
    fontSize: 18, 
    fontFamily: 'Outfit', 
    fontWeight: '700',
  },
  "& .tabLabelInactive":{
    color: (theme as any).typography.h1,
    fontSize: '18px', 
    fontFamily: 'Outfit', 
    fontWeight: '700'
  },
  '& .tab': {
    display: "flex",
    borderRadius: "8px",
    padding: "10px",
    backgroundColor: (theme as any).typography.body2,
    width: "fit-content",
    border: `1px ${(theme as any).typography.border} solid`,
  },
  "& .btnWrap":{
    cursor: 'pointer',
    paddingTop: "10px", 
    paddingBottom: "10px", 
    background: 'linear-gradient(90deg, #916538 0%, #A07746 9%, #B08955 16%, #BF9B63 22%, #CEAE71 26%, #DDC07F 31%, #EDD28E 36%, #F7DD91 50%, #EDD28E 61%, #DDC07F 69%, #CEAE71 73%, #BF9B63 78%, #B08955 84%, #A07746 91%, #916538 100%)', 
    borderRadius: '8px', 
    overflow: 'hidden', 
    justifyContent: 'center', 
    textAlign:'center',
    width: '100%',
    maxWidth: '327px'
  },
  "& .btnTextApply":{
    color: '#171717', 
    fontSize: "16px", 
    fontFamily: 'Outfit', 
    fontWeight: '700'
  },
  "& .tabWrap":{
    padding: '10px',
    borderRadius: '8px', 
    justifyContent: 'flex-start', 
    alignItems: 'center', 
    display: 'inline-flex'
  },
  "& .inputLabel": {
    display: "flex", 
    justifyContent: "center",
    marginBottom: "5px",
    color: "rgba(243, 243, 243, 0.80)",
    textAlign: "right",
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  "& .dobBox": {
    height: "32px",
    width:"150px",
    padding: "10px 8px 10px 8px",
    background: (theme as any).typography.body2, 
    borderRadius: "8px",
    border: `1px ${(theme as any).typography.border2} solid`,
    justifyContent: 'flex-start', 
    alignItems: 'center', 
    display: 'inline-flex'
  },
  "& .dobText": {
    fontFamily: "Outfit",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .underline":{
    height:2,
    backgroundColor:'#F7DD91', 
    width:'100%'
  },
}))
const ModalStyle = styled(Box)(({ theme } ) => ({
  '& .line': {
    height:"1px", 
    backgroundColor:"#FFF6DC30",
  },
  '& .wrapModal': {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    backgroundColor:(theme as any).typography.body2,
    borderRadius: "16px",
    minWidth:'40%'
  },
  '& .wrapModalContent': {
    padding: '40px'
  },
  '& .modalHeaderWrap': {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "24px"   
  },
  '& .modalHeaderText': {
    color: (theme as any).typography.h1,
    fontFamily: "Outfit",
    fontSize:"24px",
    fontWeight: 700,
  },
  '& .modalFooterWrap': {
    display: "flex",
    justifyContent: "flex-end",
    padding: "24px"   
  },
  '& .modalContentWrap': {
    paddingTop: "40px",
    paddingBottom: "40px",
    paddingLeft: "100px",
    paddingRight: "100px",
  },
  "& .deleteMsg":{
    color: '#F87171', 
    fontSize: 16, 
    fontFamily: 'Outfit', 
    fontWeight: '400',
  },
  '& .cancelBtn': {
    backgroundColor:"#3F3E3A",
    borderRadius: "8px",
    color: "#CEAE71",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
    height: "56px"
  },
  '& .saveBtn': {
    marginLeft: "16px",
    background:
    "var(--primary-purple-gradient-3, linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%))",
    borderRadius: "8px",
    color: "#171717",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    textTransform: "none",
    width: "120px",
    height: "56px"
  },
  
}));
export {Cfcrm4}

 withWidth()(Cfcrm4);
// Customizable Area End