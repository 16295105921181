import React, {  } from "react";

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    BlobProvider,
    Image,
    Font} from "@react-pdf/renderer";
import moment from "moment";

type Props = {
    isSave: boolean,
    setPdf: any,
    data: any,
};


const styles = StyleSheet.create({
    text: {
      fontFamily: 'AgendaMedium', 
      fontSize:7,
      color:'#000'
    },
    textHel: {
        fontFamily: 'RegularHel', 
        fontSize:7,
      },
    textBold: {
        fontFamily: 'AgendaBold', 
        fontSize:7,
      },
      textBold6px: {
        fontFamily: 'AgendaBold', 
        fontSize:6.6,
        marginTop:2
      },
    textBoldWhite: {
      fontFamily: 'AgendaBold', 
      fontSize:7,
      color:'#fff'
    },
    textBold12px: {
      fontFamily: 'AgendaBold', 
      fontSize:12,
    },
    textBold10px: {
      fontFamily: 'AgendaBold', 
      fontSize:10,
    },
  });

Font.register({
  family: 'AgendaMedium',
  src: require('./fonts/Agenda-Medium.ttf'),
});
  
Font.register({
    family: 'AgendaBold',
    src: require('./fonts/Agenda-Bold.ttf'),
});
Font.register({
  family: 'RegularHel',
  src: require('./fonts/helvetica_regular.ttf'),
  fontWeight: 'normal',
});



const pStyles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff",
        padding: '8px 28px',
        letterSpacing: 0
    },

});
const Doc1 = ({data, dob, dobGuarantor, dobGuarantor1}: any)=>(<>
  <Document>
    <Page size="A4" style={pStyles.page}>
        <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
          <Image src={require("../src/UOB.png")} style={{width:162, height:41}}/>
          <Text style={styles.textBold12px}>APPLICATION FOR HIRE PURCHASE FACILITY (INDIVIDUAL APPLICANT)</Text>
        </View>
        <View style={{flexDirection:'row'}}>
          <View style={{width:'100%'}}>
            <Text style={styles.text}>If you wish to have a free credit report, you may obtain it within 30 calendar days from
the date of approval or rejection of this application via the credit bureau website listed
below. Alternatively, you may bring the approval or rejection letter and your NRIC to the
following credit bureau’s registered office to obtain a free credit report.</Text>

            <Text style={{...styles.text, marginTop:8.8}}>
            Credit Bureau (Singapore) Pte Ltd
            </Text>
            <Text style={styles.text}>
2 Shenton Way, #20-02 SGX Centre 1</Text>
<Text style={styles.text}>
Singapore 068804</Text>
<Text style={styles.text}>www.creditbureau.com.sg</Text>
            

          </View>
          <View style={{width:'100%', marginLeft:16}}>
            <Text style={styles.text}>Please enclose the following documents with your application:</Text>
            <Text style={styles.text}>1. A photocopy of your NRIC (front and back) or passport</Text>
            <Text style={styles.text}>2. Copy of the Vehicle Sales & Purchase Agreement</Text>
            <Text style={styles.text}>3. For salaried employees;</Text>
            <Text style={styles.text}>a. Latest computerized payslip;</Text>
            <Text style={styles.text}>b. Latest Income Tax Notice of Assessment (NOA); OR</Text>
            <Text style={styles.text}>c. Last 12 months’ CPF contribution history.</Text>
            <Text style={styles.text}>4. For self-employed and commission earners, last two years’ Income Tax Notice of</Text>
            <Text style={styles.text}>Assessment</Text>
            <Text style={styles.text}>For Foreigners – Additional documents to be submitted:</Text>
            <Text style={styles.text}>1. Copy of work permit or employment pass</Text>
            <Text style={styles.text}>For Used Passenger Vehicles:</Text>
            <Text style={styles.text}>1. Copy of LTA Vehicle Information</Text>
            
          </View>
        </View>

        <View style={{border:'1px solid #000', marginTop:3.5}}>
          <View style={{backgroundColor:'#000', padding:'3px 6.35px'}}>
            <Text style={styles.textBoldWhite}>APPLICANT – please v in the appropriate <Text style={{...styles.textHel, color:'#fff'}}>❑</Text></Text>
          </View>
          <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
            <View style={{borderRight:'1px solid #000', width:'100%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>NAME <Text style={styles.textBold}>(Underline Surname)</Text>        <Text style={styles.textHel}>❑</Text> Mr    <Text style={styles.textHel}>❑</Text> Mdm    <Text style={styles.textHel}>❑</Text> Miss    <Text style={styles.textHel}>❑</Text> Dr</Text>
              <Text style={styles.text}> </Text>
              <Text style={styles.text}>{data.fullName}</Text>
            </View>
            <View style={{borderRight:'1px solid #000', width:'30%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Date Of Birth</Text>
              <Text style={{...styles.text}}> </Text>
              <Text style={{...styles.text}}>{dob}</Text>
            </View>
            <View style={{borderRight:'1px solid #000', width:'35%', padding:'2px 5.65px', flexDirection:'column'}}>
              <Text style={styles.text}>Identity / Passport No</Text>
              <Text style={{...styles.text}}> </Text>
              <Text style={{...styles.text}}>{data.NRIC}</Text>
              <Text style={{...styles.text}}> </Text>
              <Text style={{...styles.text}}>Expiry Date ____________</Text>
            </View>
            <View style={{width:'35%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Monthly Salary + Other Income</Text>
              <Text style={{...styles.text}}> </Text>
              <Text style={{...styles.text}}>{data.grossMonthlySalary}</Text>
            </View>
          </View>
          <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
            <View style={{borderRight:'1px solid #000', width:'130%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Home Address</Text>
              <Text style={{...styles.text}}> </Text>
              <Text style={{...styles.text}}>{data.address}</Text>
            </View>
            
            <View style={{borderRight:'1px solid #000', width:'17%', padding:'2px 2.65px', flexDirection:'column'}}>
              <Text style={styles.text}>Nationality</Text> 
              <Text style={styles.text}><Text style={styles.textHel}>❑</Text> SC</Text> 
              <Text style={styles.text}><Text style={styles.textHel}>❑</Text> PR</Text> 
              <Text style={styles.text}><Text style={styles.textHel}>❑</Text> Others</Text> 
            </View>
            <View style={{borderRight:'1px solid #000', width:'18%', padding:'2px 2.65px', flexDirection:'column', }}>
              <Text style={styles.text}>Marital Status</Text> 
              <Text style={styles.text}><Text style={styles.textHel}>❑</Text> Singles</Text> 
              <Text style={styles.text}><Text style={styles.textHel}>❑</Text> Married</Text> 
              <Text style={styles.text}><Text style={styles.textHel}>❑</Text> Others</Text> 
            </View>
              
            <View style={{ width:'35%', padding:'2px 5.65px', flexDirection:'column', justifyContent:'space-between'}}>
              <Text style={styles.text}>Home Tel:</Text>
              <Text style={styles.text}>Mobile No: {data.contactNumber}</Text>
              <Text style={styles.text}>Office Tel:</Text>
            </View>
          </View>
          <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
            <View style={{borderRight:'1px solid #000', width:'130%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Mailing Address (if different from above):</Text>
            </View>
            
            <View style={{ width:'70%', padding:'2px 2.65px'}}>
              <Text style={styles.text}>Email Address: {data.emailID}</Text>
            </View>
            
          </View>
          <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
            <View style={{borderRight:'1px solid #000', width:'100%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Employer / Office Address       ❑ Self-Employed       ❑ Salaried</Text>
              <Text style={{...styles.text}}> </Text>
              <Text style={{...styles.text}}>{data.employer}</Text>
              <Text style={{...styles.text}}>{data.employmentAddress}</Text>
            </View>
            
            <View style={{borderRight:'1px solid #000', width:'30%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Occupation</Text>
              <Text style={{...styles.text}}> </Text>
              <Text style={{...styles.text}}>{data.jobTitle}</Text>
            </View>
            <View style={{borderRight:'1px solid #000', width:'35%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Nature of Business</Text>
              
            </View>
            <View style={{width:'35%', padding:'2px 5.65px', flexDirection:'column'}}>
              <Text style={styles.text}>Employment Period</Text>
              <Text style={{...styles.text, marginTop:16.62, alignSelf:'center'}}>years                months</Text>
            </View>
            
          </View>
          <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
            <View style={{borderRight:'1px solid #000', width:'100%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Previous Employer’s Name (if above employment period is a year or less)</Text>
            </View>
            
            <View style={{borderRight:'1px solid #000', width:'30%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Occupation</Text>
            </View>
            <View style={{borderRight:'1px solid #000', width:'35%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Nature of Business</Text>
              
            </View>
            <View style={{width:'35%', padding:'2px 5.65px', flexDirection:'column'}}>
              <Text style={styles.text}>Employment Period</Text>
              <Text style={{...styles.text, marginTop:16.62, alignSelf:'center'}}>years                months</Text>
            </View>
            
          </View>
          <View style={{flexDirection:'row', borderBottom:'1px solid #000', justifyContent:'center', padding:'2px 0px'}}>
            <Text style={styles.textBold}>APPLICANT’S FACILITIES / COMMITMENTS WITH OTHER BANKS OR FINANCIERS</Text>
          </View>
          <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
            <View style={{borderRight:'1px solid #000', width:'100%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Type of Facilities    please give details eg. (Credit Card, Car Loan, Housing Loan, etc....)</Text>
            </View>
            
            <View style={{borderRight:'1px solid #000', width:'30%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Credit Limit</Text>
            </View>
            <View style={{borderRight:'1px solid #000', width:'35%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Monthly Repayment</Text>
              
            </View>
            <View style={{ width:'35%', padding:'2px 5.65px', flexDirection:'column'}}>
              <Text style={styles.text}>Outstanding Balance</Text>
              <Text style={{...styles.text, marginTop:16.62, alignSelf:'center'}}> </Text>
            </View>
            
          </View>
          <View style={{flexDirection:'row', borderBottom:'1px solid #000', backgroundColor:'#000'}}>
            <View style={{borderRight:'1px solid #fff', width:'130%', padding:'2px 5.65px'}}>
              <Text style={styles.textBoldWhite}>DESCRIPTION OF VEHICLE – please submit your Vehicle Sales Agreement and LTA Vehicle Information.</Text>
            </View>
            
            <View style={{width:'70%', padding:'2px 5.65px'}}>
              <Text style={styles.textBoldWhite}>FINANCIAL REQUEST</Text>
            </View>
            
            
          </View>
          <View style={{flexDirection:'row', borderBottom:'1px solid #000',}}>
            <View style={{borderRight:'1px solid #000', width:'130%', padding:'6px 6px', flexDirection:'column'}}>
              
                <View style={{flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                  <Text style={styles.text}><Text style={styles.textHel}> </Text>Make & Model</Text>    
                  <Text style={styles.text}><Text style={styles.textHel}>❑</Text> New</Text>
                  <View>
                    <Text style={{position:'absolute',...styles.textHel}}>v</Text>
                    <Text style={styles.text}><Text style={styles.textHel}>❑</Text> Used</Text>
                  </View>
                      
                  <Text style={styles.text}><Text style={styles.textHel}>❑</Text> OMV≤$20,000, max LTV60% </Text>   
                  <Text style={styles.text}><Text style={styles.textHel}>❑</Text> OMV{">"}$20,000, max LTV50%</Text>
                </View>
              <View>
                <Text style={{...styles.text, marginTop:6}}>____________________________________________Chassis No :  ________________________________________</Text>
                <Text style={{position:'absolute', left:5, ...styles.text, top: 5}}>{data.vehicleMake} {data.vehicleModel}</Text>
                <Text style={{position:'absolute', right:40, ...styles.text, top: 5}}>{data.chassisNumber}</Text>
              </View>
              <View>
                <Text style={{...styles.text, marginTop:6}}><Text style={styles.textBold}>DETAILS OF VENDOR (Seller) / SUPPLIER</Text>                  Registration/Serial No :___________________________________</Text>
                <Text style={{position:'absolute', right:40, ...styles.text, top: 5}}>{data.vehicleNumber}</Text>
              </View>
              <Text style={{...styles.text, marginTop:6}}>Name :______________________________________________      Salesman :_________________________________</Text>
              <Text style={{...styles.text, marginTop:6}}>Address :___________________________________________________________________________________________</Text>
              <Text style={{...styles.textBold, marginTop:6}}>DEALER’s Declaration</Text>
              <Text style={{...styles.text, marginTop:2}}>We confirm that the information given in this application are true and correct and that we have also, verified the identities
and personal particulars of the applicant and the guarantor(s) herein against their relevant original documents.</Text>
              <Text style={{...styles.text, marginTop:23, alignSelf:'center'}}>...................................................................................................................................................</Text>
              <Text style={{...styles.text, alignSelf:'center'}}>Dealer’s company stamp & signature of authorized signatory / Date</Text>
            
            </View>
            
            <View style={{width:'70%', padding:'2px 5.65px', justifyContent:'center'}}>
              <View>
                <Text style={styles.text}><Text style={styles.textBold}>Purchase Price $</Text>_____________________________________</Text>
                <Text style={{...styles.text, position:'absolute', right:40, bottom:2}}>{data.vehiclePrice}</Text>
              </View>
              <Text style={{...styles.text, marginTop:8}}>Year of Manufacture__________________________________</Text>
              <Text style={{...styles.text, marginTop:8}}><Text style={styles.textBold}>Amount Financed $ </Text>__________________________________</Text>
              <Text style={{...styles.text, marginTop:8}}>Interest Rate Fixed ___________________% p.a.</Text>
              <View>
                <Text style={{...styles.text, marginTop:8}}><Text style={styles.textBold}>Repayment Period :</Text> _________________<Text style={styles.textBold}>months payable by</Text></Text>
                <Text style={{...styles.text, position:'absolute', left :70, top:5}}>{data.tenure}</Text>
              </View>
              <Text style={{...styles.text, marginTop:8}}>___________<Text style={styles.textBold}>monthly installments of</Text>  $_________________</Text>
              <Text style={{...styles.text, marginTop:8}}><Text style={styles.textBold}>and a final installment of                        </Text> $_________________</Text>
              <Text style={{...styles.text, marginTop:8}}>or such remaining balance plus outstanding interest.</Text>
            </View>
          </View>
          <View style={{flexDirection:'column', borderBottom:'1px solid #000',padding:'2px 6px'}}>
            <Text style={styles.textBold}>APPLICANT’s Declaration</Text>
            <Text style={{...styles.text, marginTop:2}}>1.     Did you receive any discount, rebate or any other benefit from the above Vendor/Supplier or any other third party? (eg. car servicing/maintenance package, petrol/shopping</Text>
            <Text style={{...styles.text, marginTop:2}}>        vouchers, cash discount etc).</Text>
            <Text style={{...styles.text, marginTop:2}}>       ❑   Yes, the type of discount, rebate and benefit(s) I/we received is/are__________________and amount(s) received/value of the item(s) is/are S$_____________</Text>
            <Text style={{...styles.text, marginTop:2}}>       ❑ No, I/we did not receive any discount, rebate or benefit(s).</Text>
            <Text style={{...styles.text, marginTop:2}}>2.    Did you obtain any other credit facility for the purchase of the above vehicle/goods?</Text>
            <Text style={{...styles.text, marginTop:2}}>       ❑ Yes, the type of credit facility(ies) I/we received is/are__________________and amount received is S$_________________</Text>
            <Text style={{...styles.text, marginTop:2}}>       ❑ No, I/we did not obtain any credit facility.</Text>
            <Text style={{...styles.text, marginTop:2}}>3.    I/We undertake to provide United Overseas Bank Limited (the “Bank”) with the actual Open Market Value (OMV) of the vehicle upon registration of the said vehicle.</Text>
            <Text style={{...styles.text, marginTop:2}}>4.    I/We declare that the information given by me/us is true, complete and correct and that I/we have not willfully withheld any material fact. I/We have never been made bankrupt</Text>
            <Text style={{...styles.text, marginTop:2}}>       and there is no litigation or petition filed against me/us in the past or presently.</Text>
            <Text style={{...styles.text, marginTop:2}}>5.    I/We shall promptly notify the Bank in writing if any information given becomes inaccurate or misleading or changes in any way before this application is approved. I/We shall</Text>
            <Text style={{...styles.text, marginTop:2}}>       supply any additional information and documentary proof as the Bank may require from time to time in connection with this application.</Text>
            <Text style={{...styles.text, marginTop:2}}>6.    I/We hereby authorize and give the Bank my/our irrevocable consent to make such inquiries and conduct all such checks on me/us (including, but not limited to, inquiries and</Text>
            <Text style={{...styles.text, marginTop:2}}>       checks with any credit bureau recognised as such by the Monetary Authority of Singapore or other parties relative to this applications) and obtain from and/or verify with any</Text>
            <Text style={{...styles.text, marginTop:2}}>       source and/or disclose or release any information relating to me/us or any of my/our account(s) with the Bank to any other party or source (including, but not limited to, any of</Text>
            <Text style={{...styles.text, marginTop:2}}>       the Bank’s related companies, advisors, credit bureaus, credit reference agents, insurance providers, Housing Development Board (HDB) or Central Provident Fund (CPF) Board or</Text>
            <Text style={{...styles.text, marginTop:2}}>       governmental authorities/agencies) as the Bank may from time to time deem fit at the Bank’s own absolute discretion and without any liability or notice to me/us.</Text>
            <Text style={{...styles.text, marginTop:2}}>7.    I/We further acknowledge that the Bank reserves the right to reject this application without assigning any reason and retain documents submitted with this application, for the</Text>
            <Text style={{...styles.text, marginTop:2}}>       purposes of this application.</Text>
            <Text style={{...styles.text, marginTop:2}}>8.    I/We hereby authorize the Bank to disclose any information of myself/ourselves and/or data relating to myself/ourselves to the Vendor (Seller), Supplier and/or Dealer by phone,</Text>
            <Text style={{...styles.text, marginTop:2}}>       electronic mail, short messaging service (SMS) or any other means of communication which the Bank deems appropriate, for the purposes of this application.</Text>
            <Text style={{...styles.text, marginTop:2}}>9.    I/We confirm that I/we have read and understood the Bank’s Privacy Notice (Individual) (available at www.uob.com.sg and the Bank’s branches) which forms part of the terms and</Text>
            <Text style={{...styles.text, marginTop:2}}>       conditions governing my/our relationship with the Bank. I/We consent to the Bank collecting, using and disclosing my/our personal data for Basic Banking Purposes, Co-branding</Text>
            <Text style={{...styles.text, marginTop:2}}>       Purpose, Research Purpose and Marketing Purpose as described in the Bank’s Privacy Notice (Individual). I/We note that (a) I/we may withdraw consent for any or all of the purposes</Text>
            <Text style={{...styles.text, marginTop:2}}>       at any time; (b) if I/we withdraw consent for Basic Banking Purposes and/or Co-branding Purpose, the Bank may not be able to continue to provide the products and services to</Text>
            <Text style={{...styles.text, marginTop:2}}>       me/us; (c) if I/we withdraw consent for Research Purpose and Marketing Purpose, my/our personal data will not be used for these purposes unless I/we expressly and separately</Text>
            <Text style={{...styles.text, marginTop:2}}>       consent to the same again.</Text>
            <Text style={{...styles.text, marginTop:9}}>Applicant’s Signature / Date : ................................................................................................................................................</Text>
          </View>
          <View style={{flexDirection:'column', padding:'2px 6px'}}>
            <Text style={styles.text}><Text style={styles.textBold}>IMPORTANT NOTICE </Text>for fax / email communication from the Bank</Text>
            <Text style={styles.text}>Privileged/Confidential information may be contained in this communication (which includes any attachment). If you are not an intended recipient, please call the sender immediately at
the telephone number on the right ➔ and thereafter, destroy all copies of this communication. You must not use, copy, disclose, distribute or retain all or any part of the contents of this
communication if it is not meant for you.Thank you for your cooperation.</Text>
          </View>
          
        </View>
        <Text style={styles.text}>PERSONAL FINANCIAL SERVICES LOANS DIVISION, CAR FINANCING 80 Raffles Place #13-00, UOB Plaza 1, Singapore 048624</Text>
        <Text style={styles.text}>United Overseas Bank Limited Co. Reg. No. 193500026Z</Text>
    </Page>
    <Page size="A4" style={pStyles.page}>
      <Text style={{...styles.textBold10px, marginTop:30}}>APPLICANT’S Name:</Text>
      <View style={{border:'1px solid #000', marginTop:17}}>
        <View style={{backgroundColor:'#000', padding:'3px 6.35px'}}>
          <Text style={styles.textBoldWhite}>GUARANTOR 1 – please submit your latest Payslip / Tax Assessment & copy of your Identity documents and namecard.</Text>
        </View>
        <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
            <View style={{borderRight:'1px solid #000', width:'100%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>NAME <Text style={styles.textBold}>(Underline Surname)</Text>        <Text style={styles.textHel}>❑</Text> Mr    <Text style={styles.textHel}>❑</Text> Mdm    <Text style={styles.textHel}>❑</Text> Miss    <Text style={styles.textHel}>❑</Text> Dr</Text>
              <Text style={styles.text}> </Text>
              <Text style={styles.text}>{data.guarantor[0] && data.guarantor[0].fullNameGuarantor}</Text>            
            </View>
            <View style={{borderRight:'1px solid #000', width:'30%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Date Of Birth</Text>
              <Text style={{...styles.text}}> </Text>
              <Text style={{...styles.text}}>{dobGuarantor}</Text>
            </View>
            <View style={{borderRight:'1px solid #000', width:'35%', padding:'2px 5.65px', flexDirection:'column'}}>
              <Text style={styles.text}>Identity / Passport No</Text>
              <Text style={{...styles.text}}> </Text>
              <Text style={{...styles.text}}>{data.guarantor[0] && data.guarantor[0].NRICGuarantor}</Text>
              <Text style={{...styles.text}}> </Text>
              <Text style={{...styles.text}}>Expiry Date ____________</Text>
            </View>
            <View style={{width:'35%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Monthly Salary + Other Income</Text>
              <Text style={{...styles.text}}> </Text>
              <Text style={{...styles.text}}>{data.guarantor[0] && data.guarantor[0].grossMonthlySalaryGuarantor}</Text>
            </View>
          </View>
          <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
        <View style={{borderRight:'1px solid #000', width:'130%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Home Address</Text>
          <Text style={{...styles.text}}> </Text>
          <Text style={{...styles.text}}>{data.guarantor[0] && data.guarantor[0].addressGuarantor}</Text>
        </View>
        
        <View style={{borderRight:'1px solid #000', width:'17%', padding:'2px 2.65px', flexDirection:'column'}}>
          <Text style={styles.text}>Nationality</Text> 
          <Text style={styles.text}><Text style={styles.textHel}>❑</Text> SC</Text> 
          <Text style={styles.text}><Text style={styles.textHel}>❑</Text> PR</Text> 
          <Text style={styles.text}><Text style={styles.textHel}>❑</Text> Others</Text> 
        </View>
        <View style={{borderRight:'1px solid #000', width:'18%', padding:'2px 2.65px', flexDirection:'column', }}>
          <Text style={styles.text}>Marital Status</Text> 
          <Text style={styles.text}><Text style={styles.textHel}>❑</Text> Singles</Text> 
          <Text style={styles.text}><Text style={styles.textHel}>❑</Text> Married</Text> 
          <Text style={styles.text}><Text style={styles.textHel}>❑</Text> Others</Text> 
        </View>
          
        <View style={{width:'35%', padding:'2px 5.65px', flexDirection:'column', justifyContent:'space-between'}}>
          <Text style={styles.text}>Home Tel:</Text>
          <Text style={styles.text}>Mobile No: {data.guarantor[0] && data.guarantor[0].contactNumberGuarantor}</Text>
          <Text style={styles.text}>Office Tel:</Text>
        </View>
      </View>
      <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
        <View style={{borderRight:'1px solid #000', width:'130%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Mailing Address (if different from above):</Text>
        </View>
        
        <View style={{ width:'70%', padding:'2px 2.65px'}}>
          <Text style={styles.text}>Email Address:  {data.guarantor[0] && data.guarantor[0].emailIDGuarantor}</Text>
        </View>
        
      </View>
      <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
        <View style={{borderRight:'1px solid #000', width:'100%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Employer / Office Address       ❑ Self-Employed       ❑ Salaried</Text>
          <Text style={{...styles.text}}> </Text>
          <Text style={{...styles.text}}>{data.guarantor[0] && data.guarantor[0].employerGuarantor}</Text>
          <Text style={{...styles.text}}>{data.guarantor[0] && data.guarantor[0].employmentAddressGuarantor}</Text>
        </View>
        
        <View style={{borderRight:'1px solid #000', width:'30%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Occupation</Text>
          <Text style={{...styles.text}}> </Text>
          <Text style={{...styles.text}}>{data.guarantor[0] && data.guarantor[0].jobTitleGuarantor}</Text>
        </View>
        <View style={{borderRight:'1px solid #000', width:'35%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Nature of Business</Text>
          
        </View>
        <View style={{ width:'35%', padding:'2px 5.65px', flexDirection:'column'}}>
          <Text style={styles.text}>Employment Period</Text>
          <Text style={{...styles.text, marginTop:16.62, alignSelf:'center'}}>years                months</Text>
        </View>
        
      </View>
      <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
        <View style={{borderRight:'1px solid #000', width:'100%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Previous Employer’s Name (if above employment period is a year or less)</Text>
        </View>
        
        <View style={{borderRight:'1px solid #000', width:'30%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Occupation</Text>
        </View>
        <View style={{borderRight:'1px solid #000', width:'35%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Nature of Business</Text>
          
        </View>
        <View style={{ width:'35%', padding:'2px 5.65px', flexDirection:'column'}}>
          <Text style={styles.text}>Employment Period</Text>
          <Text style={{...styles.text, marginTop:16.62, alignSelf:'center'}}>years                months</Text>
        </View>
        
      </View>
      <View style={{flexDirection:'row', borderBottom:'1px solid #000', justifyContent:'center', padding:'2px 0px'}}>
        <Text style={styles.textBold}>GUARANTOR’S FACILITIES / COMMITMENTS WITH OTHER BANKS OR FINANCIERS</Text>
      </View>
      <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
        <View style={{borderRight:'1px solid #000', width:'100%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Type of Facilities    please give details eg. (Credit Card, Car Loan, Housing Loan, etc....)</Text>
        </View>
        
        <View style={{borderRight:'1px solid #000', width:'30%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Credit Limit</Text>
        </View>
        <View style={{borderRight:'1px solid #000', width:'35%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Monthly Repayment</Text>
          
        </View>
        <View style={{width:'35%', padding:'2px 5.65px', flexDirection:'column'}}>
          <Text style={styles.text}>Outstanding Balance</Text>
          <Text style={{...styles.text, marginTop:16.62, alignSelf:'center'}}> </Text>
        </View>

      </View>
        <View style={{backgroundColor:'#000', padding:'3px 6.35px'}}>
          <Text style={styles.textBoldWhite}>GUARANTOR 2 – please submit your latest Payslip / Tax Assessment & copy of your Identity documents and namecard.</Text>
        </View>
        <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
            <View style={{borderRight:'1px solid #000', width:'100%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>NAME <Text style={styles.textBold}>(Underline Surname)</Text>        <Text style={styles.textHel}>❑</Text> Mr    <Text style={styles.textHel}>❑</Text> Mdm    <Text style={styles.textHel}>❑</Text> Miss    <Text style={styles.textHel}>❑</Text> Dr</Text>
              <Text style={styles.text}> </Text>
              <Text style={styles.text}>{data.guarantor[1] && data.guarantor[1].fullNameGuarantor}</Text>   
            </View>
            <View style={{borderRight:'1px solid #000', width:'30%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Date Of Birth</Text>
              <Text style={{...styles.text}}> </Text>
              <Text style={{...styles.text}}>{dobGuarantor1}</Text>
            </View>
            <View style={{borderRight:'1px solid #000', width:'35%', padding:'2px 5.65px', flexDirection:'column'}}>
              <Text style={styles.text}>Identity / Passport No</Text>
              <Text style={{...styles.text}}> </Text>
              <Text style={{...styles.text}}>{data.guarantor[1] && data.guarantor[1].NRICGuarantor}</Text>
              <Text style={{...styles.text}}> </Text>
              <Text style={{...styles.text}}>Expiry Date ____________</Text>
            </View>
            <View style={{width:'35%', padding:'2px 5.65px'}}>
              <Text style={styles.text}>Monthly Salary + Other Income</Text>
              <Text style={{...styles.text}}> </Text>
              <Text style={{...styles.text}}>{data.guarantor[1] && data.guarantor[1].grossMonthlySalaryGuarantor}</Text>
            </View>
          </View>
          <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
        <View style={{borderRight:'1px solid #000', width:'130%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Home Address</Text>
          <Text style={{...styles.text}}> </Text>
          <Text style={{...styles.text}}>{data.guarantor[1] && data.guarantor[1].addressGuarantor}</Text>
        </View>
        
        <View style={{borderRight:'1px solid #000', width:'17%', padding:'2px 2.65px', flexDirection:'column'}}>
          <Text style={styles.text}>Nationality</Text> 
          <Text style={styles.text}><Text style={styles.textHel}>❑</Text> SC</Text> 
          <Text style={styles.text}><Text style={styles.textHel}>❑</Text> PR</Text> 
          <Text style={styles.text}><Text style={styles.textHel}>❑</Text> Others</Text> 
        </View>
        <View style={{borderRight:'1px solid #000', width:'18%', padding:'2px 2.65px', flexDirection:'column', }}>
          <Text style={styles.text}>Marital Status</Text> 
          <Text style={styles.text}><Text style={styles.textHel}>❑</Text> Singles</Text> 
          <Text style={styles.text}><Text style={styles.textHel}>❑</Text> Married</Text> 
          <Text style={styles.text}><Text style={styles.textHel}>❑</Text> Others</Text> 
        </View>
          
        <View style={{width:'35%', padding:'2px 5.65px', flexDirection:'column', justifyContent:'space-between'}}>
          <Text style={styles.text}>Home Tel:</Text>
          <Text style={styles.text}>Mobile No: {data.guarantor[1] && data.guarantor[1].contactNumberGuarantor}</Text>
          <Text style={styles.text}>Office Tel:</Text>
        </View>
      </View>
      <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
        <View style={{borderRight:'1px solid #000', width:'130%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Mailing Address (if different from above):</Text>
        </View>
        
        <View style={{ width:'70%', padding:'2px 2.65px'}}>
        <Text style={styles.text}>Email Address:  {data.guarantor[1] && data.guarantor[1].emailIDGuarantor}</Text>
        </View>
        
      </View>
      <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
        <View style={{borderRight:'1px solid #000', width:'100%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Employer / Office Address       ❑ Self-Employed       ❑ Salaried</Text>
          <Text style={{...styles.text}}> </Text>
          <Text style={{...styles.text}}>{data.guarantor[1] && data.guarantor[1].employerGuarantor}</Text>
          <Text style={{...styles.text}}>{data.guarantor[1] && data.guarantor[1].employmentAddressGuarantor}</Text>
        </View>
        
        <View style={{borderRight:'1px solid #000', width:'30%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Occupation</Text>
          <Text style={{...styles.text}}> </Text>
          <Text style={{...styles.text}}>{data.guarantor[1] && data.guarantor[1].jobTitleGuarantor}</Text>
        </View>
        <View style={{borderRight:'1px solid #000', width:'35%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Nature of Business</Text>
          
        </View>
        <View style={{ width:'35%', padding:'2px 5.65px', flexDirection:'column'}}>
          <Text style={styles.text}>Employment Period</Text>
          <Text style={{...styles.text, marginTop:16.62, alignSelf:'center'}}>years                months</Text>
        </View>
        
      </View>
      <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
        <View style={{borderRight:'1px solid #000', width:'100%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Previous Employer’s Name (if above employment period is a year or less)</Text>
        </View>
        
        <View style={{borderRight:'1px solid #000', width:'30%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Occupation</Text>
        </View>
        <View style={{borderRight:'1px solid #000', width:'35%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Nature of Business</Text>
          
        </View>
        <View style={{ width:'35%', padding:'2px 5.65px', flexDirection:'column'}}>
          <Text style={styles.text}>Employment Period</Text>
          <Text style={{...styles.text, marginTop:16.62, alignSelf:'center'}}>years                months</Text>
        </View>
        
      </View>
      <View style={{flexDirection:'row', borderBottom:'1px solid #000', justifyContent:'center', padding:'2px 0px'}}>
        <Text style={styles.textBold}>GUARANTOR 2 FACILITIES / COMMITMENTS WITH OTHER BANKS OR FINANCIERS</Text>
      </View>
      <View style={{flexDirection:'row', borderBottom:'1px solid #000'}}>
        <View style={{borderRight:'1px solid #000', width:'100%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Type of Facilities    please give details eg. (Credit Card, Car Loan, Housing Loan, etc....)</Text>
        </View>
        
        <View style={{borderRight:'1px solid #000', width:'30%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Credit Limit</Text>
        </View>
        <View style={{borderRight:'1px solid #000', width:'35%', padding:'2px 5.65px'}}>
          <Text style={styles.text}>Monthly Repayment</Text>
          
        </View>
        <View style={{width:'35%', padding:'2px 5.65px', flexDirection:'column'}}>
          <Text style={styles.text}>Outstanding Balance</Text>
          <Text style={{...styles.text, marginTop:16.62, alignSelf:'center'}}> </Text>
        </View>

      </View>
      <View style={{backgroundColor:'#000', padding:'3px 6.35px'}}>
          <Text style={styles.textBoldWhite}>GUARANTOR(S) Declaration</Text>
        </View>
        <View style={{padding:'4.3px 5.43px'}}>
          <Text style={styles.textBold6px}>1.     I/We confirm that I/we am/are agreeable to guarantee the hire purchase facility applied herein.</Text>
          <Text style={styles.textBold6px}>2.    I/We declare that the information given by me/us is true, complete and correct and that I/we have not wilfully withheld any material fact.</Text>
          <Text style={styles.textBold6px}>3.    I/We have never been made bankrupt and there is no litigation or petition filed against me/us in the past or presently.</Text>
          <Text style={styles.textBold6px}>4.    I/We shall promptly notify United Overseas Bank Limited (“Bank”) in writing if any information given becomes inaccurate or misleading or changes in any way before this application</Text>
          <Text style={styles.textBold6px}>        is approved. I/We shall supply any additional information and documentary proof as the Bank may require from time to time in connection with this application.</Text>
          <Text style={styles.textBold6px}>5.     I/We hereby authorize and give the Bank my/our irrevocable consent to make such inquiries and conduct all such checks on me/us (including, but not limited to, inquiries and checks</Text>
          <Text style={styles.textBold6px}>        with any credit bureau recognised as such by the Monetary Authority of Singapore or other parties relative to this applications) and obtain from and/or verify with any source and/or</Text>
          <Text style={styles.textBold6px}>        disclose or release any information relating to me/us or any of my/our account(s) with the Bank to any other party or source (including, but not limited to, any of the Bank’s related</Text>
          <Text style={styles.textBold6px}>        companies, advisors, credit bureaus, credit reference agents, insurance providers, Housing Development Board (HDB) or Central Provident Fund(CPF) Board or governmental authorities/</Text>
          <Text style={styles.textBold6px}>        agencies ) as the Bank may from time to time deem fit at the Bank’s own absolute discretion and without any liability or notice to me/us.</Text>
          <Text style={styles.textBold6px}>6.    I/We further acknowledge that the Bank reserves the right to reject this application without assigning any reason and retain the documents submitted with this application, for the</Text>
          <Text style={styles.textBold6px}>        purposes of this application.</Text>
          <Text style={styles.textBold6px}>7.     I/We hereby authorize the Bank to disclose any information of myself/ourselves and/or data relating to myself/ourselves to the Vendor (Seller), Supplier and/or Dealer (all referred</Text>
          <Text style={styles.textBold6px}>        to over leaf) by phone, electronic mail, short messaging service (SMS) or any other means of communication which the Bank deems appropriate, for the purposes of this application.</Text>
          <Text style={styles.textBold6px}>8.     I/We confirm that I/we have read and understood the Bank’s Privacy Notice (Individual) (available at www.uob.com.sg and the Bank’s branches) which forms part of the terms and</Text>
          <Text style={styles.textBold6px}>        conditions governing my/our relationship with the Bank. I/We consent to the Bank collecting, using and disclosing my/our personal data for Basic Banking Purposes, Co-branding</Text>
          <Text style={styles.textBold6px}>        Purpose, Research Purpose and Marketing Purpose as described in the Bank’s Privacy Notice (Individual). I/We note that (a) I/we may withdraw consent for any or all of the purposes</Text>
          <Text style={styles.textBold6px}>        at any time; (b) if I/we withdraw consent for Basic Banking Purposes and/or Co-branding Purpose, the Bank may not be able to continue to provide the products and services to</Text>
          <Text style={styles.textBold6px}>        me/us; (c) if I/we withdraw consent for Research Purpose and Marketing Purpose, my/our personal data will not be used for these purposes unless I/we expressly and separately</Text>
          <Text style={styles.textBold6px}>        consent to the same again.</Text>
          <View style={{marginTop:40, flexDirection:'row', justifyContent:'space-between'}}>
            <View style={{justifyContent:'center', alignItems:'center', marginLeft:20}}>
              <Text style={styles.textBold6px}>....................................................................................</Text>
              <Text style={styles.textBold6px}>Guarantor 1 Signature / Date</Text>
            </View>
            <View style={{justifyContent:'center', alignItems:'center', marginRight:20}}>
              <Text style={styles.textBold6px}>....................................................................................</Text>
              <Text style={styles.textBold6px}>Guarantor 2 Signature / Date</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={{flexDirection:'row', justifyContent:'space-between', position:'absolute', bottom:20, width:'100%',left:28}}>
        <Text style={styles.text}>*HP-27 (R3.16)</Text>
        <Text style={styles.text}>mc.kq.pp</Text>
      </View>
    </Page>
  </Document>
</>)


const UOBPdf = React.memo(({ isSave, setPdf, data }:Props) => {
  
  return (
      <BlobProvider 
      document={isSave?<Doc1 data={data} dob={moment(data.dob,'YYYY-MM-DD').format('DD/MM/YYYY')} 
      dobGuarantor={moment(data.guarantor[0]?.dobGuarantor,'YYYY-MM-DD').format('DD/MM/YYYY')}
      dobGuarantor1={moment(data.guarantor[1]?.dobGuarantor,'YYYY-MM-DD').format('DD/MM/YYYY')}/>:<></>}>
          {({blob})=>{
                  let file;
                  file = blob && new File([blob as Blob], 'uob.pdf', { type: 'application/pdf' });
                  data && setPdf(file)
              return <></>;
          }}
          
      </BlobProvider>
  )
});

export default UOBPdf;