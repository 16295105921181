import { Box, styled } from "@material-ui/core";
import Pagination, { UsePaginationProps } from "@material-ui/lab/Pagination";
import React from 'react';


const CustomPagination = (props: UsePaginationProps) => {
  
  return <CustomPaginationStyle>
    <Pagination {...props} />
  </CustomPaginationStyle>
}

const CustomPaginationStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "24px",
  marginBottom:"24px",
  "& .MuiPaginationItem-ellipsis":{
    color: "rgba(243, 243, 243, 0.65)",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .MuiPaginationItem-icon":{
    display: "flex",
    padding: "6px",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "8px",
    background:
      "linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%)",
    width: "20px",
    height: "20px",
    fill: '#171717'
  },
  "& .MuiPaginationItem-page":{
    color: (theme as any).typography.h3,
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    textDecorationLine: "underline",
  },
  "& .MuiPaginationItem-page.Mui-selected":{
    fontFamily: "Outfit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    background:
      "linear-gradient(96deg, #916538 -22.93%, #A07746 -9.8%, #B08955 0.2%, #BF9B63 9.37%, #CEAE71 15.96%, #DDC07F 22.45%, #EDD28E 30.39%, #F7DD91 50.43%, #EDD28E 67.97%, #DDC07F 78.59%, #CEAE71 85.12%, #BF9B63 92.53%, #B08955 101.91%, #A07746 111.71%, #916538 125.15%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textDecorationLine: "none",
  },
}));
export default CustomPagination;