import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: string;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  data:any;
  uploadedFiles:any;
  paginationData:any,
  selectId:any,
  searchText: string,
  openModal: boolean,
  openImportModal: boolean,
  open:boolean,
  menuAnchorEl: any,
  menuAnchorElNoDelete: any,
  selectedPostedBy: string,
  selectedMake: string,
  selectedModel: string,
  selectedPrice: string,
  selectedDepreciation: string,
  selectedRegDate: string,
  selectedEngineCap: string,
  selectedMileage: string,
  selectedVehicleType: string,
  vehicleNumber:string,
  vehicleSelect:string,
  vehicleId:string,
  vehicleEditId:string,
  alertOpen:string,
  vehicleData:{id: string, type: string, attributes:any}[];
  delete:any,
  currentPage:number,
  totalPage: number,
  errorFile:boolean,
  deleteError:boolean,
  deletedFileName:string, 
  csvFile:any,
  selectedFilter: string,
  anchorElImport: any,

  valueSearchPostedBy: string,
  valueSearchMake: string,
  valueSearchModel: string,
  valueSearchPrice: string,
  valueSearchDeprecation: string,
  valueSearchRegDate: string,
  valueSearchEngineCap: string,
  valueSearchMilage:string,
  valueSearchVehicleType:string,
  searchAttribute: string,
  valueSearch: string,
  listMakeCatalogue: any,
  listModelCatalogue: any,
  selectedGovernment: any,
  governmentList: any,
  formIsDirty: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
  getProductApiCallId: any;
  getVehicleDetailId:string = "";
  getFilterId:string = "";
  deleteDetailID:string = "";
  EditCataloguesApiId:string = "";
  import_csvcallID:string ="";
  makeListId:any;
  modelListId: any;
  getGovernmentListId: any;
  getVehicleId: any;
   // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      formIsDirty: false,
      anchorElImport: null,
      arrayHolder: [],
      paginationData:{},
      selectId:null,
      open:false,
      openModal: false,
      openImportModal:false,
      menuAnchorEl: null,
      menuAnchorElNoDelete: null,
      currentPage: 1,
      totalPage: 0,
      selectedPostedBy: "",
      selectedMake: "",
      selectedModel: "",
      selectedPrice: "",
      selectedDepreciation: "",
      selectedRegDate: "",
      selectedEngineCap: "",
      selectedMileage: "",
      selectedVehicleType: "",
      data: [
        {
          ownerName: "joy",
          carName: "Audi"
        },
        {
          ownerName: "John",
          carName: "BMW"
        },
        {
          ownerName: "meet",
          carName: "MG"
        },
        {
          ownerName:"tom",
          carName:"TATA"
        },      
      ],
      searchText: "",
      token: "",
      vehicleNumber:"",
      vehicleSelect:"",
      vehicleId:"",
      vehicleEditId:"",
      alertOpen:"",
      errorFile:false,
      uploadedFiles:[],
      vehicleData:[],
      delete:[],
      deleteError:false,
      csvFile:{},
      deletedFileName:"",
      selectedFilter: "",

      valueSearchPostedBy: '',
      valueSearchMake: '',
      valueSearchModel: '',
      valueSearchPrice: '',
      valueSearchDeprecation: '',
      valueSearchRegDate: '',
      valueSearchEngineCap: '',
      valueSearchMilage:'',
      valueSearchVehicleType:'',
      searchAttribute: '',
      valueSearch: '',
      listMakeCatalogue: [],
      listModelCatalogue: [],
      selectedGovernment: {},
      governmentList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    this.getVehicleDetailApi(1);
    this.getMakeList();
    this.getGovernmentList();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (!apiRequestCallId || !responseJson) {
      return;
    }
    switch (apiRequestCallId) {
      case this.getVehicleDetailId:
        this.getVehicleDetailReceiver(responseJson);
        break;
      case this.getVehicleId:
        this.getVehicleReceiver(responseJson);
        break;
      case this.makeListId:
        this.getMakeListCatalogueReceiver(responseJson);
        break;
      case this.modelListId:
        this.getModelListCatalogueReceiver(responseJson);
        break;
      case this.getGovernmentListId:
        this.getGovermentListReceiver(responseJson);
        break;
      case this.deleteDetailID:
        this.deleteDetailReceiver(responseJson);
        break;
      case this.import_csvcallID:
        this.importCSVReceiver(responseJson)
        break;
            
    }
    // runEngine.debugLog("Message Recived", message);
  
    // Customizable Area End
  }

  // Customizable Area Start
  checkSubmit = () => {
    this.setState({...this.state, formIsDirty: true})
  }
  
  getGovernmentList = async () => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getGovernmentApi}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.getGovernmentListId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  getGovermentListReceiver = (responseJson: any) => {
    const governmentList = responseJson.data.map((e: any)=>e?.attributes)
    this.setState({governmentList: governmentList})
  }

  getMakeList = async () => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getMakeModalDataApi}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.makeListId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMakeListCatalogueReceiver = (responseJson: any) => {
    const makeList = responseJson.data.map((e: any)=>e.attributes.name)
    this.setState({listMakeCatalogue: makeList})
  }
  getModelList = async (selectedMake: string) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getMakeModalDataApi}?filter_values=${selectedMake}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.modelListId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getModelListCatalogueReceiver = (responseJson: any) => {
    const modelList = responseJson.data?.map((e: any)=>e.attributes.name)
    this.setState({listModelCatalogue: modelList ?? []})
  }

  handleFileDataDelete = (index:number) => {
    this.setState((prevState) => {
      const deletedFileName = prevState.uploadedFiles[0].file.name;
      const updatedFiles = [...prevState.uploadedFiles];
      updatedFiles.splice(index, 1);
      return { uploadedFiles: updatedFiles,deletedFileName : deletedFileName};
    });
    this.setState({deleteError: !this.state.deleteError});
    };
//istanbul ignore next
    handleCsvChange = (event: any) => {
      const input = event.target;
      const files = input.files;
      const newUploadedFiles = [] as any;
    
      for (const file of files) {
        if (!this.isCsvFile(file)) {
          this.setState({ errorFile: true });
        }
    
        const reader = new FileReader();
    
        reader.onload = (e: any) => {
          const csvContent = e.target.result;
    
          newUploadedFiles.push({
            file,
            fileSize: this.formatFileSize(file.size),
            csvContent,
          });
    
          this.setState((prevState: any) => ({
            uploadedFiles: [...prevState.uploadedFiles, ...newUploadedFiles],
          }));
        };
        reader.readAsText(file);
      }
    };
//istanbul ignore next
handleDownloadTemplate = () => {
  let templateContent = null;
  let fileName = 'sample.csv'; 
  
  templateContent = configJSON.sampleCsvFile;

  const blob = new Blob([templateContent], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
};

  isCsvFile = (file:any) => {
    return file.type === 'text/csv' || file.name.toLowerCase().endsWith('.csv');
  };

  formatFileSize = (size: number): string => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return Math.round((size / Math.pow(k, i)) * 100) / 100 + ' ' + sizes[i];
  };


   handleClickOpen = () => {
    this.setState({ openModal: !this.state.openModal,csvFile:"" , vehicleNumber:'', selectedGovernment:{}, vehicleId:'', formIsDirty: false});
  };


  handleClickOpenModal = () => {
    this.setState({ openImportModal: !this.state.openImportModal, anchorElImport:null });
    this.setState({deleteError: false});
    this.setState({errorFile:false});
    this.setState({csvFile:"",uploadedFiles:[]})
  };

  handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>,id:number) => {
    event.stopPropagation();
    this.setState({ menuAnchorEl: event.currentTarget , selectId: id });
  };

  handleOpenMenuNoDelete = (event: React.MouseEvent<HTMLDivElement>,id:number) => {
    event.stopPropagation();
    this.setState({ menuAnchorElNoDelete: event.currentTarget , selectId: id });
  };
  
  handleCloseMenu = (event: any) => {
    event?.stopPropagation();
    this.setState({  menuAnchorEl: null });
  };

  handleCloseMenu1 = () => {
    this.setState({  menuAnchorEl: null });
  };

  handleCloseMenuNoDelete = (event: any) => {
    event?.stopPropagation();
    this.setState({ menuAnchorElNoDelete: null });
  };
  
  handleDelete = (event:any) => {
    event?.stopPropagation();
    this.setState({ menuAnchorEl: null });
    this.deleteDetailApi();
  };

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });  
  };

  handleNavigateOverview = (event: any, id: string) => {
    event.stopPropagation();
    this.props.navigation.navigate('CatalogueDetail', { vehicleId: id })
  }
  handleNavigateOverviewEdit = (event: any) => {
    event.stopPropagation();
    if({id:this.state.selectId}){
      this.props.navigation.navigate('EditCatalougue',{id:this.state.selectId})
    }
  }
  
  handleNavigateAdd = () => {
    if({id:this.state.selectId}){
      setStorageData('vehicleNumber', this.state.vehicleNumber);
      setStorageData('government', this.state.selectedGovernment.id);
      setStorageData('empId', this.state.vehicleId)

      this.props.navigation.navigate('AddCatalougue')
    }
  }

  handleSetSearchBar = (args: {target: {value: string}})=>{
    this.setState({
      searchText: args.target.value, 
      searchAttribute: '', 
      valueSearch:'', 
      valueSearchMake:'',
      valueSearchModel:'',
      valueSearchPostedBy: '',
      valueSearchDeprecation: '',
      valueSearchEngineCap: '',
      valueSearchMilage: '',
      valueSearchRegDate: '',
      valueSearchPrice:'',
      valueSearchVehicleType:''
    })
  }

  getVehicleDetailApi = async (page: number) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVehicleDetailApi}?page=${page}&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.getVehicleDetailId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  searchVehicleDetailApi = async (page: number) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVehicleDetailApi}?page=${page}&per_page=10&filter_params=${this.state.searchAttribute}&filter_value=${this.state.valueSearch}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.getVehicleDetailId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  searchVehicleApi = async (page: number) => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVehicleDetailApi}/search?page=${page}&per_page=10&query=${this.state.searchText}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.getVehicleId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getVehicleReceiver = (responseJson: any) => {
    this.setState({ vehicleData: responseJson.data?.data,totalPage: Math.ceil(responseJson.pagination?.total_count/10)})
  }

  getVehicleDetailReceiver = (responseJson: any) => {
    this.setState({ vehicleData: responseJson.data.data,totalPage: Math.ceil(responseJson?.pagination.total_count/10) })
  }

  deleteDetailApi = async () => {
    const tokenValue = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": tokenValue
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.deleteDetailApi}${this.state.selectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    this.deleteDetailID= requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

   deleteDetailReceiver = (responseJson: any) => {
    
    this.setState({currentPage:1})
    if(this.state.searchText !== ''){
      this.searchVehicleApi(1) 
    } else{
      this.searchVehicleDetailApi(1)
    }
      
      
    this.handleCloseMenu1();

  }

  importCatalogueApi = async() => {
    const tokenValue = await getStorageData("authToken")
        const headers = {
          token: tokenValue,
        };
        let formdata = new FormData();
        const fileObject = this.state.uploadedFiles[0];
        formdata.append("csv_file", fileObject.file); 
        
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.importCatalogueEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.addCataloguePost
        );
        this.import_csvcallID = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    importCSVReceiver=(responseJson:any)=>{
     this.setState({csvFile:responseJson})
    }
    //istanbul ignore next
    navigetHistorypage = ()=>{
      if (this.state.csvFile) {
        this.props.navigation.navigate("History")
        this.setState({csvFile:""})
      }
    }
  handleNavigateHistory = ()=>{
    this.props.navigation.navigate("History")
  }

  handleChangePageCatalogue=(_event: any, value:any) =>{ 
    if(this.state.searchText.length> 0){
      this.searchVehicleApi(value);
      this.setState({valueSearch:'', searchAttribute:''})
    } else if(this.state.valueSearch.length > 0){
      this.searchVehicleDetailApi(value);
    } else {
      this.getVehicleDetailApi(value);
    }
    this.setState({currentPage:value})
  }

  handleCloseImport = ()=>{
    this.setState({anchorElImport:null})
  }
  handleClickOpenAnchor= (event: any)=>{
    const anchorElImport = event.currentTarget
    this.setState({...this.state, anchorElImport })
  }

  onChangeSearchCatalogue =(e:any, attribute: string)=>{
    this.setState({
      valueSearch:e.target.value,
      searchAttribute: attribute,
      currentPage:1,
      searchText:''
    })
    switch (attribute) {
      case 'posted_by':
        this.setState({
          valueSearchPostedBy: e.target.value,
          valueSearchMake: '',
          valueSearchModel: '',
          valueSearchPrice: '',
          valueSearchDeprecation: '',
          valueSearchRegDate: '',
          valueSearchEngineCap: '',
          valueSearchMilage:'',
          valueSearchVehicleType:'',
        })
        break;
      case 'make':
        this.getModelList(e.target.value)
        this.setState({
          valueSearchPostedBy: '',
          valueSearchMake:e.target.value,
          valueSearchModel: '',
          valueSearchPrice: '',
          valueSearchDeprecation: '',
          valueSearchRegDate: '',
          valueSearchEngineCap: '',
          valueSearchMilage:'',
          valueSearchVehicleType:'',
        })
        break;
      case 'model':
        this.setState({
          valueSearchPostedBy: '',
          // valueSearchMake: '',
          valueSearchModel: e.target.value,
          valueSearchPrice: '',
          valueSearchDeprecation: '',
          valueSearchRegDate: '',
          valueSearchEngineCap: '',
          valueSearchMilage:'',
          valueSearchVehicleType:'',
        })
        break;
      case 'price':
        this.setState({
          valueSearchPostedBy: '',
          valueSearchMake: '',
          valueSearchModel: '',
          valueSearchPrice: e.target.value,
          valueSearchDeprecation: '',
          valueSearchRegDate: '',
          valueSearchEngineCap: '',
          valueSearchMilage:'',
          valueSearchVehicleType:'',
        })
        break;
      case 'depreciation':
        this.setState({
          valueSearchPostedBy: '',
          valueSearchMake: '',
          valueSearchModel: '',
          valueSearchPrice: '',
          valueSearchDeprecation: e.target.value,
          valueSearchRegDate: '',
          valueSearchEngineCap: '',
          valueSearchMilage:'',
          valueSearchVehicleType:'',
        })
        break;
      case 'reg_date':
        this.setState({
          valueSearchPostedBy: '',
          valueSearchMake: '',
          valueSearchModel: '',
          valueSearchPrice: '',
          valueSearchDeprecation: '',
          valueSearchRegDate: e.target.value,
          valueSearchEngineCap: '',
          valueSearchMilage:'',
          valueSearchVehicleType:'',
        })
        break;
      case 'engine_cap':
        this.setState({
          valueSearchPostedBy: '',
          valueSearchMake: '',
          valueSearchModel: '',
          valueSearchPrice: '',
          valueSearchDeprecation: '',
          valueSearchRegDate: '',
          valueSearchEngineCap: e.target.value,
          valueSearchMilage:'',
          valueSearchVehicleType:'',
        })
        break;
      case 'mileage':
        this.setState({
          valueSearchPostedBy: '',
          valueSearchMake: '',
          valueSearchModel: '',
          valueSearchPrice: '',
          valueSearchDeprecation: '',
          valueSearchRegDate: '',
          valueSearchEngineCap: '',
          valueSearchMilage:e.target.value,
          valueSearchVehicleType:'',
        })
        break;
      case 'vehicle_type':
        this.setState({
          valueSearchPostedBy: '',
          valueSearchMake: '',
          valueSearchModel: '',
          valueSearchPrice: '',
          valueSearchDeprecation: '',
          valueSearchRegDate: '',
          valueSearchEngineCap: '',
          valueSearchMilage:'',
          valueSearchVehicleType:e.target.value,
        })
        break;
    }
  }
  shouldComponentUpdate(_nextProps: Readonly<Props>, nextState: Readonly<S>, _nextContext: any): boolean {
    if(nextState.valueSearch !== this.state.valueSearch){
      if(nextState.valueSearch.length > 0 ){
        this.searchVehicleDetailApi(1)
      } else{
        this.getVehicleDetailApi(1);
        this.setState({currentPage:1})
      }
    }
    if(nextState.searchText !== this.state.searchText){
      if(nextState.searchText.length > 0 ){
        this.searchVehicleApi(1)
      } else{
        this.getVehicleDetailApi(1);
        this.setState({currentPage:1})
      }
    }
    return true;
  }

  handleReset1 = ()=>{
    this.setState({
      anchorElImport:null
    })
  }
  // Customizable Area End
}
