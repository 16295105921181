// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
 
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import { createTheme,  ThemeProvider } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Cfintegrationwithsingpass4 from "../../blocks/cfintegrationwithsingpass4/src/Cfintegrationwithsingpass4";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Cfwhatsappintegrationtosendbroadcastmessage from "../../blocks/cfwhatsappintegrationtosendbroadcastmessage/src/Cfwhatsappintegrationtosendbroadcastmessage";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Cfintegrationwithinstagram2 from "../../blocks/cfintegrationwithinstagram2/src/Cfintegrationwithinstagram2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Imagemanagement2 from "../../blocks/imagemanagement2/src/Imagemanagement2";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Sorting from "../../blocks/sorting/src/Sorting";
import Catalogue from "../../blocks/catalogue/src/Catalogue.web";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Cfintegrationwithsingpass2 from "../../blocks/cfintegrationwithsingpass2/src/Cfintegrationwithsingpass2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import Cfserviceworkerintegration6 from "../../blocks/cfserviceworkerintegration6/src/Cfserviceworkerintegration6";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Cfintegrationwithtiktok from "../../blocks/cfintegrationwithtiktok/src/Cfintegrationwithtiktok";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Cfintegrationwithfacebook2 from "../../blocks/cfintegrationwithfacebook2/src/Cfintegrationwithfacebook2";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Cfprofitlosscalculationsforanalytics from "../../blocks/cfprofitlosscalculationsforanalytics/src/Cfprofitlosscalculationsforanalytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Cfcollectcarinformation from "../../blocks/cfcollectcarinformation/src/Cfcollectcarinformation";
import Cfserviceworkerintegration12 from "../../blocks/cfserviceworkerintegration12/src/Cfserviceworkerintegration12";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement1 from "../../blocks/contentmanagement1/src/Contentmanagement1";
import Adminconsole from "../../blocks/adminconsole/src/Adminconsole";
import Download1 from "../../blocks/download1/src/Download1";
import Signuplogin from "../../blocks/signuplogin/src/Signuplogin";
import Calendar from "../../blocks/calendar/src/Calendar";
import Analytics1 from "../../blocks/analytics1/src/Analytics1";
import Catalogue1 from "../../blocks/catalogue1/src/Catalogue1";
import Termsandconditions from "../../blocks/termsandconditions/src/Termsandconditions";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Dashboard4 from "../../blocks/dashboard4/src/Dashboard4";
import Dashboard from '../../blocks/dashboard/src/Dashboard.web'
import AccountPayrollWeb from '../../blocks/dashboard/src/AccountingPayroll.web'
import Settings from "../../blocks/settings/src/Settings";
import Paymentadmin from "../../blocks/paymentadmin/src/Paymentadmin";
import Payments from "../../blocks/payments/src/Payments";
import Subscriptionbilling from "../../blocks/subscriptionbilling/src/Subscriptionbilling";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import MiniDrawer from "../../components/src/LandingPageDrawer.web";
import AddCatalougue from "../../blocks/catalogue/src/AddCatalougue.web";
import History from "../../blocks/catalogue/src/History.web";
import LandingPageWeb from "../../blocks/landingpage/src/LandingPage.web";
import LandingPageDrawerWeb from "../../components/src/LandingPageDrawer.web";
import NotificationDrawer from "../../components/src/NotificationDrawer.web";
import CatalogueDetail from "../../blocks/catalogue/src/CatalogueDetail.web";
import LoanCalculator from "../../blocks/catalogue/src/LoanCalculator.web";
import Cfcrm4 from "../../blocks/cfcrm4/src/Cfcrm4.web";
import NewCustomer from "../../blocks/cfcrm4/src/NewCustomerCRM.web";
import CustomerGroup from "../../blocks/cfcrm4/src/CustomerGroup.web";
import NewCustomerGroup from "../../blocks/cfcrm4/src/NewCustomerGroup.web";
import Broadcast from "../../blocks/cfcrm4/src/Broadcast.web";
import ResetPasswordThroughSetting from "../../blocks/settings/src/ResetPasswordThroughSetting.web";
import NewBroadcast from "../../blocks/cfcrm4/src/NewBroadcast.web";
import SalesAndContract from "../../blocks/ordermanagement/src/OrderManagement";
import NewSalesAndContract from "../../blocks/ordermanagement/src/NewSalesAndContract";
import MarketingWeb from "../../blocks/cfintegrationwithtiktok/src/Marketing.web"
import CreateNewPost from "../../blocks/cfintegrationwithtiktok/src/CreateNewPost.web"
import DealDetail from '../../blocks/ordermanagement/src/DealDetail';
import EditGuarantor from '../../blocks/ordermanagement/src/GuarantorDetails';
import MarketingDetails from "../../blocks/cfintegrationwithtiktok/src/MarketingDetails.web"
import { getStorageData } from '../../framework/src/Utilities';
 
 

const routeMap = {
Contentmanagement1:{
 component:Contentmanagement1,
path:"/Contentmanagement1"},
Adminconsole:{
 component:Adminconsole,
path:"/Adminconsole"},
Download1:{
 component:Download1,
path:"/Download1"},
Signuplogin:{
 component:Signuplogin,
path:"/Signuplogin"},
Calendar:{
 component:Calendar,
path:"/Calendar"},
Analytics1:{
 component:Analytics1,
path:"/Analytics1"},
Catalogue1:{
 component:Catalogue1,
path:"/Catalogue1"},
Termsandconditions:{
 component:Termsandconditions,
path:"/Termsandconditions"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Dashboard4:{
 component:Dashboard4,
path:"/Dashboard4"},
Settings:{
 component:Settings,
path:"/Settings"},
Paymentadmin:{
 component:Paymentadmin,
path:"/Paymentadmin"},
Payments:{
 component:Payments,
path:"/Payments"},
Subscriptionbilling:{
 component:Subscriptionbilling,
path:"/Subscriptionbilling"},

Cfintegrationwithsingpass4:{
 component:Cfintegrationwithsingpass4,
path:"/Cfintegrationwithsingpass4"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Cfwhatsappintegrationtosendbroadcastmessage:{
 component:Cfwhatsappintegrationtosendbroadcastmessage,
path:"/Cfwhatsappintegrationtosendbroadcastmessage"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
Cfintegrationwithinstagram2:{
 component:Cfintegrationwithinstagram2,
path:"/Cfintegrationwithinstagram2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPasswords"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Imagemanagement2:{
 component:Imagemanagement2,
path:"/Imagemanagement2"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},

Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Cfintegrationwithsingpass2:{
 component:Cfintegrationwithsingpass2,
path:"/Cfintegrationwithsingpass2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Interactivefaqs:{
 component:Interactivefaqs,
path:"/Interactivefaqs"},
AddInteractivefaqs:{
 component:AddInteractivefaqs,
path:"/AddInteractivefaqs"},
Cfserviceworkerintegration6:{
 component:Cfserviceworkerintegration6,
path:"/Cfserviceworkerintegration6"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Cfintegrationwithtiktok:{
 component:Cfintegrationwithtiktok,
path:"/Cfintegrationwithtiktok"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Cfintegrationwithfacebook2:{
 component:Cfintegrationwithfacebook2,
path:"/Cfintegrationwithfacebook2"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Cfprofitlosscalculationsforanalytics:{
 component:Cfprofitlosscalculationsforanalytics,
path:"/Cfprofitlosscalculationsforanalytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/",
exact: true,
},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Cfcollectcarinformation:{
 component:Cfcollectcarinformation,
path:"/Cfcollectcarinformation"},
Cfserviceworkerintegration12:{
 component:Cfserviceworkerintegration12,
path:"/Cfserviceworkerintegration12"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
ResetPassword:{
  component:ResetPassword,
 path:"/ResetPassword"},
 ForgotPasswordWeb:{
  component:ForgotPasswordWeb,
 path:"/ForgotPassword"},
 LandingPageWeb:{
  component:LandingPageWeb,
 path:"/LandingPageWeb"},
 MiniDrawer:{
  component:MiniDrawer,
 path:"/MiniDrawer"},
 AddCatalougue:{
  component:AddCatalougue,
  path:"/AddCatalougue"},
  EditCatalougue:{
    component:AddCatalougue,
    path:"/EditCatalougue/:id"},
  History: {
    component: History,
    path: "/History"
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  CatalogueDetail: {
    component: CatalogueDetail,
    path: '/CatalogueDetail/:vehicleId'
  },
  LoanCalculator: {
    component: LoanCalculator,
    path: '/Calculator/:vehicleId'
  },
  
  NewCustomer: {
    component: NewCustomer,
    path: '/NewCustomer'
  },
  CustomerGroup: {
    component: CustomerGroup,
    path: '/CustomerGroup'
  },
  CRM: {
    component: Cfcrm4,
    path: '/CRM'
  },
  EditCustomer: {
    component: NewCustomer,
    path: '/EditCustomer/:customerId'
  },
  NewCustomerGroup: {
    component: NewCustomerGroup,
    path: '/NewCustomerGroup'
  },
  EditCustomerGroup: {
    component: NewCustomerGroup,
    path: '/EditCustomerGroup/:groupId'
  },
  Broadcast: {
    component: Broadcast,
    path: '/Broadcast'
  },
  ResetPasswordThroughSetting:{
    component:ResetPasswordThroughSetting,
    path: '/resetPasswordthroughsetting'
  }
,
  NewBroadcast: {
    component: NewBroadcast,
    path: '/NewBroadcast'
  },

  SalesAndContract: {
    component: SalesAndContract,
    path: '/SalesAndContract'
  },

  NewSalesAndContract: {
    component: NewSalesAndContract,
    path: '/NewSalesAndContract'
  },
  EditSalesAndContract: {
    component: NewSalesAndContract,
    path: '/EditSalesAndContract/:dealId'
  },
  DealDetail: {
    component: DealDetail,
    path: '/DealDetail/:dealId'
  },
  EditGuarantor: {
    component: EditGuarantor,
    path: '/EditGuarantor/:dealId/:guarantorId'
  },
  AccountingAndPayroll: {
    component: AccountPayrollWeb,
    path: '/AccountingAndPayroll'
  },
  Marketing: {
    component: MarketingWeb,
    path: '/Marketing'
  },
  CreateNewPost :{
    component: CreateNewPost,
    path: '/CreateNewPost'
  },
  MarketingDetails:{
    component:MarketingDetails,
    path:"/MarketingDetails/:postid"
  },
  EdiMediaPost: {
    component: CreateNewPost,
    path: '/EdiMediaPost/:postId'
  },
  
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      // contrastText: "#fff",
      background: '#E5E4E1',
      
      secondary: '#ECECEC',
    },
    
  },
  typography:{
    body1: '#E5E4E1',
    body2:  '#ECECEC',
    surface2: '#f5f5f5',
    h1: 'rgba(23, 23, 23, 1)',
    h2: 'rgba(23, 23, 23, 0.8)',
    h3: 'rgba(23, 23, 23, 0.65)',
    border:'#BF9B6350',
    border2: 'rgba(203, 213, 225, 1)',
    textSecond: "rgba(23, 23, 23, 0.8)",
    line: "rgba(191, 155, 99, 0.5)",
    borderSetting: "#64748B",
  },
  
});

export const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      background: '#151515',
      secondary: '#2B2B2B',
      
    },
  },
  typography:{
    body1:'#151515',
    body2: '#2B2B2B',
    surface2: '#3f3e3a',
    h1: 'rgba(243, 243, 243, 1)',
    h2: 'rgba(243, 243, 243, 0.8)',
    h3:'rgba(243, 243, 243, 0.65)', 
    h4: '#171717',
    border:'#FFF6DC30',
    border2: 'rgba(203, 213, 225, 1)',
    textSecond: "#64748B",
    line: "rgba(255, 246, 220, 0.3)",
    borderSetting: 'rgba(243, 243, 243, 1)',
  },
  
});

class App extends Component {

  constructor(){
    super();
    this.state={
      anchorOpenNotification: false,
    }    
  }

  handleToken = async()=>{
    const tokenValue = await getStorageData("authToken");
    const currentRoute = window.location.pathname; 
    if(tokenValue == "" || tokenValue === null){
      
      const isPreviewRoute = currentRoute === '/' || currentRoute === '/ForgotPassword' || currentRoute === '/ResetPassword';
      if(!isPreviewRoute){
      window.history.pushState(null,'','/')
      window.location.href = '/'}
    }
  }
  handleOpenNotification = () => {
    this.setState({anchorOpenNotification: !this.state.anchorOpenNotification,})
  }
   
  render() {
    const currentRoute = window.location.pathname; 
    const isPreviewRoute = currentRoute === '/' || currentRoute === '/ForgotPassword' || currentRoute === '/ResetPassword';
    const isZoneDisplayRoute = currentRoute.includes('dsdsd')
    const shouldShowDrawerMenu = !isPreviewRoute && !isZoneDisplayRoute;

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    const themeMode = localStorage.getItem('themeMode');
    {this.handleToken()}
    
    return (
      <ThemeProvider theme={themeMode ==='light' ? lightTheme : darkTheme}>
        <View style={{minHeight:'100vh',width:'100%', flexDirection:'row', backgroundColor: themeMode ==='light'?'#E5E4E1':'#151515'}}>
          {shouldShowDrawerMenu && <LandingPageDrawerWeb handleOpenNotification={this.handleOpenNotification}/>}
          <View style={{marginTop: shouldShowDrawerMenu?'64px':'0px',flex:1, height:'100%', width:'100%'}}>
            {WebRoutesGenerator({ routeMap })}
          </View>
          <NotificationDrawer anchor={this.state.anchorOpenNotification} handleOpenNotification={this.handleOpenNotification}/>
          <ModalContainer />
        </View>
      </ThemeProvider>
    );
  }
}

export default App;