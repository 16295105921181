import React, {  } from "react";

import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    BlobProvider,
    Image,
    Font} from "@react-pdf/renderer";
import moment from "moment";

const BoldFont = require("./fonts/TimesNewRomanBold.ttf");

type Props = {
    isSave: boolean;
    setPdf: any;
    data: any;
};

Font.register({
    family: 'Bold1',
    src: BoldFont,
    fontWeight: 'bold',
    format: "truetype",
});

Font.register({
    family: "Italianno",
    src: require('./fonts/EBGaramond-Italic.ttf'),
    fontWeight: 'normal',
});
Font.register({
    family: 'Regular1',
    src: require('./fonts/TimesNewRoman400.ttf'),
    fontWeight: 'normal',
});
Font.register({
    family: 'BoldArialNarrow',
    src: require('./fonts/arialnarrow_bold.ttf'),
    fontWeight: 'bold',
    format: "truetype",
});

Font.register({
    family: 'RegularArialNarrow',
    src: require('./fonts/arialnarrow.ttf'),
    fontWeight: 'normal',
});

const styles = StyleSheet.create({
    text: {
        fontFamily: 'Regular1',
        fontSize: 6.5,
    },
    textArial: {
        fontFamily: 'RegularArialNarrow',
        fontSize: 11,
    },
    textArialBold: {
        fontFamily: 'BoldArialNarrow',
        fontSize: 10,
    },
    textItalic: {
        fontFamily: 'Italianno',
        fontSize: 11,
    },
    textArialBold11: {
        fontFamily: 'BoldArialNarrow',
        fontSize: 11,
    },
    text10normal: {
        fontFamily: 'Regular1',
        fontSize: 10,
    },
    text12: {
        fontFamily: 'Regular1',
        fontSize: 13,
    },
    textBold17: {
        fontFamily: 'Bold1',
        fontSize: 17,
    },
    textBold12: {
        fontFamily: 'Bold1',
        fontSize: 12,
    },
    textBold13: {
        fontFamily: 'Bold1',
        fontSize: 13,
    },
    textBold10: {
        fontFamily: 'Bold1',
        fontSize: 9,
    },
    textBold6: {
        fontFamily: 'Bold1',
        fontSize: 6.5,
    },
});





const pStyles = StyleSheet.create({

    page: {
        backgroundColor: "#ffffff",
        padding: '20px 23px',
        letterSpacing: 0
    },

    page2: {
        backgroundColor: "#ffffff",
        padding: '74px 74px',
        letterSpacing: 0
    },
    page3: {
        backgroundColor: "#ffffff",
        padding: '49px 65.85px',
        letterSpacing: 0
    },
});

const Doc = (data: any) => {
    return <Doc1 />
}

const Doc1 = ({data, dob, dobGuarantor}: any) => (<>
    <Document>
        <Page size="A4" style={pStyles.page}>
            <View style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>

                <Image src={require("../src/TokyoLogo.png")} style={{ width: 58, height: 61, paddingBottom: "7px" }} />



                <View style={{ flexDirection: 'column', justifyContent: "flex-end", alignItems: "flex-end" }}>
                    <Text style={{ ...styles.textBold12, textAlign: "right" }}>TOKYO CENTURY LEASING (S) PTE LTD </Text>
                    <Text style={styles.textBold12}>
                        APPLICATION FOR MOTOR VEHICLE HIRE PURCHASE</Text>
                </View>

            </View>
            <View style={{margin:"9px 0"}}>
                <Text style={styles.textItalic}>For loan processing, please atach acopy of nicome statement, IR84 or 3months Pay Slip; registration card and NRCI</Text>
            </View>
            <View style={{ border: '0.5px solid #000', marginTop: 0.31 }}>
                <View style={{ textAlign: "center", padding: '1px 6px', borderBottom: '0.5px solid #000' }}>
                    <Text style={styles.textBold10}>PERSONAL PARTICULARS</Text>
                </View>
                <View style={{ borderBottom: '0.5px solid #000', width: '100%', flexDirection: 'row' }}>
                    <View style={{ width: '100%', padding: '1px 6px', borderRight: '0.5px solid #000' }}>
                        <Text style={{ ...styles.textBold10, textAlign: "center", }}>Hirer </Text>
                    </View>
                    <View style={{ width: '100%', padding: '1px 6px', }}>
                        <Text style={{ ...styles.textBold10, textAlign: "center", }}>Guarantor </Text>
                    </View>
                </View>
                <View style={{ borderBottom: '0.5px solid #000', flexDirection: 'row' }}>
                    <View style={{ width: '100%', padding: '1px 6px', borderRight: '0.5px solid #000' }}>
                        <Text style={styles.text10normal}>Surname: {data.fullName}</Text>
                    </View>
                    <View style={{ width: '70%', padding: '1px 6px', borderRight: '0.5px solid #000' }}>
                        <Text style={styles.text10normal}>Surname:  {data.guarantor[0] && data.guarantor[0].fullNameGuarantor}</Text>

                    </View>
                    <View style={{ width: '30%', padding: '1px 6px' }}>
                        <Text style={styles.text10normal}>Relationship:</Text>

                    </View>
                </View>
                <View style={{ borderBottom: '0.5px solid #000', flexDirection: 'row' }}>
                    <View style={{ width: '100%', padding: '1px 6px', borderRight: '0.5px solid #000' }}>
                        <Text style={styles.text10normal}>Given Name:</Text>
                    </View>
                    <View style={{ width: '70%', borderRight: '0.5px solid #000', padding: '1px 6px' }}>
                        <Text style={styles.text10normal}>Given Name:</Text>
                    </View>
                    <View style={{ width: '30%', padding: '1px 6px' }}>


                    </View>
                </View>
                <View style={{ borderBottom: '0.5px solid #000', flexDirection: 'row' }}>
                    <View style={{ width: '100%', flexDirection: 'row', }}>
                        <View style={{ width: '100%', borderRight: '0.5px solid #000' }}>
                            <View style={{ width: '100%', height: 30, borderBottom: "0.5px solid #000", padding: '1px 6px' }}>
                                <Text style={styles.text10normal}>NRCI No: {data.NRIC}</Text>
                            </View>

                            <View style={{ width: '100%', height: 20, padding: '1px 6px', borderBottom: '0.5px solid #000' }}>
                                <Text style={styles.text10normal}>Nationality:</Text>
                            </View>

                            <View style={{ width: '100%', height: 20, padding: '1px 6px', }}>
                                <Text style={styles.text10normal}> Date of Birth: {dob}</Text>
                            </View>
                        </View>

                        <View style={{ width: '50%', padding: '1px 2px', borderRight: '0.5px solid #000' }}>
                            <Text style={styles.text10normal}>Gender: </Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Male</Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Female</Text>
                        </View>
                        <View style={{ width: '50%', padding: '1px 2px', borderRight: '0.5px solid #000' }}>
                            <Text style={styles.text10normal}> Marital Status</Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Single</Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Married</Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Others</Text>
                        </View>
                    </View>
                    <View style={{ width: '100%', flexDirection: 'row', }}>
                        <View style={{ width: '100%', borderRight: '0.5px solid #000' }}>
                            <View style={{ width: '100%', height: 30, borderBottom: "0.5px solid #000", padding: '5px 6px' }}>
                                <Text style={styles.text10normal}>NRCI No: {data.guarantor[0] && data.guarantor[0].NRICGuarantor}</Text>
                            </View>

                            <View style={{ width: '100%', height: 20, padding: '1px 6px', borderBottom: '0.5px solid #000' }}>
                                <Text style={styles.text10normal}>Nationality:</Text>
                            </View>

                            <View style={{ width: '100%', height: 20, padding: '1px 6px', }}>
                                <Text style={styles.text10normal}> Date of Birth: {dobGuarantor}</Text>
                            </View>
                        </View>

                        <View style={{ width: '50%', padding: '1px 2px', borderRight: '0.5px solid #000' }}>
                            <Text style={styles.text10normal}>Gender: </Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Male</Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Female</Text>
                        </View>
                        <View style={{ width: '50%', padding: '1px 2px' }}>
                            <Text style={styles.text10normal}> Marital Status</Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Single</Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Married</Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Others</Text>
                        </View>
                    </View>
                </View>
                <View style={{ borderBottom: '0.5px solid #000', width: '100%', flexDirection: 'row' }}>
                    <View style={{ width: '100%', flexDirection: 'row', borderRight: '0.5px solid #000' }}>
                        <View style={{ width: '100%', borderRight: '0.5px solid #000' }}>
                            <View style={{ width: '100%', height: 35, borderBottom: "0.5px solid #000", padding: '5px 6px' }}>
                                <Text style={styles.text10normal}>Residential Address:</Text>
                                <Text style={styles.text10normal}>{data.address}</Text>
                            </View>

                            <View style={{ width: '100%', height: 35, padding: '4px 6px', }}>
                                <Text style={styles.text10normal}>Mailing Address (if any):</Text>
                            </View>


                        </View>

                        <View style={{ width: '70%', padding: '1px 2px', }}>
                            <Text style={styles.text10normal}>  Contact (min 2no.): </Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Home:</Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Office:</Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Mobile: {data.contactNumber}</Text>
                        </View>

                    </View>
                    <View style={{ width: '100%', flexDirection: 'row', }}>
                        <View style={{ width: '100%', borderRight: '0.5px solid #000' }}>
                            <View style={{ width: '100%', height: 35, borderBottom: "0.5px solid #000", padding: '5px 6px' }}>
                                <Text style={styles.text10normal}>Residential Address:</Text>
                                <Text style={styles.text10normal}>{data.guarantor[0] && data.guarantor[0].addressGuarantor}</Text>
                            </View>

                            <View style={{ width: '100%', height: 35, padding: '4px 6px', }}>
                                <Text style={styles.text10normal}>Mailing Address (if any):</Text>
                            </View>


                        </View>

                        <View style={{ width: '70%', padding: '1px 2px', }}>
                            <Text style={styles.text10normal}>  Contact (min 2no.): </Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Home:</Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Office:</Text>
                            <Text style={{ ...styles.text10normal, padding: '1px 5px' }}>Mobile: {data.guarantor[0] && data.guarantor[0].contactNumberGuarantor}</Text>
                        </View>

                    </View>
                </View>
                <View style={{ borderBottom: '0.5px solid #000', width: '100%', flexDirection: 'row' }}>
                    <View style={{ width: '100%', flexDirection: 'row', borderRight: '0.5px solid #000' }}>
                        <View style={{ width: '100%', borderRight: '0.5px solid #000' }}>
                            <View style={{ width: '100%', height: 40, borderBottom: "0.5px solid #000", padding: '5px 6px' }}>
                                <Text style={styles.text10normal}>Companys' Name:</Text>
                                <Text style={styles.text10normal}> {data.employer}</Text>
                            </View>

                            <View style={{ width: '100%', height: 35, padding: '4px 6px', }}>
                                <Text style={styles.text10normal}>Company’s Address:</Text>
                                <Text style={styles.text10normal}>{data.employmentAddress}</Text>
                            </View>


                        </View>
                        <View style={{ width: '70%', flexDirection: 'column', }}>
                            <View style={{ width: '100%', height: 25, borderBottom: "0.5px solid #000", padding: '1px 2px', }}>
                                <Text style={styles.text10normal}> Occupation: {data.jobTitle}</Text>
                            </View>
                            <View style={{ width: '100%', height: 25, borderBottom: "0.5px solid #000", padding: '1px 2px', }}>
                                <Text style={styles.text10normal}> Length: </Text>
                            </View>
                            <View style={{ width: '100%', height: 25, padding: '1px 2px', }}>
                                <Text style={styles.text10normal}> Monthly Salary: {data.grossMonthlySalary}</Text>
                            </View>
                        </View>



                    </View>
                    <View style={{ width: '100%', flexDirection: 'row'}}>
                        <View style={{ width: '100%', borderRight: '0.5px solid #000' }}>
                            <View style={{ width: '100%', height: 40, borderBottom: "0.5px solid #000", padding: '5px 6px' }}>
                                <Text style={styles.text10normal}>Companys' Name:</Text>
                                <Text style={styles.text10normal}>{data.guarantor[0] && data.guarantor[0].employerGuarantor}</Text>
                            </View>

                            <View style={{ width: '100%', height: 35, padding: '4px 6px', }}>
                                <Text style={styles.text10normal}>Company’s Address:</Text>
                                <Text style={styles.text10normal}>{data.guarantor[0] && data.guarantor[0].employmentAddressGuarantor}</Text>
                            </View>


                        </View>
                        <View style={{ width: '70%', flexDirection: 'column', }}>
                            <View style={{ width: '100%', height: 25, borderBottom: "0.5px solid #000", padding: '1px 2px', }}>
                                <Text style={styles.text10normal}> Occupation: {data.guarantor[0] && data.guarantor[0].jobTitleGuarantor}</Text>
                            </View>
                            <View style={{ width: '100%', height: 25, borderBottom: "0.5px solid #000", padding: '1px 2px', }}>
                                <Text style={styles.text10normal}> Length: </Text>
                            </View>
                            <View style={{ width: '100%', height: 25, padding: '1px 2px', }}>
                                <Text style={styles.text10normal}> Monthly Salary: {data.guarantor[0] && data.guarantor[0].grossMonthlySalaryGuarantor} </Text>
                            </View>
                        </View>



                    </View>
                </View>
                <View style={{ borderBottom: '0.5px solid #000', flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                    <View style={{ width: '100%', padding: '1px 6px', textAlign: "center" }}>
                        <Text style={styles.textBold10}>SELF DECLARATION (COMPULSORY)</Text>
                    </View>

                </View>
                <View style={{ height: 40, borderBottom: "0.5px solid #000", flexDirection: 'column', alignItems: "center", justifyContent: "center" }}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: '100%', padding: '1px 6px', flexDirection: 'row' }}>
                            <Text style={{ ...styles.text10normal, }}>Any existing or past account with us?</Text>

                            <Text style={{ ...styles.textBold10, margin: "0 9px" }}> ☐ Yes</Text>
                            <Text style={{ ...styles.textBold10, margin: "0 9px" }}> ☐ No</Text>

                            <View style={{ flexDirection: 'row', flex: 2, justifyContent: "flex-end" }}>

                                <Text style={styles.text10normal}>GIRO A/C No:___________________________</Text>
                            </View>

                        </View>

                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: '100%', padding: '1px 6px', flexDirection: 'row' }}>
                            <Text style={{ ...styles.text10normal, flex: 1 }}>Are you the primary driver?</Text>

                            <Text style={{ ...styles.textBold10, margin: "0 9px" }}> ☐ Yes</Text>
                            <Text style={{ ...styles.textBold10, margin: "0 9px" }}> ☐ No</Text>

                            <View style={{ flexDirection: 'row', flex: 2, justifyContent: "flex-end" }}>

                                <Text style={styles.text10normal}>please specify: Name____________________   NRIC________________</Text>
                            </View>

                        </View>

                    </View>
                </View>


                <View style={{ borderBottom: '0.5px solid #000', alignItems: "center", flexDirection: 'row' }}>
                    <View style={{ width: '100%', textAlign: "center", height: 20, borderRight: '0.5px solid #000', alignItems: "center", flexDirection: 'row' }}>
                        <Text style={{ ...styles.textBold10, width: '100%' }}>DESCRIPTION OF MOTOR VEHICLE</Text>

                    </View>
                    <View style={{ width: '100%', textAlign: "center", height: 20, alignItems: "center", flexDirection: 'row' }}>
                        <Text style={{ ...styles.textBold10, width: '100%' }}>DETAILS OF DEALER</Text>

                    </View>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 1, }}>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ width: '100%', height: 25, padding: '1px 6px', borderBottom: '0.5px solid #000', borderRight: '0.5px solid #000', flexDirection: 'row', alignItems: "center" }}>
                                <Text style={{ ...styles.text10normal, width: '100%' }}>Туре:                  NEW        X        USED                COE</Text>

                            </View>

                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ width: 137, height: 25, padding: '1px 6px', borderRight: '0.5px solid #000', flexDirection: 'column', borderBottom: '0.5px solid #000' }}>
                                <Text style={{ ...styles.textBold10, }}>Make & Model:</Text>
                                <Text style={{ ...styles.textBold10, }}>{data.vehicleMake} {data.vehicleModel}</Text>
                            </View>
                            <View style={{ width: 137, height: 25, borderRight: '0.5px solid #000', padding: '1px 6px', borderBottom: '0.5px solid #000', flexDirection: 'row' }}>
                                <Text style={{ ...styles.textBold10, }}>Registration No.: {data.vehicleNumber}</Text>

                            </View>

                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ width: 137, height: 25, padding: '1px 6px', borderBottom: '0.5px solid #000', borderRight: '0.5px solid #000', flexDirection: 'column' }}>
                                <Text style={{ ...styles.textBold10, }}>Registration Date :</Text>
                                <Text style={{ ...styles.textBold10, }}>{data.firstRegistrationDate}</Text>
                            </View>
                            <View style={{ width: 137, height: 25, padding: '1px 6px', borderBottom: '0.5px solid #000', borderRight: '0.5px solid #000', flexDirection: 'row' }}>
                                <Text style={{ ...styles.textBold10, }}>OMV (S$):</Text>

                            </View>

                        </View>
                        <View style={{ borderBottom: '0.5px solid #000', flexDirection: 'row' }}>
                            <View style={{ width: 137, height: 25, padding: '1px 6px', borderRight: '0.5px solid #000', flexDirection: 'row' }}>
                                <Text style={{ ...styles.textBold10, }}>COE Expiry Date:</Text>

                            </View>
                            <View style={{ width: 137, height: 25, padding: '1px 6px', borderRight: '0.5px solid #000', flexDirection: 'row' }}>
                                <Text style={{ ...styles.textBold10, }}>COE Amount (S$):</Text>

                            </View>

                        </View>
                    </View>
                    <View style={{ flex: 1 }}>
                        <View  >
                            <Text style={{ ...styles.text10normal, padding: "2px 6px" }}>Dealers' Declaration: We confirm that hte information given ni Make &Model:this application aer true and correct nda that we have also verified hte identities of hte applicant against their relevant documents.</Text>
                        </View>
                        <View style={{ borderBottom: '0.5px solid #000', height: 51, flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end" }}>
                            <Text style={{
                                ...styles.text10normal, padding: "1px 6px",
                            }}>Dealer's Stamp &Authorized Signatory</Text>
                        </View>



                    </View>
                </View>
                <View style={{ borderBottom: '0.5px solid #000', alignItems: "center", flexDirection: 'row' }}>
                    <View style={{ width: '100%', textAlign: "center", alignItems: "center", flexDirection: 'row' }}>
                        <Text style={{ ...styles.textBold10, width: '100%' }}> REPAYMENT TERMS</Text>

                    </View>

                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 1, borderRight: "0.5px solid #000" }}>
                        <View style={{ flexDirection: 'row', alignItems: "flex-end", }}>
                            <View style={{ padding: "0 4px", width: 100 }}>
                                <Text style={styles.text10normal}>Net Purchase Price </Text>
                            </View>
                            <View style={{ padding: "0 4px", width: 20 }}>
                                <Text style={{ ...styles.text10normal, margin: "0", padding: "0", }}>$$ </Text>
                            </View>
                            <View style={{ flexDirection: 'row', width: 100, margin: "0 5px", borderBottom: "0.5px solid #000", }}>

                            </View>
                            <View style={{ flexDirection: 'row', borderLeft: "0.5px solid #000", borderBottom: "0.5px solid #000", padding: "2px 0" }}>
                                <Text style={{ ...styles.text10normal, margin: "0 7px" }}>HP</Text>
                                <Text style={{ ...styles.text10normal, margin: "0 7px" }}>BS</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: "flex-end" }}>
                            <View style={{ padding: "0 4px", width: 115, flexDirection: 'row', alignItems: "flex-end", justifyContent: "space-between" }}>
                                <Text style={styles.text10normal}>Downpayment </Text>
                                <Text style={{ ...styles.text10normal, margin: "0", padding: "0", }}>$$ </Text>
                            </View>

                            <View style={{ flexDirection: 'row', width: 96, margin: "0 5px", borderBottom: "0.5px solid #000", }}>

                            </View>

                        </View>
                        <View style={{ flexDirection: 'row', alignItems: "flex-end" }}>
                            <View style={{ padding: "0 4px", width: 115, flexDirection: 'row', alignItems: "flex-end", justifyContent: "space-between" }}>
                                <Text style={styles.text10normal}>S$ Finance Amount   </Text>
                                <Text style={{ ...styles.text10normal, margin: "0", padding: "0", }}>$$ </Text>
                            </View>

                            <View style={{ flexDirection: 'row', width: 96, margin: "0 5px", borderBottom: "0.5px solid #000", }}>

                            </View>

                        </View>
                        <View style={{ flexDirection: 'row', alignItems: "flex-end" }}>
                            <View style={{ padding: "0 4px", width: 115, flexDirection: 'row', alignItems: "flex-end", justifyContent: "space-between" }}>
                                <Text style={styles.text10normal}>Monthly Instalment  </Text>
                                <Text style={{ ...styles.text10normal, margin: "0", padding: "0", }}>$$ </Text>
                            </View>

                            <View style={{ flexDirection: 'row', width: 96, margin: "0 5px", borderBottom: "0.5px solid #000", }}>

                            </View>

                        </View>
                        <View style={{ flexDirection: 'row', alignItems: "flex-end" }}>
                            <View style={{ padding: "0 4px", width: 115, flexDirection: 'row', alignItems: "flex-end", justifyContent: "space-between" }}>
                                <Text style={styles.text10normal}>S$ Final Instalment </Text>
                                <Text style={{ ...styles.text10normal, margin: "0", padding: "0", }}>$$ </Text>
                            </View>

                            <View style={{ flexDirection: 'row', width: 96, margin: "0 5px", borderBottom: "0.5px solid #000", }}>

                            </View>

                        </View>
                        <View style={{ flexDirection: 'row', alignItems: "flex-end" }}>
                            <View style={{ padding: "0 4px", width: 115, flexDirection: 'row', alignItems: "flex-end", justifyContent: "space-between" }}>
                                <Text style={styles.text10normal}>Loan Period </Text>
                                <Text style={{ ...styles.text10normal, margin: "0", padding: "0", }}>$$ </Text>
                            </View>

                            <View style={{ flexDirection: 'row', width: 96, margin: "0 5px", borderBottom: "0.5px solid #000", }}>
<Text style={styles.text10normal}>{data.tenure}</Text>
                            </View>

                        </View>
                        <View style={{ flexDirection: 'row', alignItems: "flex-end" }}>
                            <View style={{ padding: "0 4px", width: 115, flexDirection: 'row', alignItems: "flex-end", justifyContent: "space-between" }}>
                                <Text style={styles.text10normal}>Interest Rate  </Text>
                                <Text style={{ ...styles.text10normal, margin: "0", padding: "0", }}>$$ </Text>
                            </View>

                            <View style={{ flexDirection: 'row', width: 96, margin: "0 5px", borderBottom: "0.5px solid #000", }}>

                            </View>

                        </View>





                    </View>
                    <View style={{ flex: 1 }}>
                        <View  >
                            <Text style={{ ...styles.text10normal, padding: "1px 6px" }}>D“I/We have recived and read completed Second Scedule
                                Notice (Summary of Financial Obligations) attached which sets out my financial obligations to the Hire Purchase transaction for the vehicle more particularly described ni the Description of Motor Vehicle and Iconfirm that the particulars in the said Second Schedule Statement are accurate and true and to which I have added my signature as confirmation."</Text>
                        </View>




                    </View>
                </View>
                <View style={{ width: "100%", borderBottom: "0.5px solid #000", marginTop: 3 }}></View>
                <View style={{ borderBottom: '0.5px solid #000', alignItems: "center", flexDirection: 'row' }}>
                    <View style={{ width: '100%', textAlign: "center", alignItems: "center", flexDirection: 'row' }}>
                        <Text style={{ ...styles.textBold10, width: '100%' }}> APPLICANT'S DECLARATION</Text>

                    </View>

                </View>
                <View style={{ width: "100%", alignItems: "center", justifyContent: "center", flexDirection: 'row' }}>
                    <Text style={{ ...styles.textBold10, width: 96, textAlign: "center", borderBottom: '0.5px solid #000', }}>   MAS DECLARATION</Text>
                </View>
                <View style={{ width: "100%", padding: "2px 4px" }}>
                    <Text style={{ ...styles.text10normal, }}>   A,I the Applicant, hereby declare as folows:</Text>

                    <Text style={{ ...styles.text10normal }}>

                        1. Whether I have received any discount, rebate or any other benefit from the Dealer or vendor of the motor vehicle described above or any other person (which includes but si not limited ot car servicing / maintenance packages, petrol / shopping vouchers and/or cash discounts):

                    </Text>
                    <Text style={{ ...styles.text10normal, padding: "2px 10px" }}>
                        Yes, I have received the total amount of S$____________ from_______________________________________
                    </Text>
                    <Text style={{ ...styles.text10normal, padding: "2px 10px" }}>
                        No.
                    </Text>

                    <Text style={{ ...styles.text10normal }}>

                        2. Whether I have obtained other credit or hire purchase facility for the purchase or hire (as the case may be) of the motor vehicle described above:

                    </Text>
                    <Text style={{ ...styles.text10normal, padding: "2px 10px" }}>
                        Yes, I have obtained from______________________________

                    </Text>
                    <Text style={{ ...styles.text10normal, padding: "2px 10px" }}>
                        No.
                    </Text>
                </View>



            </View>
        </Page>
        <Page size="A4" style={pStyles.page}>
            <View style={{ border: '0.5px solid #000', marginTop: 0.31, padding: "1px 5px" }}>
                <Text style={styles.text10normal}>
                    B. I agree ot provide you promptly with al such information and / or documents as you may require from time ot time relating ot or ni connection with the above declaration.
                </Text>
                <Text style={styles.text10normal}>
                    .C I agre and acknowledge that you may refuse ot disburse the credit or hire purchase facility (as the case may be), terminate, recal, review and / or vary such facility or any part thereof at any time and at your discretion ni the event of the above declarations proves ot be untrue, inaccurate or incomplete ni any respect or fi I fail or delay ot comply with any request for information or documents as you may make. nI this regard, I undertake ot pay any abortive costs imposed by you at your discretion and ot release and hold you harmless from and indemnify you for al loss, damages, costs (including legal costs on an indemnity basis), expenses and liabilities suffered or incurred by you arising from or ni connection with any such untrue, inaccurate or incomplete declarations), or failure or delay ni complying with your request for information or documents.
                </Text>
                <View style={{ width: "100%", alignItems: "center", justifyContent: "center", flexDirection: 'row' }}>
                    <Text style={{ ...styles.textBold10, width: 230, textAlign: "center", borderBottom: '0.5px solid #000', }}>   PERSONAL DATA PROTECTION ACT ("PDPA")</Text>
                </View>

                <Text style={styles.text10normal}>
                    D.  Pursuant ot the personal Data Protection Act 2012 (as may be amended from time to time) (the "Act"), I, hte Applicant, hereby
                    acknowledge and grant consent ot you to collect my personal data and use my personal data so as to provide me with information from time to time on al loan and related products offered by you that may or may not be directly related to the subject matter of my relationship with you via the modes of communication channels selected herein and using my contact particulars which you may have ni your records from time ot time (including where applicable my Singapore contact numbers). I hereby choose ot receive information from you from hte selected mode of communication: SMS/MMS/Other phone number based messaging, Email, Direct Mailer, Phone Cal, Fax, Al.
                </Text>

                <Text style={styles.text10normal}>
                    E.   I acknowledge and confirm that:
                </Text>
                <Text style={styles.text10normal}>
                    1.   if you do not have a copy of my signed consent form on record, you may not be able ot provide me with the information on the products that I
                    may find useful;
                </Text>

                <Text style={styles.text10normal}>
                    2.   this written consent shall continue ot be valid until you shall have received my written revocation with a reasonable notice period given ot you
                    to carry out such revocation;
                </Text>

                <Text style={styles.text10normal}>
                    3.   all personal data provided by me ot you shall be considered valid unless Ihave written to you to inform you of any changes. Ishall also update  .
                    you ni writing of any changes ot my personal data;
                </Text>
                <Text style={styles.text10normal}>
                    4.   my consent herein is ni addition ot any other consents which I may give you and/or such exceptions provided under the Act;
                </Text>

                <Text style={styles.text10normal}>
                    5.   any consent given above shall survive and continue notwithstanding the outcome of this application, unless 1otherwise subsequently
                    withdraw my consent;
                </Text>
                <Text style={styles.text10normal}>
                    6.   leaving any information blank wil not be treated as a withdrawal of any other consents that I may have previously given you; and7.you may
                    further disclose al my personal data to: (i) your related company or associated company ni any jurisdiction; (i) any government or regulatory
                    agency or authority; (iii) any of your potential assignee or transferee or any other person who may enter into contractual relations with you ni
                    relation ot this application; (iv) any credit bureau including its members; (v) any credit reference or evaluation
                    agencies wherever situated; and (vi) any other person ot whom disclosure is permitted or required by any statutory provision or law including
                    but not limited ot the Ac.t
                </Text>
                <View style={{ margin: "9px 0" }}>
                    <Text style={styles.text10normal}>
                        OTHERS
                    </Text>
                </View>

                <Text style={styles.text10normal}>
                    .F ,I the Applicant, further authorize you ot check on my/our creditworthiness through Consumer Credit Report from DP Credit Bureau Pte Ltd
                    and/or any other credit bureau (including its members) on my/our behalf.
                </Text>

                <Text style={styles.text10normal}>
                    G.  I, the Applicant, confirm that Iam above 12 years of age and not under any legal impediment.
                </Text>


                <Text style={styles.text10normal}>
                    H. I agree ot provide al proof of my income and any other documents you may need with copies of the same ot be retained by you.
                </Text>

                <Text style={styles.text10normal}>
                    I.  I confirm that Iam applying for the credit or hire purchase facility as principle in my personal capacity and I have not entered into
                    agreement, arrangement or undertaking with any other person with regard ot the hire, purchase, payment for, possession or use of the motor
                    vehicle described above or any other arrangement which is or would be ni any way inconsistent with this application.
                </Text>





                <Text style={styles.text10normal}>
                    J.  I warrant that al hte above consents, declarations, confirmations and/or acknowledgements shall remain true, accurate and complete ni al
                    respects and at al times and I shall immediately notify you ni writing if otherwise
                </Text>

                <Text style={styles.text10normal}>
                    I the Applicant, hereby declare that the information given above and any documents provided by me ot you si true and correct and that I have not wilfully withheld any material fact. I have never ben made bankrupt and there si no litigation filed against me ni hte past/present. You are authorized ot make inquiries with other parties relative to this application, which you consider necessary. I further acknowledge that you reserve hte right ot reject this application without assigning any reason and retain the documents submitted with this application.
                </Text>
                <View style={{ height: 220, width: "100%", justifyContent: "flex-end", flexDirection: 'row', alignItems: "flex-end" }}>
                    <View style={{ height: 85, marginBottom: 20, flexDirection: "column", justifyContent: "flex-end" }}>
                        <Text style={styles.text10normal}>
                            Applicant's Signature_________________________________________

                        </Text>
                        {/* <Text style={styles.text10normal}>

                            Name:_____________________________________________________

                        </Text>
                        <Text style={styles.text10normal}>

                            NRIC:_____________________________________________________
                        </Text> */}
                    </View>
                </View>
            </View>

        </Page>
        <Page size="A4" style={pStyles.page}>

            <View style={{ border: '0.5px solid #000', marginTop: 0.31, padding: "2px 0px" }}>
                <View style={{ borderBottom: "0.5px solid #000", textAlign: "center" }}>
                    <Text style={styles.textBold10}>GUARANTOR'S DECLARATION</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "center" }}>
                    <Text style={{ ...styles.textBold10, textAlign: "center", borderBottom: "0.5px solid #000", width: 218 }}>PERSONAL DATA PROTECTION ACT ("PDPA")</Text>
                </View>
                <View style={{ padding: "1px 5px" }}>
                    <Text style={styles.text10normal}>
                        A. Pursuant to the Personal Data Protection Act 2012 (as may be amended from time to time) (the "Act"), I, the Guarantor, hereby acknowledge
                        and grant consent to you to collect my personal data and use my personal data so as to provide me with information from time to time on al loan
                        and related products offered by you that may or may not be directly related to the subject matter of my relationship with you via the modes of
                        communication channels selected herein and using my contact particulars which you may have ni your records from time ot time (including
                        where applicable my Singapore contact numbers). I hereby choose ot receive information from you from the selected mode of communication:
                        SMS/MMS/Other phone number based messaging, Email, Direct Mailer, Phone Call, Fax, Al.
                    </Text>
                    <View style={{ height: 9 }} />
                    <Text style={styles.text10normal}>
                        B. I acknowledge and confirm that:

                    </Text>


                    <Text style={styles.text10normal}>
                        1. fI you do not have a copy of my signed consent form on record, you may not be able to provide me with the information on the products
                        that I may find useful;
                    </Text>

                    <Text style={styles.text10normal}>
                        2. this written consent shall continue to be valid until you shall have received my written revocation with a reasonable notice period given to
                        you to carry out such revocation;
                    </Text>
                    <Text style={styles.text10normal}>
                        3. al personal data provided by me ot you shal be considered valid unless Ihave written ot you ot inform you of any changes. Ishal also update you in writing of any changes to my personal data;
                    </Text>

                    <Text style={styles.text10normal}>
                        4. my consent herein si ni addition ot any other consents which I may give you and/or such exceptions provided under the Act;
                    </Text>

                    <Text style={styles.text10normal}>
                        5. any consent given above shall survive and continue notwithstanding the outcome of this application, unless Iotherwise subsequently
                        withdraw my consent;
                    </Text>
                    <Text style={styles.text10normal}>
                        6. leaving any information blank wil not be treated sa a withdrawal of any other consents that I may have previously given you; and
                        you may further disclose al my personal data to: (i) your related company or associated company ni any jurisdiction; (i) any government or
                        regulatory agency or authority; (ili) any of your potential assignee or transferee or any other person who may enter into contractual relations with
                        you in relation to this application; (iv) any credit bureau including its members; (v) any credit reference or evaluation agencies wherever
                        situated; and (vi) any other person to whom disclosure is permitted or required by any statutory provision or law including but not limited to the
                        Ac.t
                    </Text>

                    <Text style={styles.text10normal}>
                        5.   any consent given above shall survive and continue notwithstanding the outcome of this application, unless 1otherwise subsequently
                        withdraw my consent;
                    </Text>
                    <Text style={styles.text10normal}>
                        6.   leaving any information blank wil not be treated as a withdrawal of any other consents that I may have previously given you; and7.you may
                        further disclose al my personal data to: (i) your related company or associated company ni any jurisdiction; (i) any government or regulatory
                        agency or authority; (iii) any of your potential assignee or transferee or any other person who may enter into contractual relations with you ni
                        relation ot this application; (iv) any credit bureau including its members; (v) any credit reference or evaluation
                        agencies wherever situated; and (vi) any other person ot whom disclosure is permitted or required by any statutory provision or law including
                        but not limited ot the Ac.t
                    </Text>
                    <View style={{ margin: "9px 0" }}>
                        <Text style={styles.text10normal}>
                            OTHERS
                        </Text>
                    </View>

                    <Text style={styles.text10normal}>
                        C. I, the Guarantor, further authorize you ot check on my/our creditworthiness through Consumer Credit Report from DP Credit Bureau Pte Ltd
                        and/or any other credit bureau (including its members) on my/our behalf.
                    </Text>

                    <Text style={styles.text10normal}>
                        D. I, the Guarantor; confirm that I am above 12 years of age and not under any legal impediment.
                    </Text>


                    <Text style={styles.text10normal}>
                        E. I agree to provide al proof of my income and any other documents you may need with copies of the same to be retained by you.
                    </Text>

                    <Text style={styles.text10normal}>
                        F. I warrant that al the above consents, declarations, confirmations and/or acknowledgements shall remain true, accurate and complete in al
                        respects and at al times and I shall immediately notify you ni writing fi otherwise.
                    </Text>
                    <Text style={styles.text10normal}>

                        I,  the Guarantor, hereby declare that the information given above and any documents provided by me to you si true and correct and that I have not wilfully withheld any material fact. I have never been made bankrupt and there si no litigation filed against me ni the past/present. You are authorized to make inquiries with other parties relative to this application, which you consider necessary. Ifurther acknowledge that you reserve the right to reject this application without assigning any reason and retain the documents submitted with this application.
                        I confirm that Iam agreeable ot guarantee hte credit or hire purchase facility applied for herein.
                    </Text>


                    <View style={{ height: 240, width: "100%", justifyContent: "flex-end", flexDirection: 'row', alignItems: "flex-end" }}>
                        <View style={{ height: 80, marginBottom: 20, flexDirection: "column", justifyContent: "space-between" }}>
                            <Text style={styles.text10normal}>
                                Guarantor's Signature ________________________________________

                            </Text>
                            <Text style={styles.text10normal}>

                                Name:_____________________________________________________

                            </Text>
                            <Text style={styles.text10normal}>

                                NRIC:_____________________________________________________
                            </Text>
                        </View>
                    </View>
                </View>
            </View>

        </Page>
        <Page  size="A4" style={pStyles.page}>
            <View style={{border:"0.5px solid #000",}}>
            <View style={{ borderBottom: "0.5px solid #000", textAlign: "center" }}>
                    <Text style={styles.textBold12}>ANTI-MONEY LAUNDERING DECLARATION ("AML")</Text>
                </View >

                <View style={{ padding: "1px 5px" }}>
                    <View style={{ height: 150, }}>
                        <Text style={{ ...styles.text12, }}>
                            I declare and confirm that Ima not aPoliticaly-Exposed Person ("PEP") nor have Icommited or ben convicted or been investigated or am being investigated for any crimes or risks ni relation to Money Laundering, tax evasion, and/or terrorism financing under Singapore Law or any other laws.
                        </Text>
                    </View>

                    
            
                </View>
                <View style={{flexDirection:"row",borderTop:"0.5px solid #000"}}>
                    <View style={{ flex: 1, borderRight: "0.5px solid #000", height: 190 }}>
                        <View style={{ borderBottom: "0.5px solid #000", height: 20, flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                            <Text style={{ ...styles.text12, textAlign: "center", }}>HIRER</Text>
                        </View>


                        <View style={{ padding: "2px 4px", height: "100%" }}>
                            <View style={{ padding: "2px 4px", height: 40 }}>
                                <Text style={{ ...styles.text12, }}>Acknowledged the above by:</Text>
                            </View>
                            <View style={{ padding: "2px 4px", height: 80, flexDirection: 'column', justifyContent: "space-between", alignItems: "flex-start" }}>
                                <View>
                                    <Text style={{ ...styles.text12, }}>Signature                  :__________________</Text>
                                </View>
                                <View>
                                    <Text style={{ ...styles.text12, }}> Name                       :__________________ </Text>
                                </View>
                                <View>
                                    <Text style={{ ...styles.text12, }}>NRCI /PassportNo.  :__________________</Text>
                                </View>
                            </View>

                        </View>
                    </View>
                    <View style={{ flex: 1, height: 190 }}>
                        <View style={{ borderBottom: "0.5px solid #000", height: 20, flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                            <Text style={{ ...styles.text12, textAlign: "center", }}>GUARANTOR</Text>
                        </View>


                        <View style={{ padding: "2px 4px", height: "100%" }}>
                            <View style={{ padding: "2px 4px", height: 40 }}>
                                <Text style={{ ...styles.text12, }}>Acknowledged the above by:</Text>
                            </View>
                            <View style={{ padding: "2px 4px", height: 80, flexDirection: 'column', justifyContent: "space-between", alignItems: "flex-start" }}>
                                <View>
                                    <Text style={{ ...styles.text12, }}>Signature                  :__________________</Text>
                                </View>
                                <View>
                                    <Text style={{ ...styles.text12, }}> Name                       :__________________ </Text>
                                </View>
                                <View>
                                    <Text style={{ ...styles.text12, }}>NRCI /PassportNo.  :__________________</Text>
                                </View>
                            </View>

                        </View>
                    </View>
                    </View>
            </View>
           <View style={{border:"0.5px solid #000",marginTop:20}}>
           <View style={{  textAlign: "center",borderBottom:"0.5px solid #000",height:20,flexDirection:'row',alignItems:"center",justifyContent:"center" }}>
                    <Text style={styles.textBold12}>MONTHLY INCOME DECLARATION</Text>
                </View >
                <View style={{height:70,borderBottom:"0.5px solid #000",padding:"5px"}}>
                    <Text style={styles.text12}>I am unable ot furnish a copy of my income as required.</Text>
                </View>
             
            <View style={{flexDirection:"row",}}>
                    <View style={{ flex: 1, borderRight: "0.5px solid #000", height: 190 }}>
                        <View style={{ borderBottom: "0.5px solid #000", height: 20, flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                            <Text style={{ ...styles.text12, textAlign: "center", }}>HIRER</Text>
                        </View>


                        <View style={{ padding: "2px 4px", height: "100%" }}>
                            <View style={{ padding: "2px 4px", height: 40 }}>
                                <Text style={{ ...styles.text12, }}>Thereby declare and confirm that my current monthly income is $___________</Text>
                            </View>
                            <View style={{ padding: "2px 4px", height: 80, flexDirection: 'column', justifyContent: "space-between", alignItems: "flex-start" }}>
                                <View>
                                    <Text style={{ ...styles.text12, }}>Signature                  :__________________</Text>
                                </View>
                                <View>
                                    <Text style={{ ...styles.text12, }}> Name                       :__________________ </Text>
                                </View>
                                <View>
                                    <Text style={{ ...styles.text12, }}>NRCI /PassportNo.  :__________________</Text>
                                </View>
                            </View>

                        </View>
                    </View>
                    <View style={{ flex: 1, height: 190 }}>
                        <View style={{ borderBottom: "0.5px solid #000", height: 20, flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                            <Text style={{ ...styles.text12, textAlign: "center", }}>GUARANTOR</Text>
                        </View>


                        <View style={{ padding: "2px 4px", height: "100%" }}>
                            <View style={{ padding: "2px 4px", height: 40 }}>
                                <Text style={{ ...styles.text12, }}>Thereby declare and confirm that my current monthly income is $___________</Text>
                            </View>
                            <View style={{ padding: "2px 4px", height: 80, flexDirection: 'column', justifyContent: "space-between", alignItems: "flex-start" }}>
                                <View>
                                    <Text style={{ ...styles.text12, }}>Signature                  :__________________</Text>
                                </View>
                                <View>
                                    <Text style={{ ...styles.text12, }}> Name                       :__________________ </Text>
                                </View>
                                <View>
                                    <Text style={{ ...styles.text12, }}>NRCI /PassportNo.  :__________________</Text>
                                </View>
                            </View>

                        </View>
                    </View>
                    </View>
           </View>
         
        </Page>

    </Document>
</>)

const TokyoPdf = React.memo(({ isSave, setPdf, data }:Props) => {
    return (
        <BlobProvider document={isSave? <Doc1 data={data} dob={moment(data.dob,'YYYY-MM-DD').format('DD/MM/YYYY')} 
            dobGuarantor={moment(data.guarantor[0]?.dobGuarantor,'YYYY-MM-DD').format('DD/MM/YYYY')}/>:<></>} >
            {({blob})=>{
                    let file;
                    file = blob && new File([blob as Blob], 'tokyo.pdf');
                    data && setPdf(file)

                return <></>;
            }}

        </BlobProvider>
    )
  });

export default TokyoPdf;